import { ReactElement, useMemo, useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { ExtensionService, getSelectedInstance, useApiGetter, useAwsGetter, useRoutes } from '@localstack/services';

import { IconButton } from '@mui/material';

import {
  CloseFullscreen as CloseFullscreenIcon,
  OpenInFull as OpenInFullIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from '@mui/icons-material';


import { CustomerLayout } from '~/layouts';

import { AppRoute } from '~/config';

import { IframeExtension } from '../ExtensionCard/IframeExtension';

import { ExtensionHeader } from '../ExtensionCard/ExtensionHeader';
import { getExtensionUiURL } from '../../utils';

export const ExtensionsDetails = (): ReactElement => {
  const { plugin_name } = useParams<'plugin_name'>();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const isDev = queryParams.get('isDev');

  const isDevBoolean = isDev === 'true';

  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const { data: extension } =
    useApiGetter(ExtensionService, 'getExtensionByPluginName', [plugin_name ?? ''],
      { enable: !isDevBoolean });

  const { data: installedExtensions } = useAwsGetter('LocalStack', 'listExtensions', [],
    {
      enable: isDevBoolean,
    },
  );

  const targetDevExtension = installedExtensions?.map(ext =>
    ({ ...ext, plugin_name: ext.name })).find(ext => ext.name === plugin_name);

  const targetExtension = isDevBoolean ? targetDevExtension : extension;

  const instance = getSelectedInstance();
  const { goto } = useRoutes();

  return (
    <CustomerLayout
      maxWidth='lg'
      title={
        <>
          <IconButton
            size="large"
            onClick={() => goto(AppRoute.EXTENSIONS_MANAGE, { iid: instance?.id })}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
          <ExtensionHeader extension={targetExtension} typographyVariant='h4' showAdditionalInfo />
          <IconButton
            size="large"
            onClick={() => setFullScreen(!fullScreen)}
          >
            {fullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
          </IconButton>
        </>
      }
      forceShowInstanceHeader={!fullScreen}
      fullScreen={fullScreen}
    >
      <IframeExtension
        url={getExtensionUiURL(instance, targetExtension)}
      />
    </CustomerLayout >
  );
};
