import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button, MenuItem } from '@mui/material';
import { Dropdown, ConfirmableButton, KMSKeysTable } from '@localstack/ui';
import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { KMSProps } from './types';

const POLLING_INTERVAL = 5000;

export const KMSKeys = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_KMS_ROUTES,
}: KMSProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: keys, isLoading } = useAwsGetter(
    'KMS',
    'listKeys',
    [],
    { swrOverrides: { refreshInterval: POLLING_INTERVAL } , clientOverrides },
  );

  const { disableKey, enableKey, scheduleKeyDeletion } = useAwsEffect(
    'KMS',
    ['disableKey', 'enableKey', 'scheduleKeyDeletion'],
    { revalidate: ['listKeys', 'describeKey'] },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((KeyId) => scheduleKeyDeletion({ KeyId }));
    await Promise.all(promises);
  }, [selectedIds]);

  const handleDisableSelected = useCallback(async () => {
    const promises = selectedIds.map((KeyId) => disableKey({ KeyId }));
    await Promise.all(promises);
  }, [selectedIds]);

  const handleEnableSelected = useCallback(async () => {
    const promises = selectedIds.map((KeyId) => enableKey({ KeyId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title="KMS Keys"
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_KMS_KEY_CREATE)}>
            Create Key
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Key(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Keys will be permanently deleted"
            >
              Schedule Deletion
            </ConfirmableButton>
            <MenuItem onClick={handleDisableSelected}>
              Disable Selected
            </MenuItem>
            <MenuItem onClick={handleEnableSelected}>
              Enable Selected
            </MenuItem>
          </Dropdown>
        </>
      }
    >
      <Card>
        <KMSKeysTable
          selectable
          loading={isLoading}
          keys={keys?.Keys || []}
          onSelect={setSelectedIds}
          onViewKey={
            ({ KeyId }) => goto(routes.RESOURCES_KMS_KEY, { keyId: KeyId })
          }
        />
      </Card>
    </Layout>
  );
};
