import { ReactElement } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CardHeader,
  CardContent,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import classnames from 'classnames';
import { PlannedUsageType } from '@localstack/types';

import { useStyles } from './styles';

export const LSUsageDetails = (): ReactElement => {
  const { control } = useFormContext();
  const classes = useStyles();

  return (
    <>
      <CardHeader title="How are you using LocalStack?" />
      <CardContent>
        <Controller
          rules={{ required: true }}
          control={control}
          name="plannedUsageType"
          render={({ field }) => (
            <RadioGroup {...field}>
              <Box display='flex' flexDirection='column' gap={1} mt={2}>
                <FormControlLabel
                  value={PlannedUsageType.PROFESSIONAL}
                  control={<Radio />}
                  className={classnames(classes.primaryItemBox,
                    { [classes.primaryItemBoxActive]: field.value === PlannedUsageType.PROFESSIONAL })}
                  label={
                    <>
                      <Typography variant="body1">Professional</Typography>
                      <Typography variant="caption">
                        I&apos;m using LocalStack professionally.
                      </Typography>
                    </>
                  }
                />
                <FormControlLabel
                  value={PlannedUsageType.HOBBYIST}
                  control={<Radio />}
                  className={classnames(classes.primaryItemBox,
                    { [classes.primaryItemBoxActive]: field.value === PlannedUsageType.HOBBYIST })}
                  label={
                    <>
                      <Typography variant="body1">Hobbyist</Typography>
                      <Typography variant="caption">
                        I&apos;m using LocalStack only non-commercially.
                      </Typography>
                    </>
                  }
                />
              </Box>
            </RadioGroup>
          )}
        />
      </CardContent>
    </>
  );
};
