import { DEFAULT_CE_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateCostCategoryDefinitionRequest, UpdateCostCategoryDefinitionRequest } from '@localstack/types';
import { Breadcrumbs, CECostCategoryDefinitionForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { CEProps } from './types';

export const CECostCategoryDefinitionUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CE_ROUTES,
}: CEProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: costCategoryDefinition } = useAwsGetter(
    'CostExplorer',
    'describeCostCategoryDefinition',
    [{ CostCategoryArn: id }],
    { clientOverrides },
  );
  const { createCostCategoryDefinition, updateCostCategoryDefinition, isLoading } = useAwsEffect(
    'CostExplorer',
    ['createCostCategoryDefinition', 'updateCostCategoryDefinition'],
    {
      clientOverrides,
      revalidate: ['listCostCategoryDefinitions', 'describeCostCategoryDefinition'],
    },
  );

  const handleCreate = async (data: CreateCostCategoryDefinitionRequest) => {
    if (await createCostCategoryDefinition(data)) {
      goto(routes.RESOURCES_CE_COST_CATEGORY_DEFINITIONS);
    }
  };

  const handleUpdate = async (data: UpdateCostCategoryDefinitionRequest) => {
    if (await updateCostCategoryDefinition(data)) {
      goto(routes.RESOURCES_CE_COST_CATEGORY_DEFINITION, { id });
    }
  };

  return (
    <Layout
      documentTitle={`CE: ${id ? 'Update' : 'Create'} Cost Category Definition`}
      title={
        <Box>
          <Typography variant="h4">{id ? 'Update' : 'Create'} Cost Category Definition</Typography>
          <Breadcrumbs
            mappings={[
              ['Cost Explorer', () => goto(routes.RESOURCES_CE_COST_CATEGORY_DEFINITIONS)],
              [id, () => goto(routes.RESOURCES_CE_COST_CATEGORY_DEFINITION, { id })],
              [`${costCategoryDefinition ? 'Update' : 'Create'} Cost Category Definition` , null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="CECostCategoryDefinitionUpsert"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CECostCategoryDefinitionForm
            formId="CECostCategoryDefinitionUpsert"
            onSubmit={costCategoryDefinition ? handleUpdate : handleCreate}
            costCategoryDefinition={costCategoryDefinition}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="contained"
            type="submit"
            form="CECostCategoryDefinitionUpsert"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
