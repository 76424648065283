import { ReactElement } from 'react';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import {
  ElastiCacheCluster,
  CreateElastiCacheClusterParams,
  ModifyElastiCacheClusterParams,
} from '@localstack/types';
import { DEFAULT_SNS_ROUTES, DEFAULT_ELASTICACHE_VALUES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface ElastiCacheClusterFormProps {
  cluster?: Optional<Partial<ElastiCacheCluster>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateElastiCacheClusterParams) => unknown;
  onUpdate: (data: ModifyElastiCacheClusterParams) => unknown;
}

export const ElastiCacheClusterForm = ({
  cluster,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: ElastiCacheClusterFormProps): ReactElement => (
  <MagicForm
    data={cluster}
    schema={getSchema('ElastiCache')}
    loading={loading}
    entry={cluster ? 'ModifyCacheClusterMessage' : 'CreateCacheClusterMessage'}
    formId={formId}
    fieldConditions={{
      '^CacheClusterId$': !cluster,
    }}
    externalFields={{
      '^NotificationTopicArn$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='SNS' method='listTopics' arrayKeyName='Topics' property='TopicArn'
          fieldName={fieldName} entityName='SNS Topic'
          creationRoute={DEFAULT_SNS_ROUTES.RESOURCES_SNS_TOPIC_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    defaultValues={formId === 'create' ? DEFAULT_ELASTICACHE_VALUES?.CreateCacheClusterMessage?.input : undefined}
    onSubmit={(data: CreateElastiCacheClusterParams | ModifyElastiCacheClusterParams) => {
      if (!cluster) return onCreate(data);
      return onUpdate({ ...data, CacheClusterId: cluster.CacheClusterId as string });
    }}
  />
);
