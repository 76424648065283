import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CognitoCreateUserParams, CognitoUpdateUserParams } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, CognitoUserForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { CognitoProps } from './types';

import { PoolNavTabs } from './components';

export const CognitoPoolUserUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { poolId, username } = useParams<'poolId' | 'username'>();

  const { data: pool } = useAwsGetter(
    'CognitoIdentityServiceProvider', 
    'describeUserPool', 
    [{ UserPoolId: poolId }],
    { clientOverrides },
  );

  const { data: user } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'adminGetUser',
    [{ UserPoolId: poolId, Username: username }],
    { clientOverrides },
  );

  const { adminCreateUser, adminUpdateUserAttributes, isLoading } = useAwsEffect(
    'CognitoIdentityServiceProvider',
    ['adminCreateUser', 'adminUpdateUserAttributes'],
    { revalidate: ['adminGetUser', 'listUsers'], clientOverrides },
  );

  const handleCreate = async (data: CognitoCreateUserParams) => {
    await adminCreateUser(data);
    goto(routes.RESOURCES_COGNITO_POOL_USERS, { poolId });
  };

  const handleUpdate = async (data: CognitoUpdateUserParams) => {
    await adminUpdateUserAttributes(data);
    goto(routes.RESOURCES_COGNITO_POOL_USER, { poolId, username });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">User Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Cognito', () => goto(routes.RESOURCES_COGNITO)],
              [pool?.UserPool?.Name ?? poolId, () => goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId })],
              ['Users', () => goto(routes.RESOURCES_COGNITO_POOL_USERS, { poolId })],
              [username, () => goto(routes.RESOURCES_COGNITO_POOL_USER, { poolId, username })],
              [username ? 'Update' : 'Create User', null],
            ]}
          />
        </Box>
      }
      tabs={poolId && <PoolNavTabs poolId={poolId} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CognitoUserForm
            poolId={poolId as string}
            user={user}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
