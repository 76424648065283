import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { IAMUser } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export interface IAMUsersTableProps {
  users: IAMUser[];
  loading?: boolean;
  selectable?: boolean;
  onViewUser?: (user: IAMUser) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const IAMUsersTable = ({
  users,
  loading,
  selectable,
  onViewUser,
  onSelect,
}: IAMUsersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('IAM')}
    entry="User"
    idAttribute="UserName"
    rows={users}
    selectable={selectable}
    onSelect={onSelect}
    order={['UserId', 'UserName']}
    loading={loading}
    externalFields={{
      UserId: (row) => (
        <Link onClick={() => onViewUser && onViewUser(row)} underline="hover">
          {row.UserId}
        </Link>
      ),
    }}
  />
);
