/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Extension } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ExtensionService {

    /**
     * @returns Extension
     * @throws ApiError
     */
    public static listExtensionsMarketplace(): CancelablePromise<Array<Extension>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/extensions/marketplace',
        });
    }

    /**
     * @returns Extension
     * @throws ApiError
     */
    public static listExtensions(): CancelablePromise<Array<Extension>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/extensions',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static updateExtensionAdmin(
        requestBody?: Extension,
    ): CancelablePromise<{
        pre_signed_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/extensions/update/admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static submitExtension(
        requestBody?: Extension,
    ): CancelablePromise<{
        pre_signed_url: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/extensions/submit',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param extensionId
     * @returns Extension
     * @throws ApiError
     */
    public static getExtension(
        extensionId: string,
    ): CancelablePromise<Extension> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/extensions/{extension_id}',
            path: {
                'extension_id': extensionId,
            },
        });
    }

    /**
     * @param extensionUrl
     * @returns Extension
     * @throws ApiError
     */
    public static getGitExtension(
        extensionUrl: string,
    ): CancelablePromise<Extension> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/extensions/git/',
            query: {
                'extension_url': extensionUrl,
            },
        });
    }

    /**
     * @param pluginName
     * @returns Extension
     * @throws ApiError
     */
    public static getExtensionByPluginName(
        pluginName: string,
    ): CancelablePromise<Extension> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/extensions/by-plugin-name/{plugin_name}',
            path: {
                'plugin_name': pluginName,
            },
        });
    }

}
