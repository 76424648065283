import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, ECSTaskDetails } from '@localstack/ui';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

import { ClusterNavTabs } from './components';

export const ECSClusterTask = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterName, arn } = useParams<'clusterName' | 'arn'>();

  const { data: tasks } = useAwsGetter(
    'ECS',
    'describeTasks',
    [{ cluster: clusterName as string, tasks: [arn as string] }],
    { clientOverrides },
  );

  const task = tasks?.tasks?.[0];

  return (
    <Layout
      documentTitle="Task Details"
      tabs={<ClusterNavTabs clusterName={clusterName as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Task Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ECS', () => goto(routes.RESOURCES_ECS)],
              ['Clusters', () => goto(routes.RESOURCES_ECS_CLUSTERS)],
              [clusterName, () => goto(routes.RESOURCES_ECS_CLUSTER, { clusterName })],
              ['Tasks', () => goto(routes.RESOURCES_ECS_CLUSTER_TASKS, { clusterName })],
              [arn, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <ECSTaskDetails task={task} />
        </CardContent>
      </Card>
    </Layout>
  );
};
