import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, Grid, Tab, Tabs } from '@mui/material';
import capitalize from 'lodash/capitalize';
import {
  StateMachineExecutionEventsTable, PageTitle, ContentModal, CodeSnippetViewer, TabPanel,
  StateMachineExecutionGroupedEventsTable,
  StateExecutionGroupedEventDetails,
} from '@localstack/ui';
import { useRoutes, useAwsGetter } from '@localstack/services';
import {
  StateMachineCustomExecutionEvent,
  StateMachineDefinition,
  StateMachineDefinitionStates,
  StateMachineExecutionEventDetails, StateMachineExecutionGroupedEvent,
} from '@localstack/types';

import { DEFAULT_STEPFUNCTIONS_ROUTES } from '@localstack/constants';

import { StepFunctionsProps } from './types';
import { ExecutionTabs } from './components';
import { getDateDifferenceInMsFormat } from './utils';

const MAX_RESULTS = 500;

type StateMachineCustomEventsMap = Map<number, { previousEventId: number | undefined, step: string }>;

const getStepFromPreviousEvent = (map: StateMachineCustomEventsMap, eventId: number | undefined) => {
  const event = map.get(eventId || -1);
  if (!event) return '';
  if (!event.step) {
    getStepFromPreviousEvent(map, event.previousEventId);
  }
  return event.step;
};

const flattenStates = (states: StateMachineDefinitionStates): StateMachineDefinitionStates => {
  let statesObj = {};
  Object.entries(states).forEach(([key, value]) => {
    statesObj = { ...statesObj, [key]: value };
    if (value.Type === 'Parallel') {
      value.Branches?.forEach(branch => {
        statesObj = { ...statesObj, ...(flattenStates(branch.States)) };
      });
    }
  });
  return statesObj;
};

export const StateExecutionEvents = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_STEPFUNCTIONS_ROUTES,
}: StepFunctionsProps): ReactElement => {
  const { goto } = useRoutes();

  // Grouped execution events table states (left top table)
  const [eventGroupedTab, setEventGroupedTab] = useState('table');
  const [selected, setSelected] = useState<string[]>([]);
  const [groupedEvents, setGroupedEvents] = useState<StateMachineExecutionGroupedEvent[]>([]);
  const [selectedGroupedEvent, setSelectedGroupedEvent] = useState<StateMachineExecutionGroupedEvent | null>(null);

  // Grouped execution event details states (right top tabs)
  const [eventGroupedDetailsTab, setEventGroupedDetailsTab] = useState('input');

  // Execution Events Table States
  const [executionsData, setExecutionsData] = useState<StateMachineCustomExecutionEvent[]>([]);
  const [selectedExecutionEvent, setSelectedExecutionEvent] = useState<StateMachineExecutionEventDetails | null>(null);

  const { stateMachineArn, executionArn } = useParams<'stateMachineArn' | 'executionArn'>();
  const stateMachineName = stateMachineArn?.split(':').pop();
  const executionID = executionArn?.split(':').pop();

  const { data: executions, isLoading: executionHistoryLoading } = useAwsGetter(
    'StepFunctions',
    'getExecutionHistory',
    [{ executionArn, maxResults: MAX_RESULTS }],
    { clientOverrides },
  );
  const { data: stateMachineForExecution, isLoading: definitionLoading } = useAwsGetter(
    'StepFunctions',
    'describeStateMachine',
    [{ stateMachineArn }],
    { clientOverrides },
  );

  useEffect(() => {
    if (!executions?.events) return;

    const eventsMapById: StateMachineCustomEventsMap = new Map();

    const startDate = executions.events[0]?.timestamp;
    const processedExecutions = executions.events.map(item => {
      let details: StateMachineExecutionEventDetails = {};
      Object.keys(item).forEach(key => {
        if (key.endsWith('EventDetails')) {
          details = item[key as keyof typeof item] as StateMachineExecutionEventDetails;
        }
      });

      const step = item.type.includes('Execution') ? '' :
        details?.name || getStepFromPreviousEvent(eventsMapById, item.previousEventId);
      const resourceType = details?.resourceType || '';
      eventsMapById.set(item.id, { previousEventId: item.previousEventId, step });

      return ({
        Id: item.id,
        Type: item.type,
        Step: step,
        Resource: capitalize(resourceType),
        StartedAfter: getDateDifferenceInMsFormat(startDate, item.timestamp),
        DateAndTime: item.timestamp,
        EventDetails: details,
      });
    });
    setExecutionsData(processedExecutions ?? []);
  }, [executions?.events, executionHistoryLoading]);

  useEffect(() => {
    if (definitionLoading || !executionsData.length) return;
    const groupedEventsObj: Record<string, StateMachineExecutionGroupedEvent> = {};
    const definition = JSON.parse(stateMachineForExecution?.definition ?? '{}') as StateMachineDefinition;
    const states = flattenStates(definition.States);
    const stateKeys = Object.keys(states);

    executionsData.forEach(executionItem => {
      if (executionItem.Step !== '' && stateKeys.includes(executionItem.Step)) {
        const key = executionItem.Step as keyof typeof groupedEventsObj;
        const obj = groupedEventsObj[key];
        groupedEventsObj[key] = {
          Data: obj?.Data ? [...obj.Data, executionItem] : [executionItem],
        };
      }
    });

    Object.entries(groupedEventsObj).forEach(([groupedEventKey, groupedEventValue]) => {
      const statusChecks = {
        hasEntered: false,
        hasExited: false,
        hasNotFailed: false,
      };
      let input = '{}';
      let output = '{}';
      let resource = '';

      groupedEventValue.Data.forEach((executionItem: StateMachineCustomExecutionEvent) => {
        if (executionItem.Type.includes('Entered')) statusChecks.hasEntered = true;
        if (!executionItem.Type.includes('Failed')) statusChecks.hasNotFailed = true;
        if (executionItem.Type.includes('Exited')) statusChecks.hasExited = true;
        const eventDetails = executionItem.EventDetails;
        if (executionItem.Type.includes('Entered')) {
          input = eventDetails?.input || '';
        }
        if (executionItem.Type.includes('Exited')) {
          output = eventDetails?.output || '';
        }
        const resourceType = eventDetails?.resourceType || '';
        if (resourceType) resource = resourceType;
      });

      const firstExecutionItem = groupedEventValue.Data[0];
      const lastExecutionItem = groupedEventValue.Data[groupedEventValue.Data.length - 1];

      const state = states[groupedEventKey as keyof typeof states];
      groupedEventsObj[groupedEventKey] = {
        ...groupedEventValue,
        Name: groupedEventKey,
        Type: state?.Type || '',
        Status: Object.values(statusChecks).every(check => check) ? 'Succeeded' : 'Failed',
        Duration: getDateDifferenceInMsFormat(firstExecutionItem?.DateAndTime, lastExecutionItem?.DateAndTime),
        StartedAfter: firstExecutionItem?.StartedAfter || '',
        Definition: state,
        Input: input,
        Output: output,
        Resource: resource,
      };
    });
    setGroupedEvents(Object.values(groupedEventsObj));
  }, [definitionLoading, executionsData]);

  useEffect(() => {
    if (selected.length) {
      setSelectedGroupedEvent(groupedEvents.find(row => row.Name === selected[0]) || null);
    }
    else {
      setSelectedGroupedEvent(null);
    }
  }, [selected]);


  return (
    <Layout
      documentTitle="Execution Events"
      tabs={<ExecutionTabs stateMachineArn={stateMachineArn} executionArn={executionArn} routes={routes} />}
      title={
        <PageTitle
          title="Execution Events"
          breadcrumbs={[
            ['Step Functions', () => goto(routes.RESOURCES_STEPFUNCTIONS)],
            ['State Machines', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES)],
            [
              stateMachineName,
              () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS, { stateMachineArn }),
            ],
            [
              'Executions', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS, { stateMachineArn }),
            ],
            [
              executionID,
              () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION, { stateMachineArn, executionArn }),
            ],
            ['Events', null],
          ]}
        />
      }
    >

      <Grid container spacing={3} alignItems='stretch'>
        <Grid item md={6} sm={12}>
          <Card style={{ height: '100%' }}>
            <Tabs
              value={eventGroupedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_: unknown, newValue: string) => {
                setEventGroupedTab(newValue);
              }}
            >
              <Tab label="Table View" value='table' />
            </Tabs>

            <TabPanel index='table' tab={eventGroupedTab}>
              <Box p={1}>
                <StateMachineExecutionGroupedEventsTable
                  events={groupedEvents}
                  loading={definitionLoading || executionHistoryLoading}
                  onSelect={setSelected}
                  selectedItems={selected}
                />
              </Box>
            </TabPanel>
          </Card>
        </Grid>
        <Grid item md={6} sm={12}>
          <Card style={{ height: '100%' }}>
            {!selectedGroupedEvent ? <Box p={2}><p>Choose a step to view its details</p></Box> :
              <StateExecutionGroupedEventDetails
                event={selectedGroupedEvent}
                activeTab={eventGroupedDetailsTab}
                setTabs={setEventGroupedDetailsTab}
                setSelectedExecutionEvent={setSelectedExecutionEvent}
              />
            }
          </Card>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Card>
          <StateMachineExecutionEventsTable
            events={executionsData ?? []}
            setSelectedExecutionEvent={setSelectedExecutionEvent}
            loading={executionHistoryLoading}
          />
        </Card>
      </Box>
      {
        selectedExecutionEvent && (
          <ContentModal
            title='Event Details'
            fullWidth maxWidth="md" open={!!selectedExecutionEvent} onClose={() => setSelectedExecutionEvent(null)}
          >
            <CodeSnippetViewer data={JSON.stringify(selectedExecutionEvent)} />
          </ContentModal>
        )
      }
    </Layout>
  );
};
