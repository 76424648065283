import { ReactElement } from 'react';
import { useTheme, Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { formatDateTime, getDisplayName } from '@localstack/services';
import { HealthState, StackInfo } from '@localstack/types';
import classnames from 'classnames';

import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  TypographyTypeMap,
  Tooltip,
  useMediaQuery,
} from '@mui/material';

import {
  AvTimer as TimeIcon,
  Cancel as ErrorCallsIcon,
  CheckCircle as SuccessfulCallsIcon,
  Laptop as LocalRunIcon,
  Power as NumberOfServicesIcon,
  PowerOutlined as NumberOfServicesOutlinedIcon,
  RotateRight as CiRunIcon,
  Person as UserIcon,
} from '@mui/icons-material';

import moment from 'moment';

import { ThemeType } from '@localstack/constants';

import { SystemStatus } from '../../../display';
import { ClientIcon } from '../../../display/ClientIcon';
import { formatUserAgent } from '../common/index';

import { StackIcon } from '../StackIcon';

interface TextWithIconProps {
  text: string;
  iconLight: JSX.Element;
  iconDark?: JSX.Element;
  iconPosition?: 'left' | 'right';
  textVariant?: TypographyTypeMap['props']['variant'];
}

export const TextWithIcon = ({
  text,
  iconLight,
  iconDark,
  iconPosition = 'left',
  textVariant: variant,
}: TextWithIconProps): JSX.Element => {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === ThemeType.DARK;

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item component={Box} display="flex"
        sx={{ alignItems: 'center' }} style={{ order: iconPosition === 'left' ? 0 : 2 }}
      >
        {(isDarkTheme && iconDark) || iconLight}
      </Grid>
      <Grid item style={{ order: 1 }} component={Typography} variant={variant}>
        {text}
      </Grid>
    </Grid>
  );
};

export interface StackCardProps {
  stackInfo: StackInfo;
  running?: boolean;
  selected?: boolean;
  size?: 'md' | 'lg';
  onClick?: (session_id: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      position: 'relative',
      cursor: (props: StackCardProps) => props.onClick ? 'pointer' : 'unset',
      transition: 'all 0.2s ease-in-out',
      '&:hover': {
        transform: (props: StackCardProps) => props.onClick ? 'scale(0.98)' : '',
      },
    },
    cardContent: {
      padding: '0.5rem !important',
    },
    iconGridItem: {
      minWidth: '112px',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      display: 'flex',
    },
    item: {
      height: '100%',
      minHeight: '30px',
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      height: '17px',
    },
    selected: {
      '&:before': {
        content: '""',
        position: 'absolute',
        width: theme.spacing(2),
        height: '100%',
        background: theme.palette.primary.main,
        right: 0,
        top: 0,
      },
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.5,
    },
    overflowEllipsis: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      dispay: 'inline-block',
    },
  }),
);

export const StackCard = ({
  stackInfo,
  running = false,
  selected = false,
  size = 'lg',
  onClick,
}: StackCardProps): ReactElement => {
  const classes = useStyles({ stackInfo, running, selected, size, onClick });
  const theme = useTheme();

  const isViewportSmOrAbove = useMediaQuery(theme.breakpoints.up('sm'));
  const isViewportMdOrAbove = useMediaQuery(theme.breakpoints.up('md'));
  const isViewportLgOrAbove = useMediaQuery(theme.breakpoints.up('lg'));

  const isSizeLg = size === 'lg';
  const smSize = isSizeLg ? 12 : 6;
  const mdSize = isSizeLg ? 6 : 12;
  const lgSize = isSizeLg ? 4 : 6;

  const lgSizeSessionId = isSizeLg ? 'auto' : 12;

  const userAgentCode = formatUserAgent(stackInfo.top_user_agent || '');
  const disabled = (stackInfo.number_of_api_calls_error === 0) && (stackInfo.number_of_api_calls_success === 0);

  const displayStackSymbol = isSizeLg && isViewportSmOrAbove;
  const displayEmptyGridItem = isSizeLg ?
    isViewportMdOrAbove && !isViewportLgOrAbove :
    isViewportSmOrAbove && !isViewportMdOrAbove || isViewportLgOrAbove;

  const onCardClick = () => disabled ? undefined : onClick?.(stackInfo.session_id);

  return (
    <Card
      onClick={onCardClick}
      className={classnames(classes.card, { [classes.selected]: selected, [classes.disabled]: disabled })}
    >
      <CardContent className={classes.cardContent}>
        <Grid container spacing={5} wrap="nowrap" alignItems="center">
          {displayStackSymbol && (
            <Grid item xs={2} className={classes.iconGridItem}>
              <StackIcon variant={running ? 'running' : 'default'} />
            </Grid>
          )}
          <Grid item>
            <Grid container spacing={1} alignItems="center" >
              <Grid item xs={12} lg={lgSizeSessionId}
                className={classes.item} component={Typography} variant="subtitle2"
              >
                <b>ID: {stackInfo.session_id}</b>
              </Grid>

              <Grid item xs={12} sm={smSize} md={mdSize} lg={lgSize}
                className={classes.item} component={Typography} variant="subtitle2"
              >
                {formatDateTime(stackInfo.server_time_utc)} UTC
              </Grid>

              {displayEmptyGridItem &&
                <Grid item xs={12} sm={smSize} md={mdSize} lg={lgSize}/>
              }

              <Grid item xs={12} sm={smSize} md={mdSize} lg={lgSize} className={classes.item}>
                { running ?
                  <Typography variant='subtitle2'>
                    <SystemStatus state={HealthState.RUNNING} />
                  </Typography> :
                  <TextWithIcon
                    text={getDisplayName(stackInfo)}
                    textVariant="subtitle2"
                    iconLight={
                      <UserIcon fontSize='small' />
                    }
                  />
                }
              </Grid>

              <Grid item xs={12} sm={smSize} md={mdSize} lg={lgSize} className={classes.item}>
                <Box display="flex" style={{ gap: '1rem' }}>
                  {stackInfo.is_ci ? (
                    <Tooltip title="CI RUN">
                      <CiRunIcon fontSize='small' />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Local Run">
                      <LocalRunIcon fontSize='small' />
                    </Tooltip>
                  )}
                  {stackInfo.is_docker && <ClientIcon code="docker" />}
                  {userAgentCode && (
                    <ClientIcon title={`Top user agent: ${userAgentCode}`} code={userAgentCode} />
                  )}
                </Box>
              </Grid>

              <Grid item xs={12} sm={smSize} md={mdSize} lg={lgSize}
                variant="subtitle2" component={Typography} className={classes.item}
              >
                <span className={classes.overflowEllipsis}>
                  Version: {stackInfo.version}
                </span>
              </Grid>

              <Grid item xs={12} sm={smSize} md={mdSize} lg={lgSize} className={classes.item}>
                <TextWithIcon
                  iconLight={<TimeIcon />}
                  text={`Runtime: ${moment.duration({ seconds: stackInfo.duration_in_seconds }).humanize()}`}
                  textVariant="subtitle2"
                />
              </Grid>

              <Grid item component={Typography} variant="subtitle2"
                xs={12} sm={smSize} md={mdSize} lg={lgSize} className={classes.item}
              >
                <Box display="flex" flexWrap="nowrap" alignItems="center">
                  API Calls: &nbsp;
                  {stackInfo.number_of_api_calls_success}
                  &nbsp;
                  <SuccessfulCallsIcon style={{ color: theme.palette.success.main }} />
                  &nbsp;
                  {stackInfo.number_of_api_calls_error}
                  &nbsp;
                  <ErrorCallsIcon style={{ color: theme.palette.error.main }} />
                </Box>
              </Grid>

              <Grid item xs={12} sm={smSize} md={mdSize} lg={lgSize} className={classes.item}>
                <TextWithIcon
                  textVariant="subtitle2"
                  text={`${stackInfo.number_of_services} services`}
                  iconLight={<NumberOfServicesOutlinedIcon />}
                  iconDark={<NumberOfServicesIcon />}
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
