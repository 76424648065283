import { ReactElement } from 'react';
import { CreateSESV2EmailIdentityRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface SESV2IdentityFormProps {
  loading?: boolean;
  formId?: string;
  onSubmit: (data: CreateSESV2EmailIdentityRequest) => unknown;
}

export const SESV2IdentityForm = ({
  loading,
  formId,
  onSubmit,
}: SESV2IdentityFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SESV2')}
    loading={loading}
    entry="CreateEmailIdentityRequest"
    formId={formId}
    onSubmit={(data: CreateSESV2EmailIdentityRequest) => onSubmit(data)}
  />
);
