import { ReactElement } from 'react';
import { LoadBalancerInput } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type ELoadBalancersV2TableProps = {
  selectable?: boolean;
  loading?: boolean;
  loadBalancers: LoadBalancerInput[];
  onViewLoadBalancer?: (loadBalancers: LoadBalancerInput) => unknown;
  onSelect?: (names: string[]) => void;
};

export const ELoadBalancersV2Table = ({
  loadBalancers,
  selectable = true,
  loading,
  onSelect,
}: ELoadBalancersV2TableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ELBv2')}
    entry="LoadBalancers"
    idAttribute="LoadBalancerName"
    filterColumns={['LoadBalancerArn','DNSName', 'LoadBalancerName', 'Scheme', 'CanonicalHostedZoneId']}
    rows={loadBalancers}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
  />
);
