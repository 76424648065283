import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { AgwApiIntegration, CreateAgwApiIntegrationRequest, UpdateAgwApiIntegrationRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwApiIntegrationFormProps {
  apiId: string;
  integration?: Optional<AgwApiIntegration>;
  loading?: boolean;
  isWebsocket?: boolean;
  formId?: string;
  onCreate: (data: CreateAgwApiIntegrationRequest) => unknown;
  onUpdate: (data: UpdateAgwApiIntegrationRequest) => unknown;
}

export const AgwApiIntegrationForm = ({
  apiId,
  integration,
  loading,
  isWebsocket,
  formId,
  onCreate,
  onUpdate,
}: AgwApiIntegrationFormProps): ReactElement => (
  <MagicForm
    data={integration}
    schema={getSchema('ApiGatewayV2')}
    loading={loading}
    entry="CreateIntegrationRequest"
    formId={formId}
    fieldConditions={{
      '^ConnectionId$': ['$ConnectionType', '===', 'VPC_LINK'],
      '^IntegrationSubtype$': ['$IntegrationType', '===', 'AWS_PROXY'],
      '^ContentHandlingStrategy$': !!isWebsocket,
    }}
    externalFields={{
      '^ApiId$': () => (
        <TextField fullWidth disabled value={apiId} variant="outlined" />
      ),
    }}
    onSubmit={(data: CreateAgwApiIntegrationRequest) => {
      if (!integration) return onCreate({ ...data, ApiId: apiId });
      return onUpdate({ ...data, ApiId: apiId, IntegrationId: integration.IntegrationId as string });
    }}
  />
);
