import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiFab'] => ({
  styleOverrides: {
    root: {
      backgroundColor: palette.common.white,
      color: palette.text.secondary,
      '&:hover': {
        backgroundColor: palette.grey[400],
      },
    },
  },
});
