import { SERVICE_CODES } from '@localstack/constants';
import { getSelectedInstance, useLocalstackStatus, useRoutes } from '@localstack/services';
import { AwsServiceTile } from '@localstack/ui';
import { ReactElement, useState } from 'react';

import {
  Box,
  Grid,
  List,
  ListItem,
  TextField,
  Typography,
} from '@mui/material';

import { RESOURCES_TYPES, RESOURCE_GROUPS, ResourceGroups } from '@localstack/resources';
import { ProductFeature } from '@localstack/types';

import { CustomerLayout } from '~/layouts';

import { NotRunningAlert } from '~/components';
import { useAuthProvider } from '~/hooks';

const DEFAULT_COLUMN_WIDTH = 240;
const PAGE_TITLE = 'Resource Browser';

export const ResourcesOverview = (): ReactElement => {
  const { goto } = useRoutes();
  const [query, setQuery] = useState('');
  const { hasFeature } = useAuthProvider();
  const isEnterpriseSub = hasFeature(ProductFeature.FEATURE_CI_PROJECTS);


  const filteredGroups = RESOURCE_GROUPS.reduce((groupsMemo, groups) => {
    const subgroups = groups.reduce((groupMemo, group) => {
      const filteredCodes = group[1].filter(
        (code) => code.toLowerCase().includes(query) ||
          SERVICE_CODES[code]?.toLowerCase()?.includes(query),
      );

      return filteredCodes.length ? [...groupMemo, [group[0], filteredCodes]] : groupMemo;
    }, []);

    return subgroups.length ? [...groupsMemo, [...subgroups]] : groupsMemo;
  }, []) as ResourceGroups;

  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};
  const { running } = useLocalstackStatus(clientOverrides);

  return (
    <CustomerLayout title={PAGE_TITLE}>
      <Grid container spacing={2}>
        {!running &&
          <Grid item xs={12}>
            <NotRunningAlert />
          </Grid>
        }
        <Grid item xs={12}>
          <Box mb={4}>
            <TextField
              fullWidth
              autoFocus
              onChange={(e) => setQuery(e.target.value?.toLocaleLowerCase())}
              variant="outlined"
              size="small"
              label="Which service are you looking for?"
            />
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" mb={4}>
            {filteredGroups.map((group) => (
              <Box mr={2} width={DEFAULT_COLUMN_WIDTH} key={group.join('')}>
                {group.map(([title, services]) => (
                  <Box key={title}>
                    <Typography variant="caption">{title}</Typography>
                    <List dense disablePadding>
                      {services.map((code) => (
                        <ListItem
                          key={code}
                          disableGutters
                        >
                          <AwsServiceTile
                            code={code}
                            size="large"
                            title={SERVICE_CODES[code]}
                            hideTooltip
                            showFavoriteIcon
                            clickable={running}
                            navigateTo={() => goto(RESOURCES_TYPES[code])}
                            isEnterpriseSub={isEnterpriseSub}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </CustomerLayout>
  );
};
