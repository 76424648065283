import { AxiosError } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AxiosErrorType = Optional<AxiosError<any, any> | Error>;

export enum SIGNUP_FLOW_TYPE {
  EMAIL = 'email',
  PUBLIC_SSO = 'sso',
  ENTERPRISE_SSO = 'enterprise-sso',
}
