import { ExternalLink, SUBSCRIBABLE_PLAN_FAMILIES, UPDATEABLE_PLAN_FAMILIES } from '@localstack/constants';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Alert, Skeleton, ToggleButton, ToggleButtonGroup,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  Theme,
} from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

import {
  computeNumLicensesUsed,
  formatIntervalMonths,
  formatMonetaryAmount,
  formatTaxableText,
  getPlanEstimations,
  pluralizeOrDash,
} from '@localstack/services';

import {
  Business as CompanyIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  Person as IndividualIcon,
} from '@mui/icons-material';

import {
  ApiKey,
  CreditCard,
  Discount,
  LicenseAssignment,
  OrderContext,
  Organization,
  OrganizationType,
  Plan,
  PlanFamily,
  Product,
  Subscription,
  TaxDetails,
} from '@localstack/types';


import { noop } from 'lodash';

import { NewTabLink } from '../../../navigation';

import { ActionTitle, DecorativeDivider, TosAgreementLine } from '../../../display';
import { IncrementButton, LoadingFragment, ProgressButton } from '../../../feedback';
import { CreditCardsList } from '../../account/CreditCardsList';
import { AddressForm } from '../../billing/AddressForm';
import { AddressPreview } from '../../billing/AddressPreview';
import { TaxForm } from '../../billing/TaxForm';

import { PlansList } from '../PlansList';
import { ProductsList } from '../ProductsList';

import { DowngradeLicensesList } from '../DowngradeLicensesList';

import { ConstructorForm } from './ConstructorForm';

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    padding: 0,
  },
  discount: {
    color: theme.palette.success.main,
    '& span, & p': {
      color: theme.palette.success.main,
    },
  },
  total: {
    fontWeight: 'bold',
    '& span': {
      fontWeight: 'bold',
    },
  },
  totalDivider: {
    background: 'transparent',
    borderBottom: `1px dashed ${theme.palette.divider}`,
  },
  toggleButtonGroup: {
    display: 'flex',
    width: '100%',
  },
  toggleButton: {
    flexGrow: 1,
  },
}));

export interface CheckoutFormProps {
  loading?: boolean;
  isSubmitting?: boolean;
  taxLoading?: boolean;
  tax?: Optional<TaxDetails>;
  defaultPlan?: Optional<Plan>;
  plans: Plan[];
  cards: CreditCard[];
  subscription?: Optional<Subscription>;
  subscriptionKeys?: ApiKey[];
  subscriptionLicenseAssignments?: Optional<LicenseAssignment[]>;
  organization: Optional<Organization>;
  promotion?: Optional<Discount>;
  promotionLoading?: boolean;
  adminMode?: boolean;
  /* all available discounts, use it with `adminMode=true` */
  adminDiscounts?: Discount[];
  /* all available products, use it with `adminMode=true` */
  adminProducts?: Product[];
  showCardForm?: boolean;
  /* a bit ugly way to keep stripe libs out of ui package */
  renderCardForm?: (currency: string) => ReactElement;
  onChangeSeats?: (seats: number) => unknown;
  onChangePlan?: (plan: Plan) => unknown;
  onToggleCardForm?: () => unknown;
  onApplyPromoCode?: (planId: string, code: Optional<string>) => unknown;
  onUpdateOrganization?: (data: Organization) => unknown;
  onDeleteCard?: (card: CreditCard) => unknown;
  onDeleteApiKey?: (id: string) => unknown;
  onDeleteLicense?: (id: string) => unknown;
  onSubscribe?: (
    plan: Plan,
    products: Product[],
    context: OrderContext,
    subscription?: Optional<Subscription>,
    discount?: Optional<Discount>,
  ) => unknown;
  hideHobbyPlan?: boolean
}

export const CheckoutFormSkeleton = (): ReactElement => (
  <Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
      <LoadingFragment variant="card" height={650} loading />
    </Grid>
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
      <LoadingFragment variant="card" height={300} loading />
      <Box mt={2} />
      <LoadingFragment variant="list" size={4} height={40} loading />
      <Box mt={2} />
      <LoadingFragment variant="list" size={4} height={16} loading />
    </Grid>
  </Grid>
);
export const CheckoutForm = ({
  loading,
  isSubmitting,
  taxLoading,
  tax,
  defaultPlan,
  plans,
  cards,
  subscription,
  subscriptionKeys,
  subscriptionLicenseAssignments,
  organization,
  promotion,
  promotionLoading,
  adminMode,
  adminDiscounts,
  adminProducts,
  showCardForm,
  renderCardForm,
  onChangeSeats,
  onChangePlan,
  onToggleCardForm,
  onApplyPromoCode,
  onUpdateOrganization,
  onDeleteCard,
  onDeleteApiKey,
  onDeleteLicense,
  onSubscribe,
  hideHobbyPlan,
}: CheckoutFormProps): ReactElement => {
  const classes = useStyles();

  const [agree, setAgree] = useState(false);
  const [seats, setSeats] = useState(1);
  const [card, setCard] = useState<Optional<CreditCard>>(cards?.[0]);
  const [discount, setDiscount] = useState<Optional<Discount>>(null);
  const [promoCode, setPromoCode] = useState<Optional<string>>(null);
  const [purchaseType, setPurchaseType] = useState<Optional<OrganizationType>>(null);
  const [overrides, setOverrides] = useState<Partial<OrderContext>>({});

  const [showAddressForm, setShowAddressForm] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState({ address: false, tax: false });

  const filteredPlans = plans.filter((plan) => {
    const editMode = !!subscription;

    if (adminMode) {
      const adminUpdatablePlans = [...UPDATEABLE_PLAN_FAMILIES, PlanFamily.ENTERPRISE_PLANS];
      return !editMode || adminUpdatablePlans.includes(plan.family);
    }

    // always include currently subscribed plan
    // this only works if we still offer the currently provided plan
    // see next check for a fallback to superseding plan
    if (editMode && plan.id === subscription?.plan.id) {
      return true;
    }

    // always include plans superseding the currently subscribed plan
    // this is needed to have controlled upgrade paths from legacy plans
    if (editMode && subscription && plan.supersedes_plan_ids?.includes(subscription.plan.id)) {
      return true;
    }

    // remove trial plan from the plans list on updates
    if (editMode && plan.family === PlanFamily.TRIAL_PLANS) {
      return false;
    }

    // remove hobby unless preselected
    if (plan.family === PlanFamily.HOBBY_PLANS && !(defaultPlan?.family === PlanFamily.HOBBY_PLANS)) {
      return false;
    }

    // remove regular monthly plans
    if ((plan.family === PlanFamily.PRO_PLANS && plan.interval_months === 1)
      || (plan.family === PlanFamily.TEAM_PLANS && plan.interval_months === 1)) {
      return false;
    }

    // remove hobby plans from plans list on updates
    if (editMode && plan.family === PlanFamily.HOBBY_PLANS) {
      return false;
    }

    if (hideHobbyPlan && plan.family === PlanFamily.HOBBY_PLANS) {
      return false;
    }

    return editMode
      ? UPDATEABLE_PLAN_FAMILIES.includes(plan.family)
      : SUBSCRIBABLE_PLAN_FAMILIES.includes(plan.family);
  });

  const sortedPlans = filteredPlans.slice().sort(
    (lp, rp) => SUBSCRIBABLE_PLAN_FAMILIES.indexOf(lp.family as PlanFamily) -
      SUBSCRIBABLE_PLAN_FAMILIES.indexOf(rp.family as PlanFamily),
  );

  const [plan, setPlan] = useState<Plan>(defaultPlan || sortedPlans[0] as Plan);
  const [products, setProducts] = useState<Product[]>(plan.products);

  const filteredCards = cards.filter((c) => c.currency === plan.currency);

  // final context with overrides
  const context: OrderContext = {
    seats,

    org_id: '', // assigned by the backend
    customer_id: '', // assigned by the backend
    payment_id: card?.id,
    promo_code: discount && promoCode ? promoCode : undefined,

    interval: subscription ? subscription.interval_months : plan.interval_months,
    name: subscription?.name,

    ...overrides,
  };

  const customMrr = context.mrr ? (context.mrr * (context.interval ?? plan.interval_months)) : null;
  const taxAmount = tax?.tax_amount ?? 0;

  const planFullAmount = customMrr ?? getPlanEstimations(products, context, discount, false);
  const planFormattedFullAmount = formatMonetaryAmount(planFullAmount, plan.currency);

  const planDiscountedAmount = customMrr ?? getPlanEstimations(products, context, discount, true);
  const planFormattedDiscountedAmount = formatMonetaryAmount(planDiscountedAmount, plan.currency);

  const planDiscountedAmountWithTax = customMrr ?? (planDiscountedAmount + taxAmount);
  const planFormattedDiscountedAmountWithTax = formatMonetaryAmount(planDiscountedAmountWithTax, plan.currency);

  const planFormattedDiscountAmount = formatMonetaryAmount(
    planFullAmount - planDiscountedAmount,
    plan.currency,
  );


  const licensesUsed = (subscription && subscriptionLicenseAssignments && subscriptionKeys && organization)
    && computeNumLicensesUsed(
      subscription,
      subscriptionLicenseAssignments,
      subscriptionKeys,
      organization?.members,
    ) || 0;

  const hasDiscount = planFullAmount !== planDiscountedAmount;
  const hasCorrectCurrency = !subscription || subscription.currency === plan.currency;
  const hasOutstandingKeys = subscription && (licensesUsed - context.seats) > 0;
  const isUpgrading = subscription && subscription.seats < context.seats;
  const isDowngrading = subscription && subscription.seats > context.seats;
  const isLegacySubscription = subscription?.plan?.family === PlanFamily.LEGACY_PLANS;
  const isCardRequired = getPlanEstimations(products, context, discount, true) > 0;

  const [isNonCommercialConfirmed, setIsNonCommercialConfirmed] = useState(false);

  const getFirstValidationError: () => Optional<string> = () => {
    if (!hasCorrectCurrency) {
      return 'This subscription cannot be updated due to a currency mismatch';
    }
    if (hasOutstandingKeys) {
      return 'Please cancel outstanding keys to proceed';
    }
    if (!purchaseType && !adminMode) {
      return 'Please select whether you are purchasing as an individual or on behalf of a company.';
    }

    // compliance requirements (VAT) unless we have a trial plan
    if (!organization?.country && plan.family !== PlanFamily.TRIAL_PLANS) {
      return 'Please provide your address details (country).';
    }

    if (unsavedChanges.address) {
      return 'There are unsaved changes in the address form.';
    }
    if (unsavedChanges.tax) {
      return 'There are unsaved changes in the business details form.';
    }
    if (isCardRequired && !card) {
      return 'No payment method selected.';
    }

    // at this point all must-have requirements are met, skip other checks for admins
    if (adminMode) {
      return undefined;
    }

    if (purchaseType === OrganizationType.COMPANY) {
      if (!organization?.name) {
        return 'Company name is required.';
      }
      if (!organization?.tax_id) {
        return 'Valid tax ID is required.';
      }
      if (!organization?.state) {
        return 'State is required when purchasing on behalf of a company.';
      }
      if (!organization?.address || !organization?.zip || !organization?.city) {
        return 'Please complete your address details.';
      }
    }

    if (plan.family === PlanFamily.HOBBY_PLANS) {
      if (!isNonCommercialConfirmed) {
        return 'You have to confirm that you are using LocalStack for non-commercial purposes only.';
      }
    }

    // last check
    if (!agree) {
      return 'Please agree to our Terms and Conditions and Privacy Policy.';
    }

    return undefined;
  };

  const hasValidationError = !!getFirstValidationError();

  // update discount from promotion once available
  useEffect(() => {
    setDiscount(promotion || subscription?.discount || plan.discount);
  }, [promotion]);

  // inform listeners about account type change
  useEffect(() => {
    if (!organization) {
      return;
    }

    if (purchaseType && purchaseType !== organization.orgType) {
      onUpdateOrganization?.({ ...organization, orgType: purchaseType });
    }
  }, [purchaseType]);

  // propagate back seat selection
  useEffect(() => {
    onChangeSeats?.(seats);
  }, [seats]);

  // propagate back plan changes
  useEffect(() => {
    onChangePlan?.(plan);
  }, [plan]);

  // automatically expand the address form on user type selection whenever
  // plan changes with `purchaseType` set with missing address details
  useEffect(() => {
    if (!purchaseType || plan.family === PlanFamily.TRIAL_PLANS) {
      return;
    }

    setShowAddressForm(showAddressForm || !organization?.address);
  }, [purchaseType, plan.family]);

  const handleChangePlan = (newPlan: Plan) => {
    // if subscription is not set we default products and
    // discount selection based on selected plan
    if (!subscription) {
      setDiscount(newPlan.discount);
    }

    // set the plan itself
    setPlan(newPlan);

    // show products for selected plan
    setProducts(newPlan.products);
    // reset promo code as it may be scoped to specific plan/products
    setPromoCode(null);
    // reset card on plan change (in case there's a change in currency)
    setCard(filteredCards?.[0]);

    // reset promotion on plan change, since promotion can be scoped to
    // specific products/interval, and it's just easier for now to enforce
    // the user to type it again to re-trigger verification flow
    onApplyPromoCode?.(newPlan.id, null);

    // reset seats to 1 if hobby plan is selected
    if (newPlan.family === PlanFamily.HOBBY_PLANS) {
      setSeats(1);
    }
  };

  // inherit subscription properties once available or if changes (somehow)
  useEffect(() => {
    if (!subscription) {
      return;
    }

    // preselect subscription plan if available (note we look up over unscoped plans)
    const subscriptionPlan = filteredPlans.find((p) => p.id === subscription.plan.id);
    // fall back to superseding plan
    const supersedingPlan = filteredPlans.find(p => p.supersedes_plan_ids?.includes(subscription.plan.id));

    const planToPreselect = subscriptionPlan ?? supersedingPlan;
    if (planToPreselect) {
      setPlan(planToPreselect);
      setCard(filteredCards.find((c) => c.id === subscription.payment_id));
      setSeats(subscription.seats);
      setProducts(planToPreselect.products);
      setDiscount(subscription.discount);
    } else {
      handleChangePlan(filteredPlans[0] as Plan);
    }
  }, [subscription]);

  const onUpdateOverrides = (updates: Partial<OrderContext>) => setOverrides({ ...overrides, ...updates });

  return (
    <Grid container spacing={3}>
      {/* Administrative Area */}
      {adminMode && (
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Admin Customizations</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <ConstructorForm
                plan={plan}
                context={context}
                products={adminProducts ?? []}
                selectedProducts={products}
                discounts={adminDiscounts ?? []}
                selectedDiscount={discount}
                onUpdateContext={onUpdateOverrides}
                onUpdateDiscount={setDiscount}
                onUpdateProducts={setProducts}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      )}
      {subscription && subscription?.plan.id !== plan.id && (
        <Grid item xs={12}>
          <Alert severity="info">
            You are changing the current subscription Plan from{' '}
            <strong>{subscription.plan.name}</strong> to <strong>{plan.name}</strong>.
          </Alert>
        </Grid>
      )}
      {isLegacySubscription && hasCorrectCurrency && (
        <Grid item xs={12}>
          <Alert severity="warning">
            You are trying to modify a legacy subscription.
            To be modified subscription has to be switched to a newer Plan.
            You will be charged or credited a prorated amount in the next billing cycle.
          </Alert>
        </Grid>
      )}
      {isLegacySubscription && !hasCorrectCurrency && (
        <Grid item xs={12}>
          <Alert severity="warning">
            Unfortunately, the plan for this subscription can not be changed automatically.
            Please contact us at info@localstack.cloud for support.
          </Alert>
        </Grid>
      )}
      {/* Plan selection */}
      <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
        <Card variant="outlined" className={classes.card}>
          <CardHeader
            title="Select a Plan"
          />
          <PlansList
            tax={tax}
            plans={sortedPlans}
            selected={plan}
            onSelect={handleChangePlan}
            context={context}
          />
          <DecorativeDivider />
          <Box p={2}>
            <ActionTitle
              title={<Typography>
                {subscription && (
                  <>
                    New number of seats (previously {pluralizeOrDash(subscription.seats, 'seat', 'seats')})
                  </>
                )}
                {!subscription && (<>Seats</>)}
              </Typography>}
              actions={
                <IncrementButton
                  value={seats}
                  onSetValue={(value) => setSeats(Math.max(1, value))}
                  onIncrement={() => setSeats(seats + 1)}
                  onDecrement={() => seats > 1 ? setSeats(seats - 1) : null}
                  disabled={plan.family === PlanFamily.HOBBY_PLANS}
                />
              }
            />
          </Box>
        </Card>

        <Box mt={3} />

        {/* Org Toggle */}
        <Card>
          <CardHeader title="Order Information" />
          <CardContent>
            <Typography paragraph>
              Are you purchasing as an individual or on behalf of a company?
            </Typography>
            <ToggleButtonGroup
              exclusive
              className={classes.toggleButtonGroup}
              onChange={(_, value: OrganizationType) => setPurchaseType(value)}
              value={purchaseType}
            >
              <ToggleButton
                className={classes.toggleButton}
                value={OrganizationType.INDIVIDUAL}
                disabled={loading}
                color="primary"
              >
                <Box display="flex" alignItems="center">
                  <IndividualIcon /><Box mr={1} />
                  <span>Individual</span>
                </Box>
              </ToggleButton>
              <ToggleButton
                className={classes.toggleButton}
                value={OrganizationType.COMPANY}
                disabled={loading}
                color="primary"
              >
                <Box display="flex" alignItems="center">
                  <CompanyIcon /><Box mr={1} />
                  <span>Company</span>
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
          </CardContent>
          {purchaseType === OrganizationType.COMPANY && (
            <TaxForm
              loading={loading}
              taxData={organization ?? { name: '' }}
              onSubmit={onUpdateOrganization ?? noop}
              wrappers={{ ContentWrapper: CardContent, ActionsWrapper: CardActions }}
            />
          )}
        </Card>

        <Box mt={3} />

        {/* Billing Address */}
        <Card>
          <CardHeader
            title="Billing Address"
            action={
              <Button
                size="small"
                color="primary"
                startIcon={<EditIcon />}
                onClick={() => {
                  setShowAddressForm(!showAddressForm);
                  setUnsavedChanges({ ...unsavedChanges, address: !showAddressForm });
                }}
              >
                Edit Address
              </Button>
            }
          />
          {showAddressForm ? (
            <AddressForm
              loading={loading}
              showDiscardButton
              showName={organization?.orgType === OrganizationType.COMPANY}
              address={organization ?? {}}
              onSubmit={onUpdateOrganization ?? (() => null)}
              onDiscard={() => setShowAddressForm(false)}
              onDirty={(address) => setUnsavedChanges({ ...unsavedChanges, address })}
              wrappers={{ ContentWrapper: CardContent, ActionsWrapper: CardActions }}
            />
          ) : (
            <CardContent>
              {!organization?.address && (
                <Alert severity="info" variant="outlined">
                  Address is incomplete or not provided
                </Alert>
              )}
              {organization?.address && (
                <AddressPreview address={organization} />
              )}
            </CardContent>
          )}
        </Card>

        <Box mt={3} />

        {/* Payment Method */}
        <Card>
          <CardHeader title="Payment Method" />
          {isCardRequired ? (
            <>
              {!showCardForm && (
                <CreditCardsList
                  selectable
                  cards={filteredCards}
                  selected={card}
                  onSelect={setCard}
                  onDelete={onDeleteCard}
                />
              )}
              {showCardForm && renderCardForm && renderCardForm(plan.currency)}
              <Button fullWidth color="primary" variant="text" onClick={onToggleCardForm}>
                {showCardForm ? 'Cancel' : 'Add +'}
              </Button>
            </>
          ) : (
            <Alert severity="info">No payment method required</Alert>
          )}
        </Card>

        <Box mt={3} />

        {/* Hobby non-commercial */}
        {plan.family === PlanFamily.HOBBY_PLANS && (
          <Card>
            <CardHeader title="Non-commercial Use" />
            <CardContent>
              <Typography paragraph>
                LocalStack’s Hobby plan is explicitly for personal, non-corporate and non-commercial use only.
                Any commercial use is in direct violation of our Terms and Conditions.
                If you are unsure whether you qualify, please refer to our FAQ or reach out to us directly.
              </Typography>
              <Box display="flex" style={{ alignItems: 'flex-start' }}>
                <Checkbox
                  color='primary'
                  checked={isNonCommercialConfirmed}
                  onChange={(_, checked) => setIsNonCommercialConfirmed(checked)}
                />
                <Typography paragraph>
                  In checking this box I declare my usage under this account
                  to be non-commercial and agree to the Terms and Conditions
                  located <Link href={ExternalLink.LEGAL_TOS} target="_blank" underline="hover">here</Link>.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        )}

        <Box mt={3} />

        {/* Delete keys UI */}
        {subscription && isDowngrading && hasOutstandingKeys && (
          <Card>
            <CardHeader title="Reduce Number of Licenses" />
            <Alert severity="warning">
              Remove <b>{licensesUsed - context.seats}</b> assigned license(s) or API key(s) in order
              to reduce the number of seats from {subscription.seats} to {seats}.
            </Alert>
            <DowngradeLicensesList
              keys={subscriptionKeys ?? []}
              members={organization?.members}
              licenseAssignments={subscriptionLicenseAssignments ?? []}
              onDeleteApiKey={(id) => onDeleteApiKey?.(id)}
              onDeleteLicense={(id) => onDeleteLicense?.(id)}
            />
          </Card>
        )}

        {/* Footer */}
        <Box mt={2}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {/* Terms and Conditions */}
            {!adminMode && (
              <Box display="flex" alignItems="center" justifyContent="flex-end" mr={2}>
                <Box>
                  <Checkbox
                    color="primary"
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                  />
                </Box>
                <Typography>
                  <TosAgreementLine />
                </Typography>
              </Box>
            )}
            <ProgressButton
              color="primary"
              variant="contained"
              size="large"
              disabled={hasValidationError || loading || promotionLoading || taxLoading}
              loading={isSubmitting}
              onClick={() => onSubscribe?.(plan, products, context, subscription, discount)}
            >
              {subscription ? 'Update Terms' : 'Subscribe Now'}
            </ProgressButton>
          </Box>
          {hasValidationError && (
            <Box mt={1} textAlign="right">
              <Typography variant="body2" color="textSecondary">
                {getFirstValidationError()}
              </Typography>
            </Box>
          )}
          <Box mt={3}>
            <Typography variant="body1" align="right" component="div" color="textSecondary">
              You can manage your subscription or cancel it anytime from the{' '}
              <NewTabLink
                type='Link'
                href="/account/subscriptions"
              >subscriptions section of your account.</NewTabLink>
            </Typography>
          </Box>
        </Box>

      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <CardHeader title="Summary" />
        <ProductsList
          currency={plan.currency}
          products={products ?? []}
          context={context}
        />
        <List>
          <Divider component="li" className={classes.totalDivider} />
          <ListItem>
            <ListItemText
              primary={`${seats} Seat(s)`}
            />
            <ListItemSecondaryAction>
              <Typography>{planFormattedFullAmount}</Typography>
            </ListItemSecondaryAction>
          </ListItem>

          {/* Promo Code */}
          <Box display="flex">
            <Box flexGrow={1} mr={1}>
              <TextField
                label="Promo Code"
                variant="outlined"
                fullWidth
                value={promoCode || ''}
                onChange={(e) => setPromoCode(e.target.value || null)}
                InputProps={{
                  ...(promotionLoading && {
                    endAdornment: (
                      <InputAdornment position="start">
                        <CircularProgress size={12} />
                      </InputAdornment>
                    ),
                  }),
                }}
              />
            </Box>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => onApplyPromoCode?.(plan.id, promoCode)}
              disabled={promotionLoading}
            >
              Apply
            </Button>
          </Box>

          {discount && hasDiscount && (
            <>
              <ListItem className={classes.discount}>
                <ListItemText primary="Discount" secondary={discount.name} />
                <ListItemSecondaryAction className={classes.discount}>
                  <Typography color="inherit">
                    -{planFormattedDiscountAmount}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider component="li" className={classes.totalDivider} />
            </>
          )}
          {taxLoading && (
            <>
              <ListItem>
                <ListItemText primary={<Skeleton variant="text" />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Skeleton variant="text" />} />
              </ListItem>
              <ListItem>
                <ListItemText primary={<Skeleton variant="text" />} />
              </ListItem>
            </>
          )}
          {tax && (
            <>
              <ListItem>
                <ListItemText
                  primary={<>Subtotal{' '}{hasDiscount && <sup>*</sup>}</>}
                />
                <ListItemSecondaryAction>
                  <Typography>
                    {planFormattedDiscountedAmount}
                    {(isUpgrading || isDowngrading) && <sup>**</sup>}
                  </Typography>
                </ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary={`VAT (${tax.tax_rate}%)`} />
                <ListItemSecondaryAction className={classes.total}>
                  <Typography>{formatMonetaryAmount(tax.tax_amount, plan.currency)}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
            </>
          )}
          {!taxLoading && (
            <ListItem className={classes.total}>
              <ListItemText
                primary={
                  <>
                    {formatTaxableText('Total', tax, true)}{' '}{hasDiscount && <sup>*</sup>}
                  </>
                }
              />
              <ListItemSecondaryAction className={classes.total}>
                <Typography>
                  {planFormattedDiscountedAmountWithTax}
                  {(isUpgrading || isDowngrading) && <sup>**</sup>}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
          )}
        </List>

        {/* Terms and Conditions */}
        {!adminMode && (
          <Box display="flex" alignItems="center">
            <Box>
              <Checkbox
                color="primary"
                checked={agree}
                onChange={() => setAgree(!agree)}
              />
            </Box>
            <Typography>
              <TosAgreementLine />
            </Typography>
          </Box>
        )}
        <ProgressButton
          color="primary"
          variant="contained"
          size="large"
          fullWidth
          disabled={hasValidationError || taxLoading || loading || promotionLoading}
          loading={isSubmitting}
          onClick={() => onSubscribe?.(plan, products, context, subscription, discount)}
        >
          {subscription ? 'Update Terms' : 'Subscribe Now'}
        </ProgressButton>
        {hasValidationError && (
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary">
              {getFirstValidationError()}
            </Typography>
          </Box>
        )}
        <Box mt={2}>
          {discount && discount?.duration_months && discount?.duration_months !== -1 && (
            <Typography variant="caption" align="justify" color="textSecondary" component="div">
              *Please note the total displayed is subject to a discount.
              We will start charging the full amount of {planFormattedFullAmount}{' '}
              after the {formatIntervalMonths(discount.duration_months)}{' '}
              of your subscription
            </Typography>
          )}
          {(subscription && subscription.plan.id !== plan.id) || (isUpgrading || isDowngrading) && (
            <Typography variant="caption" align="justify" color="textSecondary" component="div">
              **Please note that in case of subscription upgrade or downgrade you will
              be charged a prorated amount in the next billing cycle
            </Typography>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
