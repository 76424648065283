import { ComputeInstanceImage, ComputeInstanceShape, SupportedImage } from '@localstack/types';

export const DEFAULT_INSTANCE_SHAPE: ComputeInstanceShape = {
  virtual_cpus: 1,
  memory_megabytes: 2048,
};

export const DEFAULT_INSTANCE_IMAGE: ComputeInstanceImage = {
  image_name: SupportedImage.localstack,
  tag: 'latest',
};

export const DEFAULT_INSTANCE_LIFETIME = 10;
export const MAX_INSTANCE_LIFETIME = 120;
