import { SubscriptionService, useApiGetter, useRoutes } from '@localstack/services';
import { Permission, PlanFamily, SubscriptionStatus } from '@localstack/types';
import { Button } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

import { STORAGE_KEY_IGNORE_EXPIRED_TRIAL } from '@localstack/constants';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks';

import { Banner } from '../Banner';


export const PlanUpgradeBanner = (): ReactElement => {

  const { can } = useAuthProvider();

  const { data: subscriptions } = useApiGetter(
    SubscriptionService,
    'listSubscriptions',
    [],
    { suppressErrors: true, enable: can(Permission.READ_SUBSCRIPTIONS) },
  );

  const [showTrialExpiredBanner, setShowTrialExpiredBanner] = useState<boolean>(false);

  const ignoredExpiredTrialBanner: boolean =
    JSON.parse(localStorage.getItem(STORAGE_KEY_IGNORE_EXPIRED_TRIAL) || 'false');
  const hasExpiredTrialPlan = subscriptions?.length === 1 && !!subscriptions?.some(sub =>
    sub.plan?.family === PlanFamily.TRIAL_PLANS && sub.status === SubscriptionStatus.CANCELED,
  );

  const { goto } = useRoutes();

  useEffect(() => {
    setShowTrialExpiredBanner(hasExpiredTrialPlan && !ignoredExpiredTrialBanner);
  }, [subscriptions]);

  if (showTrialExpiredBanner) {
    return (
      <Banner
        variant='rainbow'
        title='Your Trial has expired. Would you like to upgrade?'
        actions={
          <Button
            variant='outlined' style={{ color: 'white', borderColor: 'white' }}
            onClick={() => goto(AppRoute.PRICING)}
          >
            Upgrade
          </Button>
        }
        onClose={() => {
          localStorage.setItem(STORAGE_KEY_IGNORE_EXPIRED_TRIAL, JSON.stringify(true));
          setShowTrialExpiredBanner(false);
        }}
      />
    );
  }

  return <></>;
};

