import { Extension, LocalStackInstance, LocalStackInstanceType } from '@localstack/types';

export const getExtensionUiURL = (
  instance: Optional<LocalStackInstance>,
  extension: Optional<Extension>,
): string => {
  // probably a bit dirty but TODO clean up later once url format is more structured
  const isEphemeralInstance = instance?.instanceType === LocalStackInstanceType.EPHEMERAL_INSTANCE;

  if (isEphemeralInstance) { 
    return `${instance?.endpoint}/_extension/${extension?.plugin_name}/`;
  }

  const splitUrl = instance?.endpoint?.split('//');
  const protocol = splitUrl?.[0];
  const host = splitUrl?.[1];
  const extension_ui_url = `${protocol}//${extension?.plugin_name}.${host}`;
  return extension_ui_url;
};
