import { PATH_PREFIX_RESOURCES } from './base';

export const DEFAULT_NEPTUNE_ROUTES: Record<string, string> = {
  RESOURCES_NEPTUNE_INSTANCES: `${PATH_PREFIX_RESOURCES}/neptune/instances`,
  RESOURCES_NEPTUNE_INSTANCE: `${PATH_PREFIX_RESOURCES}/neptune/instances/:instanceId`,
  RESOURCES_NEPTUNE_INSTANCE_CREATE: `${PATH_PREFIX_RESOURCES}/neptune/instances/new`,
  RESOURCES_NEPTUNE_INSTANCE_UPDATE: `${PATH_PREFIX_RESOURCES}/neptune/instances/:instanceId/update`,
  RESOURCES_NEPTUNE_CLUSTER_CREATE: `${PATH_PREFIX_RESOURCES}/neptune/clusters/new`,
  RESOURCES_NEPTUNE_CLUSTER_UPDATE: `${PATH_PREFIX_RESOURCES}/neptune/clusters/:clusterId/update`,
  RESOURCES_NEPTUNE_CLUSTERS: `${PATH_PREFIX_RESOURCES}/neptune/clusters`,
  RESOURCES_NEPTUNE_CLUSTER: `${PATH_PREFIX_RESOURCES}/neptune/clusters/:clusterId`,
  RESOURCES_NEPTUNE_CLUSTER_GRAPH_BROWSER: `${PATH_PREFIX_RESOURCES}/neptune/graph-browser`,
  RESOURCES_NEPTUNE_CLUSTER_QUICK_ACTIONS: `${PATH_PREFIX_RESOURCES}/neptune/quick-actions`,
};
