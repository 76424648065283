import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes, useAwsGetter } from '@localstack/services';
import { QLDBCreateLedgerRequest, QLDBUpdateLedgerRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, QLDBLedgerForm } from '@localstack/ui';

import { QLDBProps } from './types';

export const QLDBLedgerUpsert = ({
  Layout,
  clientOverrides,
  routes,
}: QLDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();
  const title = `${name ? 'Update' : 'Create'} Ledger`;

  const { data: ledger } = useAwsGetter(
    'QLDB',
    'describeLedger',
    [{ Name: name }],
    { clientOverrides },
  );

  const { createLedger, updateLedger, isLoading } = useAwsEffect(
    'QLDB',
    ['createLedger', 'updateLedger'],
    { revalidate: ['listLedgers', 'describeLedger'], clientOverrides },
  );

  const handleCreate = async (data: QLDBCreateLedgerRequest) => {
    if (await createLedger(data)) {
      goto(routes.RESOURCES_QLDB_LEDGERS);
    }
  };

  const handleUpdate = async (data: QLDBUpdateLedgerRequest) => {
    if (await updateLedger(data)) {
      goto(routes.RESOURCES_QLDB_LEDGER, { name });
    }
  };

  return (
    <Layout
      documentTitle={`QLDB: ${title}`}
      title={
        <Box>
          <Typography variant="h4">{title}</Typography>
          <Breadcrumbs
            mappings={[
              ['QLDB', () => goto(routes.RESOURCES_QLDB_LEDGERS)],
              [name, () => goto(routes.RESOURCES_QLDB_LEDGER, { name })],
              [name ? 'Update Ledger' : 'Create Ledger', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateQLDBLedger"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <QLDBLedgerForm
            ledger={ledger}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            clientOverrides={clientOverrides}
            formId="CreateQLDBLedger"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateQLDBLedger"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
