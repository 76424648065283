import { ReactElement } from 'react';
import { CognitoUser } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface CognitoUserDetailsProps {
  user?: Optional<Partial<CognitoUser>>;
}

export const CognitoUserDetails = ({ user }: CognitoUserDetailsProps): ReactElement => (
  <MagicDetails
    data={user}
    schema={getSchema('CognitoIdentityServiceProvider')}
    entry="AdminGetUserResponse"
    title="Cognito User Details"
  />
);
