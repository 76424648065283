import { MuiThemeComponentsType } from '../types';

export default (): MuiThemeComponentsType['MuiListItem'] => ({
  styleOverrides: {
    root: {
      paddingTop: '4px',
      paddingBottom: '4px',
    },
  },
});
