import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { User } from '@localstack/types';

const STATE_MAP = {
  'signed_up': 'Signed Up',
  'activated': 'Activated',
  'deleted': 'Deleted',
  'unknown': 'Unknown',
};

const getUserState = (user: User) => {
  if (user.deleted) return 'deleted';
  if (!user.activated) return 'signed_up';
  if (user.activated) return 'activated';

  return 'unknown';
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  dot: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(.5),
    marginRight: theme.spacing(1),
  },
  signedUp: {
    backgroundColor: theme.palette.info.main,
  },
  activated: {
    backgroundColor: theme.palette.success.main,
  },
  deleted: {
    backgroundColor: theme.palette.error.main,
  },
  unknown: {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  user: User;
}

export const UserState = ({ user }: Props): ReactElement => {
  const classes = useStyles();

  const state = getUserState(user);

  return (
    <span>
      <span
        className={classnames(classes.dot, {
          [classes.signedUp]: state === 'signed_up',
          [classes.activated]: state === 'activated',
          [classes.deleted]: state === 'deleted',
          [classes.unknown]: state === 'unknown',
        })}
      />
      {STATE_MAP[state]}
    </span>
  );
};

export default UserState;
