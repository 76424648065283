const HOSTED_ZONE_DISABLED_RECORD_TYPES = ['NS', 'SOA'];

const HOSTED_ZONE_RECORD_TYPES = [
  'A', 'AAAA', 'CAA', 'CNAME', 'DS', 'X', 'NAPTR', 'NS', 'PTR', 'SOA', 'SPF', 'SRV', 'TXT',
];

const ROUTING_POLICIES = {
  SIMPLE: 'Simple',
  FAILOVER: 'Failover',
};

export {
  HOSTED_ZONE_DISABLED_RECORD_TYPES,
  HOSTED_ZONE_RECORD_TYPES,
  ROUTING_POLICIES,
};
