import { DEFAULT_EKS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, EKSClusterDetail } from '@localstack/ui';

import { EKSProps } from './types';

export const EKSCluster = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EKS_ROUTES,
}: EKSProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: cluster } = useAwsGetter(
    'EKS',
    'describeCluster',
    [{ name: id }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">EKS Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['EKS', () => goto(routes.RESOURCES_EKS_CLUSTERS)],
              [id, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_EKS_CLUSTER_UPDATE, { id })}
        >
          Edit Cluster
        </Button>
      }
    >
      <Card>
        <EKSClusterDetail cluster={cluster} />
      </Card>
    </Layout>
  );
};
