import { useState, useCallback, ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Dropdown, ConfirmableButton, CloudWatchLogGroupsTable, PageTitle } from '@localstack/ui';
import { DEFAULT_CLOUDWATCH_ROUTES } from '@localstack/constants';

import { CloudWatchProps } from './types';

export const CloudWatchLogGroups = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDWATCH_ROUTES,
}: CloudWatchProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const { data: logGroups, isLoading: isGroupsLoading, mutate } = useAwsGetter(
    'CloudWatchLogs',
    'describeLogGroups',
    [],
    { clientOverrides },
  );

  const { deleteLogGroup, isLoading: isGroupMutating } = useAwsEffect(
    'CloudWatchLogs',
    ['deleteLogGroup'],
    { revalidate: ['describeLogGroups'], clientOverrides },
  );

  const isLoading = isGroupsLoading || isGroupMutating;

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((logGroupName) => deleteLogGroup({ logGroupName }))),
    [selected],
  );

  return (
    <Layout
      title={
        <PageTitle
          title="CloudWatch Log Groups"
          onMutate={mutate}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_CLOUDWATCH_GROUP_CREATE)}>
            Create Log Group
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} group(s)?`}
              onClick={handleDeleteObjects}
              text="Selected groups will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CloudWatchLogGroupsTable
          selectable
          logGroups={logGroups?.logGroups || []}
          onViewLogGroup={({ logGroupName }) =>
            goto(routes.RESOURCES_CLOUDWATCH_GROUP_STREAMS, { logGroupName })
          }
          onSelect={setSelected}
        />
      </Card>
    </Layout>
  );
};
