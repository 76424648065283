import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { GlueRoutes } from '../../types';

type MainNavTabsProps = {
  routes: GlueRoutes;
}

export const MainNavTabs = ({ routes }: MainNavTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_DATABASES)}>
      Databases
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_TABLES)}>
      Tables
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_CONNECTIONS)}>
      Connections
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_CRAWLERS)}>
      Crawlers
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_JOBS)}>
      Jobs
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_REGISTRIES)}>
      Schema Registries
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_SCHEMAS)}>
      Schemas
    </NavLink>
  </>
);
