import { ReactElement } from 'react';
import { ECSService } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface ECSServiceDetailsProps {
  service?: Optional<Partial<ECSService>>;
}

export const ECSServiceDetails = ({ service }: ECSServiceDetailsProps): ReactElement => (
  <MagicDetails
    data={service}
    schema={getSchema('ECS')}
    entry="Service"
    title="Service Details"
  />
);
