import { getSchema } from '@localstack/services';
import { IdentityPool, CreateIdentityPoolInput } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type CognitoIdentityIdentityPoolFormProps = {
  identityPool?: Optional<Partial<IdentityPool>>;
  formId: string;
  onSubmit: (data: CreateIdentityPoolInput | IdentityPool) => void;
  loading?: boolean;
};

export const CognitoIdentityPoolForm = ({
  identityPool,
  formId,
  onSubmit,
  loading,
}: CognitoIdentityIdentityPoolFormProps): ReactElement => (
  <MagicForm
    entry={identityPool ? 'IdentityPool' : 'CreateIdentityPoolInput'}
    schema={getSchema('CognitoIdentity')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={identityPool}
    fieldConditions={identityPool ? {
      IdentityPoolId: false,
      IdentityPoolName: false,
      AllowClassicFlow: false,
    } : { AllowClassicFlow: false }}
  />
);
