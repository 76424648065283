/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CIProject } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CiService {

    /**
     * @returns CIProject
     * @throws ApiError
     */
    public static listCiProjects(): CancelablePromise<Array<CIProject>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/ci/projects',
        });
    }

    /**
     * @param requestBody
     * @returns CIProject
     * @throws ApiError
     */
    public static createCiProject(
        requestBody?: CIProject,
    ): CancelablePromise<CIProject> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/ci/projects',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectName
     * @returns CIProject
     * @throws ApiError
     */
    public static getCiProject(
        projectName: any,
    ): CancelablePromise<CIProject> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/ci/projects/{project_name}',
            path: {
                'project_name': projectName,
            },
        });
    }

    /**
     * @param projectName
     * @param requestBody
     * @returns CIProject
     * @throws ApiError
     */
    public static storeCiProject(
        projectName: any,
        requestBody?: CIProject,
    ): CancelablePromise<CIProject> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/ci/projects/{project_name}',
            path: {
                'project_name': projectName,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param projectName
     * @returns CIProject
     * @throws ApiError
     */
    public static deleteCiProject(
        projectName: any,
    ): CancelablePromise<CIProject> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/ci/projects/{project_name}',
            path: {
                'project_name': projectName,
            },
        });
    }

    /**
     * @param projectName
     * @param runName
     * @param direct
     * @returns any
     * @throws ApiError
     */
    public static getCiRunLogs(
        projectName: any,
        runName: any,
        direct?: any,
    ): CancelablePromise<{
        url?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/ci/projects/{project_name}/runs/{run_name}/logs',
            path: {
                'project_name': projectName,
                'run_name': runName,
            },
            query: {
                'direct': direct,
            },
        });
    }

}
