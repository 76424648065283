import { ReactElement } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Card, CardContent, Typography, Button, Link } from '@mui/material';
import { AnimatedLogo } from '@localstack/ui';

import { BaseLayout } from '~/layouts';
import { AppRoute } from '~/config';

type Props = {
  message: string | JSX.Element;
  activated?: boolean;
  activationDisabled?: boolean;
  activationText?: string | JSX.Element;
  onActivation?: () => void | Promise<void>;
};

export const BaseActivation = ({
  message,
  activated,
  activationDisabled,
  activationText,
  onActivation,
}: Props): ReactElement => {
  const theme = useTheme();

  return (
    <BaseLayout hideNavigation>
      <Box
        textAlign="center"
        margin="auto"
        padding={3}
        width={theme.breakpoints.values.sm}
        maxWidth='100%'
      >
        <Link href={AppRoute.SIGN_IN} underline="hover">
          <AnimatedLogo animation={false} />
        </Link>
        <Card>
          <CardContent>
            <Typography component="div">{message}</Typography>
            {!activated && activationText && onActivation && (
              <Box mt={3}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onActivation}
                  disabled={activationDisabled}
                >
                  {activationText}
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </BaseLayout>
  );
};
