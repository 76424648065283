import { ReactElement } from 'react';
import { Box, Typography, Grid , Skeleton } from '@mui/material';
import { Organization, Subscription, ApiKey } from '@localstack/types';

import { OrganizationForm } from '../OrganizationForm';

export interface OrganizationDetailsProps {
  organization: Organization;
  subscriptions: Subscription[];
  keys: ApiKey[];
  loading?: boolean;
  onUpdate: (orgId: string, details: Organization) => unknown;
}

export const OrganizationDetailsSkeleton = (): ReactElement => (
  <>
    <Box display="flex" justifyContent="space-between">
      <Skeleton width={90} />
      <Skeleton width={90} />
      <Skeleton width={90} />
      <Skeleton width={90} />
    </Box>
    <Box mt={3}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Skeleton height={54} variant="rectangular" />
        </Grid>
      </Grid>
    </Box>
  </>
);

export const OrganizationDetails = ({
  organization,
  subscriptions,
  keys,
  loading,
  onUpdate,
}: OrganizationDetailsProps): ReactElement => (
  <>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexWrap="wrap"
    >
      <Typography>
        <strong>Stripe:&nbsp;</strong>
        {organization.stripe_customer ? 'Yes' : 'No'}
      </Typography>
      <Typography>
        <strong># Members:&nbsp;</strong>
        {organization.members?.length || 0}
      </Typography>
      <Typography>
        <strong># Subscriptions:&nbsp;</strong>
        {subscriptions.length}
      </Typography>
      <Typography>
        <strong># API Keys:&nbsp;</strong>
        {keys.length}
      </Typography>
    </Box>

    <Box mt={4}>
      <OrganizationForm
        organization={organization}
        loading={loading}
        onUpdate={onUpdate}
      />
    </Box>
  </>
);
