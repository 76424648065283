import { useState, useCallback, ReactElement } from 'react';
import { Button, Card } from '@mui/material';

import {
  BackupPlansTable,
  Dropdown,
  ConfirmableButton,
  LoadingFragment,
  PageTitle,
} from '@localstack/ui';

import {
  useAwsEffect,
  useAwsGetter,
  useAwsBatchIterator,
  useRoutes,
} from '@localstack/services';

import { DEFAULT_BACKUP_ROUTES } from '@localstack/constants/src';

import { DetailsNavTabs } from './components';
import { BackupProps } from './types';

export const BackupPlans = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_BACKUP_ROUTES,
}: BackupProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

  const { getBackupPlan } = useAwsEffect('Backup', ['getBackupPlan'], { clientOverrides });

  const {
    deleteBackupPlan,
    isLoading: isPlansMutating,
  } = useAwsEffect(
    'Backup',
    ['deleteBackupPlan'],
    { revalidate: ['listBackupPlans', 'getBackupPlan'], clientOverrides },
  );

  const { data: planSummaries, isLoading: isPlansLoading, mutate } =
    useAwsGetter('Backup', 'listBackupPlans', [], { clientOverrides });

  const backupPlans = useAwsBatchIterator(
    planSummaries?.BackupPlansList,
    (summary) => getBackupPlan({ BackupPlanId: summary.BackupPlanId as string }),
  );

  const isLoading = isPlansMutating || isPlansLoading;

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selectedObjects.map((BackupPlanId) => deleteBackupPlan({ BackupPlanId }))),
    [selectedObjects],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="Backup Plans"
            onMutate={mutate}
          />
        }
        tabs={<DetailsNavTabs routes={routes} />}
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_BACKUP_PLAN_CREATE)}>
              Create
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedObjects.length === 0 || isLoading}
                title={`Remove ${selectedObjects.length} object(s)?`}
                onClick={handleDeleteObjects}
                text="Selected Objects will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <BackupPlansTable
              plans={backupPlans}
              onSelect={setSelectedObjects}
              onViewBackupPlan={(plan) => goto(
                routes.RESOURCES_BACKUP_PLAN,
                { id: plan.BackupPlanId as string },
              )}
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
