import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, ECSClusterDetails } from '@localstack/ui';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

import { ClusterNavTabs } from './components';


export const ECSCluster = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterName } = useParams<'clusterName'>();

  const { data: clusters } = useAwsGetter(
    'ECS',
    'describeClusters',
    [{ clusters: [clusterName as string] }],
    { clientOverrides },
  );
  const cluster = clusters?.clusters?.find((clust) => clust.clusterName === clusterName);

  return (
    <Layout
      documentTitle="Cluster Details"
      tabs={<ClusterNavTabs clusterName={clusterName as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ECS', () => goto(routes.RESOURCES_ECS)],
              ['Clusters', () => goto(routes.RESOURCES_ECS_CLUSTERS)],
              [clusterName, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_ECS_CLUSTER_UPDATE, { clusterName })}>
          Edit Cluster
        </Button>
      }
    >
      <Card>
        <CardContent>
          <ECSClusterDetails cluster={cluster} />
        </CardContent>
      </Card>
    </Layout>
  );
};
