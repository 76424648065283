import { ReactElement } from 'react';
import { CognitoGroup } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface CognitoGroupDetailsProps {
  group?: Optional<Partial<CognitoGroup>>;
}

export const CognitoGroupDetails = ({ group }: CognitoGroupDetailsProps): ReactElement => (
  <MagicDetails
    data={group}
    schema={getSchema('CognitoIdentityServiceProvider')}
    entry="GroupType"
    title="Group Details"
  />
);
