import { ReactElement } from 'react';
import classnames from 'classnames';
import { ProductType } from '@localstack/types';
import { 
  Group as SeatIcon, 
  DataUsage as CIUsageIcon, 
  Storage as PodUsageIcon, 
  ViewInAr as InstanceIcon, 
} from '@mui/icons-material';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { Avatar } from '@mui/material';

export interface PlanProductIconProps {
  productType: ProductType;
  size?: 'medium' | 'small';
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    background: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.light} 100%)`,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  medium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    '& svg': {
      width: theme.spacing(2),
    },
  },
}));

export const PlanProductIcon = ({ productType, size = 'medium' }: PlanProductIconProps): ReactElement => {
  const classes = useStyles();

  const iconsMap = {
    [ProductType.SEATS]: SeatIcon,
    [ProductType.CI_USAGE]: CIUsageIcon,
    [ProductType.POD_USAGE]: PodUsageIcon,
    [ProductType.COMPUTE_USAGE]: InstanceIcon,
  };

  const Icon = iconsMap[productType] ?? iconsMap[ProductType.SEATS];

  return (
    <Avatar
      variant="rounded"
      className={
        classnames(classes.root, {
          [classes.medium]: size === 'medium',
          [classes.small]: size === 'small',
        })
      }
    >
      <Icon />
    </Avatar>
  );
};
