export const GremlinNodeColor = '#00bcd4';
export const GremlinNodeColorAccent = '#f50057';

export const NODE_EDGES_SCHEMA = {
  shapes: {
    EdgeList: {
      type: 'structure',
      members: {
        source: { type: 'string' },
        label: { type: 'string' },
        destination: { type: 'string' },
        edgeId: { type: 'string' },
        sourceId: { type: 'string' },
        destinationId: { type: 'string' },
      },
    },
  },
};

export const GREMLIN_ITEM_SCHEMA = {
  shapes: {
    ItemAttribute: {
      type: 'structure',
      members: {
        key: {
          type: 'string',
        },
        value: {
          type: 'string',
        },
      },
    },
    AttributeList: {
      type: 'list',
      member: {
        shape: 'ItemAttribute',
      },
    },
    GraphItem: {
      type: 'structure',
      members: {
        id: { type: 'string' },
        label: { type: 'string' },
        attributes: {
          shape: 'AttributeList',
        },
      },
    },
  },
};

export const GREMLIN_ACTION_SCHEMA = {
  shapes: {
    AttributeKey: {
      type: 'string',
    },
    AttributeValue: {
      type: 'string',
    },
    AttributeMap: {
      type: 'map',
      key: {
        shape: 'AttributeKey',
        locationName: 'Key',
      },
      value: {
        shape: 'AttributeValue',
        locationName: 'Value',
      },
      flattened: true,
      locationName: 'Attribute',
    },
    NodeInsertForm: {
      type: 'structure',
      required: ['label'],
      members: {
        label: {
          type: 'string',
          documentation: '<p>The label associated with the vertex</p>',
        },
        attributes: {
          shape: 'AttributeMap',
          documentation: '<p>A list of attributes associated with the vertex</p>',
          locationName: 'Attribute',
        },
      },
    },
    NodeUpdateForm: {
      type: 'structure',
      required: ['id'],
      members: {
        id: {
          type: 'string',
          documentation: '<p>The ID of the vertex to update</p>',
        },
        attributes: {
          shape: 'AttributeMap',
          documentation: '<p>A list of attributes associated with the vertex</p>',
          locationName: 'Attribute',
        },
      },
    },
    EdgeInsertForm: {
      type: 'structure',
      required: ['label', 'sourceVertexId', 'targetVertexId'],
      members: {
        label: {
          type: 'string',
          documentation: '<p>The label associated with the edge</p>',
        },
        sourceVertexId: {
          type: 'string',
          documentation: '<p>The ID of the source vertex</p>',
        },
        targetVertexId: {
          type: 'string',
          documentation: '<p>The ID of the target vertex</p>',
        },
        attributes: {
          shape: 'AttributeMap',
          documentation: '<p>A list of attributes associated with the vertex</p>',
          locationName: 'Attribute',
        },
      },
    },
  },
};
