import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, IAMRolesTable, PageTitle } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMRoles = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: roles, isLoading, mutate } = useAwsGetter('IAM', 'listRoles', [], { clientOverrides });

  const { deleteRole } = useAwsEffect(
    'IAM',
    ['deleteRole'],
    { revalidate: ['getRole', 'listRoles'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((RoleName) => deleteRole({ RoleName }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={
        <PageTitle
          title="IAM Roles"
          onMutate={mutate}
        />
      }
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_IAM_ROLE_CREATE)}>
            Create Role
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Role(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Roles will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <IAMRolesTable
          selectable
          loading={isLoading}
          roles={roles?.Roles || []}
          onSelect={setSelectedIds}
          onViewRole={
            ({ RoleName }) => goto(routes.RESOURCES_IAM_ROLE, { roleName: RoleName })
          }
        />
      </Card>
    </Layout>
  );
};
