import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const CLOUDTRAIL_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.CLOUDTRAIL}`;

export const DEFAULT_CLOUDTRAIL_ROUTES: Record<string,string> = {
  RESOURCES_CLOUDTRAIL_EVENTS: `${CLOUDTRAIL_BASE_ROUTE}/events`,
  RESOURCES_CLOUDTRAIL_TRAILS: `${CLOUDTRAIL_BASE_ROUTE}/trails`,
  RESOURCES_CLOUDTRAIL_TRAIL: `${CLOUDTRAIL_BASE_ROUTE}/trails/:name`,
  RESOURCES_CLOUDTRAIL_TRAIL_CREATE: `${CLOUDTRAIL_BASE_ROUTE}/trail/new`,
  RESOURCES_CLOUDTRAIL_EVENT: `${CLOUDTRAIL_BASE_ROUTE}/events/:id`,
};
