import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AgwRestApiRequestValidator } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiValidatorsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  validators: AgwRestApiRequestValidator[];
  onViewValidator?: (validator: AgwRestApiRequestValidator) => unknown;
  onSelect?: (ids: string[]) => void;
};

export const AgwRestApiValidatorsTable = ({
  validators,
  selectable = true,
  loading,
  onViewValidator,
  onSelect,
}: AgwRestApiValidatorsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="RequestValidator"
    idAttribute="id"
    rows={validators}
    selectable={selectable}
    onSelect={onSelect}
    order={['id', 'name']}
    loading={loading}
    externalFields={{
      id: (row) => (
        <Link onClick={() => onViewValidator && onViewValidator(row)} underline="hover">
          {row.id}
        </Link>
      ),
    }}
  />
);
