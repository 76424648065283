import { S3Object } from '@localstack/types';

/**
 * Sometimes folders are not created explcitly but are part of object keys,
 * in such cases we are creating folders by ourselves.
 *
 * Note it's still hard to distinguish between zero-byte objects and folders,
 */
export const expandS3Folders = (objects: S3Object[], prefix?: Optional<string>): S3Object[] => {
  const folders = objects.filter((o) => o.Size === 0);
  const nonFolders = objects.filter((o) => o.Size !== 0);

  // build increasing paths out of object keys
  const extendedFolders = objects.reduce(
    (memo, obj) => {
      const parts = (obj.Key ?? '').split('/').slice(0, obj.Size === 0 ? undefined : -1).filter((part) => part !== '');
      const paths = parts.reduce((pathsMemo, part) => [...pathsMemo, [...(pathsMemo.slice(-1)[0] ?? []), part]], []);
      return [...memo, ...paths.map((path) => ({ Key: path.join('/'), Size: 0, LastModified: obj.LastModified })) ];
    }, folders,
  );

  // set to keep track of already included folders (to avoid in-loop lookups)
  const foldersRegistry = new Set();

  // deduplicated folders (doing it outside to keep complexity [almost] linear)
  const uniqueFolders = extendedFolders.reduce((memo, object) => {
    if (!foldersRegistry.has(object.Key)) {
      foldersRegistry.add(object.Key);
      return [...memo, object];
    }

    return memo;
  }, []);

  // return sub-folders starting from the selected folder, excluding itself
  const filteredFolders = uniqueFolders.filter(
    (o) => {
      const objectName = o.Key?.endsWith('/') ? o.Key.slice(0, -1) : o.Key;
      return !prefix || (objectName?.startsWith(prefix) && objectName !== prefix);
    },
  );

  return [...filteredFolders, ...nonFolders];
};
