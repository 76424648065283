import { ReactElement } from 'react';
import { BackdoorSESMessage } from '@localstack/types';
import { ListItem } from '@mui/material';

import { MagicDetails } from '../../magic/MagicDetails';
import { CodeSnippetViewer } from '../../../display';

export interface SESMessageDetailsProps {
  message?: Optional<Partial<BackdoorSESMessage>>;
}

const SCHEMA = {
  shapes: {
    BackdoorSESMessage: {
      type: 'structure',
      members: {
        Id: {
          type: 'string',
        },
        Timestamp: {
          type: 'timestamp',
        },
        Region: {
          type: 'string',
        },
        Source: {
          type: 'string',
        },
        'Destination.ToAddresses': {
          type: 'list',
          member: {
            shape: 'SesAddress',
          },
        },
        'Destination.CcAddresses': {
          type: 'list',
          member: {
            shape: 'SesAddress',
          },
        },
        'Destination.BccAddresses': {
          type: 'list',
          member: {
            shape: 'SesAddress',
          },
        },
        Subject: {
          type: 'string',
        },
        'Body.text_part': {
          type: 'string',
        },
        'Body.html_part': {
          type: 'string',
        },
      },
    },
    BackdoorSESRawMessage: {
      type: 'structure',
      members: {
        Id: {
          type: 'string',
        },
        Timestamp: {
          type: 'timestamp',
        },
        Region: {
          type: 'string',
        },
        Source: {
          type: 'string',
        },
        RawData: {
          type: 'string',
        },
      },
    },
    SesAddress: {
      type: 'string',
    },
  },
};

export const SESMessageDetails = ({ message }: SESMessageDetailsProps): ReactElement => (
  <MagicDetails
    key={message?.Id}
    data={message}
    schema={SCHEMA}
    entry={message?.RawData ? 'BackdoorSESRawMessage' : 'BackdoorSESMessage'}
    title="Message Details"
    externalFields={{
      '^RawData$': (data: string, fieldName: string) => (
        <ListItem key={fieldName}>
          <CodeSnippetViewer data={data} fieldName={fieldName} disableParsing />
        </ListItem>
      ),
    }}
  />
);
