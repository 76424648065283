import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueSchemaVersionDetails as Details } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { SchemaVersionTabs } from './components';

export const GlueSchemaVersionDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { schema, version, registry } = useParams<'schema' | 'version' | 'registry'>();

  const { data: schemaVersionData } = useAwsGetter(
    'Glue', 'getSchemaVersion',
    [{
      SchemaId: { SchemaName: schema, RegistryName: registry },
      SchemaVersionNumber: { VersionNumber: Number(version ?? 0) },
    }], { clientOverrides });
  return (
    <Layout
      documentTitle="Glue: Schema Version Details"
      tabs={
        <SchemaVersionTabs routes={routes} schema={schema ?? ''} version={version ?? ''} registry={registry ?? ''} />
      }
      title={
        <Box>
          <Typography variant="h4">Schema Version Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Schemas', () => goto(routes.RESOURCES_GLUE_SCHEMAS)],
              [schema, () => goto(routes.RESOURCES_GLUE_SCHEMA, { schema, registry })],
              ['Schema Versions', () => goto(routes.RESOURCES_GLUE_SCHEMA_VERSIONS, { schema, registry })],
              [version, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <Details schemaVersion={schemaVersionData} />
        </CardContent>
      </Card>
    </Layout>
  );
};
