import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { CognitoRoutes } from '../../types';

interface Props {
  poolId: string;
  username: string;
  routes: CognitoRoutes;
}

export const UserNavTabs = ({ poolId, username, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_COGNITO_POOL_USER, { poolId, username })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_COGNITO_POOL_USER_GROUPS, { poolId, username })}>
      Groups
    </NavLink>
  </>
);
