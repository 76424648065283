import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const OPENSEARCH_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.OPENSEARCH}`;

export const DEFAULT_OPENSEARCH_ROUTES: Record<string,string> = {
  RESOURCES_OPENSEARCH_DOMAINS: `${OPENSEARCH_BASE_ROUTE}/domains`,
  RESOURCES_OPENSEARCH_DOMAIN_CREATE: `${OPENSEARCH_BASE_ROUTE}/domain/new`,
  RESOURCES_OPENSEARCH_DOMAIN: `${OPENSEARCH_BASE_ROUTE}/domain/:domainName`,
  RESOURCES_OPENSEARCH_DOMAIN_UPDATE: `${OPENSEARCH_BASE_ROUTE}/domain/:domainName/update`,
};
