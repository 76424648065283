import { ReactElement } from 'react';
import { MenuItem } from '@mui/material';
import { ECSService, ECSTaskDefinition, CreateECSServiceParams, UpdateECSServiceParams } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledSelect } from '../../../form';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface ECSServiceFormProps {
  cluster: string;
  service?: Optional<Partial<ECSService>>;
  definitions: ECSTaskDefinition[];
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateECSServiceParams) => unknown;
  onUpdate: (data: UpdateECSServiceParams) => unknown;
}

export const ECSServiceForm = ({
  cluster,
  service,
  definitions,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: ECSServiceFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('ECS')}
    loading={loading}
    entry={service ? 'UpdateServiceRequest' : 'CreateServiceRequest'}
    data={service}
    formId={formId}
    fieldConditions={{
      '^cluster': false,
      '^service': !service,
      '^serviceName': !service,
    }}
    externalFields={{
      '^taskDefinition': (control, name) => (
        <ControlledSelect
          variant="outlined"
          control={control}
          fullWidth
          label="Task Definition"
          name={name}
          options={
            definitions.map(
              (d) => (
                <MenuItem key={d.taskDefinitionArn} value={d.taskDefinitionArn}>
                  {d.taskDefinitionArn}
                </MenuItem>
              ),
            )
          }
        />
      ),
      '^role$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='IAM' method='listRoles' arrayKeyName='Roles' property='Arn'
          fieldName={fieldName} entityName='IAM Role'
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateECSServiceParams) => {
      if (!service) return onCreate({ ...data, cluster });
      return onUpdate({ ...data, cluster, service: service.serviceName as string });
    }}
  />
);
