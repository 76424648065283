import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { ListCertificatesResponse } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type ACMCertificatesTableProps = {
  certificates: ListCertificatesResponse;
  onViewCertificate: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const ACMCertificatesTable = ({
  certificates,
  onViewCertificate,
  onSelect,
  loading,
}: ACMCertificatesTableProps): ReactElement => (
  <MagicTable
    entry="CertificateSummary"
    idAttribute="CertificateArn"
    rows={certificates?.CertificateSummaryList || []}
    schema={getSchema('ACM')}
    externalFields={{
      DomainName: ({ DomainName, CertificateArn }) => (
        <Link onClick={() => onViewCertificate(CertificateArn || '')} underline="hover">
          {DomainName}
        </Link>
      ),
    }}
    order={['DomainName']}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
