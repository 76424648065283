import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AppSyncDataSourceDetails } from '@localstack/ui';


import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiDataSource = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps):ReactElement => {
  const { goto } = useRoutes();
  const { apiId, name } = useParams<'apiId' | 'name'>();

  const { data: dataSource } = useAwsGetter('AppSync', 'getDataSource', [{ apiId, name }],{ clientOverrides });

  return (
    <Layout
      documentTitle="Data Source Details"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Data Source Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              [name, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button 
          onClick={() => goto(
            routes.RESOURCES_APPSYNC_API_DATA_SOURCE_UPDATE, 
            { apiId, name },
          )}
        >
          Edit Data Source
        </Button>
      }
    >
      <Card>
        <CardContent>
          <AppSyncDataSourceDetails dataSource={dataSource?.dataSource} />
        </CardContent>
      </Card>
    </Layout>
  );
};
