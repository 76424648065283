/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthLink } from '@localstack/types';
import type { AuthToken } from '@localstack/types';
import type { CombinedUserInfo } from '@localstack/types';
import type { CreditCard } from '@localstack/types';
import type { HubspotToken } from '@localstack/types';
import type { Notification } from '@localstack/types';
import type { TosAcceptanceContext } from '@localstack/types';
import type { User } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static signinUser(
        requestBody?: {
            username: string;
            password: string;
        },
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/signin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static refreshUserToken(
        requestBody?: AuthToken,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/user/signin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns AuthToken
     * @throws ApiError
     */
    public static finalizeSso(
        requestBody?: {
            code: string;
            redirect_uri: string;
        },
    ): CancelablePromise<AuthToken> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/sso/finalize',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Signed out successfully
     * @throws ApiError
     */
    public static signoutUser(
        requestBody?: {
            token?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/signout',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Signed out successfully
     * @throws ApiError
     */
    public static globalSignoutUser(
        requestBody?: {
            token?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/global-signout',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns CombinedUserInfo
     * @throws ApiError
     */
    public static getUser(): CancelablePromise<CombinedUserInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/user',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static updateAccount(
        requestBody?: User,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/user',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static signupUser(
        requestBody?: {
            email: string;
            password: string;
            firstname?: string;
            lastname?: string;
            howFound?: string;
            newsletter?: boolean;
            recaptcha_token: string;
            tos_acceptance_context: TosAcceptanceContext;
            company?: string;
            job_title?: string;
            github_username?: string;
            signup_survey?: {
                planned_usage_type?: string;
                create_sub?: boolean;
            };
        },
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static rememberWorkspace(
        requestBody?: {
            workspace_id: string;
        },
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/workspace',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static setDefaultWorkspace(
        requestBody?: {
            workspace_id: string;
        },
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/user/workspace',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns User
     * @throws ApiError
     */
    public static leaveWorkspace(
        requestBody?: {
            workspace_id: string;
        },
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/user/workspace',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Password updated successfully
     * @throws ApiError
     */
    public static updatePassword(
        requestBody?: {
            old_password: string;
            password: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/user/password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Password reset link sent
     * @throws ApiError
     */
    public static requestPasswordReset(
        requestBody?: {
            email: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/recover',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Password reset confirmed
     * @throws ApiError
     */
    public static confirmPasswordReset(
        requestBody?: {
            user_id: string;
            recovery_key: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/user/recover',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Activation email sent
     * @throws ApiError
     */
    public static resendActivation(
        requestBody?: {
            email: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/resend',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Account activated
     * @throws ApiError
     */
    public static activateAccount(
        requestBody?: {
            activation_key?: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/activate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param currency
     * @returns CreditCard
     * @throws ApiError
     */
    public static listCreditCards(
        currency?: any,
    ): CancelablePromise<Array<CreditCard>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/user/cards',
            query: {
                'currency': currency,
            },
        });
    }

    /**
     * @param requestBody
     * @returns CreditCard
     * @throws ApiError
     */
    public static attachCreditCard(
        requestBody?: {
            token?: string;
            currency?: string;
        },
    ): CancelablePromise<CreditCard> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/cards',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param cardId
     * @param currency
     * @returns CreditCard
     * @throws ApiError
     */
    public static removeCreditCard(
        cardId: any,
        currency?: any,
    ): CancelablePromise<CreditCard> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/user/cards/{card_id}',
            path: {
                'card_id': cardId,
            },
            query: {
                'currency': currency,
            },
        });
    }

    /**
     * @param cardId
     * @param requestBody
     * @returns CreditCard
     * @throws ApiError
     */
    public static changeCreditCard(
        cardId: any,
        requestBody?: {
            currency?: string;
        },
    ): CancelablePromise<CreditCard> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/cards/{card_id}/change',
            path: {
                'card_id': cardId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns Notification
     * @throws ApiError
     */
    public static listNotifications(): CancelablePromise<Array<Notification>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/user/notifications',
        });
    }

    /**
     * @param notificationId
     * @param requestBody
     * @returns Notification
     * @throws ApiError
     */
    public static updateNotification(
        notificationId: any,
        requestBody?: Notification,
    ): CancelablePromise<Notification> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/user/notifications/{notification_id}',
            path: {
                'notification_id': notificationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns HubspotToken
     * @throws ApiError
     */
    public static getHubspotToken(): CancelablePromise<HubspotToken> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/user/hubspot-token',
        });
    }

    /**
     * @returns AuthLink
     * @throws ApiError
     */
    public static listAuthLinks(): CancelablePromise<Array<AuthLink>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/user/authlinks',
        });
    }

    /**
     * @param authLinkId
     * @returns AuthLink
     * @throws ApiError
     */
    public static deleteAuthLink(
        authLinkId: any,
    ): CancelablePromise<AuthLink> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/user/authlinks/{auth_link_id}',
            path: {
                'auth_link_id': authLinkId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static acceptTermsOfService(
        requestBody?: {
            acceptance_context: TosAcceptanceContext;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/accept-tos',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static checkEmailDomain(
        requestBody: {
            email: string;
        },
    ): CancelablePromise<{
        is_public?: boolean;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/user/check-mail',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
