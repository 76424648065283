import { ReactElement } from 'react';
import { AgwRestApiKey } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiKeysTableProps = {
  selectable?: boolean;
  loading?: boolean;
  apiKeys: AgwRestApiKey[];
  onSelect?: (ids: string[]) => void;
};

export const AgwRestApiKeysTable = ({
  apiKeys,
  selectable = true,
  loading,
  onSelect,
}: AgwRestApiKeysTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="ApiKey"
    idAttribute="id"
    rows={apiKeys}
    selectable={selectable}
    onSelect={onSelect}
    order={['id', 'name']}
    loading={loading}
  />
);
