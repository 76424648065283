import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AppSyncResolver } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AppSyncResolversTableProps = {
  selectable?: boolean;
  loading?: boolean;
  resolvers: AppSyncResolver[];
  onViewResolver?: (resolver: AppSyncResolver) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AppSyncResolversTable = ({
  resolvers,
  selectable = true,
  loading,
  onViewResolver,
  onSelect,
}: AppSyncResolversTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('AppSync')}
    entry="Resolver"
    idAttribute="fieldName"
    rows={resolvers}
    selectable={selectable}
    onSelect={onSelect}
    order={['fieldName', 'typeName']}
    loading={loading}
    externalFields={{
      fieldName: (row) => (
        <Link onClick={() => onViewResolver && onViewResolver(row)} underline="hover">
          {row.fieldName}
        </Link>
      ),
    }}
  />
);
