import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueTableForm, ProgressButton } from '@localstack/ui';
import { CreateGlueTableRequest, UpdateGlueTableRequest } from '@localstack/types';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { MainNavTabs } from './components';


export const GlueTableUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { database, table } = useParams<'database' | 'table'>();

  const { createTable, updateTable, isLoading } = useAwsEffect(
    'Glue',
    ['createTable', 'updateTable'],
    { revalidate: ['getTables', 'getTable'], clientOverrides },
  );

  const { data: TableData } = useAwsGetter(
    'Glue', 'getTable',
    [{ DatabaseName: database, Name: table }], { clientOverrides });

  const handleCreate = async (data: CreateGlueTableRequest) => {
    await createTable(data);
    goto(
      database ? routes.RESOURCES_GLUE_DATABASE_TABLES : routes.RESOURCES_GLUE_TABLES,
      { database },
    );
  };

  const handleUpdate = async (data: UpdateGlueTableRequest) => {
    await updateTable(data);
    goto(routes.RESOURCES_GLUE_TABLE, { database, table });
  };

  return (
    <Layout
      documentTitle={`Glue: ${table ? 'Update' : 'Create'} Table`}
      title={
        <Box>
          <Typography variant="h4">{table ? 'Update' : 'Create'} Table</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              [database ? 'Databases' : 'Tables', () => goto(
                database ? routes.RESOURCES_GLUE_DATABASES : routes.RESOURCES_GLUE_TABLES,
              )],
              [database, () => goto(routes.RESOURCES_GLUE_DATABASE_TABLES, { database })],
              [table, () => goto(routes.RESOURCES_GLUE_TABLE, { database, table })],
              [`${table ? 'Update' : 'Create'} Table`, null],
            ]}
          />
        </Box>
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="UpsertTable"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueTableForm
            databaseName={database}
            table={TableData?.Table}
            loading={isLoading}
            formId="UpsertTable"
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="UpsertTable"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
