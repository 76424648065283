import { ReactElement, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { UserService, useSnackbar, useApiEffect } from '@localstack/services';

import { AppRoute } from '~/config';

import { BaseActivation } from './components/BaseActivation';

// eslint-disable-next-line
const CONFIRMED_MESSAGE = 'Your account has been successfully activated, you can now sign in with your username and password';
const ERROR_MESSAGE = 'Account activation failed, please make sure that the activation code or link is correct';

export const AccountActivation = (): ReactElement => {
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const { key: activation_key } = useParams<'key'>();

  const { activateAccount } = useApiEffect(UserService, ['activateAccount']);

  useEffect(() => {
    const activate = async () => {
      try {
        await activateAccount({ activation_key });
        showSnackbar({ severity: 'success', message: CONFIRMED_MESSAGE });
        navigate(AppRoute.SIGN_IN);
      } catch (e) {
        showSnackbar({ severity: 'error', message: ERROR_MESSAGE });
      }
    };
    activate();
  }, [activation_key]);

  return (
    <BaseActivation
      message={<CircularProgress />}
      activated
    />
  );
};
