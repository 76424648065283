import { ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { CloudFormationResourceGrid, PageTitle } from '@localstack/ui';
import { useParams } from 'react-router-dom';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { DEFAULT_CLOUDFORMATION_ROUTES } from '@localstack/constants';

import { CloudFormationProps } from './types';

export const CloudFormationResources = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDFORMATION_ROUTES,
}: CloudFormationProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const {
    data: resources,
    isLoading,
    mutate,
  } = useAwsGetter('CloudFormation', 'listStackResources', [{ StackName: name }], { clientOverrides });

  return (
    <Layout
      documentTitle="CloudFormation Resources"
      title={
        <PageTitle
          title="CloudFormation Resources"
          onMutate={mutate}
          breadcrumbs={[
            ['CloudFormation', () => goto(routes.RESOURCES_CLOUDFORMATION)],
            ['Stacks', () => goto(routes.RESOURCES_CLOUDFORMATION_STACKS)],
            [name, () => goto(routes.RESOURCES_CLOUDFORMATION_STACK_RESOURCES, { name })],
            ['Resources', null],
          ]}
        />
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_CLOUDFORMATION_STACK_UPDATE, { name })}>
          Edit Stack
        </Button>
      }
    >
      <Card>
        <CloudFormationResourceGrid
          entries={resources?.StackResourceSummaries ?? []}
          loading={isLoading}
        />
      </Card>
    </Layout>
  );
};
