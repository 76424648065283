/* eslint-disable no-console */
/* eslint-disable max-len */

import { ReactElement } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AppRoute } from '../../config';

import { NotFound, SessionExpired } from '../common';

// Views
import { SignOut } from '../common/SignOut';
import { GlobalSignOut } from '../common/GlobalSignOut';


import Dashboard from './Dashboard';
import { Account, Billing, ApiKeys, TeamMembers, Authentication, SingleSignOnSettings, Subscriptions, SubscriptionDetails, CIKeys } from './Profile';
import { SignIn, SignUp, Recover, PostSignUp } from './Account';
import { AccountActivation, MembershipActivation, UnsubscribeActivation, PasswordResetActivation, AuthRequestActivation, MarketplaceActivation } from './Activation';
import { PricingPlans, PricingSubscribe } from './PricingPlans';
import { Launchpad } from './Launchpad';

import { InstanceLogs } from './InstanceLogs';

// Pods
import { CloudPods, CloudPodsPublic, PodDetails, CloudPodsLoad, StateManager } from './CloudPods';

// CI Analytics
import { CIProjects, CIProjectRunDetails, CIProjectsCreate, CIProjectSettings } from './CIAnalytics';

// Resource browser
import { DefaultResourceBrowserRoutes } from './Resources';

import { SystemStatus } from './SystemStatus';
import { Quickstart, DemoOne, DemoTwo, DemoThree, DemoFour, DemoFive, QuickstartCloudFormation } from './Quickstart';
import { Stack } from './Stacks';
import { SSOCallback, SSOStart } from './SSO';
import { GettingStarted } from './GettingStarted';
import { DesktopDownload } from './DesktopDownload';
import { AuthToken } from './AuthToken';
import { ExtensionsLibrary, ExtensionsManage, ExtensionsDetails, ExtensionsRemote, ExtensionsSubmit } from './Extensions';
import { ResourcesOverview } from './ResourcesOverview';
import { CIProjectDetails } from './CIAnalytics/ProjectDetails';

import { Instances } from './Instances';
import { MyLicense } from './MyLicense';
import { WorkspaceSettings } from './Workspace';
import { IAMStream } from './IAMPolicyStream';

// Chaos Engineering
import { FISExperiments } from './ChaosEngineering';
import { AcceptTos } from './Account/AcceptTos';

type PathElementMapEntry = {
  [componentName: string]: () => ReactElement;
};

// Define the PathElementMapType type
type PathElementMapType = {
  [key: string]: PathElementMapEntry;
};

export const PATH_ELEMENT_MAP: PathElementMapType = {
  [AppRoute.SIGN_UP]: { 'SignUp': SignUp },
  [AppRoute.POST_SIGN_UP]: { 'PostSignUp': PostSignUp },
  [AppRoute.SIGN_IN]: { 'SignIn': SignIn },
  [AppRoute.DASHBOARD]: { 'Dashboard': Dashboard },
  [AppRoute.ACCEPT_TOS]: { 'AcceptTos': AcceptTos },
  [AppRoute.STACKS_STACK]: { 'Stack': Stack },
  [AppRoute.STACKS_OVERVIEW]: { 'Stack': Stack },
  [AppRoute.IAM_POLICY_STREAM]: { 'IAMStream': IAMStream },
  [AppRoute.CHAOS_ENGINEERING]: { 'FISExperiments': FISExperiments },
  [AppRoute.QUICKSTART]: { 'Quickstart': Quickstart },
  [AppRoute.QUICKSTARTCLOUDFORMATION]: { 'QuickstartCloudFormation': QuickstartCloudFormation },
  [AppRoute.QUICKSTART_DEMO1]: { 'DemoOne': DemoOne },
  [AppRoute.QUICKSTART_DEMO2]: { 'DemoTwo': DemoTwo },
  [AppRoute.QUICKSTART_DEMO3]: { 'DemoThree': DemoThree },
  [AppRoute.QUICKSTART_DEMO4]: { 'DemoFour': DemoFour },
  [AppRoute.QUICKSTART_DEMO5]: { 'DemoFive': DemoFive },
  [AppRoute.GETTING_STARTED]: { 'GettingStarted': GettingStarted },
  [AppRoute.ACCOUNT]: { 'Account': Account },
  [AppRoute.ACCOUNT_AUTHENTICATION]: { 'Authentication': Authentication },
  [AppRoute.WORKSPACE]: { 'WorkspaceSettings': WorkspaceSettings },
  [AppRoute.WORKSPACE_BILLING]: { 'Billing': Billing },
  [AppRoute.WORKSPACE_KEYS]: { 'ApiKeys': ApiKeys },
  [AppRoute.WORKSPACE_CI_KEYS]: { 'CIKeys': CIKeys },
  [AppRoute.WORKSPACE_MEMBERS]: { 'TeamMembers': TeamMembers },
  [AppRoute.WORKSPACE_SUBSCRIPTIONS]: { 'Subscriptions': Subscriptions },
  [AppRoute.WORKSPACE_SUBSCRIPTION_DETAIL]: { 'SubscriptionDetails': SubscriptionDetails },
  [AppRoute.WORKSPACE_SUBSCRIPTION_UPDATE]: { 'PricingSubscribe': PricingSubscribe },
  [AppRoute.WORKSPACE_AUTH_TOKEN]: { 'AuthToken': AuthToken },
  [AppRoute.WORKSPACE_MY_LICENSE]: { 'MyLicense': MyLicense },
  [AppRoute.WORKSPACE_SSO_SETTINGS]: { 'SingleSignOnSettings': SingleSignOnSettings },
  [AppRoute.PRICING]: { 'PricingPlans': PricingPlans },
  [AppRoute.PRICING_SUBSCRIBE]: { 'PricingSubscribe': PricingSubscribe },
  [AppRoute.STATUS]: { 'SystemStatus': SystemStatus },
  [AppRoute.RESOURCES_OVERVIEW]: { 'ResourcesOverview': ResourcesOverview },
  [AppRoute.INSTANCE_STATE]: { 'StateManager': StateManager },
  [AppRoute.INSTANCE_LOGS]: { 'InstanceLogs': InstanceLogs },
  [AppRoute.LAUNCHPAD]: { 'Launchpad': Launchpad },
  [AppRoute.DESKTOP_DOWNLOAD]: { 'DesktopDownload': DesktopDownload },
  [AppRoute.SSO_CALLBACK]: { 'SSOCallback': SSOCallback },
  [AppRoute.SSO_START]: { 'SSOStart': SSOStart },
  [AppRoute.EXTENSIONS_LIBRARY]: { 'ExtensionsLibrary': ExtensionsLibrary },
  [AppRoute.EXTENSIONS_MANAGE]: { 'ExtensionsManage': ExtensionsManage },
  [AppRoute.EXTENSIONS_DETAILS]: { 'ExtensionsDetails': ExtensionsDetails },
  [AppRoute.EXTENSIONS_REMOTE]: { 'ExtensionsRemote': ExtensionsRemote },
  [AppRoute.EXTENSIONS_SUBMIT]: { 'ExtensionsSubmit': ExtensionsSubmit },
  [AppRoute.INSTANCES]: { 'Instances': Instances },
  [AppRoute.INSTANCES_EPHEMERAL]: { 'Instances': Instances },
  [AppRoute.CI_PROJECTS]: { 'CIProjects': CIProjects },
  [AppRoute.CI_PROJECT_CREATE]: { 'CIProjectsCreate': CIProjectsCreate },
  [AppRoute.CI_PROJECT_SETTINGS]: { 'CIProjectSettings': CIProjectSettings },
  [AppRoute.CI_PROJECT]: { 'CIProjectDetails': CIProjectDetails },
  [AppRoute.CI_PROJECT_RUN]: { 'CIProjectRunDetails': CIProjectRunDetails },
  [AppRoute.PODS]: { 'CloudPods': CloudPods },
  [AppRoute.PODS_PUBLIC]: { 'CloudPodsPublic': CloudPodsPublic },
  [AppRoute.POD]: { 'PodDetails': PodDetails },
  [AppRoute.POD_DIFF]: { 'PodDetails': PodDetails },
  [AppRoute.POD_LOAD]: { 'CloudPodsLoad': CloudPodsLoad },
  [AppRoute.ACCOUNT_ACTIVATE]: { 'AccountActivation': AccountActivation },
  [AppRoute.MEMBERSHIP_ACTIVATE]: { 'MembershipActivation': MembershipActivation },
  [AppRoute.UNSUBSCRIBE_ACTIVATE]: { 'UnsubscribeActivation': UnsubscribeActivation },
  [AppRoute.RECOVER]: { 'Recover': Recover },
  [AppRoute.RECOVER_ACTIVATE]: { 'PasswordResetActivation': PasswordResetActivation },
  [AppRoute.AUTH_REQUEST_ACTIVATE]: { 'AuthRequestActivation': AuthRequestActivation },
  [AppRoute.MARKETPLACE_ACTIVATE]: { 'MarketplaceActivation': MarketplaceActivation },
  [AppRoute.SESSION_EXPIRED]: { 'SessionExpired': SessionExpired },
  [AppRoute.SIGN_OUT]: { 'SignOut': SignOut },
  [AppRoute.GLOBAL_SIGN_OUT]: { 'GlobalSignOut': GlobalSignOut },
  [AppRoute.NOT_FOUND]: { 'NotFound': NotFound },
};

export const CustomerViews = (): ReactElement => (
  <Routes>
    <Route path="/" element={<Navigate replace to={AppRoute.DASHBOARD} />} />
    {Object.entries(PATH_ELEMENT_MAP).map(([path, mapEntry]) => {
      const Element = Object.values(mapEntry)[0] ?? (() => (<div/>));
      return <Route element={<Element />} path={path} />;
    },
    )}
    <Route element={<DefaultResourceBrowserRoutes />} path={AppRoute.RESOURCES_COMMON} />
    <Route element={<Navigate replace to={AppRoute.NOT_FOUND} />} path="*" />


    {/*
      * Legacy routes
      * unfortunately there is no way in react to respond with a '301 moved permanently'
      * so simply navigating to the new path is all we can do. sorry search engines...
      */}
    <Route path='/account/members' element={<Navigate replace to={AppRoute.WORKSPACE_MEMBERS} />} />
    <Route path='/account/apikeys' element={<Navigate replace to={AppRoute.WORKSPACE_KEYS} />} />
    <Route path='/account/billing' element={<Navigate replace to={AppRoute.WORKSPACE_BILLING} />} />
    <Route path='/account/subscriptions' element={<Navigate replace to={AppRoute.WORKSPACE_SUBSCRIPTIONS} />} />
    <Route path='/account/auth-token' element={<Navigate replace to={AppRoute.WORKSPACE_AUTH_TOKEN} />} />
    <Route path='/account/sso' element={<Navigate replace to={AppRoute.WORKSPACE_SSO_SETTINGS} />} />

    {/*
      * not forwarded legacy routes:
      * - '/account/subscriptions/:subscriptionId'
      * - '/account/subscriptions/:subscriptionId/update'
      */}

  </Routes>
);
