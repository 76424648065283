import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { getSchema } from '@localstack/services';

import {
  AgwApiModel,
  CreateAgwApiModelRequest,
  UpdateAgwApiModelRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledTextarea } from '../../../../form';

export interface AgwApiModelFormProps {
  apiId: string;
  model?: Optional<Partial<AgwApiModel>>;
  formId?: string;
  loading?: boolean;
  onCreate: (data: CreateAgwApiModelRequest) => unknown;
  onUpdate: (data: UpdateAgwApiModelRequest) => unknown;
}

export const AgwApiModelForm = ({
  apiId,
  model,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AgwApiModelFormProps): ReactElement => (
  <MagicForm
    data={model}
    schema={getSchema('ApiGatewayV2')}
    loading={loading}
    entry="CreateModelRequest"
    formId={formId}
    externalFields={{
      '^ApiId$': () => (
        <TextField fullWidth disabled value={apiId} variant="outlined" />
      ),
      '^Schema$': (control, name) => (
        <ControlledTextarea
          control={control}
          name={name}
          placeholder="Schema"
          minRows={10}
          fullWidth
        />
      ),
    }}
    onSubmit={(data: CreateAgwApiModelRequest) => {
      if (!model) return onCreate({ ...data, ApiId: apiId });
      return onUpdate({ ...data, ApiId: apiId, ModelId: model.ModelId as string });
    }}
  />
);
