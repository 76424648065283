import { ReactElement } from 'react';
import { NeptuneInstance } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface NeptuneInstanceDetailsProps {
  instance?: Optional<Partial<NeptuneInstance>>;
}

export const NeptuneInstanceDetails = ({ instance }: NeptuneInstanceDetailsProps): ReactElement => (
  <MagicDetails
    data={instance}
    schema={getSchema('Neptune')}
    entry="DBInstance"
    title="Instance Details"
  />
);
