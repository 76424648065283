import { ReactElement } from 'react';
import { AwsClientOverrides, buildRoute, getSchema } from '@localstack/services';

import {
  AppSyncDataSource,
  AppSyncFunction,
  CreateAppSyncFunctionRequest,
  UpdateAppSyncFunctionRequest,
} from '@localstack/types';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor, RelatedResourcePicker } from '../../../../form';

export interface AppSyncFunctionFormProps {
  apiId: string;
  dataSources: AppSyncDataSource[];
  func?: Optional<Partial<AppSyncFunction>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateAppSyncFunctionRequest) => unknown;
  onUpdate: (data: UpdateAppSyncFunctionRequest) => unknown;
}

export const AppSyncFunctionForm = ({
  apiId,
  func,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: AppSyncFunctionFormProps): ReactElement => (
  <MagicForm
    data={func}
    schema={getSchema('AppSync')}
    loading={loading}
    entry={func ? 'UpdateFunctionRequest' : 'CreateFunctionRequest'}
    formId={formId}
    fieldConditions={{
      '^apiId$': false,
      '^functionId$': false,
      '^syncConfig\\.lambdaConflictHandlerConfig': ['$syncConfig.conflictHandler', '===', 'LAMBDA'],
    }}
    defaultValues={{
      functionVersion: '2018-05-29',
    }}
    externalFields={{
      '^requestMappingTemplate$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="json"
        />
      ),
      '^responseMappingTemplate$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="json"
        />
      ),
      '^dataSourceName$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          required={required}
          creationRoute={buildRoute(DEFAULT_APPSYNC_ROUTES.RESOURCES_APPSYNC_API_DATA_SOURCE_CREATE, { apiId })}
          clientOverrides={clientOverrides}
          client='AppSync'
          method='listDataSources'
          arrayKeyName='dataSources'
          property='name'
          args={[{ apiId }]}
          fieldName={fieldName} entityName='AppSync Data Source'
        />
      ),
    }}
    onSubmit={(data: CreateAppSyncFunctionRequest | UpdateAppSyncFunctionRequest) => {
      if (!func) return onCreate({ ...data, apiId } as CreateAppSyncFunctionRequest);
      return onUpdate({ ...data, apiId, functionId: func?.functionId as string });
    }}
  />
);
