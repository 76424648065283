import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const ACCOUNT_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ACCOUNT}`;

export const DEFAULT_ACCOUNT_ROUTES: Record<string, string> = {
  RESOURCES_ACCOUNT_CONTACTINFO: `${ACCOUNT_BASE_ROUTE}/contactinfo`,
  RESOURCES_ACCOUNT_CONTACTINFO_FORM: `${ACCOUNT_BASE_ROUTE}/contactinfo/form`,
  RESOURCES_ALTERNATE_CONTACT: `${ACCOUNT_BASE_ROUTE}/alternatecontact`,
  RESOURCES_ALTERNATE_CONTACT_FORM: `${ACCOUNT_BASE_ROUTE}/alternatecontact/form`,
};
