import { ReactElement } from 'react';
import { CreateECRImageRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export type CreateECRImageFormProps = {
  loading?: boolean;
  formId: string;
  onSubmit: (data: CreateECRImageRequest) => unknown;
}

export const CreateECRImageForm = ({
  loading,
  formId,
  onSubmit,
}: CreateECRImageFormProps): ReactElement => {

  // eslint-disable-next-line arrow-body-style
  const submitHandler = async (data: CreateECRImageRequest) => {
    return onSubmit(data);
  };

  return (
    <>
      <MagicForm
        schema={getSchema('ECR')}
        formId={formId}
        entry="PutImageRequest"
        loading={loading}
        onSubmit={(data: CreateECRImageRequest) => submitHandler(data)}
      />
    </>
  );
};
