import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AppSyncApiKey } from '@localstack/types';
import { formatDateTime, getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AppSyncApiKeysTableProps = {
  selectable?: boolean;
  loading?: boolean;
  apiKeys: AppSyncApiKey[];
  onViewApiKey?: (key: AppSyncApiKey) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AppSyncApiKeysTable = ({
  apiKeys,
  selectable = true,
  loading,
  onViewApiKey,
  onSelect,
}: AppSyncApiKeysTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('AppSync')}
    entry="ApiKey"
    idAttribute="id"
    rows={apiKeys}
    selectable={selectable}
    onSelect={onSelect}
    order={['id']}
    loading={loading}
    externalFields={{
      id: (row) => (
        <Link onClick={() => onViewApiKey && onViewApiKey(row)} underline="hover">
          {row.id}
        </Link>
      ),
      expires: (row) => <>{row.expires ? formatDateTime(row.expires) : ''}</>,
      deletes: (row) => <>{row.deletes ? formatDateTime(row.deletes) : ''}</>,
    }}
  />
);
