import { ReactElement } from 'react';
import { AwsServiceIcon } from '@localstack/ui';
import {
  Card, CardHeader, CardContent, CardMedia, CardActions, Grid, Box, Button,
  Alert } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useRoutes } from '@localstack/services';


import { ExternalLink } from '@localstack/constants';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import { NavTabs } from './components/NavTabs';
import { Demo } from './components/common';

const CLOUD_POD_DEMOS : Demo[] = [
  {
    title: 'Automatic thumbnail creation',
    services: ['lambda', 's3'],
    image: '/demo4/thumbnailerPod.svg',
    route: AppRoute.QUICKSTART_DEMO4,
  },
  {
    title: 'Layer your application with Cloud Pods',
    services: ['ecs', 'dynamodb', 'apigateway', 'cognito-idp', 's3', 'amplify', 'iam'],
    image: '/demo5/serverless-container-api.png',
    route: AppRoute.QUICKSTART_DEMO5,
  },
  // Commenting out second quickstart pod for now, since we keep running into issues
  // {
  //   title: 'Request worker sample app',
  //   services: ['apigateway', 'cloudformation', 'dynamodb', 'lambda', 's3', 'sqs', 'stepfunctions'],
  //   image: '/demo3/requestworkerPod.svg',
  //   route: AppRoute.QUICKSTART_DEMO3,
  // },
];

const useStyles = makeStyles((theme: Theme) => createStyles({
  media: {
    height: theme.spacing(24),
    borderRadius: 8,
  },
}));

type QuickPodCardProps = {
  demo: Demo;
}

const QuickPodCard = ({ demo }: QuickPodCardProps) => {
  const { goto } = useRoutes();
  const classes = useStyles();

  return (
    <Card>
      <CardHeader title={demo.title} />
      <CardMedia
        className={classes.media}
        image={demo.image}
      />
      <CardContent>
        <Box display="flex">
          {demo.services.map((code: string) => (
            <AwsServiceIcon code={code} key={code} />
          ))}
        </Box>
      </CardContent>
      <CardActions>
        <Button color="primary" onClick={() => goto(demo.route)}>
          View Application
        </Button>
      </CardActions>
    </Card>
  );
};

export const Quickstart = (): ReactElement => (
  <CustomerLayout title="Quickstart" tabs={<NavTabs/>}>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Alert severity="info">
          To learn more about Cloud Pods visit our documentation
          {' '}
          <a target="_blank" rel="noreferrer"
            href={ExternalLink.DOCS_CLOUD_PODS}
          >here</a>.
        </Alert>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          {CLOUD_POD_DEMOS.map((demo) => (
            <Grid item sm={4} key={demo.route}>
              <QuickPodCard demo={demo}/>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </CustomerLayout>
);
