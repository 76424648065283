import { useState, useEffect, ReactElement } from 'react';
import { v4 as uuid } from 'uuid';
import mermaid from 'mermaid';

export interface MermaidRendererProps {
  code: string;
}

export const MermaidRenderer = ({ code }: MermaidRendererProps): ReactElement => {
  const [rendererId, setRendererId] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    setRendererId(`mermaid-${uuid()}`);
  }, []);

  useEffect(() => {
    if (!rendererId) return;
    document.getElementById(rendererId)?.removeAttribute('data-processed');
    // eslint-disable-next-line
    // @ts-ignore
    mermaid.init(undefined, `#${rendererId}`);
    setShow(true);
  }, [rendererId, code]);
  // Mermaid doesn't render in display: none div so using opacity instead
  return <div id={rendererId} style={{ opacity: show ? '1' : '0' }}>{code}</div>;
};
