import { Components, Palette, Theme } from '@mui/material';

import MuiAlert from './MuiAlert';
import MuiButton from './MuiButton';
import MuiCard from './MuiCard';
import MuiCardActions from './MuiCardActions';
import MuiCardHeader from './MuiCardHeader';
import MuiChip from './MuiChip';
import MuiCssBaseline from './MuiCssBaseline';
import MuiFab from './MuiFab';
import MuiFilledInput from './MuiFilledInput';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiInputLabel from './MuiInputLabel';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItem from './MuiListItem';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiPaper from './MuiPaper';
import MuiSkeleton from './MuiSkeleton';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
import MuiToggleButton from './MuiToggleButton';
import MuiTooltip from './MuiTooltip';
import MuiInputAdornment from './MuiInputAdornment';

const overrides = {
  MuiAlert,
  MuiButton,
  MuiCard,
  MuiCardActions,
  MuiCardHeader,
  MuiChip,
  MuiCssBaseline,
  MuiFab,
  MuiFilledInput,
  MuiIconButton,
  MuiInputBase,
  MuiInputLabel,
  MuiLinearProgress,
  MuiListItem,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiPaper,
  MuiSkeleton,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  MuiToggleButton,
  MuiTooltip,
  MuiInputAdornment,
};

export default (palette: Palette): Components<Omit<Theme, 'components'>> =>
  Object.entries(overrides).reduce((m, [k, v]) => ({ ...m, [k]: v(palette) }), {});
