import { DefaultValuesObject } from './types';

export const DEFAULT_EC2_VALUES: DefaultValuesObject = {
  RunInstancesRequest: {
    input: {
      MinCount: 1,
      MaxCount: 1,
      CreditSpecification: {
        CpuCredits: 'standard',
      },
    },
  },
};
