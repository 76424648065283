import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { MetricsRoutes } from '../../types';

interface Props {
  routes: MetricsRoutes;
}

export const MainNavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_CLOUDWATCH_MONITORING_METRICS)} end>
      Metrics
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_CLOUDWATCH_MONITORING_ALARMS)}>
      Alarms
    </NavLink>
  </>
);
