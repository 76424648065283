import { DEFAULT_CE_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { CECostCategoryDefinitionsTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { CEProps } from './types';

export const CECostCategoryDefinitions = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CE_ROUTES,
}: CEProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: costCategoryDefinitions, isLoading, mutate } = useAwsGetter(
    'CostExplorer',
    'listCostCategoryDefinitions',
    [],
    { clientOverrides },
  );
  const { deleteCostCategoryDefinition } = useAwsEffect('CostExplorer', ['deleteCostCategoryDefinition'], {
    revalidate: ['listCostCategoryDefinitions'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteCostCategoryDefinition({ CostCategoryArn: id }),
      ),
    );
  };
  return (
    <Layout
      title={<PageTitle title="Cost Explorer Cost Category Definitions" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_CE_COST_CATEGORY_DEFINITION_CREATE)}>
            Create
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Cost Category Definition(s)?`}
              onClick={handleRemove}
              text="Selected Cost Category Definitions will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CECostCategoryDefinitionsTable
          costCategoryDefinitions={costCategoryDefinitions || {}}
          onViewCostCategoryDefinition={(id: string) =>
            goto(routes.RESOURCES_CE_COST_CATEGORY_DEFINITION, { id })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
