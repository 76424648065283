import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';

import { AppRoute } from '~/config';

import { AuthenticatedLayout, AuthenticatedLayoutProps } from '../Authenticated';

import { Navbar } from '../Customer/components';

import { Searchbar } from './components';

export const AdminLayout = ({ children, ...rest }: AuthenticatedLayoutProps): ReactElement => (
  <AuthenticatedLayout
    {...rest}
    searchbar={<Searchbar />}
    navigation={<Navbar />}
    tabs={
      <>
        <NavLink to={AppRoute.ADMIN_HOME} end>Dashboard</NavLink>
        <NavLink to={AppRoute.ADMIN_ACCOUNTS}>Accounts</NavLink>
        <NavLink to={AppRoute.ADMIN_ORGANIZATIONS}>Organizations</NavLink>
        <NavLink to={AppRoute.ADMIN_EXTENSIONS}>Extensions</NavLink>
        <NavLink to={AppRoute.ADMIN_ANNOUNCEMENT}>Announcements</NavLink>
      </>
    }
  >
    {children}
  </AuthenticatedLayout>
);
