import { getSchema } from '@localstack/services';
import { DescribeClusterResponse } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type EKSClusterDetailProps = {
  cluster?: Optional<Partial<DescribeClusterResponse>>;
};

export const EKSClusterDetail = ({
  cluster,
}: EKSClusterDetailProps): ReactElement => (
  <MagicDetails
    data={cluster?.cluster}
    schema={getSchema('EKS')}
    entry="Cluster"
    title="EKS Cluster Details"
  />
);
