import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { LambdaLayersList } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type LambdaLayersTableProps = {
  loading?: boolean;
  selectable?: boolean;
  layers: LambdaLayersList[];
  onSelectLayer?: (names: string[]) => void;
  onViewLayer?: (name: string) => void;
};

export const LambdaLayersTable = ({
  loading,
  selectable = true,
  layers,
  onSelectLayer,
  onViewLayer,
}: LambdaLayersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Lambda')}
    entry="LayersList"
    idAttribute="LayerArn"
    order={['LayerName']}
    filterColumns={['LayerName','LayerArn']}
    rows={layers}
    selectable={selectable}
    onSelect={onSelectLayer}
    loading={loading}
    externalFields={{
      LayerName: (row) => (
        <Link
          onClick={() => onViewLayer && onViewLayer(row.LayerName as string)}
          underline="hover"
        >
          {row.LayerName}
        </Link>
      ),
    }}
  />
);
