import { ReactElement } from 'react';
import { AgwApiRoute } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwApiRouteDetailsProps {
  route?: Optional<Partial<AgwApiRoute>>;
}

export const AgwApiRouteDetails = ({ route }: AgwApiRouteDetailsProps): ReactElement => (
  <MagicDetails
    data={route}
    schema={getSchema('ApiGatewayV2')}
    entry="Route"
    title="Route Details"
  />
);
