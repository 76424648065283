import { ChangeEvent, ReactElement, useState } from 'react';
import { CreateLambdaFunctionRequest } from '@localstack/types';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box } from '@mui/material';
import { DEFAULT_KMS_ROUTES, DEFAULT_S3_ROUTES, PublicAsset } from '@localstack/constants';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { ControlledDropzone } from '../../../../form/ControlledDropzone';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';

const CODE_SOURCE = {
  DEFAULT: 'default',
  S3: 's3',
  UPLOAD: 'upload',
};

export type LambdaFunctionCreateFormProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateLambdaFunctionRequest) => unknown;
}

export const LambdaFunctionCreateForm = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: LambdaFunctionCreateFormProps): ReactElement => {
  const [codeSource, setCodeSource] = useState(CODE_SOURCE.DEFAULT);

  const submitHandler = async (data: CreateLambdaFunctionRequest) => {
    if (codeSource === CODE_SOURCE.DEFAULT) {
      const demoResponse = await fetch(PublicAsset.DEMO_LAMBDA);
      const demoBuffer = await demoResponse.arrayBuffer();
      return onSubmit({ ...data, Code: { ...data.Code, ZipFile: demoBuffer } });
    }
    if (codeSource === CODE_SOURCE.UPLOAD) {
      const blobbedZip = data.Code.ZipFile as Blob;
      const buffer = await blobbedZip.arrayBuffer();
      return onSubmit({ ...data, Code: { ...data.Code, ZipFile: buffer } });
    }
    return onSubmit(data);
  };

  return <>
    <Box>
      <FormControl
        variant="standard"
        component="fieldset"
        // eslint-disable-next-line
        onChange={(e: ChangeEvent<HTMLFieldSetElement>) => setCodeSource((e.target as any).value)}>
        <FormLabel component="legend">Code Source</FormLabel>
        <RadioGroup row value={codeSource} color="primary">
          <FormControlLabel value={CODE_SOURCE.S3} control={<Radio color="primary" />} label="S3 Bucket" />
          <FormControlLabel value={CODE_SOURCE.UPLOAD} control={<Radio color="primary" />} label="Upload ZIP File" />
          <FormControlLabel value={CODE_SOURCE.DEFAULT} control={<Radio color="primary" />} label="Default" />
        </RadioGroup>
      </FormControl>
    </Box>
    <MagicForm
      schema={getSchema('Lambda')}
      formId={formId}
      entry="CreateFunctionRequest"
      loading={loading}
      onSubmit={(data: CreateLambdaFunctionRequest) => submitHandler(data)}
      fieldConditions={{
        '^Code\\.ImageUri': false,
        '^Code\\.ZipFile': codeSource === CODE_SOURCE.UPLOAD,
        '^Code\\.S3Bucket': codeSource === CODE_SOURCE.S3,
        '^Code\\.S3Key': codeSource === CODE_SOURCE.S3,
        '^Code\\.S3ObjectVersion': codeSource === CODE_SOURCE.S3,
        '^Code': codeSource !== CODE_SOURCE.DEFAULT,
        '^EphemeralStorage': false,
      }}
      defaultValues={{
        Handler: 'handler.handler',
      }}
      externalFields={{
        '^Code.ZipFile$': (control, fieldName) => (
          <ControlledDropzone
            name={fieldName}
            control={control}
            rules={{ required: 'Zip File is required' }}
          />
        ),
        '^Code.S3Bucket$': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client='S3' method='listBuckets' arrayKeyName='Buckets' property='Name'
            fieldName={fieldName} entityName='S3 Bucket'
            creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),

        '^KMSKeyArn$': (control, fieldName, required) => (
          <RelatedResourcePicker
            control={control}
            client='KMS' method='listKeys' arrayKeyName='Keys' property='KeyArn'
            fieldName={fieldName} entityName='KMS Key'
            creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
            required={required}
            clientOverrides={clientOverrides}
          />
        ),
      }}
    />
  </>;
};
