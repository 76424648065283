import { LocalStackChaosFault, LocalStackChaosFaults, LocalStackChaosNetworkEffect } from '@localstack/types';

import {
  DEFAULT_PROBABILITY, DEFAULT_REGIONS,
  DEFAULT_SERVICES,
  EXPERIMENT_NAMES,
} from '../constants';
import { ExperimentFormValues, AutocompleteSelectOption } from '../types';

const DYNAMODB_THROTTLED_ACTIONS = [
  'GetItem', 'Query', 'Scan', 'TransactGetItems', 'BatchGetItem',
  'PutItem', 'BatchWriteItem', 'UpdateItem', 'DeleteItem', 'TransactWriteItems',
];
const KINESIS_THROTTLED_ACTIONS = ['PutRecord', 'PutRecords'];

const DEFAULT_REGION = DEFAULT_REGIONS[0];

export const buildChaosFaultTemplate = (params: LocalStackChaosFault): LocalStackChaosFault => ({
  ...(params.service && { service: params.service }),
  ...(params.region && { region: params.region }),
  ...(params.probability && { probability: params.probability / 100 }),
  ...(params.operation && { operation: params.operation }),
  ...(params.description && { description: params.description }),
  ...(params.error && {
    error: {
      ...(params.error.statusCode && { statusCode: params.error.statusCode }),
      ...(params.error.code && { code: params.error.code }),
    },
  }),
});

export const buildChaosNetworkEffectTemplate =
  (params: LocalStackChaosNetworkEffect): LocalStackChaosNetworkEffect => ({
    ...((params.latency || params.latency === 0) && { latency: params.latency }),
  });

// Templates for experiments

export const getDynamoDbErrorTemplate =
  (params?: ExperimentFormValues): LocalStackChaosFaults =>
    DYNAMODB_THROTTLED_ACTIONS.map(operation => buildChaosFaultTemplate({
      service: 'dynamodb',
      operation,
      probability: params?.probability || DEFAULT_PROBABILITY,
      description: EXPERIMENT_NAMES.dynamodbError,
      error: {
        statusCode: 400,
        code: 'ProvisionedThroughputExceededException',
      },
    }));

export const getKinesisErrorTemplate =
  (params?: ExperimentFormValues): LocalStackChaosFaults =>
    KINESIS_THROTTLED_ACTIONS.map(operation => buildChaosFaultTemplate({
      service: 'kinesis',
      operation,
      probability: params?.probability || DEFAULT_PROBABILITY,
      description: EXPERIMENT_NAMES.kinesisError,
      error: {
        statusCode: 400,
        code: 'ProvisionedThroughputExceededException',
      },
    }));

export const getInternalServerErrorTemplate =
  (params?: ExperimentFormValues): LocalStackChaosFaults => [
    buildChaosFaultTemplate({
      region: params?.region || DEFAULT_REGION,
      probability: params?.probability || DEFAULT_PROBABILITY,
      description: EXPERIMENT_NAMES.internalServerError,
      error: {
        statusCode: 500,
        code: 'InternalServerError',
      },
    }),
  ];

export const getServiceUnavailableError = (params?: ExperimentFormValues): LocalStackChaosFaults =>
  (params?.services || DEFAULT_SERVICES).map(
    (service: string | AutocompleteSelectOption) => buildChaosFaultTemplate({
      service: typeof service === 'string' ? service : service.key,
      region: params?.region || DEFAULT_REGION,
      description: EXPERIMENT_NAMES.serviceUnavailableError,
      error: {
        statusCode: 503,
        code: 'ServiceUnavailable',
      },
    }));

export const getRegionUnavailableError = (params?: ExperimentFormValues): LocalStackChaosFaults =>
  (params?.regions || DEFAULT_REGIONS).map(
    region => buildChaosFaultTemplate({
      region,
      description: EXPERIMENT_NAMES.regionUnavailableError,
      error: {
        statusCode: 503,
        code: 'RegionUnavailable',
      },
    }));

export const getLatencyExperimentTemplate =
  (params?: ExperimentFormValues): LocalStackChaosNetworkEffect => buildChaosNetworkEffectTemplate({
    latency: params?.latencyMilliseconds,
  });
