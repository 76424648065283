import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { AgwApiAuthorizer, CreateAgwApiAuthorizerRequest, UpdateAgwApiAuthorizerRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwApiAuthorizerFormProps {
  apiId: string;
  authorizer?: Optional<Partial<AgwApiAuthorizer>>;
  loading?: boolean;
  isWebsocket?: boolean;
  formId?: string;
  onCreate: (data: CreateAgwApiAuthorizerRequest) => unknown;
  onUpdate: (data: UpdateAgwApiAuthorizerRequest) => unknown;
}

export const AgwApiAuthorizerForm = ({
  apiId,
  authorizer,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AgwApiAuthorizerFormProps): ReactElement => (
  <MagicForm
    data={authorizer}
    schema={getSchema('ApiGatewayV2')}
    loading={loading}
    entry="CreateAuthorizerRequest"
    formId={formId}
    fieldConditions={{
      '^JwtConfiguration': ['$AuthorizerType', '===', 'JWT'],
      '^(AuthorizerUri|AuthorizerPayloadFormatVersion)': ['$AuthorizerType', '===', 'REQUEST'],
      '^(AuthorizerResultTtlInSeconds|AuthorizerCredentialsArn)': ['$AuthorizerType', '===', 'REQUEST'],
      '^EnableSimpleResponses': ['$AuthorizerType', '===', 'REQUEST'],
    }}
    externalFields={{
      '^ApiId$': () => (
        <TextField fullWidth disabled value={apiId} variant="outlined" />
      ),
    }}
    onSubmit={(data: CreateAgwApiAuthorizerRequest) => {
      if (!authorizer) return onCreate({ ...data, ApiId: apiId });
      return onUpdate({ ...data, ApiId: apiId, AuthorizerId: authorizer.AuthorizerId as string });
    }}
  />
);
