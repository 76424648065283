import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwApiStageRequest, UpdateAgwApiStageRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwApiStageForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiStageUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId, stageName: StageName } = useParams<'apiId' | 'stageName'>();

  const { createStage, updateStage, isLoading } = useAwsEffect(
    'ApiGatewayV2',
    ['createStage', 'updateStage'],
    {
      revalidate: ['getStage', 'getStages'],
      clientOverrides,
    },
  );

  const { data: stage } = useAwsGetter(
    'ApiGatewayV2',
    'getStage',
    [{ ApiId, StageName }],
    { clientOverrides },
  );

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const handleCreate = async (data: CreateAgwApiStageRequest) => {
    await createStage(data);
    goto(routes.RESOURCES_AGW2_API_STAGES, { apiId: ApiId });
  };

  const handleUpdate = async (data: UpdateAgwApiStageRequest) => {
    await updateStage(data);
    goto(routes.RESOURCES_AGW2_API_STAGE, { apiId: ApiId, stageName: StageName });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Stage Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [stage?.StageName, () => goto(routes.RESOURCES_AGW2_API_STAGE, { apiId: ApiId, stageName: StageName })],
              [StageName ? 'Update' : 'Create Stage', null],
            ]}
          />
        </Box>
      }
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwApiStageForm
            clientOverrides={clientOverrides}
            apiId={ApiId as string}
            stage={stage}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
