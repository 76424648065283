import { ReactElement } from 'react';
import { AdminService, useApiEffect, useApiGetter } from '@localstack/services';

import { OrganizationDetails, OrganizationDetailsSkeleton } from '../components';

export interface BasicDetailsProps {
  orgId: string;
}

export const BasicDetails = ({ orgId }: BasicDetailsProps): ReactElement => {
  const { data: organization } = useApiGetter(AdminService, 'getOrganization', [orgId]);

  const { data: subscriptions } =
    useApiGetter(AdminService, 'listOrganizationSubscriptions', [orgId]);

  const { data: keys } = useApiGetter(AdminService, 'listOrganizationKeys', [orgId]);

  const { updateOrganization, isLoading: isOrgMutating } =
    useApiEffect(AdminService, ['updateOrganization'], { revalidate: ['getOrganization', 'getOrganizations'] });

  const activeKeys = (keys ?? []).filter(({ deleted }) => !deleted);

  if (!organization) {
    return <OrganizationDetailsSkeleton />;
  }

  return (
    <OrganizationDetails
      loading={isOrgMutating}
      organization={organization}
      subscriptions={subscriptions ?? []}
      keys={activeKeys}
      onUpdate={updateOrganization}
    />
  );
};
