import { useState, ReactElement } from 'react';
import { SMSecret, SMSecretValue } from '@localstack/types';
import { Button, Box } from '@mui/material';
import { Visibility as VisibilityIcon } from '@mui/icons-material';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface SecretsManagerSecretDetailsProps {
  secret?: Optional<Partial<SMSecret>>;
  value?: Optional<Partial<SMSecretValue>>;
}

export const SecretsManagerSecretDetails = ({ secret, value }: SecretsManagerSecretDetailsProps): ReactElement => {
  const [displaySecret, setDisplaySecret] = useState(false);
  return (
    <>
      <Box textAlign="right">
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<VisibilityIcon />}
          onClick={() => setDisplaySecret(!displaySecret)}
        >
          Display Secret
        </Button>
      </Box>
      {displaySecret && (
        <MagicDetails
          data={value}
          schema={getSchema('SecretsManager')}
          entry="GetSecretValueResponse"
          title="Secret Value"
        />
      )}
      <MagicDetails
        data={secret}
        schema={getSchema('SecretsManager')}
        entry="DescribeSecretResponse"
        title="Secret Details"
      />
    </>
  );
};
