import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { CognitoIdentityPoolsTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { CognitoProps } from './types';
import { CognitoNavTabs } from './components';

export const CognitoIdentityPools = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: identityPools, isLoading, mutate } = useAwsGetter(
    'CognitoIdentity',
    'listIdentityPools',
    [{ MaxResults: 100 }],
    { clientOverrides },
  );
  const { deleteIdentityPool } = useAwsEffect('CognitoIdentity', ['deleteIdentityPool'], {
    revalidate: ['listIdentityPools'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteIdentityPool({ IdentityPoolId: id }),
      ),
    );
  };
  return (
    <Layout
      title={<PageTitle title="Cognito Identity Pools" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_COGNITO_IDENTITY_POOL_CREATE)}>
            Create Identity Pool
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} IdentityPool(s)?`}
              onClick={handleRemove}
              text="Selected IdentityPools will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
      tabs={<CognitoNavTabs routes={routes} />}
    >
      <Card>
        <CognitoIdentityPoolsTable
          identityPools={identityPools?.IdentityPools || []}
          onViewIdentityPool={(id: string) =>
            goto(routes.RESOURCES_COGNITO_IDENTITY_POOL, { id })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
