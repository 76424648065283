import { useState, useEffect, ReactElement, ChangeEvent } from 'react';
import { TextField, InputAdornment, CircularProgress, Autocomplete } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useRoutes, AdminService, useApiEffect } from '@localstack/services';
import { AdminSearchResult } from '@localstack/types';
import { debounce } from 'lodash';

import { AppRoute } from '~/config';

export interface SearchbarProps {
  size?: 'small' | 'medium';
}

export const Searchbar = ({ size = 'small' }: SearchbarProps): ReactElement => {
  const { goto } = useRoutes();

  const [query, setQuery] = useState<string>('');
  const [searching, setSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<Optional<AdminSearchResult>>();

  const options = searchResult
    ? [...searchResult.organizations, ...searchResult.users, ...searchResult.keys, ...searchResult.extensions]
    : [];

  const handleSearchResultClick = (item: [string, string]) => {
    if (!item) {
      return;
    }

    if (searchResult?.organizations.includes(item)) {
      return goto(AppRoute.ADMIN_ORGANIZATION, { id: item[0] });
    }

    if (searchResult?.users.includes(item)) {
      return goto(AppRoute.ADMIN_ACCOUNT, { id: item[0] });
    }

    if (searchResult?.keys.includes(item)) {
      return goto(AppRoute.ADMIN_ORGANIZATION, { id: item[0] });
    }

    if (searchResult?.extensions.includes(item)) {
      return goto(AppRoute.ADMIN_EXTENSION, { id: item[0] });
    }


    throw new Error('Unknown search result target');
  };

  const handleSearchFieldChange = debounce((value: string) => setQuery(value), 1000);

  const groupBy = (item: [string, string]) => {
    if (searchResult?.organizations.includes(item)) return 'Organizations';
    if (searchResult?.users.includes(item)) return 'Users';
    if (searchResult?.keys.includes(item)) return 'Keys';
    if (searchResult?.extensions.includes(item)) return 'Extensions';

    throw new Error('Unknown search result target');
  };

  const { getSearchResults } = useApiEffect(AdminService, ['getSearchResults']);

  useEffect(() => {
    if (!query) return;

    (async () => {
      setSearching(true);
      setSearchResult(await getSearchResults(query));
      setSearching(false);
    })();
  }, [query]);

  return (
    // XXX: The <Autocomplete> component is used here, even though the autocomplete
    // feature is not acutally used. It is merely used for the styling and grouping it
    // proviedes.
    //
    // Thus we have to disable the filtering `filterOptions={(x) => x}` so that all
    // results are shown as intended.
    //
    // Another issue with using autocomplete is that a value sticks to the text field
    // once it is selected in the list below. The search remains functional but its annoying.
    // Best solution would be to resplace <autocomplete> with a more fitting component
    // at some place.
    <Autocomplete
      fullWidth
      filterOptions={(x) => x}
      options={options}
      getOptionLabel={(item: [string, string]) => item[1]}
      renderOption={(props, item: [string, string]) => <li {...props}>{item[1]}</li>}
      groupBy={groupBy}
      onChange={(_, value) => handleSearchResultClick(value as [string, string])}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Go to page, resource, ..."
          variant="outlined"
          size={size}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleSearchFieldChange(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {searching ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
