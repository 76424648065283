import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { AppConfigApplications } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type AppConfigApplicationsTableProps = {
  applications: AppConfigApplications;
  onViewApplication: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const AppConfigApplicationsTable = ({
  applications,
  onViewApplication,
  onSelect,
  loading,
}: AppConfigApplicationsTableProps): ReactElement => (
  <MagicTable
    entry="Application"
    idAttribute="Id"
    rows={applications.Items || []}
    schema={getSchema('AppConfig')}
    externalFields={{
      Id: ({ Id }) => (
        <Link onClick={() => onViewApplication(Id || '')} underline="hover">
          {Id}
        </Link>
      ),
    }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
