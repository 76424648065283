import { ReactElement } from 'react';
import { AgwApiAuthorizer } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwApiAuthorizerDetailsProps {
  authorizer?: Optional<Partial<AgwApiAuthorizer>>;
}

export const AgwApiAuthorizerDetails = ({ authorizer }: AgwApiAuthorizerDetailsProps): ReactElement => (
  <MagicDetails
    data={authorizer}
    schema={getSchema('ApiGatewayV2')}
    entry="Authorizer"
    title="Authorizer Details"
  />
);
