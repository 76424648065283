import { Route53HostedZone, Route53ResourceRecordSet } from '@localstack/types';

import { ROUTING_POLICIES, HOSTED_ZONE_DISABLED_RECORD_TYPES } from './data';

type Options = {
  withoutHostedZone?: string;
  addRoutingPolicy?: boolean;
}

const removeTrailingDot = (text: string): string => text.slice(0, -1);

const processHostedZone = (
  hostedZone: Route53HostedZone,
): Route53HostedZone => ({
  ...hostedZone,
  Name: removeTrailingDot(hostedZone.Name),
  Id: hostedZone.Id.split('/').pop() || hostedZone.Id,
});

const processHostedZoneRecord = (record: Route53ResourceRecordSet, options?: Options): Route53ResourceRecordSet => {
  let name = record.Name;
  if (options && options.withoutHostedZone) name = name.split(options.withoutHostedZone)[0] || '';
  return {
    ...record,
    Name: removeTrailingDot(name),
    RoutingPolicy: identifyRecordRoutingPolicy(record),
  };
};

const isHostedZoneDefaultRecord = (record: Optional<Route53ResourceRecordSet>, name: string): boolean =>
  HOSTED_ZONE_DISABLED_RECORD_TYPES.includes(record?.Type || '') && record?.Name === name;


const identifyRecordRoutingPolicy = (record: Route53ResourceRecordSet): string => {
  if (record?.Failover) return ROUTING_POLICIES.FAILOVER;
  return ROUTING_POLICIES.SIMPLE;
};

export {
  processHostedZone,
  processHostedZoneRecord,
  isHostedZoneDefaultRecord,
  identifyRecordRoutingPolicy,
};
