import { useMemo, useState, ReactNode, ReactElement, useEffect, createContext, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CombinedUserInfo, RESOURCES_SERVICES_TYPE, SERVICE_NAME } from '@localstack/types';

import {
  UserService,
  useApiEffect,
  useRoutes,
  useGlobalSwr,
  useApiGetter,
  getSelectedInstance,
  useLocalstackStatus,
  useSnackbar,
} from '@localstack/services';

import { PRO_REGEXES } from '@localstack/constants';

import { AppRoute } from '~/config';

import {
  getUserInfo,
  storeUserInfo,
  updatePreSignInPageURL,
  clearStorage,
  retrieveImpersonationMarker,
  storeImpersonationMarker,
} from '~/util/storage';

import { ImpersonationWrapper } from '../ImpersonationWrapper';

const PUBLIC_ROUTES = [
  AppRoute.SIGN_IN,
  AppRoute.SIGN_UP,
  AppRoute.SIGN_OUT,
  AppRoute.GLOBAL_SIGN_OUT,
  AppRoute.RECOVER,
  AppRoute.RECOVER_ACTIVATE,
  AppRoute.ACCOUNT_ACTIVATE,
  AppRoute.MEMBERSHIP_ACTIVATE,
  AppRoute.UNSUBSCRIBE_ACTIVATE,
  AppRoute.MARKETPLACE_ACTIVATE,
  AppRoute.SSO_START,
  AppRoute.SSO_CALLBACK,
  AppRoute.LAUNCHPAD,
  AppRoute.EXTENSIONS_REMOTE,
  AppRoute.NOT_FOUND,
  AppRoute.SESSION_EXPIRED,
];

interface AuthGuardProviderProps {
  children: ReactNode;
}

export type AuthGuardProviderContextInterface = {
  userInfo: Optional<CombinedUserInfo>,
  reloadUserInfo: () => Promise<CombinedUserInfo | void>;
};

export const AuthGuardProviderContext = createContext<AuthGuardProviderContextInterface>({
  userInfo: null,
  reloadUserInfo: () => Promise.resolve(),
});


/**
 * This component makes sure private routes are unavailable from direct navigation.
 * It also takes care of any auth-related stuff, like refreshing auth tokens.
 */
export const AuthGuardProvider = ({ children }: AuthGuardProviderProps): ReactElement => {
  const { goto } = useRoutes();
  const { pathname } = useLocation();
  const { clearAll: clearSwrCache } = useGlobalSwr();
  const navigate = useNavigate();

  const { showSnackbar } = useSnackbar();

  const [userInfo, setUserInfo] = useState<Optional<CombinedUserInfo>>(getUserInfo());

  const instance = getSelectedInstance();
  const isAuthenticated = userInfo?.roles.includes('authenticated');
  const isImpersonationMode = !!retrieveImpersonationMarker();

  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};
  const { isPro, running } = useLocalstackStatus(clientOverrides);

  // Note `getUser` invalidation will cause a full-app re-render
  const { data: loadedUserInfo } = useApiGetter(
    UserService,
    'getUser',
    [],
    { enable: !!isAuthenticated }, // `!!` is important here!
  );

  const { getUser } = useApiEffect(UserService, ['getUser']);

  useEffect(() => {
    if (!loadedUserInfo) return;

    setUserInfo(loadedUserInfo);
    storeUserInfo(loadedUserInfo);
  }, [loadedUserInfo]);

  useEffect(() => {
    if (!running) {
      return;
    }
    // extract the service name from the pathname (e.g. apigatewayv2)
    const service = pathname.match(/(?<=\/resources\/)[^/]+/)?.at(0);
    if (!service) {
      return;
    }

    if ((RESOURCES_SERVICES_TYPE.pro.includes(service as SERVICE_NAME) ||
      PRO_REGEXES.some((regex) => pathname.match(regex))) &&
      !isPro) {

      const { referrer } = document;

      if (referrer) {
        navigate(-1);
      } else {
        goto(AppRoute.HOME);
      }
      showSnackbar({
        message: 'Please purchase a subscription if you want to access pro services in the resource browser',
        severity: 'error',
      });
    }
  }, [pathname, running]);

  const routeMatches = useMemo(() => PUBLIC_ROUTES.map((route: string) => {
    const regex = new RegExp(route.replace(/:[^:/]+/g, '[^/]+'));
    return regex.test(pathname);
  }), [pathname]);

  const anyMatches = useMemo(() => routeMatches.some(Boolean), [routeMatches]);

  const handleStopImpersonation = useCallback(async () => {
    const impersonatedUserId = retrieveImpersonationMarker();

    if (!impersonatedUserId) return;

    await clearSwrCache();

    // clear impersonation marker
    storeImpersonationMarker(null);

    await reloadUserInfo();

    // navigate back to the impersonated user's account
    goto(AppRoute.ADMIN_ACCOUNT, { id: impersonatedUserId });
  }, []);

  const reloadUserInfo = useCallback(async () => {
    const newUserInfo = await getUser();
    setUserInfo(newUserInfo);
    return newUserInfo;
  }, []);

  // if user navigates to a private route while being unauthenticated:
  // * clear local storage from any remaining tokens or cache
  // * remember current page visited before redirection
  // * redirect to sign-in page
  // User should be automatically redirected to the previously requested page
  // after successful login. This is also true for logins after signups.
  useEffect(() => {
    if (!anyMatches && !isAuthenticated) {
      clearStorage();
      clearSwrCache();

      // remember current page visited before redirection
      updatePreSignInPageURL(pathname);

      // redirect to sigin page
      goto(AppRoute.SIGN_IN);
    }
  }, [anyMatches, isAuthenticated]);

  // we are on a public route, or user is authenticated
  if (anyMatches || isAuthenticated) {
    return (
      <AuthGuardProviderContext.Provider value={{ userInfo, reloadUserInfo }}>
        {isImpersonationMode ? (
          <ImpersonationWrapper onStop={handleStopImpersonation}>
            {children}
          </ImpersonationWrapper>
        ) : (
          children
        )}

      </AuthGuardProviderContext.Provider>
    );
  }

  // permission denied, redirection will be handled by `useEffect` section
  return <></>;
};
