import { Fragment, ChangeEvent, ReactElement } from 'react';
import { CreditCard } from '@localstack/types';

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  RadioGroup,
  Radio,
  Divider,
} from '@mui/material';

import { ConfirmableButton } from '../../../feedback';
import { CreditCardBrandIcon } from '../../../display/CreditCardBrandIcon';

export interface CreditCardsListProps {
  cards: CreditCard[];
  selectable?: boolean;
  selected?: Optional<CreditCard>;
  onSelect?: (card: CreditCard) => unknown;
  onDelete?: (card: CreditCard) => unknown;
}

export const CreditCardsList = ({
  cards,
  selectable,
  selected,
  onSelect,
  onDelete,
}: CreditCardsListProps): ReactElement => {
  const handleRadioGroupChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e?.currentTarget?.value;
    onSelect?.(cards.find(c => c.id === value) as CreditCard);
  };

  return (
    <>
      <RadioGroup
        value={selected?.id ?? ''}
        onChange={handleRadioGroupChange}
      >
        <List disablePadding>
          {cards.map((card) => (
            <Fragment key={card.id}>
              <ListItem button onClick={() => onSelect?.(card)}>
                {selectable && (
                  <ListItemAvatar>
                    <Radio color="primary" value={card.id} checked={selected?.id === card.id} />
                  </ListItemAvatar>
                )}
                <ListItemText
                  primary={`**** ${card.last4} (${card.currency?.toUpperCase()})`}
                  secondary={
                    <>
                      {card.brand}{' | '}
                      <ConfirmableButton
                        title="Removing Payment Method"
                        text={`Are you sure you want to remove this credit card (${card.brand} ****${card.last4})?
                          Removing this credit card will also remove it from all current subscriptions.`}
                        okText='Remove Card'
                        cancelText='Cancel'
                        componentType='Link'
                        onClick={() => onDelete?.(card)}
                        color="inherit"
                      >
                        Remove
                      </ConfirmableButton>

                      {/* <Link color="inherit" onClick={() => onDelete?.(card)}>Remove</Link> */}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <CreditCardBrandIcon brand={card.brand} />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider component="li" />
            </Fragment>
          ))}
        </List>
      </RadioGroup>
    </>
  );
};
