export const DEFAULT_PROBABILITY = 100;
export const DEFAULT_LATENCY = 5;
export const DEFAULT_SERVICES = ['s3', 'kms'];
export const DEFAULT_REGIONS = ['us-east-1', 'us-west-2'];

export const EXPERIMENT_NAMES = {
  internalServerError: 'internalServerError',
  serviceUnavailableError: 'serviceUnavailableError',
  regionUnavailableError: 'regionUnavailableError',
  latencyError: 'latencyError',
  dynamodbError: 'dynamodbError',
  kinesisError: 'kinesisError',
};
