/* eslint-disable max-len */
import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const ECS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ECS}`;

export const DEFAULT_ECS_ROUTES: Record<string,string> = {
  RESOURCES_ECS: `${ECS_BASE_ROUTE}`,
  RESOURCES_ECS_CLUSTERS: `${ECS_BASE_ROUTE}/clusters`,
  RESOURCES_ECS_CLUSTER: `${ECS_BASE_ROUTE}/clusters/:clusterName`,
  RESOURCES_ECS_CLUSTER_CREATE: `${ECS_BASE_ROUTE}/clusters/new`,
  RESOURCES_ECS_CLUSTER_UPDATE: `${ECS_BASE_ROUTE}/clusters/:clusterName/update`,
  RESOURCES_ECS_TASK_DEFINITIONS: `${ECS_BASE_ROUTE}/definitions`,
  RESOURCES_ECS_TASK_DEFINITION: `${ECS_BASE_ROUTE}/definitions/:family`,
  RESOURCES_ECS_TASK_DEFINITION_CREATE: `${ECS_BASE_ROUTE}/definitions/new`,
  RESOURCES_ECS_CLUSTER_SERVICES: `${ECS_BASE_ROUTE}/clusters/:clusterName/services`,
  RESOURCES_ECS_CLUSTER_SERVICE: `${ECS_BASE_ROUTE}/clusters/:clusterName/services/:arn`,
  RESOURCES_ECS_CLUSTER_SERVICE_CREATE: `${ECS_BASE_ROUTE}/clusters/:clusterName/services/new`,
  RESOURCES_ECS_CLUSTER_SERVICE_UPDATE: `${ECS_BASE_ROUTE}/clusters/:clusterName/services/:arn/update`,
  RESOURCES_ECS_CLUSTER_TASKS: `${ECS_BASE_ROUTE}/clusters/:clusterName/tasks`,
  RESOURCES_ECS_CLUSTER_TASK: `${ECS_BASE_ROUTE}/clusters/:clusterName/tasks/:arn`,
  RESOURCES_ECS_CLUSTER_TASK_DEPLOY: `${ECS_BASE_ROUTE}/clusters/:clusterName/tasks/:arn/deploy`,
};
