import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { ListClustersResponse } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type EKSClustersTableProps = {
  clusters: ListClustersResponse;
  onViewCluster: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const EKSClustersTable = ({
  clusters,
  onViewCluster,
  onSelect,
  loading,
}: EKSClustersTableProps): ReactElement => {
  const realClusters = clusters?.clusters?.map(cluster => ({ name: cluster }));

  return (
    <MagicTable
      entry="String"
      idAttribute="name"
      rows={realClusters || []}
      schema={getSchema('EKS')}
      externalFields={{
        name: ({ name }) => (
          <Link onClick={() => onViewCluster(name || '')} underline="hover">
            {name}
          </Link>
        ),
      }}
      loading={loading}
      onSelect={onSelect}
      selectable
      disableSelectionOnClick
    />
  );
};


