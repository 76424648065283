import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwRestApiUsagePlanDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiUsagePlanDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, usagePlanId } = useParams<'restApiId' | 'usagePlanId'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: plan } = useAwsGetter('APIGateway', 'getUsagePlan', [{ usagePlanId }], { clientOverrides });

  return (
    <Layout
      documentTitle="Plan Details"
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Plan Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [plan?.name, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(
            routes.RESOURCES_AGW1_API_USAGE_PLAN_UPDATE,
            { restApiId, usagePlanId },
          )}
        >
          Edit Plan
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details usagePlan={plan} />
        </CardContent>
      </Card>
    </Layout>
  );
};
