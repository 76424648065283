import { ReactElement } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { PlanChip } from '@localstack/ui';
import { PlanFamily } from '@localstack/types';

import { ExternalLink } from '@localstack/constants';

import { BaseLayout } from '~/layouts';

import { ExtensionsInstaller } from './components/ExtensionsRemote/ExtensionsInstaller';
import { ExtensionsBadgeGenerator } from './components/ExtensionsRemote/ExtensionBadgeGenerator';

const useStyles = makeStyles(() => ({
  gridItem: {
    gap: '2rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));



export const ExtensionsRemote = (): ReactElement => {
  const classes = useStyles();
  // this is a bit ugly but react-router-dom stops parsing at an anchor tag in the url
  const url = window.location.href;
  const gitUrl = url.split('?url=')[1];

  return (
    <BaseLayout documentTitle="Extensions Installer" hideNavigation>
      <Grid container
        alignItems='center' direction='column'
        style={{ flexGrow: 1, margin: '3rem 0', flexWrap: 'nowrap' }}
      >
        <Grid xs={11} sm={9} md={7} lg={5} xl={4} className={classes.gridItem}>
          <Typography variant="h2">Extensions Installer <PlanChip planFamily={PlanFamily.PRO_PLANS}/></Typography>
          { gitUrl
            ? <ExtensionsInstaller url={gitUrl} />
            : <ExtensionsBadgeGenerator />
          }
        </Grid>

        <Grid xs={11} sm={9} md={7} lg={5} xl={4} className={classes.gridItem}>
          <Typography style={{ marginTop: '5rem' }} variant='h4'>What are LocalStack Extensions?</Typography>
          <Typography color='textSecondary'>
            LocalStack Extensions allow you to enrich your developer experience with LocalStack.
            A LocalStack Extension is a Python application that runs together with LocalStack
            within the LocalStack container.
          </Typography>
          <Box>
            <Button variant='contained' color='primary'
              href={ExternalLink.DOCS_EXTENSIONS} target='_blank'
            >
              Learn more
            </Button>
          </Box>
        </Grid>
      </Grid>

    </BaseLayout>
  );
};
