import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiChip'] => ({
  styleOverrides: {
    clickable: {
      '&:hover, &:focus, &:active': {
        backgroundColor: '#EDF0F2',
      },
    },
    deletable: {
      '&:focus': {
        backgroundColor: palette.primary.light,
      },
    },
  },
});
