import { ReactElement } from 'react';
import { ECSCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface ECSClusterDetailsProps {
  cluster?: Optional<Partial<ECSCluster>>;
}

export const ECSClusterDetails = ({ cluster }: ECSClusterDetailsProps): ReactElement => (
  <MagicDetails
    data={cluster}
    schema={getSchema('ECS')}
    entry="Cluster"
    title="Cluster Details"
  />
);
