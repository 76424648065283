import { ReactElement } from 'react';
import { SNSMessagePublishRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form';

export type SNSMessagePublishFormProps = {
  loading?: boolean;
  arn?: string;
  formId: string;
  onSubmit: (data: SNSMessagePublishRequest) => unknown;
  clientOverrides?: AwsClientOverrides;
}

export const SNSMessagePublishForm = ({
  loading,
  arn,
  formId,
  onSubmit,
  clientOverrides,
}: SNSMessagePublishFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SNS')}
    formId={formId}
    entry="PublishInput"
    loading={loading}
    onSubmit={onSubmit}
    defaultValues={{ TopicArn: arn }}
    externalFields={{
      '^TopicArn$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='SNS' method='listTopics' arrayKeyName='Topics' property='TopicArn'
          fieldName={fieldName} entityName='Endpoint Config'
          creationRoute={DEFAULT_SNS_ROUTES.RESOURCES_SNS_TOPIC_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
  />
);
