import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  AgwApiAuthorizersTable,
} from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiAuthorizers = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { apiId: ApiId } = useParams<'apiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const { data: authorizers, isLoading, mutate } = useAwsGetter(
    'ApiGatewayV2',
    'getAuthorizers',
    [{ ApiId }],
    { clientOverrides },
  );

  const { deleteAuthorizer } = useAwsEffect(
    'ApiGatewayV2',
    ['deleteAuthorizer'],
    {
      revalidate: ['getAuthorizers', 'getAuthorizer'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((AuthorizerId) => deleteAuthorizer({ ApiId: ApiId as string, AuthorizerId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Authorizers"
      title={
        <PageTitle
          title="API Authorizers"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
            [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
            ['Authorizers', null],
          ]}
        />
      }
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW2_API_AUTHORIZER_CREATE, { apiId: ApiId })}>
            Create Authorizer
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Authorizer(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Authorizers will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwApiAuthorizersTable
          authorizers={authorizers?.Items || []}
          onSelect={setSelectedIds}
          onViewAuthorizer={
            ({ AuthorizerId }) => goto(
              routes.RESOURCES_AGW2_API_AUTHORIZER,
              { apiId: ApiId, authorizerId: AuthorizerId },
            )
          }
        />
      </Card>
    </Layout>
  );
};
