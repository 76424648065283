import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateIAMRoleRequest, UpdateIAMRoleRequest } from '@localstack/types';
import { ProgressButton, IAMRoleForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMRoleUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { roleName } = useParams<'roleName'>();

  const { createRole, updateRole, isLoading } = useAwsEffect(
    'IAM',
    ['createRole', 'updateRole'],
    { revalidate: ['getRole', 'listRoles'], clientOverrides },
  );

  const { data: role } = useAwsGetter('IAM', 'getRole', [{ RoleName: roleName }],{ clientOverrides });

  const handleCreate = async (data: CreateIAMRoleRequest) => {
    await createRole(data);
    goto(routes.RESOURCES_IAM_ROLES);
  };

  const handleUpdate = async (data: UpdateIAMRoleRequest) => {
    await updateRole(data);
    goto(routes.RESOURCES_IAM_ROLE, { roleName });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Role Details</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              [role?.Role?.RoleName, () => goto(routes.RESOURCES_IAM_ROLE, { roleName })],
              [roleName ? 'Update' : 'Create Role', null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs routes={routes}/>}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <IAMRoleForm
            iamRole={role?.Role}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
