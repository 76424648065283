import { ReactElement, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Box, CardActions, CardContent, Typography } from '@mui/material';
import { StartBackupJobRequest } from '@localstack/types';

import {
  BackupJobForm,
  ProgressButton,
  Breadcrumbs,
} from '@localstack/ui';

import {
  useRoutes,
  useAwsGetter,
  useAwsEffect,
  useAwsBatchIterator,
} from '@localstack/services';

import { DEFAULT_BACKUP_ROUTES } from '@localstack/constants';

import { BackupProps } from './types';

export const BackupJobCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_BACKUP_ROUTES,
}: BackupProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const { data: rdsDatabases, isLoading: isRdsLoading } =
    useAwsGetter('RDS', 'describeDBInstances', [], { clientOverrides });
  const { data: dynamodbTableNames, isLoading: isDdbLoading } =
    useAwsGetter('DynamoDB', 'listTables', [], { clientOverrides });

  const { describeTable } = useAwsEffect('DynamoDB', ['describeTable']);


  const { startBackupJob } = useAwsEffect(
    'Backup',
    ['startBackupJob'],
    { revalidate: ['listBackupJobs'], clientOverrides },
  );

  const dynamoDbTables = useAwsBatchIterator(
    dynamodbTableNames?.TableNames,
    (TableName) => describeTable({ TableName }),
  );

  const arns = useMemo(() => [
    ...dynamoDbTables.map(({ Table }) => Table?.TableArn as string),
    ...(rdsDatabases?.DBInstances?.map(({ DBInstanceArn }) => DBInstanceArn as string) ?? []),
  ], [dynamoDbTables, rdsDatabases]);

  const isLoading =
    isDdbLoading ||
    isRdsLoading;

  const handleStartBackupJob = useCallback(
    async (request: StartBackupJobRequest) => {
      await startBackupJob(request);
      goto(routes.RESOURCES_BACKUP_VAULT, { name });
    }, [],
  );

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Create Backup Job</Typography>
          <Breadcrumbs
            mappings={[
              ['Vaults', () => goto(routes.RESOURCES_BACKUP_VAULTS)],
              [name, () => goto(routes.RESOURCES_BACKUP_VAULT, { name })],
              ['Create Job', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateBackupJob"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <BackupJobForm
            clientOverrides={clientOverrides}
            onCreate={handleStartBackupJob}
            formId="CreateBackupJob"
            arns={arns}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateBackupJob"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
