import { ReactElement } from 'react';
import { Box, List, ListItem, Theme, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

type TooltipPayload = {
  dataKey: string;
  name: string;
  color: string;
  value: string
};
export type TooltipProps = {
  active: boolean;
  payload: TooltipPayload[];
  label: string;
  order?: string[];
};

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '15px',
  },
  tooltipItem: {
    padding: `${theme.spacing(0.5)} 0`,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  tooltipIcon: {
    width: '15px',
    height: '15px',
    marginRight: theme.spacing(1),
    boxShadow: `0px 0px 7px ${theme.palette.divider}`,
  },
}));

const sortByOrder = (payload: TooltipPayload[], order: string[]) =>
  payload.sort((a, b) => order.indexOf(a.dataKey) - order.indexOf(b.dataKey));

export const ChartTooltip = ({ active, payload, label, order }: TooltipProps): ReactElement => {
  const classes = useStyles();
  return active && payload.length > 0 ? (
    <div className={classes.tooltip}>
      <Box paddingBottom={1}>
        <Typography variant="h5"><b>{label}</b></Typography>
      </Box>
      <List disablePadding>
        {(order ? sortByOrder(payload, order) : payload).map(item => (
          <ListItem key={item.dataKey} className={classes.tooltipItem}>
            <div className={classes.tooltipIcon}>
              <svg width="100%" height="100%">
                <rect width="100%" height="100%" fill={item.color} />
              </svg>
            </div>
            <Typography variant="body1">{item.name}: <b>{item.value}</b></Typography>
          </ListItem>
        ))}
      </List>
    </div>
  ) : <></>;
};
