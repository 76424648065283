import { ReactElement } from 'react';
import { SNSSubscription } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type SNSSubscriptionTableProps = {
  selectable?: boolean;
  loading?: boolean;
  entries: SNSSubscription[];
  onSelect?: (names: string[]) => void;
  onViewSNSSubscription?: (subscription: SNSSubscription) => void;
};

export const SNSSubscriptionTable = ({
  entries,
  selectable = true,
  loading,
  onSelect,
  onViewSNSSubscription,
}: SNSSubscriptionTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('SNS')}
    entry="Subscription"
    idAttribute="SubscriptionArn"
    order={['SubscriptionArn']}
    rows={entries}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      SubscriptionArn: (row) => (
        <Link
          onClick={() => onViewSNSSubscription && onViewSNSSubscription(row)}
          underline="hover"
        >
          {row.SubscriptionArn}
        </Link>
      ),
    }}
  />
);
