import React, { ReactElement, useEffect, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, ListItem } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

import { CodeSnippetViewer } from '@localstack/ui';

import { GeneratedPolicy } from '@localstack/types';

import { PolicyCard } from '../PolicyCard';

interface PolicySummaryListItemProps {
  summary: GeneratedPolicy,
  expand: boolean,
}


const useStyles = makeStyles(() => createStyles({
  accordion: {
    border: 'none',
    boxShadow: 'none',
    padding: 0,
    width: '100%',
    '&::before': {
      display: 'none',
    },
  },
  accordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const PolicySummaryListItem = ({ summary, expand }: PolicySummaryListItemProps): ReactElement => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<boolean>(expand);
  useEffect(() => setExpanded(expand), [expand]);

  return (
    <ListItem key={summary.resource} divider>
      <Accordion
        expanded={expanded}
        onChange={(_event, state) => setExpanded(state)}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.accordionSummary}
        >
          <PolicyCard policy={summary} showCode={false} />
        </AccordionSummary>
        <AccordionDetails>
          <CodeSnippetViewer data={JSON.stringify(summary.policy_document)} />
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

export const MemoizedPolicySummaryListItem = React.memo(PolicySummaryListItem);
