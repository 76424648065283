import { useState, useCallback, ReactElement } from 'react';
import { Button, Card, Box } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { Dropdown, ConfirmableButton, S3ObjectsTable, S3ObjectBreadcrumbs, PageTitle } from '@localstack/ui';
import { DEFAULT_S3_ROUTES } from '@localstack/constants';

import {
  useRoutes,
  useRegion,
  downloadFileContent,
  useAwsEffect,
  useAwsGetter,
  expandS3Folders,
} from '@localstack/services';

import { DeleteS3ObjectsRequest, ListS3ObjectsV2Request, S3EmptyBucketFolderArgs } from '@localstack/types';

import { S3Props } from './types';
import { BucketTabs } from './components';

export const S3Objects = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_S3_ROUTES,
}: S3Props): ReactElement => {
  const { goto } = useRoutes();

  const [searchParams] = useSearchParams();
  const { bucket } = useParams<'bucket'>();
  const { region } = useRegion();

  const [fileInputValue, setFileInputValue] = useState('');

  const prefix = searchParams.get('prefix') || '';

  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

  const { deleteObjects, getObject, upload, listObjectsV2 } = useAwsEffect(
    'S3',
    ['getObject', 'upload', 'deleteObjects', 'listObjectsV2'],
    {
      revalidate: ['listObjectsV2'],
      clientOverrides: { s3ForcePathStyle: true, ...clientOverrides },
    },
  );

  const { data: rawObjects, isLoading, mutate } = useAwsGetter(
    'S3',
    'listObjectsV2',
    [{ Bucket: bucket, Prefix: prefix ? `${prefix}/` : prefix, MaxKeys: 1000 }],
    { clientOverrides: { s3ForcePathStyle: true, ...clientOverrides } },
  );

  const objects = expandS3Folders(rawObjects?.Contents ?? [], prefix);

  const handleUploadFiles = useCallback(
    async (files: File[]) => Promise.all(
      files.map(async (data) => {
        upload({
          Bucket: bucket as string,
          Body: data instanceof Blob ? data : new Blob([data]),
          Key: prefix ? `${prefix}/${data.name}` : data.name,
        });
        setFileInputValue(''); // clear the file names after every upload
      }),
    ),
    [bucket, prefix],
  );

  const emptyBucketFolder = async (data: S3EmptyBucketFolderArgs) => {
    const { Bucket, Prefix } = data;
    const listParams: ListS3ObjectsV2Request = {
      Bucket,
      Prefix: `${Prefix}/`,
    };

    const listData = await listObjectsV2(listParams);

    if (!listData.Contents) return;

    const filesToDelete = listData.Contents.map((item) => ({ Key: item.Key || '' })) || [];

    const deleteParams: DeleteS3ObjectsRequest = {
      Bucket,
      Delete: {
        Objects: filesToDelete.concat({ Key: Prefix }),
      },
    };

    await deleteObjects(deleteParams);

    if (listData.IsTruncated) {
      await emptyBucketFolder({
        Bucket,
        Prefix: `${Prefix}/`,
      });
    }
  };

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selectedObjects.map((Key) => emptyBucketFolder({
      Bucket: bucket as string,
      Prefix: Key,
    }))),
    [selectedObjects],
  );

  const handleDownloadObject = useCallback(
    async (key: string) => {
      const content = await getObject({ Bucket: bucket as string, Key: key });
      if (content) downloadFileContent(content.Body as Blob, key, content.ContentType as string);
    },
    [selectedObjects, region, bucket],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title={`Bucket ${bucket}`}
            onMutate={mutate}
          />
        }
        tabs={<BucketTabs routes={routes} bucket={bucket ?? ''} />}
        actions={
          <>
            <Button onClick={() => prefix ? goto(routes.RESOURCES_S3_FOLDER_CREATE_PREFIX, { bucket, prefix })
              : goto(routes.RESOURCES_S3_FOLDER_CREATE, { bucket })}
            >
              Create Folder
            </Button>
            <Button component="label">
              Upload
              <input
                type="file"
                hidden
                value={fileInputValue}
                multiple
                onChange={(event) => handleUploadFiles(Array.from(event.target.files ?? []) as File[])}
              />
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedObjects.length === 0 || isLoading}
                title={`Remove ${selectedObjects.length} object(s)?`}
                onClick={handleDeleteObjects}
                text="Selected Objects will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <Box p={2}>
            <S3ObjectBreadcrumbs
              bucket={bucket as string}
              prefix={prefix}
              onViewRoot={() => goto(routes.RESOURCES_S3)}
              onViewBucket={(b, p) => goto(routes.RESOURCES_S3_BUCKET, { bucket: b }, p && `prefix=${p}`)}
            />
          </Box>
          <S3ObjectsTable
            selectable
            objects={objects}
            prefix={prefix}
            onSelect={setSelectedObjects}
            onViewObject={(pref) => goto(routes.RESOURCES_S3_BUCKET, { bucket: bucket as string }, `prefix=${pref}`)}
            onDownloadObject={handleDownloadObject}
          />
        </Card>
      </Layout>
    </>
  );
};
