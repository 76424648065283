import { ReactElement } from 'react';
import { CreateEventBridgeEventBusRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface EventBridgeBusFormProps {
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateEventBridgeEventBusRequest) => unknown;
}

export const EventBridgeBusForm = ({
  loading,
  formId,
  onCreate,
}: EventBridgeBusFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('EventBridge')}
    loading={loading}
    entry="CreateEventBusRequest"
    formId={formId}
    onSubmit={(data: CreateEventBridgeEventBusRequest) => onCreate(data)}
  />
);
