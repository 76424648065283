import { ReactElement, useEffect } from 'react';
import { Grid } from '@mui/material';
import { ControlledRangeSlider } from '@localstack/ui';
import { useForm } from 'react-hook-form';

import { getDynamoDbErrorTemplate } from '../../ExperimentTemplates';
import { DEFAULT_PROBABILITY } from '../../constants';
import { ExperimentFormValues, ExperimentProps } from '../../types';

import { ExperimentCard } from '../ExperimentCard';
import { UpdateExperimentButton } from '../UpdateExperimentButton';

import { useErrorCardStyles } from './styles';

export const DynamodbErrorCard = (props: ExperimentProps): ReactElement => {
  const classes = useErrorCardStyles();
  const { experiment: experimentState, alert, onUpsertExperiment } = props;
  const experiment = Array.isArray(experimentState) ? experimentState[0] : undefined;

  const { control, handleSubmit, formState, watch, reset } = useForm<ExperimentFormValues>({
    mode: 'all',
    defaultValues: {
      probability: DEFAULT_PROBABILITY,
    },
  });
  useEffect(() => {
    if (!experiment?.probability) return;
    reset({
      probability: experiment.probability * 100,
    });
  }, [experiment]);
  const formValues = watch();
  const template = getDynamoDbErrorTemplate(formValues);
  return (
    <ExperimentCard
      experimentCard={{
        title: 'Dynamodb Error',
        description: <>Randomly inject <code className={classes.code}>ProvisionedThroughputExceededException</code>
          {' '} errors into DynamoDB API responses.</>,
        template,
        options: (
          <form onSubmit={handleSubmit(_ => onUpsertExperiment(template, experimentState))}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledRangeSlider
                  control={control}
                  name="probability"
                  label="Fault Probability"
                  postfixElement={<>%</>}
                  inputProps={{
                    type: 'number',
                    style: { textAlign: 'center', width: 80 },
                  }}
                  sliderProps={{
                    step: 1,
                    min: 1,
                    max: 100,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <UpdateExperimentButton formState={formState} experimentState={experimentState} alert={alert} />
              </Grid>
            </Grid>
          </form>
        ),
      }}
      formState={formState}
      {...props}
    />
  );
};
