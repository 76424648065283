/* eslint-disable max-len */

// TODO: could be translation strings living in transifex or whatever
export const BACKEND_ERROR_MAP = {
  // generic
  'generic.unhandled': 'An unknown error occurred',
  'generic.bad_request': 'The request was malformed or incomplete',
  'generic.unauthorised': 'Resource requires authorization',
  'generic.unprocessable': 'We were unable to process the request',
  'generic.forbidden': 'You have no access to the requested resource',
  'generic.not_found': 'The requested resource was not found',

  // extensions
  'extensions.duplicate_plugin_name': 'An extension with the same name identifiers already exists',
  'extensions.package_not_found': 'We couldn\'t find your package on pypi',

  // auth
  'auth.error': 'We were unable to verify your credentials',
  'auth.token_refresh': 'Failed to refresh your authentication token, please log in again',
  'auth.email_format': 'Invalid email address',
  'auth.weak_credentials': 'The specified password is too weak, use uppercase characters as well as numbers and special symbols',
  'auth.same_credentials': 'Please try a different password as this one matches the old one',
  'auth.update_credentials': 'We were unable to update your credentials',
  'auth.create_identity': 'There was an error during account creation',
  'auth.identity_exists': 'A user with that email already exists',
  'auth.not_activated': 'Your account has not been activated, please follow the link from the activation email',
  'auth.auth_request': 'Invalid authentication request',

  // entity
  'entity.validation_error': 'Entity validation failed',

  // idp
  'idp.invalid_name': 'Identity provider has an invalid name, please only use alphanumeric characters and "-"',

  // memberships
  'membership.invalid_org': 'Failed to join organization. Please make sure the invite is correct and still valid',
  'membership.not_found': 'Failed to join organization because the invite is invalid or has expired',
  'membership.password_required': 'Please provide a password for your new account',
  'membership.already_in_org': 'User already part of the organization',

  // billing
  'billing.card_not_found': 'The specified card was not found',

  // user
  'user.password_recovery': 'Failed to recover password. Please try again or contact us at info@localstack.cloud',

  // artifacts (not used on FE)
  'artifact.not_found': '',
  'artifact.invalid_or_expired_token': '',

  // ci
  'ci.run_not_found': 'The specified CI run could not be found',
  'ci.run_already_exists': 'The specified CI run already exists',
  'ci.project_already_exists': 'The specified CI project already exists',

  // marketplace
  'marketplace.link_exists': 'Your organization has already been linked to the AWS Marketplace',

  // recaptcha
  'recaptcha.verification': 'Recaptcha verification failed, please try again',

  // license (not used on FE)
  'license.activation': '',
  'license.unknown_version': '',
  'license.subscription_expired': '',
  'license.usage_exceeded': '',
  'license.self_serve_exhausted': 'No more seats left within the subscriptions of your organization, please contact your organization admin to upgrade their subscription(s)',

  // subscription
  'subscription.unhandled': 'There was an unknown error during subscription processing',
  'subscription.not_found': 'The specified subscription could not be found',
  'subscription.subscribe': 'Failed to create subscription, please try again or contact us at info@localstack.cloud',
  'subscription.unsubscribe': 'We were unable to cancel your subscription, please try again or contact us at info@localstack.cloud',
  'subscription.update': 'We were unable to update your subscription terms, please try again or contact us at info@localstack.cloud',

  // compute
  'compute.already_exists': 'Please choose a different name, as an instance with it already exists',
  'compute.general_error': 'An error has occured, please try again',
  'compute.invalid_argument': 'Received an invalid argument',
  'compute.failed_precondition': 'Precondition was not met',
  'compute.not_found': 'Did not find the requested resource',
  'compute.resource_exhausted': 'You have exhausted your resource limit',
};
