import { ReactElement } from 'react';
import { Typography } from '@mui/material';
import { formatDateTime } from '@localstack/services';

import {
  ModelAttributeChange,
  Organization,
  OrganizationMember,
  User,
  Subscription,
  CreditCard,
  AuditTrailEvent,
} from '@localstack/types';

import { formatStandardAttributeChange } from './formatters';
import { AttributeMappingCallbacks } from './types';

/**
 * Rules defining how to display attribute changes.
 * - The rules are executed sequentially one by one.
 * - The changes that aren't covered by any rules are not displayed.
 *
 * Example:
 * {
 *   regex: /^attribute_name/,
 *
 *   // Displayed when a new attribute is added
 *   add: (entity: User, change: ModelAttributeChange) => ReactElement,
 *
 *   // Displayed when the attribute value is changed
 *   replace: (entity: User, change: ModelAttributeChange) => ReactElement,
 *
 *   // Displayed when the attribute value is moved to another place (i.e. array is re-arranged)
 *   move: (entity: User, change: ModelAttributeChange) => ReactElement,
 *
 *   // Displayed when the attribute is removed
 *   remove: (entity: User, change: ModelAttributeChange) => ReactElement,
 * }
 */

// be careful with /g as it changes the lastIndex resulting in false-negatives inside loops
export const USER_MAPPINGS: ({ regex: RegExp } & AttributeMappingCallbacks)[] = [
  {
    regex: /^$/,
    add: (entity: User, _: ModelAttributeChange) => (
      <Typography variant="caption" component="div">
        created user account <u>{entity.email}</u>
      </Typography>
    ),
    remove: (entity: User, _: ModelAttributeChange) => (
      <Typography variant="caption" component="div">
        removed user account <u>{entity.email}</u>
      </Typography>
    ),
  },
  {
    regex: /^(email|name|firstname|lastname|phone|free_beta|activation)$/,
    ...formatStandardAttributeChange((entity: User) => <>for user account <u>{entity.email}</u></>),
  },
  {
    regex: /^(last_hst_ts)$/,
    add: (_: User, change: ModelAttributeChange) => (
      <Typography variant="caption" component="div">
        signed in on {formatDateTime(change.new_value as number)}
      </Typography>
    ),
    replace: (_: User, change: ModelAttributeChange) => (
      <Typography variant="caption" component="div">
        signed in on {formatDateTime(change.new_value as number)}
      </Typography>
    ),
  },
  {
    regex: /^organizations\.\d+$/,
    add: (entity: User, change: ModelAttributeChange) => (
      <Typography variant="caption" component="div">
        added user <u>{entity.email}</u> to the workspace with ID {change.new_value}
      </Typography>
    ),
    remove: (entity: User, change: ModelAttributeChange) => (
      <Typography variant="caption" component="div">
        removed user <u>{entity.email}</u> from the workspace with ID {change.old_value}
      </Typography>
    ),
  },
  // fallback (uncomment to display a fallback msg for unmapped changes)
  // {
  //   regex: /^.+$/,
  //   ...formatFallbackAttributeChange(() => <>other account updates</>),
  // },
];

const ORG_MAPPINGS: ({ regex: RegExp } & AttributeMappingCallbacks)[] = [
  {
    regex: /^$/,
    add: (entity: Organization, _: ModelAttributeChange) => (
      <Typography variant="caption" component="div">
        created workspace <u>{entity.name}</u>
      </Typography>
    ),
    remove: (entity: Organization, _: ModelAttributeChange) => (
      <Typography variant="caption" component="div">
        removed workspace <u>{entity.name}</u>
      </Typography>
    ),
  },
  {
    regex: /^(name|email|address|city|state|country|zip|phone)$/,
    ...formatStandardAttributeChange((entity: Organization) => <>for workspace <u>{entity.name}</u></>),
  },
  {
    regex: /^members\.\d+$/,
    add: (entity: Organization, change: ModelAttributeChange): ReactElement => (
      <Typography variant="caption" component="div">
        invited member <u>{(change.new_value as OrganizationMember).email}</u>{' '}
        to the workspace <strong>{entity.name}</strong>
      </Typography>
    ),
    remove: (entity: Organization, change: ModelAttributeChange): ReactElement => (
      <Typography variant="caption" component="div">
        removed member <u>{(change.old_value as OrganizationMember).email}</u>{' '}
        from the workspace <strong>{entity.name}</strong>
      </Typography>
    ),
  },
  {
    regex: /^subscriptions\.\d+$/,
    add: (entity: Organization, change: ModelAttributeChange): ReactElement => (
      <Typography variant="caption" component="div">
        added subscription <u>{change.new_value as string}</u>{' '}
        to the workspace <strong>{entity.name}</strong>
      </Typography>
    ),
    remove: (entity: Organization, change: ModelAttributeChange): ReactElement => (
      <Typography variant="caption" component="div">
        removed subscription <u>{change.new_value as string}</u>{' '}
        from the workspace <strong>{entity.name}</strong>
      </Typography>
    ),
  },
  // note since members are not entities on their own it is very
  // hard to reliably extract which exact member got changed
  {
    regex: /^members\.\d+\.(name|email)$/,
    ...formatStandardAttributeChange((_: Organization) => <></>),
  },
  // fallback (uncomment to display a fallback msg for unmapped changes)
  // {
  //   regex: /^.+$/,
  //   ...formatFallbackAttributeChange(() => <>other workspace updates</>),
  // },
];

const SUBSCRIPTION_MAPPINGS: ({ regex: RegExp } & AttributeMappingCallbacks)[] = [
  {
    regex: /^$/,
    add: (entity: Subscription, _: ModelAttributeChange, event: AuditTrailEvent) => (
      <Typography variant="caption" component="div">
        subscribed <u>{event.subject.org_name}</u> to a new plan <u>{entity.plan.name}</u>
      </Typography>
    ),
    remove: (entity: Subscription, _: ModelAttributeChange, event: AuditTrailEvent) => (
      <Typography variant='caption' component='div'>
        unsubscribed <u>{event.subject.org_name}</u> from plan <u>{entity.plan.name}</u>
      </Typography>
    ),
  },
];

const CARD_MAPPINGS: ({ regex: RegExp } & AttributeMappingCallbacks)[] = [
  {
    regex: /^$/,
    add: (entity: CreditCard, _: ModelAttributeChange, event: AuditTrailEvent) => (
      <Typography variant="caption" component="div">
        added a new card <u>{entity.last4}</u> to the workspace <u>{event.subject.org_name}</u>
      </Typography>
    ),
    remove: (entity: CreditCard, _: ModelAttributeChange, event: AuditTrailEvent) => (
      <Typography variant='caption' component='div'>
        removed card <u>{entity.last4}</u> from the workspace <u>{event.subject.org_name}</u>
      </Typography>
    ),
  },
];

export const RECOGNIZED_UPDATE_MAPPINGS = {
  'User': USER_MAPPINGS,
  'Organization': ORG_MAPPINGS,
  'Subscription': SUBSCRIPTION_MAPPINGS,
  'CreditCard': CARD_MAPPINGS,
};
