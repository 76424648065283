import { ComputeService, getSelectedInstance, useApiGetter, useLocalstackStatus } from '@localstack/services';
import { ProductFeature , LocalStackEvent } from '@localstack/types';
import { LoadingFragment, LogOutput, PageTitle } from '@localstack/ui';
import { Grid } from '@mui/material';
import { ReactElement, useMemo } from 'react';

import { NotRunningAlert } from '~/components';
import { useAuthProvider } from '~/hooks';
import { CustomerLayout } from '~/layouts';


export const InstanceLogs = (): ReactElement => {
  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};
  const { running } = useLocalstackStatus(clientOverrides);

  const { hasFeature } = useAuthProvider();

  const hasAccessToEphemeralInstances = hasFeature(ProductFeature.FEATURE_COMPUTE);

  const { data: logs, mutate, isLoading: isLoadingLogs } =
    useApiGetter(
      ComputeService, 'getEphemeralInstanceLogs', [instance?.name], { 
        enable: hasAccessToEphemeralInstances && !!instance?.name, 
      },
    );

  const mappedLogs: LocalStackEvent[] | undefined = useMemo(
    () => logs?.map(log => ({ 'event': 'log', 'message': log.content })), [logs],
  );

  return (
    <CustomerLayout
      title={<PageTitle title="Log Output" onMutate={mutate} />}
    >
      <Grid container spacing={2}>
        {!running &&
          <Grid item xs={12}>
            <NotRunningAlert/>
          </Grid>
        }
        <Grid item xs={12}>
          <LoadingFragment loading={isLoadingLogs} variant="card" height={300}>
            <LogOutput logEvents={mappedLogs} level='all' hideMoreButton />
          </LoadingFragment>
        </Grid>
      </Grid>
    </CustomerLayout>
  );

};
