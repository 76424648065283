import { ReactElement } from 'react';
import { Alert , Card, CardContent, Button, Box, Typography } from '@mui/material';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Breadcrumbs, AgwRestApiIntegrationDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiMethodNavTabs } from './components';

export const AgwRestApiIntegrationDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, resourceId, httpMethod } = useParams<'restApiId' | 'resourceId' | 'httpMethod'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: resource } = useAwsGetter(
    'APIGateway',
    'getResource',
    [{ restApiId, resourceId }],
    { clientOverrides },
  );

  const { data: method } = useAwsGetter(
    'APIGateway',
    'getMethod',
    [{ restApiId, resourceId, httpMethod }],
    { clientOverrides },
  );

  // Note that even if integration does not exist we get an empty object with '$response' as a result
  const { data: integration, isLoading } = useAwsGetter(
    'APIGateway',
    'getIntegration',
    [{ restApiId, resourceId, httpMethod }],
  );

  return (
    <Layout
      documentTitle="Integration Details"
      tabs={
        <RestApiMethodNavTabs
          restApiId={restApiId as string}
          resourceId={resourceId as string}
          httpMethod={httpMethod as string}
          routes={routes}
        />
      }
      title={
        <Box>
          <Typography variant="h4">Integration Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [resource?.path, () => goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })],
              [
                method?.httpMethod,
                () => goto(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod }),
              ],
              ['Integration', null],
            ]}
          />
        </Box>
      }
      actions={
        <>
          {integration?.httpMethod && !isLoading && (
            <Button
              onClick={() => goto(
                routes.RESOURCES_AGW1_API_INTEGRATION_UPDATE,
                { restApiId, resourceId, httpMethod },
              )}
            >
              Edit Integration
            </Button>
          )}
          {!integration?.httpMethod && !isLoading && (
            <Button
              onClick={() => goto(
                routes.RESOURCES_AGW1_API_INTEGRATION_CREATE,
                { restApiId, resourceId, httpMethod },
              )}
            >
              Create Integration
            </Button>
          )}
        </>
      }
    >
      <Card>
        <CardContent>
          {integration && !isLoading && <Details integration={integration} />}
          {!integration && !isLoading && (
            <Alert severity="info">
              No Integration to show
            </Alert>
          )}
        </CardContent>
      </Card>
    </Layout>
  );
};
