import { ReactElement } from 'react';
import { IAMUser, CreateIAMUserRequest, UpdateIAMUserRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface IAMUserFormProps {
  user?: Optional<Partial<IAMUser>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateIAMUserRequest) => unknown;
  onUpdate: (data: UpdateIAMUserRequest) => unknown;
}

export const IAMUserForm = ({ user, loading, formId, onCreate, onUpdate }: IAMUserFormProps): ReactElement => (
  <MagicForm
    data={user}
    schema={getSchema('IAM')}
    loading={loading}
    entry="CreateUserRequest"
    formId={formId}
    fieldConditions={{
      '^PermissionsBoundary$': !user,
    }}
    onSubmit={(data: CreateIAMUserRequest) => {
      if (!user) return onCreate(data);
      return onUpdate({ UserName: user?.UserName as string, NewUserName: data.UserName, NewPath: data.Path });
    }}
  />
);
