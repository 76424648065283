import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwApiStageDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiStageDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId, stageName: StageName } = useParams<'apiId' | 'stageName'>();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const { data: stage } = useAwsGetter(
    'ApiGatewayV2',
    'getStage',
    [{ ApiId, StageName }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Stage Details"
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Stage Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [stage?.StageName, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_AGW2_API_STAGE_UPDATE, { apiId: ApiId, stageName: StageName })}
        >
          Edit Stage
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details stage={stage} />
        </CardContent>
      </Card>
    </Layout>
  );
};
