import { useState, ReactElement } from 'react';
import { Box, TextField, MenuItem } from '@mui/material';
import { AddCognitoUserToGroupRequest, CognitoGroup } from '@localstack/types';

import { ProgressButton } from '../../../feedback';

export interface CognitoUserGroupAssignmentFormProps {
  poolId: string;
  username: string;
  groups: CognitoGroup[];
  loading?: boolean;
  onAssign?: (req: AddCognitoUserToGroupRequest) => unknown;
}

export const CognitoUserGroupAssignmentForm = ({
  poolId,
  username,
  groups,
  loading,
  onAssign,
}: CognitoUserGroupAssignmentFormProps): ReactElement => {
  const [groupName, setGroupName] = useState<Optional<string>>(null);
  return (
    <Box display="flex" alignItems="center">
      <Box flexGrow={1} mr={1}>
        <TextField
          select
          fullWidth
          value={groupName ?? ''}
          size="small"
          variant="outlined"
          label="Assign User to Group"
          onChange={(event) => setGroupName(event.target.value as string)}
        >
          {groups.map((group) => <MenuItem key={group.GroupName} value={group.GroupName}>{group.GroupName}</MenuItem>)}
        </TextField>
      </Box>
      <ProgressButton
        variant="outlined"
        color="primary"
        loading={loading}
        disabled={!groupName}
        onClick={() => onAssign?.({ UserPoolId: poolId, Username: username, GroupName: groupName as string })}
      >
        Assign
      </ProgressButton>
    </Box>
  );
};
