import { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';

import { ThemeType } from '@localstack/constants';

import LogoSvg from './logos/localstack.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .text': {
        fill: theme.palette.mode === ThemeType.DARK ? '#FFF' : undefined,
      },
      marginBottom: '-8px',
    },
    medium: {
      height: theme.spacing(5),
      width: 'auto',
    },
    dark: {
      '& .text': {
        fill: '#FFF',
      },
    },
    white: {
      '& .text': {
        fill: '#FFF',
      },
      '& .rocket': {
        fill: '#FFF',
      },
    },
  }),
);

export type LogoProps = {
  size?: 'medium';
  variant?: 'light' | 'dark' | 'default' | 'white';
};

export const Logo = ({ size = 'medium', variant = 'default' }: LogoProps): ReactElement => {
  const classes = useStyles();

  return (
    <LogoSvg
      className={classnames(classes.root, {
        [classes.medium]: size === 'medium',
        [classes.dark]: variant === 'dark',
        [classes.white]: variant === 'white',
      })}
    />
  );
};
