import { useEffect, ReactElement, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';

import { UserService, useApiEffect, useGlobalSwr } from '@localstack/services';

import { BaseLayout } from '~/layouts';
import { AppRoute } from '~/config';
import { retrieveAuthToken, clearStorage } from '~/util/storage';
import { useHubspotProvider } from '~/components/HubspotProvider';

export const GlobalSignOut = (): ReactElement => {
  useEffect(() => {
    document.title = 'Global Sign Out - LocalStack';
    handleSignOut();
  }, []);

  const { globalSignoutUser } = useApiEffect(
    UserService,
    ['globalSignoutUser'],
    { suppressErrors: true },
  );

  const { cleanupHubspotUser } = useHubspotProvider();
  const { clearAll } = useGlobalSwr();

  const [isSignedOut, setIsSignedOut] = useState(false);

  const handleSignOut = async () => {
    const authToken = retrieveAuthToken();

    if (!authToken) return;

    await globalSignoutUser(authToken);

    // clean local storage and swr cache
    clearStorage();
    clearAll();
    cleanupHubspotUser();
    setIsSignedOut(true);
  };

  return (
    <BaseLayout hideNavigation>
      <Box flexGrow={1}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {!isSignedOut ? (
            <Typography variant="h3">
              Signing out user on all devices...
            </Typography>
          ) : (
            <>
              <Typography variant="h3">
                You have been signed out successfully.
              </Typography>
              <Box mt={2}>
                <Typography variant="h2" component="span">
                  <Link to={AppRoute.SIGN_IN} component={RouterLink} underline="hover">
                    Sign in again
                  </Link>
                </Typography>
              </Box>
            </>
          )}

        </Box>
      </Box>
    </BaseLayout>
  );
};
