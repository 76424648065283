import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiTableCell'] => ({
  styleOverrides: {
    root: {
      borderBottom: `1px solid ${palette.divider}`,
      padding: '0px',
    },
    head: {
      fontSize: '14px',
      color: palette.text.primary,
    },
    sizeSmall: {
      padding: '0px',
    },
  },
});
