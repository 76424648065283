/* eslint-disable max-len */
import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const COGNITO_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.COGNITOIDP}`;
const COGNITO_IDENTITY_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.COGNITOIDENTITY}`;

export const DEFAULT_COGNITO_ROUTES: Record<string, string> = {
  // Cognito
  RESOURCES_COGNITO: `${COGNITO_BASE_ROUTE}`,
  RESOURCES_COGNITO_USER_POOL: `${COGNITO_BASE_ROUTE}/:poolId`,
  RESOURCES_COGNITO_USER_POOL_CREATE: `${COGNITO_BASE_ROUTE}/new`,
  RESOURCES_COGNITO_USER_POOL_UPDATE: `${COGNITO_BASE_ROUTE}/:poolId/update`,
  RESOURCES_COGNITO_POOL_USERS: `${COGNITO_BASE_ROUTE}/:poolId/users`,
  RESOURCES_COGNITO_POOL_USER: `${COGNITO_BASE_ROUTE}/:poolId/users/:username`,
  RESOURCES_COGNITO_POOL_GROUPS: `${COGNITO_BASE_ROUTE}/:poolId/groups`,
  RESOURCES_COGNITO_POOL_GROUP: `${COGNITO_BASE_ROUTE}/:poolId/groups/:groupName`,
  RESOURCES_COGNITO_POOL_GROUP_CREATE: `${COGNITO_BASE_ROUTE}/:poolId/groups/new`,
  RESOURCES_COGNITO_POOL_GROUP_UPDATE: `${COGNITO_BASE_ROUTE}/:poolId/groups/:groupName/update`,
  RESOURCES_COGNITO_POOL_USER_CREATE: `${COGNITO_BASE_ROUTE}/:poolId/users/new`,
  RESOURCES_COGNITO_POOL_USER_UPDATE: `${COGNITO_BASE_ROUTE}/:poolId/users/:username/update`,
  RESOURCES_COGNITO_POOL_USER_GROUPS: `${COGNITO_BASE_ROUTE}/:poolId/users/:username/groups`,
  // Cognito Identity
  RESOURCES_COGNITO_IDENTITY_POOLS: `${COGNITO_IDENTITY_BASE_ROUTE}/pools`,
  RESOURCES_COGNITO_IDENTITY_POOL: `${COGNITO_IDENTITY_BASE_ROUTE}/pool/:id`,
  RESOURCES_COGNITO_IDENTITY_POOL_CREATE: `${COGNITO_IDENTITY_BASE_ROUTE}/pool/new`,
  RESOURCES_COGNITO_IDENTITY_POOL_UPDATE: `${COGNITO_IDENTITY_BASE_ROUTE}/pool/:id/update`,
};
