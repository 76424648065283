import { ReactElement, useState, useEffect, Fragment } from 'react';
import { AdminService, useApiGetter, useApiEffect } from '@localstack/services';
import { ProductFeature, FeatureOverrides } from '@localstack/types';
import { ProgressButton, LoadingFragment } from '@localstack/ui';

import {
  Delete as DeleteIcon,
  Add as PlusIcon,
  Restore as RestoreIcon,
} from '@mui/icons-material';

import {
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  ListItemAvatar,
  Card,
  CardHeader,
  CardActions,
  IconButton,
  TextField,
  Autocomplete,
  Chip,
  Box,
} from '@mui/material';

const FEATURE_NAME_MAPPING: Record<ProductFeature, string> = {
  [ProductFeature.FEATURE_PODS]: 'Owned Cloud Pods',
  [ProductFeature.FEATURE_PODS_PUBLIC]: 'Public Cloud Pods',
  [ProductFeature.FEATURE_STACKS_PREVIEW]: 'Basic Stack Insights',
  [ProductFeature.FEATURE_STACKS_PREMIUM]: 'Extended Stack Insights',
  [ProductFeature.FEATURE_CI_PROJECTS]: 'CI Projects',
  [ProductFeature.FEATURE_COMPUTE]: 'Ephemeral Instances',
  [ProductFeature.FEATURE_LOCALSTACK_PRO]: 'LocalStack Pro Activation',
  [ProductFeature.FEATURE_LOCALSTACK_EXTENSION]: 'LocalStack Extension',
  [ProductFeature.FEATURE_LOCALSTACK_PLUGIN]: 'LocalStack Plugin',
  [ProductFeature.FEATURE_LOCALSTACK_AWS_PROVIDER]: 'LocalStack AWS Provider',
  [ProductFeature.FEATURE_LOCALSTACK_CONTAINER_RUNTIME]: 'LocalStack Container Runtimes',
  [ProductFeature.FEATURE_LOCALSTACK_LAMBDA_RUNTIME_EXECUTOR]: 'LocalStack Lambda Runtime Executors',
};

export interface FeaturesProps {
  orgId: string;
}

type FeatureTuple = [ProductFeature, string, string];

export const Features = ({ orgId }: FeaturesProps): ReactElement => {
  const [featureOverrides, setFeatureOverrides] = useState<FeatureOverrides>({ add: {}, remove: {} });

  const [editedFeatureName, setEditedFeatureName] = useState<ProductFeature>(ProductFeature.FEATURE_LOCALSTACK_PRO);
  const [editedFeatureVariant, setEditedFeatureVariant] = useState<string>('');
  const [editedFeatureVersion, setEditedFeatureVersion] = useState<string>('*.*.*');

  const { data: featureControlRecord, isLoading: isFclLoading } =
    useApiGetter(AdminService, 'getFeatureControlRecord', [orgId]);

  const { updateFeatureControlRecord, isLoading: isFlcMutating } =
    useApiEffect(AdminService, ['updateFeatureControlRecord'], { revalidate: ['getFeatureControlRecord'] });

  useEffect(() => {
    if (!featureControlRecord) {
      return;
    }

    setFeatureOverrides(featureControlRecord?.feature_overrides ?? {});
  }, [featureControlRecord]);

  const subscriptionIds = Array.from(
    new Set([
      ...Object.keys(featureControlRecord?.default_features ?? {}),
      ...Object.keys(featureOverrides.add ?? {}),
      ...Object.keys(featureOverrides.remove ?? {}),
    ]),
  );

  const isFeatureRemoved = (subscriptionId: string, featureTuple: FeatureTuple): boolean =>
    !!featureOverrides.remove?.[subscriptionId]?.find(
      (featureTuple_) => featureTuple_.join('/') === featureTuple.join('/'),
    );

  const handleAddFeature = (subscriptionId: string, feature: FeatureTuple) => {
    setFeatureOverrides({
      ...featureOverrides,
      add: {
        ...featureOverrides.add,
        [subscriptionId]: [
          ...(featureOverrides.add?.[subscriptionId] ?? []),
          feature,
        ],
      },
    });
  };

  const handleRemoveFeature = (subscriptionId: string, featureTuple: FeatureTuple) => {
    // handle removal of default features = create a new removal override
    const isDefaultFeatureRemoval = Object.values(featureControlRecord?.default_features?.[subscriptionId] ?? {}).find(
      (featureTuple_) => featureTuple_.join('/') === featureTuple.join('/'),
    );
    if (isDefaultFeatureRemoval) {
      setFeatureOverrides({
        ...featureOverrides,
        remove: {
          ...featureOverrides.remove,
          [subscriptionId]: [
            ...(featureOverrides.remove?.[subscriptionId] ?? []),
            featureTuple,
          ],
        },
      });
    }

    // handle removal of removed features
    const isRemovalOverrideRemoval = (featureOverrides?.remove?.[subscriptionId] ?? []).find(
      (featureTuple_) => featureTuple_.join('/') === featureTuple.join('/'),
    );
    const isAdditionOverrideRemoval = (featureOverrides?.add?.[subscriptionId] ?? []).find(
      (featureTuple_) => featureTuple_.join('/') === featureTuple.join('/'),
    );
    if (isRemovalOverrideRemoval || isAdditionOverrideRemoval) {
      setFeatureOverrides({
        ...featureOverrides,
        add: {
          ...featureOverrides.add,
          [subscriptionId]: (featureOverrides.add?.[subscriptionId] ?? []).filter(
            (featureTuple_) => featureTuple_.join('/') !== featureTuple.join('/'),
          ),
        },
        remove: {
          ...featureOverrides.remove,
          [subscriptionId]: (featureOverrides.remove?.[subscriptionId] ?? []).filter(
            (featureTuple_) => featureTuple_.join('/') !== featureTuple.join('/'),
          ),
        },
      });
    }
  };

  return (
    <>
      <Card>
        <CardHeader title="Features Management" />
        <LoadingFragment loading={isFclLoading} height={50} />
        {subscriptionIds.map((subId) => (
          <Fragment key={subId}>
            <List>
              <ListSubheader>{`Subscription ${subId}`}</ListSubheader>
              <ListItem>
                <Box width="100%">
                  <Autocomplete
                    size="small"
                    clearIcon
                    fullWidth
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    options={Object.values(ProductFeature as Record<string, string>)}
                    getOptionLabel={(r) => FEATURE_NAME_MAPPING[r as ProductFeature]}
                    renderInput={(params) => <TextField {...params} label="Feature" variant="outlined" />}
                    value={editedFeatureName}
                    onChange={(_e, r) => r && setEditedFeatureName(r as ProductFeature)}
                  />
                  <Box display="flex" mt={2}>
                    <Box flexGrow={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Variant"
                        variant="outlined"
                        value={editedFeatureVariant}
                        onChange={(e) => setEditedFeatureVariant(e.target.value)}
                      />
                    </Box>
                    {/* Hidden for now not to distract admins, since versions are not being respected by localstack atm */}
                    <Box flexGrow={1} ml={2} display="none">
                      <TextField
                        fullWidth
                        size="small"
                        label="Version"
                        variant="outlined"
                        value={editedFeatureVersion}
                        onChange={(e) => setEditedFeatureVersion(e.target.value)}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        color="primary"
                        onClick={() => handleAddFeature(
                          subId, [editedFeatureName, editedFeatureVariant, editedFeatureVersion],
                        )}
                      >
                        <PlusIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </ListItem>
              {/* List Default Features and Their State */}
              {featureControlRecord?.default_features?.[subId]?.map((featureTuple) => (
                <Fragment key={subId + featureTuple.join('/')}>
                  <ListItem button>
                    <ListItemAvatar>
                      <Box mr={2}>
                        <Chip label="Default" variant="filled" color="default" size="small" />
                      </Box>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${FEATURE_NAME_MAPPING[featureTuple[0]]} (${featureTuple[0]})`}
                      secondary={`Variant: ${featureTuple[1] || 'None'} // Version: ${featureTuple[2]}`}
                      primaryTypographyProps={{
                        color: isFeatureRemoved(subId, featureTuple) ? 'error' : 'textPrimary',
                        style: {
                          textDecoration: isFeatureRemoved(subId, featureTuple) ? 'line-through' : 'none',
                        },
                      }}
                      secondaryTypographyProps={{
                        color: isFeatureRemoved(subId, featureTuple) ? 'error' : 'textPrimary',
                        style: {
                          textDecoration: isFeatureRemoved(subId, featureTuple) ? 'line-through' : 'none',
                        },
                      }}
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={() => handleRemoveFeature(subId, featureTuple)}>
                        {isFeatureRemoved(subId, featureTuple) ? (
                          <RestoreIcon />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Fragment>
              ))}
              {/* List Added Features and Their State, we do not display `remove` features as those should work as overrides for default ones */}
              {(featureOverrides.add?.[subId] ?? []).map((featureTuple) => (
                <ListItem key={subId + featureTuple.join('/')} button>
                  <ListItemAvatar>
                    <Box mr={2}>
                      <Chip label="Custom" variant="filled" color="secondary" size="small" />
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${FEATURE_NAME_MAPPING[featureTuple[0]]} (${featureTuple[0]})`}
                    secondary={`Variant: ${featureTuple[1] || 'None'} // Version: ${featureTuple[2]}`}
                    primaryTypographyProps={{ color: 'success.main' }}
                    secondaryTypographyProps={{ color: 'success.main' }}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => handleRemoveFeature(subId, featureTuple)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Fragment>
        ))}
        <CardActions>
          {featureControlRecord && (
            <ProgressButton
              variant="contained"
              color="primary"
              loading={isFlcMutating}
              onClick={() => updateFeatureControlRecord(orgId, orgId, {
                ...featureControlRecord,
                feature_overrides: featureOverrides,
              })}
            >
              Save
            </ProgressButton>
          )}
        </CardActions>
      </Card>
    </>
  );
};
