import { getSchema } from '@localstack/services';
import {
  PutAlternateContactRequest,
  AlternateContact,
} from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type AlternateContactFormProps = {
  info?: Optional<Partial<AlternateContact>>;
  formId: string;
  onSubmit: (data: PutAlternateContactRequest) => void;
  loading?: boolean;
  selectedContactType: string;
};

export const AlternateContactForm = ({
  formId,
  onSubmit,
  loading,
  info,
  selectedContactType: AlternateContactType,
}: AlternateContactFormProps): ReactElement => (
  <MagicForm
    entry='AlternateContact'
    schema={getSchema('Account')}
    loading={loading}
    formId={formId}
    data={{ ...info, AlternateContactType }}
    onSubmit={(data: PutAlternateContactRequest) => onSubmit(data)
    }
  />
);
