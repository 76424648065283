import { ReactElement, useCallback } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, Button } from '@mui/material';
import { Breadcrumbs, IAMPolicyVersion } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMPolicyVersionDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { policyArn } = useParams<'policyArn'>();
  const { versionId } = useParams<'versionId'>();

  const decodedPolicyArn = decodeURIComponent(policyArn || '');

  const { data: policy } = useAwsGetter('IAM', 'getPolicy', [{
    PolicyArn: decodedPolicyArn,
  }], { clientOverrides });

  const { data: policyVersion } = useAwsGetter('IAM', 'getPolicyVersion', [{
    PolicyArn: decodedPolicyArn,
    VersionId: versionId,
  }], { clientOverrides });

  const gotoUpdatePolicyVersion = useCallback(
    () => goto(routes.RESOURCES_IAM_POLICY_VERSION_UPDATE, { 
      policyArn: decodedPolicyArn,
      versionId: policy?.Policy?.DefaultVersionId || '',
    }),
    [policy],
  );

  return (
    <Layout
      documentTitle="Policy Version Details"
      tabs={<NavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Policy Version Details</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              [policy?.Policy?.PolicyName, () => goto(routes.RESOURCES_IAM_POLICY, {
                policyArn: decodedPolicyArn,
              })],
              [versionId, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={gotoUpdatePolicyVersion}>Update Version</Button>
      }
    >
      <Card>
        <CardContent>
          <IAMPolicyVersion policyVersion={policyVersion?.PolicyVersion} />
        </CardContent>
      </Card>
    </Layout>
  );
};
