import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes, useAwsGetter } from '@localstack/services';
import { OpenSearchCreateDomainRequest, OpenSearchUpdateDomainConfigRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, OpenSearchDomainForm } from '@localstack/ui';

import { OpenSearchProps } from './types';

export const OpenSearchDomainUpsert = ({
  Layout,
  clientOverrides,
  routes,
}: OpenSearchProps): ReactElement => {
  const { goto } = useRoutes();
  const { domainName } = useParams<'domainName'>();

  const { data: domain } = useAwsGetter(
    'OpenSearch',
    'describeDomain',
    [{ DomainName: domainName }],
    { clientOverrides },
  );

  const { createDomain, updateDomainConfig, isLoading } = useAwsEffect(
    'OpenSearch',
    ['createDomain', 'updateDomainConfig'],
    { revalidate: ['listDomainNames', 'describeDomain'], clientOverrides },
  );

  const handleCreate = async (data: OpenSearchCreateDomainRequest) => {
    if (await createDomain(data)) {
      goto(routes.RESOURCES_OPENSEARCH_DOMAINS);
    }
  };

  const handleUpdate = async (data: OpenSearchUpdateDomainConfigRequest) => {
    if (await updateDomainConfig(data)) {
      goto(routes.RESOURCES_OPENSEARCH_DOMAIN, { domainName });
    }
  };

  return (
    <Layout
      documentTitle={`${domainName ? 'Update' : 'Create'} Domain`}
      title={
        <Box>
          <Typography variant="h4">Domain Details</Typography>
          <Breadcrumbs
            mappings={[
              ['OpenSearch', () => goto(routes.RESOURCES_OPENSEARCH_DOMAINS)],
              [domainName, () => goto(routes.RESOURCES_OPENSEARCH_DOMAIN, { domainName })],
              [domainName ? 'Update Domain' : 'Create Domain', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateOpenSearchDomain"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <OpenSearchDomainForm
            domain={domain?.DomainStatus}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            clientOverrides={clientOverrides}
            formId="CreateOpenSearchDomain"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateOpenSearchDomain"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
