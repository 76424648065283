import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import {
  AppSyncApiKey,
  CreateAppSyncApiKeyRequest,
  UpdateAppSyncApiKeyRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';

export interface AppSyncApiKeyFormProps {
  apiId: string;
  apiKey?: Optional<Partial<AppSyncApiKey>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAppSyncApiKeyRequest) => unknown;
  onUpdate: (data: UpdateAppSyncApiKeyRequest) => unknown;
}

export const AppSyncApiKeyForm = ({
  apiId,
  apiKey,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AppSyncApiKeyFormProps): ReactElement => (
  <MagicForm
    data={apiKey}
    schema={getSchema('AppSync')}
    loading={loading}
    entry={apiKey ? 'UpdateApiKeyRequest' : 'CreateApiKeyRequest'}
    formId={formId}
    fieldConditions={{
      '^apiId$': false,
      '^id$': false,
    }}
    onSubmit={(data: CreateAppSyncApiKeyRequest | UpdateAppSyncApiKeyRequest) => {
      if (!apiKey) return onCreate({ ...data, apiId } as CreateAppSyncApiKeyRequest);
      return onUpdate({ ...data, apiId, id: apiKey.id as string });
    }}
  />
);
