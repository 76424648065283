import { ReactElement } from 'react';
import { MenuItem } from '@mui/material';
import { ECSTaskDefinition, RunECSTaskParams } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledSelect } from '../../../form';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface ECSTaskFormProps {
  cluster: string;
  definitions: ECSTaskDefinition[];
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: RunECSTaskParams) => unknown;
}

export const ECSTaskForm = ({
  cluster,
  definitions,
  loading,
  formId,
  clientOverrides,
  onCreate,
}: ECSTaskFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('ECS')}
    loading={loading}
    entry="RunTaskRequest"
    formId={formId}
    fieldConditions={{
      '^cluster': false,
    }}
    externalFields={{
      '^taskDefinition': (control, name) => (
        <ControlledSelect
          variant="outlined"
          control={control}
          fullWidth
          label="Task Definition"
          name={name}
          options={
            definitions.map(
              (d) => (
                <MenuItem key={d.taskDefinitionArn} value={d.taskDefinitionArn}>
                  {d.taskDefinitionArn}
                </MenuItem>
              ),
            )
          }
        />
      ),
      '(taskRoleArn|executionRoleArn)$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='IAM' method='listRoles' arrayKeyName='Roles' property='Arn'
          fieldName={fieldName} entityName='IAM Role'
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: RunECSTaskParams) => onCreate({ ...data, cluster })}
  />
);
