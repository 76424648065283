import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AppSyncFunction } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AppSyncFunctionsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  functions: AppSyncFunction[];
  onViewFunction?: (func: AppSyncFunction) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AppSyncFunctionsTable = ({
  functions,
  selectable = true,
  loading,
  onViewFunction,
  onSelect,
}: AppSyncFunctionsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('AppSync')}
    entry="FunctionConfiguration"
    idAttribute="functionId"
    rows={functions}
    selectable={selectable}
    onSelect={onSelect}
    order={['functionId']}
    loading={loading}
    externalFields={{
      functionId: (row) => (
        <Link onClick={() => onViewFunction && onViewFunction(row)} underline="hover">
          {row.functionId}
        </Link>
      ),
    }}
  />
);
