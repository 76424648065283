import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { SSMParameter } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface SystemParametersTableProps {
  parameters: SSMParameter[];
  loading?: boolean;
  selectable?: boolean;
  onViewParameter?: (parameter: SSMParameter) => unknown;
  onSelect?: (ids: string[]) => unknown;
  onPageChange?: (page: number) => unknown;
  hasMore?: boolean;
  page?: number;
}

const SSM_PAGE_SIZE = 10;

export const SystemParametersTable = ({
  parameters,
  loading,
  selectable,
  onViewParameter,
  onSelect,
  onPageChange,
  hasMore,
  page = 0,
}: SystemParametersTableProps): ReactElement => {
  const rowCount = (page + 1) * SSM_PAGE_SIZE + (hasMore ? 1 : 0);

  return (
    <MagicTable
      schema={getSchema('SSM')}
      entry="ParameterMetadata"
      idAttribute="Name"
      rows={parameters}
      selectable={selectable}
      onSelect={onSelect}
      pagination
      paginationMode="server"
      page={page}
      rowCount={rowCount}
      pageSize={SSM_PAGE_SIZE}
      onPageChange={onPageChange}
      order={['Name']}
      loading={loading}
      externalFields={{
        Name: (row) => (
          <Link onClick={() => onViewParameter && onViewParameter(row)} underline="hover">
            {row.Name}
          </Link>
        ),
      }}
    />
  );
};
