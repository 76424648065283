import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const MWAA_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.MWAA}`;

export const DEFAULT_MWAA_ROUTES: Record<string, string> = {
  RESOURCES_MWAA_ENVIRONMENTS: `${MWAA_BASE_ROUTE}/environments`,
  RESOURCES_MWAA_ENVIRONMENT: `${MWAA_BASE_ROUTE}/environment/:id`,
  RESOURCES_MWAA_ENVIRONMENT_CREATE: `${MWAA_BASE_ROUTE}/environment/new`,
  RESOURCES_MWAA_ENVIRONMENT_UPDATE: `${MWAA_BASE_ROUTE}/environment/:id/update`,
};
