import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, EventBridgeBusDetails } from '@localstack/ui';
import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { EventBridgeProps } from './types';
import { BusTabs } from './components';

export const EventBridgeBus = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { goto } = useRoutes();
  const { busName } = useParams<'busName'>();

  const { data: bus } = useAwsGetter('EventBridge', 'describeEventBus', [{ Name: busName }], { clientOverrides });

  return (
    <Layout
      documentTitle="EventBridge: Bus Details"
      tabs={<BusTabs busName={busName ?? ''} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Bus Details</Typography>
          <Breadcrumbs
            mappings={[
              ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
              ['Buses', () => goto(routes.RESOURCES_EVENT_BRIDGE_BUSES)],
              [busName, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <EventBridgeBusDetails bus={bus} />
        </CardContent>
      </Card>
    </Layout>
  );
};
