import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { AppSyncRoutes } from '../../types';

interface Props {
  apiId: string,
  routes: AppSyncRoutes;
}

export const NavTabs = ({ apiId, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_APPSYNC_API, { apiId })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_APPSYNC_API_DATA_SOURCES, { apiId })}>
      Data Sources
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_APPSYNC_API_TYPES, { apiId })}>
      Types
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_APPSYNC_API_RESOLVERS, { apiId })}>
      Resolvers
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_APPSYNC_API_FUNCTIONS, { apiId })}>
      Functions
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_APPSYNC_API_KEYS, { apiId })}>
      API Keys
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_APPSYNC_API_SCHEMA, { apiId })}>
      Schema
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_APPSYNC_API_QUERY, { apiId })}>
      Query
    </NavLink>
  </>
);
