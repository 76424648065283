import { ReactElement } from 'react';
import { DistributionDetails } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface CloudFrontDistributionDetailsProps {
  distribution?: Optional<Partial<DistributionDetails>>;
}

export const CloudFrontDistributionDetails = ({ distribution }: CloudFrontDistributionDetailsProps): ReactElement => (
  <MagicDetails
    data={distribution}
    schema={getSchema('CloudFront')}
    entry="Distribution"
    title="Distribution Details"
  />
);
