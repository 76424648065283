import { CloudTrailEvent } from '@localstack/types';
import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface CloudTrailEventDetailsProps {
  event: Optional<Partial<CloudTrailEvent>>;
}

export const CloudTrailEventDetails = ({ event }: CloudTrailEventDetailsProps): ReactElement => (
  <MagicDetails
    data={event}
    schema={getSchema('CloudTrail')}
    entry="Event"
    title="Event Details"
  />
);
