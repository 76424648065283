import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueTableDetails as Details } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs, TableTabs } from './components';

export const GlueTableDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { database, table } = useParams<'database' | 'table'>();

  const { data: TableData } = useAwsGetter(
    'Glue', 'getTable',
    [{ DatabaseName: database, Name: table }], { clientOverrides });

  return (
    <Layout
      documentTitle="Glue Table Details"
      tabs={TableData?.Table?.PartitionKeys?.length ?
        <TableTabs routes={routes} database={database ?? ''} table={table ?? ''}
          partitions={TableData?.Table?.PartitionKeys?.map(item => item.Name).join(',') ?? ''}
        /> : <MainNavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Glue Table Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Tables', () => goto(routes.RESOURCES_GLUE_TABLES)],
              [database, () => goto(routes.RESOURCES_GLUE_DATABASE_TABLES, { database })],
              [table, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_GLUE_TABLE_UPDATE, { database, table })}
        >
          Edit Table
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details table={TableData?.Table} />
        </CardContent>
      </Card>
    </Layout>
  );
};
