import { ReactElement } from 'react';
import { KinesisStream } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface KinesisStreamDetailsProps {
  stream?: Optional<Partial<KinesisStream>>;
}

export const KinesisStreamDetails = ({ stream }: KinesisStreamDetailsProps): ReactElement => (
  <MagicDetails
    data={stream}
    schema={getSchema('Kinesis')}
    entry="StreamDescription"
    title="Stream Table Details"
  />
);
