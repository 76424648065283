import { ReactElement } from 'react';
import { KafkaCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface KafkaClusterDetailsProps {
  cluster?: Optional<Partial<KafkaCluster>>;
}

export const KafkaClusterDetails = ({ cluster }: KafkaClusterDetailsProps): ReactElement => (
  <MagicDetails
    data={cluster}
    schema={getSchema('Kafka')}
    entry="ClusterInfo"
    title="Cluster Details"
  />
);
