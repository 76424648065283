import { MARKER_IDS, TestMarker, UserService, useApiEffect, useApiGetter } from '@localstack/services';
import { ProgressButton } from '@localstack/ui';
import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TosAcceptanceContext } from '@localstack/types';

import { AppRoute } from '~/config';

import { ACCEPT_TOS_BUTTON_CAPTION, AcceptTosTextBlock } from './AcceptTosTextBlock';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: 'fixed',
      top: '0px',
      left: '0px',
      width: '100vw',
      height: '100vh',
      backgroundColor: '#00000045',
      backdropFilter: 'blur(10px)',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    card: {
      margin: '1em',
      maxWidth: '600px',
      height: 'auto',
    },
  }),
);

const CUTOFF_DATE = new Date('2024-03-01T00:00:00Z');


export const AcceptTosOverlay = (): ReactElement => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { acceptTermsOfService } = useApiEffect(UserService, ['acceptTermsOfService'], { revalidate: ['getUser'] });

  const handleAccept = () => {
    setIsLoading(true);
    acceptTermsOfService({ acceptance_context: TosAcceptanceContext.OVERLAY_PROMPT });
  };

  // not using getUserInfo here, since it will not trigger a rerender when the user is loaded/changed
  const { data: combinedUserInfo } = useApiGetter(UserService, 'getUser', []);

  // only enforcing this on 'new' accounts (i.e. created on or after 2024-03-01)
  const accountCreationDate = new Date((combinedUserInfo?.user.created ?? 0) * 1000);
  const isOldAccount = accountCreationDate < CUTOFF_DATE;

  if (
    !combinedUserInfo ||
    isOldAccount ||
    combinedUserInfo.org.has_custom_terms ||
    combinedUserInfo.user.tos_acceptance_log) {
    return <></>;
  }


  return (
    <div className={classes.container}>
      <TestMarker name={MARKER_IDS.TOS_OVERLAY} />
      <Card className={classes.card}>
        <CardHeader title="Terms and Conditions" />
        <CardContent>
          <AcceptTosTextBlock />
        </CardContent>
        <CardActions>
          <Button
            variant='contained'
            onClick={() => navigate(AppRoute.SIGN_OUT)}
            disabled={isLoading}
          >
            Sign Out
          </Button>
          <ProgressButton
            variant='contained'
            color='primary'
            onClick={handleAccept}
            loading={isLoading}
            disabled={isLoading}
          >
            {ACCEPT_TOS_BUTTON_CAPTION}
          </ProgressButton>
        </CardActions>
      </Card>
    </div>
  );
};
