import { useState, useMemo, ReactElement } from 'react';
import { Alert ,
  Button,
  Box,
  Card,
  CardHeader,
  CardContent,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Add as AddIcon } from '@mui/icons-material';
import { Subscription, Permission, CallbackOrigin, PlanFamily } from '@localstack/types';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { LoadingFragment, SubscriptionsList } from '@localstack/ui';
import { SUB_ACTIVE_PAID_STATUSES, SUB_ACTIVE_STATUSES, SUB_CANCELED_STATUSES } from '@localstack/constants';

import {
  useApiGetter,
  useRoutes,
  SubscriptionService,
  OrganizationsService,
} from '@localstack/services';


import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks';
import { CustomerLayout } from '~/layouts';

import { ProStarterRenameInfo } from './components/Subscriptions/ProStarterRenameInfo';


const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    marginBottom: theme.spacing(2),
  },
}));

export const Subscriptions = (): ReactElement => {
  const classes = useStyles();

  const { goto } = useRoutes();
  const { can } = useAuthProvider();
  const { search } = useLocation();

  const [showCancelled, setShowCancelled] = useState(false);
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const origin = queryParams.get('origin');

  const { data: subscriptions, isLoading: isSubsLoading } =
    useApiGetter(SubscriptionService, 'listSubscriptions', [], { defaultValue: [] });

  const { data: keys } = useApiGetter(
    SubscriptionService,
    'listKeys',
    [false],
    { defaultValue: { org: [], shared: [] } },
  );

  const { userInfo } = useAuthProvider();

  const { data: licenseAssignments, isLoading: isLicensesLoading } = useApiGetter(
    OrganizationsService,
    'listLicenseAssignments',
    [userInfo?.org?.id || ''],
    { enable: !!userInfo?.org });

  const activeSubs = (subscriptions ?? []).filter(
    ({ status }) => SUB_ACTIVE_STATUSES.includes(status),
  );
  const cancelledSubs = (subscriptions ?? [])?.filter(
    ({ status }) => SUB_CANCELED_STATUSES.includes(status),
  );

  const subscriptionsToShow: Subscription[] = [...(showCancelled ?
    [...activeSubs, ...cancelledSubs] :
    [...activeSubs])].sort((subA, subB) => subB.created - subA.created);

  // only show 'add subscriptions' button if users has no paid non-legacy subscriptions yet to avoid multi subs.
  const notCancelledNonLegacyPaidActiveSubscriptions = (subscriptions ?? []).filter((subscription) =>
    SUB_ACTIVE_PAID_STATUSES.includes(subscription.status)
    && !(subscription?.cancel_at || subscription?.cancel_at_period_end)
    && subscription.plan.family !== PlanFamily.LEGACY_PLANS,
  );
  const showAddNewPlanButton = (!isSubsLoading && notCancelledNonLegacyPaidActiveSubscriptions.length === 0);

  const marketplaceSubscriptions = subscriptionsToShow.filter((s) => s.plan?.family === PlanFamily.MARKETPLACE_PLANS);

  return (
    <CustomerLayout
      title="Subscriptions"
    >
      <Card className={classes.card}>
        <CardHeader
          title="Subscriptions"
          action={
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={showCancelled}
                    color="primary"
                    onChange={(_, checked: boolean) => setShowCancelled(checked)}
                  />
                }
                label="Show cancelled"
              />
              {(can(Permission.CREATE_SUBSCRIPTIONS) && showAddNewPlanButton) && (
                <Button
                  color="primary"
                  size="small"
                  onClick={() => goto(AppRoute.PRICING)}
                  startIcon={<AddIcon />}
                >
                  Add new Plan
                </Button>
              )}
            </Box>
          }
        />
        <CardContent>
          {(origin === CallbackOrigin.MARKETPLACE && marketplaceSubscriptions.length === 0 && !isSubsLoading) && (
            <Box mb={2}>
              <Alert severity="info">
                We are syncronising your AWS Marketplace subscriptions. This may take up to few minutes to complete.
              </Alert>
            </Box>
          )}
          <LoadingFragment
            loading={isSubsLoading || isLicensesLoading}
            height={40}
            size={3}
            arrayData={subscriptionsToShow}
          >
            <SubscriptionsList
              showCancelled={showCancelled}
              subscriptions={subscriptionsToShow}
              keys={keys ?? { org: [], shared: [] }}
              licenseAssignments={licenseAssignments ?? []}
              members={userInfo?.org?.members ?? []}
              onViewSubscription={
                (subscription) => goto(AppRoute.WORKSPACE_SUBSCRIPTION_DETAIL, { subscriptionId: subscription.id })
              }
            />
            {subscriptionsToShow.filter((sub) => sub.plan.family === PlanFamily.PRO_PLANS).length > 0 && (
              <ProStarterRenameInfo activeSubscriptions={subscriptions ?? []} mt={2} />
            )}
          </LoadingFragment>
        </CardContent>
      </Card>
    </CustomerLayout>
  );
};
