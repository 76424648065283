import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { ECSTask } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type ECSTasksTableProps = {
  selectable?: boolean;
  loading?: boolean;
  tasks: ECSTask[];
  onViewTask?: (task: ECSTask) => unknown;
  onSelect?: (names: string[]) => void;
};

export const ECSTasksTable = ({
  tasks,
  selectable = true,
  loading,
  onViewTask,
  onSelect,
}: ECSTasksTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ECS')}
    entry="Task"
    idAttribute="taskArn"
    rows={tasks}
    selectable={selectable}
    onSelect={onSelect}
    order={['taskArn', 'lastStatus', 'desiredStatus']}
    loading={loading}
    externalFields={{
      taskArn: (row) => (
        <Link onClick={() => onViewTask && onViewTask(row)} underline="hover">
          {row.taskArn}
        </Link>
      ),
    }}
  />
);
