import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, OpenSearchDomainDetails } from '@localstack/ui';

import { DEFAULT_OPENSEARCH_ROUTES } from '@localstack/constants';

import { OpenSearchProps } from './types';

export const OpenSearchDomain = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_OPENSEARCH_ROUTES,
}: OpenSearchProps): ReactElement => {
  const { goto } = useRoutes();
  const { domainName } = useParams<'domainName'>();

  const { data: domain } = useAwsGetter(
    'OpenSearch',
    'describeDomainConfig',
    [{ DomainName: domainName }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Domain Details"
      title={
        <Box>
          <Typography variant="h4">Domain Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Domains', () => goto(routes.RESOURCES_OPENSEARCH_DOMAINS)],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_OPENSEARCH_DOMAIN_UPDATE, { domainName })}>
          Edit Domain
        </Button>
      }
    >
      <Card>
        <CardContent>
          <OpenSearchDomainDetails domain={domain?.DomainConfig} />
        </CardContent>
      </Card>
    </Layout>
  );
};
