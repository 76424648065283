import { ReactElement } from 'react';
import { SSMParameter } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface SystemParameterDetailsProps {
  parameter?: Optional<Partial<SSMParameter>>;
}

export const SystemParameterDetails = ({ parameter }: SystemParameterDetailsProps): ReactElement => (
  <MagicDetails
    data={parameter}
    schema={getSchema('SSM')}
    entry="Parameter"
    title="Parameter Details"
  />
);
