export const removeEmptyProperties = (obj: object): object => {
  const isFileOrBlob = (value: unknown): value is File | Blob =>
    value instanceof File || value instanceof Blob;

  Object.keys(obj).forEach((key: keyof typeof obj) => {
    if (typeof obj[key] === 'undefined') {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && obj[key] !== null && !isFileOrBlob(obj[key])) {
      removeEmptyProperties(obj[key]);
      if (!Object.keys(obj[key]).length) {
        delete obj[key];
      }
    }
  });
  return obj;
};
