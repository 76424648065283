import { AWS_REGIONS } from '@localstack/constants';
import { useRegion } from '@localstack/services';
import { TextField , Autocomplete } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    regionDropdown: {
      width: '12em',
    },
  }),
);

export const AwsRegionDropdown = (): ReactElement => {
  const classes = useStyles();
  const { region, setRegion } = useRegion();

  return (
    <Autocomplete
      size="small"
      options={AWS_REGIONS}
      getOptionLabel={(r) => r}
      className={classes.regionDropdown}
      renderInput={(params) => <TextField {...params} label="Region" variant="outlined" />}
      value={region}
      onChange={(_e, r) => r && setRegion(r)}
    />
  );
};
