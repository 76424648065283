import { ReactElement } from 'react';
import { AgwApiModel } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwApiModelDetailsProps {
  model?: Optional<Partial<AgwApiModel>>;
}

export const AgwApiModelDetails = ({ model }: AgwApiModelDetailsProps): ReactElement => (
  <MagicDetails
    data={model}
    schema={getSchema('ApiGatewayV2')}
    entry="Model"
    title="Model Details"
  />
);
