import { useState, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Alert,
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormHelperText,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Divider,
  InputAdornment,
} from '@mui/material';
import { ControlledTextField, ProgressButton, NavLink, AnimatedLogo, SSOIcon, DecorativeDivider } from '@localstack/ui';
import { IdentityProvider } from '@localstack/types';
import { PUBLIC_IDENTITY_PROVIDERS } from '@localstack/constants';

import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon, RotateLeft } from '@mui/icons-material/';

import {
  VALIDATION_RULES,
  UserService,
  OrganizationsService,
  useSnackbar,
  useApiEffect,
  useRoutes,
  formatError,
  formatErrorCode,
} from '@localstack/services';



import { BaseLayout } from '~/layouts';
import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks';
import { getPreSignInPageURL, storeAuthToken, updatePreSignInPageURL } from '~/util/storage';
import { useHubspotProvider } from '~/components/HubspotProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: theme.breakpoints.values.sm,
      [theme.breakpoints.down('md')]: {
        width: 'auto',
      },
    },
    inputField: {
      '& *:-webkit-autofill': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
      },
    },
  }),
);

interface FormData {
  username: string;
  password: string;
}

export const SignIn = (): ReactElement => {
  const classes = useStyles();

  const [providers, setProviders] = useState<IdentityProvider[]>([]);
  const [step, setStep] = useState(0); // 0 - email, 1 - provider / pwd
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { goto } = useRoutes();
  const { showSnackbar } = useSnackbar();
  const { reloadUserInfo } = useAuthProvider();
  const { cleanupHubspotUser } = useHubspotProvider();

  const {
    control,
    watch,
    handleSubmit,
    formState,
    resetField,
  } = useForm<FormData>({ mode: 'all' });

  const email = watch('username');

  const { signinUser, resendActivation, isLoading, error } = useApiEffect(
    UserService,
    ['signinUser', 'resendActivation'],
    { revalidate: ['getUser'] },
  );

  const { listIdentityProvidersForEmail, isLoading: isLoadingIdps } = useApiEffect(
    OrganizationsService,
    ['listIdentityProvidersForEmail'],
  );

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const onSubmit = async (data: FormData) => {
    const result = await signinUser(data);

    // FIXME: error from signIn gets somehow suppressed and we get result=undefined
    if (result?.token) {
      cleanupHubspotUser();
      storeAuthToken(result.token);

      await reloadUserInfo();

      const preSignInUrl = getPreSignInPageURL();

      if (preSignInUrl) {
        updatePreSignInPageURL(null);
        return goto(preSignInUrl);
      }

      goto(AppRoute.DASHBOARD);
    }
  };

  const onSubmitEmailCheck = async () => {
    setProviders(await listIdentityProvidersForEmail(email));
    setStep(1);
  };

  const resetForm = () => {
    setStep(0);
    setProviders([]);
    resetField('username');
    resetField('password');
  };

  const handleResendActivationEmail = async () => {
    await resendActivation({ email });

    showSnackbar({
      message: 'The activation email is being resent if an account with the email ' +
        `address "${email}" exists. Please check your inbox and spam folder.`,
      severity: 'success',
    });
  };

  // always include public providers into the list, even if the user hasn't activated one yet
  const allProviders = Array.from(
    new Set([
      ...PUBLIC_IDENTITY_PROVIDERS.map((p) => `public/${p}`),
      ...providers.map((p) => `${p.org_id}/${p.idp_name}`),
    ]),
  );

  const ProviderGridComponent = allProviders.length > 0 && (
    <Grid item xs={12}>
      <Box mb={2}>
        <DecorativeDivider />
      </Box>
      <Typography paragraph variant="caption">
        Or sign in with one of the following providers:
      </Typography>
      <Box display="flex">
        {allProviders.map((orgIdWithProviderName) => (
          <Box key={orgIdWithProviderName} marginLeft={1}>
            <Button
              size="small"
              variant="outlined"
              data-sso-button={orgIdWithProviderName}
              startIcon={<SSOIcon size="small" provider={orgIdWithProviderName.split('/')[1] as string} />}
              onClick={
                () => goto(
                  AppRoute.SSO_START,
                  {
                    orgId: orgIdWithProviderName.split('/')[0],
                    idpName: orgIdWithProviderName.split('/')[1],
                  },
                )
              }
            >
              {orgIdWithProviderName.split('/')[1]}
            </Button>
          </Box>
        ))}
      </Box>
    </Grid>
  );

  return (
    <BaseLayout documentTitle="Sign In" hideNavigation>
      <Box
        flexGrow={1}
        mt={3}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        px={2}
      >
        <Box textAlign="center">
          <AnimatedLogo />
        </Box>
        <Card className={classes.card}>
          <CardHeader
            title="Sign In"
            titleTypographyProps={{
              variant: 'h3',
            }}
            component='h2'
          />
          <CardContent>
            {error && (
              <Box mb={2}>
                <Alert
                  severity="error"
                  variant="outlined"
                  action={formatErrorCode(error) === 'auth.not_activated' && (
                    <ProgressButton
                      size="small"
                      onClick={handleResendActivationEmail}
                      loading={isLoading}
                    >
                      Resend
                    </ProgressButton>
                  )}
                >
                  {formatError(error)}
                </Alert>
              </Box>
            )}
            {step === 0 && (
              <form onSubmit={handleSubmit(onSubmitEmailCheck)} id="signin">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <ControlledTextField
                      control={control}
                      name="username"
                      fullWidth
                      required
                      label="Email address"
                      type="text"
                      variant="outlined"
                      rules={{
                        ...VALIDATION_RULES.email,
                        ...VALIDATION_RULES.required,
                      }}
                    />
                  </Grid>
                  {ProviderGridComponent}
                </Grid>
              </form>
            )}
            {step === 1 && (
              <form onSubmit={handleSubmit(onSubmit)} id="signin">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography paragraph variant="caption">
                      Sign in with your email and password
                    </Typography>
                    <ControlledTextField
                      control={control}
                      name="username"
                      fullWidth
                      required
                      disabled
                      label="Email address"
                      type="text"
                      variant="outlined"
                      rules={{
                        ...VALIDATION_RULES.email,
                        ...VALIDATION_RULES.required,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Reset Email">
                              <IconButton onClick={() => resetForm()} size="large">
                                <RotateLeft />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ControlledTextField
                      className={classes.inputField}
                      autoFocus
                      control={control}
                      name="password"
                      fullWidth
                      required
                      label="Password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        autoComplete: 'password',
                        endAdornment:
                          <InputAdornment position="end">
                            <Tooltip title={`${showPassword ? 'Hide' : 'Show'} Password`}>
                              <IconButton onClick={handleClickShowPassword} size="large">
                                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>,
                      }}
                      variant="outlined"
                      rules={VALIDATION_RULES.required}
                    />
                    <FormHelperText>
                      <Box style={{ marginTop: '1em' }}>
                        <NavLink to={AppRoute.RECOVER}>
                          Forgot Password?
                        </NavLink>
                      </Box>
                    </FormHelperText>
                  </Grid>
                  {ProviderGridComponent}
                </Grid>
              </form>
            )}
          </CardContent>
          <CardActions>
            <ProgressButton
              loading={isLoading}
              disabled={!formState.isValid || isLoadingIdps}
              color="primary"
              type="submit"
              size="large"
              variant="contained"
              form="signin"
            >
              {step === 0 ? 'Next' : 'Sign In'}
            </ProgressButton>
          </CardActions>
        </Card>

        <Divider
          variant='inset'
          style={{
            margin: '1.5rem',
            width: '10rem',
          }}
        />
        <Card className={classes.card} style={{ marginBottom: '2rem' }}>
          <CardHeader
            title="New to LocalStack?"
            titleTypographyProps={{
              variant: 'h3',
            }}
            component='h2'
          />
          <CardContent>
            <Typography variant='body1'>
              Create an account to access additional features and to subscribe to our Starter,{' '}
              Team and Enterprise plans.
            </Typography>

            <Box mt={4}>
              <Button
                color="primary"
                size="large"
                variant='contained'
                onClick={() => goto(AppRoute.SIGN_UP)}
              >
                Sign Up
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box >
    </BaseLayout >
  );
};
