import { ReactElement } from 'react';
import { IAMPolicy } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface IAMPolicyDetailsProps {
  policy?: Optional<Partial<IAMPolicy>>;
}

export const IAMPolicyDetails = ({ policy }: IAMPolicyDetailsProps): ReactElement => (
  <MagicDetails
    data={policy}
    schema={getSchema('IAM')}
    entry="Policy"
    title="Policy Details"
  />
);
