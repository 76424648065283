import { ReactElement } from 'react';
import { Box, Tooltip } from '@mui/material';

import { SERVICE_NAME } from '@localstack/types';

import { WrapInComponent } from '../../utils';

import { ICONS_MAP } from './icons';

export type AwsServiceIconProps = {
  code: string;
  size?: 'small' | 'medium' | 'large';
  title?: string;
  hideTooltip?: boolean;
};


export const AwsServiceIcon = ({ code, title, size = 'medium', hideTooltip }: AwsServiceIconProps): ReactElement => {
  const sizes = { small: [11, 11], medium: [22, 22], large: [32, 32] };
  const [width, height] = sizes[size as keyof typeof sizes] || sizes.medium;

  const Icon = ICONS_MAP[code as SERVICE_NAME] || ICONS_MAP.infrastructure;

  return (
    <WrapInComponent
      wrap={!hideTooltip}
      wrapperFn={child =>
        <Tooltip title={title || code} placement='top'>
          {child}
        </Tooltip>
      }
    >
      <Box padding='6px 8px'>
        <Icon
          style={{ display: 'block', borderRadius: 6 }}
          width={width}
          height={height}
        />
      </Box>
    </WrapInComponent>
  );
};

export default AwsServiceIcon;
