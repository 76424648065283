import { DEFAULT_ACM_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { ACMCertificatesTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { ACMProps } from './types';

export const ACMCertificates = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ACM_ROUTES,
}: ACMProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: certificates, isLoading, mutate } = useAwsGetter(
    'ACM',
    'listCertificates',
    [],
    { clientOverrides },
  );
  const { deleteCertificate } = useAwsEffect('ACM', ['deleteCertificate'], {
    revalidate: ['listCertificates'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteCertificate({ CertificateArn: id }),
      ),
    );
  };
  return (
    <Layout
      title={<PageTitle title="ACM Certificates" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ACM_CERTIFICATE_CREATE)}>
            Create Certificate
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Certificate(s)?`}
              onClick={handleRemove}
              text="Selected Certificates will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <ACMCertificatesTable
          certificates={certificates || {}}
          onViewCertificate={(id: string) =>
            goto(routes.RESOURCES_ACM_CERTIFICATE, { id })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
