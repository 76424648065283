import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AppSyncGraphqlApi } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AppSyncApisTableProps = {
  selectable?: boolean;
  loading?: boolean;
  apis: AppSyncGraphqlApi[];
  onViewApi?: (api: AppSyncGraphqlApi) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AppSyncApisTable = ({
  apis,
  selectable = true,
  loading,
  onViewApi,
  onSelect,
}: AppSyncApisTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('AppSync')}
    entry="GraphqlApi"
    idAttribute="apiId"
    rows={apis}
    selectable={selectable}
    onSelect={onSelect}
    order={['apiId', 'name']}
    loading={loading}
    externalFields={{
      apiId: (row) => (
        <Link onClick={() => onViewApi && onViewApi(row)} underline="hover">
          {row.apiId}
        </Link>
      ),
    }}
  />
);
