import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import {
  KinesisStream,
  CreateKinesisStreamParams,
  UpdateKinesisStreamModeParams,
} from '@localstack/types';

import { MagicForm } from '../../magic/MagicForm';

export interface KinesisStreamFormProps {
  stream?: Optional<Partial<KinesisStream>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateKinesisStreamParams) => unknown;
  onUpdate: (data: UpdateKinesisStreamModeParams) => unknown;
}

export const KinesisStreamForm = ({
  stream,
  loading,
  formId,
  onCreate,
  onUpdate,
}: KinesisStreamFormProps): ReactElement => (
  <MagicForm
    data={stream}
    schema={getSchema('Kinesis')}
    loading={loading}
    entry={stream ? 'UpdateStreamModeInput' : 'CreateStreamInput'}
    formId={formId}
    fieldConditions={{
      '^StreamARN$': !stream,
    }}
    onSubmit={(data: CreateKinesisStreamParams) => {
      if (!stream) return onCreate(data);
      return onUpdate({ ...data, StreamARN: stream.StreamARN as string } as UpdateKinesisStreamModeParams);
    }}
  />
);
