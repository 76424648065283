import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { StepFunctionsRoutes } from '../../types';

interface Props {
  stateMachineArn: string | undefined;
  executionArn: string | undefined;
  routes: StepFunctionsRoutes;
}

export const ExecutionTabs = ({ stateMachineArn, executionArn, routes }: Props): ReactElement => (
  <>
    <NavLink
      to={buildRoute(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION, { stateMachineArn, executionArn })}
      end
    >
      Details
    </NavLink>
    <NavLink
      to={buildRoute(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION_EVENTS, { stateMachineArn, executionArn })}
    >
      Events
    </NavLink>
  </>
);
