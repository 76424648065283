import { ReactElement } from 'react';

import { CustomerLayout } from '~/layouts';

import { ApiKeys as AccountApiKeys } from './components';

export const ApiKeys = (): ReactElement => (
  <CustomerLayout
    title="API Keys"
  >
    <AccountApiKeys />
  </CustomerLayout>
);
