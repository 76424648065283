import { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Grid } from '@mui/material';
import { Permission } from '@localstack/types';

import { useAuthProvider } from '~/hooks';

import PaymentMethod from './PaymentMethod';
import Invoices from './Invoices';
import { Address } from './Address';

const useStyles = makeStyles((theme: Theme) => createStyles({
  card: {
    marginBottom: theme.spacing(2),
  },
}));

export const Billing = (): ReactElement => {
  const classes = useStyles();
  const { can } = useAuthProvider();

  return (
    <Grid item md={12}>
      {can(Permission.READ_CREDIT_CARDS) && (
        <PaymentMethod
          className={classes.card}
          hideAddButton
        />
      )}

      {can(Permission.UPDATE_ORGANIZATION) && (
        <Address className={classes.card} />
      )}

      {can(Permission.READ_INVOICES) && (
        <Invoices />
      )}
    </Grid>
  );
};

export default Billing;
