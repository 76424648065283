import { ReactElement } from 'react';

import { GetLambdaFunctionResponse } from '@localstack/types';

import { MagicDetails } from '../../../magic/MagicDetails';

export type LambdaTagsProps = {
  lambdaFunction: Optional<GetLambdaFunctionResponse>;
};

const SCHEMA = {
  shapes: {
    TagKey: {
      type: 'string',
    },
    TagValue: {
      type: 'string',
    },
    Tags: {
      type: 'map',
      key: {
        shape: 'TagKey',
        locationName: 'Key',
      },
      value: {
        shape: 'TagValue',
        locationName: 'Value',
      },
    },
    TagsStructure: {
      type: 'structure',
      members: {
        Tags: {
          shape: 'Tags',
        },
      },
    },
  },
};

export const LambdaTags = ({ lambdaFunction }: LambdaTagsProps): ReactElement => (
  <MagicDetails
    data={lambdaFunction}
    schema={SCHEMA}
    entry="TagsStructure"
    title="Tags for Lambda"
  />
);
