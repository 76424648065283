import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const TRANSCRIBE_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.TRANSCRIBE}`;

export const DEFAULT_TRANSCRIBE_ROUTES: Record<string, string> = {
  RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOBS: `${TRANSCRIBE_BASE_ROUTE}/transcriptionjobs`,
  RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOB: `${TRANSCRIBE_BASE_ROUTE}/transcriptionjob/:name`,
  RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOB_CREATE: `${TRANSCRIBE_BASE_ROUTE}/transcriptionjob/new`,
};
