import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, ElastiCacheClusterDetails } from '@localstack/ui';

import { DEFAULT_ELASTICACHE_ROUTES } from '@localstack/constants/src';

import { ElastiCacheProps } from './types';

export const ElastiCacheCluster = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ELASTICACHE_ROUTES,
}: ElastiCacheProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterId } = useParams<'clusterId'>();

  const { data: clusters } = useAwsGetter('ElastiCache', 'describeCacheClusters',
    [{ CacheClusterId: clusterId, ShowCacheNodeInfo: true }], { clientOverrides });
  const cluster = clusters?.CacheClusters?.find((c) => c.CacheClusterId === clusterId);

  return (
    <Layout
      documentTitle="Cluster Details"
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ElastiCache', () => goto(routes.RESOURCES_ELASTICACHE)],
              ['Clusters', () => goto(routes.RESOURCES_ELASTICACHE_CLUSTERS)],
              [clusterId, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_ELASTICACHE_CLUSTER_UPDATE, { clusterId })}>
          Edit Cluster
        </Button>
      }
    >
      <Card>
        <CardContent>
          <ElastiCacheClusterDetails cluster={cluster} />
        </CardContent>
      </Card>
    </Layout>
  );
};
