import { useState, useCallback, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';

import {
  Dropdown,
  ConfirmableButton,
  CognitoGroupsTable,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { PoolNavTabs } from './components';

import { CognitoProps } from './types';

export const CognitoPoolGroups = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { poolId } = useParams<'poolId'>();

  const [selected, setSelected] = useState<string[]>([]);

  const { data: pool } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'describeUserPool',
    [{ UserPoolId: poolId }],
    { clientOverrides },
  );

  const { data: groups, isLoading: isGroupsLoading, mutate } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'listGroups',
    [{ UserPoolId: poolId }],
    { clientOverrides },
  );

  const { deleteGroup, isLoading: isGroupMutating } = useAwsEffect(
    'CognitoIdentityServiceProvider',
    ['deleteGroup'],
    { revalidate: ['listGroups', 'adminListGroupsForUser'], clientOverrides },
  );

  const isLoading = isGroupsLoading || isGroupMutating;

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((GroupName) => deleteGroup({ UserPoolId: poolId as string, GroupName }))),
    [selected],
  );

  return (
    <Layout
      documentTitle="Pool Groups"
      tabs={<PoolNavTabs poolId={poolId as string} routes={routes} />}
      title={
        <PageTitle
          title="Pool Groups"
          onMutate={mutate}
          breadcrumbs={[
            ['Cognito', () => goto(routes.RESOURCES_COGNITO)],
            [pool?.UserPool?.Name ?? poolId, () => goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId })],
            ['Groups', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_COGNITO_POOL_GROUP_CREATE, { poolId })}>
            Create Group
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} Group(s)?`}
              onClick={handleDeleteObjects}
              text="Selected groups will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CognitoGroupsTable
          selectable
          loading={isGroupsLoading}
          groups={groups?.Groups ?? []}
          onSelect={setSelected}
          onViewGroup={
            (row) => goto(routes.RESOURCES_COGNITO_POOL_GROUP, { poolId, groupName: row.GroupName })
          }
        />
      </Card>
    </Layout>
  );
};
