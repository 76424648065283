import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { ECSTaskDefinition } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type ECSTaskDefinitionsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  definitions: ECSTaskDefinition[];
  onViewDefinition?: (definition: ECSTaskDefinition) => unknown;
  onSelect?: (names: string[]) => void;
};

export const ECSTaskDefinitionsTable = ({
  definitions,
  selectable = true,
  loading,
  onViewDefinition,
  onSelect,
}: ECSTaskDefinitionsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ECS')}
    entry="TaskDefinition"
    idAttribute="taskDefinitionArn"
    rows={definitions}
    selectable={selectable}
    onSelect={onSelect}
    order={['taskDefinitionArn', 'status']}
    loading={loading}
    externalFields={{
      taskDefinitionArn: (row) => (
        <Link
          onClick={() => onViewDefinition && onViewDefinition(row)}
          underline="hover"
        >
          {row.taskDefinitionArn}
        </Link>
      ),
    }}
  />
);
