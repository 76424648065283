import { ReactElement } from 'react';
import { Card } from '@mui/material';
import { CloudTrailEventsTable, PageTitle } from '@localstack/ui';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { CloudTrailEvent } from '@localstack/types';

import { TrailsNavTabs } from './components/TrailsNavTabs';
import { CloudTrailProps } from './types';

const REFRESH_INTERVAL = 5000;

export const CloudTrailEvents = ({
  Layout,
  clientOverrides,
  routes,
}: CloudTrailProps): ReactElement => {
  const { data: events, mutate } = useAwsGetter(
    'CloudTrail',
    'lookupEvents',
    [],
    { swrOverrides: { refreshInterval: REFRESH_INTERVAL }, clientOverrides },
  );
  const { goto } = useRoutes();

  return (
    <Layout
      title={
        <PageTitle
          title="Trails"
          onMutate={mutate}
        />
      }
      tabs={<TrailsNavTabs routes={routes} />}
    >
      <Card>
        <CloudTrailEventsTable
          entries={events?.Events || []}
          onViewCloudTrailEvent={(event: CloudTrailEvent) => goto(
            routes.RESOURCES_CLOUDTRAIL_EVENT,
            { id: event.EventId as string },
          )}
        />
      </Card>
    </Layout >
  );
};
