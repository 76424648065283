import { MuiThemeComponentsType } from '../types';

export default (): MuiThemeComponentsType['MuiAlert'] => ({
  styleOverrides: {
    root: {
      alignItems: 'center',
      borderRadius: 8,
    },
    message: {
      width: '100%',
    },
  },
});
