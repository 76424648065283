import { useState, ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { CloudWatchGetMetricStatisticsRequest } from '@localstack/types';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, CardActions } from '@mui/material';
import { Breadcrumbs, MonitoringMetricDetails, MonitoringMetricsChart, MonitoringGraphControls } from '@localstack/ui';

import { DEFAULT_METRICS_ROUTES } from '@localstack/constants/src';

import { MainNavTabs } from './components';
import { MetricsProps } from './types';

export const Metric = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_METRICS_ROUTES,
}: MetricsProps): ReactElement => {
  const { goto } = useRoutes();
  const { namespace, name, dimensions } = useParams<'namespace' | 'name' | 'dimensions'>();

  const [statsRequest, setStatsRequest] = useState<CloudWatchGetMetricStatisticsRequest>();

  const parsedDimensions = JSON.parse(dimensions ?? '[]');

  const { data: metrics } = useAwsGetter(
    'CloudWatch',
    'listMetrics',
    [{ MetricName: name, Namespace: namespace, Dimensions: parsedDimensions }],
    { clientOverrides },
  );

  const { data: statistics, isLoading: isStatsLoading } = useAwsGetter(
    'CloudWatch',
    'getMetricStatistics',
    [statsRequest ?? {}],
    { defaultValue: { Datapoints: [], Label: 'Unknown' },clientOverrides },
    
  );

  const metric = metrics?.Metrics?.[0];

  return (
    <Layout
      documentTitle="Metric Details"
      tabs={<MainNavTabs routes={routes}/>}
      title={
        <Box>
          <Typography variant="h4">Metric Details</Typography>
          <Breadcrumbs
            mappings={[
              ['CloudWatch Metrics', () => goto(routes.RESOURCES_CLOUDWATCH_MONITORING)],
              ['Metrics', () => goto(routes.RESOURCES_CLOUDWATCH_MONITORING_METRICS)],
              [`${namespace}:${name}`, null],
            ]}
          />
        </Box>
      }
    >
      {metric && (
        <Card>
          <CardActions>
            <MonitoringGraphControls metric={metric} loading={isStatsLoading} onSubmit={setStatsRequest} />
          </CardActions>
          <CardContent style={{ height: '600px' }}>
            <MonitoringMetricsChart statistics={statistics} statistic={statsRequest?.Statistics?.[0]} />
          </CardContent>
        </Card>
      )}
      <Card>
        <CardContent>
          <MonitoringMetricDetails metric={metric} />
        </CardContent>
      </Card>
    </Layout>
  );
};
