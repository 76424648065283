import { useState, useCallback, ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import {
  Dropdown,
  ConfirmableButton,
  CognitoPoolsTable,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { CognitoProps } from './types';
import { CognitoNavTabs } from './components';

const MAX_RESULTS = 100;

export const CognitoPools = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const {
    data: pools,
    isLoading: isPoolsLoading,
    mutate,
  } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'listUserPools',
    [{ MaxResults: MAX_RESULTS }],
    { defaultValue: { UserPools: [] }, clientOverrides },
  );

  const { deleteUserPool, isLoading: isPoolMutating } = useAwsEffect(
    'CognitoIdentityServiceProvider',
    [
      'createUserPool',
      'updateUserPool',
      'deleteUserPool',
      'addCustomAttributes',
    ],
    { revalidate: ['listUserPools'], clientOverrides },
  );

  const isLoading = isPoolsLoading || isPoolMutating;

  const handleDeleteObjects = useCallback(
    async () =>
      Promise.all(selected.map((UserPoolId) => deleteUserPool({ UserPoolId }))),
    [selected],
  );

  return (
    <Layout
      title={<PageTitle title="Cognito User Pools" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_COGNITO_USER_POOL_CREATE)}>
            Create User Pool
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} User Pool(s)?`}
              onClick={handleDeleteObjects}
              text="Selected User Pools will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
      tabs={<CognitoNavTabs routes={routes} />}
    >
      <Card>
        <CognitoPoolsTable
          selectable
          loading={isPoolsLoading}
          pools={pools?.UserPools ?? []}
          onSelect={setSelected}
          onViewPool={(pool) =>
            goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId: pool.Id })
          }
        />
      </Card>
    </Layout>
  );
};
