import { ReactElement } from 'react';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, ProgressButton, TimestreamDatabaseForm } from '@localstack/ui';
import { CreateTimestreamDatabaseRequest } from '@localstack/types';

import { DEFAULT_TIMESTREAMDB_ROUTES } from '@localstack/constants/src';

import { TimestreamDBProps } from './types';


export const TimestreamDatabaseCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_TIMESTREAMDB_ROUTES,
}: TimestreamDBProps): ReactElement => {
  const { goto } = useRoutes();

  const { createDatabase, isLoading } = useAwsEffect(
    'TimestreamWrite',
    ['createDatabase'],
    { revalidate: ['listDatabases'],clientOverrides },
  );

  const handleCreate = async (data: CreateTimestreamDatabaseRequest) => {
    if (await createDatabase(data)) {
      goto(routes.RESOURCES_TIMESTREAM_DATABASE, { database: data.DatabaseName });
    }
    goto(routes.RESOURCES_TIMESTREAM);
  };


  return (
    <Layout
      documentTitle="Database Details"
      title={
        <Box>
          <Typography variant="h4">Database Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Timestream', () => goto(routes.RESOURCES_TIMESTREAM)],
              ['Create Database', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateDB"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <TimestreamDatabaseForm
            loading={isLoading}
            formId="CreateDB"
            clientOverrides={clientOverrides}
            onSubmit={handleCreate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateDB"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
