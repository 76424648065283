import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { ECRDescribeRepositories } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type ECRRepositoriesTableProps = {
  loading?: boolean;
  selectable?: boolean;
  repositories: ECRDescribeRepositories[];
  onSelectRepositories?: (names: string[]) => void;
  onViewRepository?: (name: string) => void;
};

export const ECRRepositoriesTable = ({
  loading,
  selectable = true,
  repositories,
  onSelectRepositories,
  onViewRepository,
}: ECRRepositoriesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ECR')}
    entry='RepositoryList'
    idAttribute='repositoryName'
    order={['repositoryName']}
    filterColumns={['repositoryArn', 'registryId', 'repositoryName', 'repositoryUri', 'createdAt',
      'imageTagMutability']}
    rows={repositories}
    selectable={selectable}
    onSelect={onSelectRepositories}
    loading={loading}
    externalFields={{
      repositoryName: (row) => (
        <Link
          onClick={() => onViewRepository && onViewRepository(row.repositoryName as string)}
          underline="hover"
        >
          {row.repositoryName}
        </Link>
      ),
    }}
  />
);
