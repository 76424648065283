import { ReactElement } from 'react';
import { RegisterECSTaskDefinitionParams } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_ECS_VALUES, DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledTextField } from '../../../form';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface ECSTaskDefinitionFormProps {
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: RegisterECSTaskDefinitionParams) => unknown;
}

export const ECSTaskDefinitionForm = ({
  loading,
  formId,
  clientOverrides,
  onCreate,
}: ECSTaskDefinitionFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('ECS')}
    loading={loading}
    entry="RegisterTaskDefinitionRequest"
    formId={formId}
    externalFields={{
      // Override field to nake it optional
      '^containerDefinitions\\.[0-9]+\\.repositoryCredentials\\.credentialsParameter$': (control, name) => (
        <ControlledTextField
          fullWidth
          variant="outlined"
          label="Credentials Paramter"
          control={control}
          name={name}
        />
      ),
      '^(taskRoleArn|executionRoleArn)$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='IAM' method='listRoles' arrayKeyName='Roles' property='Arn'
          fieldName={fieldName} entityName='IAM Role'
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    defaultValues={formId === 'create' ? DEFAULT_ECS_VALUES?.RegisterTaskDefinitionRequest?.input : {}}
    onSubmit={(data: RegisterECSTaskDefinitionParams) => onCreate(data)}
  />
);
