import { getSchema } from '@localstack/services';
import { DMSEndpoint } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type DMSEndpointDetailProps = {
  endpoint?: Optional<Partial<DMSEndpoint>>;
};

const EMPTY_GROUPS_TO_HIDE: (keyof DMSEndpoint)[] = ['DynamoDbSettings', 'S3Settings',
  'DmsTransferSettings', 'MongoDbSettings', 'MySQLSettings', 'KinesisSettings', 'KafkaSettings',
  'ElasticsearchSettings', 'NeptuneSettings', 'RedshiftSettings', 'PostgreSQLSettings', 'OracleSettings',
  'SybaseSettings', 'MicrosoftSQLServerSettings', 'IBMDb2Settings', 'DocDbSettings', 'RedisSettings',
  'GcpMySQLSettings', 'TimestreamSettings'];

export const DMSEndpointDetail = ({
  endpoint,
}: DMSEndpointDetailProps): ReactElement => {
  let externalFields = {};
  EMPTY_GROUPS_TO_HIDE.forEach(item => {
    if (!endpoint?.[item]) externalFields = { ...externalFields, [item]: () => null };
  });
  return (
    <MagicDetails
      data={endpoint}
      schema={getSchema('DMS')}
      entry="Endpoint"
      title="DMS Endpoint Details"
      externalFields={externalFields}
    />
  );
};
