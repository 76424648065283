import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { DMSReplicationTask } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type DMSReplicationTasksTableProps = {
  replicationTasks: DMSReplicationTask[];
  onViewReplicationTask: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const DMSReplicationTasksTable = ({
  replicationTasks,
  onViewReplicationTask,
  onSelect,
  loading,
}: DMSReplicationTasksTableProps): ReactElement => (
  <MagicTable
    entry="ReplicationTask"
    idAttribute="ReplicationTaskArn"
    rows={replicationTasks}
    schema={getSchema('DMS')}
    filterColumns={['ReplicationTaskIdentifier', 'ReplicationTaskArn',
      'MigrationType', 'SourceEndpointArn', 'TargetEndpointArn', 'Status']}
    order={['ReplicationTaskArn', 'MigrationType', 'Status', 'ReplicationTaskIdentifier',
      'SourceEndpointArn', 'TargetEndpointArn']}
    externalFields={{
      ReplicationTaskArn: ({ ReplicationTaskArn }) => (
        <Link onClick={() => onViewReplicationTask(ReplicationTaskArn || '')}>
          {ReplicationTaskArn}
        </Link>
      ),
    }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
