import { DEFAULT_AWS_ACCOUNT_ID } from '@localstack/constants';
import { useAwsAccountId } from '@localstack/services';
import { TextField, Tooltip } from '@mui/material';
import { ReactElement } from 'react';



export const AwsAccountField = (): ReactElement => {
  const { awsAccountId, handleAwsAccountIdChange } = useAwsAccountId();

  return (
    <Tooltip title={`Account ID for multi-account support. It can be
    any 12-digit number. Default: ${DEFAULT_AWS_ACCOUNT_ID}`}
    >
      <TextField
        variant="outlined"
        size="small"
        label="Account ID"
        defaultValue={awsAccountId}
        onBlur={handleAwsAccountIdChange}
      />
    </Tooltip>
  );
};
