import { ReactElement } from 'react';
import { SGMEndpoints } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface SGMEndpointsTableProps {
  endpoints: SGMEndpoints[];
  loading?: boolean;
  selectable?: boolean;
  onSelect?: (ids: string[]) => unknown;
}

export const SGMEndpointsTable = ({
  endpoints,
  loading,
  selectable,
  onSelect,
}: SGMEndpointsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('SGM')}
    entry="EndpointSummary"
    idAttribute="EndpointName"
    rows={endpoints}
    selectable={selectable}
    onSelect={onSelect}
    order={['EndpointName']}
    loading={loading}
  />
);
