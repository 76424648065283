import { ReactElement } from 'react';
import { ECSTaskDefinition } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface ECSTaskDefinitionDetailsProps {
  definition?: Optional<Partial<ECSTaskDefinition>>;
}

export const ECSTaskDefinitionDetails = ({ definition }: ECSTaskDefinitionDetailsProps): ReactElement => (
  <MagicDetails
    data={definition}
    schema={getSchema('ECS')}
    entry="TaskDefinition"
    title="Task Definition Details"
  />
);
