import { ReactElement } from 'react';
import { AgwRestApiMethodResponse } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiMethodResponseDetailsProps {
  response?: Optional<Partial<AgwRestApiMethodResponse>>;
}

export const AgwRestApiMethodResponseDetails = ({ response }: AgwRestApiMethodResponseDetailsProps): ReactElement => (
  <MagicDetails
    data={response}
    schema={getSchema('APIGateway')}
    entry="MethodResponse"
    title="Response Details"
  />
);
