import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, Route53HostedZoneDetails as Details } from '@localstack/ui';
import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants';

import { Route53Props } from './types';
import { HostedZonesTabs } from './components';
import { processHostedZone } from './utils';

export const Route53HostedZoneDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const { goto } = useRoutes();
  const { hostedZone } = useParams<'hostedZone'>();

  const { data: hostedZoneData } = useAwsGetter(
    'Route53', 'getHostedZone',
    [{ Id: hostedZone }], { clientOverrides });

  return (
    <Layout
      documentTitle="Route53: Hosted Zone Details"
      tabs={<HostedZonesTabs routes={routes} hostedZoneId={hostedZone ?? ''} />}
      title={
        <Box>
          <Typography variant="h4">Hosted Zone Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Route53', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
              ['Hosted Zones', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
              [hostedZone, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <Details hostedZone={hostedZoneData?.HostedZone && processHostedZone(hostedZoneData?.HostedZone)} />
        </CardContent>
      </Card>
    </Layout>
  );
};
