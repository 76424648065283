import { ReactElement, useCallback } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography, Button } from '@mui/material';
import { Breadcrumbs, IAMPolicyDetails as Details } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMPolicyDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { policyArn } = useParams<'policyArn'>();
  const { data: policy } = useAwsGetter('IAM', 'getPolicy', [{
    PolicyArn: decodeURIComponent(policyArn || ''),
  }], { clientOverrides });

  const gotoPolicyVersion = useCallback(
    () => goto(routes.RESOURCES_IAM_POLICY_VERSION, { 
      policyArn: decodeURIComponent(policyArn || ''),
      versionId: policy?.Policy?.DefaultVersionId || '',
    }),
    [policy],
  );

  return (
    <Layout
      documentTitle="Policy Details"
      tabs={<NavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Policy Details</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              [policy?.Policy?.PolicyName, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={gotoPolicyVersion}>View Version</Button>
      }
    >
      <Card>
        <CardContent>
          <Details 
            policy={policy?.Policy} 
          />
        </CardContent>
      </Card>
    </Layout>
  );
};
