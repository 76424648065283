import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const TIMESTREAM_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.TIMESTREAMWRITE}`;

export const DEFAULT_TIMESTREAMDB_ROUTES: Record<string,string> = {
  RESOURCES_TIMESTREAM: `${TIMESTREAM_BASE_ROUTE}`,
  RESOURCES_TIMESTREAM_DATABASE: `${TIMESTREAM_BASE_ROUTE}/:database`,
  RESOURCES_TIMESTREAM_DATABASE_CREATE: `${TIMESTREAM_BASE_ROUTE}/database/new`,
  RESOURCES_TIMESTREAM_TABLE: `${TIMESTREAM_BASE_ROUTE}/:database/:table`,
  RESOURCES_TIMESTREAM_TABLE_CREATE: `${TIMESTREAM_BASE_ROUTE}/:database/table/new`,
};
