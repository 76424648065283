import { AuthToken } from '@localstack/types';

import { SERVICE_BASE_URL } from '~/config/config';
import { retrieveAuthToken, storeAuthToken } from '~/util/storage';

const TOKEN_REFRESH_LOOK_AHEAD = 2 * 60 * 1000; // 2 minutes (ms)

export const validateToken = (token: Optional<AuthToken>): boolean => {
  if (!token?.metadata) return false;

  const expiresAt = token?.metadata?.ExpiresAt as Optional<number>;

  return expiresAt
    ? expiresAt > (Date.now() + TOKEN_REFRESH_LOOK_AHEAD) / 1000
    : false;
};

const waitTokenRefreshed = async () =>
  new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (!window.refreshingToken) {
        clearInterval(interval);
        resolve();
      }
    }, 500);
  });

export enum RefreshTokenStatus {
  TOKEN_OK = 'TOKEN_OK',
  TOKEN_MISSING = 'TOKEN_MISSING',
  TOKEN_FAILED = 'TOKEN_FAILED',
}

export const refreshExpiringToken = async (force = false): Promise<RefreshTokenStatus> => {
  // something is already refreshing the token, wait for it to finish and quit
  if (window.refreshingToken) {
    await waitTokenRefreshed();
    return RefreshTokenStatus.TOKEN_OK;
  }

  const cachedAuthToken = retrieveAuthToken();

  // cached auth token still looks good, use it
  if (validateToken(cachedAuthToken) && !force) return RefreshTokenStatus.TOKEN_OK;

  // there is no authentication token, but it could still be a legitimate request
  if (!cachedAuthToken) return RefreshTokenStatus.TOKEN_MISSING;

  window.refreshingToken = true;

  try {
    // note a raw request here - we are bypassing axios interceptors
    // to make sure we are not trapped in recursive refresh loop
    const refreshResponse = await fetch(
      `${SERVICE_BASE_URL}/v1/user/signin`,
      { method: 'PUT', body: JSON.stringify(cachedAuthToken) },
    );

    const refreshResponseJson = await refreshResponse.json();
    const newAuthToken = refreshResponseJson.token;

    if (!newAuthToken) throw new Error('Failed to refresh token');

    storeAuthToken(newAuthToken);
  } catch (e) {
    window.refreshingToken = false;
    return RefreshTokenStatus.TOKEN_FAILED;
  }

  window.refreshingToken = false;
  return RefreshTokenStatus.TOKEN_OK;
};
