import { ReactElement, Fragment } from 'react';
import { List, ListItem, ListSubheader, ListItemAvatar, ListItemText } from '@mui/material';

import { AwsServiceIcon } from '../../../display';

const REGION_NAMES = {
  _: 'Global',
  static: 'No Region',
};

export type ResourcesListProps = {
  /** selected service */
  selected: [Optional<string>, Optional<string>];
  /** callback invoked whenever service selection changes (region, service) */
  onSelect: (tuple: [string, string]) => void;
  /** resource types grouped by region */
  groupedResourceTypes: Map<string, string[]>;
};

export const ResourcesList = ({ selected, onSelect, groupedResourceTypes }: ResourcesListProps): ReactElement => (
  <List>
    {Array.from(groupedResourceTypes.keys()).map((region: keyof typeof REGION_NAMES) => (
      <Fragment key={region}>
        <ListSubheader>{REGION_NAMES[region] || region}</ListSubheader>
        {(groupedResourceTypes.get(region) || []).map((type) => (
          <ListItem
            key={type}
            button
            selected={selected.toString() === [region, type].toString()}
            onClick={() => onSelect([region, type])}
          >
            <ListItemAvatar>
              <AwsServiceIcon code={type} />
            </ListItemAvatar>
            <ListItemText primary={type} />
          </ListItem>
        ))}
      </Fragment>
    ))}
  </List>
);
