import { CreateBackupVaultRequest } from '@localstack/types';
import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface BackupVaultCreateFormProps {
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateBackupVaultRequest) => unknown;
}

export const BackupVaultCreateForm = ({
  loading,
  formId,
  onCreate,
}: BackupVaultCreateFormProps): ReactElement => (
  <MagicForm
    formId={formId}
    schema={getSchema('Backup')}
    entry="CreateBackupVaultInput"
    loading={loading}
    onSubmit={(data: CreateBackupVaultRequest) => onCreate(data)}
  />
);
