import { getSchema } from '@localstack/services';
import {
  RequestCertificateRequest,
  UpdateCertificateOptionsRequest,
} from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type ACMCertificateFormProps = {
  formId: string;
  onSubmit: (data: RequestCertificateRequest | UpdateCertificateOptionsRequest) => void;
  loading?: boolean;
};

export const ACMCertificateForm = ({
  formId,
  onSubmit,
  loading,
}: ACMCertificateFormProps): ReactElement => (
  <MagicForm
    entry='RequestCertificateRequest'
    schema={getSchema('ACM')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    fieldConditions={{ CertificateArn: false }}
  />
);
