import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const AMPLIFY_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.AMPLIFY}`;

export const DEFAULT_AMPLIFY_ROUTES: Record<string, string> = {
  RESOURCES_AMPLIFY_APPS: `${AMPLIFY_BASE_ROUTE}/apps`,
  RESOURCES_AMPLIFY_APP: `${AMPLIFY_BASE_ROUTE}/app/:appId`,
  RESOURCES_AMPLIFY_APP_CREATE: `${AMPLIFY_BASE_ROUTE}/app/new`,
  RESOURCES_AMPLIFY_APP_UPDATE: `${AMPLIFY_BASE_ROUTE}/app/:appId/update`,
  RESOURCES_AMPLIFY_BE_ENVIRONMENT: `${AMPLIFY_BASE_ROUTE}/app/:appId/:name`,
  RESOURCES_AMPLIFY_BE_ENVIRONMENTS: `${AMPLIFY_BASE_ROUTE}/app/:appId/environments`,
  RESOURCES_AMPLIFY_BE_ENVIRONMENT_CREATE: `${AMPLIFY_BASE_ROUTE}/app/:appId/environment/new`,
};
