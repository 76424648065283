import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, MQBrokerDetails } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { useParams } from 'react-router-dom';

import { MQProps } from './types';

export const MQBroker = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: MQProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();

  const { data: broker } = useAwsGetter('MQ', 'describeBroker', [{ BrokerId: id }], { clientOverrides });

  return (
    <Layout
      documentTitle="Broker Details"
      title={
        <Box>
          <Typography variant="h4">Broker Details</Typography>
          <Breadcrumbs
            mappings={[
              ['MQ', () => goto(routes.RESOURCES_IAM)],
              [broker?.BrokerName, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <MQBrokerDetails broker={broker} />
        </CardContent>
      </Card>
    </Layout>
  );
};
