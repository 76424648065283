import { ReactElement, useCallback, useState } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  LoadingFragment,
  ECRImagesTable,
  PageTitle,
  ContentModal,
  Dropdown,
  ConfirmableButton,
  CodeVisualizer,
  NewTabLink,
} from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';

import { ExternalLink } from '@localstack/constants';

import { ECRProps } from './types';

export const ECRImages = ({
  Layout,
  clientOverrides,
  routes,
}: ECRProps): ReactElement => {
  const { name: repositoryName } = useParams<'name'>();
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);

  const { goto } = useRoutes();

  const { data: images, isLoading: isImagesLoading, mutate: mutateImages } = useAwsGetter(
    'ECR',
    'describeImages',
    [{ repositoryName }],
    { clientOverrides },
  );

  const { data: repos, mutate: mutateRepos } = useAwsGetter(
    'ECR',
    'describeRepositories',
    [],
    { clientOverrides },
  );

  const matchingRepo = repos?.repositories?.find((repo) => repo.repositoryName === repositoryName);
  const { repositoryUri } = matchingRepo ?? {};

  const { batchDeleteImage, isLoading: isImagesMutating } = useAwsEffect(
    'ECR',
    ['batchDeleteImage'],
    { revalidate: ['describeImages'], clientOverrides },
  );

  const handleDeleteImages = useCallback(
    async () => {
      const imageIds = selectedImages.map(image => ({ imageDigest: image }));
      await batchDeleteImage({
        repositoryName: repositoryName ?? '', imageIds: imageIds ?? [],
      });
    },
    [selectedImages],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title={`ECR Repository - ${repositoryName}`}
            onMutate={() => { mutateImages(); mutateRepos(); }}
            breadcrumbs={[
              ['Repositories', () => goto(routes.RESOURCES_ECR_REPOSITORIES)],
              [repositoryName, undefined],
            ]}
          />
        }
        actions={
          <>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => setModalOpen(true)}
            >
              view push commands
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedImages.length === 0 || isImagesMutating}
                title={`Remove ${selectedImages.length} image(s)?`}
                onClick={handleDeleteImages}
                text="Selected Images will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <CardContent>
            <LoadingFragment
              loading={isImagesLoading}
              arrayData={images?.imageDetails ?? []}
              variant="list"
              height={50}
              size={10}
            >
              <ECRImagesTable
                loading={isImagesLoading}
                images={images?.imageDetails ?? []}
                onSelectImages={setSelectedImages}
              />
            </LoadingFragment>
          </CardContent>
        </Card>
        <ContentModal
          title={`Push commands for ${repositoryName}`}
          maxWidth="md"
          open={modalOpen}
          fullWidth
          onClose={() => setModalOpen(false)}
        >
          <Box>
            <Typography variant='h5' align='left' style={{ fontWeight: 'bold', marginBottom: '1em' }}>
              Use the following steps to push an image to your repository.
            </Typography>
            <Typography variant="body1" component="div">
              1. Build your Docker image using the following command. For information on building a
              Docker file from scratch see the instructions
              <NewTabLink
                type='Link'
                href={ExternalLink.DOCS_ECR_GUIDE}
              >
                <span> here. </span>
              </NewTabLink>
              You can skip this step if your image is already built:
              <CodeVisualizer commands={[`docker build -t ${repositoryName} .`]} />
            </Typography>
            <Typography variant="body1" component="div">
              2. After the build completes, tag your image so you can push the image to this repository:
              <CodeVisualizer commands={[`docker tag ${repositoryName}:latest ${repositoryUri}:latest`]} />
            </Typography>
            <Typography variant="body1" component="div">
              3. Run the following command to push this image to your newly created AWS repository:
              <CodeVisualizer commands={[`docker push ${repositoryUri}:latest`]} />
            </Typography>
          </Box>
        </ContentModal>
      </Layout>
    </>
  );
};
