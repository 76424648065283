import { DefaultValuesObject } from './types';

export const DEFAULT_ELASTICACHE_VALUES: DefaultValuesObject = {
  CreateCacheClusterMessage: {
    input: {
      AZMode: 'cross-az',
      CacheNodeType: 'cache.r3.large',
      CacheSubnetGroupName: 'default',
      Engine: 'redis',
      EngineVersion: '1.4.24',
      NumCacheNodes: 1,
      Port: 11211,
    },
  },
};
