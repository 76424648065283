import { ReactElement } from 'react';
import { StateMachineDefinition } from '@localstack/types';

import { CodeSnippetViewer } from '../../../display/CodeSnippetViewer';

export interface StateMachineSchemaProps {
  definition?: Optional<Partial<StateMachineDefinition>>;
}

export const StateMachineSchema = ({ definition }: StateMachineSchemaProps): ReactElement => (
  <CodeSnippetViewer data={JSON.stringify(definition)} />
);
