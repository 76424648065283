import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { Card } from '@mui/material';
import {
  PageTitle,
  GlueTablePartitionsGrid,
} from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';
import { useParams } from 'react-router-dom';
import { GluePartition } from '@localstack/types';

import { GlueProps } from './types';


export const GlueTablePartitions = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { database, table, partitions } = useParams<'database' | 'table' | 'partitions'>();

  const partitionColNames = partitions?.split(',') || [];

  const { data: tablePartitions, isLoading, mutate } = useAwsGetter(
    'Glue',
    'getPartitions',
    [{ DatabaseName: database, TableName: table }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Glue: Partitions"
      title={
        <PageTitle
          title="Glue Partitions"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Tables', () => goto(routes.RESOURCES_GLUE_TABLES)],
            [database, () => goto(routes.RESOURCES_GLUE_DATABASE_TABLES, { database })],
            [table, () => goto(routes.RESOURCES_GLUE_TABLE, { database, table })],
            ['Partitions', null],
          ]}
          onMutate={mutate}
        />
      }
    >
      <Card>
        <GlueTablePartitionsGrid
          partitionColNames={partitionColNames as Partial<keyof GluePartition>[]}
          partitions={tablePartitions?.Partitions?.map(obj => partitionColNames.reduce((acc, key, index) => ({
            ...acc,
            [key]: obj.Values?.[index],
            ...obj,
          }), {})) ?? []}
          loading={isLoading}
          selectable={false}
        />
      </Card>
    </Layout>
  );
};
