import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, AppSyncDataSourcesTable,PageTitle } from '@localstack/ui';


import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiDataSources = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const {
    data: dataSources,
    isLoading,
    mutate } = useAwsGetter('AppSync', 'listDataSources', [{ apiId }], { clientOverrides });

  const { deleteDataSource } = useAwsEffect(
    'AppSync',
    ['deleteDataSource'],
    { revalidate: ['listDataSources', 'getDataSource'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((name) => deleteDataSource({ apiId: apiId as string, name }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Data Sources"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <PageTitle
          title="API Data Sources"
          onMutate={mutate}
          breadcrumbs={[
            ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
            [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
            ['Data Sources', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_APPSYNC_API_DATA_SOURCE_CREATE, { apiId })}>
            Create Data Source
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Data Source(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Data Sources will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AppSyncDataSourcesTable
          selectable
          loading={isLoading}
          dataSources={dataSources?.dataSources ?? []}
          onSelect={setSelectedIds}
          onViewDataSource={
            ({ name }) => goto(routes.RESOURCES_APPSYNC_API_DATA_SOURCE, { apiId, name })
          }
        />
      </Card>
    </Layout>
  );
};
