import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { ApiGatewayRoutes } from '../../types';

interface Props {
  restApiId: string;
  routes: ApiGatewayRoutes;
}

export const RestApiNavTabs = ({ restApiId, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API, { restApiId })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_RESOURCES, { restApiId })}>
      Resources
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_STAGES, { restApiId })}>
      Stages
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_AUTHORIZERS, { restApiId })} pro>
      Authorizers
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_MODELS, { restApiId })}>
      Models
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_VALIDATORS, { restApiId })}>
      Request Validators
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_KEYS, { restApiId })}>
      API Keys
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_AGW1_API_USAGE_PLANS, { restApiId })}>
      Usage Plans
    </NavLink>
  </>
);
