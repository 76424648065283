import { Alert } from '@mui/material';
import { ReactElement } from 'react';

export const NotRunningAlert = (): ReactElement => (
  <Alert severity='info'>
    Could not connect to running LocalStack instance. Make sure LocalStack
    is running and that its endpoint is accessible from this browser. <br />
    Update the endpoint URL in the above configuration if you are running LocalStack
    on a non-standard port or on a remote host.
  </Alert>
);
