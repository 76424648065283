import { useState, useCallback, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Dropdown, ConfirmableButton, CloudWatchLogStreamsTable, PageTitle } from '@localstack/ui';
import { DEFAULT_CLOUDWATCH_ROUTES } from '@localstack/constants';

import { CloudWatchProps } from './types';

import { LogGroupNavTabs } from './components';

export const CloudWatchLogStreams = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDWATCH_ROUTES,
}: CloudWatchProps): ReactElement => {
  const { goto } = useRoutes();
  const { logGroupName } = useParams<'logGroupName'>();

  const [selected, setSelected] = useState<string[]>([]);

  const { data: logStreams, isLoading: isStreamsLoading, mutate } = useAwsGetter(
    'CloudWatchLogs',
    'describeLogStreams',
    [{ logGroupName }],
    { clientOverrides },
  );

  const { deleteLogStream, isLoading: isStreamMutating } = useAwsEffect(
    'CloudWatchLogs',
    ['deleteLogStream'],
    { revalidate: ['describeLogStreams'], clientOverrides },
  );

  const isLoading = isStreamsLoading || isStreamMutating;

  const handleDeleteObjects = useCallback(
    async () => Promise.all(
      selected.map(
        (logStreamName) => deleteLogStream({ logStreamName, logGroupName: logGroupName as string }),
      ),
    ),
    [selected],
  );

  return (
    <Layout
      documentTitle="CloudWatch Log Streams"
      tabs={<LogGroupNavTabs logGroupName={logGroupName as string} routes={routes} />}
      title={
        <PageTitle
          title="CloudWatch Log Streams"
          onMutate={mutate}
          breadcrumbs={[
            ['CloudWatch Logs', () => goto(routes.RESOURCES_CLOUDWATCH)],
            ['Log Groups', () => goto(routes.RESOURCES_CLOUDWATCH_GROUPS)],
            [logGroupName, () => goto(routes.RESOURCES_CLOUDWATCH_GROUPS)],
            ['Log Streams', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_CLOUDWATCH_GROUP_STREAM_CREATE, { logGroupName })}>
            Create Log Stream
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} stream(s)?`}
              onClick={handleDeleteObjects}
              text="Selected streams will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CloudWatchLogStreamsTable
          selectable
          logStreams={logStreams?.logStreams || []}
          onViewLogStream={({ logStreamName }) =>
            goto(routes.RESOURCES_CLOUDWATCH_GROUP_STREAM_EVENTS, { logGroupName, logStreamName })
          }
          onSelect={setSelected}
        />
      </Card>
    </Layout>
  );
};
