import { grey } from '@mui/material/colors';
import createPalette from '@mui/material/styles/createPalette';

import { ThemeType } from '../constants';

const white = '#ffffff';
const black = '#000000';

// prototype for the dark mode
export const darkPalette = createPalette({
  mode: ThemeType.DARK,
  common: {
    black,
    white,
  },
  primary: {
    contrastText: white,
    main: '#8a75f0',
    light: '#a494f3',
    dark: '#715cd6',
  },
  secondary: {
    contrastText: white,
    main: '#2E3532',
    light: '#585d5b',
    dark: '#252a28',
  },
  success: {
    contrastText: white,
    main: '#45B880',
    // light: '#F1FAF5',
    dark: '#00783E',
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    dark: '#007489',
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    // light: '#FDF8F3',
    dark: '#95591E',
  },
  error: {
    contrastText: white,
    main: '#ED4740',
    // light: '#FEF6F6',
    dark: '#BF0E08',
  },
  text: {
    primary: '#bdc8f0',
    secondary: '#8492c4',
    disabled: '#bdc8f0',
  },
  background: {
    default: '#111936',
    paper: '#212946',
  },
  action: {
    active: '#2a2d52',
    disabledBackground: '#3e435b',
    hover: '#2a2d52',
    selected: '#2a2d52',
  },
  divider: '#303651',
  grey,
});

// primary color based on tintsandshades 7d66ee

export default createPalette({
  common: {
    black,
    white,
  },
  primary: {
    contrastText: white,
    main: '#715cd6',
    light: '#8a75f0',
    dark: '#5847a7',
  },
  secondary: {
    contrastText: white,
    main: '#2E3532',
    light: '#585d5b',
    dark: '#252a28',
  },
  success: {
    contrastText: white,
    main: '#45B880',
    // light: '#F1FAF5',
    dark: '#00783E',
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    // light: '#F1FBFC',
    dark: '#007489',
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    // light: '#FDF8F3',
    dark: '#95591E',
  },
  error: {
    contrastText: white,
    main: '#ED4740',
    // light: '#FEF6F6',
    dark: '#BF0E08',
  },
  text: {
    primary: '#12161B',
    secondary: '#66788A',
    disabled: '#A6B1BB',
  },
  background: {
    default: '#f8fafc',
    paper: white,
  },
  action: {
    active: '#f8fafc',
    hover: '#f8fafc',
    selected: '#f8fafc',
  },
  divider: '#DFE3E8',
  grey,
});
