import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { DynamoDBRoutes } from '../../types';

interface TableNavTabsProps {
  tableName: string;
  routes: DynamoDBRoutes;
}

export const TableNavTabs = ({ tableName, routes }: TableNavTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_DYNAMODB_TABLE, { tableName })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_DYNAMODB_TABLE_ITEMS, { tableName })}>
      Items
    </NavLink>
  </>
);
