import { ReactElement, useEffect, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  AnimatedLogo,
  Confetti,
} from '@localstack/ui';

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
} from '@mui/material';

import { useRoutes } from '@localstack/services';
import { useLocation } from 'react-use';

import { BaseLayout } from '~/layouts';
import { AppRoute } from '~/config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(3),
      width: theme.breakpoints.values.sm,
      [theme.breakpoints.down('md')]: {
        width: 'auto',
      },
    },
  }),
);
enum FLOW_TYPE {
  SSO = 'sso',
  EMAIL = 'email',
}

type signupSuccessPageDataType = {
  cardText: JSX.Element,
  redirectLink: string;
  redirectButtonText: string;
}

const getSignupSuccessPageData = (flow: FLOW_TYPE): Record<FLOW_TYPE, signupSuccessPageDataType>[FLOW_TYPE] => ({
  'sso': {
    cardText: <>
      You will be redirected to the dashboard shortly...<br />
      If this does not happen, please click the button below
    </>,
    redirectLink: AppRoute.DASHBOARD,
    redirectButtonText: 'Go to Dashboard',
  },
  'email': {
    cardText: <>
      Please find the activation link in your email inbox to activate your account.
    </>,
    redirectLink: AppRoute.SIGN_IN,
    redirectButtonText: 'Sign In',
  },
})[flow];


export const PostSignUp = (): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const source = queryParams.get('source');
  const flowName = (source === FLOW_TYPE.SSO ? FLOW_TYPE.SSO : FLOW_TYPE.EMAIL);
  const data = getSignupSuccessPageData(flowName);
  useEffect(() => {
    if (flowName === 'sso') setTimeout(() => goto(AppRoute.DASHBOARD), 5000);
  }, []);

  return (
    <BaseLayout pageName="PostSignUp" documentTitle="Sign Up" hideNavigation>
      <Confetti numberOfPieces={150} />
      <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={3}>
        <Box textAlign="center">
          <AnimatedLogo animation={false} />
        </Box>
        <Card className={classes.card}>
          <CardHeader
            title="Sign Up Successful"
            titleTypographyProps={{
              variant: 'h4',
              align: 'center',
            }}
            component='h2'
          />
          <CardContent style={{ textAlign: 'center', lineHeight: '1.5' }}>
            Thanks for signing up to LocalStack!
            <br />
            {data.cardText}
          </CardContent>
          <CardActions style={{ 'justifyContent': 'center' }}>
            <Button
              color="primary"
              variant='contained'
              onClick={() => goto(data.redirectLink)}
              data-action="signin"
            >
              {data.redirectButtonText}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </BaseLayout>
  );
};
