import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { Route53Routes } from '../../types';

type HostedZonesTabsProps = {
  routes: Route53Routes;
  hostedZoneId: string;
}

export const HostedZonesTabs = ({ routes, hostedZoneId }: HostedZonesTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORDS, { hostedZone: hostedZoneId })} end>
      Records
    </NavLink>
  </>
);
