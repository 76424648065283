import { ReactElement } from 'react';
import { Link, Breadcrumbs, Typography } from '@mui/material';

export type LambdaBreadcrumbsProps = {
  /** currently viewed function */
  functionName?: Optional<string>;
  /** callback fired whenever user navigates to the root */
  onViewRoot: () => void;
};

export const LambdaBreadcrumbs = ({ functionName, onViewRoot }: LambdaBreadcrumbsProps): ReactElement => (
  <Breadcrumbs>
    <Link onClick={onViewRoot} underline="hover">Lambda</Link>
    {functionName && <Typography color="textPrimary">{functionName}</Typography>}
  </Breadcrumbs>
);
