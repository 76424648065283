import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter, useAwsBatchIterator } from '@localstack/services';
import { Card, Button } from '@mui/material';

import {
  Dropdown,
  ConfirmableButton,
  KinesisStreamsTable,
} from '@localstack/ui';

import { DEFAULT_KINESIS_ROUTES } from '@localstack/constants';

import { KinesisProps } from './types';

const POLLING_INTERVAL = 5000;

export const KinesisStreams = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_KINESIS_ROUTES,
}: KinesisProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { describeStream } = useAwsEffect('Kinesis', ['describeStream'],{ clientOverrides });

  const { data: streamNames, isLoading } = useAwsGetter(
    'Kinesis',
    'listStreams',
    [],
    { swrOverrides: { refreshInterval: POLLING_INTERVAL },clientOverrides },
  );

  const streams = useAwsBatchIterator(
    streamNames?.StreamNames,
    async (StreamName) => (await describeStream({ StreamName })).StreamDescription,
  );

  const { deleteStream } = useAwsEffect(
    'Kinesis',
    ['deleteStream'],
    { revalidate: ['listStreams', 'describeStream'] },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((StreamName) => deleteStream({ StreamName }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title="Kinesis Streams"
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_KINESIS_STREAM_CREATE)}>
            Create Stream
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Stream(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Streams will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <KinesisStreamsTable
          selectable
          streams={streams}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewStream={
            ({ StreamName }) => goto(routes.RESOURCES_KINESIS_STREAM, { streamName: StreamName })
          }
        />
      </Card>
    </Layout>
  );
};
