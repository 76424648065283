import { ReactElement } from 'react';
import { AgwRestApiRequestValidator } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiValidatorDetailsProps {
  validator?: Optional<Partial<AgwRestApiRequestValidator>>;
}

export const AgwRestApiValidatorDetails = ({ validator }: AgwRestApiValidatorDetailsProps): ReactElement => (
  <MagicDetails
    data={validator}
    schema={getSchema('APIGateway')}
    entry="RequestValidator"
    title="Request Validator Details"
  />
);
