import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { DEFAULT_EC2_ROUTES } from '@localstack/constants';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  ELoadBalancersV2Table,
} from '@localstack/ui';

import { EC2Props } from './types';
import { LoadBalancerNavTabs } from './components/NavTabs/LoadBalancersNavTabs';

export const LoadBalancersV2 = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EC2_ROUTES,
}: EC2Props): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { goto } = useRoutes();

  const { data: loadBalancers, isLoading, mutate } = useAwsGetter
  ('ELBv2', 'describeLoadBalancers', [], { clientOverrides });

  const { deleteLoadBalancer } = useAwsEffect(
    'ELBv2',
    ['deleteLoadBalancer'],
    {
      revalidate: ['describeLoadBalancers'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((LoadBalancerArn) => deleteLoadBalancer({ LoadBalancerArn }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={
        <PageTitle
          title="Load Balancers V2"
          onMutate={mutate}
        />
      }
      tabs={<LoadBalancerNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_EC2_LBV2_NEW)}>
            Create Load Balancer V2
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Load Balancer V2(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Load Balancers V2 will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <ELoadBalancersV2Table
          loadBalancers={loadBalancers?.LoadBalancers || []}
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
