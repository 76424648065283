import { ReactElement } from 'react';
import { SendSESV2EmailRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface SESV2MessageFormProps {
  fromEmailAddress: string;
  loading?: boolean;
  formId?: string;
  onSubmit: (data: SendSESV2EmailRequest) => unknown;
}

export const SESV2MessageForm = ({
  fromEmailAddress,
  loading,
  formId,
  onSubmit,
}: SESV2MessageFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SESV2')}
    loading={loading}
    entry="SendEmailRequest"
    formId={formId}
    fieldConditions={{
      '^FromEmailAddress$': false,
      '^Content.Raw$': false,
      '^Content.Template$': false,
      '^ListManagementOptions$': false,
    }}
    onSubmit={(data: SendSESV2EmailRequest) => onSubmit({ ...data, FromEmailAddress: fromEmailAddress })}
  />
);
