import { ReactElement, ReactNode } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { FormControlLabel, Checkbox as MuiCheckbox, CheckboxProps } from '@mui/material';

type Props = CheckboxProps & Omit<ControllerProps, 'control' | 'render'> & {
  label?: ReactNode;
  control: Control<any> // eslint-disable-line
}

export const ControlledCheckbox = ({ label, ...props }: Props): ReactElement => (
  <FormControlLabel
    control={
      <Controller
        render={({ field }) => (
          <MuiCheckbox
            {...props}
            {...field}
            color="primary"
            value={field.value || false}
            checked={field.value || false}
          />
        )}
        {...props}
      />
    }
    label={label}
  />
);

export default ControlledCheckbox;
