import { ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

export type ControlledTextFieldProps = TextFieldProps & Omit<ControllerProps, 'control' | 'render'> & {
  control: Control<any> // eslint-disable-line
}

export const ControlledTextField = ({ control, ...rest }: ControlledTextFieldProps): ReactElement => (
  <Controller
    {...rest}
    control={control}
    render={({ field, fieldState }) => (
      <TextField
        variant="standard"
        {...rest}
        {...field}
        value={field.value || ''}
        error={fieldState.isTouched && Boolean(fieldState.error)}
        helperText={fieldState.isTouched && fieldState.error?.message}
      />
    )}
  />
);

export default ControlledTextField;
