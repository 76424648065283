import { useContext } from 'react';
import { extractFeatureTuple } from '@localstack/services';
import { ProductFeature } from '@localstack/types';

import { AuthGuardProviderContext, AuthGuardProviderContextInterface } from '~/components/AuthGuardProvider';

type UseAuthProvider = AuthGuardProviderContextInterface & {
  can: (permission: string) => boolean;
  hasFeature: (feature: ProductFeature) => boolean;
}

export const useAuthProvider = (): UseAuthProvider => {
  const context = useContext(AuthGuardProviderContext);

  const { roles, permissions, features } = context.userInfo ?? {};

  const featureTuples = (features ?? []).map(extractFeatureTuple);
  const featureNames = featureTuples.map((f) => f[0]);

  const can = (permission: string) => (permissions ?? []).includes(permission) || (roles ?? []).includes(permission);
  const hasFeature = (feature: ProductFeature) => featureNames.includes(feature);

  return { ...context, can, hasFeature };
};
