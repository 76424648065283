import { ReactElement, useCallback, useState } from 'react';
import { Card, CardContent, CardActions, Box, Typography, Grid } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { LamdaFunctionInvocationRequest, LambdaFunctionInvocationResponse } from '@localstack/types';
import { Breadcrumbs, ProgressButton, LambdaFunctionInvokeForm, LambdaInvocationDetails } from '@localstack/ui';
import { useParams } from 'react-router-dom';

import { LambdaProps } from './types';
import { FunctionTabs } from './components/FunctionTabs';

export const LambdaFunctionInvoke = ({
  Layout,
  clientOverrides,
  routes,
}: LambdaProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();
  const [invocationDetails, setInvocationDetails] = useState<LambdaFunctionInvocationResponse | undefined>(undefined);

  const { invoke, isLoading } = useAwsEffect(
    'Lambda',
    ['invoke'],
    { clientOverrides },
  );

  const handleCreate = useCallback(async (data: LamdaFunctionInvocationRequest) => {
    const response = await invoke(data);
    try {
      const logs = atob(response.LogResult || '');
      setInvocationDetails({ ...response, LogResult: logs });
    } catch (error) {
      setInvocationDetails(response);
    }
  }, []);

  return (
    <Layout
      documentTitle="Lambda Invoke Function"
      tabs={<FunctionTabs routes={routes} functionName={name} />}
      title={
        <Box>
          <Typography variant="h4">Lambda Invoke: {name}</Typography>
          <Breadcrumbs
            mappings={[
              ['Lambda', () => goto(routes.RESOURCES_LAMBDA_FUNCTIONS)],
              ['Create Lambda function', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="InvokeLambdaFunction"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Grid container spacing={1}>
        <Grid item>
          {invocationDetails && <Card>
            <CardContent>
              <LambdaInvocationDetails details={invocationDetails} />
            </CardContent>
          </Card>}
        </Grid>
        <Grid item>
          <Card>
            <CardContent>
              <LambdaFunctionInvokeForm
                functionName={name}
                onCreate={handleCreate}
                formId="InvokeLambdaFunction"
              />
            </CardContent>
            <CardActions>
              <ProgressButton
                type="submit"
                form="InvokeLambdaFunction"
                variant="contained"
                color="primary"
                loading={isLoading}
              >
                Submit
              </ProgressButton>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};
