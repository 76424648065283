import { DefaultValuesObject } from './types';

export const DEFAULT_ECS_VALUES: DefaultValuesObject = {
  RegisterTaskDefinitionRequest: {
    input: {
      containerDefinitions: [
        {
          logConfiguration: {
            logDriver: 'awslogs',
          },
          firelensConfiguration: {
            type: 'fluentd',
          },
          memoryReservation: 300,
        },
      ],
      ephemeralStorage: {
        sizeInGiB: 21,
      },
    },
  },
};
