import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, IAMUsersTable,PageTitle } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMUsers = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: users, isLoading, mutate } = useAwsGetter('IAM', 'listUsers',[],{ clientOverrides });

  const { deleteUser } = useAwsEffect(
    'IAM',
    ['deleteUser'],
    { revalidate: ['getUser', 'listUsers'],clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((UserName) => deleteUser({ UserName }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={
        <PageTitle
          title="IAM Users"
          onMutate={mutate}
        />
      }
      tabs={<NavTabs routes={routes}/>}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_IAM_USER_CREATE)}>
            Create User
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} User(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Users will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <IAMUsersTable
          selectable
          loading={isLoading}
          users={users?.Users || []}
          onSelect={setSelectedIds}
          onViewUser={
            ({ UserName }) => goto(routes.RESOURCES_IAM_USER, { userName: UserName })
          }
        />
      </Card>
    </Layout>
  );
};
