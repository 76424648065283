import { MuiThemeComponentsType } from '../types';

export default (): MuiThemeComponentsType['MuiPaper'] => ({
  styleOverrides: {
    elevation1: {
      boxShadow: '0px 1px 3px 0px rgb(0 0 0 / 20%)',
    },
    rounded: {
      borderRadius: 10,
      padding: 10,
    },
    root: { backgroundImage: 'unset' },
  },
});
