import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { NeptuneInstance } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface NeptuneInstancesTableProps {
  instances: NeptuneInstance[];
  loading?: boolean;
  selectable?: boolean;
  onViewInstance?: (instance: NeptuneInstance) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const NeptuneInstancesTable = ({
  instances,
  loading,
  selectable,
  onViewInstance,
  onSelect,
}: NeptuneInstancesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Neptune')}
    entry="DBInstance"
    idAttribute="DBInstanceIdentifier"
    rows={instances}
    selectable={selectable}
    onSelect={onSelect}
    order={['DBInstanceIdentifier']}
    loading={loading}
    externalFields={{
      DBInstanceIdentifier: (row) => (
        <Link onClick={() => onViewInstance && onViewInstance(row)}>
          {row.DBInstanceIdentifier}
        </Link>
      ),
    }}
  />
);
