/* eslint-disable @typescript-eslint/no-explicit-any, no-underscore-dangle */

import { useState, useMemo, ReactElement } from 'react';
import { Box } from '@mui/material';
import { merge } from 'lodash';
import { LambdaFunctionConfig, EventSourceMapping } from '@localstack/types';

import { LambdaBreadcrumbs } from '../../resource/lambda/LambdaBreadcrumbs';
import { LambdaFunctionsTable } from '../../resource/lambda/LambdaFunctionsTable';
import { LambdaDetails } from '../../resource/lambda/LambdaDetails';
import { LambdaCodeDetails } from '../../resource/lambda/LambdaCodeDetails';
import { LambdaEventSourceMappings } from '../../resource/lambda/LambdaEventSourceMappings';

const convertArnToLambdaName = (arn: string) => arn.split(':').slice(-1)[0];

const convertDeltaToFunctions = (delta: any): LambdaFunctionConfig[] => {
  const state = merge({}, delta.region_state, delta.shared_state);

  return Object.entries(state.lambdas || {}).map(([arn, config]: [string, any]) => ({
    FunctionName: convertArnToLambdaName(arn),
    FunctionArn: arn,
    Runtime: config.runtime,
    Role: config.role,
    Handler: config.handler,
    CodeSize: config.versions?.$LATEST?.CodeSize,
    Description: config.description,
    Timeout: config.timeout,
    MemorySize: config.memory_size,
    LastModified: config.last_modified,
    CodeSha256: config.versions?.$LATEST?.CodeSha256,
    Version: '$LATEST',
  }));
};

const convertDeltaToEventSourceMappings = (delta: any): EventSourceMapping[] => {
  const state = merge({}, delta.region_state, delta.shared_state);
  return Object.values(state.event_source_mappings || {});
};

type Props = {
  delta: unknown;
};

export const LambdaPreview = ({ delta }: Props): ReactElement => {
  const [selectedFunctionName, setSelectedFunctionName] = useState<Optional<string>>(null);

  const functions = useMemo(() => convertDeltaToFunctions(delta), [delta]);
  const mappings = useMemo(() => convertDeltaToEventSourceMappings(delta), [delta]);

  const selectedFunction = functions.find(({ FunctionName }) => FunctionName === selectedFunctionName);
  const selectedmappings = mappings.filter(({ FunctionArn }) => FunctionArn === selectedFunction?.FunctionArn);

  return (
    <>
      {!selectedFunction && (
        <LambdaFunctionsTable
          selectable={false}
          functions={functions}
          onViewFunction={setSelectedFunctionName}
          page={0}
          hasMore={false}
        />
      )}
      {selectedFunction && (
        <>
          <Box p={2}>
            <LambdaBreadcrumbs
              functionName={selectedFunctionName}
              onViewRoot={() => setSelectedFunctionName(null)}
            />
          </Box>
          <Box mt={3}>
            <LambdaDetails lambdaFunction={selectedFunction} />
          </Box>
          <Box mt={3}>
            <LambdaCodeDetails
              lambdaFunction={selectedFunction}
              lambdaFunctionCodeLocation={{}}
            />
          </Box>
          <Box mt={3}>
            <LambdaEventSourceMappings mappings={selectedmappings} />
          </Box>
        </>
      )}
    </>
  );
};
