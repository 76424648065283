import { ReactElement, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useRoutes, useAwsEffect, useAwsGetter, useAwsBatchIterator } from '@localstack/services';
import { Card, Button, Box, Typography } from '@mui/material';

import {
  Dropdown,
  Breadcrumbs,
  ConfirmableButton,
  ECSTasksTable,
} from '@localstack/ui';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

import { ClusterNavTabs } from './components';

const POLLING_INTERVAL = 5000;

export const ECSClusterTasks = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { clusterName } = useParams<'clusterName'>();

  const { goto } = useRoutes();
  const { describeTasks } = useAwsEffect(
    'ECS',
    ['describeTasks'],
    { clientOverrides },
  );

  const { data: tasksArns, isLoading } = useAwsGetter(
    'ECS',
    'listTasks',
    [{ cluster: clusterName }],
    { swrOverrides: { refreshInterval: POLLING_INTERVAL }, clientOverrides },
  );

  const tasks = useAwsBatchIterator(
    tasksArns?.taskArns,
    async (arn) => (await describeTasks({ cluster: clusterName, tasks: [arn] })).tasks ?? [],
  );

  const { stopTask } = useAwsEffect(
    'ECS',
    ['startTask', 'stopTask', 'runTask'],
    { revalidate: ['listTasks', 'describeTasks'], clientOverrides },
  );

  const handleStopSelected = useCallback(async () => {
    const promises = selectedIds.map((task) => stopTask({ cluster: clusterName as string, task }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="Cluster Tasks"
      tabs={<ClusterNavTabs clusterName={clusterName as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Cluster Tasks</Typography>
          <Breadcrumbs
            mappings={[
              ['ECS', () => goto(routes.RESOURCES_ECS)],
              ['Clusters', () => goto(routes.RESOURCES_ECS_CLUSTERS)],
              [clusterName, () => goto(routes.RESOURCES_ECS_CLUSTER, { clusterName })],
              ['Tasks', null],
            ]}
          />
        </Box>
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ECS_CLUSTER_TASK_DEPLOY, { clusterName })}>
            Start new Task
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Stop ${selectedIds.length} Task(s)?`}
              onClick={handleStopSelected}
              text="Selected Tasks will be stopped"
            >
              Stop Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <ECSTasksTable
          tasks={tasks}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewTask={
            ({ taskArn }) => goto(
              routes.RESOURCES_ECS_CLUSTER_TASK,
              { clusterName, arn: taskArn },
            )
          }
        />
      </Card>
    </Layout>
  );
};
