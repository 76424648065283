import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { ECSService } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type ECSServicesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  services: ECSService[];
  onViewService?: (service: ECSService) => unknown;
  onSelect?: (names: string[]) => void;
};

export const ECSServicesTable = ({
  services,
  selectable = true,
  loading,
  onViewService,
  onSelect,
}: ECSServicesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ECS')}
    entry="Service"
    idAttribute="serviceName"
    rows={services}
    selectable={selectable}
    onSelect={onSelect}
    order={['serviceName']}
    loading={loading}
    externalFields={{
      serviceName: (row) => (
        <Link onClick={() => onViewService && onViewService(row)} underline="hover">
          {row.serviceName}
        </Link>
      ),
    }}
  />
);
