import { ReactElement } from 'react';
import { CloudWatchMetricStatistics, CloudWatchMetricStatistic } from '@localstack/types';
import { useTheme } from '@mui/material/styles';
import { formatDateTime } from '@localstack/services';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export interface MonitoringMetricsChartProps {
  statistics?: Optional<CloudWatchMetricStatistics>;
  statistic?: Optional<CloudWatchMetricStatistic>;
}

export const MonitoringMetricsChart = ({ statistics, statistic }: MonitoringMetricsChartProps): ReactElement => {
  const theme = useTheme();

  const data = (statistics?.Datapoints ?? []).map(
    (dp) => ({
      name: formatDateTime(dp.Timestamp as Date),
      value: dp[(statistic ?? 'Average') as keyof typeof dp],
    }),
  );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend name={statistics?.Label} />
        <Line
          type="monotone"
          dataKey="value"
          stroke={theme.palette.primary.main}
          activeDot={{ r: 8 }}
          name={statistics?.Label}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
