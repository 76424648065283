import { capitalise, getSelectedInstance, useLocalstackStatus, useRoutes } from '@localstack/services';
import {
  HealthState,
  HealthStatePlus,
  HealthStatePlusType,
  OFFLINE,
  ProductFeature,
  RESOURCES_SERVICES_TYPE,
  SERVICE_NAME,
} from '@localstack/types';
import {
  AwsServiceTile,
} from '@localstack/ui';
import { Box, Card, Grid, List, ListItem, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment, ReactElement, useMemo } from 'react';

import { RESOURCES_TYPES } from '@localstack/resources';

import { CustomerLayout } from '~/layouts';

import { NotRunningAlert } from '~/components';
import { useAuthProvider } from '~/hooks';

const ORDER = [
  HealthStatePlus.RUNNING,
  HealthStatePlus.AVAILABLE,
  HealthStatePlus.INITIALIZED,
  HealthStatePlus.DISABLED,
  HealthStatePlus.ERROR,
  HealthStatePlus.OFFLINE,
];

const useStyles = makeStyles((theme: Theme) => createStyles({
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
  },
  serviceLink: {
    border: `solid 1px ${theme.palette.primary.main}`,
    display: 'flex',
    padding: '6px 8px',
    borderRadius: '0.5rem',
    marginLeft: '-9px',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    cursor: 'pointer',
    width: '100%',
  },

  service: {
    display: 'flex',
    padding: '6px 8px',
    borderRadius: '0.5rem',
    marginLeft: '-9px',
    alignItems: 'center',
    width: '100%',
    pointerEvents: 'none',
  },

  left: {
    textAlign: 'left',
  },
}));

const PAGE_TITLE = 'System Status';

export const SystemStatus = (): ReactElement => {
  const classes = useStyles();
  const { goto } = useRoutes();
  const { hasFeature } = useAuthProvider();
  const isEnterpriseSub = hasFeature(ProductFeature.FEATURE_CI_PROJECTS);

  const instance = getSelectedInstance();
  const clientOverrides = instance ? { endpoint: instance.endpoint } : {};
  const { running, services } = useLocalstackStatus(clientOverrides);
  const online_resources = Object.keys(services ?? {}) as Partial<SERVICE_NAME>[];

  const statusesMap = Object.entries(services ?? {}).reduce(
    (memo, [serviceCode, status]) => ({ ...memo, [status]: { ...memo[status], [serviceCode]: status } }),
    {} as Record<HealthState, Record<SERVICE_NAME, HealthState>>,
  );

  const extendedStatusesMap = useMemo(() => {
    const { community: communityList, pro: proList } = RESOURCES_SERVICES_TYPE;
    const supported_ls_resources = [...communityList, ...proList];

    const difference = supported_ls_resources.filter(res => !online_resources.includes(res));
    if (difference.length > 0) {
      return {
        ...statusesMap,
        [OFFLINE.OFFLINE]: difference.reduce((memo, name) => ({ ...memo, [name]: OFFLINE.OFFLINE }),
          {} as Record<HealthStatePlusType, Record<SERVICE_NAME, HealthStatePlusType>>,
        ),
      };
    }
    return statusesMap as unknown as Record<HealthStatePlusType, Record<SERVICE_NAME, HealthStatePlusType>>;
  }, [online_resources]);

  const getRoute = (serviceCode: string) => RESOURCES_TYPES[serviceCode as keyof typeof RESOURCES_TYPES];

  return (
    <CustomerLayout title={PAGE_TITLE}>
      <Grid container spacing={2}>
        {!running &&
          <Grid item xs={12}>
            <NotRunningAlert />
          </Grid>
        }
        <Grid item xs={12}>

          <Card>
            {ORDER.map((statusGroup) => (
              <Fragment key={statusGroup}>
                {extendedStatusesMap[statusGroup] && (
                  <Box>
                    <Typography variant="caption">
                      {capitalise(statusGroup)}
                    </Typography>
                    <List className={classes.list} dense>
                      {Object.entries(extendedStatusesMap[statusGroup] ?? {})
                        .sort((a, b) => a[0].localeCompare(b[0]))
                        .map(([serviceCode, serviceStatus]) => (
                          <ListItem
                            key={serviceCode}
                          >
                            <AwsServiceTile
                              className={getRoute(serviceCode) ? classes.serviceLink : classes.service}
                              code={serviceCode}
                              status={serviceStatus}
                              clickable={!!getRoute(serviceCode) && running}
                              navigateTo={() =>
                                goto(RESOURCES_TYPES[serviceCode as keyof typeof RESOURCES_TYPES],
                                  { iid: instance?.id })
                              }
                              isEnterpriseSub={isEnterpriseSub}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                )}
              </Fragment>
            ))}
          </Card>
        </Grid>
      </Grid>
    </CustomerLayout>
  );
};
