import { ReactElement } from 'react';
import { buildRoute } from '@localstack/services';
import { RotateLeft as CiRunsIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { MenuItem, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { NavLink } from 'react-router-dom';

import { AppRoute } from '~/config';

type Props = {
  projectName: string;
};


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      display: 'inline-flex',
      '&:hover, &.active': {
        color: theme.palette.primary.main,
        textDecoration: 'underline',
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
      borderRadius: 8,
    },
    icon: {
      color: theme.palette.text.secondary,
    },
    label: {
      marginLeft: '0.5em',
    },
  }),
);

export const ProjectDetailNavTabs = ({ projectName }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <MenuItem 
        className={classes.menuItem}
        component={NavLink}
        to={buildRoute(AppRoute.CI_PROJECT, {
          project: projectName,
        }) as string}
        end
      >
        <CiRunsIcon fontSize='small' className={classes.icon}/>
        <Typography variant="inherit" className={classes.label}>Project Runs</Typography>
      </MenuItem>
      <MenuItem 
        component={NavLink}
        to={buildRoute(AppRoute.CI_PROJECT_SETTINGS, {
          project: projectName,
        })}
        className={classes.menuItem}
      >
        <SettingsIcon fontSize='small' className={classes.icon}/>
        <Typography variant="inherit" className={classes.label}>Settings</Typography>
      </MenuItem>
    </>
  );
}; 
