import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { AthenaRoutes } from '../../types';

interface Props {
  routes: AthenaRoutes;
}

export const NavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_ATHENA_DATABASES)} end>
      Databases
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_ATHENA_CATALOGS)}>
      Catalogs
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_ATHENA_SQL)}>
      SQL
    </NavLink>
  </>
);
