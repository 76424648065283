import { ReactNode } from 'react';
import { AwsClientOverrides } from '@localstack/services';

export * from './chart';
export * from './core';
export * from './display';
export * from './feedback';
export * from './form';
export * from './navigation';
export * from './provider';

export interface LayoutProps {
  children?: JSX.Element | ReactNode;
  title?: JSX.Element | string;
  documentTitle?: string;
  actions?: JSX.Element | ReactNode;
  tabs?: JSX.Element | ReactNode;
  maxWidth?: string;
}

// TODO: maybe it makes sense to move it under "resources" package
export interface ResourceBrowserProps<R extends Record<string, string>> {
  Layout: (props: LayoutProps) => JSX.Element;
  routes: R;
  clientOverrides?: AwsClientOverrides;
}
