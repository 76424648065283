import { ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import { TextareaAutosize, TextareaAutosizeProps, FormHelperText } from '@mui/material';

export type ControlledTextareaProps = TextareaAutosizeProps & Omit<ControllerProps, 'control' | 'render'> & {
  control: Control<any> // eslint-disable-line
  fullWidth?: boolean;
}

export const ControlledTextarea = ({ control, fullWidth, ...rest }: ControlledTextareaProps): ReactElement => (
  <Controller
    {...rest}
    control={control}
    render={({ field, fieldState }) => (
      <>
        <TextareaAutosize
          {...rest}
          {...field}
          style={{ ...rest.style, width: fullWidth ? '100%' : undefined }}
          value={field.value || ''}
        />
        <FormHelperText error={fieldState.isTouched && Boolean(fieldState.error)}>
          {fieldState.error}
        </FormHelperText>
      </>
    )}
  />
);

export default ControlledTextarea;
