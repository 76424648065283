import { ReactElement } from 'react';

import { AcceptTosOverlay } from '~/components/AcceptTosOverlay';

import { AuthenticatedLayout, AuthenticatedLayoutProps } from '../Authenticated';

import { Navbar } from './components';

export const CustomerLayout = ({ children, ...rest }: AuthenticatedLayoutProps): ReactElement => (
  <AuthenticatedLayout
    {...rest}
    navigation={<Navbar />}
  >
    {children}
    <AcceptTosOverlay/>
  </AuthenticatedLayout>
);
