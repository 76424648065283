import { getSchema } from '@localstack/services';
import { DMSConnection } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type DMSEndpointConnectionsTableProps = {
  connections: DMSConnection[];
  loading?: boolean;
};

export const DMSEndpointConnectionsTable = ({
  connections,
  loading,
}: DMSEndpointConnectionsTableProps): ReactElement => (
  <MagicTable
    entry="Connection"
    idAttribute={['EndpointArn', 'ReplicationInstanceArn']}
    rows={connections}
    schema={getSchema('DMS')}
    loading={loading}
    selectable
    disableSelectionOnClick
    order={['EndpointIdentifier', 'ReplicationInstanceIdentifier', 'Status']}
  />
);
