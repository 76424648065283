import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { DMSEndpoint } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type DMSEndpointsTableProps = {
  endpoints: DMSEndpoint[];
  onViewEndpoint: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const DMSEndpointsTable = ({
  endpoints,
  onViewEndpoint,
  onSelect,
  loading,
}: DMSEndpointsTableProps): ReactElement => (
  <MagicTable
    entry="Endpoint"
    idAttribute="EndpointArn"
    rows={endpoints}
    schema={getSchema('DMS')}
    order={['EndpointArn']}
    externalFields={{
      EndpointArn: ({ EndpointArn }) => (
        <Link onClick={() => onViewEndpoint(EndpointArn || '')}>
          {EndpointArn}
        </Link>
      ),
    }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
