import { ReactElement } from 'react';
import { CreateCloudFrontDistributionRequest,
  UpdateDistributionParams } from '@localstack/types';
import { DEFAULT_S3_ROUTES } from '@localstack/constants';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker, ControlledTextField, ControlledSelect,
  buildOptionsFromArray } from '../../../form';

export const PROTOCOL_POLICY_LIST = [
  'allow-all',
  'https-only',
  'redirect-to-https',
];

export type CreateCloudFrontDistributionProps = {
  distribution?: Optional<Partial<UpdateDistributionParams>>;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateCloudFrontDistributionRequest) => unknown;
  onUpdate: (data: UpdateDistributionParams) => unknown;
}

export const CloudFrontDistributionForm = ({
  distribution,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: CreateCloudFrontDistributionProps): ReactElement => (
  <MagicForm
    data={distribution}
    schema={getSchema('CloudFront')}
    formId={formId}
    fieldConditions={{
      'id$': !distribution,
    }}
    externalFields={{
      '^CallerReference$': (control, name) => (
        <ControlledTextField
          fullWidth
          required
          control={control}
          name={name}
          label="Caller Reference"
          variant="outlined"
          defaultValue="cli-example"
        />
      ),
      '^Origins.Quantity$': (control, name) => (
        <ControlledTextField
          fullWidth
          required
          control={control}
          name={name}
          label="Quantity"
          variant="outlined"
          defaultValue={1}
        />
      ),
      '^Origins.Items\\.[0-9]+\\.Id$': (control, name) => (
        <ControlledTextField
          fullWidth
          required
          control={control}
          name={name}
          label="Origins Items Id"
          variant="outlined"
          defaultValue="awsexamplebucket.s3.amazonaws.com-cli-example"
        />
      ),
      '^DefaultCacheBehavior.TargetOriginId$': (control, name) => (
        <ControlledTextField
          fullWidth
          required
          control={control}
          name={name}
          label="Target Origin Id"
          variant="outlined"
          defaultValue="awsexamplebucket.s3.amazonaws.com-cli-example"
        />
      ),
      '^DefaultCacheBehavior.ViewerProtocolPolicy': (control, name) => (
        <ControlledSelect
          variant="outlined"
          required
          control={control}
          fullWidth
          label="Task Definition"
          name={name}
          options={buildOptionsFromArray(PROTOCOL_POLICY_LIST)}
          defaultValue='allow-all'
        />
      ),
      '^Comment$': (control, name) => (
        <ControlledTextField
          fullWidth
          required
          control={control}
          name={name}
          label="Comment"
          variant="outlined"
          defaultValue="-"
        />
      ),
      '^Logging.Bucket$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='S3' method='listBuckets' arrayKeyName='Buckets' property='Name'
          fieldName={fieldName} entityName='S3 Bucket'
          creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^ViewerCertificate.IAMCertificateId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='IAM' method='listServerCertificates' arrayKeyName='ServerCertificateMetadataList'
          property='ServerCertificateId'
          fieldName={fieldName} entityName='IAM Certificate ID'
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    entry={distribution ? 'UpdateDistributionRequest' : 'DistributionConfig'}
    loading={loading}
    onSubmit={(data: CreateCloudFrontDistributionRequest | UpdateDistributionParams) => {
      const obj = { 'DistributionConfig': data };
      if (!distribution) return onCreate(obj as unknown as CreateCloudFrontDistributionRequest);
      return onUpdate({ ...data, Id: distribution?.Id || '' });
    }}
  />
);
