import { ReactElement } from 'react';
import { EventBridgeBus } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface EventBridgeBusDetailsProps {
  bus?: Optional<Partial<EventBridgeBus>>;
}

export const EventBridgeBusDetails = ({ bus }: EventBridgeBusDetailsProps): ReactElement => (
  <MagicDetails
    data={bus}
    schema={getSchema('EventBridge')}
    entry="EventBus"
    title="Bus Details"
  />
);
