import { OpenAPI } from './apis';
import { ApiRequestOptions } from './apis/generated/core/ApiRequestOptions';
import { API_CONFIGURATION } from './config';

export const configureClient = (overrides: Partial<typeof API_CONFIGURATION>): void => {
  if (overrides?.baseUrl) {
    OpenAPI.BASE = overrides.baseUrl;
  }

  if (overrides?.getAuthHeaders) {
    OpenAPI.HEADERS = async (_options: ApiRequestOptions) =>
      overrides.getAuthHeaders?.() as Record<string, string>;
  }

  Object.entries(overrides).forEach(([key, value]) => {
    // eslint-disable-next-line
    // @ts-ignore
    API_CONFIGURATION[key] = value;
  });
};
