import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { S3Routes } from '../../types';

type PermissionsTabsProps = {
  routes: S3Routes;
  bucket: string;
}

export const PermissionsTabs = ({ routes, bucket }: PermissionsTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_S3_BUCKET_PERMISSIONS_CORS_CONFIGURATION, { bucket })}>
      CORS Configuration
    </NavLink>
  </>
);
