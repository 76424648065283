import { DEFAULT_TRANSCRIBE_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';
import { Breadcrumbs, TranscriptionJobDetails } from '@localstack/ui';

import { TranscribeProps } from './types';

export const TranscriptionJob = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_TRANSCRIBE_ROUTES,
}: TranscribeProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();
  const { data: job } = useAwsGetter(
    'TranscribeService',
    'getTranscriptionJob',
    [{ TranscriptionJobName: name }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      documentTitle="Transcribe: Transcription Job Details"
      title={
        <Box>
          <Typography variant="h4">Transcription Job Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Transcribe', () => goto(routes.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOBS)],
              [name, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <TranscriptionJobDetails job={job} />
      </Card>
    </Layout>
  );
};
