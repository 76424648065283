import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwRestApiResourceDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiResourceNavTabs } from './components';

export const AgwRestApiResourceDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, resourceId } = useParams<'restApiId' | 'resourceId'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: resource } = useAwsGetter(
    'APIGateway',
    'getResource',
    [{ restApiId, resourceId }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Resource Details"
      tabs={
        <RestApiResourceNavTabs
          restApiId={restApiId as string}
          resourceId={resourceId as string}
          routes={routes}
        />
      }
      title={
        <Box>
          <Typography variant="h4">Resource Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [resource?.path, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_AGW1_API_RESOURCE_UPDATE, { restApiId, resourceId })}
        >
          Edit Resource
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details resource={resource} />
        </CardContent>
      </Card>
    </Layout>
  );
};
