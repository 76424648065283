import { ReactElement } from 'react';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Grid,
  CardHeader,
  CardActions,
  Button,
} from '@mui/material';
import { MagicForm } from '@localstack/ui';

import { DEFAULT_NEPTUNE_ROUTES, GREMLIN_ACTION_SCHEMA } from '@localstack/constants';

import {
  GremlinEdgeInsertFormProps,
  GremlinNodeInsertFormProps,
  GremlinNodeUpdateFormProps,
  QUERY_TYPES,
} from '@localstack/types/src';

import { NeptuneProps } from './types';

import { VertexIdAutocomplete } from './components/VertexIdAutocomplete';
import { NeptuneRequirementsAlert } from './NeptuneRequirementsAlert';
import { NavTabs } from './components/NavTabs';
import { useNeptuneDataSourceArea } from './components';

export const NeptuneDBClusterQuickAction = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_NEPTUNE_ROUTES,
}: NeptuneProps): ReactElement => {
  
  const {
    returnData,
    sendToServer,
    NeptuneDataSourceArea,
  } = useNeptuneDataSourceArea({ clientOverrides, routes });

  const onSubmitNode = (nodeData: GremlinNodeInsertFormProps) => {
    const query = `g.addV('${nodeData.label}')
      ${Object.entries(nodeData.attributes || {}).map(([key, value]) => `.property('${key}' , '${value}')`).join('')};`;
    sendToServer(query, QUERY_TYPES.NodeInsert);
  };

  const onUpdateNode = (nodeData: GremlinNodeUpdateFormProps) => {
    const query = `g.V('${nodeData.id}')
      ${Object.entries(nodeData.attributes || {}).map(([key, value]) => `.property('${key}' , '${value}')`).join('')}`;
    sendToServer(query, QUERY_TYPES.NodeEdit);
  };

  const onSubmitEdge = (edgeData: GremlinEdgeInsertFormProps) => {
    const query = `g.V('${edgeData.sourceVertexId}')
      .addE('${edgeData.label}').to(V('${edgeData.targetVertexId}'))
      ${Object.entries(edgeData.attributes || {}).map(([key, value]) => `.property('${key}' , '${value}')`).join('')}`;
    sendToServer(query, QUERY_TYPES.EdgeInsert, edgeData.sourceVertexId);
  };

  const onSubmit = (data: GremlinNodeInsertFormProps | GremlinNodeUpdateFormProps | GremlinEdgeInsertFormProps) => {
    if ('id' in data) {
      onUpdateNode(data as GremlinNodeUpdateFormProps);
    } else if ('sourceVertexId' in data) {
      onSubmitEdge(data as GremlinEdgeInsertFormProps);
    } else {
      onSubmitNode(data as GremlinNodeInsertFormProps);
    }
  };

  return (
    <Layout
      documentTitle="Cluster Actions"
      tabs={<NavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Cluster Actions</Typography>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NeptuneRequirementsAlert />
        </Grid>
        <Grid item xs={12}>
          {NeptuneDataSourceArea}
        </Grid>
        <Grid item md={6}>
          <Card>
            <CardHeader title="Add Node" />
            <CardContent>
              <MagicForm
                schema={GREMLIN_ACTION_SCHEMA}
                entry="NodeInsertForm"
                formId="NodeInsertForm"
                onSubmit={onSubmit}
              />
            </CardContent>
            <CardActions>
              <Button
                type="submit"
                form="NodeInsertForm"
                variant="outlined"
                color="primary"
              >
                Add Node
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item md={6}>
          <Card>
            <CardHeader title="Update Node" />
            <CardContent>
              <MagicForm
                schema={GREMLIN_ACTION_SCHEMA}
                entry="NodeUpdateForm"
                formId="NodeUpdateForm"
                onSubmit={onSubmit}
                externalFields={{
                  '^id$': (control, name) => (
                    <VertexIdAutocomplete
                      name={name}
                      control={control}
                      options={returnData.nodes}
                      placeholder='Id'
                    />
                  ),
                }}
              />
            </CardContent>
            <CardActions>
              <Button
                type="submit"
                form="NodeUpdateForm"
                variant="outlined"
                color="primary"
              >
                Update Node
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title="Add Edge" />
            <CardContent>
              <MagicForm
                schema={GREMLIN_ACTION_SCHEMA}
                entry="EdgeInsertForm"
                formId="EdgeInsertForm"
                onSubmit={onSubmit}
                externalFields={{
                  '^sourceVertexId$': (control, name) => (
                    <VertexIdAutocomplete
                      name={name}
                      control={control}
                      options={returnData.nodes}
                      placeholder='Source Vertex Id'
                    />
                  ),
                  '^targetVertexId$': (control, name) => (
                    <VertexIdAutocomplete
                      name={name}
                      control={control}
                      options={returnData.nodes}
                      placeholder='Target Vertex Id'
                    />
                  ),
                }}
              />
            </CardContent>
            <CardActions>
              <Button
                type="submit"
                form="EdgeInsertForm"
                variant="outlined"
                color="primary"
              >
                Add Edge
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid >
    </Layout >
  );
};
