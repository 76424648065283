import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AgwRestApi } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApisTableProps = {
  selectable?: boolean;
  loading?: boolean;
  apis: AgwRestApi[];
  onViewApi?: (api: AgwRestApi) => unknown;
  onSelect?: (ids: string[]) => void;
};

export const AgwRestApisTable = ({
  apis,
  selectable = true,
  loading,
  onViewApi,
  onSelect,
}: AgwRestApisTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="RestApi"
    idAttribute="id"
    rows={apis}
    selectable={selectable}
    onSelect={onSelect}
    order={['id', 'name']}
    loading={loading}
    externalFields={{
      id: (row) => (
        <Link onClick={() => onViewApi && onViewApi(row)} underline="hover">
          {row.id}
        </Link>
      ),
    }}
  />
);
