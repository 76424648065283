import { DEFAULT_TRANSCRIBE_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { TranscriptionJobsTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { TranscribeProps } from './types';

export const TranscriptionJobs = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_TRANSCRIBE_ROUTES,
}: TranscribeProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: jobs, isLoading, mutate } = useAwsGetter(
    'TranscribeService',
    'listTranscriptionJobs',
    [],
    { clientOverrides },
  );
  const { deleteTranscriptionJob } = useAwsEffect('TranscribeService', ['deleteTranscriptionJob'], {
    revalidate: ['listTranscriptionJobs'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteTranscriptionJob({ TranscriptionJobName: id }),
      ),
    );
  };
  return (
    <Layout
      documentTitle="Transcribe: Transcription Jobs"
      title={<PageTitle title="Transcribe Transcription Jobs" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOB_CREATE)}>
            Create Transcription Job
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Transcription Job(s)?`}
              onClick={handleRemove}
              text="Selected Job will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <TranscriptionJobsTable
          jobs={jobs || {}}
          onViewTranscriptionJob={(name: string) =>
            goto(routes.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOB, { name })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
