import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { StepFunctionsRoutes } from '../../types';

interface Props {
  stateMachineArn: string;
  routes: StepFunctionsRoutes;
}

export const DefinitionTabs = ({ stateMachineArn, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION_SCHEMA, { stateMachineArn })}>
      Schema
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION_FLOWCHART, { stateMachineArn })}>
      Flowchart
    </NavLink>
  </>
);
