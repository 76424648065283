import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  AgwRestApiResourcesTable,
} from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiResources = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { restApiId } = useParams<'restApiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: resources, mutate } = useAwsGetter('APIGateway', 'getResources', [{ restApiId }], { clientOverrides });

  const { deleteResource, isLoading } = useAwsEffect(
    'APIGateway',
    ['deleteResource'],
    {
      revalidate: ['getResources', 'getResource'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((resourceId) => deleteResource({ restApiId: restApiId as string, resourceId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Resources"
      title={
        <PageTitle
          title="API Resources"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
            [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
            ['Resources', null],
          ]}
        />
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_RESOURCE_CREATE, { restApiId })}>
            Create Resource
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Resource(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Resources will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwRestApiResourcesTable
          resources={resources?.items || []}
          onSelect={setSelectedIds}
          onViewResource={
            ({ id }) => goto(
              routes.RESOURCES_AGW1_API_RESOURCE,
              { restApiId, resourceId: id },
            )
          }
        />
      </Card>
    </Layout>
  );
};
