import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiFilledInput'] => ({
  styleOverrides: {
    root: {
      backgroundColor: palette.background.default,
      '&:hover': {
        backgroundColor: palette.primary.light,
      },
      '&$focused': {
        backgroundColor: palette.primary.light,
      },
    },
  },
});
