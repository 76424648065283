import { ReactElement } from 'react';
import { GlueJobRun } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface GlueJobRunDetailsProps {
  jobRun?: Optional<Partial<GlueJobRun>>;
}

export const GlueJobRunDetails = ({ jobRun }: GlueJobRunDetailsProps): ReactElement => (
  <MagicDetails
    data={jobRun}
    schema={getSchema('Glue')}
    entry="JobRun"
    title="Job Run Details"
  />
);
