import { v4 as uuid } from 'uuid';
import { useMemo, useState } from 'react';
import { QueryResponse } from '@localstack/types';

import { MagicTable } from '../../../magic/MagicTable';

const DEFAULT_PAGE_SIZE = 10;

export interface TimestreamQueryGridProps {
  data: QueryResponse,
  loading?: boolean;
  hasMore?: boolean;
  pageSize?: number;
}

export const TimestreamQueryGrid: React.FC<TimestreamQueryGridProps> = ({
  data,
  loading,
  pageSize = DEFAULT_PAGE_SIZE,
}) => {
  const [page, setPage] = useState(0);

  const schema = useMemo(
    () => ({
      shapes: {
        TSResults: {
          type: 'structure',
          members: Object.fromEntries(
            (data?.ColumnInfo ?? []).map(
              (ci) => [ci.Name, { type: 'string' }],
            ),
          ),
        },
      },
    }),
    [data],
  );

  const tablePageSize = pageSize || DEFAULT_PAGE_SIZE;
  const columnNames = data?.ColumnInfo?.map((ci) => ci.Name) ?? [];

  const rowsWithColumns = (data?.Rows ?? []).map(
    (row) => (row.Data ?? []).reduce(
      (memo, values, idx) => ({ ...memo, [columnNames[idx] as string]: values.ScalarValue }),
      {},
    ),
  );

  // we need to bring unique row ids
  const hashedRows = rowsWithColumns.map((row) => ({ $rowId: uuid(), ...row }));

  return (
    <MagicTable
      pagination
      page={page}
      pageSize={tablePageSize}
      rowCount={hashedRows.length}
      onPageChange={setPage}
      rowsPerPageOptions={[tablePageSize]}
      schema={schema}
      loading={loading}
      entry="TSResults"
      rows={hashedRows}
      idAttribute="$rowId"
      formatNames={false}
      selectable={false}
    />
  );
};
