import { ReactElement } from 'react';
import { SQSMessage } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type SQSMessagesTableProps = {
  loading?: boolean;
  entries: SQSMessage[];
  onViewSQSMessage?: (subscription: SQSMessage) => void;
};

export const SQSMessagesTable = ({
  entries,
  loading,
  onViewSQSMessage,
}: SQSMessagesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('SQS')}
    entry="Message"
    idAttribute="MessageId"
    rows={entries}
    selectable={false}
    loading={loading}
    pageSize={25}
    externalFields={{
      MessageId: (row) => (
        <Link
          onClick={() => onViewSQSMessage && onViewSQSMessage(row)}
          underline="hover"
        >
          {row.MessageId}
        </Link>
      ),
    }}
  />
);
