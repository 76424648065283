import { useCallback, useState, ReactElement } from 'react';
import { Card, Button } from '@mui/material';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';

import {
  BackupVaultsTable,
  Dropdown,
  ConfirmableButton,
  LoadingFragment,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_BACKUP_ROUTES } from '@localstack/constants/src';

import { DetailsNavTabs } from './components';
import { BackupProps } from './types';

export const BackupVaults = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_BACKUP_ROUTES,
}: BackupProps): ReactElement => {
  const { goto } = useRoutes();

  const [selectedObjects, setSelectedObjects] = useState<string[]>([]);

  const { data: backupVaults, isLoading: isVaultsLoading, mutate } =
    useAwsGetter('Backup', 'listBackupVaults', [], { clientOverrides });

  const { deleteBackupVault, isLoading: isVaultMutating } = useAwsEffect(
    'Backup',
    ['deleteBackupVault'],
    { revalidate: ['describeBackupVault', 'listBackupVaults'], clientOverrides },
  );

  const isLoading = isVaultsLoading || isVaultMutating;

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selectedObjects.map((BackupVaultName) => deleteBackupVault({ BackupVaultName }))),
    [selectedObjects],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="Backup Vaults"
            onMutate={mutate}
          />
        }
        tabs={<DetailsNavTabs routes={routes} />}
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_BACKUP_VAULT_CREATE)}>
              Create
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedObjects.length === 0 || isLoading}
                title={`Remove ${selectedObjects.length} object(s)?`}
                onClick={handleDeleteObjects}
                text="Selected Objects will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <BackupVaultsTable
              vaults={backupVaults?.BackupVaultList ?? []}
              onSelect={setSelectedObjects}
              onViewVault={(vault) => goto(
                routes.RESOURCES_BACKUP_VAULT,
                { name: vault.BackupVaultName as string },
              )}
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
