import { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';
import { CheckOutlined as PassIcon, Clear as ErrorIcon } from '@mui/icons-material';

import { passwordValidator } from '@localstack/services';

import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    item: {
      width: '50%',
    },
    passedItem: {
      '& svg': {
        fill: theme.palette.success.main,
      },
      '& span': {
        color: theme.palette.success.main,
      },
    },
    badItem: {
      '& svg': {
        fill: theme.palette.error.main,
      },
      '& span': {
        color: theme.palette.error.main,
      },
    },
  }),
);

export interface PasswordCheckProps {
  password: string;
}

export const PasswordCheck = ({ password }: PasswordCheckProps): ReactElement => {
  const classes = useStyles();

  const result = passwordValidator(password);

  const textMapping: Record<keyof ReturnType<typeof passwordValidator>, string> = {
    digits: 'Digits',
    lowercase: 'Lowercase letters',
    uppercase: 'Uppercase letters',
    specials: 'Special characters',
    length: '8 characters long',
  };

  return (
    <List dense className={classes.listContainer}>
      {Object.entries(textMapping).map(([k, v]) => (
        <ListItem
          key={k}
          className={classnames(classes.item, {
            [classes.passedItem]: result[k as keyof typeof result],
            [classes.badItem]: !result[k as keyof typeof result],
          })}
        >
          <ListItemIcon>
            {result[k as keyof typeof result] && <PassIcon />}
            {!result[k as keyof typeof result] && <ErrorIcon />}
          </ListItemIcon>
          <ListItemText primary={v} />
        </ListItem>
      ))}
    </List>
  );
};
