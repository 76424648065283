import { LoadingFragment } from '@localstack/ui';
import { CardContent, Grid , Skeleton } from '@mui/material';
import { ReactElement } from 'react';

export const StackTabSkeleton = (): ReactElement => (
  <CardContent>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={250} width="100%" />
      </Grid>
      <Grid item xs={12} xl={6}>
        <Skeleton variant="rectangular" height={250} width="100%" />
      </Grid>
      <Grid item xs={12} xl={6}>
        <Skeleton variant="rectangular" height={250} width="100%" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={78} width="100%" />
      </Grid>
      <Grid item xs={12}>
        <LoadingFragment variant="list" size={12} height={52} loading />
      </Grid>
    </Grid>
  </CardContent>
);
