import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  AgwApisTable,
} from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { IndexNavTabs } from './components';

export const AgwV2Index = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: apis, isLoading, mutate } = useAwsGetter('ApiGatewayV2', 'getApis', [], { clientOverrides });

  const { deleteApi } = useAwsEffect(
    'ApiGatewayV2',
    ['deleteApi'],
    {
      revalidate: ['getApi', 'getApis'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((ApiId) => deleteApi({ ApiId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={
        <PageTitle
          title="API Gateway"
          onMutate={mutate}
        />
      }
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW2_API_CREATE)}>
            Create API
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} API(s)?`}
              onClick={handleDeleteSelected}
              text="Selected APIs will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwApisTable
          apis={apis?.Items || []}
          onSelect={setSelectedIds}
          onViewApi={({ ApiId }) => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId as string })}
        />
      </Card>
    </Layout>
  );
};
