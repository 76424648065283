import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { S3Bucket } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type S3BucketsTableProps = {
  selectable?: boolean;
  buckets: S3Bucket[];
  onViewBucket?: (name: string) => void;
  onSelect?: (names: string[]) => void;
};

export const S3BucketsTable = ({
  buckets,
  selectable = true,
  onViewBucket,
  onSelect,
}: S3BucketsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('S3')}
    entry="Buckets"
    idAttribute="Name"
    rows={buckets}
    selectable={selectable}
    onSelect={onSelect}
    externalFields={{
      Name: (row) => (
        <Link
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            return onViewBucket && onViewBucket(row.Name as string);
          }}
          underline="hover"
        >
          {row.Name}
        </Link>
      ),
    }}
  />
);
