import { DEFAULT_AMPLIFY_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, AmplifyAppDetail } from '@localstack/ui';

import { AmplifyProps } from './types';

export const AmplifyApp = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_AMPLIFY_ROUTES,
}: AmplifyProps): ReactElement => {
  const { goto } = useRoutes();
  const { appId } = useParams<'appId'>();
  const { data: app } = useAwsGetter(
    'Amplify',
    'getApp',
    [{ appId }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Amplify App</Typography>
          <Breadcrumbs
            mappings={[
              ['Amplify', () => goto(routes.RESOURCES_AMPLIFY_APPS)],
              [appId, null],
            ]}
          />
        </Box>
      }
      // tabs={<NavTabs routes={routes} appId={appId ?? ''} />}
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_AMPLIFY_APP_UPDATE, { appId })}
        >
          Edit App
        </Button>
      }
    >
      <Card>
        <AmplifyAppDetail app={app} />
      </Card>
    </Layout>
  );
};
