import { LocalStackWebEvent } from '../constants';

type Observer<T> = (event: T) => void;

class Observable<T> {
  private observers: Observer<T>[] = [];

  subscribe(observer: Observer<T>): void {
    this.observers.push(observer);
  }

  unsubscribe(observer: Observer<T>): void {
    this.observers = this.observers.filter(obs => obs !== observer);
  }

  notify(event: T): void {
    this.observers.forEach(observer => observer(event));
  }
}

export const observable = new Observable<LocalStackWebEvent>();
