import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { GlueRoutes } from '../../types';

type TableTabsProps = {
  routes: GlueRoutes;
  database: string;
  table: string;
  partitions: string;
}

export const TableTabs = ({ routes, database, table, partitions }: TableTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_TABLE_PARTITIONS, { database, table, partitions })} end>
      Partitions
    </NavLink>
  </>
);
