import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, AppSyncTypesTable,PageTitle } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

const DEFAULT_FORMAT = 'SDL';

export const AppSyncApiTypes = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const { data: types, isLoading, mutate } =
    useAwsGetter('AppSync', 'listTypes', [{ apiId, format: DEFAULT_FORMAT }], { clientOverrides });

  const { deleteType } = useAwsEffect(
    'AppSync',
    ['deleteType'],
    { revalidate: ['listTypes', 'getType'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((typeName) => deleteType({ apiId: apiId as string, typeName }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Types"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <PageTitle
          title="API Types"
          onMutate={mutate}
          breadcrumbs={[
            ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
            [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
            ['Types', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_APPSYNC_API_TYPE_CREATE, { apiId })}>
            Create Type
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Type(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Types will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AppSyncTypesTable
          selectable
          loading={isLoading}
          types={types?.types ?? []}
          onSelect={setSelectedIds}
          onViewType={
            ({ name }) => goto(routes.RESOURCES_APPSYNC_API_TYPE_UPDATE, { apiId, name })
          }
        />
      </Card>
    </Layout>
  );
};
