import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiListItemIcon'] => ({
  styleOverrides: {
    root: {
      color: palette.text.secondary,
      minWidth: '32px',
    },
  },
});
