import {
  useApiGetter,
  ExtensionService,
  useAwsEffect,
  useAwsGetter,
  useSnackbar,
  useLocalstackStatus,
  useLocalStackEvents,
  satisfiesVersionConstraint,
} from '@localstack/services';


import { ReactElement, useEffect, useMemo, useState } from 'react';

import { Grid, Link, Typography, Alert, AlertTitle } from '@mui/material';

import { Extension } from '@localstack/types';

import { LoadingFragment } from '@localstack/ui';


import { ExternalLink } from '@localstack/constants';

import { ExtensionCard, gridItemBreakpoints } from '../ExtensionCard';


type Props = {
  url: string;
};

export const ExtensionsInstaller = ({ url }: Props): ReactElement => {
  const { showSnackbar } = useSnackbar();

  const { running, version, isPro } = useLocalstackStatus();
  const { logs, setLogs } = useLocalStackEvents();

  const [isExtensionMutating, setIsExtensionMutating] = useState(false);

  const { data: extension, isLoading: loadingExtension, hasError: hasPublishedExtensionsError } =
    useApiGetter(ExtensionService, 'getGitExtension', [url], { defaultValue: undefined });

  const hasVersionMismatch = !satisfiesVersionConstraint(version || '', '2.3');

  const { data: installedExtensions } =
    useAwsGetter(
      'LocalStack', 'listExtensions', [],
      {
        silentErrors: true,
        swrOverrides: {
          refreshInterval: 1000,
          errorRetryInterval: 1000,
          shouldRetryOnError: true,
          onErrorRetry: (_error, _key, _config, revalidate) => {
            setTimeout(() => revalidate(), 1000);
          },
        },
      },
    );

  const isExtensionInstalled = useMemo(() =>
    installedExtensions?.some((ext: Extension) => ext.distribution?.name === extension?.name),
  [installedExtensions, extension]);


  useEffect(() => {
    if (isExtensionMutating) {
      setIsExtensionMutating(false);
      showSnackbar({
        message: `Extension ${extension?.name} has been ${isExtensionInstalled ? 'installed' : 'uninstalled'}`,
        severity: 'success',
      });
    }
  }, [isExtensionInstalled]);


  const {
    installExtension,
    uninstallExtension,
    isLoading: isExtensionManageLoading,
    hasError: hasExtensionInstallError,
  } = useAwsEffect(
    'LocalStack',
    ['installExtension', 'uninstallExtension'],
  );


  const onInstall = () => {
    setIsExtensionMutating(true);
    installExtension(url, setLogs);
  };

  const onUninstall = () => {
    setIsExtensionMutating(true);
    uninstallExtension(extension?.name || '', setLogs);
  };

  const hasErrorManaging = useMemo(() => {
    if (hasExtensionInstallError) return true;
    const errorInLogs = logs?.some(log => log.event === 'error' || 'exception');
    return !!errorInLogs;
  }, [logs, hasExtensionInstallError]);

  useEffect(() => {
    if (!isExtensionManageLoading && hasErrorManaging) {
      setIsExtensionMutating(false);
    }
  }, [isExtensionManageLoading, hasErrorManaging]);


  const AlertLocalStackNotRunning =
    <Alert severity='warning'>
      <AlertTitle>
        Could not connect to running LocalStack instance
      </AlertTitle>
      Start a new LocalStack instance to install the extension. Find out more in our {' '}
      <Link
        href={ExternalLink.DOCS_GETTING_STARTED}
        target='_blank'
        underline="hover"
      >documentation</Link>.
    </Alert>;

  const AlertVersionMismatch =
    <Alert severity='warning'>
      <AlertTitle>
        Your LocalStack version is too old
      </AlertTitle>
      The extension you are trying to install requires LocalStack version 2.3 or higher.
      Please update your LocalStack instance and try again.
    </Alert>;

  const AlertMetadataNotFound =
    <Alert severity='warning'>
      <AlertTitle>
        Failed to load extension metadata
      </AlertTitle>
      There seems to be something wrong with the provided url.
      Please ensure that it is correct and try again.
    </Alert>;

  const AlertNotProVersion =
    <Alert severity='warning'>
      <AlertTitle>
        Community Edition detected
      </AlertTitle>
      Please start the LocalStack Pro edition to manage extensions via this UI
    </Alert>;

  const disableManage = !running || isExtensionMutating || hasVersionMismatch || !isPro;

  return (
    <>
      <Typography variant="h5" color='textSecondary'>
        Add the following extension to your LocalStack instance
      </Typography>

      <Grid container spacing={2}>
        {!(running || isExtensionMutating) &&
          <Grid item xs={12}>
            {AlertLocalStackNotRunning}
          </Grid>
        }
        {running && hasPublishedExtensionsError &&
          <Grid item xs={12}>
            {AlertMetadataNotFound}
          </Grid>
        }
        {running && !hasPublishedExtensionsError && hasVersionMismatch &&
          <Grid item xs={12}>
            {AlertVersionMismatch}
          </Grid>
        }
        {running && !isPro &&
          <Grid item xs={12}>
            {AlertNotProVersion}
          </Grid>
        }
        <LoadingFragment
          loading={loadingExtension}
          variant="grid"
          size={1}
          gridItemBreakpoints={gridItemBreakpoints('medium')}
          height={170}
        >
          {extension &&
            <ExtensionCard
              extension={extension}
              onInstall={onInstall}
              onUninstall={onUninstall}
              installed={isExtensionInstalled}
              isUpdating={isExtensionMutating}
              logOutput={logs}
              disableManage={disableManage}
              size='medium'
              showTerminal
              hasError={hasExtensionInstallError && !isExtensionManageLoading}
            />
          }
        </LoadingFragment>
      </Grid>
    </>
  );
};
