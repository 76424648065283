import { ReactElement, useState } from 'react';
import { ButtonGroup, Button, ButtonGroupProps, MenuItem } from '@mui/material';

import { Dropdown } from '../Dropdown';

export interface SplitButtonAction {
  id: string;
  title: string;
}

export interface SplitButtonProps {
  actions: SplitButtonAction[];
  defaultAction: string;
  onClick?: (action: SplitButtonAction) => unknown;
  component?: 'DangerZoneAction';
}

export const SplitButton = ({
  actions,
  defaultAction,
  onClick,
  ...rest
}: Omit<ButtonGroupProps, 'onClick'> & SplitButtonProps): ReactElement => {
  const [current, setCurrent] = useState<SplitButtonAction>(
    actions.find((a) => a.id === defaultAction) as SplitButtonAction,
  );

  return (
    <ButtonGroup {...rest}>
      <Button onClick={() => onClick?.(current)}>{current.title}</Button>
      <Dropdown>
        {actions.map((action) => (
          <MenuItem
            id={action.id}
            title={action.title}
            onClick={() => setCurrent(action)}
          >
            {action.title}
          </MenuItem>
        ))}
      </Dropdown>
    </ButtonGroup>
  );
};
