import { useState, useCallback, ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { useAwsGetter, useAwsEffect, useRoutes } from '@localstack/services';
import { Dropdown, ConfirmableButton, SecretsManagerSecretsTable, PageTitle } from '@localstack/ui';
import { DEFAULT_SECRETSMANAGER_ROUTES } from '@localstack/constants';

import { SecretsManagerProps } from './types';

export const SecretsManagerSecrets = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SECRETSMANAGER_ROUTES,
}: SecretsManagerProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const { data: secrets, isLoading, mutate } = useAwsGetter('SecretsManager', 'listSecrets', [], { clientOverrides });

  const { deleteSecret } = useAwsEffect(
    'SecretsManager',
    ['deleteSecret'],
    { revalidate: ['listSecrets', 'describeSecret', 'getSecretValue'], clientOverrides },
  );

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((SecretId) => deleteSecret({ SecretId }))),
    [selected],
  );

  return (
    <Layout
      title={
        <PageTitle
          title="Secrets Manager"
          onMutate={mutate}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_SECRETS_MANAGER_SECRET_CREATE)}>
            Add a Secret
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} secret(s)?`}
              onClick={handleDeleteObjects}
              text="Selected secrets will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <SecretsManagerSecretsTable
          loading={isLoading}
          selectable
          secrets={secrets?.SecretList ?? []}
          onSelect={setSelected}
          onViewSecret={
            ({ Name }) => goto(routes.RESOURCES_SECRETS_MANAGER_SECRET, { secretId: Name })
          }
        />
      </Card>
    </Layout>
  );
};
