import { ReactElement } from 'react';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateGlobalRDSCluster } from '@localstack/types';
import { ProgressButton, RDSGlobalDatabaseForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { RDSProps } from './types';

export const RDSDatabaseCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_RDS_ROUTES,
}: RDSProps): ReactElement => {
  const { goto } = useRoutes();

  const { createGlobalCluster, isLoading } = useAwsEffect(
    'RDS',
    ['createGlobalCluster'],
    { revalidate: ['describeGlobalClusters', 'describeDBClusters', 'describeDBInstances'], clientOverrides },
  );

  const handleCreate = async (data: CreateGlobalRDSCluster) => {
    await createGlobalCluster(data);
    goto(routes.RESOURCES_RDS_DATABASES);
  };

  return (
    <Layout
      documentTitle="RDS: Create Global Database"
      title={
        <Box>
          <Typography variant="h4">Create Global Database</Typography>
          <Breadcrumbs
            mappings={[
              ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
              ['Create Database', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="create"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <RDSGlobalDatabaseForm
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            loading={isLoading}
            formId="create"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="create"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
