import { ReactElement, useState } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { DEFAULT_ACCOUNT_ROUTES } from '@localstack/constants';
import { Card, CardContent, Button, MenuItem } from '@mui/material';

import {
  AlternateContactTable,
  Dropdown,
  PageTitle,
} from '@localstack/ui';

import { AccountProps } from './types';
import { NavTabs } from './components/NavTabs';
import { ALTERNATE_CONTACT_TYPES } from './constants';

export const AlternateContact = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ACCOUNT_ROUTES,
}: AccountProps): ReactElement => {
  const [selectedContactType, setSelectedContactType] = useState<string>(ALTERNATE_CONTACT_TYPES[0] as string);
  const { goto } = useRoutes();

  const { data: info, isLoading, mutate, hasError } = useAwsGetter(
    'Account',
    'getAlternateContact',
    [{ AlternateContactType: selectedContactType }],
    { clientOverrides, silentErrors: true },
  );

  const handleUpsert = () => {
    goto(routes.RESOURCES_ALTERNATE_CONTACT_FORM, {}, `contactType=${selectedContactType}`);
  };

  return (
    <Layout
      documentTitle="Account: Alternate Contact"
      tabs={<NavTabs routes={routes} />}
      title={<PageTitle title="Account" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={handleUpsert} disabled={!isLoading && !!info?.AlternateContact}>
            Create
          </Button>
          <Dropdown label={selectedContactType}>
            {ALTERNATE_CONTACT_TYPES.map((contactType) => (
              <MenuItem key={contactType} onClick={() => setSelectedContactType(contactType)}>
                {contactType}
              </MenuItem>
            ))}
          </Dropdown>
        </>
      }
    >
      <Card>
        <CardContent>
          <AlternateContactTable
            item={!hasError ? info?.AlternateContact : undefined}
            onEditAlternateContact={handleUpsert}
            loading={isLoading}
          />
        </CardContent>
      </Card>
    </Layout>
  );
};
