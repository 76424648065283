import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAppSyncResolverRequest, UpdateAppSyncResolverRequest } from '@localstack/types';
import { ProgressButton, AppSyncResolverForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiResolverUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId, typeName, fieldName } = useParams<'apiId' | 'typeName' | 'fieldName'>();

  const { createResolver, updateResolver, isLoading } = useAwsEffect(
    'AppSync',
    ['createResolver', 'updateResolver'],
    { revalidate: ['listResolvers', 'getResolver'], clientOverrides },
  );

  const {
    data: resolver } = useAwsGetter('AppSync', 'getResolver', [{ apiId, typeName, fieldName }], { clientOverrides });

  const handleCreate = async (data: CreateAppSyncResolverRequest) => {
    await createResolver(data);
    goto(routes.RESOURCES_APPSYNC_API_RESOLVERS, { apiId });
  };

  const handleUpdate = async (data: UpdateAppSyncResolverRequest) => {
    await updateResolver(data);
    goto(routes.RESOURCES_APPSYNC_API_RESOLVER, { apiId, typeName, fieldName });
  };

  return (
    <Layout
      documentTitle="Resolver Details"
      title={
        <Box>
          <Typography variant="h4">Resolver Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              [
                fieldName && `${typeName}#${fieldName}`,
                () => goto(routes.RESOURCES_APPSYNC_API_RESOLVER, { apiId, typeName, fieldName }),
              ],
              [fieldName ? 'Update' : 'Create Resolver', null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AppSyncResolverForm
            apiId={apiId as string}
            clientOverrides={clientOverrides}
            typeName={typeName as string}
            resolver={resolver?.resolver}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
