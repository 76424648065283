import {
  LocalStackEventType,
  LocalStackWebEvent,
  getThemeOptionsFromType,
  ThemeType,
  useObservableEvent,
  getStoredThemeType,
} from '@localstack/integrations';
import { ReactElement, useEffect, useRef } from 'react';

import { createStyles, makeStyles } from '@mui/styles';


const useStyles = makeStyles(() =>
  createStyles({
    fullFrame: {
      width: '100%',
      height: '100%',
      border: 'none',
    },
  }),
);

type IFrameExtensionProps = {
  url: string;
}

export const IframeExtension = ({ url }: IFrameExtensionProps): ReactElement => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const classes = useStyles();

  const postThemeInject = (theme: ThemeType) => {
    iframeRef.current?.contentWindow?.postMessage({
      eventType: LocalStackEventType.THEME_INJECT,
      data: { theme: JSON.stringify(getThemeOptionsFromType(theme)) },
    }, '*');
  };

  const injectTheme = () => postThemeInject(getStoredThemeType());

  const observer = (event: LocalStackWebEvent) => {
    const window = iframeRef.current?.contentWindow;

    if (event.eventType === LocalStackEventType.THEME_UPDATE) { // we inject, not update the theme
      postThemeInject(event.data.theme);
    } else {
      window?.postMessage(event, '*');
    }

  };

  useObservableEvent({ genericUpdate: observer });

  useEffect(() => {
    const iframe = iframeRef.current;

    const onLoad = () => injectTheme();
    iframe?.addEventListener('load', onLoad);

    return () => iframe?.removeEventListener('load', onLoad);
  }, []);

  return (
    <iframe
      ref={iframeRef}
      src={url}
      title="Embedded Iframe"
      className={classes.fullFrame}
    />
  );
};
