import { ReactElement, useCallback, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import {
  Dropdown,
  ConfirmableButton,
  PageTitle,
  GlueDatabasesTable,
} from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueDatabases = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: databases, isLoading, mutate } = useAwsGetter(
    'Glue',
    'getDatabases',
    [],
    { clientOverrides },
  );

  const { deleteDatabase } = useAwsEffect(
    'Glue',
    ['deleteDatabase'],
    { revalidate: ['getDatabases'], clientOverrides });

  const handleDeleteSelected = useCallback(async () =>
    Promise.all(selected.map((database) => deleteDatabase({ Name: database }))),
  [selected],
  );

  return (
    <Layout
      documentTitle="Glue: Databases"
      title={
        <PageTitle
          title="Glue Databases"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Databases', () => goto(routes.RESOURCES_GLUE_DATABASES)],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_GLUE_DATABASE_CREATE)}>
            Create Database
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Database(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Database(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <GlueDatabasesTable
          databases={databases?.DatabaseList ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewDatabase={(database) => goto(routes.RESOURCES_GLUE_DATABASE_TABLES, { database })}
        />
      </Card>
    </Layout>
  );
};
