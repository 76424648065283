import { AuthToken, CombinedUserInfo } from '@localstack/types';

import {
  STORAGE_KEY_RESOURCE_BASE_URL,
  DEFAULT_RESOURCE_BASE_URL,
  STORAGE_KEY_USER_INFO,
  STORAGE_KEY_PRE_SIGNIN_PAGE_URL,
  STORAGE_KEY_AUTH_TOKEN,
  STORAGE_KEY_IMPERSONATION_MARKER, STORAGE_KEYS,
} from '@localstack/constants';

// *** AUXILIARY FUNCTIONS ***

export const clearStorage = (): void =>
  STORAGE_KEYS.forEach((key) => localStorage.removeItem(key));

export const extractDataFromLocalStorage = <T>(key: string): Optional<T> => {
  const data = localStorage.getItem(key);

  if (data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      console.error('ERROR: Unable to parse the data');
    }
  }

  return undefined;
};

// *** USER INFO ***

export const storeUserInfo = (info: CombinedUserInfo): void => {
  localStorage.setItem(STORAGE_KEY_USER_INFO, JSON.stringify(info));
};

export const getUserInfo = (): Optional<CombinedUserInfo> =>
  extractDataFromLocalStorage<CombinedUserInfo>(STORAGE_KEY_USER_INFO);

// *** AUTH TOKENS ***

export const storeAuthToken = (token: AuthToken): void =>
  localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, JSON.stringify(token));

export const retrieveAuthToken = (): Optional<AuthToken> =>
  extractDataFromLocalStorage<AuthToken>(STORAGE_KEY_AUTH_TOKEN);

// *** IMPERSONATION ***

export const storeImpersonationMarker = (marker: Optional<string>): void =>
  localStorage.setItem(STORAGE_KEY_IMPERSONATION_MARKER, JSON.stringify(marker));

export const retrieveImpersonationMarker = (): Optional<string> =>
  extractDataFromLocalStorage<string>(STORAGE_KEY_IMPERSONATION_MARKER);

export const getResourcesBaseURL = (): string =>
  // backward compatibility
  (getUserInfo()?.user as unknown as { localEndpoint?: string })?.localEndpoint ||
  localStorage.getItem(STORAGE_KEY_RESOURCE_BASE_URL) ||
  DEFAULT_RESOURCE_BASE_URL;

export const updateResourcesBaseURL = (url: Optional<string>): void => {
  localStorage.setItem(STORAGE_KEY_RESOURCE_BASE_URL, url || '');
};

export const getPreSignInPageURL = (): string | null =>
  localStorage.getItem(STORAGE_KEY_PRE_SIGNIN_PAGE_URL);


export const updatePreSignInPageURL = (url: Optional<string>): void => {
  localStorage.setItem(STORAGE_KEY_PRE_SIGNIN_PAGE_URL, url || '');
};
