export const DATABASE_TYPES = {
  GLOBAL: 'Global Database',
  CLUSTER: 'Cluster',
  CLUSTER_PRIMARY: 'Primary Cluster',
  CLUSTER_SECONDARY: 'Secondary Cluster',
  INSTANCE: 'Instance',
};

const CLUSTER_TYPES = [DATABASE_TYPES.CLUSTER_PRIMARY, DATABASE_TYPES.CLUSTER_SECONDARY];

export const isCluster = (type: string | undefined): boolean => CLUSTER_TYPES.includes(type || '');
