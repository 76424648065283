import { ReactElement } from 'react';
import { GlueSchemaVersion } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueSchemaVersionsTableProps = {
  schemaVersions: GlueSchemaVersion[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewSchemaVersion?: (name: string) => void;
};

export const GlueSchemaVersionsTable = ({
  schemaVersions,
  selectable = true,
  loading,
  onSelect,
  onViewSchemaVersion,
}: GlueSchemaVersionsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="SchemaVersionList"
    idAttribute="VersionNumber"
    rows={schemaVersions}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    filterColumns={['VersionNumber', 'SchemaVersionId', 'Status', 'CreatedTime']}
    order={['VersionNumber', 'SchemaVersionId', 'Status', 'CreatedTime']}
    externalFields={{
      VersionNumber: (row) => (
        <Link
          onClick={() => onViewSchemaVersion && onViewSchemaVersion((row.VersionNumber || 0)?.toString())}
          underline="hover"
        >
          {row.VersionNumber}
        </Link>
      ),
    }}
  />
);
