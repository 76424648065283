import { ReactElement } from 'react';

import { Box, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  Person as PersonIcon,
  AccountTree as AccountTreeIcon,
} from '@mui/icons-material';
import { CodeSnippetViewer } from '@localstack/ui';

import { capitalise } from '@localstack/services';

import { GeneratedPolicy } from '@localstack/types';

interface PolicyCardProps {
  policy: GeneratedPolicy,
  showCode?: boolean
}


const useStyles = makeStyles(() => createStyles({
  iconStyle: {
    display: 'block',
    borderRadius: 6,
    width: '32px',
    height: '32px',
  },
}));

export const PolicyCard = ({ policy, showCode = true }: PolicyCardProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <Box alignItems='center' display='flex' width='100%'>
        <Box padding='6px 8px'>
          {policy.policy_type === 'identity' ?
            <PersonIcon
              className={classes.iconStyle}
            /> :
            <AccountTreeIcon
              className={classes.iconStyle}
            />
          }
        </Box>
        <Box>
          <Typography variant='subtitle1' style={{ lineHeight: '16px' }}>
            Required {capitalise(policy.policy_type)}-Based Policy
          </Typography>
          <Typography variant='caption'>{policy.resource}</Typography>
        </Box>
      </Box>
      {showCode &&
        <CodeSnippetViewer data={JSON.stringify(policy.policy_document)} />
      }
    </>
  );
};
