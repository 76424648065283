import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { EventBridgeRoutes } from '../../types';

type Props = {
  routes: EventBridgeRoutes;
}

export const MainNavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_EVENT_BRIDGE_BUSES)} end>
      Event Buses
    </NavLink>
  </>
);
