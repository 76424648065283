import { ReactElement, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { Grid , Alert } from '@mui/material';

import { LoadingFragment } from '@localstack/ui';
import { CloudPodsService, useApiGetter } from '@localstack/services';

import { ProductFeature } from '@localstack/types';

import { CustomerLayout } from '~/layouts';
import { useAuthProvider } from '~/hooks';

import { LoadFromCloudPodCard } from './components/LoadFromCloudPodCard';


export const PodDetails = (): ReactElement => {
  const { name } = useParams<'name'>() as { name: string };

  const { hasFeature } = useAuthProvider();
  const hasAccessToEphemeralInstances = hasFeature(ProductFeature.FEATURE_COMPUTE);

  const { data: pod, isLoading: isPodRetrieving } = useApiGetter(
    CloudPodsService,
    'getCloudPod',
    [name],
    {
      enable: !!name,
    });

  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const queryParamVersion = queryParams.get('version') ?? undefined;

  return (
    <CustomerLayout
      title='Cloud Pod Details'
    >
      <Grid container spacing={3}>
        {!(isPodRetrieving || pod) && (
          <Grid item xs={12}>
            <Alert severity='warning'>
              Could not find Cloud Pod with name {name}
            </Alert>
          </Grid>
        )}
        <LoadingFragment
          loading={isPodRetrieving}
          variant='grid'
          size={1}
          height={319}
          gridItemBreakpoints={{ xs: 12 }}
        >
          <Grid item xs={12}>
            <LoadFromCloudPodCard
              podName={name}
              version={queryParamVersion}
              size='medium'
              enableBrowse={hasAccessToEphemeralInstances}
              mainAction='instance'
              showSkeletonLoad
            />
          </Grid>
        </LoadingFragment>
      </Grid>
    </CustomerLayout>
  );
};
