import { useState, ReactElement } from 'react';
import { Grid, Box, MenuItem, Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LambdaFunctionConfig } from '@localstack/types';
import { useRoutes, useAwsGetter } from '@localstack/services';

import {
  Dropdown,
  LoadingFragment,
  LambdaDetails,
  LambdaCodeDetails,
  LambdaEventSourceMappings,
  LambdaBreadcrumbs,
  LambdaTags,
} from '@localstack/ui';

import { LambdaProps } from './types';
import { FunctionTabs } from './components/FunctionTabs';

export const LambdaFunction = ({
  Layout,
  clientOverrides,
  routes,
}: LambdaProps): ReactElement => {
  const { goto } = useRoutes();

  const [revision, setRevision] = useState<Optional<LambdaFunctionConfig>>(null);
  const { name } = useParams<'name'>();

  const { data: lambdaFunction, isLoading: isFunctionLoading } = useAwsGetter(
    'Lambda', 'getFunction', [{ FunctionName: name }], { clientOverrides },
  );
  const { data: versions, isLoading: isVersionsLoading } = useAwsGetter(
    'Lambda', 'listVersionsByFunction', [{ FunctionName: name }], { clientOverrides },
  );
  const { data: mappings, isLoading: isMappingsLoading } = useAwsGetter(
    'Lambda', 'listEventSourceMappings', [{ FunctionName: name }], { clientOverrides },
  );


  return (
    <Layout
      title={`Lambda Function ${name}`}
      tabs={<FunctionTabs routes={routes} functionName={name} />}
    >
      <>
        <Box
          textAlign="right"
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <LambdaBreadcrumbs
            functionName={lambdaFunction?.Configuration?.FunctionName}
            onViewRoot={() => goto(routes.RESOURCES_LAMBDA_FUNCTIONS)}
          />
          <Dropdown
            label={revision?.Version || 'Version'}
            size="small"
            variant="text"
          >
            {(versions?.Versions ?? []).map((version) => (
              <MenuItem
                key={version.RevisionId}
                onClick={() => setRevision(version)}
              >
                {version.Version}
              </MenuItem>
            ))}
          </Dropdown>
        </Box>
        <Grid container spacing={3}>
          <Grid item md={6}>
            <Card>
              <CardContent>
                <LoadingFragment
                  loading={isFunctionLoading || isVersionsLoading}
                  variant="list"
                  height={50}
                  size={6}
                >
                  <LambdaDetails lambdaFunction={revision || lambdaFunction?.Configuration} />
                </LoadingFragment>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6}>
            <Grid container spacing={3}>
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <LoadingFragment
                      loading={isFunctionLoading || isVersionsLoading}
                      variant="list"
                      height={50}
                      size={5}
                    >
                      <LambdaCodeDetails
                        lambdaFunction={revision || lambdaFunction?.Configuration}
                        lambdaFunctionCodeLocation={lambdaFunction?.Code}
                      />
                    </LoadingFragment>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={12}>
                <Card>
                  <CardContent>
                    <LoadingFragment
                      loading={isFunctionLoading || isVersionsLoading}
                      variant="list"
                      height={50}
                      size={5}
                    >
                      <LambdaTags
                        lambdaFunction={lambdaFunction}
                      />
                    </LoadingFragment>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Card>
              <CardContent>
                <LoadingFragment
                  loading={isMappingsLoading}
                  arrayData={mappings?.EventSourceMappings ?? []}
                  variant="list"
                  height={50}
                  size={6}
                >
                  <LambdaEventSourceMappings mappings={mappings?.EventSourceMappings ?? []} />
                </LoadingFragment>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    </Layout>
  );
};
