import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, CognitoGroupDetails } from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { PoolNavTabs } from './components';

import { CognitoProps } from './types';

export const CognitoPoolGroup = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { poolId, groupName } = useParams<'poolId' | 'groupName'>();

  const { data: pool } = useAwsGetter(
    'CognitoIdentityServiceProvider', 
    'describeUserPool', 
    [{ UserPoolId: poolId }],
    { clientOverrides },
  );

  const { data: group } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'getGroup',
    [{ UserPoolId: poolId, GroupName: groupName }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Group Details"
      tabs={<PoolNavTabs poolId={poolId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Group Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Cognito', () => goto(routes.RESOURCES_COGNITO)],
              [pool?.UserPool?.Name ?? poolId, () => goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId })],
              ['Groups', () => goto(routes.RESOURCES_COGNITO_POOL_GROUPS, { poolId })],
              [groupName, null],
            ]}
          />
        </Box>
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_COGNITO_USER_POOL_UPDATE, { poolId })}>
            Edit Pool
          </Button>
        </>
      }
    >
      <Card>
        <CardContent>
          <CognitoGroupDetails group={group?.Group} />
        </CardContent>
      </Card>
    </Layout>
  );
};
