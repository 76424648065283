import { createPatch } from 'rfc6902';
// import { isEqual, difference } from 'lodash';

interface PatchOperation {
  op: 'add' | 'replace' | 'remove' | 'move' | 'copy' | 'test';
  value?: any; // eslint-disable-line
  path: string;
}

/**
 * Convert RFC6902 patches to ApiGateway-compatible patches.
 * The main idea is to flatten any object or array values.
 */
const flattenPatches = (patch: PatchOperation): PatchOperation[] => {
  if (patch.value instanceof Array) {
    return patch.value.map(
      (value) => flattenPatches({ op: patch.op, value, path: `${patch.path}/0` }),
    ).flat();
  }

  if (typeof patch.value === 'object') {
    if (patch.op === 'add') {
      return Object.entries(patch.value).map(
        ([k, value]) => flattenPatches({ op: 'add', value, path: `${patch.path}/${k}` }),
      ).flat();
    }
    if (patch.op === 'replace') {
      return [
        { op: 'remove' as const, path: patch.path },
        ...Object.entries(patch.value).map(
          ([k, value]) => flattenPatches({ op: 'add', value, path: `${patch.path}/${k}` }),
        ),
      ].flat();
    }
  }

  if (typeof patch.value === 'boolean') {
    return [{ ...patch, value: patch.value.toString() }];
  }

  return [patch];
};

/**
 * Although it looks like ApiGateway uses RFC6902 for updates,
 * it actually has it's very own treatment of the standard and
 * sometimes very weird behaviour.
 * @param before
 * @param after
 * @returnse
 */
export const buildAgwPatches = <A, B>(before: A, after: B): PatchOperation[] => {
  // to avoud unnecessary deletions, we are merging new object into the old one
  const mergedView = { ...before, ...after };
  return createPatch(before, mergedView).map(flattenPatches).flat(); 
};
