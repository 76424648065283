import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { VALIDATION_RULES, UserService, useSnackbar, useApiEffect, useRoutes } from '@localstack/services';

import {
  ControlledTextField,
  ProgressButton,
  NavLink,
  AnimatedLogo,
} from '@localstack/ui';

import {
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  FormHelperText,
} from '@mui/material';

import { BaseLayout } from '~/layouts';
import { AppRoute } from '~/config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(3),
      width: theme.breakpoints.values.sm,
      [theme.breakpoints.down('md')]: {
        width: 'auto',
      },
    },
  }),
);

interface FormData {
  email: string;
}

export const Recover = (): ReactElement => {
  const classes = useStyles();

  const { showSnackbar } = useSnackbar();
  const { goto } = useRoutes();

  const { control, handleSubmit, formState } = useForm<FormData>({ mode: 'all' });

  const { requestPasswordReset, isLoading } = useApiEffect(
    UserService,
    ['requestPasswordReset'],
  );

  const onSubmit = async (data: FormData) => {
    await requestPasswordReset(data);

    showSnackbar({
      message: 'Password recovery email sent - please check your email inbox.',
      severity: 'success',
    });

    goto(AppRoute.SIGN_IN);
  };

  return (
    <BaseLayout documentTitle="Recover Password" hideNavigation>
      <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={3} px={2}>
        <Box textAlign="center">
          <AnimatedLogo animation={false} />
        </Box>
        <Card className={classes.card}>
          <CardHeader title="Recover Password" />
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)} id="recover">
              <ControlledTextField
                autoFocus
                control={control}
                name="email"
                fullWidth
                required
                label="Email address"
                type="text"
                variant="outlined"
                rules={{
                  ...VALIDATION_RULES.email,
                  ...VALIDATION_RULES.required,
                }}
              />
            </form>
          </CardContent>
          <CardActions>
            <FormHelperText>
              Don&apos;t need to recover your password?{' '}
              <NavLink to={AppRoute.SIGN_IN}>
                Sign In instead
              </NavLink>
            </FormHelperText>
            <ProgressButton
              loading={isLoading}
              disabled={!formState.isValid}
              color="primary"
              type="submit"
              size="large"
              variant="contained"
              form="recover"
            >
              Reset Password
            </ProgressButton>
          </CardActions>
        </Card>
      </Box>
    </BaseLayout>
  );
};
