import { ReactElement } from 'react';
import { CloudWatchLogStream } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface CloudWatchLogStreamsTableProps {
  selectable?: boolean;
  loading?: boolean;
  logStreams: CloudWatchLogStream[];
  onViewLogStream?: (user: CloudWatchLogStream) => unknown;
  onSelect?: (names: string[]) => void;
}

export const CloudWatchLogStreamsTable = ({
  logStreams,
  selectable = true,
  loading,
  onViewLogStream,
  onSelect,
}: CloudWatchLogStreamsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('CloudWatchLogs')}
    entry="LogStream"
    idAttribute="logStreamName"
    rows={logStreams}
    selectable={selectable}
    onSelect={onSelect}
    order={['logStreamName']}
    loading={loading}
    externalFields={{
      logStreamName: (row) => (
        <Link onClick={() => onViewLogStream && onViewLogStream(row)} underline="hover">
          {row.logStreamName}
        </Link>
      ),
    }}
  />
);
