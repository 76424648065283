import { getSchema } from '@localstack/services';
import { DMSTableStatistics } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type DMSReplicationTaskTableStatsTableProps = {
  tableStats: DMSTableStatistics[];
  loading?: boolean;
};

export const DMSReplicationTaskTableStatsTable = ({
  tableStats,
  loading,
}: DMSReplicationTaskTableStatsTableProps): ReactElement => (
  <MagicTable
    entry="TableStatistics"
    idAttribute="TableName"
    rows={tableStats}
    schema={getSchema('DMS')}
    loading={loading}
    selectable
    disableSelectionOnClick
    order={['SchemaName', 'TableName', 'TableState', 'ValidationState']}
  />
);
