import moment, { Moment } from 'moment';
import md5 from 'md5';
import { AxiosError } from 'axios';
import { BACKEND_ERROR_MAP } from '@localstack/constants';
import { TaxDetails } from '@localstack/types';

import { ApiError } from '../apis';

const DATE_FORMAT = 'YYYY-MM-DD';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_TIME_FORMAT_TIMEZONE = `${DATE_TIME_FORMAT} (UTC ZZ)`;
const DATE_TIME_FORMAT_MS = 'YYYY-MM-DD HH:mm:ss.SSS';
const DATE_TIME_FORMAT_MS_TIMEZONE = `${DATE_TIME_FORMAT_MS} (UTC ZZ)`;

const TEXT_MAX_LENGTH = 100;

const normalizeTimestamp = (t: unknown): string | number | Date => {
  if (!['number', 'string'].includes(typeof t)) {
    return t as string | number;
  }
  if (typeof t === 'string' && t.includes('-')) {
    return t;
  }
  if (['date', 'object'].includes(typeof t)) {
    return t as Date;
  }
  const tnum = parseInt(`${t}`, 10);
  if (typeof tnum === 'number' && tnum > 9999999999) {
    // timestamp seems to be in milliseconds -> convert to seconds
    return tnum / 1000;
  }
  return tnum;
};

const momentFromTimestamp = (date: string | number | Date): Moment => {
  const dateTS = normalizeTimestamp(date);
  return typeof dateTS === 'number' ? moment.unix(dateTS) : moment(dateTS);
};

export const formatDate = (date: string | number | Date): string => {
  const m = momentFromTimestamp(date);
  return m.format(DATE_FORMAT);
};

export const formatDateTo = (date: string | number | Date, format: string): string => {
  const m = momentFromTimestamp(date);
  return m.format(format);
};

export const parseDate = (date: string | number | Date): Date => moment(date).toDate();

export const formatDateTime = (date: string | number | Date, millis = false): string => {
  const m = momentFromTimestamp(date);
  return m.format(millis ? DATE_TIME_FORMAT_MS : DATE_TIME_FORMAT);
};

export const formatDateTimeTimezone = (date: string | number | Date, millis = false): string => {
  const m = momentFromTimestamp(date);
  return m.format(millis ? DATE_TIME_FORMAT_MS_TIMEZONE : DATE_TIME_FORMAT_TIMEZONE);
};

export const formatMonetaryAmount = (amount: number | string, currency: string): string =>
  Number.parseFloat(amount.toString()).toLocaleString('en-us', { style: 'currency', currency });

export const formatTaxableText = (text: string, tax?: Optional<TaxDetails>, inclusive?: boolean): string => {
  if (tax) {
    return inclusive ? `${text} (incl. VAT)` : `${text} (excl. VAT)`;
  }

  return text;
};

export const formatBytes = (bytes: Optional<number>): Optional<string> => {
  if (!bytes) return;

  const kb = bytes / 2 ** 10;
  const mb = bytes / 2 ** 20;
  const gb = bytes / 2 ** 30;

  if (gb > 1) return `${gb.toFixed(2)} GiB`;
  if (mb > 1) return `${mb.toFixed(2)} MiB`;
  return `${kb.toFixed(2)} KiB`;
};

export const formatServiceName = (service: string): string => {
  const parts = service.split(':');
  const serviceKey = (parts.length >= 3 ? parts[2] : service) as string;
  const names = {
    sns: 'SNS',
    sqs: 'SQS',
    lambda: 'Lambda',
    dynamodb: 'DynamoDB',
  };
  return names[serviceKey as keyof typeof names] || serviceKey;
};

export const getDaysDiff = (date: string | number | Date): number => {
  const m = momentFromTimestamp(date);
  return m.diff(moment(), 'days');
};

export const isPrintableASCII = (string: string): boolean => !!/^[\x20-\x7F]*$/.test(string);

export const trimText = (text: string, maxLength: number): string => {
  const maxLen = maxLength || TEXT_MAX_LENGTH;
  let result = text || '';
  if (result.length > maxLen) {
    result = `${result.substring(0, maxLen)} ...`;
  }
  return result;
};

export const hashEmail = (email: string): string => md5(email.trim().toLowerCase());

export const capitalise = (str: string): string => {
  if (/[a-z]/.test(str[0] || '')) return `${(str[0] || '').toUpperCase()}${str.substring(1)}`;
  return str;
};

export const singlify = (str: string): string => {
  if (/(s|ss|z|ch|sh|x)es$/i.test(str)) return str.substring(0, str.length - 2);
  if (/s$/i.test(str)) return str.substring(0, str.length - 1);
  return str;
};

export const getNumberWithOrdinal = (num: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = num % 100;
  return num + (s[(v - 20) % 10] || s[v] || s[0] || '');
};

export const formatIntervalMonths = (months: number): string => {
  if (months % 12 === 0) {
    const digit = months / 12;
    const ordinalNumber = getNumberWithOrdinal(digit);
    return `${ordinalNumber} year`;
  }
  const ordinalNumber = getNumberWithOrdinal(months);
  return `${ordinalNumber} month`;
};

export const formatErrorStatus = (error: ApiError | AxiosError | Error): number =>
  (error as ApiError)?.status ?? (error as AxiosError)?.response?.status ?? 500;

export const formatErrorCode = (error: ApiError | AxiosError | Error): Optional<string> =>
  (error as ApiError)?.body?.message ||
  (error as AxiosError)?.response?.data?.message ||
  (error as AxiosError)?.response?.data?.error;

export const formatError = (error: ApiError | AxiosError | Error): string => {
  const errorCode = formatErrorCode(error);

  return BACKEND_ERROR_MAP[errorCode as keyof typeof BACKEND_ERROR_MAP]
    ?? error.message
    ?? 'There was an error while processing your request';
};

export const pluralizeOrDash = (
  cnt: number | null | undefined,
  singular: string,
  plural: string,
  short = false,
): string => {
  if (!cnt === undefined || cnt === null || Number.isNaN(cnt)) {
    return `- ${plural}`;
  }

  if (cnt === 1 && short) {
    return singular;
  }

  return (cnt === 1) ? `${cnt} ${singular}` : `${cnt} ${plural}`;
};

export const getIsostringFromDate = (date: Date): string => date.toISOString().split('T')[0] || '';

export const areDatesEqual =
  (date1: Date, date2: Date): boolean => getIsostringFromDate(date1) === getIsostringFromDate(date2);

/**
 * Returns an array of Date objects between two given dates.
 *
 * @param {Date} startDate - The starting date.
 * @param {Date} endDate - The ending date.
 * @returns {Date[]} - An array containing Date objects between the provided dates.
 */

export const getDateRangeBetweenDates = (
  startDate: Date, endDate: Date): Date[] => {
  const datesArray = [];
  while (startDate <= endDate) {
    datesArray.push(new Date(startDate));
    startDate.setDate(startDate.getDate() + 1);
  }
  return datesArray;
};
