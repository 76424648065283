import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { CloudWatchRoutes } from '../../types';

interface Props {
  logGroupName: string;
  routes: CloudWatchRoutes;
}

export const LogGroupNavTabs = ({ logGroupName, routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_CLOUDWATCH_GROUP_STREAMS, { logGroupName })}>
      Streams
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_CLOUDWATCH_GROUP_EVENTS, { logGroupName })}>
      Events
    </NavLink>
  </>
);
