import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const DOCDB_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.DOCDB}`;

export const DEFAULT_DOCDB_ROUTES: Record<string, string> = {
  RESOURCES_DOCDB_CLUSTERS: `${DOCDB_BASE_ROUTE}/clusters`,
  RESOURCES_DOCDB_CLUSTER: `${DOCDB_BASE_ROUTE}/cluster/:id`,
  RESOURCES_DOCDB_CLUSTER_CREATE: `${DOCDB_BASE_ROUTE}/cluster/new`,
  RESOURCES_DOCDB_CLUSTER_UPDATE: `${DOCDB_BASE_ROUTE}/cluster/:id/update`,
  RESOURCES_DOCDB_INSTANCE: `${DOCDB_BASE_ROUTE}/instance/:id`,
  RESOURCES_DOCDB_INSTANCE_CREATE: `${DOCDB_BASE_ROUTE}/instance/new`,
  RESOURCES_DOCDB_INSTANCE_UPDATE: `${DOCDB_BASE_ROUTE}/instance/:id/update`,
};
