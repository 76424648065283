import { ReactElement } from 'react';
import { KMSKeyMetadata, CreateKSMKeyParams, UpdateKMSKeyDescriptionParams } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface KMSKeyFormProps {
  metadata?: Optional<Partial<KMSKeyMetadata>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateKSMKeyParams) => unknown;
  onUpdate: (data: UpdateKMSKeyDescriptionParams) => unknown;
}

export const KMSKeyForm = ({ metadata, loading, formId, onCreate, onUpdate }: KMSKeyFormProps): ReactElement => (
  <MagicForm
    data={metadata}
    schema={getSchema('KMS')}
    loading={loading}
    entry={metadata ? 'UpdateKeyDescriptionRequest' : 'CreateKeyRequest'}
    formId={formId}
    fieldConditions={{
      '^KeyId': !metadata,
    }}
    onSubmit={(data: CreateKSMKeyParams | UpdateKMSKeyDescriptionParams) => {
      if (!metadata) return onCreate(data as CreateKSMKeyParams);
      return onUpdate({ ...data, KeyId: metadata.KeyId as string } as UpdateKMSKeyDescriptionParams);
    }}
  />
);
