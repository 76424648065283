import { useRoutes, useAwsGetter } from '@localstack/services';
import { Breadcrumbs, CloudTrailEventDetails } from '@localstack/ui';
import { Card, Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { TrailsNavTabs } from './components/TrailsNavTabs';
import { CloudTrailProps } from './types';

export const CloudTrailEvent = ({
  Layout,
  clientOverrides,
  routes,
}: CloudTrailProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();

  const { data: event } = useAwsGetter(
    'CloudTrail',
    'lookupEvents',
    [{
      LookupAttributes: [{
        AttributeKey: 'EventId',
        AttributeValue: id as string,
      }],
    }],
    { clientOverrides },
  );

  return (
    <>
      <Layout
        title={
          <Box>
            <Typography variant="h4">Event details</Typography>
            <Breadcrumbs
              mappings={[
                ['Events', () => goto(routes.RESOURCES_SQS_QUEUES)],
                [id, null],
              ]}
            />
          </Box>
        }
        tabs={<TrailsNavTabs routes={routes} />}
      >
        <Card>
          <CloudTrailEventDetails event={event?.Events?.at(0) || {}} />
        </Card>
      </Layout>
    </>
  );
};
