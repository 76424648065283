import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueConnectionDetails as Details } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueConnectionDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { connection } = useParams<'connection'>();

  const { data: ConnectionData } = useAwsGetter(
    'Glue', 'getConnection',
    [{ Name: connection }], { clientOverrides });

  return (
    <Layout
      documentTitle="Glue: Connection Details"
      tabs={<MainNavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Connection Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Connections', () => goto(routes.RESOURCES_GLUE_CONNECTIONS)],
              [connection, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_GLUE_CONNECTION_UPDATE, { connection })}
        >
          Edit Connection
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details connection={ConnectionData?.Connection} />
        </CardContent>
      </Card>
    </Layout>
  );
};
