import { User } from '@localstack/types';
import { ExternalLink } from '@localstack/constants';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  UserService,
  VALIDATION_RULES,
  hashEmail,
  useApiEffect,
} from '@localstack/services';

import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link,
  TextField,
  Typography,
} from '@mui/material';

import {
  ControlledPhoneField,
  ControlledTextField,
  ProgressButton,
} from '@localstack/ui';

import { useAuthProvider } from '~/hooks';

const useStyles = makeStyles((theme: Theme) => createStyles({
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: theme.spacing(0, 'auto'),
    boxShadow: theme.shadows[1],
    marginBottom: theme.spacing(1),
  },
  switch: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
export const Profile = (): ReactElement => {
  const classes = useStyles();
  const { can, userInfo } = useAuthProvider();

  const { user } = userInfo || {};

  const { updateAccount, isLoading: isUserMutating } = useApiEffect(
    UserService,
    ['updateAccount'],
    { revalidate: ['getUser'] },
  );

  const emailHash = hashEmail(user?.email ?? '');
  const userForm = useForm<{ user: User }>({ mode: 'all' });

  const onSubmitUserInfo = useCallback(
    async (data: { user: Partial<User> }) => {
      const userUpdates = Object.keys(userForm.formState.dirtyFields.user || {}).reduce(
        (memo, field) => ({ ...memo, [field]: data.user[field as keyof typeof data.user] }), {},
      );

      await updateAccount(userUpdates as User);

    }, [userForm.formState, can],
  );

  // Set initial form values
  useEffect(() => {
    if (user) {
      userForm.reset({ user });
    }
  }, [user]);

  return (
    <Grid container spacing={2}>
      <Grid item md={8} xs={12}>
        {/* personal info */}
        <form onSubmit={userForm.handleSubmit(onSubmitUserInfo)}>
          <Card>
            <CardHeader title='Personal Info' />
            <CardContent>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    fullWidth
                    variant="outlined"
                    value={user?.email ?? ''}
                    disabled
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ControlledTextField
                    control={userForm.control}
                    name="user.firstname"
                    label="First Name"
                    placeholder="First Name"
                    fullWidth
                    required
                    variant="outlined"
                    rules={VALIDATION_RULES.defaultRequired}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ControlledTextField
                    control={userForm.control}
                    name="user.lastname"
                    label="Last Name"
                    placeholder="Last Name"
                    fullWidth
                    required
                    variant="outlined"
                    rules={VALIDATION_RULES.defaultRequired}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ControlledPhoneField
                    control={userForm.control}
                    label="Phone"
                    name="user.phone"
                    fullWidth
                    variant="outlined"
                    rules={VALIDATION_RULES.phone}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ControlledTextField
                    control={userForm.control}
                    label="Github Username"
                    name="user.github_username"
                    fullWidth
                    variant="outlined"
                    rules={VALIDATION_RULES.default}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ControlledTextField
                    control={userForm.control}
                    label="Company"
                    name="user.company"
                    fullWidth
                    variant="outlined"
                    rules={VALIDATION_RULES.default}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <ControlledTextField
                    control={userForm.control}
                    label="Job Title"
                    name="user.job_title"
                    fullWidth
                    variant="outlined"
                    rules={VALIDATION_RULES.default}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <ProgressButton
                color="primary"
                variant="contained"
                loading={isUserMutating}
                disabled={!userForm.formState.isValid}
                type="submit"
              >
                Save Changes
              </ProgressButton>
            </CardActions>
          </Card>
        </form>
      </Grid>

      {/* gravatar */}
      <Grid item md={4} xs={12} >
        <Card>
          <CardContent>
            <Box p={3}>
              <Avatar
                className={classes.avatar}
                alt={user?.name || ''}
                src={`https://www.gravatar.com/avatar/${emailHash}?d=db`}
              />
              <Typography variant="caption" align="center" component="div">
                Profile photo is powered by{' '}
                <Link href={ExternalLink.EXTERNAL_GRAVATAR} target="__blank" underline="hover">Gravatar</Link>
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Profile;
