import { DEFAULT_APPCONFIG_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { AppConfigApplicationsTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { AppConfigProps } from './types';

export const AppConfigApplications = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPCONFIG_ROUTES,
}: AppConfigProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: applications, isLoading, mutate } = useAwsGetter(
    'AppConfig',
    'listApplications',
    [],
    { clientOverrides },
  );
  const { deleteApplication } = useAwsEffect('AppConfig', ['deleteApplication'], {
    revalidate: ['listApplications'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteApplication({ ApplicationId: id }),
      ),
    );
  };
  return (
    <Layout
      title={<PageTitle title="AppConfig Applications" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_APPCONFIG_APPLICATION_CREATE)}>
            Create Application
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Application(s)?`}
              onClick={handleRemove}
              text="Selected Applications will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AppConfigApplicationsTable
          applications={applications || {}}
          onViewApplication={(id: string) =>
            goto(routes.RESOURCES_APPCONFIG_APPLICATION, { id })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
