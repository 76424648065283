import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';
import { Breadcrumbs, DMSReplicationInstanceDetail } from '@localstack/ui';

import { DMSProps } from './types';

export const DMSReplicationInstance = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DMS_ROUTES,
}: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: replicationInstance } = useAwsGetter(
    'DMS',
    'describeReplicationInstances',
    [{ Filters: [{ Name: 'replication-instance-arn', Values: [id || ''] }] }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">DMS Replication Instance</Typography>
          <Breadcrumbs
            mappings={[
              ['DMS', () => goto(routes.RESOURCES_DMS_REPLICATION_INSTANCES)],
              [id, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <DMSReplicationInstanceDetail replicationInstance={replicationInstance?.ReplicationInstances?.[0]} />
      </Card>
    </Layout>
  );
};
