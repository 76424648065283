import { useState, useEffect, FormEvent, ReactElement } from 'react';
import { Card, CardContent, CardActions, Button, Box, Typography } from '@mui/material';
import { CodeEditor, Breadcrumbs, DynamoDBItemsTable } from '@localstack/ui';
import { useRoutes, useAwsGetter, useAwsKeysMemo } from '@localstack/services';
import { DEFAULT_DYNAMODB_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { DynamoDBProps } from './types';

const PAGE_SIZE = 50;

export const DynamoDBPartiQL = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DYNAMODB_ROUTES,
}: DynamoDBProps): ReactElement => {
  const { goto } = useRoutes();

  const [statement, setStatement] = useState('SELECT * FROM "table"');
  const [statementToExecute, setStatementToExecute] = useState<string | undefined>(undefined);

  const [page, setPage] = useState(0);
  const [counter, setCounter] = useState(0);
  const [paginationKey, setPaginationKey] = useState<any>(null); // eslint-disable-line

  const { data: result, isLoading } = useAwsGetter(
    'DynamoDB',
    'executeStatement',
    [{ Statement: statementToExecute, NextToken: paginationKey }],
    { defaultValue: { Items: [], LastEvaluatedKey: undefined }, clientOverrides },
  );

  const queryKeys = useAwsKeysMemo(page, result?.LastEvaluatedKey);

  const handleExecuteStatement = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setPage(0);
    setCounter(counter+1);
    setStatementToExecute(`${statement}${' '.repeat(counter)}`);
  };

  useEffect(() => {
    setPaginationKey(queryKeys[page]);
  }, [page]);

  return (
    <Layout
      documentTitle="PartiQL"
      tabs={<NavTabs routes={routes} />}
      actions={
        <Button
          type="submit"
          form="statement"
          color="primary"
          variant="outlined"
        >
          Execute
        </Button>
      }
      title={
        <Box>
          <Typography variant="h4">PartiQL</Typography>
          <Breadcrumbs
            mappings={[
              ['DynamoDB', () => goto(routes.RESOURCES_DYNAMODB)],
              ['PartiQL', null],
            ]}
          />
        </Box>
      }
    >
      <Card variant="outlined">
        <CardContent>
          <form id="statement" onSubmit={handleExecuteStatement}>
            <CodeEditor
              language="sql"
              setValue={setStatement}
              value={statement ?? ''}
            />
          </form>
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            form="statement"
            color="primary"
            variant="outlined"
          >
            Execute
          </Button>
        </CardActions>
      </Card>
      <Box mt={3}>
        <Card>
          <DynamoDBItemsTable
            page={page}
            pageSize={PAGE_SIZE}
            table={null}
            rows={result?.Items ?? []}
            loading={isLoading}
            hasMore={!!result?.LastEvaluatedKey}
            onPageChange={setPage}
          />
        </Card>
      </Box>
    </Layout>
  );
};
