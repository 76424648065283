import { ReactElement } from 'react';
import { IAMGroup, CreateIAMGroupRequest, UpdateIAMGroupRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface IAMGroupFormProps {
  group?: Optional<Partial<IAMGroup>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateIAMGroupRequest) => unknown;
  onUpdate: (data: UpdateIAMGroupRequest) => unknown;
}

export const IAMGroupForm = ({ group, loading, formId, onCreate, onUpdate }: IAMGroupFormProps): ReactElement => (
  <MagicForm
    data={group}
    schema={getSchema('IAM')}
    loading={loading}
    entry="CreateGroupRequest"
    formId={formId}
    onSubmit={(data: CreateIAMGroupRequest) => {
      if (!group) return onCreate(data);
      return onUpdate({ GroupName: group.GroupName as string, NewGroupName: data.GroupName, NewPath: data.Path });
    }}
  />
);
