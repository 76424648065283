import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { TestConnectionMessage } from '@localstack/types';
import { Breadcrumbs, DMSEndpointConnectionTestForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { DMSProps } from './types';

export const DMSEndpointConnectionTest = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DMS_ROUTES,
}: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { testConnection, isLoading } = useAwsEffect(
    'DMS',
    ['testConnection'],
    {
      clientOverrides,
      revalidate: ['describeConnections'],
    },
  );

  const handleTest = async (data: TestConnectionMessage) => {
    if (await testConnection(data)) {
      goto(routes.RESOURCES_DMS_ENDPOINT_CONNECTIONS, { id });
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Test Endpoint Connection</Typography>
          <Breadcrumbs
            mappings={[
              ['DMS', () => goto(routes.RESOURCES_DMS_ENDPOINTS)],
              [id, () => goto(routes.RESOURCES_DMS_ENDPOINT, { id })],
              ['Connections', () => goto(routes.RESOURCES_DMS_ENDPOINT_CONNECTIONS, { id })],
              ['Test', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="DMSEndpointConnectionTest"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <DMSEndpointConnectionTestForm
            formId="DMSEndpointConnectionTest"
            onSubmit={handleTest}
            loading={isLoading}
            endpointId={id || ''}
            clientOverrides={clientOverrides}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="DMSEndpointConnectionTest"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
