import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';
import { ReactElement } from 'react';

import { CognitoRoutes } from '../../types';

export const CognitoNavTabs = ({
  routes,
}: {
  routes: CognitoRoutes;
}): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_COGNITO)}>User Pools</NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_COGNITO_IDENTITY_POOLS)}>
        Identity Pools
    </NavLink>
  </>
);
