import { getSchema } from '@localstack/services';
import {
  PutContactInformationRequest,
  GetContactInformationResponse,
} from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type ContactInformationFormProps = {
  info?: Optional<Partial<GetContactInformationResponse>>;
  formId: string;
  onSubmit: (data: PutContactInformationRequest) => void;
  loading?: boolean;
};

export const ContactInformationForm = ({
  formId,
  onSubmit,
  loading,
  info,
}: ContactInformationFormProps): ReactElement => (
  <MagicForm
    entry='GetContactInformationResponse'
    schema={getSchema('Account')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={info}
  />
);
