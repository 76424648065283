export const SERVICES = [
  'ecs',
  'elasticmapreduce',
  'ec2',
  'appstream',
  'dynamodb',
  'rds',
  'sagemaker',
  'custom-resource',
  'comprehend',
  'lambda',
  'cassandra',
  'kafka',
];
