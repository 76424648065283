import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwApiDeploymentRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwApiDeploymentForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiDeploymentCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId: apiId }], { clientOverrides });

  const { createDeployment, isLoading } = useAwsEffect(
    'ApiGatewayV2',
    ['createDeployment'],
    {
      revalidate: ['getStage', 'getStages'],
      clientOverrides,
    },
  );

  const handleCreate = async (data: CreateAgwApiDeploymentRequest) => {
    await createDeployment(data);
    goto(routes.RESOURCES_AGW2_API_STAGES, { apiId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Deployment Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId })],
              ['Deploy', null],
            ]}
          />
        </Box>
      }
      tabs={<ApiNavTabs apiId={apiId as string} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="deploy"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwApiDeploymentForm
            apiId={apiId as string}
            onCreate={handleCreate}
            loading={isLoading}
            formId="deploy"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="deploy"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
