import { ReactElement } from 'react';
import { CognitoGroup } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface CognitoGroupsTableProps {
  selectable?: boolean;
  loading?: boolean;
  groups: CognitoGroup[];
  onViewGroup?: (group: CognitoGroup) => unknown;
  onSelect?: (ids: string[]) => void;
}

export const CognitoGroupsTable = ({
  groups,
  selectable = true,
  loading,
  onViewGroup,
  onSelect,
}: CognitoGroupsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('CognitoIdentityServiceProvider')}
    entry="GroupType"
    idAttribute="GroupName"
    rows={groups}
    selectable={selectable}
    onSelect={onSelect}
    order={['GroupName']}
    loading={loading}
    externalFields={{
      GroupName: (row) => (
        <Link onClick={() => onViewGroup && onViewGroup(row)} underline="hover">
          {row.GroupName}
        </Link>
      ),
    }}
  />
);
