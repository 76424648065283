import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const CE_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.CE}`;

export const DEFAULT_CE_ROUTES: Record<string, string> = {
  RESOURCES_CE_COST_CATEGORY_DEFINITIONS: `${CE_BASE_ROUTE}/costcategorydefinitions`,
  RESOURCES_CE_COST_CATEGORY_DEFINITION: `${CE_BASE_ROUTE}/costcategorydefinition/:id`,
  RESOURCES_CE_COST_CATEGORY_DEFINITION_CREATE: `${CE_BASE_ROUTE}/costcategorydefinition/new`,
  RESOURCES_CE_COST_CATEGORY_DEFINITION_UPDATE: `${CE_BASE_ROUTE}/costcategorydefinition/:id/update`,
};
