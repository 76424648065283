/**
 * This file shows an example how to re-use resource browser for custom endpoints.
 *
 * In this particular example we are building routes for pods,
 * that would also support adjustable resource browser.
 *
 * The main trick is adjusting routes while still kepping them
 */

import { ReactElement } from 'react';
import { useParams, Routes, Route } from 'react-router-dom';
import { Button } from '@mui/material';
import { useRoutes } from '@localstack/services';
import { ResourceBrowserProps } from '@localstack/ui';
import { APIGatewayViewMappings } from '@localstack/resources';

import { AppRoute } from '~/config';
import { CustomerLayout } from '~/layouts';

const allMappings = new Map([...Array.from(APIGatewayViewMappings.entries())]
  .map(([key, value]) => [key, Object.values(value)[0]]));

interface ResourceAdapterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: (props: ResourceBrowserProps<any>) => ReactElement;
  routes: Record<string, string>;
  endpoint: string;
}

/**
 * This adapter helps to wrap resource browser in a layout we need, and pass
 * down the override for an endpoint (which will be coming from the path parameters).
 *
 * Note that we can use some special layout for pods, with sidebars, menus etc.
 *      Potentially we could even use <Outlet /> to render stuff in existing pod views.
 */
const ResourceAdapter = ({ Component, routes, endpoint }: ResourceAdapterProps): ReactElement =>
  <Component Layout={CustomerLayout} routes={routes} clientOverrides={{ endpoint }} />;

/**
 * This component represents some random Pod view that should open
 * a Pod Resource Browser for a particular endpoint.
 */
const TestPodView = () => {
  const { goto } = useRoutes();

  return (
    <CustomerLayout>
      <Button
        onClick={() => goto(
          // think about some nice way to rewrite/adjust default routes
          '/pods-test/:id/:endpoint/resources/gateway/v1',
          { id: '123', endpoint: 'http://localhost:4566' },
        )}
      >
        Go to AGW
      </Button>
    </CustomerLayout>
  );
};

interface PodResourceBrowserRoutesProps {
  base: string;
}

/**
 * This component takes care of resource browser rendering for a particular pod/endpoint.
 * This is a main part where we are overriding default routes and injecting endpoints.
 *
 * Note: the overrides are necessary to keep internal Resource Browser navigation working.
 */
const PodResourceBrowserRoutes = ({ base }: PodResourceBrowserRoutesProps): ReactElement => {
  const { id, endpoint } = useParams<'endpoint' | 'id'>() as { id: string, endpoint: string };

  const adjustedRoutes = Object.fromEntries(
    Object.entries(AppRoute).map(
      ([k, v]) => [k, v.replace(/^\/resources/, `${base}/${id}/${encodeURIComponent(endpoint)}/resources`)],
    ),
  );

  return (
    <Routes>
      {Array.from(allMappings.keys()).map((key) => (
        <Route
          key={key}
          path={key?.replace(/^\/resources/, '')}
          element={
            <ResourceAdapter
              Component={allMappings.get(key) as ResourceAdapterProps['Component']}
              routes={adjustedRoutes}
              endpoint={endpoint}
            />
          }
        />
      ))}
    </Routes>
  );
};

export interface PodResourceBrowserRoutesTestProps {
  base: string;
}

/**
 * This component encapsulates all routes related to Pods (for now only test views).
 */
export const PodResourceBrowserRoutesTest = ({ base }: PodResourceBrowserRoutesTestProps): ReactElement => (
  <Routes>
    <Route path="/" element={<TestPodView />} />
    <Route path="/:id/:endpoint/resources/*" element={<PodResourceBrowserRoutes base={base} />} />
  </Routes>
);
