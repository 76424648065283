import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAppSyncTypeRequest, UpdateAppSyncTypeRequest } from '@localstack/types';
import { ProgressButton, AppSyncTypeForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

const DEFAULT_FORMAT = 'SDL';

export const AppSyncApiTypeUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId, name } = useParams<'apiId' | 'name'>();

  const { createType, updateType, isLoading } = useAwsEffect(
    'AppSync',
    ['createType', 'updateType'],
    { revalidate: ['listTypes', 'getType'], clientOverrides },
  );

  const { data: appSyncType } =
    useAwsGetter('AppSync', 'getType', [{ apiId, typeName: name, format: DEFAULT_FORMAT }], { clientOverrides });

  const handleCreate = async (data: CreateAppSyncTypeRequest) => {
    await createType(data);
    goto(routes.RESOURCES_APPSYNC_API_TYPES, { apiId });
  };

  const handleUpdate = async (data: UpdateAppSyncTypeRequest) => {
    await updateType(data);
    goto(routes.RESOURCES_APPSYNC_API_TYPES, { apiId });
  };

  return (
    <Layout
      documentTitle="Type Details"
      title={
        <Box>
          <Typography variant="h4">Type Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              [name, null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AppSyncTypeForm
            apiId={apiId as string}
            appSyncType={appSyncType?.type}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
