import { debounce } from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Add as AddIcon } from '@mui/icons-material';
import { AdminService, formatDate, useApiEffect, useApiGetter, useGlobalSwr, useRoutes } from '@localstack/services';

import {
  COMPLETED_AUDIT_TRAIL_STATES,
  FAILED_AUDIT_TRAIL_STATES,
  LOADING_AUDIT_TRAIL_STATES,
} from '@localstack/constants';

import {
  AdminSearchResult,
  AuditTrailQueryResult,
  AuditTrailQueryStatus,
  MembershipRole,
  MembershipState as MembershipStateEnum,
  User,
  UserHealthFixReport,
  UserHealthReportItem,
} from '@localstack/types';

import {
  AuditTrailTable,
  ConfirmableButton,
  DangerZone,
  DangerZoneActions,
  LoadingFragment,
  MembershipState,
  ProgressButton,
  UserState,
  PageTitle,
} from '@localstack/ui';

import {
  Alert,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';

import { AppRoute } from '~/config';
import { storeImpersonationMarker } from '~/util/storage';
import { AdminLayout } from '~/layouts';
import { useAuthProvider } from '~/hooks';

import { MemberForm } from './components/MemberForm';
import { AccountForm } from './components/AccountForm';
import { AccountHealth } from './components/AccountHealth';
import { TosAcceptanceLog } from './components/TosAcceptanceLog';

const SEARCH_DEBOUNCE_RATE = 1000;
const AUDIT_TRAIL_DEFAULT_START_OFFSET = 14 * 24 * 60 * 60; // 2 weeks (sec)

enum AccountTab {
  BASIC_DETAILS,
  MEMBERSHIPS,
  TERMS_AND_CONDITIONS,
  ACCOUNT_HEALTH,
  AUDIT_TRAIL,
  DANGER_ZONE,
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  memberForm: {
    marginBottom: theme.spacing(3),
  },
  drawer: {
    width: theme.breakpoints.values['sm' as const],
    padding: theme.spacing(4),
  },
}));

export const Account = (): ReactElement => {
  const { id: userId } = useParams<'id'>() as { id: string };
  const { goto } = useRoutes();

  const classes = useStyles();
  const { reloadUserInfo } = useAuthProvider();

  const { clearAll: clearAllCache } = useGlobalSwr();

  const [currentTab, setCurrentTab] = useState<AccountTab>(AccountTab.BASIC_DETAILS);

  const [userIssues, setUserIssues] = useState<UserHealthReportItem[] | null>(null);
  const [userIssuesFixReport, setUserIssuesFixReport] = useState<UserHealthFixReport | null>(null);

  const [showMembershipForm, setShowMembershipForm] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [orgsQuery, setOrgsQuery] = useState<Optional<string>>(null);

  const [startTimestamp, setStartTimestamp] = useState(
    Math.floor(new Date().getTime() / 1000) - AUDIT_TRAIL_DEFAULT_START_OFFSET,
  );
  const [endTimestamp, setEndTimestamp] = useState(Math.floor(new Date().getTime() / 1000));

  const [auditTrailResult, setAuditTrailResult] = useState<AuditTrailQueryResult>(
    { events: [], status: AuditTrailQueryStatus.COMPLETE },
  );

  const { data: organizations } = useApiGetter(AdminService, 'listUserOrganizations', [userId]);
  const { data: user, isLoading: isAdminUserLoading } = useApiGetter(AdminService, 'getUser', [userId]);

  const { getUserHealth, isLoading: isUserhealthLoading } = useApiEffect(AdminService, ['getUserHealth']);
  const { fixUserIssues, isLoading: isUserIssuesFixing } = useApiEffect(
    AdminService, ['fixUserIssues'], { revalidate: ['getUserHealth'] },
  );

  const { updateUser, removeUser, activateUser, replaceUserEmail, isLoading: isAdminActionLoading } = useApiEffect(
    AdminService,
    ['updateUser', 'removeUser', 'activateUser', 'replaceUserEmail'],
    { revalidate: ['getUser', 'getUsers'] },
  );

  const { createOrganizationMember, removeOrganizationMember, isLoading: isMembershipLoading } = useApiEffect(
    AdminService,
    ['createOrganizationMember', 'removeOrganizationMember'],
    { revalidate: ['getUser', 'listUserOrganizations'] },
  );

  const { resetUserPassword, assignUserPassword, resendUserActivation, isLoading: isPasswordLoading } = useApiEffect(
    AdminService,
    ['resetUserPassword', 'resendUserActivation', 'assignUserPassword'],
  );

  const { data: searchResults, isLoading: isSearching } = useApiGetter(
    AdminService,
    'getSearchResults',
    [orgsQuery],
    {
      enable: !!orgsQuery,
      defaultValue: {} as AdminSearchResult,
    },
  );

  const loadAuditTrail = useCallback(async () => {
    let result: AuditTrailQueryResult = {
      events: [],
      status: AuditTrailQueryStatus.SCHEDULED,
    };

    setAuditTrailResult(result);

    while (!COMPLETED_AUDIT_TRAIL_STATES.includes(result.status)) {
      try {
        // eslint-disable-next-line
        result = await AdminService.getUserAuditTrail(
          userId,
          startTimestamp,
          result.query_id,
          endTimestamp,
        );
      } catch (e) {
        result = {
          status: AuditTrailQueryStatus.FAILED,
          events: [],
        };
      }

      setAuditTrailResult(result);

      // eslint-disable-next-line
      await new Promise(t => setTimeout(t, 3000));
    }
  }, [userId, startTimestamp, endTimestamp]);

  useEffect(() => {
    loadAuditTrail();
  }, [userId]);

  const handleImpersonateUser = useCallback(async (assumedUser: User) => {
    await clearAllCache(); // drop the swr cache as it may contain admin-user entries

    storeImpersonationMarker(assumedUser.id);

    await reloadUserInfo();

    goto(AppRoute.DASHBOARD);
  }, []);

  const handleLoadUserIssues = useCallback(async () => {
    setUserIssues(await getUserHealth(userId));
  }, [userId]);

  const handleLoadUserFixReport = useCallback(async (issues: UserHealthReportItem[]) => {
    setUserIssuesFixReport(await fixUserIssues(userId, { issues }));
    setUserIssues(await getUserHealth(userId));
  }, [userId]);

  const mainOrgId = user?.organizations?.[0];

  const isLoading = isAdminUserLoading
    || isMembershipLoading
    || isAdminActionLoading
    || isPasswordLoading
    || isSearching;

  return (
    <AdminLayout
      documentTitle="Account Details"
      title={
        <PageTitle
          title={`Account ${user?.name ?? userId}`}
          breadcrumbs={[
            ['Accounts', () => goto(AppRoute.ADMIN_ACCOUNTS)],
            [user?.name ?? userId, () => null],
          ]}
        />
      }
    >
      <Tabs
        value={currentTab}
        onChange={(_, tab: AccountTab) => setCurrentTab(tab)}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab value={AccountTab.BASIC_DETAILS} label="Basic Details" />
        <Tab value={AccountTab.MEMBERSHIPS} label="Memberships" />
        <Tab value={AccountTab.TERMS_AND_CONDITIONS} label="Terms and Conditions" />
        <Tab value={AccountTab.ACCOUNT_HEALTH} label="Account Health" />
        <Tab value={AccountTab.AUDIT_TRAIL} label="Audit Trail" />
        <Tab value={AccountTab.DANGER_ZONE} label="Danger Zone" />
      </Tabs>
      {currentTab === AccountTab.BASIC_DETAILS && (
        <Box mt={2}>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Typography>
              <strong>State:&nbsp;</strong>
              {user && <UserState user={user} />}
            </Typography>
            <Typography>
              <strong>Activated:&nbsp;</strong>
              {user?.activated ? formatDate(user.activated) : 'N/A'}
            </Typography>
            <Typography>
              <strong>Source:&nbsp;</strong>
              {user?.how_found || 'N/A'}
            </Typography>
            <Typography>
              <strong># Organizations:&nbsp;</strong>
              {user?.organizations?.length || 0}
            </Typography>
          </Box>

          <Box mt={4}>
            <AccountForm
              user={user}
              loading={isLoading}
              onUpdate={updateUser}
            />
          </Box>
        </Box>
      )}
      {currentTab === AccountTab.MEMBERSHIPS && (
        <Box mt={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="caption" align="right" component="div">Memberships</Typography>
            <Box display="flex" alignItems="center">
              <IconButton color="primary" onClick={() => setShowMembershipForm(!showMembershipForm)}>
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
          {showMembershipForm && (
            <MemberForm
              className={classes.memberForm}
              loading={isLoading}
              searching={isSearching}
              searchedOrganizations={searchResults?.organizations ?? []}
              onSearchOrganization={debounce(setOrgsQuery, SEARCH_DEBOUNCE_RATE)}
              onCreateMember={async (orgId: string, roles: MembershipRole[]) => {
                await createOrganizationMember(orgId,
                  {
                    id: user?.id as string,
                    name: user?.name as string,
                    email: user?.email as string,
                    roles,
                  });
                setShowMembershipForm(false);
              }}
            />
          )}
          {(organizations ?? []).map((org) => (
            <List key={org.id}>
              <Typography variant="subtitle2" paragraph>
                <Link onClick={() => goto(AppRoute.ADMIN_ORGANIZATION, { id: org.id })}>
                  {org.name}
                </Link>
              </Typography>
              {org.members.filter(({ email }) => email === user?.email).map((membership) => (
                <ListItem
                  key={membership.id} button selected={org.id === mainOrgId}
                  onClick={() => updateUser(
                    membership.id,
                    {
                      organizations: Array.from(
                        (new Set([org.id, ...(user?.organizations || [])])),
                      ),
                    } as User,
                  )}
                >
                  <ListItemAvatar>
                    <Box mr={2}>
                      <Typography component="div">
                        <MembershipState state={membership.state} />
                      </Typography>
                    </Box>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        {org.name}{' '}
                        {org.id === mainOrgId && '(current)'}
                      </>
                    }
                    secondary={`${membership.email} (${membership.roles.join(', ')})`}
                  />
                  <ListItemSecondaryAction>
                    {membership.state === MembershipStateEnum.ACTIVE && (
                      <Button
                        size="small"
                        color="secondary"
                        onClick={() => removeOrganizationMember(org.id, membership.id)}
                        disabled={isLoading}
                      >
                        Remove
                      </Button>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          ))}
        </Box>
      )}
      {currentTab === AccountTab.TERMS_AND_CONDITIONS && (
        <Box mt={2}>
          {user && <TosAcceptanceLog user={user} />}
        </Box>
      )}
      {currentTab === AccountTab.ACCOUNT_HEALTH && (
        <Box mt={2}>
          <AccountHealth
            issues={userIssues}
            fixReport={userIssuesFixReport}
            issuesLoading={isUserhealthLoading}
            reportLoading={isUserIssuesFixing}
            onRunAccountCheck={handleLoadUserIssues}
            onRunAccountFix={handleLoadUserFixReport}
          />
        </Box>
      )}
      {currentTab === AccountTab.AUDIT_TRAIL && (
        <Box mt={2}>
          <Box display="flex" mb={2}>
            <Box mr={1} flexGrow={1}>
              <TextField
                required
                variant="outlined"
                label="Start Date"
                size="small"
                type="date"
                fullWidth
                disabled={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
                onChange={
                  ({ target }) => setStartTimestamp(
                    target.value ? moment(target.value).unix() : Date.now(),
                  )
                }
                value={
                  startTimestamp ? moment.unix(startTimestamp).format(moment.HTML5_FMT.DATE) : ''
                }
              />
            </Box>
            <Box mr={1} flexGrow={1}>
              <TextField
                required
                variant="outlined"
                label="End Date"
                size="small"
                type="date"
                fullWidth
                disabled={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
                onChange={
                  ({ target }) => setEndTimestamp(
                    target.value ? moment(target.value).unix() : Date.now(),
                  )
                }
                value={
                  endTimestamp ? moment.unix(endTimestamp).format(moment.HTML5_FMT.DATE) : ''
                }
              />
            </Box>
            <ProgressButton
              variant="contained"
              color="primary"
              onClick={loadAuditTrail}
              disabled={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
              loading={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
            >
              Load Audit Trail
            </ProgressButton>
          </Box>
          {FAILED_AUDIT_TRAIL_STATES.includes(auditTrailResult.status) && (
            <Box mb={2}>
              <Alert severity="error">
                There was an error querying audit trail
              </Alert>
            </Box>
          )}
          <LoadingFragment
            loading={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
            arrayData={auditTrailResult.events ?? []}
            size={10}
            height={40}
          >
            <AuditTrailTable events={auditTrailResult.events ?? []} revealLocalStackStaff />
          </LoadingFragment>
        </Box>
      )}
      {currentTab === AccountTab.DANGER_ZONE && (
        <Box mt={2}>
          {user && (
            <DangerZone>
              <DangerZoneActions>
                {!user.activated && (
                  <>
                    <ConfirmableButton
                      componentType="DangerZoneAction"
                      primaryText="Resend Activation"
                      secondaryText="Resend activation link to the user"
                      actionText="Resend"
                      onClick={() => user && resendUserActivation(user.id)}
                      okText="Resend"
                      title="Resend Activation Link"
                      text="Are you sure you want to resend the activation link to this account?"
                    />
                    <ConfirmableButton
                      componentType="DangerZoneAction"
                      primaryText="Activate the Account"
                      secondaryText="Mark this account as activated"
                      actionText="Activate"
                      onClick={() => user && activateUser(user.id)}
                      okText="Activate"
                      title="Activate the Account"
                      text="Are you sure you want to activate this account?"
                    />
                  </>
                )}
                <ConfirmableButton
                  componentType="DangerZoneAction"
                  primaryText="Reset Password"
                  secondaryText="Send temporary password straight to the user"
                  actionText="Reset"
                  onClick={() => user && resetUserPassword(user.id)}
                  okText="Reset"
                  title="Reset Account Password"
                  text="Are you sure you want to reset the password for this account?"
                />
                <ConfirmableButton
                  componentType="DangerZoneAction"
                  primaryText="Regenerate Password"
                  secondaryText="Generate a new password and show it here"
                  actionText="Regenerate"
                  onClick={
                    async () => {
                      const result = await assignUserPassword(user.id);
                      alert(result.password); // eslint-disable-line
                    }
                  }
                  okText="Regenerate Password"
                  title="Regenerate password for the user"
                  // eslint-disable-next-line
                  text="Are you sure you want to generate a new password, it will no longer be possible to log in using an old one?"
                />
                <ConfirmableButton
                  componentType="DangerZoneAction"
                  primaryText="Change User Email"
                  secondaryText="Change user email to another verified email"
                  actionText="Change"
                  okText="Confirm"
                  onClick={() => replaceUserEmail(user.id, { email: newEmail })}
                  title="Change User Email?"
                  text={
                    <>
                      <Typography paragraph>
                        Please note we are not doing any automated emails checks.
                        Make sure user really owns the email address before pressing the button.
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        label="New Email"
                        onChange={(e) => setNewEmail(e.target.value)}
                        value={newEmail ?? ''}
                      />
                    </>
                  }
                />
                <ConfirmableButton
                  componentType="DangerZoneAction"
                  primaryText="Impersonate User"
                  secondaryText="See the Platform how the user sees it"
                  actionText="Impersonate"
                  onClick={() => handleImpersonateUser(user)}
                  title="Impersonate User?"
                  text={
                    <>
                      <Typography paragraph>
                        You will impersonate the user and be able
                        to see the Platform the same way the user sees it.
                      </Typography>
                      <Typography paragraph color="error">
                        Remember that any changes made in this mode will take
                        a real effect.
                      </Typography>
                    </>
                  }
                />
                <ConfirmableButton
                  componentType="DangerZoneAction"
                  primaryText="Remove Account"
                  secondaryText="Mark User Account as Deleted"
                  actionText="Remove"
                  onClick={
                    async () => {
                      await removeUser(userId);
                      goto(AppRoute.ADMIN_ACCOUNTS);
                    }
                  }
                  title="Remove this Account?"
                  text={
                    <>
                      The user will loose access to account.
                      However, any data associated with this account will
                      remain unchanged, active keys and subscriptions will be retained.
                    </>
                  }
                />
              </DangerZoneActions>
            </DangerZone>
          )}
        </Box>
      )}
    </AdminLayout>
  );
};
