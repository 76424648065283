import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { IAMRole } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export interface IAMRolesTableProps {
  roles: IAMRole[];
  loading?: boolean;
  selectable?: boolean;
  onViewRole?: (group: IAMRole) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const IAMRolesTable = ({
  roles,
  loading,
  selectable,
  onViewRole,
  onSelect,
}: IAMRolesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('IAM')}
    entry="Role"
    idAttribute="RoleName"
    rows={roles}
    selectable={selectable}
    onSelect={onSelect}
    order={['RoleId', 'RoleName']}
    loading={loading}
    externalFields={{
      RoleId: (row) => (
        <Link onClick={() => onViewRole && onViewRole(row)} underline="hover">
          {row.RoleId}
        </Link>
      ),
    }}
  />
);
