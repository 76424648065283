import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateLambdaLayerRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, LambdaLayerCreateForm } from '@localstack/ui';

import { LambdaProps } from './types';

export const LambdaLayerCreate = ({
  Layout,
  clientOverrides,
  routes,
}: LambdaProps): ReactElement => {
  const { goto } = useRoutes();

  const { publishLayerVersion, isLoading } = useAwsEffect(
    'Lambda',
    ['publishLayerVersion'],
    { revalidate: ['listLayers'], clientOverrides },
  );

  const handleCreate = async (data: CreateLambdaLayerRequest) => {
    await publishLayerVersion(data);
    goto(routes.RESOURCES_LAMBDA_LAYERS, { name: data.LayerName });
  };

  return (
    <Layout
      documentTitle="Lambda Details"
      title={
        <Box>
          <Typography variant="h4">Lambda Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Lambda', () => goto(routes.RESOURCES_LAMBDA_LAYERS)],
              ['Create Lambda layer', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateLambdaLayer"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <LambdaLayerCreateForm
            onSubmit={handleCreate}
            clientOverrides={clientOverrides}
            formId="CreateLambdaLayer"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateLambdaLayer"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
