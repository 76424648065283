import { ReactElement } from 'react';

import { ExternalLink } from '@localstack/constants';
import { Typography } from '@mui/material';

import { ContentModal } from '../ContentModal';

type Props = {
  open: boolean,
  onClose: () => void;
  urlOpenerFuncOverride?: (url: string) => void
};

export const EnterpriseFeatureUnavailableDialog = ({ open, onClose, urlOpenerFuncOverride }: Props): ReactElement => {
  const externalUrl = ExternalLink.DOCS_AUTH_TOKEN;
  return (
    <ContentModal
      title='Enterprise features unavailable'
      open={open}
      onClose={onClose}
      onConfirm={() => {
        if (urlOpenerFuncOverride) {
          urlOpenerFuncOverride(externalUrl);
        }
        else {
          window.open(externalUrl, '_blank');
        }
      }}
      confirmText='Learn more'
      okText='Close'
      titleActions
      maxWidth="sm"
    >
      <Typography variant='body1'>
        This feature requires enterprise subscription.
        Make sure that you are using an account with enterprise subscription
        and that you have set up your license and auth token correctly.
      </Typography>
    </ContentModal>
  );
};

