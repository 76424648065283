import { ReactElement, useState } from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { BackdoorSESMessage } from '@localstack/types';
import { Typography, Button, Grid, Box, Card } from '@mui/material';
import { useRoutes, useEndpoint } from '@localstack/services';
import { SESMessagesTable, SESMessageDetails, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_SES_ROUTES } from '@localstack/constants';

import { SESProps } from './types';
import { IndexNavTabs } from './components';

const POLLING_INTERVAL = 5000;
const FIELDS_TO_EXTRACT = {
  to: 'To:',
  subject: 'Subject:',
};

const extractToAndSubjectFields = (rawEmail: string) => {
  const lines = rawEmail.split('\n');
  const { to, subject } = FIELDS_TO_EXTRACT;
  let toField = '';
  let subjectField = '';
  for (let i = 0; i < lines.length; i++) {
    const line = lines[i];
    if (line?.startsWith(to)) {
      toField = line.substring(to.length).trim();
    } else if (line?.startsWith(subject)) {
      subjectField = line.substring(subject.length).trim();
    }

    // Break if both fields are found
    if (toField && subjectField) break;
  }

  return { To: toField, Subject: subjectField };
};


export const SESV1Messages = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SES_ROUTES,
}: SESProps): ReactElement => {
  const { id } = useParams();
  const { goto } = useRoutes();

  const [selected, setSelected] = useState<Optional<BackdoorSESMessage>>(null);

  const { endpoint } = useEndpoint();
  const usedEndpoint = clientOverrides?.endpoint || endpoint;
  const backdoorEndpoint = `${usedEndpoint}/_aws/ses?email=${id}`;

  const { data: messages } = useSWR(
    backdoorEndpoint,
    async (ep) => (await (await fetch(ep)).json()).messages,
    { refreshInterval: POLLING_INTERVAL },
  );

  const rows = (messages?.map((message: BackdoorSESMessage) => {
    if (message?.RawData) {
      const extractedData = extractToAndSubjectFields(message.RawData);
      return {
        ...message,
        Destination: {
          ToAddresses: [extractedData.To],
        },
        Subject: extractedData.Subject,
      };
    }
    return message;
  }) as Optional<BackdoorSESMessage[]>) ?? [];

  return (
    <Layout
      documentTitle="SES Messages"
      title={
        <Box>
          <Typography variant="h4">SES Messages for {id}</Typography>
          <Breadcrumbs
            mappings={[
              ['SES', () => goto(routes.RESOURCES_SES1)],
              ['Identities', () => goto(routes.RESOURCES_SES1_IDENTITIES)],
              [id, () => goto(routes.RESOURCES_SES1_IDENTITY_MESSAGES, { id })],
              ['Messages', null],
            ]}
          />
        </Box>
      }
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_SES1_IDENTITY_MESSAGE_CREATE, { id })}>
            Create Message
          </Button>
        </>
      }
    >
      <Grid container spacing={3}>
        <Grid item md={6} sm={12}>
          <Card>
            <SESMessagesTable
              rows={rows}
              onView={(json) => setSelected(JSON.parse(json) as BackdoorSESMessage)}
            />
          </Card>
        </Grid>
        <Grid item md={6} sm={12}>
          <Card>
            <SESMessageDetails message={selected} />
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};
