import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, KinesisStreamDetails } from '@localstack/ui';

import { DEFAULT_KINESIS_ROUTES } from '@localstack/constants';

import { KinesisProps } from './types';

export const KinesisStream = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_KINESIS_ROUTES,
}: KinesisProps): ReactElement => {
  const { goto } = useRoutes();
  const { streamName } = useParams<'streamName'>();

  const { data: stream } = useAwsGetter('Kinesis', 'describeStream', [{ StreamName: streamName }],{ clientOverrides });

  return (
    <Layout
      documentTitle="Stream Details"
      title={
        <Box>
          <Typography variant="h4">Stream Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Kinesis', () => goto(routes.RESOURCES_KINESIS)],
              ['Streams', () => goto(routes.RESOURCES_KINESIS_STREAMS)],
              [streamName, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_KINESIS_STREAM_UPDATE, { streamName })}>
          Edit Stream
        </Button>
      }
    >
      <Card>
        <CardContent>
          <KinesisStreamDetails stream={stream?.StreamDescription} />
        </CardContent>
      </Card>
    </Layout>
  );
};
