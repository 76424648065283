import { ApiKey, LicenseAssignment, OrganizationMember, Subscription } from '@localstack/types';

export const computeNumLicensesUsed = (
  subscription: Subscription,
  licenseAssignments: Array<LicenseAssignment>,
  orgKeys: ApiKey[],
  members: Array<OrganizationMember>,
): number | undefined => {
  // If a user has both an api key and a license key assigned we only count that as 1
  // Example:
  // api keys:
  //  |id  | email             |   |
  //  |----|-------------------|---|
  //  |123 | alice@example.com |   |
  //  |456 | undefined         |   |

  // licenses:
  //  |id  | user_id    |   |
  //  |----|------------|---|
  //  |abc | 789 (Alice)|   |
  //  |edf | 888 (Bob)  |   |
  //
  // this will result in 3 used licences

  if (!licenseAssignments) {
    return undefined;
  }

  const numLicensesAssigned = licenseAssignments?.filter(
    (license) => license.subscription_ids?.[0] === subscription.id,
  ).length;

  const subKeys = orgKeys?.filter((key) => key.subscription_id === subscription.id);
  const activeNonCIKeys = subKeys?.filter((k) => !k.is_ci && !k.deleted && !k.rotated);

  let cntDuplicates = 0;
  for (let i = 0; i < activeNonCIKeys.length; i++) {
    const key = activeNonCIKeys[i];

    for (let j = 0; j < licenseAssignments.length; j++) {
      const licenseAssignment = licenseAssignments[j];
      const licensedMember = members.find((member) => member.id === licenseAssignment?.user_id);

      if (licenseAssignment?.subscription_ids?.[0] === subscription.id && licensedMember?.email === key?.member_email) {
        cntDuplicates += 1;
      }
    }
  }

  return numLicensesAssigned + activeNonCIKeys.length - cntDuplicates;
};

export const numNonCIKeysUsed = (subscription: Subscription, orgKeys: ApiKey[]): number => {
  if (!subscription) {
    return 0;
  }

  const subscriptionKeys = orgKeys.filter((k) => k.subscription_id === subscription.id);
  const activeNonCIKeys = subscriptionKeys?.filter((k) => !k.is_ci && !k.deleted && !k.rotated);

  return activeNonCIKeys.length;
};
