import { ReactElement } from 'react';
import { v4 as uuid } from 'uuid';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Card,
  CardHeader,
  CardContent,
  Link,
  TableHead,
  Box,
  Alert } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
  formatDate,
  formatMonetaryAmount,
  useApiGetter,
  SubscriptionService,
} from '@localstack/services';
import { InvoiceStatus } from '@localstack/ui';
import { Invoice, InvoiceStatus as InvoiceStatusType } from '@localstack/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '& > *': {
        textWrap: 'nowrap',
      },
    },

    openInvoice: {
      background: theme.palette.error.main,
      '& *': {
        color: theme.palette.common.white,
      },
      '& button': {
        border: `1px solid ${theme.palette.common.white} !important`,
      },
    },
  }),
);

export const Invoices = (): ReactElement => {
  const classes = useStyles();
  const { data: invoices } = useApiGetter(SubscriptionService, 'listInvoices', []);

  return (
    <Card>
      <CardHeader title="Invoice History" />
      <CardContent>
        <Box style={{ overflowX: 'auto' }}>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Invoice No.</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Pay/Download</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((invoice: Invoice) => (
                <TableRow
                  key={uuid()}
                  className={invoice?.status === InvoiceStatusType.OPEN ? classes.openInvoice : ''}
                >
                  <TableCell>
                    {formatDate(invoice.status_transitions?.paid_at || invoice.created)}
                  </TableCell>
                  <TableCell>
                    {invoice.number}
                  </TableCell>
                  <TableCell>
                    {formatMonetaryAmount(invoice.total, invoice.currency)}
                  </TableCell>
                  <TableCell>
                    <InvoiceStatus status={invoice.status} />
                  </TableCell>
                  <TableCell>
                    {invoice.status === InvoiceStatusType.OPEN && invoice.hosted_invoice_url ? (
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        href={invoice.hosted_invoice_url}
                        target="_blank"
                      >
                        Pay
                      </Button>
                    ) : (
                      <Button
                        variant="text"
                        color="primary"
                        size="small"
                        onClick={() =>
                          window.location.replace(invoice.invoice_pdf || '')
                        }
                      >
                        PDF
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box mt={2}>
          <Alert severity="info" variant="outlined">
            To get historical invoices prior to October 2022,
            please contact us <Link href='mailto:info@localstack.cloud' underline="hover">via email</Link>.
          </Alert>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Invoices;
