import { ReactElement } from 'react';
import { Box, Alert } from '@mui/material';
import { formatError } from '@localstack/services';
import { AxiosError } from 'axios';

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: Optional<AxiosError<any, any> | Error>
}

export const ApiError = ({ error }: Props): ReactElement => {
  if (!error) return <></>;
  return (
    <Box mb={2}>
      <Alert severity="error" variant="outlined">
        {formatError(error)}
      </Alert>
    </Box>
  );
};
