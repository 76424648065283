import { Palette } from '@mui/material';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiToggleButton'] => ({
  styleOverrides: {
    root: {
      borderRadius: '8px',
      '&.MuiToggleButton-standard': {
        color: palette.text.secondary,
        '&:hover': {
          color: palette.text.primary,
        },
        '&.Mui-selected': {
          color: palette.text.primary,
          fontWeight: 900,
        },
      },
      '&.MuiToggleButton-primary': {
        border: `solid 1px ${palette.primary.main}`,
        color: `${palette.primary.main}`,
        '&.Mui-selected': {
          backgroundColor: `${palette.primary.main}`,
          color: 'white',
          fontWeight: 900,
        },
      },
    },    
  },
  variants: [
    {
      props: { size: 'xsmall' },
      style: {
        padding: '3.5px',
        fontSize: '0.7rem',
      },
    },
  ],
});
