import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { RunEC2InstancesRequest } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, EC2InstanceForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_EC2_ROUTES } from '@localstack/constants';

import { EC2Props } from './types';

export const EC2InstanceUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EC2_ROUTES,
}: EC2Props): ReactElement => {
  const { goto } = useRoutes();

  const { runInstances, isLoading } = useAwsEffect(
    'EC2',
    ['runInstances'],
    { revalidate: ['describeInstances'], clientOverrides },
  );

  const handleSubmit = async (data: RunEC2InstancesRequest) => {
    if (await runInstances(data)) {
      goto(routes.RESOURCES_EC2_INSTANCES);
    }
  };

  return (
    <Layout
      documentTitle="EC2: Launch Instance"
      title={
        <Box>
          <Typography variant="h4">Launch Instance</Typography>
          <Breadcrumbs
            mappings={[
              ['EC2', () => goto(routes.RESOURCES_EC2)],
              ['Instances', () => goto(routes.RESOURCES_EC2_INSTANCES)],
              ['Launch Instance', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <EC2InstanceForm
            onSubmit={handleSubmit}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
