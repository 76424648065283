import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { MembershipState as MembershipStateEnum } from '@localstack/types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dot: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(.5),
    marginRight: theme.spacing(1),
  },
  invited: {
    backgroundColor: theme.palette.info.main,
  },
  active: {
    backgroundColor: theme.palette.success.main,
  },
  removed: {
    backgroundColor: theme.palette.error.main,
  },
}));

type Props = {
  state: MembershipStateEnum | string;
}

export const MembershipState = ({ state }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <span>
      <span
        className={classnames(classes.dot, {
          [classes.invited]: state === MembershipStateEnum.INVITED,
          [classes.active]: state === MembershipStateEnum.ACTIVE,
          [classes.removed]: state === MembershipStateEnum.REMOVED,
        })}
      />
      {state}
    </span>
  );
};

export default MembershipState;
