import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AthenaDatabase } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AthenaDatabasesTableProps = {
  loading?: boolean;
  databases: AthenaDatabase[];
  onViewDatabase?: (name: AthenaDatabase) => void;
};

export const AthenaDatabasesTable = ({
  databases,
  loading,
  onViewDatabase,
}: AthenaDatabasesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Athena')}
    entry="Database"
    idAttribute="Name"
    rows={databases}
    selectable={false}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewDatabase && onViewDatabase(row)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
