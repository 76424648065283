import axios from 'axios';
import useSWR from 'swr';
import { useState, ReactElement } from 'react';
import { MagicTable, ProgressButton, Breadcrumbs } from '@localstack/ui';
import { CloudPod } from '@localstack/types';
import { ExternalLink } from '@localstack/constants';
import {
  useEndpoint,
  AWS_SERVICE_PORTS,
  useSnackbar,
  useRoutes,
  useInjectPodState,
  useAwsGetter,
} from '@localstack/services';
import { Alert ,
  Box,
  Card,
  Link,
  CardActions,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
} from '@mui/material';
import { Cloud, Image, Remove as ListIcon, TouchApp } from '@mui/icons-material';


import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

import StepperCard, { StepperItem } from '../components/StepperCard';

const REST_API_NAME = 'local-localstack-demo';
const POLLING_INTERVAL = 2000;

interface DemoRequest {
  id: string;
  requestID: string;
  timestamp: string;
  status: 'QUEUED' | 'PROCESSING' | 'FINISHED';
}

const demoPod: CloudPod = {
  id: '-',
  is_public: true,
  max_version: 1,
  org_id: '-',
  pod_name: 'quickstart',
};

const TABLE_SCHEMA = {
  shapes: {
    DemoRequest: {
      type: 'structure',
      members: {
        id: { type: 'string' },
        requestID: { type: 'string' },
        timestamp: { type: 'string' },
        status: { type: 'string' },
      },
    },
  },
};

export const DemoThree = (): ReactElement => {
  const { goto } = useRoutes();
  const [sending, setSending] = useState(false);

  const { inject, injected, isLoading: cloudPodInjecting } = useInjectPodState();

  const { endpoint } = useEndpoint();
  const { showSnackbar } = useSnackbar();

  const { data: apis } = useAwsGetter(
    'APIGateway',
    'getRestApis',
    [],
    { swrOverrides: { refreshInterval: POLLING_INTERVAL }, silentErrors: true },
  );
  const restApi = apis?.items?.find((api) => api.name === REST_API_NAME);

  const agwEndpoint = endpoint.includes(':') ? endpoint : `${endpoint}:${AWS_SERVICE_PORTS.APIGateway}`;
  const restApiEndpoint = `${agwEndpoint}/restapis/${restApi?.id}/local/_user_request_`;

  const isDeployed = injected || !!restApi;

  const fetchTableData = async () => {
    if (isDeployed) {
      const { data } = (await axios.get<{ result: DemoRequest[] }>(`${restApiEndpoint}/requests`));
      return data.result;
    }
    return null;
  };

  const { data: requests, isValidating: isRequestsLoading } = useSWR(
    restApiEndpoint,
    fetchTableData,
    { refreshInterval: POLLING_INTERVAL },
  );

  const sendRequest = async () => {
    setSending(true);
    try {
      await axios.post(`${restApiEndpoint}/requests`, {});
      showSnackbar({
        message: 'New request has been sent to the API. It should appear in the list as QUEUED shortly.',
        severity: 'info',
      });
    } catch (e) {
      showSnackbar({
        message: 'Unable to send request to the API. Please make sure that LocalStack is running properly.',
        severity: 'error',
      });
    } finally {
      setSending(false);
    }
  };

  const stepperItems: StepperItem[] = [
    {
      content:
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography paragraph>
              This sample illustrates a typical application
              with asynchronous request processing happening in
              the background, all running <strong>locally inside LocalStack</strong>.
            </Typography>
            <Typography paragraph>
              The end-to-end process of the application is illustrated in the figure on the right.
            </Typography>
            <Typography paragraph>Here&apos;s how the app works:</Typography>
            <List>
              <ListItem>
                <ListItemIcon><ListIcon /></ListItemIcon>
                <ListItemText
                  primary={`
                        Requests received by the API
                        Gateway will be handled by a Lambda function
                        for further processing.
                      `}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon><ListIcon /></ListItemIcon>
                <ListItemText
                  primary={`
                        As the request is going through different stages of
                        processing, the status of the request will be updated with the
                        current status (QUEUED -> PROCESSING -> FINISHED) in DynamoDB.
                      `}
                />
              </ListItem>
              <ListItem>
                <ListItemIcon><ListIcon /></ListItemIcon>
                <ListItemText
                  primary={`
                        Once the request is FINISHED, the processing result will
                        become available as a link to a file stored in S3.
                      `}
                />
              </ListItem>
            </List>
            <Typography paragraph>
              (Note that all resources will be deployed to the local
              &quot;us-east-1&quot; region.)
            </Typography>
            <Typography paragraph>
              The full source code of this sample is available in this
              Github repo:
              {' '}
              <Link href={ExternalLink.DEMO_LS} underline="hover">
                {ExternalLink.DEMO_LS}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <img alt="" src="/demo3/requestworker.svg" width="100%" />
          </Grid>
        </Grid>,
      title: 'Application Overview',
      icon: <Image />,
    },
    {
      content:
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>
              <Typography paragraph>
                Cloud Pods are a mechanism that allows you to take a snapshot of the state in your current LocalStack
                instance, persist it to a storage backend, and easily share it with your team members.
              </Typography>
              <Typography paragraph>
                This sample will restore the previously shown application from a Cloud Pod that we prepared.<br />
                First, inject the Cloud Pod into your running instance of LocalStack.
                Afterwards, you will be able to interact with the application, and view the data stored within
                your local DynamoDB.
              </Typography>
              <Typography paragraph>
                To learn more about Cloud Pods, visit our docs
                {' '}
                <a target='_blank' rel='noreferrer'
                  href={ExternalLink.DOCS_CLOUD_PODS}
                >here</a>.
              </Typography>
            </Typography>
            <ProgressButton
              color='primary'
              variant='contained'
              disabled={isDeployed}
              loading={cloudPodInjecting}
              onClick={() => inject(demoPod)}
            >
              Inject Cloud Pod
            </ProgressButton>
            {isDeployed && (
              <Alert severity='info' style={{ marginTop: '2rem' }}>
                You successfully injected the Cloud Pod. Click on &apos;Continue&apos; to interact with the application
              </Alert>
            )}
          </Grid>
          <Grid item xs={6}>
            <img alt="" src="/demo3/requestworkerPod.svg" width="100%" />
          </Grid>
        </Grid>,
      icon: <Cloud />,
      title: 'Inject Cloud Pod',
      disableContinue: !isDeployed,
    },
    {
      content:
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography>
              <Typography paragraph>
                As the Cloud Pod has been injected into your LocalStack instance, you can now interact
                with the locally deployed application.
              </Typography>
              <Typography paragraph>
                Use the button &apos;Send Sample Request&apos; to send a new request to the
                local API Gateway, which will then be handled by the Step Function State Machine
                and Lambda functions for further processing.
              </Typography>
              <Typography paragraph>
                Watch as the request is going through different stages of processing in the DynamoDB
                table to the right.
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Card>
              {isRequestsLoading && <LinearProgress />}
              <MagicTable
                schema={TABLE_SCHEMA}
                entry="DemoRequest"
                rows={requests ?? []}
                idAttribute="id"
              />
              <CardActions>
                <ProgressButton
                  color="primary"
                  variant="contained"
                  loading={sending}
                  onClick={sendRequest}
                >
                  Send Sample Request
                </ProgressButton>
              </CardActions>
            </Card>
          </Grid>
        </Grid>,
      title: 'Interact with Application',
      icon: <TouchApp />,
    },
  ];

  return (
    <CustomerLayout
      documentTitle="Request Worker Sample App"
      title={
        <Box>
          <Typography variant="h4">Cloud Pod Request Worker Sample App</Typography>
          <Breadcrumbs
            mappings={[
              ['Quickstart', () => goto(AppRoute.QUICKSTART)],
              ['Request Worker Sample App', null],
            ]}
          />
        </Box>
      }
    >
      <StepperCard
        stepperItems={stepperItems}
      />
    </CustomerLayout>
  );
};
