import { DEFAULT_AMPLIFY_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';
import { Breadcrumbs, AmplifyBEEnvironmentDetail } from '@localstack/ui';

import { AmplifyProps } from './types';

export const AmplifyBEEnvironment = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_AMPLIFY_ROUTES,
}: AmplifyProps): ReactElement => {
  const { goto } = useRoutes();
  const { appId } = useParams<'appId'>();
  const { environmentName } = useParams<'environmentName'>();

  const { data } = useAwsGetter(
    'Amplify',
    'getBackendEnvironment',
    [{ appId, environmentName }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Amplify Backend Environment</Typography>
          <Breadcrumbs
            mappings={[
              ['Amplify', () => goto(routes.RESOURCES_AMPLIFY_APPS)],
              ['appId', () => goto(routes.RESOURCES_AMPLIFY_APP, { appId })],
              [environmentName, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <AmplifyBEEnvironmentDetail environment={data} />
      </Card>
    </Layout>
  );
};
