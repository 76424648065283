import { ReactElement } from 'react';
import { RDSInstance } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface RDSInstanceDetailsProps {
  instance?: Optional<Partial<RDSInstance>>;
}

export const RDSInstanceDetails = ({ instance }: RDSInstanceDetailsProps): ReactElement => (
  <MagicDetails
    data={instance}
    schema={getSchema('RDS')}
    entry="DBInstance"
    title="Instance Details"
  />
);
