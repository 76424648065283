import { ReactElement } from 'react';
import { AppSyncResolver } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AppSyncResolverDetailsProps {
  resolver?: Optional<Partial<AppSyncResolver>>;
}

export const AppSyncResolverDetails = ({ resolver }: AppSyncResolverDetailsProps): ReactElement => (
  <MagicDetails
    data={resolver}
    schema={getSchema('AppSync')}
    entry="Resolver"
    title="Resolver Details"
  />
);
