import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateSGMEndpointRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, SGMEndpointCreate } from '@localstack/ui';

import { SageMakerProps } from './types';

export const SageMakerEndpointCreate = ({
  Layout,
  clientOverrides,
  routes,
}: SageMakerProps): ReactElement => {
  const { goto } = useRoutes();

  const { createEndpoint, isLoading } = useAwsEffect(
    'SGM',
    ['createEndpoint'],
    { revalidate: ['listEndpoints'], clientOverrides },
  );

  const handleCreate = async (data: CreateSGMEndpointRequest) => {
    await createEndpoint(data);
    goto(routes.RESOURCES_SGM_ENDPOINTS, { name: data.EndpointName });
  };

  return (
    <Layout
      documentTitle="Endpoint Details"
      title={
        <Box>
          <Typography variant="h4">Endpoint Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SGM', () => goto(routes.RESOURCES_SGM_ENDPOINTS)],
              ['Create Endpoint', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateSageMakerEndpoint"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SGMEndpointCreate
            onSubmit={handleCreate}
            clientOverrides={clientOverrides}
            formId="CreateSageMakerEndpoint"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateSageMakerEndpoint"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
