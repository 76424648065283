import { ReactElement } from 'react';
import { AgwRestApiUsagePlan } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiUsagePlansTableProps = {
  selectable?: boolean;
  loading?: boolean;
  plans: AgwRestApiUsagePlan[];
  onSelect?: (ids: string[]) => unknown;
  onViewUsagePlan?: (plan: AgwRestApiUsagePlan) => unknown;
};

export const AgwRestApiUsagePlansTable = ({
  plans,
  selectable = true,
  loading,
  onSelect,
  onViewUsagePlan,
}: AgwRestApiUsagePlansTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="UsagePlan"
    idAttribute="id"
    rows={plans}
    selectable={selectable}
    onSelect={onSelect}
    order={['id', 'name', 'apiStages']}
    loading={loading}
    externalFields={{
      id: (row) => (
        <Link onClick={() => onViewUsagePlan && onViewUsagePlan(row)} underline="hover">
          {row.id}
        </Link>
      ),
      apiStages: (row) => (
        <span>
          {row.apiStages?.map((v) => `${v.apiId}:${v.stage}`).join(', ')}
        </span>
      ),
    }}
  />
);
