import { ReactElement } from 'react';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, StateExecutionStartForm, ProgressButton } from '@localstack/ui';
import { StartExecutionRequest } from '@localstack/types';
import { DEFAULT_STEPFUNCTIONS_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { StepFunctionsProps } from './types';

export const StateExecutionStart = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_STEPFUNCTIONS_ROUTES,
}: StepFunctionsProps): ReactElement => {
  const { goto } = useRoutes();
  const { stateMachineArn } = useParams<'stateMachineArn'>();

  const { startExecution, isLoading } = useAwsEffect(
    'StepFunctions',
    ['startExecution'],
    { revalidate: ['listExecutions'], clientOverrides },
  );

  const handleCreate = async (data: StartExecutionRequest) => {
    const res = await startExecution(data);
    if (res) {
      goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS, { stateMachineArn });
    }
  };

  return (
    <Layout
      documentTitle="Step Functions: Start State Machine Execution"
      title={
        <Box>
          <Typography variant="h4">Start State Machine Execution</Typography>
          <Breadcrumbs
            mappings={[
              ['Step Functions', () => goto(routes.RESOURCES_STEPFUNCTIONS)],
              ['State Machines', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES)],
              [stateMachineArn?.split(':').pop(), () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE)],
              ['Executions', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS)],
              ['Start Execution', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateDB"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <StateExecutionStartForm
            stateMachineArn={stateMachineArn}
            loading={isLoading}
            formId="CreateDB"
            onCreate={handleCreate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateDB"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
