import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, AppSyncApisTable, PageTitle } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { AppSyncProps } from './types';

export const AppSyncApis = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: apis, isLoading, mutate } = useAwsGetter('AppSync', 'listGraphqlApis',[],{ clientOverrides });

  const { deleteGraphqlApi } = useAwsEffect(
    'AppSync',
    ['deleteGraphqlApi'],
    { revalidate: ['listGraphqlApis', 'getGraphqlApi'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((apiId) => deleteGraphqlApi({ apiId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={<PageTitle title="AppSync APIs" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_APPSYNC_API_CREATE)}>
            Create API
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} API(s)?`}
              onClick={handleDeleteSelected}
              text="Selected APIs will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AppSyncApisTable
          selectable
          loading={isLoading}
          apis={apis?.graphqlApis ?? []}
          onSelect={setSelectedIds}
          onViewApi={
            ({ apiId }) => goto(routes.RESOURCES_APPSYNC_API, { apiId })
          }
        />
      </Card>
    </Layout>
  );
};
