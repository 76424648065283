import { ReactElement } from 'react';
import { CreateSNSTopicRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface SNSCreateFormDialogProps {
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateSNSTopicRequest) => void;
}

export const SNSTopicCreateForm = ({
  loading,
  formId,
  onCreate,
}: SNSCreateFormDialogProps): ReactElement => (
  <MagicForm
    schema={getSchema('SNS')}
    loading={loading}
    entry="CreateTopicInput"
    formId={formId}
    onSubmit={(data: CreateSNSTopicRequest) => onCreate(data)}
  />
);
