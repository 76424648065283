
import { ReactElement } from 'react';

import { AwsClientOverrides, buildRoute, getSchema } from '@localstack/services';
import { TestConnectionMessage } from '@localstack/types';
import { DEFAULT_DMS_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form';

export type DMSEndpointConnectionTestFormProps = {
  endpointId: string;
  formId: string;
  onSubmit: (data: TestConnectionMessage) => void;
  clientOverrides?: AwsClientOverrides | undefined;
  loading?: boolean;
};

export const DMSEndpointConnectionTestForm = ({
  endpointId,
  formId,
  onSubmit,
  loading,
  clientOverrides,
}: DMSEndpointConnectionTestFormProps): ReactElement => (
  <MagicForm
    fieldConditions={{ EndpointArn: false }}
    entry="TestConnectionMessage"
    schema={getSchema('DMS')}
    loading={loading}
    onSubmit={(data: TestConnectionMessage) => onSubmit({ ...data, EndpointArn: endpointId })}
    formId={formId}
    externalFields={{
      '^ReplicationInstanceArn$': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name} entityName='ReplicationInstance'
          client='DMS'
          method='describeReplicationInstances'
          arrayKeyName='ReplicationInstances'
          property='ReplicationInstanceArn'
          clientOverrides={clientOverrides}
          creationRoute={buildRoute(DEFAULT_DMS_ROUTES.RESOURCES_DMS_REPLICATION_INSTANCE_CREATE)}
        />
      ),
    }}
  />
);
