import { ReactElement } from 'react';
import { CreateRoute53HostedZoneRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';


export type Route53HostedZoneCreateFormProps = {
  loading?: boolean;
  formId: string;
  onSubmit: (data: CreateRoute53HostedZoneRequest) => unknown;
}

export const Route53HostedZoneCreateForm = ({
  loading,
  formId,
  onSubmit,
}: Route53HostedZoneCreateFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('Route53')}
    formId={formId}
    entry="CreateHostedZoneRequest"
    loading={loading}
    fieldConditions={{
      CallerReference: false,
    }}
    onSubmit={(data: CreateRoute53HostedZoneRequest) => onSubmit(data)}
  />
);
