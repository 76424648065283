import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AgwRestApiMethodResponse } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiMethodResponsesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  responses: AgwRestApiMethodResponse[];
  onViewResponse?: (response: AgwRestApiMethodResponse) => unknown;
  onSelect?: (ids: string[]) => unknown;
};

export const AgwRestApiMethodResponsesTable = ({
  responses,
  selectable = true,
  loading,
  onViewResponse,
  onSelect,
}: AgwRestApiMethodResponsesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="MethodResponse"
    idAttribute="statusCode"
    rows={responses}
    selectable={selectable}
    onSelect={onSelect}
    order={['statusCode']}
    loading={loading}
    externalFields={{
      statusCode: (row) => (
        <Link onClick={() => onViewResponse && onViewResponse(row)} underline="hover">
          {row.statusCode}
        </Link>
      ),
    }}
  />
);
