import { ReactElement } from 'react';
import { CognitoPool } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface CognitoPoolsTableProps {
  selectable?: boolean;
  loading?: boolean;
  pools: CognitoPool[];
  onViewPool?: (model: CognitoPool) => unknown;
  onSelect?: (ids: string[]) => void;
}

export const CognitoPoolsTable = ({
  pools,
  selectable = true,
  loading,
  onViewPool,
  onSelect,
}: CognitoPoolsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('CognitoIdentityServiceProvider')}
    entry="UserPoolDescriptionType"
    idAttribute="Id"
    rows={pools}
    selectable={selectable}
    onSelect={onSelect}
    order={['Id', 'Name']}
    loading={loading}
    externalFields={{
      Id: (row) => (
        <Link onClick={() => onViewPool && onViewPool(row)} underline="hover">
          {row.Id}
        </Link>
      ),
    }}
  />
);
