import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAppSyncDataSourceRequest, UpdateAppSyncDataSourceRequest } from '@localstack/types';
import { ProgressButton, AppSyncDataSourceForm, Breadcrumbs } from '@localstack/ui';


import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiDataSourceUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId, name } = useParams<'apiId' | 'name'>();

  const { createDataSource, updateDataSource, isLoading } = useAwsEffect(
    'AppSync',
    ['createDataSource', 'updateDataSource'],
    { revalidate: ['listDataSources', 'getDataSource'], clientOverrides },
  );

  const { data: dataSource } = useAwsGetter('AppSync', 'getDataSource', [{ apiId, name }], { clientOverrides });

  const handleCreate = async (data: CreateAppSyncDataSourceRequest) => {
    await createDataSource(data);
    goto(routes.RESOURCES_APPSYNC_API_DATA_SOURCES, { apiId });
  };

  const handleUpdate = async (data: UpdateAppSyncDataSourceRequest) => {
    await updateDataSource(data);
    goto(routes.RESOURCES_APPSYNC_API_DATA_SOURCE, { apiId, name });
  };

  return (
    <Layout
      documentTitle="Data Source Details"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Data Source Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              [name, () => goto(routes.RESOURCES_APPSYNC_API_DATA_SOURCE, { apiId, name })],
              [name ? 'Update' : 'Create Data Source', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AppSyncDataSourceForm
            apiId={apiId as string}
            dataSource={dataSource?.dataSource}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
