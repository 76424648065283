import { DefaultValuesObject } from './types';

export const DEFAULT_CE_VALUES: DefaultValuesObject = {
  CreateCostCategoryDefinitionRequest: {
    input: {
      EffectiveStart: '2024-01-01',
      RuleVersion: 'CostCategoryExpression.v1',
      Rules: [{
        Value: 'test',
        Type: 'REGULAR',
      }],
      DefaultValue: 'test',
    },
  },
};
