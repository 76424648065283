import { useState, useCallback, ReactElement, useEffect } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter, useAwsKeysMemo } from '@localstack/services';
import { Card, Button } from '@mui/material';

import {
  Dropdown,
  ConfirmableButton,
  SystemParametersTable,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_SYSTEMPARAMETERS_ROUTES } from '@localstack/constants/src';

import { SystemParametersProps } from './types';

export const SystemParameters = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SYSTEMPARAMETERS_ROUTES,
}: SystemParametersProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [nextToken, setNextToken] = useState<string | undefined>('0');

  const { goto } = useRoutes();

  const { data: parameters, isLoading, mutate } = useAwsGetter(
    'SSM',
    'describeParameters',
    [{ 'NextToken': nextToken }],
    { clientOverrides, defaultValue: { Parameters: [], NextToken: undefined } },
  );

  const { deleteParameter } = useAwsEffect(
    'SSM',
    ['deleteParameter'],
    { revalidate: ['describeParameters', 'getParameter'],clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((Name) => deleteParameter({ Name }));
    await Promise.all(promises);
  }, [selectedIds]);

  const queryKeys = useAwsKeysMemo(page, parameters?.NextToken);

  useEffect(() => {
    setNextToken(queryKeys[page]);
  }, [page]);

  return (
    <Layout
      title={
        <PageTitle
          title="System Parameters"
          onMutate={mutate}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_SYSTEM_PARAMETER_CREATE)}>
            Create Parameter
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Parameter(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Parameters will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <SystemParametersTable
          selectable
          parameters={parameters?.Parameters ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          hasMore={!!parameters?.NextToken}
          onPageChange={setPage}
          page={page}
          onViewParameter={({ Name }) => goto(
            routes.RESOURCES_SYSTEM_PARAMETER,
            { name: encodeURIComponent(Name ?? '') },
          )}
        />
      </Card>
    </Layout>
  );
};
