import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { Route53ResolverEndpointsTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { MainNavTabs } from './components';

import { Route53Props } from './types';

export const Route53ResolverEndpoints = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: endpoints, isLoading, mutate } = useAwsGetter(
    'Route53Resolver',
    'listResolverEndpoints',
    [],
    { clientOverrides },
  );
  const { deleteResolverEndpoint } = useAwsEffect('Route53Resolver', ['deleteResolverEndpoint'], {
    revalidate: ['listResolverEndpoints'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteResolverEndpoint({ ResolverEndpointId: id }),
      ),
    );
  };
  return (
    <Layout
      documentTitle="Route53 Resolver: Endpoints"
      title={
        <PageTitle
          title="Route 53"
          breadcrumbs={[
            ['Route53', () => goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINTS)],
            ['Resolver Endpoints', () => goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINTS)],
          ]}
          onMutate={mutate}
        />}
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINT_CREATE)}>
            Create Endpoint
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Endpoint(s)?`}
              onClick={handleRemove}
              text="Selected Endpoint(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <Route53ResolverEndpointsTable
          endpoints={endpoints?.ResolverEndpoints || []}
          onViewEndpoint={(id: string) =>
            goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINT, { id })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
