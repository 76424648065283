import { ReactElement } from 'react';
import { EventBridgeTarget } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface EventBridgeTargetDetailsProps {
  target?: Optional<Partial<EventBridgeTarget>>;
}

export const EventBridgeTargetDetails = ({ target }: EventBridgeTargetDetailsProps): ReactElement => (
  <MagicDetails
    data={target}
    schema={getSchema('EventBridge')}
    entry="Target"
    title="Target Details"
  />
);
