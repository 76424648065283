// any version of the form 1.X[.X ...], the noise is ignored (i.e. v1.2 is handled as 1.2)
const VERSION_REGEX = /(\d+)(?=\.?)/g;

/**
 * This function takes a version string and a version constraint string.
 * It returns true if the version is greater than or equal to the constraint.
 *
 * @param version version being checked
 * @param constraint version constraint
 */
export const satisfiesVersionConstraint = (version: string, constraint: string): boolean => {
  const versionParts = (version.match(VERSION_REGEX) ?? []).map(Number);
  const constraintParts = (constraint.match(VERSION_REGEX) ?? []).map(Number);

  for (let i = 0; i < Math.max(versionParts.length, constraintParts.length); i++) {
    const v = versionParts[i] || 0;
    const c = constraintParts[i] || 0;

    if (v > c) {
      return true;
    }  
    if (v < c) {
      return false;
    }
    // If v === c, continue checking the next part
  }

  return true; // The versions are equal
};
