import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const MQ_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.MQ}`;

export const DEFAULT_MQ_ROUTES: Record<string,string> = {
  RESOURCES_MQ_BROKERS: `${MQ_BASE_ROUTE}/brokers`,
  RESOURCES_MQ_BROKER_CREATE: `${MQ_BASE_ROUTE}/broker/new`,
  RESOURCES_MQ_BROKER: `${MQ_BASE_ROUTE}/:id`,
};
