import { ApiKey, OrganizationMember } from '@localstack/types';
import { Autocomplete , Button, Grid, TextField } from '@mui/material';
import { ReactElement, useState } from 'react';

interface AssignKeyFormProps {
  apiKey: ApiKey;
  members: OrganizationMember[];
  onAssign: (apiKey: ApiKey, selectedUser: string) => unknown;
  setAssigning: (isAssigning: null) => void;
}

export const AssignKeyForm = ({ apiKey, members, onAssign, setAssigning }: AssignKeyFormProps): ReactElement => {
  const [selectedUser, setSelectedUser] = useState<Optional<OrganizationMember>>(
    members.find(member => member.email === apiKey.member_email) || null,
  );

  const saveButtonClickHandler = async () => {
    if (!selectedUser) return;
    await onAssign(apiKey, selectedUser.email);
    setAssigning(null);
  };

  return (
    <Grid container spacing={2} style={{ width: '100%', margin: '10px 0' }}>
      <Grid item xs={12}>
        <Autocomplete
          id="combo-box-demo"
          options={members}
          getOptionLabel={({ name, email }) => `${name} (${email})`}
          onChange={(_, user) => setSelectedUser(user)}
          renderInput={(options) => <TextField variant="outlined" label="Assigned User" {...options} />}
          defaultValue={selectedUser}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              size='small'
              disabled={!selectedUser}
              onClick={saveButtonClickHandler}
            >
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" size="small" onClick={() => setAssigning(null)}>Cancel</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
