import { useRoutes, useAwsEffect } from '@localstack/services';
import { Card, CardContent, CardActions, Typography, Box } from '@mui/material';
import { CreateIAMPolicyRequest } from '@localstack/types';
import { ProgressButton, IAMPolicyForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';
import { ReactElement } from 'react';

import { NavTabs } from './components';
import { IAMProps } from './types';

export const IAMPolicyCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();

  const { createPolicy, isLoading } = useAwsEffect(
    'IAM',
    ['createPolicy'],
    { revalidate: ['getPolicy', 'listPolicies'],clientOverrides },
  );

  const handleCreate = async (data: CreateIAMPolicyRequest) => {
    await createPolicy(data);
    goto(routes.RESOURCES_IAM_POLICIES);
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Policy Details</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              ['Create Policy', null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs routes={routes}/>}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <IAMPolicyForm
            onCreate={handleCreate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
