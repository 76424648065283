import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import {
  AppSyncDataSource,
  CreateAppSyncDataSourceRequest,
  UpdateAppSyncDataSourceRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';

export interface AppSyncDataSourceFormProps {
  apiId: string;
  dataSource?: Optional<Partial<AppSyncDataSource>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAppSyncDataSourceRequest) => unknown;
  onUpdate: (data: UpdateAppSyncDataSourceRequest) => unknown;
}

export const AppSyncDataSourceForm = ({
  apiId,
  dataSource,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AppSyncDataSourceFormProps): ReactElement => (
  <MagicForm
    data={dataSource}
    schema={getSchema('AppSync')}
    loading={loading}
    entry={dataSource ? 'UpdateDataSourceRequest' : 'CreateDataSourceRequest'}
    formId={formId}
    fieldConditions={{
      '^apiId$': false,
      '^name$': !dataSource,
      '^dynamodbConfig': ['$type', '===', 'AMAZON_DYNAMODB'],
      '^lambdaConfig': ['$type', '===', 'AWS_LAMBDA'],
      '^elasticsearchConfig': ['$type', '===', 'AMAZON_ELASTICSEARCH'],
      '^openSearchServiceConfig': ['$type', '===', 'AMAZON_OPENSEARCH_SERVICE'],
      '^relationalDatabaseConfig': ['$type', '===', 'RELATIONAL_DATABASE'],
      '^httpConfig': ['$type', '===', 'HTTP'],
    }}
    onSubmit={(data: CreateAppSyncDataSourceRequest | UpdateAppSyncDataSourceRequest) => {
      if (!dataSource) return onCreate({ ...data, apiId } as CreateAppSyncDataSourceRequest);
      return onUpdate({ ...data, apiId, name: dataSource?.name as string });
    }}
  />
);
