import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button, MenuItem } from '@mui/material';

import {
  Dropdown,
  ConfirmableButton,
  EventBridgeRulesTable,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { EventBridgeProps } from './types';

export const EventBridgeRules = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { busName } = useParams<'busName'>();

  const { data: rules, isLoading, mutate } = useAwsGetter(
    'EventBridge',
    'listRules',
    [{ EventBusName: busName }],
    { clientOverrides });

  const { deleteRule } = useAwsEffect(
    'EventBridge',
    ['deleteRule'],
    { revalidate: ['listRules', 'describeRule'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((Name) => deleteRule({ Name }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="EventBridge: Rules"
      title={
        <PageTitle
          title="EventBridge Rules"
          onMutate={mutate}
          breadcrumbs={[
            ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
            ['Buses', () => goto(routes.RESOURCES_EVENT_BRIDGE_BUSES)],
            [busName, () => goto(routes.RESOURCES_EVENT_BRIDGE_BUS, { busName })],
            ['Rules', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_CREATE, { busName })}>
            Create Rule
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Event Bus(es)?`}
              onClick={handleDeleteSelected}
              text="Selected Event Buses will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
            <MenuItem
              disabled={selectedIds.length !== 1}
              onClick={() => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_UPDATE, { busName, ruleName: selectedIds[0] })}
            >
              Edit Rule
            </MenuItem>

          </Dropdown>
        </>
      }
    >
      <Card>
        <EventBridgeRulesTable
          rules={rules?.Rules ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewRule={({ Name }) => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { ruleName: Name, busName })}
        />
      </Card>
    </Layout>
  );
};
