import { ReactElement } from 'react';
import { AgwRestApiAuthorizer } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiAuthorizerDetailsProps {
  authorizer?: Optional<Partial<AgwRestApiAuthorizer>>;
}

export const AgwRestApiAuthorizerDetails = ({ authorizer }: AgwRestApiAuthorizerDetailsProps): ReactElement => (
  <MagicDetails
    data={authorizer}
    schema={getSchema('APIGateway')}
    entry="Authorizer"
    title="Authorizer Details"
  />
);
