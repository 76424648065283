import { useState, useCallback, useEffect, useRef, ReactElement } from 'react';
import { Box } from '@mui/material';

import {
  InfiniteLoader,
  List,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  ListRowProps,
  Index,
  ScrollParams,
} from 'react-virtualized';

import { LogsTableRow } from './LogsTableRow';
import { LogsTableEvent } from './types';

export { LogsTableEvent } from './types';
export { LogsTableToolbar } from './LogsTableToolbar';

enum LoadDirection {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
  FORWARD_END = 'FORWARD_END'
}

export interface LogsTableProps {
  events: LogsTableEvent[];
  onLoadForward: () => unknown;
  onLoadBackward: () => unknown;
  onViewGroup?: (groupName: string) => unknown;
}

export const LogsTable = ({ events, onLoadForward, onLoadBackward, onViewGroup }: LogsTableProps): ReactElement => {
  // Direction to decide how to scroll when we have new portion of logs
  const [lastDirection, setLastDirection] = useState(LoadDirection.FORWARD_END);

  // track the very first scroll event (first scroll event is skipped)
  const [scrollInitialized, setScrollInitialized] = useState(false);

  // whether we are currently loading more rows
  const [loadMoreInitialized, setLoadMoreInitialized] = useState(false);

  // we are loading something (either more rows / forward, or backward)
  const [pageLoading, setPageLoading] = useState(false);

  const [oldLogsLength, setOldLogsLength] = useState(0);

  // cache for cell measurments (required to properly calculate offsets of expandable rows)
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 20,
  });

  const listRef = useRef<List | null>();

  const loadMoreRows = useCallback(async () => {
    if (pageLoading) return;

    setPageLoading(true);

    await onLoadForward();

    setLastDirection(LoadDirection.FORWARD);
    setLoadMoreInitialized(true);
    setPageLoading(false);
  }, [pageLoading, onLoadForward, loadMoreInitialized]);

  const onScroll = useCallback(async (params: ScrollParams) => {
    if (!scrollInitialized) {
      setScrollInitialized(true);
      return;
    }

    if (pageLoading || params.scrollTop !== 0) return;

    setPageLoading(true);

    await onLoadBackward();

    setLastDirection(LoadDirection.BACKWARD);
    setPageLoading(false);
  }, [onLoadBackward, scrollInitialized, events.length, pageLoading]);

  // Scroll to the right row when new logs are appended in the live mode/scroll,
  // or when logs are prepended in scroll mode
  useEffect(() => {
    if (!listRef.current || pageLoading) return;
    if (oldLogsLength === events.length) return;

    if (lastDirection === LoadDirection.FORWARD_END) {
      listRef.current.scrollToRow(events.length);
    }

    if (lastDirection === LoadDirection.FORWARD) {
      listRef.current.scrollToRow(events.length);
    }

    if (lastDirection === LoadDirection.BACKWARD) {
      listRef.current.scrollToRow(events.length - oldLogsLength);
    }

    // Do nothing for LoadDirection.FORWARD, component will
    // automatically do the job and leave a user at the same position

    setOldLogsLength(events.length);
  }, [events.length, lastDirection, pageLoading, oldLogsLength, loadMoreInitialized]);

  // callback to report whether the row has been loaded and is ready to be rendered
  const isRowLoaded = useCallback(({ index }: Index) => !!events[index], [events]);

  const rowRenderer = ({ index, parent, key, style }: ListRowProps) => {
    const logMessage = events[index];

    if (!logMessage) return <div style={style} />;

    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <LogsTableRow
          style={style}
          event={events[index] as LogsTableEvent}
          onViewGroup={onViewGroup}
        />
      </CellMeasurer>
    );
  };

  return (
    <Box height="100%">
      <InfiniteLoader
        isRowLoaded={isRowLoaded}
        loadMoreRows={loadMoreRows}
        rowCount={events.length + 1} // +1 to enable infinite scroll
      >
        {({ onRowsRendered, registerChild }) => (
          <AutoSizer>
            {({ width, height }) => (
              <List
                ref={(ref) => {
                  listRef.current = ref;
                  registerChild(ref);
                }}
                style={{ outline: 'none' }}
                height={height}
                onRowsRendered={onRowsRendered}
                rowCount={events.length + 1}
                rowHeight={cache.rowHeight}
                deferredMeasurementCache={cache}
                rowRenderer={rowRenderer}
                width={width}
                scrollToAlignment="start"
                onScroll={onScroll}
              />
            )}
          </AutoSizer>
        )}
      </InfiniteLoader>
    </Box>
  );
};
