import { ReactElement } from 'react';
import { get } from 'lodash';
import { CognitoCreateUserParams, CognitoUpdateUserParams, CognitoUser } from '@localstack/types';
import { VALIDATION_RULES, getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledTextField } from '../../../form';

const UNCHANGEABLE_ATTTRS = ['sub'];

export interface CognitoUserFormProps {
  poolId: string;
  user?: Optional<Partial<CognitoUser>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CognitoCreateUserParams) => unknown;
  onUpdate: (data: CognitoUpdateUserParams) => unknown;
}

export const CognitoUserForm = ({
  poolId,
  user,
  loading,
  formId,
  onCreate,
  onUpdate,
}: CognitoUserFormProps): ReactElement => (
  <MagicForm
    data={user}
    schema={getSchema('CognitoIdentityServiceProvider')}
    loading={loading}
    entry={user ? 'AdminUpdateUserAttributesRequest' : 'AdminCreateUserRequest'}
    formId={formId}
    fieldConditions={{
      '^UserPoolId': false,
      '^Username': !user,
    }}
    // override some of the attributes to make them unchangeable
    externalFields={{
      '^UserAttributes\\.[0-9]+\\.Name': (control, name) => {
        const nameValue = get(user ?? {}, name);
        return (
          <ControlledTextField
            fullWidth
            required
            rules={VALIDATION_RULES.required}
            control={control}
            name={name}
            label="Name"
            variant="outlined"
            disabled={UNCHANGEABLE_ATTTRS.some((attr) => nameValue?.endsWith(attr))}
          />
        );
      },
      '^UserAttributes\\.[0-9]+\\.Value': (control, name) => {
        const nameField = name.split('.').slice(0, -1).concat(['Name']).join('.');
        const nameValue = get(user ?? {}, nameField);

        return (
          <ControlledTextField
            fullWidth
            control={control}
            name={name}
            label="Value"
            variant="outlined"
            disabled={UNCHANGEABLE_ATTTRS.some((attr) => nameValue?.endsWith(attr))}
          />
        );
      },
    }}
    onSubmit={(data: CognitoCreateUserParams) => {
      if (!user) return onCreate({ ...data, UserPoolId: poolId });

      return onUpdate(
        { ...data, UserPoolId: poolId, Username: user.Username as string } as CognitoUpdateUserParams,
      );
    }}
  />
);
