/* eslint-disable max-len */
import { GLOBAL_ROUTES, PATH_PREFIX_INSTANCE, PATH_PREFIX_RESOURCES } from '@localstack/constants';

export const AppRoute = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  GETTING_STARTED: '/getting-started',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  GLOBAL_SIGN_OUT: '/global-sign-out',
  SIGN_UP: '/sign-up',
  POST_SIGN_UP: '/sign-up-success',
  ACCEPT_TOS: '/accept-tos',

  ACCOUNT: '/account',
  ACCOUNT_AUTHENTICATION: '/account/authentication',

  WORKSPACE: '/workspace',
  WORKSPACE_MEMBERS: '/workspace/members',
  WORKSPACE_KEYS: '/workspace/api-keys',
  WORKSPACE_CI_KEYS: '/workspace/ci-keys',
  WORKSPACE_BILLING: '/workspace/billing',
  WORKSPACE_SUBSCRIPTIONS: '/workspace/subscriptions',
  WORKSPACE_SUBSCRIPTION_DETAIL: '/workspace/subscriptions/:subscriptionId',
  WORKSPACE_SUBSCRIPTION_UPDATE: '/workspace/subscriptions/:subscriptionId/update',
  WORKSPACE_AUTH_TOKEN: '/workspace/auth-token',
  WORKSPACE_MY_LICENSE: '/workspace/my-license',
  WORKSPACE_SSO_SETTINGS: '/workspace/sso',

  PRICING: '/pricing',
  PRICING_SUBSCRIBE: '/pricing/subscribe',

  QUICKSTART: '/quickstart',
  QUICKSTARTCLOUDFORMATION: '/quickstart-cloudformation',
  QUICKSTART_DEMO1: '/quickstart/demo1',
  QUICKSTART_DEMO2: '/quickstart/demo2',
  QUICKSTART_DEMO3: '/quickstart/demo3',
  QUICKSTART_DEMO4: '/quickstart/demo4',
  QUICKSTART_DEMO5: '/quickstart/demo5',

  RECOVER: '/recover',
  RECOVER_ACTIVATE: '/recover/:userId/:key',
  UNSUBSCRIBE_ACTIVATE: '/unsubscribe/:key',
  MEMBERSHIP_ACTIVATE: '/confirm/:org/:key',
  ACCOUNT_ACTIVATE: '/activate/:key',
  LAUNCHPAD: '/launchpad',
  NOTIFICATIONS: '/notifications',

  MARKETPLACE_ACTIVATE: '/auth/marketplace/:token',
  AUTH_REQUEST_ACTIVATE: '/auth/request/:requestId',
  SSO_CALLBACK: '/auth/sso/callback',
  SSO_START: '/auth/sso/:orgId/:idpName',

  PODS: '/pods',
  PODS_PUBLIC: '/pods/public',
  POD: '/pod/:name',
  POD_DIFF: '/pod/:name/diff',
  POD_LOAD: '/pod/:name/:version/load',

  EXTENSIONS_LIBRARY: '/extensions/library',
  EXTENSIONS_MANAGE: `${PATH_PREFIX_INSTANCE}/extensions/manage`,
  EXTENSIONS_DETAILS: `${PATH_PREFIX_INSTANCE}/extensions/details/:plugin_name`,
  EXTENSIONS_REMOTE: '/extensions/remote',
  EXTENSIONS_SUBMIT: '/extensions/submit',

  DESKTOP_DOWNLOAD: '/download',

  INSTANCES: '/instances',
  INSTANCES_EPHEMERAL: '/instances/ephemeral',

  CI_PROJECTS: '/ci',
  CI_PROJECT_CREATE: '/ci-create',
  CI_PROJECT: '/ci/:project',
  CI_PROJECT_SETTINGS: '/ci/:project/settings',
  CI_PROJECT_RUN: '/ci/:project/:run',

  // instance-specific routes below

  STATUS: `${PATH_PREFIX_INSTANCE}/status`,
  INSTANCE_STATE: `${PATH_PREFIX_INSTANCE}/state`,
  INSTANCE_LOGS: `${PATH_PREFIX_INSTANCE}/logs`,

  RESOURCES_COMMON: `${PATH_PREFIX_RESOURCES}/*`,
  RESOURCES_OVERVIEW: `${PATH_PREFIX_RESOURCES}`,

  IAM_POLICY_STREAM: '/policy-stream',

  ...GLOBAL_ROUTES,

  // admin panel
  ADMIN_HOME: '/admin',
  ADMIN_ACCOUNTS: '/admin/accounts',
  ADMIN_ACCOUNT: '/admin/accounts/:id',
  ADMIN_CREATE_ACCOUNT: '/admin/accounts/new',
  ADMIN_ORGANIZATIONS: '/admin/organizations',
  ADMIN_ORGANIZATION: '/admin/organizations/:id',
  ADMIN_ORGANIZATION_SUBSCRIBE: '/admin/organizations/:orgId/subscribe',
  ADMIN_ORGANIZATION_SUBSCRIPTION: '/admin/organizations/:orgId/subscriptions/:subscriptionId',
  ADMIN_ORGANIZATION_SUBSCRIPTION_UPDATE: '/admin/organizations/:orgId/subscriptions/:subscriptionId/update',
  ADMIN_EXTENSIONS: '/admin/extensions',
  ADMIN_EXTENSION: '/admin/extension/:id',
  ADMIN_COMMON: '/admin/*',
  ADMIN_ANNOUNCEMENT: '/admin/announcement/new',

  // stacks insights
  STACKS_STACK: '/stacks/:stackId',
  STACKS_OVERVIEW: '/stacks',

  // chaos engineering
  CHAOS_ENGINEERING: '/chaos-engineering',

  NOT_FOUND: '/not-found',
  SESSION_EXPIRED: '/session-expired',
};

export const externalURLs = {
  documentation: {
    root: 'https://docs.localstack.cloud',
    gettingStarted: {
      installation: 'https://docs.localstack.cloud/getting-started/installation/',
      api_key: 'https://docs.localstack.cloud/getting-started/api-key/',
    },
  },
  developerHub: {
    root: 'https://docs.localstack.cloud/developer-hub/',
  },
};
