import { ReactElement } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { ZoomOut as ZoomOutIcon } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { StackAwsApiCall } from '@localstack/types';

import {
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';

// eslint-disable-next-line import/no-unresolved
import { useChartProps, useZoomableChart } from '../../../chart';

export interface StatsChartProps {
  title: string;
  data: StackAwsApiCall[];
  showZoomOut?: boolean;
  onResetInterval?: () => unknown;
  onChangeInterval?: (start: string, end: string) => unknown;
}

export const StatsChart = ({
  title,
  data,
  showZoomOut,
  onResetInterval,
  onChangeInterval,
}: StatsChartProps): ReactElement => {
  const chartProps = useChartProps();

  const zommableChart = useZoomableChart({ type: 'datestring', onChangeInterval });

  const aggregatedData = data.reduce((memo, item) => ({
    ...memo,
    [item.server_time]: (memo[item.server_time as keyof typeof memo] ?? 0) + 1,
  }), {});

  const formattedData = Object.entries(aggregatedData).map(([time, events]) => ({ time, events }));

  return <>
    <Typography align='center'>
      {title}
    </Typography>
    {showZoomOut && (
      <Box position="relative">
        {/* FIXME: remove hardcoded zIndex and use theme or styles  */}
        <Box position="absolute" right={0} zIndex={123}>
          <IconButton onClick={onResetInterval} size="large"><ZoomOutIcon /></IconButton>
        </Box>
      </Box>
    )}
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={formattedData}
        syncId="stack-charts"
        {...zommableChart.props}
      >
        <CartesianGrid strokeDasharray="3 3" {...chartProps.cartesianGridProps} />
        <XAxis dataKey="time" minTickGap={20} {...chartProps.xAxisProps} />
        <YAxis yAxisId="events" {...chartProps.yAxisProps} />

        <Legend {...chartProps.legendProps} />
        <Tooltip {...chartProps.tooltipProps} />

        <Line yAxisId="events" type="monotone" dataKey="events" stroke={blue[400]} />

        {zommableChart.leftSelection && (
          <ReferenceArea yAxisId="events" x1={zommableChart.leftSelection} x2={zommableChart.rightSelection} />
        )}
      </LineChart>
    </ResponsiveContainer>
  </>;
};
