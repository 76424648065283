import { Dispatch, SetStateAction, createContext, useContext } from 'react';

type BaseLayoutInterface = {
  setResetFn: Dispatch<SetStateAction<(() => void) | null>>;
};

export const BaseLayoutContext = createContext<BaseLayoutInterface>({
  setResetFn: () => undefined,
});

export const useBaseLayoutProvider = (): BaseLayoutInterface => useContext(BaseLayoutContext);
