import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { ListTranscriptionJobsResponse } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type TranscriptionJobsTableProps = {
  jobs: ListTranscriptionJobsResponse;
  onViewTranscriptionJob: (name: string) => void;
  onSelect: (names: string[]) => void;
  loading?: boolean;
};

export const TranscriptionJobsTable = ({
  jobs,
  onViewTranscriptionJob,
  onSelect,
  loading,
}: TranscriptionJobsTableProps): ReactElement => (
  <MagicTable
    entry="TranscriptionJobSummaries"
    idAttribute="TranscriptionJobName"
    rows={jobs?.TranscriptionJobSummaries || []}
    schema={getSchema('TranscribeService')}
    externalFields={{
      TranscriptionJobName: ({ TranscriptionJobName }) => (
        <Link
          onClick={() => onViewTranscriptionJob(TranscriptionJobName || '')}
          underline="hover"
        >
          {TranscriptionJobName}
        </Link>
      ),
    }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
