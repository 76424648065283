import { useRoutes, useAwsGetter } from '@localstack/services';
import { Breadcrumbs, CloudTrailDetails } from '@localstack/ui';
import { Card, Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import { TrailsNavTabs } from './components/TrailsNavTabs';
import { CloudTrailProps } from './types';

export const CloudTrailTrail = ({
  Layout,
  clientOverrides,
  routes,
}: CloudTrailProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const { data: trail } = useAwsGetter(
    'CloudTrail',
    'getTrail',
    [{ Name: name as string }],
    { clientOverrides },
  );

  return (
    <>
      <Layout
        title={
          <Box>
            <Typography variant="h4">Trail Details</Typography>
            <Breadcrumbs
              mappings={[
                ['Trails', () => goto(routes.RESOURCES_CLOUDTRAIL_TRAILS)],
                [name, null],
              ]}
            />
          </Box>
        }
        tabs={<TrailsNavTabs routes={routes}/>}
      >
        <Card>
          <CloudTrailDetails trail={trail?.Trail}/>
        </Card>
      </Layout>
    </>
  );
};
