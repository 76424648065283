import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { DynamoDBTable } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type DynamoDBTablesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  tables: DynamoDBTable[];
  onViewTable?: (authorizer: DynamoDBTable) => unknown;
  onSelect?: (names: string[]) => void;
};

export const DynamoDBTablesTable = ({
  tables,
  selectable = true,
  loading,
  onViewTable,
  onSelect,
}: DynamoDBTablesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('DynamoDB')}
    entry="TableDescription"
    idAttribute="TableName"
    rows={tables}
    selectable={selectable}
    onSelect={onSelect}
    order={['TableName']}
    loading={loading}
    filterColumns={['TableName', 'TableStatus', 'TableArn']}
    externalFields={{
      TableName: (row) => (
        <Link
          onClick={() => {
            if (onViewTable) {
              onViewTable(row);
            }
          }}
          underline="hover"
        >
          {row.TableName}
        </Link>
      ),
    }}
  />
);
