import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwApiRouteRequest, UpdateAgwApiRouteRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwApiRouteForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiRouteUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId, routeId: RouteId } = useParams<'apiId' | 'routeId'>();

  const { createRoute, updateRoute, isLoading } = useAwsEffect(
    'ApiGatewayV2',
    ['createRoute', 'updateRoute'],
    {
      revalidate: ['getRoute', 'getRoutes'],
      clientOverrides,
    },
  );

  const { data: route } = useAwsGetter(
    'ApiGatewayV2',
    'getRoute',
    [{ ApiId, RouteId }],
    { clientOverrides },
  );

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const handleCreate = async (data: CreateAgwApiRouteRequest) => {
    await createRoute(data);
    goto(routes.RESOURCES_AGW2_API_ROUTES, { apiId: ApiId });
  };

  const handleUpdate = async (data: UpdateAgwApiRouteRequest) => {
    await updateRoute(data);
    goto(routes.RESOURCES_AGW2_API_ROUTE, { apiId: ApiId, routeId: RouteId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Route Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [route?.RouteKey, () => goto(routes.RESOURCES_AGW2_API_ROUTE, { apiId: ApiId, routeId: RouteId })],
              [RouteId ? 'Update' : 'Create Route', null],
            ]}
          />
        </Box>
      }
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwApiRouteForm
            clientOverrides={clientOverrides}
            isWebsocket={api?.ProtocolType === 'WEBSOCKET'}
            apiId={ApiId as string}
            route={route}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
