import { ReactElement } from 'react';
import { AppSyncFunction } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AppSyncFunctionDetailsProps {
  func?: Optional<Partial<AppSyncFunction>>;
}

export const AppSyncFunctionDetails = ({ func }: AppSyncFunctionDetailsProps): ReactElement => (
  <MagicDetails
    data={func}
    schema={getSchema('AppSync')}
    entry="FunctionConfiguration"
    title="Function Details"
  />
);
