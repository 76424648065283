import { ReactElement } from 'react';
import { SNSTopic } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type SNSTopicTableProps = {
  selectable?: boolean;
  loading?: boolean;
  entries: SNSTopic[];
  onSelect?: (names: string[]) => void;
  onViewSNSTopic?: (queue: SNSTopic) => void;
};

export const SNSTopicTable = ({
  entries,
  selectable = true,
  loading,
  onSelect,
  onViewSNSTopic,
}: SNSTopicTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('SNS')}
    entry="Topic"
    idAttribute="TopicArn"
    rows={entries}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      TopicArn: (row) => (
        <Link onClick={() => onViewSNSTopic && onViewSNSTopic(row)} underline="hover">
          {row.TopicArn}
        </Link>
      ),
    }}
  />
);
