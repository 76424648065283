import { useState, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { Plan, Product, Subscription, Discount, Organization, OrderContext } from '@localstack/types';
import { CheckoutForm, CheckoutFormSkeleton } from '@localstack/ui';
import { VAT_COUNTRIES } from '@localstack/constants';

import {
  useRoutes,
  useApiGetter,
  useApiEffect,
  SubscriptionService,
  AdminService,
  UserService,
} from '@localstack/services';

import { AdminLayout } from '~/layouts';
import { AppRoute } from '~/config';
import { StripeCardForm } from '~/components';

export const Subscribe = (): ReactElement => {
  const {
    orgId,
    subscriptionId,
  } = useParams<'orgId' | 'subscriptionId'>() as {
    orgId: string,
    subscriptionId?: string,
  };

  const { goto } = useRoutes();

  const [promotion, setPromotion] = useState<Optional<Discount>>();
  const [seats, setSeats] = useState<number>(1);
  const [plan, setPlan] = useState<Optional<Plan>>(null);

  const [stripeLoading, setStripeLoading] = useState(false);
  const [showCardForm, setShowCardForm] = useState(false);

  const { data: organization, isLoading: isOrgLoading } = useApiGetter(AdminService, 'getOrganization', [orgId]);
  const { data: resources, isLoading: isResourcesLoading } = useApiGetter(AdminService, 'listResources', []);
  const { data: keys, isLoading: isKeysLoading } = useApiGetter(AdminService, 'listOrganizationKeys', [orgId]);
  const { getPromotion, isLoading: isPromotionLoading } = useApiEffect(SubscriptionService, ['getPromotion']);

  const { data: subscription, isLoading: isSubLoading } = useApiGetter(
    AdminService,
    'getOrganizationSubscription',
    [orgId, subscriptionId ?? ''],
    { enable: !!subscriptionId },
  );

  const { removeOrganizationKey, isLoading: isKeysMutating } = useApiEffect(
    AdminService,
    ['removeOrganizationKey'],
    { revalidate: ['listOrganizationKeys'] },
  );

  const { attachOrganizationCard, removeOrganizationCard, isLoading: isCreditCardMutating } = useApiEffect(
    AdminService,
    ['attachOrganizationCard', 'removeOrganizationCard'],
    { revalidate: ['listOrganizationCards'] },
  );

  const { createOrganizationSubscription, updateOrganizationSubscription, isLoading: isSubMutating } = useApiEffect(
    AdminService,
    ['createOrganizationSubscription', 'updateOrganizationSubscription'],
    {
      revalidate: [
        'listOrganizationSubscriptions',
        'getOrganizationSubscription',
        'listOrganizationLicenseAssignments',
      ],
    },
  );

  const { updateOrganization, isLoading: isOrgMutating } = useApiEffect(
    AdminService,
    ['updateOrganization'],
    { revalidate: ['getOrganization'], revalidateOtherClient: { client: UserService, methods: ['getUser'] } },
  );

  const { data: tax, isLoading: isTaxLoading } = useApiGetter(
    AdminService,
    'getOrganizationPlanTax',
    [orgId, plan?.id ?? '', seats],
    {
      defaultValue: null,
      enable: !!(plan && organization?.country && VAT_COUNTRIES.includes(organization.country)),
    },
  );

  const subscriptionKeys = (keys ?? []).filter((k) => k.subscription_id === subscriptionId && !k.is_ci && !k.deleted);
  const isPageLoading = isResourcesLoading || (subscriptionId && isSubLoading);

  const isLoading = isResourcesLoading
    || (subscriptionId && isKeysLoading)
    || isOrgLoading
    || isOrgMutating
    || isKeysMutating;

  const { data: cards } = useApiGetter(AdminService, 'listOrganizationCards', [orgId]);

  const onAddNewCard = async (cardId: string, currency: string) => {
    await attachOrganizationCard(orgId, { token: cardId, currency });
  };

  const onApplyPromoCode = async (planId: string, code: string) => {
    setPromotion(code ? await getPromotion(planId, code) : undefined);
  };

  const onSubscribe = async (
    selectedPlan: Plan,
    products: Product[],
    context: OrderContext,
    sub: Optional<Subscription>,
    discount: Optional<Discount>,
  ) => {
    if (sub) {
      const updatedSubscription = await updateOrganizationSubscription(
        orgId,
        sub.id,
        {
          plan: selectedPlan.id,
          products: products.map((p) => p.id),
          discount: discount?.id,
          ...context,
        },
      );

      return goto(
        AppRoute.ADMIN_ORGANIZATION_SUBSCRIPTION,
        { orgId, subscriptionId: updatedSubscription.id },
      );
    }

    const createdSubscription = await createOrganizationSubscription(
      orgId,
      {
        plan: selectedPlan.id,
        products: products.map((p) => p.id),
        discount: discount?.id,
        ...context,
      },
    );

    return goto(
      AppRoute.ADMIN_ORGANIZATION_SUBSCRIPTION,
      { orgId, subscriptionId: createdSubscription.id },
    );
  };

  return (
    <AdminLayout title={subscriptionId ? 'Update Subscription' : 'Create Subscription'}>
      {isPageLoading && (
        <CheckoutFormSkeleton />
      )}
      {!isPageLoading && (
        <CheckoutForm
          adminMode
          adminDiscounts={resources?.discounts ?? []}
          adminProducts={resources?.products ?? []}
          loading={isLoading}
          isSubmitting={isSubMutating}
          taxLoading={isTaxLoading}
          tax={tax}
          plans={resources?.plans ?? []}
          cards={cards ?? []}
          subscription={subscription}
          subscriptionKeys={subscriptionKeys}
          organization={organization as Organization}
          promotion={promotion}
          promotionLoading={isPromotionLoading}
          showCardForm={showCardForm}
          onChangeSeats={setSeats}
          onChangePlan={setPlan}
          onToggleCardForm={() => setShowCardForm(!showCardForm)}
          onApplyPromoCode={onApplyPromoCode}
          onUpdateOrganization={(org) => updateOrganization(orgId, org)}
          onDeleteCard={(card) => removeOrganizationCard(orgId, card.id)}
          onDeleteApiKey={(keyId) => removeOrganizationKey(orgId, keyId)}
          onSubscribe={onSubscribe}
          renderCardForm={(currency: string) => (
            <Box p={2}>
              <StripeCardForm
                loading={stripeLoading || isCreditCardMutating}
                onSaveCard={async (cardId) => {
                  try {
                    setStripeLoading(true);
                    await onAddNewCard(cardId, currency);
                    setShowCardForm(false);
                  } finally {
                    setStripeLoading(false);
                  }
                }}
              />
            </Box>
          )}
        />
      )}
    </AdminLayout>
  );
};
