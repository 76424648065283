import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { EC2Instance } from '@localstack/types';
import { Card, Button, MenuItem } from '@mui/material';
import { Dropdown, ConfirmableButton, EC2InstancesTable, PageTitle } from '@localstack/ui';
import { DEFAULT_EC2_ROUTES } from '@localstack/constants';

import { EC2Props } from './types';
import { NavTabs } from './components/NavTabs';

export const EC2Instances = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EC2_ROUTES,
}: EC2Props): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: instances, isLoading, mutate } = useAwsGetter(
    'EC2',
    'describeInstances',
    [],
    { clientOverrides },
  );

  const ec2Instances = instances?.Reservations?.map(
    (res) => res.Instances as EC2Instance[],
  )?.filter(Boolean)?.flat() ?? [];

  const { stopInstances, terminateInstances, startInstances } = useAwsEffect(
    'EC2',
    ['stopInstances', 'terminateInstances', 'startInstances'],
    { revalidate: ['describeInstances'], clientOverrides },
  );

  const handleStopSelected = useCallback(async () => {
    await stopInstances({ InstanceIds: selectedIds });
  }, [selectedIds]);

  const handleTerminateSelected = useCallback(async () => {
    const runningInstancesIds = selectedIds.filter(
      id => ec2Instances.find(instanceId => instanceId.InstanceId === id)?.State?.Name === 'running',
    );
    await terminateInstances({ InstanceIds: runningInstancesIds });
  }, [selectedIds]);

  const handleStartSelected = useCallback(async () => {
    await startInstances({ InstanceIds: selectedIds });
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="EC2: Instances"
      title={
        <PageTitle
          title="EC2 Instances"
          onMutate={mutate}
        />
      }
      tabs={<NavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_EC2_INSTANCE_NEW)}>
            Launch Instance
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Stop ${selectedIds.length} Instance(s)?`}
              onClick={handleStopSelected}
              text="Selected Instances will be stopped"
            >
              Stop Selected
            </ConfirmableButton>
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Terminate ${selectedIds.length} Instance(s)?`}
              onClick={handleTerminateSelected}
              text="Selected Instances will be terminated"
            >
              Terminate Selected
            </ConfirmableButton>
            <MenuItem
              disabled={selectedIds.length === 0 || isLoading}
              onClick={handleStartSelected}
            >
              Start Selected
            </MenuItem>
          </Dropdown>
        </>
      }
    >
      <Card>
        <EC2InstancesTable
          instances={ec2Instances}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewInstance={({ InstanceId }) => goto(routes.RESOURCES_EC2_INSTANCE, { id: InstanceId })}
        />
      </Card>
    </Layout>
  );
};
