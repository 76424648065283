import { ReactElement, FunctionComponent } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Avatar } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      background: 'transparent',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  }),
);

type Props = {
  Icon: FunctionComponent<any>; // eslint-disable-line
};

export const WidgetIcon = ({ Icon }: Props): ReactElement => {
  const classes = useStyles();

  return <Avatar className={classes.avatar}><Icon/></Avatar>;
};

export default WidgetIcon;
