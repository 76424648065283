import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { Card } from '@mui/material';
import { DMSReplicationTaskTableStatsTable, PageTitle } from '@localstack/ui';
import { useParams } from 'react-router-dom';

import { DMSProps } from './types';
import { ReplicationTaskTabs } from './components/ReplicationTaskTabs';

export const DMSReplicationTaskTableStats = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DMS_ROUTES,
}: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: tableStats, isLoading, mutate } = useAwsGetter(
    'DMS',
    'describeTableStatistics',
    [{ ReplicationTaskArn: id }],
    { clientOverrides },
  );
  return (
    <Layout
      title={
        <PageTitle title="DMS Table Statistics" onMutate={mutate}
          breadcrumbs={[
            ['DMS', () => goto(routes.RESOURCES_DMS_REPLICATION_INSTANCES)],
            [id, () => goto(routes.RESOURCES_DMS_REPLICATION_TASK, { id })],
            ['Table Statistics', null],
          ]}
        />}
      tabs={<ReplicationTaskTabs routes={routes} replicationTaskId={id || ''} />}
    >
      <Card>
        <DMSReplicationTaskTableStatsTable
          tableStats={tableStats?.TableStatistics || []}
          loading={isLoading}
        />
      </Card>
    </Layout>
  );
};
