import { ReactElement } from 'react';

import { CustomerLayout } from '~/layouts';

import { SSOSettings } from './components';

export const SingleSignOnSettings = (): ReactElement => (
  <CustomerLayout
    title="Single Sign-on Settings"
  >
    <SSOSettings />
  </CustomerLayout>
);
