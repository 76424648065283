import { DEFAULT_DOCDB_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, DocDBInstanceDetail } from '@localstack/ui';

import { DocDBProps } from './types';

export const DocDBInstance = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DOCDB_ROUTES,
}: DocDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: instanceData } = useAwsGetter(
    'DocDB',
    'describeDBInstances',
    [{ DBInstanceIdentifier: id }],
    {
      clientOverrides,
      swrOverrides: { revalidateOnMount: true },
    },
  );
  const instance = instanceData?.DBInstances?.filter((c) => c.DBInstanceIdentifier === id)[0];
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Instance Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DocDB', () => goto(routes.RESOURCES_DOCDB_INSTANCES)],
              [id, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_DOCDB_INSTANCE_UPDATE, { id })}
        >
          Edit Instance
        </Button>
      }
    >
      <Card>
        <DocDBInstanceDetail instance={instance} />
      </Card>
    </Layout>
  );
};
