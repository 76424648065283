import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { SSMPutRequest } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, SystemParameterForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_SYSTEMPARAMETERS_ROUTES } from '@localstack/constants/src';

import { SystemParametersProps } from './types';

export const SystemParameterUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SYSTEMPARAMETERS_ROUTES,
}: SystemParametersProps): ReactElement => {
  const { goto } = useRoutes();

  const { putParameter, isLoading } = useAwsEffect(
    'SSM',
    ['putParameter'],
    { revalidate: ['describeParameters', 'getParameter'],clientOverrides },
  );

  const handleCreate = async (data: SSMPutRequest) => {
    await putParameter(data);
    goto(routes.RESOURCES_SYSTEM_PARAMETERS);
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Table Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SSM', () => goto(routes.RESOURCES_SYSTEM_PARAMETERS)],
              ['Create Parameter', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SystemParameterForm
            onCreate={handleCreate}
            clientOverrides={clientOverrides}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
