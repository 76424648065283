import { useCallback, ReactElement } from 'react';
import { Controller, Control, ControllerProps, ControllerRenderProps } from 'react-hook-form';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Delete as DeleteIcon } from '@mui/icons-material';
import Dropzone from 'react-dropzone';
import classnames from 'classnames';

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {},
  fullWidth: {
    width: '100%',
  },
  dropzone: {
    textAlign: 'center',
    cursor: 'pointer',
    border: `${theme.spacing(.3)} dashed ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& .dropzone': {
      padding: theme.spacing(2),
    },
  },
  error: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
  },
}));

type Props = Omit<ControllerProps, 'control' | 'render'> & {
  control: Control<any>; // eslint-disable-line
  fullWidth?: boolean;
  accept?: string;
  multiple?: boolean;
  maxFiles?: number;
  label?: string;
};

export const ControlledDropzone = ({
  control,
  accept,
  fullWidth,
  multiple = false,
  maxFiles = 1,
  label = 'Drag & drop files, or click to select files',
  ...rest
}: Props): ReactElement => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDrop = (field: ControllerRenderProps<any, string>) => useCallback((files: File[]) => {
    const value = multiple ? Array.from(files) : files[0];
    field.onChange({ type: 'change', target: { name: field.name, value } });
  }, [multiple]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRemoveFile = useCallback((field: ControllerRenderProps<any, string>, file: File) => {
    const value = multiple ? (field.value as File[]).filter((f) => f !== file) : undefined;
    field.onChange({ type: 'change', target: { name: field.name, value } });
  }, [multiple]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getFiles = (field: ControllerRenderProps<any, string>): File[] => {
    const multipleValue = field.value ?? [];
    const singularValue = field.value ? [field.value] : [];
    return Array.from(multiple ? multipleValue : singularValue);
  };

  return (
    <Controller
      {...rest}
      control={control}
      render={({ field, fieldState }) => (
        <div
          className={classnames(classes.root, {
            [classes.fullWidth]: fullWidth,
          })}
        >
          <div
            className={classnames(classes.dropzone, {
              [classes.error]: fieldState.invalid,
            })}
          >
            <Dropzone onDrop={handleDrop(field)} accept={accept} maxFiles={maxFiles}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <p>{fieldState.error?.message ?? label}</p>
                </div>
              )}
            </Dropzone>
          </div>
          <List dense>
            {getFiles(field).map((file) => (
              <ListItem key={file.name}>
                <ListItemText primary={file.name} />
                <ListItemSecondaryAction>
                  <IconButton size="small" onClick={() => handleRemoveFile(field, file)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>
      )}
    />
  );
};
