import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { StateMachineItem } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface StateMachinesTableProps {
  stateMachines: StateMachineItem[];
  loading?: boolean;
  selectable?: boolean;
  onSelect?: (names: string[]) => void;
  onViewStateMachine?: (sm: StateMachineItem) => unknown;
}

export const StateMachinesTable = ({
  stateMachines,
  loading,
  onViewStateMachine,
  selectable = true,
  onSelect,
}: StateMachinesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('StepFunctions')}
    entry="StateMachineListItem"
    idAttribute="stateMachineArn"
    rows={stateMachines}
    order={['name','type']}
    loading={loading}
    selectable={selectable}
    onSelect={onSelect}
    externalFields={{
      name: (row) => (
        <Link
          onClick={() => onViewStateMachine && onViewStateMachine(row)}
          underline="hover"
        >
          {row.name}
        </Link>
      ),
    }}
  />
);
