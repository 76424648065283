import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { EventBridgeRoutes } from '../../types';

type Props = {
  routes: EventBridgeRoutes;
  busName: string;
}

export const BusTabs = ({ routes, busName }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_EVENT_BRIDGE_RULES, { busName })}>
      Event Rules
    </NavLink>
  </>
);
