import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AgwRestApiStage } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiStagesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  stages: AgwRestApiStage[];
  onViewStage?: (stage: AgwRestApiStage) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AgwRestApiStagesTable = ({
  stages,
  selectable = true,
  loading,
  onViewStage,
  onSelect,
}: AgwRestApiStagesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="Stage"
    idAttribute="stageName"
    rows={stages}
    selectable={selectable}
    onSelect={onSelect}
    order={['stageName']}
    loading={loading}
    externalFields={{
      stageName: (row) => (
        <Link onClick={() => onViewStage && onViewStage(row)} underline="hover">
          {row.stageName}
        </Link>
      ),
    }}
  />
);
