import { ProductFeature } from '@localstack/types';

/**
 * Extracts feature tuple from product string, which has a format of `name/variant/version`
 * @param product Product (feature) string to convert into tuple
 */
export const extractFeatureTuple = (product: string): [ProductFeature, string, string] => {
  const parts = product.split('/');

  if (parts.length !== 3) {
    throw new Error(`Invalid product string: ${product}`);
  }

  return [parts[0] as ProductFeature, parts[1] as string, parts[2] as string];
};
