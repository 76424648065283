import { ReactElement } from 'react';
import { SQSMessage } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export type SQSMessageDetailsProps = {
  message: Optional<SQSMessage>;
};

export const SQSMessageDetails = ({ message }: SQSMessageDetailsProps): ReactElement => (
  <MagicDetails
    data={message}
    schema={getSchema('SQS')}
    entry="Message"
    title="Message Details"
  />
);
