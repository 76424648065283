import { ReactElement } from 'react'; 
import { SNSSubscriptionAttributes as SNSSubscriptionAttributesType } from '@localstack/types';

import { MagicDetails } from '../../../magic/MagicDetails';

export type SNSSubscriptionAttributesProps = {
  attributes: Optional<SNSSubscriptionAttributesType>;
};

const SCHEMA = {
  shapes: {
    SubscriptionAttributesMap: {
      type: 'structure',
      members: {
        ConfirmationWasAuthenticated: { type: 'boolean' },
        DeliveryPolicy: { type: 'string' },
        EffectiveDeliveryPolicy: { type: 'string' },
        FilterPolicy: { type: 'string' },
        Owner: { type: 'string' },
        PendingConfirmation: { type: 'boolean' },
        RawMessageDelivery: { type: 'boolean' },
        RedrivePolicy: { type: 'string' },
        SubscriptionArn: { type: 'string' },
        TopicArn: { type: 'string' },
        SubscriptionRoleArn: { type: 'string' },
      },
    },
  },
};

export const SNSSubscriptionAttributes = ({ attributes }: SNSSubscriptionAttributesProps): ReactElement => (
  <MagicDetails
    data={attributes}
    schema={SCHEMA}
    entry="SubscriptionAttributesMap"
    title="Subscription Attributes"
  />
);
