import { getSchema } from '@localstack/services';
import {
  AppConfigApplication,
  AppConfigCreateApplicationRequest,
  AppConfigUpdateApplicationRequest,
} from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type AppConfigApplicationFormProps = {
  application?: Optional<Partial<AppConfigApplication>>;
  formId: string;
  onSubmit: (data: AppConfigCreateApplicationRequest | AppConfigUpdateApplicationRequest) => void;
  loading?: boolean;
};

export const AppConfigApplicationForm = ({
  application,
  formId,
  onSubmit,
  loading,
}: AppConfigApplicationFormProps): ReactElement => (
  <MagicForm
    entry={application ? 'UpdateApplicationRequest' : 'CreateApplicationRequest'}
    schema={getSchema('AppConfig')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={application}
    fieldConditions={{ Id: false }}
  />
);
