import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { PutEventBridgeRuleRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, EventBridgeRuleForm, Breadcrumbs } from '@localstack/ui';
import { useParams } from 'react-router-dom';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';
import { ReactElement } from 'react';

import { EventBridgeProps } from './types';

export const EventBridgeRuleUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { goto } = useRoutes();
  const { busName } = useParams<'busName'>();
  const { ruleName } = useParams<'ruleName'>();

  const { data: rule } = useAwsGetter(
    'EventBridge',
    'describeRule',
    [{ Name: ruleName, EventBusName: busName }],
    { clientOverrides },
  );

  const { putRule, isLoading } = useAwsEffect(
    'EventBridge',
    ['putRule'],
    { revalidate: ['listRules', 'describeRule'], clientOverrides },
  );

  const handleCreateOrUpdate = async (data: PutEventBridgeRuleRequest) => {
    if (await putRule(data)) {
      goto(routes.RESOURCES_EVENT_BRIDGE_RULES, { busName });
    }
  };

  const isUpdate = !!ruleName;
  const action = isUpdate ? 'Update' : 'Create';

  return (
    <Layout
      documentTitle={`EventBridge: ${action} Rule`}
      title={
        <Box>
          <Typography variant="h4">{action} Rule</Typography>
          <Breadcrumbs
            mappings={[
              ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
              ['Buses', () => goto(routes.RESOURCES_EVENT_BRIDGE_BUSES)],
              [busName, () => goto(routes.RESOURCES_EVENT_BRIDGE_BUS, { busName })],
              ['Rules', () => goto(routes.RESOURCES_EVENT_BRIDGE_RULES, { busName })],
              [ruleName, () => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { ruleName, busName })],
              [`${action} Event Rule`, null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <EventBridgeRuleForm
            bus={busName || ''}
            rule={rule}
            clientOverrides={clientOverrides}
            onCreate={handleCreateOrUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
