import { StackInfo } from '@localstack/types';
import { Box, Grid, Theme , Alert, Skeleton, Link } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';

import { MARKER_IDS, TestMarkerSpan } from '@localstack/services';

import { DecorativeDivider } from '../../../display/DecorativeDivider';
import { StackCard } from '../StackCard';

const useStyles = makeStyles((theme: Theme) => createStyles({
  listSummary: {
    fontSize: '14px',
    textAlign: 'center',
    color: theme.palette.text.disabled,
  },
}));

export interface StackListSkeletonProps {
  size?: 'md' | 'lg';
}

export const StackListSkeleton = ({ size = 'md' }: StackListSkeletonProps): ReactElement => {
  const height: number = size === 'md' ? 172 : 124;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <DecorativeDivider />
      <Grid item xs={12}>
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
    </Grid>
  );
};

export interface StackListProps {
  activeStack?: StackInfo;
  selectedStacks?: string[];
  historyStacks?: StackInfo[];
  size?: 'md' | 'lg';
  totalNumberOfStacks?: number;
  noActiveStackError?: ReactElement | null;
  onClick?: (session_id: string) => void;
  disableHistory?: boolean;
  stacksRoute?: string;
}

export const StackList = ({
  activeStack,
  historyStacks = [],
  totalNumberOfStacks,
  size = 'lg',
  selectedStacks,
  noActiveStackError = null,
  disableHistory,
  stacksRoute,
  ...rest
}: StackListProps): ReactElement => {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {activeStack
          ?
          <TestMarkerSpan name={MARKER_IDS.STACK_WIDGET_1}>
            <StackCard
              stackInfo={activeStack}
              running
              size={size}
              selected={selectedStacks?.includes(activeStack.session_id)}
              {...rest}
            />
          </TestMarkerSpan>
          :
          (noActiveStackError || <Alert severity='info'>
            No LocalStack instance running - please start LocalStack Pro or update it to version 1.1 or higher
          </Alert>)
        }
      </Grid>
      {historyStacks.length > 0 && <DecorativeDivider />}
      {historyStacks.filter(stack => stack.session_id !== activeStack?.session_id || '').map(stack => (
        <Grid item xs={12} key={stack.session_id}>
          <TestMarkerSpan name={MARKER_IDS.STACK_WIDGET_2}>
            <StackCard
              stackInfo={stack}
              size={size}
              selected={selectedStacks?.includes(stack.session_id)}
              {...rest}
            />
          </TestMarkerSpan>
        </Grid>
      ))}
      {totalNumberOfStacks !== undefined && (
        <Grid item xs={12}>
          <Box mt={2} className={classes.listSummary}>
            {disableHistory ? (
              <>
                Explore previous runs in <Link href={stacksRoute}>Stack Insights</Link>
              </>
            ) : (
              <>
                Showing {historyStacks.length} out of {totalNumberOfStacks} past stacks
              </>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
