import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import { CreateNDBInstance, NeptuneInstance, UpdateNDBInstance } from '@localstack/types';

import { DEFAULT_NEPTUNE_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

import { MagicForm } from '../../magic/MagicForm';

export interface NeptuneInstanceFormProps {
  instance?: Optional<Partial<NeptuneInstance>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateNDBInstance) => unknown;
  onUpdate: (data: UpdateNDBInstance) => unknown;
}

export const NeptuneInstanceForm = ({
  instance,
  loading,
  formId,
  onCreate,
  onUpdate,
}: NeptuneInstanceFormProps): ReactElement => (
  <MagicForm
    data={instance}
    schema={getSchema('Neptune')}
    loading={loading}
    entry="CreateDBInstanceMessage"
    formId={formId}
    externalFields={{
      '^DBClusterIdentifier$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='RDS' method='describeDBClusters' arrayKeyName='DBClusters' property='DBClusterIdentifier'
          fieldName={fieldName} entityName='DB Cluster'
          creationRoute={DEFAULT_NEPTUNE_ROUTES.RESOURCES_NEPTUNE_CLUSTER_CREATE}
          required={required}
        />
      ),
    }}
    onSubmit={(data: CreateNDBInstance) => {
      if (!instance) return onCreate(data);
      return onUpdate({ ...data, DBInstanceIdentifier: instance.DBInstanceIdentifier as string });
    }}
  />
);
