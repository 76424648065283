import { ReactElement } from 'react';
import { OrganizationMember, MembershipState as MembershipStateEnum } from '@localstack/types';
import { MembershipState } from '@localstack/ui';
import {
  Delete as DeleteIcon,
  DeleteForever as DeleteForeverIcon,
  Edit as EditIcon,
  InfoOutlined as InfoIcon,
} from '@mui/icons-material';

import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Box,
  Typography,
  Tooltip,
  IconButton,
  Link,
  Chip,
} from '@mui/material';

export interface MembersListProps {
  members: OrganizationMember[];
  loading?: boolean;
  onShowMember?: (membershipId: string) => unknown;
  onEditMember?: (membershipId: string) => unknown;
  onRemoveMember?: (membershipId: string, forever?: boolean) => unknown;
}

// Invitation tokens are md5 encoded
const INVITATION_TOKEN_LENGTH = 32;

const isInvitationToken = (idOrToken: string): boolean =>
  idOrToken.length === INVITATION_TOKEN_LENGTH;

export const MembersList = ({
  members,
  loading,
  onShowMember,
  onEditMember,
  onRemoveMember,
}: MembersListProps): ReactElement => (
  <List>
    {members.map((membership) => (
      <ListItem key={membership.id}>
        <ListItemAvatar>
          <Box mr={2}>
            <Typography component="div">
              <MembershipState state={membership.state} />
            </Typography>
          </Box>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box component="span" display="flex" alignItems="center">
              {membership.state === MembershipStateEnum.ACTIVE ? (
                <Link onClick={() => onShowMember?.(membership.id)} underline="hover">
                  {membership.name}
                </Link>
              ) : (
                <>{membership.name}</>
              )}
              {isInvitationToken(membership.id) && (
                <Box component="span" ml={1}>
                  <Tooltip
                    title="User has not yet completed the sign-up process."
                    placement='top'
                  >
                    <Chip variant='outlined'
                      color="primary"
                      size="small"
                      label="Awaiting sign-up"
                      icon={<InfoIcon/>}
                    />
                  </Tooltip>
                </Box>
              )}
              {membership.try_assign_license_on_join && (
                <Box component="span" ml={1}>
                  <Tooltip
                    title="A license will be assigned to this user once they join this workspace
                    (subject to availability)"
                    placement='top'
                  >
                    <Chip
                      variant='outlined'
                      color="primary"
                      size="small"
                      label="Assign license on join"
                      icon={<InfoIcon/>}
                    />
                  </Tooltip>
                </Box>
              )}
            </Box>
          }
          secondary={`${membership.email} (${membership.roles.join(', ')})`}
        />
        <ListItemSecondaryAction>
          {!isInvitationToken(membership.id) && (
            <Tooltip title="Edit Member">
              <IconButton
                size="small"
                color="primary"
                onClick={() => onEditMember?.(membership.id)}
                disabled={loading}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {[MembershipStateEnum.ACTIVE, MembershipStateEnum.INVITED].includes(membership.state) && (
            <Tooltip title="Mark as deleted">
              <IconButton
                size="small"
                color="primary"
                onClick={() => onRemoveMember?.(membership.id)}
                disabled={loading}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete forever">
            <IconButton
              size="small"
              color="primary"
              onClick={() => onRemoveMember?.(membership.id, true)}
              disabled={loading}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
);
