import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { ExecutionItem } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface StateMachineExecutionsTableProps {
  executions: ExecutionItem[];
  selectable?: boolean;
  onSelect?: (names: string[]) => void;
  loading?: boolean;
  onViewExecution?: (execution: ExecutionItem) => unknown;
}

export const StateMachineExecutionsTable = ({
  executions,
  loading,
  onViewExecution,
  selectable = true,
  onSelect,
}: StateMachineExecutionsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('StepFunctions')}
    entry="ExecutionListItem"
    idAttribute="executionArn"
    rows={executions}
    order={['name', 'status', 'startDate', 'stopDate']}
    loading={loading}
    selectable={selectable}
    onSelect={onSelect}
    externalFields={{
      name: (execution) => (
        <Link
          onClick={() => onViewExecution && onViewExecution(execution)}
          underline="hover"
        >
          {execution.name}
        </Link>
      ),
    }}
  />
);
