import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  AgwApiRoutesTable,
} from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiRoutes = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { apiId: ApiId } = useParams<'apiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { goto } = useRoutes();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const { data: apiRoutes, isLoading, mutate } = useAwsGetter(
    'ApiGatewayV2',
    'getRoutes',
    [{ ApiId }],
    { clientOverrides },
  );

  const { deleteRoute } = useAwsEffect(
    'ApiGatewayV2',
    ['deleteRoute'],
    {
      revalidate: ['getRoute', 'getRoutes'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((RouteId) => deleteRoute({ ApiId: ApiId as string, RouteId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Routes"
      title={
        <PageTitle
          title="API Routes"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
            [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
            ['Routes', null],
          ]}
        />
      }
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW2_API_ROUTE_CREATE, { apiId: ApiId })}>
            Create Route
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Route(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Routes will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwApiRoutesTable
          routes={apiRoutes?.Items || []}
          onSelect={setSelectedIds}
          onViewRoute={
            ({ RouteId }) => goto(
              routes.RESOURCES_AGW2_API_ROUTE,
              { apiId: ApiId, routeId: RouteId },
            )
          }
        />
      </Card>
    </Layout>
  );
};
