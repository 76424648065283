import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { refineSqsUrl, useAwsEffect, useAwsGetter, useRoutes, useEndpoint } from '@localstack/services';
import { SendSQSMessageRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, SQSSendForm } from '@localstack/ui';

import { DEFAULT_SQS_ROUTES } from '@localstack/constants';

import { SQSProps } from './types';

export const SQSMessageSend = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SQS_ROUTES,
}: SQSProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const { endpoint } = useEndpoint('SQS');

  const { sendMessage, isLoading } = useAwsEffect(
    'SQS',
    ['sendMessage'],
    { revalidate: ['receiveMessage'], clientOverrides },
  );

  const { data: queues, isLoading: isListLoading } = useAwsGetter('SQS', 'listQueues', [], { clientOverrides });
  const refinedQueues = queues?.QueueUrls?.map((q) => refineSqsUrl(q, clientOverrides?.endpoint ?? endpoint));

  const handleSendMessage = async (data: SendSQSMessageRequest) => {
    await sendMessage(data);
    if (name) {
      goto(routes.RESOURCES_SQS_QUEUE_MESSAGES, { name });
    } else {
      goto(routes.RESOURCES_SQS_QUEUES);
    }
  };

  return (
    <Layout
      documentTitle="Subscription Details"
      title={
        <Box>
          <Typography variant="h4">Send Message</Typography>
          <Breadcrumbs
            mappings={[
              ['SQS', () => goto(routes.RESOURCES_SQS_QUEUES)],
              [name, () => goto(routes.RESOURCES_SQS_QUEUE, { name })],
              ['Send Message', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="SendMessage"
          variant="outlined"
          color="primary"
          loading={isLoading}
          disabled={isListLoading && !!name}
        >
          Send
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SQSSendForm
            queueUrls={refinedQueues || []}
            onSubmit={handleSendMessage}
            formId="SendMessage"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="SendMessage"
            variant="contained"
            color="primary"
            loading={isLoading}
            disabled={isListLoading && !!name}
          >
            Send
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
