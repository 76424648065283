import { ReactElement } from 'react';

import { Tooltip } from '@mui/material';
import { Dvr } from '@mui/icons-material';

import awscli from './icons/awscli.svg';
import Terraform from './icons/terraform.svg';
import aws_sdk_nodejs from './icons/aws-sdk-nodejs.svg';
import docker from './icons/docker.svg';
import Boto3 from './icons/Boto3.svg';
import python_requests from './icons/python-requests.svg';
import aws_sdk_go from './icons/aws-sdk-go.svg';
// import aws_sdk_java from './icons/aws-sdk-java.svg';
import aws_sdk_js from './icons/aws-sdk-js.svg';
import aws_sdk_php from './icons/aws-sdk-php.svg';
import aws_sdk_ruby from './icons/aws-sdk-ruby3.svg';
import aws_sdk_dotnet from './icons/aws-sdk-dotnet.svg';
import curl from './icons/curl.svg';
import Pulumi from './icons/Pulumi.svg';


const ICONS_MAP = {
  'aws-cli': awscli,
  // mozilla,
  'aws-sdk-nodejs': aws_sdk_nodejs,
  docker,
  'python-requests': python_requests,
  Boto3,
  Terraform,
  // 'aws-sdk-java': aws_sdk_java,
  'aws-sdk-js': aws_sdk_js,
  'aws-sdk-go': aws_sdk_go,
  'aws-sdk-go-v2': aws_sdk_go,
  'aws-sdk-php': aws_sdk_php,
  'aws-sdk-ruby3': aws_sdk_ruby,
  'aws-sdk-dotnet-coreclr': aws_sdk_dotnet,
  curl,
  Pulumi,
};

export interface ClientIconProps {
  code: string;
  title?: string;
  size?: 'small' | 'medium' | 'large';
}

export const ClientIcon = ({ code, title, size = 'medium' }: ClientIconProps): ReactElement => {
  const sizes = { small: [11, 11], medium: [22, 22], large: [32, 32] };
  const [width, height] = sizes[size as keyof typeof sizes] || sizes.medium;

  const Icon = ICONS_MAP[code as keyof typeof ICONS_MAP];

  return (
    <Tooltip title={title || code}>
      { Icon ?
        <Icon
          style={{ display: 'block' }}
          width={width}
          height={height}
        /> :
        <Dvr/>
      }
    </Tooltip>
  );
};
