import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, KafkaClusterDetails } from '@localstack/ui';

import { DEFAULT_KAFKA_ROUTES } from '@localstack/constants';

import { KafkaProps } from './types';

export const KafkaCluster = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_KAFKA_ROUTES,
}: KafkaProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterArn } = useParams<'clusterArn'>();

  const { data: cluster } = useAwsGetter('Kafka', 'describeCluster', [{ ClusterArn: clusterArn }],{ clientOverrides });

  return (
    <Layout
      documentTitle="Cluster Details"
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Kafka', () => goto(routes.RESOURCES_KAFKA)],
              ['Clusters', () => goto(routes.RESOURCES_KAFKA_CLUSTERS)],
              [cluster?.ClusterInfo?.ClusterName ?? clusterArn, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_KAFKA_CLUSTER_UPDATE, { clusterArn })}>
          Edit Cluster
        </Button>
      }
    >
      <Card>
        <CardContent>
          <KafkaClusterDetails cluster={cluster?.ClusterInfo} />
        </CardContent>
      </Card>
    </Layout>
  );
};
