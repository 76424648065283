import { ReactElement, useCallback, useState } from 'react';
import { Button, Card } from '@mui/material';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { ConfirmableButton, Dropdown, LoadingFragment, SNSTopicTable, PageTitle } from '@localstack/ui';

import { DEFAULT_SNS_ROUTES } from '@localstack/constants';

import { SNSProps } from './types';

export const SNSTopics = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SNS_ROUTES,
}: SNSProps): ReactElement => {
  const { goto } = useRoutes();

  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  const { data: topics, isLoading, mutate } = useAwsGetter('SNS', 'listTopics', [], { clientOverrides });
  const { deleteTopic } = useAwsEffect('SNS', ['deleteTopic'], { revalidate: ['listTopics'], clientOverrides });

  const handleDeleteTopics = useCallback(
    async () => Promise.all(selectedTopics.map((TopicArn: string) => deleteTopic({ TopicArn }))),
    [selectedTopics],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="SNS Topics"
            onMutate={mutate}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_SNS_TOPIC_CREATE)}>
              Create Topic
            </Button>
            <Button onClick={() => goto(routes.RESOURCES_SNS_TOPIC_MESSAGE_SEND)}>
              Publish Message
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedTopics.length === 0 || isLoading}
                title={`Delete ${selectedTopics.length} topic(s)?`}
                onClick={handleDeleteTopics}
                text="Selected Topic(s) will be permanently deleted"
              >
                Delete Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <SNSTopicTable
              entries={topics?.Topics ?? []}
              onSelect={setSelectedTopics}
              onViewSNSTopic={(topic) => goto(
                routes.RESOURCES_SNS_TOPIC, { topicArn: topic.TopicArn as string },
              )}
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
