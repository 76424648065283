import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, Route53ResolverEndpointDetails } from '@localstack/ui';

import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants';

import { Route53Props } from './types';

export const Route53ResolverEndpoint = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();

  const { data: endpoint } = useAwsGetter(
    'Route53Resolver',
    'getResolverEndpoint',
    [{ ResolverEndpointId: id }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Route53 Resolver: Endpoint Details - LocalStack"
      title={
        <Box>
          <Typography variant="h4">Endpoint Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Route53', () => goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINTS)],
              ['Resolver Endpoints', () => goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINTS)],
              ['Endpoints', () => undefined],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINT_UPDATE, { id })}>
          Edit Endpoint
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Route53ResolverEndpointDetails endpoint={endpoint?.ResolverEndpoint} />
        </CardContent>
      </Card>
    </Layout>
  );
};
