import { isNil } from 'lodash';

import { Product, Discount, UsageLimit, Tier, OrderContext } from '@localstack/types';

export const isDiscountApplicable = (discount: Discount): boolean => {
  const currentUTCTimestamp = Math.round(new Date().getTime() / 1000);

  if (discount.valid_until) {
    return discount.valid_until > currentUTCTimestamp;
  }

  return true;
};

const getDiscountedAmount = (discount: Discount, amount: number): number => {
  // discount with expiry date and expired
  if (!isDiscountApplicable(discount)) {
    return amount;
  }

  if (discount.discount_flat) {
    return Math.max(amount - discount.discount_flat, 0);
  }

  return amount * (1.0 - (discount.discount_percentage as number) / 100);
};

const getTierEstimation = (tier: Tier, usage: number): number => {
  if (tier.item_value) {
    const usage_started = Math.min(tier.start, usage);
    const usage_ended = tier.end ? Math.min(usage, tier.end) : usage;
    return tier.item_value * (usage_ended - usage_started + 1);
  }
  return tier.flat_value as number;
};

export const getUsageLimit = (limit: UsageLimit, seats: number): number => {
  if (limit.static_limit) return limit.static_limit;

  let total = 0;

  if (limit.seat_tiers && limit.seat_tiers.length > 0) {
    // eslint-disable-next-line no-restricted-syntax
    for (const tier of limit.seat_tiers.slice().reverse()) {
      if (seats >= tier.start) {
        total += getTierEstimation(tier, seats);
      }
    }
  }

  return total;
};

export const getProductVolume = (product: Product, context: OrderContext): Optional<number> => {
  let volume = (context.volumes || {})[product.id] as Optional<number>;

  if (!volume && !product.metered) {
    volume = context.seats;
  }

  if (!volume && product.metered && product.usage_limit) {
    volume = getUsageLimit(product.usage_limit, context.seats);
  }

  return volume;
};

export const getProductEstimations = (product: Product, context: OrderContext): number => {
  let price = 0.0;

  const volume = getProductVolume(product, context);

  if (product.tiers && product.tiers.length) {
    // volume to be consumed is unknown
    if (isNil(volume)) {
      const tier = product.tiers[0] as Tier;
      price += getTierEstimation(tier, tier.end || 1);
    }

    if (!isNil(volume)) {
      product.tiers.slice().reverse().forEach((tier) => {
        if ((volume as number) >= tier.start) {
          price += getTierEstimation(tier, (volume as number));
        }
      });
    }
  }

  if (product.price && volume) {
    price = product.price * volume;
  }

  return price;
};


export const getPlanEstimations = (
  products: Array<Product>,
  context: OrderContext,
  discount: Optional<Discount>,
  discounted = true,
): number => {
  let planDiscount: Optional<Discount> = null;
  let price = 0.0;

  if (discount && isDiscountApplicable(discount)) {
    planDiscount = discount;
  }

  products.forEach((product) => {
    price += getProductEstimations(product, context);
  });

  if (planDiscount && discounted) {
    price = getDiscountedAmount(planDiscount, price);
  }

  return price;
};
