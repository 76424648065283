import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { LambdaRoutes } from '../../types';

interface Props {
  routes: LambdaRoutes;
  functionName: string | undefined;
}

export const FunctionTabs = ({ routes, functionName }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_LAMBDA_FUNCTION, { name: functionName })} end>
      Details
    </NavLink>
    <NavLink
      to={buildRoute(routes.RESOURCES_CLOUDWATCH_GROUP_EVENTS, { logGroupName: `/aws/lambda/${functionName}` })} end
    >
      Logs
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_LAMBDA_FUNCTION_INVOKE, { name: functionName })} end>
      Invoke
    </NavLink>
  </>
);
