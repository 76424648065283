import { ReactElement } from 'react';
import { AgwApi, CreateAgwApiRequest, UpdateAgwApiRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwApiFormProps {
  api?: Optional<Partial<AgwApi>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAgwApiRequest) => unknown;
  onUpdate: (data: UpdateAgwApiRequest) => unknown;
}

export const AgwApiForm = ({ api, loading, formId, onCreate, onUpdate }: AgwApiFormProps): ReactElement => (
  <MagicForm
    data={api}
    schema={getSchema('ApiGatewayV2')}
    loading={loading}
    entry="CreateApiRequest"
    formId={formId}
    fieldConditions={{
      '^CorsConfiguration': ['$ProtocolType', '===', 'HTTP'],
    }}
    onSubmit={(data: CreateAgwApiRequest) => {
      if (!api) return onCreate(data);
      return onUpdate({ ...data, ApiId: api.ApiId as string });
    }}
  />
);
