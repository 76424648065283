import { ReactElement } from 'react';

import StackSvg from './stack.svg';
import OldStackSvg from './old-stack.svg';

export interface StackIconProps {
  variant?: 'running' | 'default';
}

export const StackIcon = ({ variant = 'default' }: StackIconProps): ReactElement => {
  if (variant === 'running') {
    return <StackSvg/>;
  }

  return <OldStackSvg/>;
};
