import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { BackupRoutes } from '../../types';

interface Props {
  routes: BackupRoutes;
}

export const DetailsNavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_BACKUP_PLANS)}>
      Backup Plans
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_BACKUP_VAULTS)}>
      Backup Vaults
    </NavLink>
  </>
);
