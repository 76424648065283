import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateKSMKeyParams, UpdateKMSKeyDescriptionParams } from '@localstack/types';
import { ProgressButton, KMSKeyForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { KMSProps } from './types';

export const KMSKeyUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_KMS_ROUTES,
}: KMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { keyId } = useParams<'keyId'>();

  const { createKey, updateKeyDescription, isLoading } = useAwsEffect(
    'KMS',
    ['createKey', 'updateKeyDescription'],
    { revalidate: ['listKeys', 'describeKey'] },
  );

  const { data: key } = useAwsGetter('KMS', 'describeKey', [{ KeyId: keyId }], { clientOverrides });

  const handleCreate = async (data: CreateKSMKeyParams) => {
    await createKey(data);
    goto(routes.RESOURCES_KMS_KEYS);
  };

  const handleUpdate = async (data: UpdateKMSKeyDescriptionParams) => {
    await updateKeyDescription(data);
    goto(routes.RESOURCES_KMS_KEY, { keyId });
  };

  return (
    <Layout
      documentTitle="KMS Key Details"
      title={
        <Box>
          <Typography variant="h4">KMS Key Details</Typography>
          <Breadcrumbs
            mappings={[
              ['KMS', () => goto(routes.RESOURCES_KMS)],
              ['Keys', () => goto(routes.RESOURCES_KMS_KEYS)],
              [keyId, () => goto(routes.RESOURCES_KMS_KEY, { keyId })],
              [keyId ? 'Update' : 'Create Key', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <KMSKeyForm
            metadata={key?.KeyMetadata}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
