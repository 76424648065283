
import { ReactElement } from 'react';

import { VALIDATION_RULES } from '@localstack/services';
import { CreateEndpointMessage } from '@localstack/types';

import omit from 'lodash/omit';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../form';


const SCHEMA = {
  shapes: {
    CreateEndpointMessage: {
      'type': 'structure',
      'required': [
        'EndpointIdentifier',
        'EndpointType',
        'EngineName',
      ],
      members: {
        'EndpointIdentifier': {
          'type': 'string',
          // eslint-disable-next-line max-len
          'documentation': '<p>The database endpoint identifier. Identifiers must begin with a letter and must contain only ASCII letters, digits, and hyphens. They can\'t end with a hyphen, or contain two consecutive hyphens.</p>',
        },
        'EndpointType': {
          'type': 'string',
          'documentation': '<p>The type of endpoint. Valid values are <code>source</code> and <code>target</code>.</p>',
        },
        'EngineName': {
          'type': 'string',
          // eslint-disable-next-line max-len
          'documentation': '<p>The type of engine for the endpoint. Valid values, depending on the <code>EndpointType</code> value, include <code>"mysql"</code>, <code>"oracle"</code>, <code>"postgres"</code>, <code>"mariadb"</code>, <code>"aurora"</code>, <code>"aurora-postgresql"</code>, <code>"opensearch"</code>, <code>"redshift"</code>, <code>"s3"</code>, <code>"db2"</code>, <code>"db2-zos"</code>, <code>"azuredb"</code>, <code>"sybase"</code>, <code>"dynamodb"</code>, <code>"mongodb"</code>, <code>"kinesis"</code>, <code>"kafka"</code>, <code>"elasticsearch"</code>, <code>"docdb"</code>, <code>"sqlserver"</code>, <code>"neptune"</code>, and <code>"babelfish"</code>.</p>',
        },
        'Username': {
          'type': 'string',
          'documentation': '<p>The user name to be used to log in to the endpoint database.</p>',
        },
        'Password': {
          'type': 'string',
          'documentation': '<p>The password to be used to log in to the endpoint database.</p>',
        },
        'ServerName': {
          'type': 'string',
          'documentation': '<p>The name of the server where the endpoint database resides.</p>',
        },
        'Port': {
          'type': 'string',
          'documentation': '<p>The port used by the endpoint database.</p>',
        },
        'Settings': {
          'type': 'string',
          'documentation': '<p>JSON editor for any settings or field not provided in the form</p>',
        },
      },
    },
  },
};

export type DMSEndpointFormProps = {
  formId: string;
  onSubmit: (data: CreateEndpointMessage) => void;
  loading?: boolean;
};

export const DMSEndpointForm = ({
  formId,
  onSubmit,
  loading,
}: DMSEndpointFormProps): ReactElement => (
  <MagicForm
    entry="CreateEndpointMessage"
    schema={SCHEMA}
    externalFields={{
      '^Settings$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="json"
          rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.validJson }}
          defaultValue='{}'
        />
      ),
    }}
    loading={loading}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit={(data: any) => {
      // spread the settings object in data and remove the settings key to avoid validation errors
      const dataWithoutSettings = omit({ ...data, ...JSON.parse(data.Settings || '{}') }, 'Settings');
      return onSubmit(dataWithoutSettings as CreateEndpointMessage);
    }}
    formId={formId}
  />
);
