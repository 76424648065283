import { ReactElement } from 'react';
import { IAMRole, CreateIAMRoleRequest, UpdateIAMRoleRequest } from '@localstack/types';
import { VALIDATION_RULES, getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledTextField, ControlledCodeEditor } from '../../../../form';

export interface IAMRoleFormProps {
  iamRole?: Optional<Partial<IAMRole>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateIAMRoleRequest) => unknown;
  onUpdate: (data: UpdateIAMRoleRequest) => unknown;
}

export const IAMRoleForm = ({ iamRole, loading, formId, onCreate, onUpdate }: IAMRoleFormProps): ReactElement => (
  <MagicForm
    data={iamRole}
    schema={getSchema('IAM')}
    loading={loading}
    entry="CreateRoleRequest"
    formId={formId}
    fieldConditions={{
      '^(AssumeRolePolicyDocument|PermissionsBoundary|Tags)$': !iamRole,
    }}
    externalFields={{
      '^RoleName$': (control, name) => (
        <ControlledTextField
          fullWidth
          name={name}
          control={control}
          variant="outlined"
          required
          disabled={!!iamRole}
          rules={VALIDATION_RULES.required}
        />
      ),
      '^AssumeRolePolicyDocument$': (control, name) => (
        <ControlledCodeEditor
          name={name}
          control={control}
          required
          rules={{ ...VALIDATION_RULES.validJson, ...VALIDATION_RULES.required }}
          language="json"
        />
      ),
    }}
    onSubmit={(data: CreateIAMRoleRequest) => {
      if (!iamRole) return onCreate(data);
      return onUpdate({
        RoleName: iamRole.RoleName as string,
        Description: data.Description,
        MaxSessionDuration: data.MaxSessionDuration,
      });
    }}
  />
);
