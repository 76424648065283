import { ReactElement } from 'react';
import { useApiGetter, GithubMetadataService } from '@localstack/services';
import { CloudDownload as DownloadIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, Grid, Typography, useMediaQuery } from '@mui/material';
import { ProgressButton } from '@localstack/ui';
import { Theme, useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { CustomerLayout } from '~/layouts';

import { ImageSlider } from './ImageSlider';

const useStyles = makeStyles((theme: Theme) => createStyles({
  halfContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    height: '100%',
  },
  buttonsGrid: {
    paddingTop: theme.spacing(2),
  },
}));

const IMAGES = [
  '/images/desktop/containers.png',
  '/images/desktop/logs.png',
  '/images/desktop/terminal.png',
  '/images/desktop/resourceBrowser.png',
];

export const DesktopDownload = (): ReactElement => {
  const { data: desktopMetadata, isLoading: desktopMetadataIsLoading } =
    useApiGetter(GithubMetadataService, 'getDesktopMetadata', []);

  const classes = useStyles();
  const theme = useTheme();

  const lgAndAbove = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <CustomerLayout title="Localstack Desktop" >
      <Card>
        <CardHeader
          title="LocalStack Desktop"
          titleTypographyProps={{ variant: 'h4' }}
        />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={5} xs={12}>
              <Box className={classes.halfContainer}>
                <Typography variant='body1'>
                  Install the LocalStack desktop app to effortlessly start and stop LocalStack.
                  View logs, interact with the container and use the resource browser directly from the app.
                </Typography>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.buttonsGrid}
                >
                  {/* Existing buttons for downloading the app */}
                  <Grid item>
                    <ProgressButton
                      size='large'
                      color='primary'
                      variant='contained'
                      startIcon={<DownloadIcon />}
                      onClick={() => window.open(desktopMetadata?.executables.windows.downloadUrl, '_blank')}
                      loading={desktopMetadataIsLoading}
                    >
                      Windows
                    </ProgressButton>
                  </Grid>
                  <Grid item>
                    <ProgressButton
                      size='large'
                      color='primary'
                      variant='contained'
                      startIcon={<DownloadIcon />}
                      onClick={() => window.open(desktopMetadata?.executables.mac.downloadUrl, '_blank')}
                      loading={desktopMetadataIsLoading}
                    >
                      Mac
                    </ProgressButton>
                  </Grid>
                  <Grid item>
                    <ProgressButton
                      size='large'
                      color='primary'
                      variant='contained'
                      startIcon={<DownloadIcon />}
                      onClick={() => window.open(desktopMetadata?.executables.linux.downloadUrl, '_blank')}
                      loading={desktopMetadataIsLoading}
                    >
                      Linux
                    </ProgressButton>
                  </Grid>

                  {/* Documentation button */}
                  <Grid item>
                    <Button
                      size='large'
                      color='primary'
                      variant='contained'
                      startIcon={<OpenInNewIcon />}
                      onClick={() => window.open(
                        'https://docs.localstack.cloud/user-guide/tools/localstack-desktop/',
                        '_blank',
                      )}
                    >
                      Documentation
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md style={{ order: lgAndAbove ? '2' : '-1' }}>
              <Box className={classes.halfContainer} >
                <ImageSlider images={IMAGES} />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </CustomerLayout>
  );
};
