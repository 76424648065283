import { ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { useAwsBatchIterator, useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { CloudTrailTable, LoadingFragment, PageTitle } from '@localstack/ui';

import { TrailsNavTabs } from './components/TrailsNavTabs';
import { CloudTrailProps } from './types';

export const CloudTrailTrails = ({
  Layout,
  clientOverrides,
  routes,
}: CloudTrailProps): ReactElement => {
  const { goto } = useRoutes();

  const { data: initialTrails, isLoading, mutate } = useAwsGetter('CloudTrail', 'listTrails',[],{ clientOverrides });
  const { getTrailStatus } = useAwsEffect('CloudTrail', ['getTrailStatus'],{ clientOverrides });

  const trails = useAwsBatchIterator(
    initialTrails?.Trails,
    async (singleTrail) => {
      const { IsLogging } = await getTrailStatus({ Name: singleTrail?.Name as string });
      return { ...singleTrail, IsLogging };
    },
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="Trails"
            onMutate={mutate}
          />
        }
        tabs={<TrailsNavTabs routes={routes}/>}
        actions={<Button onClick={() => goto(routes.RESOURCES_CLOUDTRAIL_TRAIL_CREATE)}>
          Create
        </Button>}
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <CloudTrailTable
              entries={trails}
              onViewTrails={(trail) => goto(
                routes.RESOURCES_CLOUDTRAIL_TRAIL,
                { name: trail.Name as string },
              )}
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
