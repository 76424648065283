import { ReactElement } from 'react';
import { noop } from 'lodash';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAppSyncFunctionRequest } from '@localstack/types';
import { ProgressButton, AppSyncApiKeyForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiKeyUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId' | 'id'>();

  const { createApiKey, isLoading } = useAwsEffect(
    'AppSync',
    ['createApiKey'],
    { revalidate: ['listApiKeys'], clientOverrides },
  );

  const handleCreate = async (data: CreateAppSyncFunctionRequest) => {
    await createApiKey(data);
    goto(routes.RESOURCES_APPSYNC_API_KEYS, { apiId });
  };

  return (
    <Layout
      documentTitle="API Key Details"
      title={
        <Box>
          <Typography variant="h4">API Key Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              ['Create API Key', null],
            ]}
          />
        </Box>
      }
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AppSyncApiKeyForm
            apiId={apiId as string}
            onCreate={handleCreate}
            onUpdate={noop}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
