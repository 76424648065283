import { SERVICE_CODES } from '@localstack/constants';
import { VALIDATION_RULES } from '@localstack/services';
import { CIProject, RESOURCES_SERVICES_TYPE, SERVICE_NAME } from '@localstack/types';
import {
  AwsServiceIcon, ControlledCheckbox, ControlledSelect, ControlledTextField,
} from '@localstack/ui';
import {
  Checkbox,
  Divider, FormControlLabel, Grid, MenuItem, Typography,
} from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

const SUPPORTED_SERVICE_NAME = Object.values(RESOURCES_SERVICES_TYPE).flat();
type ProjectFormProps = {
  project?: CIProject | null;
  onSubmit: (data: CIProject) => void;
}

export const PROJECT_FORM_ID = 'project-form';

export const ProjectForm = ({ onSubmit, project }: ProjectFormProps): ReactElement => {
  const [createCiPod, setCreateCiPod] = useState(true);

  const { handleSubmit, control, setValue, watch } = useForm<CIProject>(
    {
      mode: 'all', defaultValues: {
        settings: {
          store_logs: true,
          store_traces: true,
          cloud_pod_name: undefined,
          cloud_pod_services: undefined,
          ci_runs_history: 100,
        },
      },
    },
  );

  const ciProjectName = watch('project_name');

  useEffect(() => {
    if (ciProjectName && createCiPod) {
      setValue('settings.cloud_pod_name', `ci_${ciProjectName}`, { shouldValidate: true, shouldDirty: false });
    } else {
      setValue('settings.cloud_pod_name', undefined, { shouldValidate: true, shouldDirty: false });
      setValue('settings.cloud_pod_services', undefined, { shouldValidate: true, shouldDirty: false });
    }
  }, [createCiPod, ciProjectName]);


  useEffect(() => {
    if (project) {
      const { settings } = project;
      Object.entries(settings ?? {}).forEach(([field, value]) => {
        setValue(
          `settings.${field}` as keyof CIProject,
          value,
          { shouldValidate: true, shouldDirty: false },
        );
      });


      Object.entries(project).forEach(([k, v]) => {
        setValue(k as keyof CIProject, v, { shouldValidate: true, shouldDirty: false });
      });
    }
  }, [project]);

  return (
    <form id={PROJECT_FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{
          display: project ? 'none' : 'block',
        }}
        >
          <ControlledTextField
            control={control}
            required
            name="project_name"
            label="Project name"
            disabled={!!project}
            fullWidth
            variant="outlined"
            rules={VALIDATION_RULES.required && VALIDATION_RULES.noSpaces}
          />
        </Grid>
        {!project && (
          <>
            <Grid item xs={12}>
              <Divider variant='fullWidth' />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5'>
                Settings
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider variant='fullWidth' />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <ControlledCheckbox
            control={control}
            name="settings.store_logs"
            label="Store LocalStack Logs"
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledCheckbox
            control={control}
            name="settings.store_traces"
            label="Store LocalStack traces"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            label="Track the state of your CI run in a cloud pod"
            control={
              <Checkbox
                color='primary'
                checked={createCiPod}
                value={createCiPod}
                onClick={() => setCreateCiPod(!createCiPod)}
              />
            }
          />
        </Grid>
        {createCiPod && (
          <>
            <Grid item xs={12} md={6}>
              <ControlledTextField
                control={control}
                name="settings.cloud_pod_name"
                label="Cloud Pod Name"
                fullWidth
                disabled
                variant="outlined"
                rules={VALIDATION_RULES.noSpaces}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ControlledSelect
                control={control}
                name="settings.cloud_pod_services"
                label="Saved Services"
                fullWidth
                multiple
                multipleDisplay={
                  (value) =>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {value?.length} services selected
                      {
                        value?.map((service: SERVICE_NAME) =>
                          <AwsServiceIcon key={service} code={service} title={service} size='medium' hideTooltip />,
                        )
                      }
                    </div>
                }
                variant="outlined"
                options={Object.values(SUPPORTED_SERVICE_NAME).map((name) =>
                  <MenuItem key={name} value={name}>
                    <AwsServiceIcon code={name} title={name} size='medium' hideTooltip />
                    {SERVICE_CODES[name as keyof typeof SERVICE_CODES] || name}
                  </MenuItem>,
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Typography>
            Number of historical CI runs for which to keep data in storage
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="settings.ci_runs_history"
            fullWidth
            label="Historical CI Runs"
            variant="outlined"
            type="number"
            rules={VALIDATION_RULES.integerOnly}
          />
        </Grid>
      </Grid>
    </form>
  );
};
