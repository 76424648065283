import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateECRRepositoryRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, CreateECRRepositoryForm } from '@localstack/ui';

import { ECRProps } from './types';

export const ECRRepositoryCreate = ({
  Layout,
  clientOverrides,
  routes,
}: ECRProps): ReactElement => {
  const { goto } = useRoutes();

  const { createRepository, isLoading } = useAwsEffect(
    'ECR',
    ['createRepository'],
    { revalidate: ['describeRepositories'], clientOverrides },
  );

  const handleCreate = async (data: CreateECRRepositoryRequest) => {
    await createRepository(data);
    goto(routes.RESOURCES_ECR_REPOSITORIES);
  };

  return (
    <Layout
      documentTitle="ECR Details"
      title={
        <Box>
          <Typography variant="h4">ECR Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ECR', () => goto(routes.RESOURCES_ECR_REPOSITORIES)],
              ['Create ECR repository', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateECRRepository"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CreateECRRepositoryForm
            onSubmit={handleCreate}
            formId="CreateECRRepository"
            loading={isLoading}
            clientOverrides={clientOverrides}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateECRRepository"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
