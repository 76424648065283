import { useState, useCallback, ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';

import {
  Dropdown,
  ConfirmableButton,
  TimestreamDatabaseGrid,
  LoadingFragment,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_TIMESTREAMDB_ROUTES } from '@localstack/constants/src';

import { TimestreamDBProps } from './types';

/**
 * A list view of all the Timestream databases.
 */
export const TimestreamDatabases = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_TIMESTREAMDB_ROUTES,
}: TimestreamDBProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);
  const { data: databases, isLoading, mutate } = useAwsGetter(
    'TimestreamWrite',
    'listDatabases',
    [],
    { clientOverrides });

  const { deleteDatabase } = useAwsEffect(
    'TimestreamWrite',
    ['deleteDatabase'],
    { revalidate: ['listDatabases'], clientOverrides });

  const handleDeleteObjects = useCallback(async () =>
    Promise.all(selected.map((database) => deleteDatabase({ DatabaseName: database }))),
  [selected],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="Timestream Databases"
            onMutate={mutate}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_TIMESTREAM_DATABASE_CREATE)}>
              Create Database
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selected.length === 0 || isLoading}
                title={`Remove ${selected.length} database(s)?`}
                onClick={handleDeleteObjects}
                text="Selected Database(s) will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <TimestreamDatabaseGrid
              selectable
              entries={databases?.Databases || []}
              onViewDatabase={(entry) =>
                goto(routes.RESOURCES_TIMESTREAM_DATABASE, { database: entry.DatabaseName })
              }
              onSelect={setSelected}
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
