import { obfuscateWithStars, buildRoute } from '@localstack/services';
import { ApiKey, OrganizationMember } from '@localstack/types';
import { Chip } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoute } from '~/config';

type ApiKeyTagProps = {
  member: OrganizationMember,
  keys: ApiKey[],
  subscriptionIds: string[]
}

export const ApiKeyTag = ({ member, keys, subscriptionIds }: ApiKeyTagProps): ReactElement => {
  const navigate = useNavigate();

  const nonCiKeys = keys.filter((key) => !key.is_ci);
  const assignedKey = nonCiKeys.find(
    (key) => (key.member_email === member.email) && subscriptionIds.includes(key.subscription_id ?? ''),
  );

  return (
    <>
      {assignedKey && (
        <Chip
          label={obfuscateWithStars(assignedKey.api_key, 4)}
          style={{ fontFamily: 'monospace' }
          }
          variant='outlined'
          onClick={(event) => {
            event.stopPropagation();
            navigate(buildRoute(AppRoute.WORKSPACE_KEYS));
          }}
        />
      )}
    </>
  );
};
