/* eslint-disable max-len */

import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const APPSYNC_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.APPSYNC}`;

export const DEFAULT_APPSYNC_ROUTES: Record<string,string> = {
  RESOURCES_APPSYNC: `${APPSYNC_BASE_ROUTE}`,
  RESOURCES_APPSYNC_API: `${APPSYNC_BASE_ROUTE}/:apiId`,
  RESOURCES_APPSYNC_API_CREATE: `${APPSYNC_BASE_ROUTE}/apis/new`,
  RESOURCES_APPSYNC_API_UPDATE: `${APPSYNC_BASE_ROUTE}/apis/:apiId/update`,
  RESOURCES_APPSYNC_API_SCHEMA: `${APPSYNC_BASE_ROUTE}/apis/:apiId/schema`,
  RESOURCES_APPSYNC_API_QUERY: `${APPSYNC_BASE_ROUTE}/apis/:apiId/query`,
  RESOURCES_APPSYNC_API_TYPES: `${APPSYNC_BASE_ROUTE}/:apiId/types`,
  RESOURCES_APPSYNC_API_TYPE_CREATE: `${APPSYNC_BASE_ROUTE}/:apiId/types/new`,
  RESOURCES_APPSYNC_API_TYPE_UPDATE: `${APPSYNC_BASE_ROUTE}/:apiId/types/:name/update`,
  RESOURCES_APPSYNC_API_RESOLVERS: `${APPSYNC_BASE_ROUTE}/:apiId/resolvers`,
  RESOURCES_APPSYNC_API_RESOLVER: `${APPSYNC_BASE_ROUTE}/:apiId/resolvers/:typeName----:fieldName`,
  RESOURCES_APPSYNC_API_RESOLVER_CREATE: `${APPSYNC_BASE_ROUTE}/:apiId/resolvers/:typeName/new`,
  RESOURCES_APPSYNC_API_RESOLVER_UPDATE: `${APPSYNC_BASE_ROUTE}/:apiId/resolvers/:typeName----:fieldName/update`,
  RESOURCES_APPSYNC_API_DATA_SOURCES: `${APPSYNC_BASE_ROUTE}/:apiId/datasources`,
  RESOURCES_APPSYNC_API_DATA_SOURCE: `${APPSYNC_BASE_ROUTE}/:apiId/datasources/:name`,
  RESOURCES_APPSYNC_API_DATA_SOURCE_CREATE: `${APPSYNC_BASE_ROUTE}/:apiId/datasources/new`,
  RESOURCES_APPSYNC_API_DATA_SOURCE_UPDATE: `${APPSYNC_BASE_ROUTE}/:apiId/datasources/:name/update`,
  RESOURCES_APPSYNC_API_FUNCTIONS: `${APPSYNC_BASE_ROUTE}/:apiId/functions`,
  RESOURCES_APPSYNC_API_FUNCTION: `${APPSYNC_BASE_ROUTE}/:apiId/functions/:functionId`,
  RESOURCES_APPSYNC_API_FUNCTION_CREATE: `${APPSYNC_BASE_ROUTE}/:apiId/functions/new`,
  RESOURCES_APPSYNC_API_FUNCTION_UPDATE: `${APPSYNC_BASE_ROUTE}/:apiId/functions/:functionId/update`,
  RESOURCES_APPSYNC_API_KEYS: `${APPSYNC_BASE_ROUTE}/:apiId/keys`,
  RESOURCES_APPSYNC_API_KEY_CREATE: `${APPSYNC_BASE_ROUTE}/:apiId/keys/new`,
};
