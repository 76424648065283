import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Subscription, SubscriptionStatus as SubscriptionStatusType } from '@localstack/types';

const STATUS_TEXT_MAP = {
  [SubscriptionStatusType.INCOMPLETE]: 'incomplete',
  [SubscriptionStatusType.INCOMPLETE_EXPIRED]: 'expired',
  [SubscriptionStatusType.TRIALING]: 'trialing',
  [SubscriptionStatusType.ACTIVE]: 'active',
  [SubscriptionStatusType.PAST_DUE]: 'past due',
  [SubscriptionStatusType.CANCELED]: 'canceled',
  [SubscriptionStatusType.UNPAID]: 'unpaid',
  'cancelling': 'cancelling', // delayed cancellation
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  dot: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(.5),
    marginRight: theme.spacing(1),
  },
  incomplete: {
    backgroundColor: theme.palette.warning.main,
  },
  incompleteExpired: {
    backgroundColor: theme.palette.warning.dark,
  },
  active: {
    backgroundColor: theme.palette.success.main,
  },
  pastDue: {
    backgroundColor: theme.palette.warning.main,
  },
  cancelled: {
    backgroundColor: theme.palette.error.dark,
  },
  unpaid: {
    backgroundColor: theme.palette.error.main,
  },
  cancelling: {
    backgroundColor: theme.palette.warning.main,
  },
}));

type Props = {
  subscription: Subscription;
}

const whatIsTheStatus = (subscription: Subscription): SubscriptionStatusType | 'cancelling' => {
  const subscriptionShouldEnd = !!(subscription.cancel_at_period_end || subscription.cancel_at);
  if (subscriptionShouldEnd && subscription.status !== SubscriptionStatusType.TRIALING) return 'cancelling';
  return subscription.status;
};

export const SubscriptionStatus = ({ subscription }: Props): ReactElement => {
  const classes = useStyles();

  const status = whatIsTheStatus(subscription);

  return (
    <span>
      <span
        className={classnames(classes.dot, {
          [classes.incomplete]: status === SubscriptionStatusType.INCOMPLETE,
          [classes.incompleteExpired]: status === SubscriptionStatusType.INCOMPLETE_EXPIRED,
          [classes.active]: [
            SubscriptionStatusType.ACTIVE,
            SubscriptionStatusType.TRIALING,
          ].includes(status as SubscriptionStatusType),
          [classes.pastDue]: status === SubscriptionStatusType.PAST_DUE,
          [classes.cancelled]: status === SubscriptionStatusType.CANCELED,
          [classes.unpaid]: status === SubscriptionStatusType.UNPAID,
          [classes.cancelling]: status === 'cancelling',
        })}
      />
      {STATUS_TEXT_MAP[status as keyof typeof STATUS_TEXT_MAP]}
    </span>
  );
};

export default SubscriptionStatus;
