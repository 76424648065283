import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { Environment } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type MWAAEnvironmentsTableProps = {
  environments: Environment[];
  onViewEnvironment: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const MWAAEnvironmentsTable = ({
  environments,
  onViewEnvironment,
  onSelect,
  loading,
}: MWAAEnvironmentsTableProps): ReactElement => (
  <MagicTable
    entry="Environment"
    idAttribute="Name"
    rows={environments}
    schema={getSchema('MWAA')}
    externalFields={{
      Name: ({ Name }) => (
        <Link onClick={() => onViewEnvironment(Name || '')} underline="hover">{Name}</Link>
      ),
    }}
    order={['Name']}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
