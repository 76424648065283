import { ReactElement } from 'react';
import { Route53ResolverEndpoint } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../magic/MagicTable';

export type Route53ResolverEndpointsTableProps = {
  endpoints: Route53ResolverEndpoint[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewEndpoint?: (name: string) => void;
};

export const Route53ResolverEndpointsTable = ({
  endpoints,
  selectable = true,
  loading,
  onSelect,
  onViewEndpoint,
}: Route53ResolverEndpointsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Route53Resolver')}
    entry="ResolverEndpoint"
    idAttribute="Id"
    order={['Id']}
    filterColumns={['Id', 'Name', 'Direction', 'Status']}
    rows={endpoints}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      Id: (row) => (
        <Link
          onClick={() => onViewEndpoint && onViewEndpoint(row.Id as string)}
          underline="hover"
        >
          {row.Id}
        </Link>
      ),
    }}
  />
);
