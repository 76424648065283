import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Card, DialogContentText, MenuItem, Typography } from '@mui/material';
import {
  useRoutes, useAwsGetter, useAwsEffect, useRegion, getInstanceRegion, getSelectedInstance, buildRoute,
} from '@localstack/services';
import {
  ConfirmableButton, ContentModal,
  Dropdown, PageTitle, RDSDatabasesTable,
} from '@localstack/ui';
import {
  RDSCluster, RDSDatabaseExpandableType, RDSGlobalCluster, RDSInstance,
} from '@localstack/types';
import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { RDSProps } from './types';
import { DATABASE_TYPES, isCluster } from './data';

const initialDataState = {
  databases: [],
  clusters: [],
  instances: [],
  loaded: false,
};

export const RDSDatabases = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_RDS_ROUTES,
}: RDSProps): ReactElement => {
  const { goto } = useRoutes();
  const [magicTableKey, setMagicTableKey] = useState(0);
  const [cannotDeleteModalOpen, setCannotDeleteModalOpen] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<RDSDatabaseExpandableType | undefined>();
  const { region: globalRegion } = useRegion();
  const [gridData, setGridData] = useState<RDSDatabaseExpandableType[]>([]);
  const selectedInstance = getSelectedInstance();

  const { data: globalDatabases, isLoading: databasesLoading, mutate: dbMutate } = useAwsGetter(
    'RDS', 'describeGlobalClusters', [], { clientOverrides },
  );
  const { data: clusters, isLoading: clustersLoading, mutate: clusterMutate } = useAwsGetter(
    'RDS', 'describeDBClusters', [], { clientOverrides },
  );
  const { data: instances, isLoading: instancesLoading, mutate: instanceMutate } = useAwsGetter(
    'RDS', 'describeDBInstances', [], { clientOverrides },
  );

  const [initialData, setInitialData] = useState<{
    databases: RDSGlobalCluster[],
    clusters: RDSCluster[],
    instances: RDSInstance[],
    loaded: boolean,
  }>(initialDataState);

  const { describeDBClusters, describeDBInstances, awsClient, isLoading: otherRegionResourcesLoading } = useAwsEffect(
    'RDS', ['describeDBClusters', 'describeDBInstances'], { clientOverrides },
  );

  const { deleteGlobalCluster, deleteDBCluster, deleteDBInstance, removeFromGlobalCluster } = useAwsEffect(
    'RDS',
    ['deleteGlobalCluster', 'deleteDBCluster', 'deleteDBInstance', 'removeFromGlobalCluster'], { clientOverrides },
  );

  useEffect(() => {
    if (databasesLoading || clustersLoading || instancesLoading || initialData.loaded) return;

    const otherRegionsClusters: RDSCluster[] = [];
    let otherRegionsInstances: RDSInstance[] = [];

    const clusterPromises: ReturnType<typeof describeDBClusters>[] = [];
    const instancesPromises: ReturnType<typeof describeDBInstances>[] = [];

    globalDatabases?.GlobalClusters?.forEach(db => {
      db.GlobalClusterMembers?.forEach(cluster => {
        const splittedArn = cluster.DBClusterArn?.split(':') || [];
        const region = splittedArn[3] || '';
        const id = splittedArn.pop() || '';
        if (region !== globalRegion) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (awsClient as any).config.update({ region });
          clusterPromises.push(describeDBClusters({ DBClusterIdentifier: id }));
        }
      });
    });

    (async () => {
      // Use Promise.all to run all promises in parallel
      const clustersResults = await Promise.all(clusterPromises);
      otherRegionsClusters.push(...clustersResults.flatMap(clusterResult => clusterResult.DBClusters || []));
      otherRegionsClusters.forEach(cluster => {
        const splittedArn = cluster.DBClusterArn?.split(':') || [];
        const region = splittedArn[3] || '';
        cluster.DBClusterMembers?.forEach(instance => {
          const id = instance.DBInstanceIdentifier;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (awsClient as any).config.update({ region });
          instancesPromises.push(describeDBInstances({ DBInstanceIdentifier: id }));
        });
      });
      const instancesResults = await Promise.all(instancesPromises);
      otherRegionsInstances = instancesResults.flatMap(instancesResult => instancesResult.DBInstances || []);
      setInitialData({
        databases: [...(globalDatabases?.GlobalClusters ?? [])],
        clusters: [...(clusters?.DBClusters ?? []), ...otherRegionsClusters],
        instances: [...(instances?.DBInstances ?? []), ...otherRegionsInstances],
        loaded: true,
      });
    })();
  }, [databasesLoading, clustersLoading, instancesLoading]);

  useEffect(() => {
    if (!initialData.loaded) return;

    const processedInstances = initialData.instances?.map(instance => ({
      id: `${instance.DBInstanceIdentifier}:::${instance.DBClusterIdentifier}`,
      DBidentifier: instance.DBInstanceIdentifier,
      Status: instance.DBInstanceStatus,
      Type: DATABASE_TYPES.INSTANCE,
      Engine: instance.Engine,
      Region: instance.AvailabilityZone,
      Size: instance.DBInstanceClass,
    }));

    const processedClusters = initialData.clusters?.map(cluster => {
      const DBidentifier = cluster.DBClusterIdentifier || '';
      return {
        id: DBidentifier,
        DBidentifier,
        Status: cluster.Status,
        Type: DATABASE_TYPES[cluster.ReplicationSourceIdentifier ? 'CLUSTER_SECONDARY' : 'CLUSTER_PRIMARY'],
        Engine: cluster.Engine,
        Region: cluster.DBClusterArn?.split(':')[3],
        Size: `${cluster.DBClusterMembers?.length ?? 0} Instance(s)`,
        childrenIds: cluster.DBClusterMembers?.map(member => `${member.DBInstanceIdentifier}:::${DBidentifier}`),
      };
    });

    const processedDatabases = initialData.databases.map(db => {
      const childClusters = db.GlobalClusterMembers?.length ?? 0;
      const DBidentifier = db.GlobalClusterIdentifier || '';
      const childrenIds = db.GlobalClusterMembers?.map(item => (item.DBClusterArn?.split(':') || []).pop() || '');
      return {
        id: DBidentifier,
        DBidentifier,
        Status: db.Status,
        Type: DATABASE_TYPES.GLOBAL,
        Engine: db.Engine,
        Region: `${childClusters} Region(s)`,
        Size: `${childClusters} Cluster(s)`,
        childrenIds,
      };
    });

    setGridData([
      ...processedDatabases,
      ...processedClusters,
      ...processedInstances,
    ]);
  }, [initialData.loaded]);

  useEffect(() => {
    if (selected.length) setSelectedRow(gridData.find(row => row.id === selected[0]));
    else setSelectedRow(undefined);
  }, [selected]);

  useEffect(() => {
    // ensures that it doesn't mutate on first load if region changes
    if (initialData.loaded) {
      // changing the key will force a re-render of our table component
      // making sure that expanded rows are collapsed and all the state is reset
      setMagicTableKey(prev => prev + 1);
      mutate();
    }
  }, [globalRegion]);

  const isLoading = databasesLoading || clustersLoading || instancesLoading || otherRegionResourcesLoading;

  const onViewDatabase = (db: RDSDatabaseExpandableType) => {
    if (db.Type === DATABASE_TYPES.GLOBAL) {
      return goto(routes.RESOURCES_RDS_DATABASE, { databaseId: db.DBidentifier });
    }
    if (isCluster(db.Type)) {
      return goto(routes.RESOURCES_RDS_CLUSTER, { clusterId: db.DBidentifier });
    }
    goto(routes.RESOURCES_RDS_INSTANCE, { instanceId: db.DBidentifier });
  };

  const handleDelete = useCallback(
    async () => {
      const id = selectedRow?.DBidentifier || '';
      if (selectedRow?.Type === DATABASE_TYPES.GLOBAL) {
        await deleteGlobalCluster({ GlobalClusterIdentifier: id });
      }
      else if (isCluster(selectedRow?.Type)) {
        await deleteDBCluster({ DBClusterIdentifier: id });
      }
      else {
        await deleteDBInstance({ DBInstanceIdentifier: id });
      }
      mutate();
    },
    [selectedRow],
  );

  const handleDetachCluster = useCallback(
    async () => {
      const clusterItem = initialData.clusters.find(
        cluster => cluster.DBClusterIdentifier === selectedRow?.DBidentifier,
      );
      await removeFromGlobalCluster({
        DbClusterIdentifier: clusterItem?.DBClusterArn,
        GlobalClusterIdentifier: selectedRow?.parentId,
      });
      mutate();
    },
    [selectedRow],
  );

  const renderSwitchRegionPopupIfApplicable = useCallback(
    (row: RDSDatabaseExpandableType, btnText: string) => {
      const selectedItemRegion = row.Type === DATABASE_TYPES.INSTANCE
        && getInstanceRegion(row.Region || '') || row.Region;
      if (row.Type !== DATABASE_TYPES.GLOBAL && selectedItemRegion !== globalRegion) {
        const builtRoute = buildRoute(
          routes.RESOURCES_RDS_DATABASES, { iid: selectedInstance?.id }, { region: selectedItemRegion },
        );
        return (
          <ConfirmableButton
            componentType='MenuItem'
            title={`Do you want to switch region to ${selectedItemRegion}`}
            text='This resource belongs to a different region than the current selected region.
        Please confirm if you want to switch the region.'
            confirmButton={{
              component: 'NewTabLink',
              props: {
                href: builtRoute,
              },
            }}
          >
            {btnText}
          </ConfirmableButton>
        );
      }
      return null;
    },
    [globalRegion],
  );

  const DeleteModalToggleButton = useMemo(
    () => {
      const btnText = 'Remove Selected';
      if (!selectedRow) return <MenuItem disabled>{btnText}</MenuItem>;

      const switchRegionPopup = renderSwitchRegionPopupIfApplicable(selectedRow, btnText);
      if (switchRegionPopup) return switchRegionPopup;

      if (selectedRow.childrenIds?.length) {
        return <MenuItem onClick={() => setCannotDeleteModalOpen(true)}>{btnText}</MenuItem>;
      }

      return (
        <ConfirmableButton
          componentType="MenuItem"
          disabled={!selectedRow || isLoading}
          title={`Remove  ${selectedRow.Type}?`}
          onClick={handleDelete}
          text={<DialogContentText>
            <b>{selectedRow.DBidentifier}</b> will be permanently deleted.
          </DialogContentText>}
        >
          {btnText}
        </ConfirmableButton>
      );
    },
    [selectedRow],
  );

  const AddInstanceButton = useMemo(() => {
    const btnText = 'Create Instance';
    if (!selectedRow) {
      return (
        <MenuItem
          onClick={() => goto(routes.RESOURCES_RDS_WITHOUT_CLUSTER_INSTANCE_CREATE)}
        >{btnText}</MenuItem>
      );
    }
    if (!isCluster(selectedRow.Type)) return null;

    const switchRegionPopup = renderSwitchRegionPopupIfApplicable(selectedRow, btnText);
    if (switchRegionPopup) return switchRegionPopup;
    return (
      <MenuItem
        onClick={() => goto(routes.RESOURCES_RDS_INSTANCE_CREATE, { clusterId: selectedRow.DBidentifier })}
      >{btnText}</MenuItem>
    );
  }, [selectedRow]);

  const RemoveFromGlobalDbButton = useMemo(() => (
    <ConfirmableButton
      componentType="MenuItem"
      title='Do you want to remove selected cluster from global cluster'
      onClick={handleDetachCluster}
      text={<DialogContentText>
        This resource belongs to a parent global database <b>{selectedRow?.parentId}</b>.
        This action will detach the cluster from the global database</DialogContentText>}
    >
      Remove from Global Database
    </ConfirmableButton>

  ), [selectedRow]);

  const mutate = () => {
    setGridData([]);
    setInitialData(initialDataState);
    dbMutate();
    clusterMutate();
    instanceMutate();
    setSelected([]);
    setSelectedRow(undefined);
  };

  const isSinglePrimaryCluster = useMemo(() => (
    initialData.databases.find(
      db => db.GlobalClusterIdentifier === selectedRow?.parentId,
    )?.GlobalClusterMembers || []).length === 1, [selectedRow]);

  return (
    <Layout
      documentTitle="RDS: Databases"
      title={
        <PageTitle
          title="RDS Databases"
          onMutate={mutate}
          breadcrumbs={[
            ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
            ['Databases', () => goto(routes.RESOURCES_RDS_DATABASES)],
          ]}
        />
      }
      actions={
        <>
          <Dropdown label="Actions">
            {!selectedRow &&
              <MenuItem onClick={() => goto(routes.RESOURCES_RDS_DATABASE_CREATE)}>Create Database</MenuItem>}
            {!selectedRow &&
              <MenuItem onClick={() => goto(routes.RESOURCES_RDS_CLUSTER_CREATE)}>Create Cluster</MenuItem>}
            {AddInstanceButton}
            {DeleteModalToggleButton}
            {((selectedRow?.Type === DATABASE_TYPES.CLUSTER_SECONDARY || isSinglePrimaryCluster)
              && selectedRow?.parentId) && RemoveFromGlobalDbButton}
          </Dropdown>
        </>
      }
    >
      <Card>
        <RDSDatabasesTable
          key={`rds-data-table-${magicTableKey}`}
          selectable
          loading={isLoading}
          databases={gridData}
          databaseTypes={DATABASE_TYPES}
          globalRegion={globalRegion}
          onSelect={setSelected}
          selectedItems={selected}
          selectedInstanceId={selectedInstance?.id}
          onViewDatabase={onViewDatabase}
          isCluster={isCluster}
        />
      </Card>
      <ContentModal
        title={`Cannot delete this ${selectedRow?.Type?.toLowerCase()}`}
        maxWidth="sm"
        open={cannotDeleteModalOpen}
        fullWidth
        onClose={() => setCannotDeleteModalOpen(false)}
      >
        <Box>
          <Typography variant='h5' align='left' style={{ fontWeight: 'bold', marginBottom: '1em' }}>
            {selectedRow?.childrenIds?.length &&
              <DialogContentText>Can&apos;t delete {selectedRow?.Type?.toLowerCase()} because it
                has children {selectedRow?.Type === DATABASE_TYPES.GLOBAL ? 'clusters' : 'instances'}
              </DialogContentText>
            }
          </Typography>
        </Box>
      </ContentModal>
    </Layout>
  );
};
