import { ReactElement } from 'react';
import { VALIDATION_RULES, getSchema } from '@localstack/services';

import {
  AppSyncType,
  CreateAppSyncTypeRequest,
  UpdateAppSyncTypeRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../../form';

export interface AppSyncTypeFormProps {
  apiId: string;
  appSyncType?: Optional<Partial<AppSyncType>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAppSyncTypeRequest) => unknown;
  onUpdate: (data: UpdateAppSyncTypeRequest) => unknown;
}

const DEFAULT_FORMAT = 'SDL';

export const AppSyncTypeForm = ({
  apiId,
  appSyncType,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AppSyncTypeFormProps): ReactElement => (
  <MagicForm
    data={appSyncType}
    schema={getSchema('AppSync')}
    loading={loading}
    entry={appSyncType ? 'UpdateTypeRequest' : 'CreateTypeRequest'}
    formId={formId}
    externalFields={{
      '^definition$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="graphql"
          required
          rules={VALIDATION_RULES.required}
        />
      ),
    }}
    fieldConditions={{
      '^(format|apiId)': false,
      '^typeName': !appSyncType,
    }}
    onSubmit={(data: CreateAppSyncTypeRequest | UpdateAppSyncTypeRequest) => {
      if (!appSyncType) return onCreate({ ...data, apiId, format: DEFAULT_FORMAT } as CreateAppSyncTypeRequest);
      return onUpdate({ ...data, apiId, typeName: appSyncType?.name as string, format: DEFAULT_FORMAT });
    }}
  />
);
