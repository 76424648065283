import { getSchema } from '@localstack/services';
import { DBCluster, CreateDBClusterMessage, ModifyDBClusterMessage } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type DocDBClusterFormProps = {
  cluster?: Optional<Partial<DBCluster>>;
  formId: string;
  onSubmit: (data: CreateDBClusterMessage | ModifyDBClusterMessage) => void;
  loading?: boolean;
};

export const DocDBClusterForm = ({
  cluster,
  formId,
  onSubmit,
  loading,
}: DocDBClusterFormProps): ReactElement => (
  <MagicForm
    entry={cluster ? 'ModifyDBClusterMessage' : 'CreateDBClusterMessage'}
    schema={getSchema('DocDB')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={cluster}
    fieldConditions={{ DBClusterIdentifier: !cluster }}
  />
);
