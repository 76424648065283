import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, SecretsManagerSecretDetails } from '@localstack/ui';
import { DEFAULT_SECRETSMANAGER_ROUTES } from '@localstack/constants';

import { SecretsManagerProps } from './types';


export const SecretsManagerSecret = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SECRETSMANAGER_ROUTES,
}: SecretsManagerProps): ReactElement => {
  const { goto } = useRoutes();
  const { secretId } = useParams<'secretId'>();

  const { data: secret } = useAwsGetter(
    'SecretsManager',
    'describeSecret',
    [{ SecretId: secretId }],
    { clientOverrides },
  );
  const { data: value } = useAwsGetter(
    'SecretsManager',
    'getSecretValue',
    [{ SecretId: secretId }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Secret Details"
      title={
        <Box>
          <Typography variant="h4">Secret Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SecretsManager', () => goto(routes.RESOURCES_SECRETS_MANAGER)],
              ['Secrets', () => goto(routes.RESOURCES_SECRETS_MANAGER_SECRETS)],
              [secretId, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_SECRETS_MANAGER_SECRET_UPDATE, { secretId })}
        >
          Edit Secret
        </Button>
      }
    >
      <Card>
        <CardContent>
          <SecretsManagerSecretDetails secret={secret} value={value} />
        </CardContent>
      </Card>
    </Layout>
  );
};
