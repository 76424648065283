import { ReactElement } from 'react';
import { CreateGlueDatabaseRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';


export type GlueDatabaseFormProps = {
  loading?: boolean;
  formId: string;
  onSubmit: (data: CreateGlueDatabaseRequest) => unknown;
}

export const GlueDatabaseForm = ({
  loading,
  formId,
  onSubmit,
}: GlueDatabaseFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('Glue')}
    formId={formId}
    entry="CreateDatabaseRequest"
    loading={loading}
    onSubmit={(data: CreateGlueDatabaseRequest) => onSubmit(data)}
  />
);
