import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { GlueTable } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueTablesGridProps = {
  loading?: boolean;
  selectable?: boolean;
  entries: GlueTable[];
  onViewTable?: (table: GlueTable) => void;
  onSelect?: (names: string[]) => void;
};

export const GlueTablesGrid = ({
  entries,
  selectable = true,
  loading,
  onSelect,
  onViewTable,
}: GlueTablesGridProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="TableList"
    idAttribute={['Name', 'DatabaseName']}
    rows={entries}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    filterColumns={['Name', 'DatabaseName', 'CreateTime', 'Description', 'CatalogId', 'Owner']}
    order={['Name', 'DatabaseName', 'Description', 'CatalogId', 'Owner', 'CreateTime']}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewTable && onViewTable(row)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
