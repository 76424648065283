import { DEFAULT_EKS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateClusterRequest, UpdateClusterConfigRequest } from '@localstack/types';
import { Breadcrumbs, EKSClusterForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { EKSProps } from './types';

export const EKSClusterUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EKS_ROUTES,
}: EKSProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: cluster } = useAwsGetter(
    'EKS',
    'describeCluster',
    [{ name: id }],
    { clientOverrides },
  );
  const { createCluster, updateClusterConfig, isLoading } = useAwsEffect(
    'EKS',
    ['createCluster', 'updateClusterConfig'],
    {
      clientOverrides,
      revalidate: ['listClusters', 'describeCluster'],
    },
  );

  const handleCreate = async (data: CreateClusterRequest) => {
    if (await createCluster(data)) {
      goto(routes.RESOURCES_EKS_CLUSTERS);
    }
  };

  const handleUpdate = async (data: UpdateClusterConfigRequest) => {
    if (await updateClusterConfig(data)) {
      goto(routes.RESOURCES_EKS_CLUSTER, { id });
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['EKS', () => goto(routes.RESOURCES_EKS_CLUSTERS)],
              [id, () => goto(routes.RESOURCES_EKS_CLUSTER, { id })],
              [cluster ? 'Update Cluster' : 'Create Cluster', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="EKSClusterUpsert"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <EKSClusterForm
            formId="EKSClusterUpsert"
            onSubmit={cluster ? handleUpdate : handleCreate}
            cluster={cluster}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="EKSClusterUpsert"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
