import { ReactElement } from 'react';
import { CreateCloudWatchLogGroupRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface CloudWatchLogGroupFormProps {
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateCloudWatchLogGroupRequest) => unknown;
}

export const CloudWatchLogGroupForm = ({
  loading,
  formId,
  clientOverrides,
  onCreate,
}: CloudWatchLogGroupFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('CloudWatchLogs')}
    loading={loading}
    entry="CreateLogGroupRequest"
    formId={formId}
    externalFields={{
      '^kmsKeyId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='KMS' method='listKeys' arrayKeyName='Keys' property='KeyArn'
          fieldName={fieldName} entityName='KMS Key'
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateCloudWatchLogGroupRequest) => onCreate(data)}
  />
);
