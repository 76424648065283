import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { SMSecret, SMSecretCreateParams, SMSecretUpdateParams } from '@localstack/types';
import { ProgressButton, SecretsManagerSecretForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_SECRETSMANAGER_ROUTES } from '@localstack/constants';

import { SecretsManagerProps } from './types';

export const SecretsManagerSecretUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SECRETSMANAGER_ROUTES,
}: SecretsManagerProps): ReactElement => {
  const { goto } = useRoutes();
  const { secretId } = useParams<'secretId'>();

  const { createSecret, updateSecret, isLoading } = useAwsEffect(
    'SecretsManager',
    ['createSecret', 'updateSecret'],
    { revalidate: ['listSecrets', 'describeSecret', 'getSecretValue'], clientOverrides },
  );

  const { data: secret } = useAwsGetter(
    'SecretsManager',
    'describeSecret',
    [{ SecretId: secretId }],
    { clientOverrides },
  );
  const { data: value } = useAwsGetter(
    'SecretsManager',
    'getSecretValue',
    [{ SecretId: secretId }],
    { clientOverrides },
  );

  const combinedSecret: SMSecret | undefined = secret ? {
    ...secret,
    ...value,
  } : undefined;

  const handleCreate = async (data: SMSecretCreateParams) => {
    await createSecret(data);
    goto(routes.RESOURCES_SECRETS_MANAGER_SECRETS);
  };

  const handleUpdate = async (data: SMSecretUpdateParams) => {
    await updateSecret(data);
    goto(routes.RESOURCES_SECRETS_MANAGER_SECRET, { secretId });
  };

  return (
    <Layout
      documentTitle="Secret Details"
      title={
        <Box>
          <Typography variant="h4">Secret Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SecretsManager', () => goto(routes.RESOURCES_SECRETS_MANAGER)],
              ['Secrets', () => goto(routes.RESOURCES_SECRETS_MANAGER_SECRETS)],
              [secretId, () => goto(routes.RESOURCES_SECRETS_MANAGER_SECRET, { secretId })],
              [secretId ? 'Update' : 'Create Secret', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SecretsManagerSecretForm
            secret={combinedSecret}
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
