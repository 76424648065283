import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiLinearProgress'] => ({
  styleOverrides: {
    root: {
      borderRadius: '3px',
      overflow: 'hidden',
    },
    colorPrimary: {
      backgroundColor: palette.grey[400],
    },
  },
});
