import { ReactElement } from 'react';
import { EventBridgeRule } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface EventBridgeRulesTableProps {
  selectable?: boolean;
  loading?: boolean;
  rules: EventBridgeRule[];
  onViewRule?: (rule: EventBridgeRule) => unknown;
  onSelect?: (ids: string[]) => void;
}

export const EventBridgeRulesTable = ({
  rules,
  selectable = true,
  loading,
  onViewRule,
  onSelect,
}: EventBridgeRulesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('EventBridge')}
    entry="Rule"
    idAttribute="Name"
    rows={rules}
    selectable={selectable}
    onSelect={onSelect}
    order={['Name']}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewRule && onViewRule(row)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
