import { ReactElement } from 'react';
import { IAMUser } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface IAMUserDetailsProps {
  user?: Optional<Partial<IAMUser>>;
}

export const IAMUserDetails = ({ user }: IAMUserDetailsProps): ReactElement => (
  <MagicDetails
    data={user}
    schema={getSchema('IAM')}
    entry="User"
    title="User Details"
  />
);
