import { Box, Alert, AlertTitle } from '@mui/material';
import { ReactElement } from 'react';

export const NeptuneRequirementsAlert = (): ReactElement => {
  const isHttps = window.location.protocol === 'https:';

  return (
    <>
      {
        isHttps &&
        <Box mt={2}>
          < Alert severity="info" >
            <AlertTitle>Neptune Graph Requirement</AlertTitle>
            In order to use this feature set NEPTUNE_USE_SSL env variable to 1 when starting your localstack container
          </Alert >
        </Box >
      }
    </>
  );
};

