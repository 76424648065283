import { ReactElement, useEffect, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { ControlledRangeSlider } from '@localstack/ui';
import { useForm } from 'react-hook-form';

import { getLatencyExperimentTemplate } from '../../ExperimentTemplates';
import { DEFAULT_LATENCY } from '../../constants';
import { ExperimentFormValues, ExperimentProps } from '../../types';

import { ExperimentCard } from '../ExperimentCard';
import { UpdateExperimentButton } from '../UpdateExperimentButton';

const map = (a: number, b: number, r: number, s: number, value: number) => r + ((s - r) * (value - a)) / (b - a);

const sliderSpan50 = 9;
const sliderSpan500 = 18;
const sliderSpan10k = 19;

const sliderTotalSpan = sliderSpan50 + sliderSpan500 + sliderSpan10k;

const sliderMarks = [
  { value: 0, label: '5ms' },
  { value: sliderSpan50, label: '50ms' },
  { value: sliderSpan50 + sliderSpan500, label: '500ms' },
  { value: sliderTotalSpan, label: '10000ms' },
];

const mapValueToSlider = (value: number) => {
  if (value <= sliderSpan50) {
    return map(0, sliderSpan50, 5, 50, value);
  }
  if (value <= sliderSpan50 + sliderSpan500) {
    return map(sliderSpan50, sliderSpan50 + sliderSpan500, 50, 500, value);
  }
  return map(sliderSpan50 + sliderSpan500, sliderTotalSpan, 500, 10000, value);
};

const getMappedValues = () => {
  const values = [];

  for (let i = 0; i <= sliderTotalSpan; i++) {
    const mappedValue = mapValueToSlider(i);
    const entry = { [mappedValue]: i };

    values.push(entry);
  }
  return values;

};

interface ValueMapping {
  [key: string]: number;
}

const findClosestValue = (data: ValueMapping[], target: number): number | undefined => {
  const closestEntry = data.reduce((closest, entry) => {
    const key = Object.keys(entry)[0];
    const value = entry[key as keyof typeof entry];
    if (key !== undefined && value !== undefined) {
      const difference = Math.abs(+key - target);

      if (!closest || difference < closest.difference) {
        return { value, difference };
      }
    }

    return closest;
  }, undefined as { value: number; difference: number } | undefined);
  return closestEntry ? closestEntry.value : undefined;
};

export const LatencyCard = (props: ExperimentProps): ReactElement => {
  const { experiment, alert, onUpsertExperiment } = props;
  const exp = !Array.isArray(experiment) ? experiment : undefined;

  const currentLatency = useMemo(() => findClosestValue(
    getMappedValues(), +(exp?.latency || DEFAULT_LATENCY),
  ), [exp?.latency]);
  const { control, handleSubmit, formState, watch, reset } = useForm<ExperimentFormValues>({
    mode: 'all',
    defaultValues: {
      latencyMilliseconds: currentLatency,
    },
  });
  useEffect(() => {
    reset({
      latencyMilliseconds: currentLatency,
    });
  }, [currentLatency]);
  const formValues = watch();
  const template = getLatencyExperimentTemplate({
    latencyMilliseconds: mapValueToSlider(formValues.latencyMilliseconds || 0),
  });

  return (
    <ExperimentCard
      experimentCard={{
        title: 'Latency',
        description: `This experiment introduces a specified amount of latency to every API call,
                      which can be used to simulate network latency or degraded network performance.`,
        template,
        options: (
          <form onSubmit={handleSubmit(_ => onUpsertExperiment(template, experiment))} >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box pr={4}>
                  <ControlledRangeSlider
                    control={control}
                    name="latencyMilliseconds"
                    label="Added Latency"
                    postfixElement={<>ms</>}
                    sliderProps={{
                      valueLabelDisplay: 'on',
                      marks: sliderMarks,
                      step: 1,
                      min: 0,
                      max: sliderTotalSpan,
                      valueLabelFormat: (x) => `${mapValueToSlider(x)}ms`,
                    }}
                    valueLabelTooltipProps={{ placement: 'left' }}
                    hideInputField
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <UpdateExperimentButton formState={formState} experimentState={experiment} alert={alert} />
              </Grid>
            </Grid>
          </form>
        ),
      }}
      formState={formState}
      {...props}
    />
  );
};
