import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { Card } from '@mui/material';
import {
  PageTitle,
  GlueJobRunsTable,
} from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { JobTabs } from './components';


export const GlueJobRuns = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { job } = useParams<'job'>();

  const { data: jobRuns, isLoading, mutate } = useAwsGetter(
    'Glue',
    'getJobRuns',
    [{ JobName: job }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Glue: Job Runs"
      title={
        <PageTitle
          title="Glue Job Runs"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Jobs', () => goto(routes.RESOURCES_GLUE_JOBS)],
            [job, () => goto(routes.RESOURCES_GLUE_JOB, { job })],
            ['Job Runs', null],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<JobTabs routes={routes} jobId={job ?? ''} />}
    >
      <Card>
        <GlueJobRunsTable
          jobRuns={jobRuns?.JobRuns ?? []}
          loading={isLoading}
          selectable={false}
          onViewJobRun={(runId) => goto(routes.RESOURCES_GLUE_JOB_RUN, { job, run: runId })}
        />
      </Card>
    </Layout>
  );
};
