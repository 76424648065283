import { ReactElement } from 'react';
import { AthenaDataCatalogSummary } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AthenaDataCatalogsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  catalogs: AthenaDataCatalogSummary[];
  onSelect?: (names: string[]) => void;
  onViewCatalog?: (catalog: AthenaDataCatalogSummary) => void;
};

export const AthenaDataCatalogsTable = ({
  catalogs,
  selectable = true,
  loading,
  onSelect,
  onViewCatalog,
}: AthenaDataCatalogsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Athena')}
    entry="DataCatalogSummary"
    idAttribute="CatalogName"
    rows={catalogs}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      CatalogName: (row) => (
        <Link onClick={() => onViewCatalog && onViewCatalog(row)} underline="hover">
          {row.CatalogName}
        </Link>
      ),
    }}
  />
);
