/* eslint-disable react/no-danger */
import { Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material';
import { ReactElement, useState } from 'react';

import { ConfirmableButton, DangerZone, DangerZoneActions } from '@localstack/ui';

import { AdminService, useApiEffect, useSnackbar } from '@localstack/services';

import { AdminLayout } from '~/layouts';


const BASE_SUBJECT = 'Subject of notification';
const SEPARATOR = '<!-- BODY -->';

const BASE_TEMPLATE = `<div class="paragraph text-center">
  <a href="https://www.localstack.cloud/" class="link-button">Fancy button</a>
</div>
`;

export const AdminAnnouncement = (): ReactElement => {

  const [announcement, setAnnouncement] = useState<string>(BASE_TEMPLATE);
  const [subject, setSubject] = useState<string>(BASE_SUBJECT);
  const [preview, setPreview] = useState<string>('');
  const { sendAnnouncement } = useApiEffect(AdminService, ['sendAnnouncement']);
  const { generateAnnouncementPreview } = useApiEffect(AdminService, ['generateAnnouncementPreview']);
  const { showSnackbar } = useSnackbar();

  const adaptToBEFormat = () => `${subject}\n${SEPARATOR}\n${announcement}`;
  const handlePreviewGeneration = async () => {
    const generatedPreview = await generateAnnouncementPreview({ rich_text: adaptToBEFormat() });
    setPreview(generatedPreview.preview_html);
  };

  const handleSendAnnouncement = async () => {
    if (await sendAnnouncement({ rich_text: adaptToBEFormat() })) {
      showSnackbar({ severity: 'success', message: 'Successfully sent announcement' });
    }
  };

  return (
    <AdminLayout
      title="Create Announcement"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Subject of notification"
                variant="outlined"
                size="small"
                value={subject}
                onChange={({ target: { value } }) => setSubject(value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Announcement body"
                variant="outlined"
                size="small"
                minRows={10}
                value={announcement}
                multiline
                onChange={({ target: { value } }) => setAnnouncement(value)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} md={4}>
          <Card>
            <CardHeader
              title="Actions"
            />
            <CardContent>
              <Grid container spacing={2} style={{ justifyContent: 'end' }}>
                <Grid item>
                  <Button
                    variant='contained'
                    color="primary"
                    onClick={handlePreviewGeneration}
                  >
                    Generate Preview
                  </Button>
                </Grid>
                <Grid item xs={12}>

                  <DangerZone>
                    <DangerZoneActions>
                      <ConfirmableButton
                        componentType="DangerZoneAction"
                        primaryText="Send Announcement"
                        secondaryText="Sends a new announcement to all users"
                        actionText="Send"
                        onClick={() => handleSendAnnouncement()}
                        okText="Send"
                        title="Send announcement?"
                        text='By performing this action every user will receive a
                  notification with the announcement below, this action is irreversible'
                      />
                    </DangerZoneActions>
                  </DangerZone>
                </Grid>

              </Grid>

            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardHeader title='Preview' />
            <CardContent>
              <div dangerouslySetInnerHTML={{ __html: preview }} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </AdminLayout >
  );
};
