import { useCallback, ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Subscription, CancellationDetails } from '@localstack/types';
import { ControlledSelect, ControlledTextField, ProgressButton } from '@localstack/ui';
import { CANCELLATION_REASONS } from '@localstack/constants';
import { VALIDATION_RULES } from '@localstack/services';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  MenuItem,
  Box,
  Button,
  Typography,
} from '@mui/material';

interface Props {
  open: boolean;
  loading: boolean;
  subscription: Optional<Subscription>;
  onCancel: (id: string, details: CancellationDetails) => unknown;
  onClose: () => void;
}

export const SubscriptionCancellationModal = ({
  open,
  loading,
  subscription,
  onCancel,
  onClose,
}: Props): ReactElement => {
  const { control, handleSubmit, formState } = useForm<CancellationDetails>({ mode: 'all' });

  const onSubmit = useCallback((details) => {
    if (!subscription) return;
    onCancel(subscription.id, details);
  }, [subscription]);

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          Cancel Subscription
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            We&apos;re sorry to see you go! Please provide your feedback to help
            us improve our services.
          </DialogContentText>
          <Box>
            <ControlledSelect
              control={control}
              name="feedback"
              variant="outlined"
              label="Cancellation Reason"
              fullWidth
              required
              rules={VALIDATION_RULES.required}
              options={CANCELLATION_REASONS.map(({ key, text }) => (
                <MenuItem key={key} value={key}>{text}</MenuItem>
              ))}
            />
          </Box>
          <Box mt={1}>
            <ControlledTextField
              control={control}
              name="comment"
              variant="outlined"
              label="Feedback"
              fullWidth
              multiline
              minRows={5}
            />
          </Box>
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            You are about to cancel the following Subscription
          </DialogContentText>
          <Typography variant="subtitle1">
            {subscription?.plan?.name}
          </Typography>
          <Typography variant="caption" color="error">
            The cancellation will be effective starting with the next billing
            cycle. Please note that <strong>all API keys, events, and other data</strong>{' '}
            associated with this subscription <strong>will be permanently deleted</strong>!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
          <ProgressButton
            color="primary"
            variant="contained"
            type="submit"
            disabled={!formState.isValid}
            loading={loading}
          >
            Cancel Subscription
          </ProgressButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
