import { ReactElement } from 'react';
import { PutEventBridgeEventsRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledCodeEditor, RelatedResourcePicker } from '../../../form';

export interface EventBridgeEventFormProps {
  clientOverrides?: AwsClientOverrides;
  loading?: boolean;
  formId?: string;
  onCreate: (data: PutEventBridgeEventsRequest) => unknown;
}

export const EventBridgeEventForm = ({
  clientOverrides,
  loading,
  formId,
  onCreate,
}: EventBridgeEventFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('EventBridge')}
    loading={loading}
    entry="PutEventsRequest"
    formId={formId}
    onSubmit={(data: PutEventBridgeEventsRequest) => onCreate(data)}
    externalFields={{
      '^Entries\\.[0-9]+\\.Detail$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="json"
        />
      ),
      '^Entries\\.[0-9]+\\.EventBusName$': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName} entityName='Bus'
          client='EventBridge'
          method='listEventBuses'
          arrayKeyName='EventBuses'
          property='Name'
          clientOverrides={clientOverrides}
          creationRoute={DEFAULT_EVENTBRIDGE_ROUTES.RESOURCES_EVENT_BRIDGE_BUS_CREATE}
        />
      ),
    }}
  />
);
