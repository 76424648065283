import { Link } from '@mui/material';

import { ReactElement } from 'react';
import { ContactInformation } from '@localstack/types';
import { getSchema } from '@localstack/services/src';

import { MagicTable } from '../../magic/MagicTable';

export type ContactInformationTableProps = {
  item?: ContactInformation;
  onEditContactInformation: () => void;
  loading?: boolean;
};

export const ContactInformationTable = ({
  item,
  onEditContactInformation,
  loading,
}: ContactInformationTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Account')}
    entry="ContactInformation"
    idAttribute="AddressLine1"
    rows={item ? [item] : []}
    externalFields={{
      AddressLine1: ({ AddressLine1 }) => (
        <Link onClick={() => onEditContactInformation()} underline="hover">
          {AddressLine1}
        </Link>
      ),
    }}
    loading={loading}
    disableSelectionOnClick
  />
);
