import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button } from '@mui/material';

import {
  CognitoGroupsTable,
  CognitoUserGroupAssignmentForm,
  Dropdown,
  ConfirmableButton,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';

import { CognitoProps } from './types';

import { UserNavTabs } from './components';

export const CognitoPoolUserGroups = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { poolId, username } = useParams<'poolId' | 'username'>();
  const [selected, setSelected] = useState<string[]>([]);

  const { data: pool } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'describeUserPool',
    [{ UserPoolId: poolId }],
    { clientOverrides },
  );

  const { data: userGroups, isLoading: isUserGroupsLoading, mutate } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'adminListGroupsForUser',
    [{ UserPoolId: poolId, Username: username }],
    { clientOverrides },
  );

  const { data: availableGroups, isLoading: isAllGroupsLoading } = useAwsGetter(
    'CognitoIdentityServiceProvider',
    'listGroups',
    [{ UserPoolId: poolId }],
    { clientOverrides },
  );

  const { adminAddUserToGroup, adminRemoveUserFromGroup, isLoading: isGroupMutating } = useAwsEffect(
    'CognitoIdentityServiceProvider',
    ['adminAddUserToGroup', 'adminRemoveUserFromGroup'],
    { revalidate: ['adminListGroupsForUser', 'listGroups'], clientOverrides },
  );

  const handleUnassign = useCallback(
    async () => Promise.all(selected.map((GroupName) => adminRemoveUserFromGroup({
      UserPoolId: poolId as string,
      Username: username as string,
      GroupName,
    }))),
    [selected],
  );

  return (
    <Layout
      documentTitle="User Groups"
      tabs={<UserNavTabs poolId={poolId as string} username={username as string} routes={routes}/>}
      title={
        <PageTitle
          title="User Groups"
          onMutate={mutate}
          breadcrumbs={[
            ['Cognito', () => goto(routes.RESOURCES_COGNITO)],
            [pool?.UserPool?.Name ?? poolId, () => goto(routes.RESOURCES_COGNITO_USER_POOL, { poolId })],
            ['Users', () => goto(routes.RESOURCES_COGNITO_POOL_USERS, { poolId })],
            [username, () => goto(routes.RESOURCES_COGNITO_POOL_USER, { poolId, username })],
            ['Groups', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_COGNITO_POOL_GROUP_CREATE, { poolId })}>
            New Group
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isGroupMutating}
              title="Unassign the User from Selected Groups?"
              onClick={handleUnassign}
              text="The User will be removed from Selected Group(s)"
            >
              Unassign from Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CardContent>
          <CognitoUserGroupAssignmentForm
            poolId={poolId as string}
            username={username as string}
            groups={availableGroups?.Groups ?? []}
            loading={isAllGroupsLoading || isGroupMutating}
            onAssign={adminAddUserToGroup}
          />
          <CognitoGroupsTable
            loading={isUserGroupsLoading}
            groups={userGroups?.Groups ?? []}
            onSelect={setSelected}
            onViewGroup={
              (group) => goto(routes.RESOURCES_COGNITO_POOL_GROUP, { poolId, groupName: group.GroupName })
            }
          />
        </CardContent>
      </Card>
    </Layout>
  );
};
