/* eslint-disable max-len */

import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const S3_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.S3}`;

export const DEFAULT_S3_ROUTES: Record<string,string> = {
  RESOURCES_S3: `${S3_BASE_ROUTE}`,
  RESOURCES_S3_BUCKET: `${S3_BASE_ROUTE}/:bucket`,
  RESOURCES_S3_BUCKET_PREFIX: `${S3_BASE_ROUTE}/:bucket?prefix=:prefix`,
  RESOURCES_S3_BUCKET_CREATE: `${S3_BASE_ROUTE}/bucket/new`,
  RESOURCES_S3_BUCKET_PERMISSIONS: `${S3_BASE_ROUTE}/:bucket/permissions`,
  RESOURCES_S3_BUCKET_PERMISSIONS_CORS_CONFIGURATION: `${S3_BASE_ROUTE}/:bucket/permissions/cors-configuration`,
  RESOURCES_S3_FOLDER_CREATE: `${S3_BASE_ROUTE}/:bucket/new`,
  RESOURCES_S3_FOLDER_CREATE_PREFIX: `${S3_BASE_ROUTE}/:bucket/:prefix/new`,
};
