import { useState, useCallback, ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Dropdown, ConfirmableButton, SESIdentitiesTable, PageTitle } from '@localstack/ui';
import { DEFAULT_SES_ROUTES } from '@localstack/constants';

import { SESProps } from './types';
import { IndexNavTabs } from './components/IndexNavTabs';

/**
 * A list view of all SimpleEmailService identities.
 */
export const SESV1Identities = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SES_ROUTES,
}: SESProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const { deleteIdentity } = useAwsEffect(
    'SES',
    ['deleteIdentity'],
    { revalidate: ['listIdentities'], clientOverrides },
  );
  const { data: identities, isLoading, mutate } = useAwsGetter('SES', 'listIdentities', [], { clientOverrides });

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((Identity) => deleteIdentity({ Identity }))),
    [selected],
  );

  return (
    <Layout
      title={
        <PageTitle
          title="SES Identities"
          onMutate={mutate}
        />
      }
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_SES1_IDENTITY_CREATE)}>
            Create Identity
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Remove ${selected.length} identities?`}
              onClick={handleDeleteObjects}
              text="Selected identities will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <SESIdentitiesTable
          rows={identities?.Identities ?? []}
          onView={(id) => goto(routes.RESOURCES_SES1_IDENTITY_MESSAGES, { id })}
          onSelect={setSelected}
        />
      </Card>
    </Layout>
  );
};
