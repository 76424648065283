import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AgwApiStage } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { NewTabLink } from '../../../../navigation';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwApiStagesTableProps = {
  apiId: string;
  selectable?: boolean;
  loading?: boolean;
  stages: AgwApiStage[];
  onViewStage?: (stage: AgwApiStage) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AgwApiStagesTable = ({
  stages,
  selectable = true,
  loading,
  apiId,
  onViewStage,
  onSelect,
}: AgwApiStagesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ApiGatewayV2')}
    entry="Stage"
    idAttribute="StageName"
    rows={stages}
    selectable={selectable}
    onSelect={onSelect}
    order={['StageName', 'DeploymentId']}
    loading={loading}
    externalFields={{
      StageName: (row) => (
        <Link onClick={() => onViewStage && onViewStage(row)} underline="hover">
          {row.StageName}
        </Link>
      ),
      DeploymentId: (row) => (
        <NewTabLink
          type='Link'
          href={`http://${apiId}.execute-api.localhost.localstack.cloud:4566/${row.StageName}`}
        >
          {row.DeploymentId}
        </NewTabLink>
      ),
    }}
  />
);
