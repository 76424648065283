import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, MonitoringAlarmDetails } from '@localstack/ui';

import { DEFAULT_METRICS_ROUTES } from '@localstack/constants/src';

import { MainNavTabs } from './components';
import { MetricsProps } from './types';

export const MetricAlarm = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_METRICS_ROUTES,
}: MetricsProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const { data: alarms } = useAwsGetter(
    'CloudWatch',
    'describeAlarms',
    [{ AlarmNames: [name as string] }],
    { clientOverrides },
  );

  const allAlarms = [...(alarms?.MetricAlarms ?? []), ...(alarms?.CompositeAlarms ?? [])];
  const alarm = allAlarms[0];

  return (
    <Layout
      documentTitle="Alarm Details"
      tabs={<MainNavTabs routes={routes}/>}
      title={
        <Box>
          <Typography variant="h4">Alarm Details</Typography>
          <Breadcrumbs
            mappings={[
              ['CloudWatch Metrics', () => goto(routes.RESOURCES_CLOUDWATCH_MONITORING)],
              ['Alarms', () => goto(routes.RESOURCES_CLOUDWATCH_MONITORING_ALARMS)],
              [name, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <MonitoringAlarmDetails alarm={alarm} />
        </CardContent>
      </Card>
    </Layout>
  );
};
