import { ReactElement } from 'react';
import { AgwApiIntegration } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwApiIntegrationDetailsProps {
  integration?: Optional<Partial<AgwApiIntegration>>;
}

export const AgwApiIntegrationDetails = ({ integration }: AgwApiIntegrationDetailsProps): ReactElement => (
  <MagicDetails
    data={integration}
    schema={getSchema('ApiGatewayV2')}
    entry="Integration"
    title="Integration Details"
  />
);
