import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { S3Routes } from '../../types';

type BucketTabsProps = {
  routes: S3Routes;
  bucket: string;
}

export const BucketTabs = ({ routes, bucket }: BucketTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_S3_BUCKET, { bucket })}>
      Objects
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_S3_BUCKET_PERMISSIONS_CORS_CONFIGURATION, { bucket })}>
      Permissions
    </NavLink>
  </>
);
