import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const APPCONFIG_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.APPCONFIG}`;

export const DEFAULT_APPCONFIG_ROUTES: Record<string, string> = {
  RESOURCES_APPCONFIG_APPLICATIONS: `${APPCONFIG_BASE_ROUTE}/applications`,
  RESOURCES_APPCONFIG_APPLICATION_CREATE: `${APPCONFIG_BASE_ROUTE}/application/new`,
  RESOURCES_APPCONFIG_APPLICATION: `${APPCONFIG_BASE_ROUTE}/application/:id`,
  RESOURCES_APPCONFIG_APPLICATION_UPDATE: `${APPCONFIG_BASE_ROUTE}/application/:id/update`,
};
