import { ReactElement } from 'react';
import { SendSQSMessageRequest } from '@localstack/types';
import { MenuItem } from '@mui/material';
import { getSchema } from '@localstack/services';

import { ControlledSelect } from '../../../../form';
import { MagicForm } from '../../../magic/MagicForm';

export type SQSSendFormProps = {
  loading?: boolean;
  queueUrls: string[],
  formId: string;
  onSubmit: (data: SendSQSMessageRequest) => unknown;
}

export const SQSSendForm = ({
  loading,
  queueUrls,
  formId,
  onSubmit,
}: SQSSendFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SQS')}
    formId={formId}
    entry="SendMessageRequest"
    loading={loading}
    externalFields={{
      '^QueueUrl$': (control ,url) => (
        <ControlledSelect
          variant="outlined"
          control={control}
          fullWidth
          required
          label="Data Source"
          name={url}
          options={[
            <MenuItem key="" value="">None</MenuItem>,
            ...queueUrls.map((q) => <MenuItem key={q} value={q}>{q}</MenuItem>),
          ]}
        />
      ),
    }}
    onSubmit={(data: SendSQSMessageRequest) => onSubmit({ ...data })}
  />
);
