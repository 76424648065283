import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { HealthStatePlus, HealthStatePlusType } from '@localstack/types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dot: {
    display: 'inline-block',
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(.5),
    marginRight: theme.spacing(1),
  },
  available: {
    backgroundColor: theme.palette.info.main,
  },
  disabled: {
    backgroundColor: theme.palette.action.disabled,
  },
  error: {
    backgroundColor: theme.palette.error.main,
  },
  initialized: {
    backgroundColor: theme.palette.info.main,
  },
  running: {
    backgroundColor: theme.palette.success.main,
  },
  offline: {
    backgroundColor: theme.palette.action.disabledBackground,
  },
}));

export interface SystemStatusProps {
  state: HealthStatePlusType;
}

export const SystemStatus = ({ state }: SystemStatusProps): ReactElement => {
  const classes = useStyles();

  return (
    <span>
      <span
        className={classnames(classes.dot, {
          [classes.available]: state === HealthStatePlus.AVAILABLE,
          [classes.disabled]: state === HealthStatePlus.DISABLED,
          [classes.error]: state === HealthStatePlus.ERROR,
          [classes.initialized]: state === HealthStatePlus.INITIALIZED,
          [classes.running]: state === HealthStatePlus.RUNNING,
          [classes.offline]: state === HealthStatePlus.OFFLINE,
        })}
      />
      {state}
    </span>
  );
};

export default SystemStatus;
