import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useErrorCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    code: {
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.common.black : '#f3f3f3',
      padding: '3px',
      margin: '0 2px',
      borderRadius: '5px',
    },
  }),
);
