import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { AwsClientOverrides, buildAgwPatches, buildRoute, getSchema } from '@localstack/services';

import {
  AgwRestApiResource,
  CreateAgwRestApiResourceRequest,
  UpdateAgwRestApiResourceRequest,
} from '@localstack/types';

import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form';

export interface AgwRestApiResourceFormProps {
  restApiId: string;
  resource?: Optional<Partial<AgwRestApiResource>>;
  resources: AgwRestApiResource[];
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateAgwRestApiResourceRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiResourceRequest) => unknown;
}

export const AgwRestApiResourceForm = ({
  restApiId,
  resource,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: AgwRestApiResourceFormProps): ReactElement => (
  <MagicForm
    data={resource}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateResourceRequest"
    formId={formId}
    externalFields={{
      '^restApiId$': () => <TextField value={restApiId} disabled fullWidth variant="outlined" />,
      '^parentId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName} entityName='Resource'
          client='APIGateway'
          method='getResources'
          arrayKeyName='items'
          property='id'
          args={[{ restApiId }]}
          clientOverrides={clientOverrides}
          creationRoute={
            buildRoute(DEFAULT_API_GATEWAY_ROUTES.RESOURCES_AGW1_API_RESOURCE_CREATE, { restApiId })
          }
        />
      ),
    }}
    onSubmit={(data: CreateAgwRestApiResourceRequest) => {
      if (!resource) return onCreate({ ...data, restApiId });

      const patchOperations = buildAgwPatches(resource, data);
      return onUpdate({ restApiId, resourceId: resource?.id as string, patchOperations });
    }}
  />
);
