import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const SES_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.SES}`;
const SESV2_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.SESV2}`;

export const DEFAULT_SES_ROUTES: Record<string,string> = {

  // SES v2
  RESOURCES_SES2: `${SESV2_BASE_ROUTE}`,
  RESOURCES_SES2_IDENTITIES: `${SESV2_BASE_ROUTE}/identities`,
  RESOURCES_SES2_IDENTITY_CREATE: `${SESV2_BASE_ROUTE}/identities/:id/new`,
  RESOURCES_SES2_IDENTITY_MESSAGES: `${SESV2_BASE_ROUTE}/identities/:id/messages`,
  RESOURCES_SES2_IDENTITY_MESSAGE_CREATE: `${SESV2_BASE_ROUTE}/identities/:id/messages/new`,

  // SES v1
  RESOURCES_SES1: `${SES_BASE_ROUTE}`,
  RESOURCES_SES1_IDENTITIES: `${SES_BASE_ROUTE}/identities`,
  RESOURCES_SES1_IDENTITY_CREATE: `${SES_BASE_ROUTE}/identities/:id/new`,
  RESOURCES_SES1_IDENTITY_MESSAGES: `${SES_BASE_ROUTE}/identities/:id/messages`,
  RESOURCES_SES1_IDENTITY_MESSAGE_CREATE: `${SES_BASE_ROUTE}/identities/:id/messages/new`,
};
