import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { PlanFamily } from '@localstack/types';
// TODO: ask Lazar for a proper icon
import { AssignmentIndOutlined as HobbyIcon } from '@mui/icons-material';

import TrialIcon from './icons/trial.svg';
import CiIcon from './icons/ci.svg';
import EnterpriseIcon from './icons/enterprise.svg';
import ProIcon from './icons/pro.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    fill: theme.palette.text.primary,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  primary: {
    fill: theme.palette.primary.main,
  },
  contrastText: {
    fill: theme.palette.primary.contrastText,
  },
}));

const ICONS_MAP = {
  'trial': TrialIcon,
  'pro': ProIcon,
  'ci': CiIcon,
  'team': CiIcon,
  'enterprise': EnterpriseIcon,
  'hobby': HobbyIcon,
};

type Props = {
  className?: string;
  planFamily: PlanFamily;
  color?: 'default' | 'primary' | 'contrastText';
  size?: 'medium' | 'large';
};

export const PlanIcon = ({ className, planFamily, color = 'default', size = 'medium' }: Props): ReactElement => {
  const classes = useStyles();
  const Icon = ICONS_MAP[planFamily.toString().toLowerCase() as keyof typeof ICONS_MAP];

  return (
    <Icon
      className={classnames(className, classes.icon, {
        [classes.large]: size === 'large',
        [classes.primary]: color === 'primary',
        [classes.contrastText]: color === 'contrastText',
      })}
    />
  );
};

export default PlanIcon;
