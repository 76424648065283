import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateCloudWatchLogGroupRequest } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, CloudWatchLogGroupForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_CLOUDWATCH_ROUTES } from '@localstack/constants';

import { CloudWatchProps } from './types';


export const CloudWatchLogGroupUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CLOUDWATCH_ROUTES,
}: CloudWatchProps): ReactElement => {
  const { goto } = useRoutes();
  const { logGroupName } = useParams<'logGroupName'>();

  const { createLogGroup, isLoading } = useAwsEffect(
    'CloudWatchLogs',
    ['createLogGroup'],
    { revalidate: ['describeLogGroups'], clientOverrides },
  );

  const handleCreate = async (data: CreateCloudWatchLogGroupRequest) => {
    await createLogGroup(data);
    goto(routes.RESOURCES_CLOUDWATCH_GROUPS, { logGroupName });
  };

  return (
    <Layout
      documentTitle="Log Group Details"
      title={
        <Box>
          <Typography variant="h4">Log Group Details</Typography>
          <Breadcrumbs
            mappings={[
              ['CloudWatch Logs', () => goto(routes.RESOURCES_CLOUDWATCH)],
              ['Log Groups', () => goto(routes.RESOURCES_CLOUDWATCH_GROUPS)],
              ['Create Log Group', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CloudWatchLogGroupForm
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
