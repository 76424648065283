import { ReactElement } from 'react';
import { List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@mui/material';
import { ProductType, Product, OrderContext } from '@localstack/types';
import { formatBytes, formatMonetaryAmount, getProductEstimations, getProductVolume } from '@localstack/services';

import { PlanProductIcon } from '../../../display/PlanProductIcon';


export interface ProductsListProps {
  currency: string;
  products: Product[];
  context: OrderContext;
}

export const ProductsList = ({ currency, products, context }: ProductsListProps): ReactElement => (
  <List dense disablePadding>
    {products?.map((product) => (
      <ListItem key={product.id}>
        <ListItemAvatar>
          <PlanProductIcon productType={product.type} />
        </ListItemAvatar>
        <ListItemText
          primary={product.name}
          secondary={
            <>
              {product.type === ProductType.CI_USAGE && (
                `${getProductVolume(product, context) ?? '∞'} CI Credits / month`
              )}
              {product.type === ProductType.POD_USAGE && (
                `${formatBytes(getProductVolume(product, context)) ?? '∞'}`
              )}
              {product.type === ProductType.COMPUTE_USAGE && (
                `${getProductVolume(product, context) ?? '∞'} Credits / month`
              )}
            </>
          }
        />
        <ListItemSecondaryAction>
          <ListItemText
            primary={formatMonetaryAmount(getProductEstimations(product, context), currency)}
          />
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
);
