import { OrganizationMember, LicenseAssignment, Subscription } from '@localstack/types';
import { Chip, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  licenseChip: {
    backgroundColor: `${theme.palette.info.main} !important`,
    color: theme.palette.info.contrastText,
    textTransform: 'capitalize',
    '&:hover, &:active': {
      backgroundColor: `${theme.palette.info.main} !important`,
    },

    '& svg': {
      color: theme.palette.info.contrastText,
    },
  },
}));

type LicenseTagProps = {
  member: OrganizationMember,
  licenseAssignments: LicenseAssignment[],
  subscriptions: Subscription[],
  unassignLicense: (licenseAssignmentId: string) => unknown,
}

export const LicenseTag = (
  {
    member,
    licenseAssignments,
    subscriptions,
    unassignLicense,
  }: LicenseTagProps): ReactElement => {
  const classes = useStyles();

  const memberLicenseAssignment = licenseAssignments.find((license) => license.user_id === member.id);
  const subId = memberLicenseAssignment?.subscription_ids?.[0];
  const licenseSubscription = subscriptions.find((sub) => sub.id === subId);
  if (memberLicenseAssignment?.id) {
    memberLicenseAssignment.id.trim();
  }

  return (
    <>
      {(memberLicenseAssignment?.id !== undefined && licenseSubscription) && (
        <Chip
          label={licenseSubscription.name}
          variant='outlined'
          className={classes.licenseChip}
          onDelete={(event) => {
            event.stopPropagation();
            unassignLicense(memberLicenseAssignment.id ?? ''); // type narrowing not working here...?
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
          clickable={false}
        />
      )}
    </>
  );
};
