import { ReactElement } from 'react';
import { StartExecutionRequest } from '@localstack/types';
import { VALIDATION_RULES, getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledCodeEditor, ControlledTextField } from '../../../form';

export type StateExecutionStartFormProps = {
  stateMachineArn?: Optional<string>;
  loading?: boolean;
  formId: string;
  onCreate: (data: StartExecutionRequest) => unknown;
}

const defaultValue = {
  'Comment': 'Insert your JSON here',
};

export const StateExecutionStartForm = ({
  stateMachineArn,
  loading,
  formId,
  onCreate,
}: StateExecutionStartFormProps): ReactElement => (
  <MagicForm
    data={{ stateMachineArn }}
    schema={getSchema('StepFunctions')}
    formId={formId}
    entry='StartExecutionInput'
    loading={loading}
    fieldConditions={{
      '^stateMachineArn$': false,
      '^traceHeader$': false,
    }}
    externalFields={{
      '^input$': (control, fieldName, required) => (
        <ControlledCodeEditor
          control={control}
          name={fieldName}
          language="json"
          rules={{ ...VALIDATION_RULES.validJson }}
          required={required}
          defaultValue={JSON.stringify(defaultValue, null, 4)}
        />
      ),
      '^name$': (control, name) => (
        <ControlledTextField
          fullWidth
          variant="outlined"
          label="Name"
          control={control}
          name={name}
          placeholder='Name will be automatically generated unless specified'
        />
      ),
    }}
    onSubmit={(data: StartExecutionRequest) => onCreate({ ...data })}
  />
);
