import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const KAFKA_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.KAFKA}`;

export const DEFAULT_KAFKA_ROUTES: Record<string,string> = {
  RESOURCES_KAFKA: `${KAFKA_BASE_ROUTE}`,
  RESOURCES_KAFKA_CLUSTERS: `${KAFKA_BASE_ROUTE}/clusters`,
  RESOURCES_KAFKA_CLUSTER: `${KAFKA_BASE_ROUTE}/clusters/:clusterArn`,
  RESOURCES_KAFKA_CLUSTER_CREATE: `${KAFKA_BASE_ROUTE}/clusters/new`,
  RESOURCES_KAFKA_CLUSTER_UPDATE: `${KAFKA_BASE_ROUTE}/clusters/:clusterArn/update`,
};
