import { ReactElement } from 'react';
import { CreateTimestreamTableRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_KMS_ROUTES, DEFAULT_S3_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';

export type TimestreamTableFormProps = {
  databaseName: string;
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateTimestreamTableRequest) => unknown;
}

export const TimestreamTableForm = ({
  databaseName,
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: TimestreamTableFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('TimestreamWrite')}
    formId={formId}
    entry="CreateTableRequest"
    loading={loading}
    fieldConditions={{
      '^DatabaseName': false,
    }}
    externalFields={{
      'BucketName$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='S3' method='listBuckets' arrayKeyName='Buckets' property='Name'
          fieldName={fieldName} entityName='S3 Bucket'
          creationRoute={DEFAULT_S3_ROUTES.RESOURCES_S3_BUCKET_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      'KmsKeyId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='KMS' method='listKeys' arrayKeyName='Keys' property='KeyId'
          fieldName={fieldName} entityName='KMS Key'
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateTimestreamTableRequest) => onSubmit({ ...data, DatabaseName: databaseName })}
  />
);
