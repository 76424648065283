import { GetS3BucketCorsRequest, PutS3BucketCorsFormRequest, PutS3BucketCorsRequest } from '@localstack/types';
import { ReactElement } from 'react';
import { Box , Alert } from '@mui/material';

import { ControlledCodeEditor } from '../../../../form';
import { MagicForm } from '../../../magic/MagicForm';

import { CORSConfigurationPlaceholder, defaultCORSRules } from './DefaultCorsRules';

export interface S3BucketCorsCreateFormProps {
  corsConfiguration: Optional<GetS3BucketCorsRequest>;
  bucket: string;
  loading?: boolean;
  formId?: string;
  showError: (message: string) => void;
  onSubmit: (data: PutS3BucketCorsRequest) => void;
}

const SCHEMA = {
  shapes: {
    PutBucketCorsRequest: {
      type: 'structure',
      required: ['Bucket'],
      members: {
        Bucket: {
          type: 'string',
          documentation: '<p>Specifies the bucket impacted by the corsconfiguration.</p>',
        },
        CORSConfiguration: {
          type: 'string',
          // eslint-disable-next-line max-len
          documentation: '<p>Describes the cross-origin access configuration for objects in an Amazon S3 bucket. For more information, see Enabling Cross-Origin Resource Sharing in the Amazon S3 User Guide.</p>',
        },
      },
    },
  },
};

// Filter out web app related CORS rules
// Default CORS rules will be added in onSubmit function
// to ensure that the web app is able to access the bucket

const processCORSRules = (rules: Optional<GetS3BucketCorsRequest>) => ({
  CORSRules: rules?.CORSRules?.filter(
    rule => rule?.AllowedOrigins?.[0] !== defaultCORSRules.AllowedOrigins[0],
  ),
});


export const S3BucketCorsCreateForm = ({
  corsConfiguration,
  bucket,
  loading,
  formId,
  showError,
  onSubmit,
}: S3BucketCorsCreateFormProps): ReactElement => {
  const processedRules = !loading && JSON.stringify(processCORSRules(corsConfiguration), null, 4);
  return (
    <>
      <Box>
        <Alert severity="info">
          A CORS Rule for {window.location.hostname} will be added to this bucket{' '}
           to ensure it&apos;s accessible via our web application.
        </Alert>
      </Box>
      <br />
      <MagicForm
        data={{
          Bucket: bucket,
          CORSConfiguration: processedRules !== '{}' ? processedRules : '',
        }}
        schema={SCHEMA}
        entry='PutBucketCorsRequest'
        loading={loading}
        formId={formId}
        fieldConditions={{
          'Bucket': false,
        }}
        externalFields={{
          '^CORSConfiguration$': (control, name) => (
            <>
              <ControlledCodeEditor
                control={control}
                name={name}
                language="json"
                placeholder={!loading ? JSON.stringify(CORSConfigurationPlaceholder, null, 4) : ''}
                style={{ minHeight: '380px' }}
              />
            </>
          ),
        }}
        onSubmit={(data: PutS3BucketCorsFormRequest) => {
          try {
            const CORSConfiguration = JSON.parse(data.CORSConfiguration || '{}');
            return onSubmit({
              ...data,
              CORSConfiguration: {
                CORSRules: data.CORSConfiguration ? [
                  ...CORSConfiguration.CORSRules,
                  defaultCORSRules,
                ] : [],
              },
            });
          } catch (e) {
            showError(e.message);
          }
        }}
      />
    </>
  );
};


