import { MuiThemeComponentsType } from '../types';

export default (): MuiThemeComponentsType['MuiCardHeader'] => ({
  styleOverrides: {
    action: {
      marginTop: 0,
      marginRight: 0,
    },
  },
});
