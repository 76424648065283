import { ReactElement } from 'react';
import { CreateSQSQueueRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface SQSQueueCreateFormProps {
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateSQSQueueRequest) => unknown;
}

export const SQSQueueCreateForm = ({
  loading,
  formId,
  onCreate,
}: SQSQueueCreateFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SQS')}
    loading={loading}
    entry="CreateQueueRequest"
    formId={formId}
    onSubmit={(data: CreateSQSQueueRequest) => onCreate(data)}
  />
);
