import { ExternalLink } from '@localstack/constants';
import { Link, Typography } from '@mui/material';
import { ReactElement } from 'react';

export const ACCEPT_TOS_BUTTON_CAPTION = 'Accept Terms and Conditions';

export const AcceptTosTextBlock = (): ReactElement =>
  <Typography variant='body1'>
    Use of the LocalStack service is subject to LocalStack’s{' '}
    <Link href={ExternalLink.LEGAL_TOS} target='_blank' underline="hover">
      &quot;Master Service Agreement&quot;
    </Link> (aka, the &quot;Terms&quot;).
    LocalStack will process all customer data and personal information in accordance with the Terms,
    LocalStack’s {' '}
    <Link
      href={ExternalLink.LEGAL_PRIVACY_POLICY}
      target='_blank'
      underline="hover"
    >Privacy Policy</Link>{' '}
    and the{' '}
    <Link href={ExternalLink.LEGAL_DPA} target='_blank' underline="hover">
      &quot;LocalStack Data Processing Addendum&quot;
    </Link>{' '}
    (&quot;DPA&quot;).
    By providing LocalStack your Customer Data (as defined in the Terms)
    and/or clicking the &quot;{ACCEPT_TOS_BUTTON_CAPTION}&quot; button below,
    you agree to the Terms and DPA.
  </Typography>;
