import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiOutlinedInput'] => ({
  styleOverrides: {
    root: {
      '&:hover:not($disabled)': {
        backgroundColor: palette.background.default,
      },
      borderRadius: 8,
    },
  },
});
