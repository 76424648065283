import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const EC2_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.EC2}`;
const ELB_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ELB}`;
const ELBV2_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ELBV2}`;

export const DEFAULT_EC2_ROUTES: Record<string,string> = {
  RESOURCES_EC2: `${EC2_BASE_ROUTE}`,
  RESOURCES_EC2_INSTANCES: `${EC2_BASE_ROUTE}/instances`,
  RESOURCES_EC2_INSTANCE: `${EC2_BASE_ROUTE}/instances/:id`,
  RESOURCES_EC2_INSTANCE_NEW: `${EC2_BASE_ROUTE}/instances/new`,
  RESOURCES_EC2_LBV1: `${ELB_BASE_ROUTE}`,
  RESOURCES_EC2_LBV1_NEW: `${ELB_BASE_ROUTE}/new`,
  RESOURCES_EC2_LBV2: `${ELBV2_BASE_ROUTE}`,
  RESOURCES_EC2_LBV2_NEW: `${ELBV2_BASE_ROUTE}/new`,
};
