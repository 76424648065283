import { GlobalStateContext } from '@localstack/services';
import { LocalStackInstance } from '@localstack/types';
import { MenuItem } from '@mui/material';

import { useContext } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';

import { Dropdown, DropdownProps } from '../../display';

type InstanceSelectorButtonProps = {
  callback: (instance: LocalStackInstance) => void;
  label?: string;
  size?: DropdownProps['size'];
}

export const InstanceSelectorButton = (
  { callback, label, size='small' }: InstanceSelectorButtonProps,
): ReactElement => {
  const { instances } = useContext(GlobalStateContext);

  return (
    <Dropdown label={label} color='primary' variant='contained' size={size}>
      {instances.map((instance) => (
        <MenuItem onClick={() => callback(instance)} key={instance.id} value={instance.id}>
          {instance.name}
        </MenuItem>
      ))}
    </Dropdown>
  );

};
