import { ReactElement } from 'react';
import { CreateAgwRestApiKeyRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwRestApiKeyFormProps {
  formId?: string;
  loading?: boolean;
  onCreate: (data: CreateAgwRestApiKeyRequest) => unknown;
}

export const AgwRestApiKeyForm = ({
  loading,
  formId,
  onCreate,
}: AgwRestApiKeyFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateApiKeyRequest"
    formId={formId}
    onSubmit={(data: CreateAgwRestApiKeyRequest) => onCreate(data)}
  />
);
