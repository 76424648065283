import { ReactElement } from 'react';
import { CreateSGMModelRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants';


import { MagicForm } from '../../magic/MagicForm';
import { S3PathPicker } from '../../../form/S3PathPicker';
import { RelatedResourcePicker } from '../../../form';

export type CreateSGMModelProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateSGMModelRequest) => unknown;
}

export const SGMModelCreate = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: CreateSGMModelProps): ReactElement => {

  // eslint-disable-next-line arrow-body-style
  const submitHandler = async (data: CreateSGMModelRequest) => {
    return onSubmit(data);
  };

  return (
    <>
      <MagicForm
        schema={getSchema('SGM')}
        formId={formId}
        entry="CreateModelInput"
        loading={loading}
        onSubmit={(data: CreateSGMModelRequest) => submitHandler(data)}
        externalFields={{
          '^PrimaryContainer.ModelDataUrl$': (control, fieldName, required) => (
            <S3PathPicker
              control={control}
              fieldName={fieldName}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
          '^PrimaryContainer.ModelDataSource.S3DataSource.S3Uri$': (control, fieldName, required) => (
            <S3PathPicker
              control={control}
              fieldName={fieldName}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
          '^ExecutionRoleArn$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client='IAM' method='listRoles' arrayKeyName='Roles' property='Arn'
              fieldName={fieldName} entityName='IAM Role'
              creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
        }}
      />
    </>
  );
};
