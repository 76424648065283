import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwApiIntegrationRequest, UpdateAgwApiIntegrationRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwApiIntegrationForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { ApiNavTabs } from './components';

export const AgwApiIntegrationUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId: ApiId, integrationId: IntegrationId } = useParams<'apiId' | 'integrationId'>();

  const { data: api } = useAwsGetter('ApiGatewayV2', 'getApi', [{ ApiId }], { clientOverrides });

  const { data: integration } = useAwsGetter(
    'ApiGatewayV2',
    'getIntegration',
    [{ ApiId, IntegrationId }],
    { clientOverrides },
  );

  const { createIntegration, updateIntegration, isLoading } = useAwsEffect(
    'ApiGatewayV2',
    ['createIntegration', 'updateIntegration'],
    {
      revalidate: ['getIntegration', 'getIntegrations'],
      clientOverrides,
    },
  );

  const handleCreate = async (data: CreateAgwApiIntegrationRequest) => {
    await createIntegration(data);
    goto(routes.RESOURCES_AGW2_API_INTEGRATIONS, { apiId: ApiId });
  };

  const handleUpdate = async (data: UpdateAgwApiIntegrationRequest) => {
    await updateIntegration(data);
    goto(routes.RESOURCES_AGW2_API_INTEGRATION, { apiId: ApiId, integrationId: IntegrationId });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Integration Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW2)],
              [api?.Name, () => goto(routes.RESOURCES_AGW2_API, { apiId: ApiId })],
              [
                integration?.IntegrationId,
                () => goto(
                  routes.RESOURCES_AGW2_API_INTEGRATION,
                  { apiId: ApiId, integrationId: IntegrationId },
                ),
              ],
              [IntegrationId ? 'Update' : 'Create Integration', null],
            ]}
          />
        </Box>
      }
      tabs={<ApiNavTabs apiId={ApiId as string} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwApiIntegrationForm
            isWebsocket={api?.ProtocolType === 'WEBSOCKET'}
            apiId={ApiId as string}
            integration={integration}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
