/* eslint-disable @typescript-eslint/ban-ts-comment */

import { useCallback, useState, ReactElement } from 'react';
import { Box, Slider, Tooltip } from '@mui/material';
import { Theme, lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ThemeType } from '@localstack/constants';

const APPROX_MARKER_LABEL_W = 120; // px (note in storybook it has a different width ~170px)

export interface RangeSelectorProps {
  dates: string[];
  intervalStartDate: string;
  intervalEndDate: string;
  onChangeInterval: (start: string, end: string) => unknown;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'relative',
    height: theme.spacing(3),
  },
  input: {
    ...(theme.palette.mode === ThemeType.DARK && {
      colorScheme: ThemeType.DARK,
    }),
  },
  rail: {
    height: '24px',
    borderRadius: '12px',
    background: theme.palette.action.disabledBackground,
    border: `1px solid ${theme.palette.divider}`,
  },
  track: {
    height: '24px',
    background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
    // leaving this here in case we need it
    // '&::before': {
    //   fontSize: '20px',
    //   letterSpacing: '-2px',
    //   position: 'absolute',
    //   content: '"|||"',
    //   textAlign: 'center',
    //   color: lighten(theme.palette.primary.light, 0.5),
    //   left: '50%',
    //   top: 0,
    //   zIndex: 2,
    // },
  },
  active: {
    boxShadow: `0px 0px 0px 8px ${theme.palette.primary.light}77 !important`,
  },
  focusVisible: {
    boxShadow: `0px 0px 0px 6px ${theme.palette.primary.light}77`,
  },
  thumb: {
    marginTop: 0,
    cursor: 'col-resize',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    boxShadow: `0px 0px 0px 4px ${theme.palette.primary.light}`,
    '&:hover': {
      boxShadow: `0px 0px 0px 6px ${theme.palette.primary.light}`,
    },
    // eslint-disable-next-line
    background: `linear-gradient(0deg, ${lighten(theme.palette.primary.main, .5)}, ${lighten(theme.palette.primary.light, .5)})`,
    '&::before': {
      color: theme.palette.primary.main,
      zIndex: 2,
    },
  },
  mark: {
    marginTop: '24px',
    height: '3px',
    borderRadius: 0,
    background: theme.palette.action.disabledBackground,
  },
  markLabel: {
    marginTop: '24px',
  },
}));

const ValueLabelComponent =
  ({ open, value, children }: { open: boolean, value: number, children: ReactElement }) => (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );

export const RangeSelector = ({
  dates,
  intervalStartDate,
  intervalEndDate,
  onChangeInterval,
}: RangeSelectorProps): ReactElement => {
  const classes = useStyles();
  // const sliderRef = useRef<HTMLDivElement>();
  const [markers, setMarkers] = useState<{ value: number, label: string }[]>([]);

  const uniqueDates = Array.from(new Set(dates));

  const distributeLabels = (width: number): { value: number, label: string }[] => {
    if (width < APPROX_MARKER_LABEL_W) return [];

    const availableWidth = width - APPROX_MARKER_LABEL_W * 2;

    if (uniqueDates.length <= 2) return [];
    if (availableWidth < APPROX_MARKER_LABEL_W) return [];

    const remainingDates = uniqueDates.slice(1, uniqueDates.length - 1);
    const numLabelsToShow = Math.round(availableWidth / APPROX_MARKER_LABEL_W);

    // +2 ta take first and last labels into account
    const labelStepToShow = Math.round((remainingDates.length + 2) / numLabelsToShow);

    // note idx + 2 because we removed the first+last items in slice(),
    // so new array is 1 item "ahead" of original
    return remainingDates.reduce(
      (memo, date, idx) => (idx + 1) % labelStepToShow === 0
        ? [...memo, { value: idx + 2, label: date }]
        : [...memo, { value: idx + 2, label: '' }],
      [],
    );
  };

  const handleSliderRef = useCallback((node: HTMLDivElement) => {
    if (!node) return;

    setMarkers([
      { value: 0, label: uniqueDates[0] as string },
      ...distributeLabels(node.offsetWidth),
      {
        value: uniqueDates.length - 1,
        label: uniqueDates[uniqueDates.length - 1] as string,
      },
    ]);
  }, []);

  const minIndex = 0;
  const maxIndex = uniqueDates.length - 1;

  const statValue = intervalStartDate ? uniqueDates.findIndex((d) => d === intervalStartDate) : minIndex;
  const endValue = intervalEndDate ? uniqueDates.findIndex((d) => d === intervalEndDate) : maxIndex;

  const handleChangeSlider = (_e: unknown, [dateOneIdx, dateTwoIdx]: [number, number]) => {
    const dateOne = uniqueDates[dateOneIdx] as string;
    const dateTwo = uniqueDates[dateTwoIdx] as string;

    if (new Date(dateOne) > new Date(dateTwo)) {
      return onChangeInterval(dateTwo, dateOne);
    }

    onChangeInterval(dateOne, dateTwo);
  };

  return (
    <>
      <Box display="flex" alignItems="flex-start" mb={3}>
        {/* @ts-ignore */}
        <Box ref={handleSliderRef} flexGrow={1} ml={12} mr={12}>
          <Slider
            min={minIndex}
            max={maxIndex}
            step={1}
            valueLabelDisplay="auto"
            value={[statValue, endValue]}
            classes={{
              root: classes.root,
              rail: classes.rail,
              track: classes.track,
              thumb: classes.thumb,
              active: classes.active,
              focusVisible: classes.focusVisible,
              thumbColorPrimary: classes.thumb,
              thumbColorSecondary: classes.thumb,
              mark: classes.mark,
              markLabel: classes.markLabel,
            }}
            marks={markers}
            onChange={handleChangeSlider}
            valueLabelFormat={(value) => <div>{uniqueDates[value]}</div>}
            slots={{
              valueLabel: ValueLabelComponent,
            }}
          />
        </Box>
      </Box>
    </>
  );
};
