import { ReactElement } from 'react';
import { unset, cloneDeep } from 'lodash';
import { VALIDATION_RULES, transformMagicFieldName, getSchema } from '@localstack/services';

import {
  DynamoDBTable,
  CreateDynamoDBTableRequest,
  UpdateDynamoDBTableRequest,
} from '@localstack/types';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledTextField } from '../../../form';

// attrs leaked from describeTable
const UNWANTED_ATTRIBUTES = [
  'ProvisionedThroughput.NumberOfDecreasesToday',
  'ProvisionedThroughput.LastDecreaseDateTime',
  'ProvisionedThroughput.LastIncreaseDateTime',
];

export interface DynamoDBTableFormProps {
  table?: Optional<Partial<DynamoDBTable>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateDynamoDBTableRequest) => unknown;
  onUpdate: (data: UpdateDynamoDBTableRequest) => unknown;
}

export const DynamoDBTableForm = ({
  table,
  loading,
  formId,
  onCreate,
  onUpdate,
}: DynamoDBTableFormProps): ReactElement => (
  <MagicForm
    data={table}
    schema={getSchema('DynamoDB')}
    loading={loading}
    entry={table ? 'UpdateTableInput' : 'CreateTableInput'}
    formId={formId}
    fieldConditions={{
      '^ProvisionedThroughput': ['$BillingMode', '===', 'PROVISIONED'],
    }}
    externalFields={{
      '^TableName$': (control, name) => (
        <ControlledTextField
          control={control}
          name={name}
          label={transformMagicFieldName(name)}
          disabled={!!table}
          fullWidth
          required
          rules={VALIDATION_RULES.required}
          variant="outlined"
        />
      ),
    }}
    onSubmit={(data: CreateDynamoDBTableRequest) => {
      const cleanedData = UNWANTED_ATTRIBUTES.reduce((memo, field) => {
        const mutatedMemo = cloneDeep(memo);
        unset(mutatedMemo, field);
        return mutatedMemo;
      }, data);

      if (!table) return onCreate(cleanedData);
      return onUpdate({ ...cleanedData, TableName: table.TableName as string });
    }}
  />
);
