import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { CloudWatchMetric } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface MonitoringMetricsTableProps {
  metrics: CloudWatchMetric[];
  loading?: boolean;
  selectable?: boolean;
  onViewMetric?: (metric: CloudWatchMetric) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const MonitoringMetricsTable = ({
  metrics,
  loading,
  selectable,
  onViewMetric,
  onSelect,
}: MonitoringMetricsTableProps): ReactElement => {
  // Use custom identifier for metrics uniquness
  const metricsWithIds = metrics.map(
    (metric) => ({
      ...metric,
      $id: `${metric.Namespace}:${metric.MetricName}:${JSON.stringify(metric.Dimensions)}`,
    }),
  );

  return (
    <MagicTable
      schema={getSchema('CloudWatch')}
      entry="Metric"
      idAttribute="$id"
      rows={metricsWithIds}
      selectable={selectable}
      onSelect={onSelect}
      filterColumns={['MetricName', 'Namespace', 'Dimensions']}
      order={['MetricName', 'Namespace', 'Dimensions']}
      loading={loading}
      externalFields={{
        MetricName: (row) => (
          <Link onClick={() => onViewMetric && onViewMetric(row)} underline="hover">
            {row.MetricName}
          </Link>
        ),
        Dimensions: (row) => <>{JSON.stringify(row.Dimensions)}</>,
      }}
    />
  );
};
