import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueCrawlerForm, ProgressButton } from '@localstack/ui';
import { CreateGlueCrawlerRequest, UpdateGlueCrawlerRequest } from '@localstack/types';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { MainNavTabs } from './components';


export const GlueCrawlerUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { crawler } = useParams<'crawler'>();

  const { createCrawler, updateCrawler, isLoading } = useAwsEffect(
    'Glue',
    ['createCrawler', 'updateCrawler'],
    { revalidate: ['getCrawlers', 'getCrawler'], clientOverrides },
  );

  const { data: CrawlerData } = useAwsGetter(
    'Glue', 'getCrawler',
    [{ Name: crawler }], { clientOverrides });

  const handleCreate = async (data: CreateGlueCrawlerRequest) => {
    await createCrawler(data);
    goto(routes.RESOURCES_GLUE_CRAWLERS);
  };

  const handleUpdate = async (data: UpdateGlueCrawlerRequest) => {
    await updateCrawler(data);
    goto(routes.RESOURCES_GLUE_CRAWLER, { crawler });
  };

  return (
    <Layout
      documentTitle={`Glue: ${crawler ? 'Update' : 'Create'} Crawler`}
      title={
        <Box>
          <Typography variant="h4">{crawler ? 'Update' : 'Create'} Crawler</Typography>
          <Breadcrumbs
            mappings={[

              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Crawlers', () => goto(routes.RESOURCES_GLUE_CRAWLERS)],
              [`${crawler ? 'Update' : 'Create'} Crawler`, null],
            ]}
          />
        </Box>
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="UpsertCrawler"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueCrawlerForm
            crawler={CrawlerData?.Crawler}
            loading={isLoading}
            formId="UpsertCrawler"
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="UpsertCrawler"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
