import { getSchema } from '@localstack/services';
import { DescribeCostCategoryDefinitionResponse } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type CECostCategoryDefinitionDetailProps = {
  costCategoryDefinition?: Optional<Partial<DescribeCostCategoryDefinitionResponse>>;
};

export const CECostCategoryDefinitionDetail = ({
  costCategoryDefinition,
}: CECostCategoryDefinitionDetailProps): ReactElement => (
  <MagicDetails
    data={costCategoryDefinition?.CostCategory}
    schema={getSchema('CostExplorer')}
    entry="CostCategory"
    title="Cost Explorer Cost Category Definition Details"
  />
);
