import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueConnectionForm, ProgressButton } from '@localstack/ui';
import { CreateGlueConnectionRequest, UpdateGlueConnectionRequest } from '@localstack/types';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { MainNavTabs } from './components';


export const GlueConnectionUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { connection } = useParams<'connection'>();

  const { createConnection, updateConnection, isLoading } = useAwsEffect(
    'Glue',
    ['createConnection', 'updateConnection'],
    { revalidate: ['getConnections', 'getConnection'], clientOverrides },
  );

  const { data: ConnectionData } = useAwsGetter(
    'Glue', 'getConnection',
    [{ Name: connection }], { clientOverrides });

  const handleCreate = async (data: CreateGlueConnectionRequest) => {
    await createConnection(data);
    goto(routes.RESOURCES_GLUE_CONNECTIONS);
  };

  const handleUpdate = async (data: UpdateGlueConnectionRequest) => {
    await updateConnection(data);
    goto(routes.RESOURCES_GLUE_CONNECTION, { connection });
  };

  return (
    <Layout
      documentTitle={`Glue: ${connection ? 'Update' : 'Create'} Connection`}
      title={
        <Box>
          <Typography variant="h4">{connection ? 'Update' : 'Create'} Connection</Typography>
          <Breadcrumbs
            mappings={[

              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Connections', () => goto(routes.RESOURCES_GLUE_CONNECTIONS)],
              [`${connection ? 'Update' : 'Create'} Connection`, null],
            ]}
          />
        </Box>
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="UpsertConnection"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueConnectionForm
            connection={ConnectionData?.Connection}
            loading={isLoading}
            formId="UpsertConnection"
            onCreate={handleCreate}
            onUpdate={handleUpdate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="UpsertConnection"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
