import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiTab'] => ({
  styleOverrides: {
    root: {
      height: '50px',
      fontWeight: 400,
      textTransform: 'none' as const,
      fontSize: '14px',
      '@media (min-width: 960px)': {
        minWidth: '100px',
      },
      '&$selected': {
        fontWeight: 500,
      },
    },
    // label: {},
    // labelContainer: {},
    textColorPrimary: {
      color: palette.text.secondary,
    },
  },
});
