import { ReactElement } from 'react';
import { DynamoDBTable } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface DynamoDBTableDetailsProps {
  table?: Optional<Partial<DynamoDBTable>>;
}

export const DynamoDBTableDetails = ({ table }: DynamoDBTableDetailsProps): ReactElement => (
  <MagicDetails
    data={table}
    schema={getSchema('DynamoDB')}
    entry="TableDescription"
    title="DynamoDB Table Details"
  />
);
