/**
 * These are the attributes as defined by the OAuth spec and used by AWS Cognito.
 * See here for more info:
 * https://openid.net/specs/openid-connect-core-1_0.html#StandardClaims
 * https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html (under Standard Attributes)
 */

export const OAuthAttributes = [
  'address',
  'nickname',
  'birthdate',
  'phone_number',
  'email',
  'picture',
  'family_name',
  'preferred_username',
  'gender',
  'profile',
  'given_name',
  'zoneinfo',
  'locale',
  'updated_at',
  'middle_name',
  'website',
  'name',
];
