import { ReactElement, useEffect, useState } from 'react';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  Alert,
  AlertTitle,
  Autocomplete,
  Skeleton,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { CIKeysUsageChart, ConfirmableButton, PlanProductIcon, ProgressButton, UsageLimit } from '@localstack/ui';

import { CICreditRangesType, CiKey, Permission, ProductType } from '@localstack/types';

import {
  SubscriptionService,
  formatDate,
  obfuscateWithStars,
  useApiEffect,
  useApiGetter,
  useSnackbar,
} from '@localstack/services';
import classNames from 'classnames';


import {
  CloseOutlined as CancelIcon,
  FileCopyOutlined as CopyIcon,
  Delete as DeleteIcon,
  EditOutlined as EditIcon,
  LocalOffer as NameTagIcon,
  Cached as RotateIcon,
  CheckOutlined as SaveIcon,
  Assessment as InspectChartIcon,
  AssessmentOutlined as InspectChartOutlinedIcon,
} from '@mui/icons-material';


import { NavLink } from 'react-router-dom';

import { CustomerLayout } from '~/layouts';

import { useAuthProvider } from '~/hooks';
import { AppRoute } from '~/config';
import { useFeatureFlags } from '~/util/FeatureFlags';

const CI_CREDIT_RANGES: CICreditRangesType = {
  green: [0, 70],
  orange: [70, 90],
  red: [90, 100],
  extra: [100, 200],
};

const PAGE_PROPS = {
  title: 'CI Keys',
};

const useStyles = makeStyles((theme: Theme) => createStyles({
  table: {
    minWidth: 700,
    '& > *': {
      textWrap: 'nowrap',
    },

    '& thead': {
      height: theme.spacing(5),
    },
    '& tbody tr:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& tr td, & tr th': {
      padding: theme.spacing(1),
    },
    '& tr:last-child td': {
      borderBottom: 'none',
    },
  },

  editInPlace: {
    padding: '0.5em',
    margin: '-0.5em',
    marginLeft: '0px',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '1em',

    '&:hover': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.action.hover,
    },

    '&:focus-visible': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  editable: {
    outline: 'none',
    paddingRight: '1em',
    flexGrow: 1,
  },

  colorDanger: {
    color: theme.palette.error.main,
  },

  deleteCell: {
    width: '4em',
  },

  skeleton: {
    height: '150px',
  },

  skeletonWord: {
    width: '10em',
    height: '1em',
    transform: 'unset',
  },
  keysDropdown: {
    width: '15em',
  },
  keysDropdownOption: {
    textOverflow: 'ellipsis',
    textWrap: 'nowrap',
    overflow: 'hidden',
    display: 'block',
  },
  usageTotalIndicator: {
    height: 10,
    minWidth: 55,
  },
}));

type SubscriptionCiKeyPros = {
  subscriptionId: string,
  cardVariant: 'outlined' | 'elevation',
  orgKeys?: Array<CiKey>,
  isApiKeysLoading: boolean,
  quota: number,
  deletedKeysUsage: number,
}

const SubscriptionCiKeys = (
  {
    subscriptionId,
    cardVariant,
    orgKeys,
    isApiKeysLoading,
    quota,
    deletedKeysUsage,
  }: SubscriptionCiKeyPros): ReactElement => {

  const classes = useStyles();

  const { useFakeCiAuthToken } = useFeatureFlags();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => { showSnackbar({ message: 'CI key copied to clipboard', severity: 'success' }); })
      .catch(() => {
        showSnackbar({ message: 'An error occurred while copying to the clipboard', severity: 'error' });
      });
  };

  const { data: usageSummary, isLoading: isUsageSummaryLoading }
    = useApiGetter(SubscriptionService, 'getUsage', [subscriptionId]);

  const creditsUsed = usageSummary?.usage_by_type[ProductType.CI_USAGE] || 0;

  const { deleteCiKey, updateCiKey, rotateCiKey, isLoading: isCiKeyUpdating } =
    useApiEffect(SubscriptionService,
      [
        'deleteCiKey',
        'updateCiKey',
        'rotateCiKey',
      ],
      { revalidate: ['listCiKeys'] },
    );

  const { createCiKey, isLoading: isCiKeyCreating } =
    useApiEffect(SubscriptionService, ['createCiKey'], { revalidate: ['listCiKeys'] });

  const { showSnackbar } = useSnackbar();
  const [showKeys, setShowKeys] = useState<boolean>(false);

  const [newKeyFormName, setNewKeyFormName] = useState<string>('');

  const subscriptionCiKeys = (orgKeys || []).filter((key) => key.subscription_id === subscriptionId);

  const [editingKey, setEditingKey] = useState<CiKey | null>(null);
  const [editingKeyName, setEditingKeyName] = useState<string>('CI Key');

  const [showTooltips, setShowTooltips] = useState<boolean>(true);
  const [selectedKey, setSelectedKey] =
    useState<{ id: string; name: string; rotatedFrom: string[] } | undefined>(undefined);



  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && editingKey) {
        setEditingKey(null);
      }
    };

    document.addEventListener('keydown', handler, false);
    return () => document.removeEventListener('keydown', handler, false);
  }, [editingKey]);

  const { can } = useAuthProvider();

  return (
    <Grid container spacing={2}>
      {/* Usage overview */}
      <Grid item xs={12}>
        <Card variant={cardVariant}>
          <CardHeader title="CI Key Usage" />
          <CardContent style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
            <Typography variant='body1'>
              The use of LocalStack in an CI environment requires a CI key.
              Each startup of LocalStack is tracked and consumes 1 CI credit.
              The amount of CI credits available to your workspace depends on your current plan
              and the count will reset at the end of the monthly billing period.
            </Typography>
            <Typography variant='body1'>
              To keep track of the CI credit consumption in different projects and pipelines
              it is recommended to create a separate CI key for each and give it a descriptive name.
              You can create as many CI keys as you need, but all keys count towards the same sum.
            </Typography>

            {(isUsageSummaryLoading || !usageSummary) ? (
              <Skeleton variant="rectangular" className={classes.skeleton} />
            ) : (
              <List disablePadding>
                <ListItem key="credits">
                  <ListItemAvatar>
                    <PlanProductIcon productType={ProductType.CI_USAGE} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        Usage ({formatDate(usageSummary?.usage_period_start)} &ndash;
                        {' '}{formatDate(usageSummary?.usage_period_end)})
                      </>
                    }
                    secondary={
                      <Box pr={2}>
                        {creditsUsed} Credits
                        <UsageLimit
                          variant="edged"
                          greenRange={CI_CREDIT_RANGES.green}
                          orangeRange={CI_CREDIT_RANGES.orange}
                          redRange={CI_CREDIT_RANGES.red}
                          value={quota ? (Math.min(100, (creditsUsed / quota)) * 100) : 0}
                          displayValue={`${creditsUsed} Credits`}
                          tooltip={
                            <>
                              Consumed {creditsUsed} out of{' '}
                              {quota || '∞'} CI credits in this billing period
                            </>
                          }
                        />
                      </Box>
                    }
                  />
                </ListItem>
              </List>
            )}

          </CardContent>
        </Card>
      </Grid>

      {usageSummary && (
        <Grid item xs={12}>
          <Card variant={cardVariant}>
            <CardHeader
              title="Inspect CI key activations"
              action={
                <Box display="flex">
                  <Autocomplete
                    size="small"
                    options={subscriptionCiKeys}
                    getOptionLabel={key => `${key.name} (${obfuscateWithStars(key.api_key)})`}
                    className={classes.keysDropdown}
                    classes={{ option: classes.keysDropdownOption }}
                    renderInput={(params) => <TextField {...params} label="Filter data" variant="outlined" />}
                    value={subscriptionCiKeys.find(key => key.id === selectedKey?.id) || null}
                    onChange={(_e, selectedItem) => {
                      if (!selectedItem) return setSelectedKey(undefined);

                      const key = subscriptionCiKeys.find(item => item.api_key === selectedItem.id);
                      if (!key) return setSelectedKey(undefined);

                      setSelectedKey({
                        id: key.id,
                        name: key.name || '',
                        rotatedFrom: key.rotated_from,
                      });
                    }}
                  />
                </Box>
              }
            />
            <CardContent>
              <CIKeysUsageChart
                data={usageSummary}
                selectedKey={selectedKey}
                creditRangePercentages={CI_CREDIT_RANGES}
                creditsQuota={quota || 0}
                subscriptionId={subscriptionId}
              />
            </CardContent>
          </Card>
        </Grid>
      )}

      {/* Key List */}
      <Grid item xs={12}>
        <Card variant={cardVariant}>

          <CardHeader
            title="CI Keys"
            action={
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Switch
                      checked={showKeys}
                      color="primary"
                      onChange={(_, checked) => setShowKeys(checked)}
                    />
                  }
                  label="Show Keys"
                />
              </Box>
            }
          />
          <CardContent>
            <Box style={{ overflowX: 'auto' }}>
              <Table className={classNames(classes.table)}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>CI Key</TableCell>
                    <TableCell>Invocations ({creditsUsed} total)</TableCell>
                    <TableCell><Box pl={2}>Inspect usage</Box></TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isApiKeysLoading ? (
                    <>
                      {new Array(3).fill(0).map(_ => (
                        <TableRow>
                          <TableCell />
                          <TableCell><Skeleton className={classes.skeletonWord} /></TableCell>
                          <TableCell><Skeleton className={classes.skeletonWord} /></TableCell>
                          <TableCell><Skeleton className={classes.skeletonWord} /></TableCell>
                          <TableCell><Skeleton className={classes.skeletonWord} /></TableCell>
                        </TableRow>
                      ))}
                    </>
                  ) : (
                    <>
                      {subscriptionCiKeys.map((key) => {
                        const keysUsed = Math.round((key.usage / (quota || 0)) * 100);
                        const keyName = key.name || 'unnamed key';
                        const onSelectHandler = () => setSelectedKey(key.id === selectedKey?.id ? undefined : ({
                          id: key.id,
                          name: keyName,
                          rotatedFrom: key.rotated_from,
                        }));
                        return (
                          <TableRow key={key.id}>
                            <TableCell>
                              {editingKey ? (
                                <Box
                                  className={classes.editInPlace}
                                  display='flex'
                                  alignItems='center'
                                >
                                  <div
                                    className={classes.editable}
                                    contentEditable
                                    suppressContentEditableWarning
                                    spellCheck={false}
                                    onInput={(e) => setEditingKeyName(e.currentTarget.textContent ?? '')}
                                  >
                                    {key.name || 'unnamed key'}
                                  </div>

                                  <IconButton
                                    color="primary"
                                    size="small"
                                    disabled={isCiKeyUpdating || editingKeyName.length === 0}
                                    onClick={async () => {
                                      await updateCiKey(key.id, { name: editingKeyName });
                                      setEditingKey(null);
                                    }}
                                  >
                                    <SaveIcon />
                                  </IconButton>
                                  <IconButton
                                    color="primary"
                                    size="small"
                                    // disabled={loading}
                                    onClick={() => setEditingKey(null)}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </Box>
                              ) : (
                                <>
                                  {key.name || 'unnamed key'}

                                  <Tooltip title="Edit CI key name">
                                    <span>
                                      <IconButton
                                        color="primary"
                                        size="small"
                                        style={{ marginLeft: '0.5em' }}
                                        disabled={!can(Permission.WRITE_CI_KEYS)}
                                        aria-label='Edit CI Key'
                                        onClick={() => {
                                          setEditingKey(key);
                                          setEditingKeyName(key.name || 'CI Key');
                                        }}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </span>
                                  </Tooltip>
                                </>
                              )}
                            </TableCell>

                            <TableCell>
                              <Chip
                                variant='outlined'
                                style={{ fontFamily: 'monospace', letterSpacing: '0.1em' }}
                                label={showKeys ? key.api_key : obfuscateWithStars(key.api_key)}
                                deleteIcon={<CopyIcon />}
                                onDelete={() => copyToClipboard(key.api_key)}
                                onClick={() => copyToClipboard(key.api_key)}
                              />

                              <Tooltip title={showTooltips ? 'Rotate CI Key' : ''}>
                                <span>
                                  <ConfirmableButton
                                    componentType='IconButton'
                                    title="Rotate CI Key"
                                    text={
                                      `Are you sure you want to rotate the
                                  CI key '${key.name}' (${showKeys ? key.api_key : obfuscateWithStars(key.api_key)})?`
                                    }
                                    okText='Rotate Key'
                                    cancelText='Cancel'
                                    disabled={!can(Permission.WRITE_CI_KEYS)}
                                    size="small"
                                    color="primary"
                                    style={{ marginLeft: '0.5em' }}
                                    aria-label='Rotate CI Key'
                                    onClick={() => rotateCiKey(key.id, {
                                      use_new_token_format: useFakeCiAuthToken,
                                    })}
                                    onBeforeShowDialog={() => setShowTooltips(false)}
                                    onAfterHideDialog={() => setShowTooltips(true)}
                                  >
                                    <RotateIcon />
                                  </ConfirmableButton>
                                </span>
                              </Tooltip>
                            </TableCell>

                            <TableCell>
                              <Grid container alignItems='center' spacing={3}>
                                {!!quota && (
                                  <Grid item xs={4}>
                                    <LinearProgress
                                      className={classes.usageTotalIndicator} variant="determinate" value={keysUsed}
                                    />
                                  </Grid>
                                )}
                                <Grid item xs={8}>
                                  {key.usage} {quota ? `(${keysUsed}% of total quota)` : null}
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell>
                              <Box pl={5}>
                                <Tooltip title='Inspect this key in the chart'>
                                  <IconButton onClick={onSelectHandler} color='primary' size="large">
                                    {key.id === selectedKey?.id ? <InspectChartIcon /> : <InspectChartOutlinedIcon />}
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </TableCell>
                            <TableCell className={classes.deleteCell}>
                              <Tooltip title={showTooltips ? 'Delete CI Key' : ''}>
                                <span>
                                  <ConfirmableButton
                                    componentType='IconButton'
                                    title="Delete CI Key"
                                    text={`Are you sure you want to delete the
                                  CI key '${key.name}' (${showKeys ? key.api_key : obfuscateWithStars(key.api_key)})?`}
                                    okText='Delete'
                                    cancelText='Cancel'
                                    size="small"
                                    color="primary"
                                    aria-label="Delete CI Key"
                                    onClick={() => deleteCiKey(key.id)}
                                    disabled={!can(Permission.WRITE_CI_KEYS)}
                                    onBeforeShowDialog={() => setShowTooltips(false)}
                                    onAfterHideDialog={() => setShowTooltips(false)}
                                  >
                                    <DeleteIcon />
                                  </ConfirmableButton>
                                </span>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
            </Box>
            {!!deletedKeysUsage && <Box mt={2}>
              <Typography component='p' variant='caption' align='center'>
                (Not showing {deletedKeysUsage} invocation of deleted keys in the table)
              </Typography>
            </Box>}
          </CardContent>
        </Card>
      </Grid>

      {/* Create CI Key */}
      <Grid item xs={12}>
        {can(Permission.WRITE_CI_KEYS) ? (
          <Card variant={cardVariant}>
            <CardHeader title="Generate CI Key" />
            <CardContent>
              <form
                onSubmit={async (event) => {
                  event.preventDefault();
                  await createCiKey({
                    subscription_id: subscriptionId,
                    name: newKeyFormName,
                    use_new_token_format: useFakeCiAuthToken,
                  });
                  setNewKeyFormName('');
                }}
              >
                <Grid item container spacing={2} xs={12} direction='column'>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      placeholder="Descriptive Name"
                      size="small"
                      variant="outlined"
                      fullWidth
                      value={newKeyFormName}
                      onChange={({ target }) => setNewKeyFormName(target.value)}
                      disabled={isCiKeyCreating}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NameTagIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item >
                    <ProgressButton
                      variant='contained'
                      color="primary"
                      size="small"
                      type="submit"
                      disabled={newKeyFormName.length < 1}
                      loading={isCiKeyCreating}
                    >
                      Generate CI Key
                    </ProgressButton>
                  </Grid>
                </Grid>
              </form>
            </CardContent>
          </Card>
        ) : (
          <Alert severity='info'>
            You do not have the necessary permissions to create CI keys for this workspace.
            Reach out to your workspace admin to review permissions for your account.
          </Alert>
        )}
      </Grid>

    </Grid>
  );
};

export const CIKeys = (): ReactElement => {

  const { data: subscriptionCiKeys,
    isLoading: isApiKeysLoading } = useApiGetter(SubscriptionService, 'listCiKeys', []);

  const { can } = useAuthProvider();

  const classes = useStyles();

  if (!can(Permission.READ_CI_KEYS)) {
    return (
      <CustomerLayout {...PAGE_PROPS}>
        <Alert severity='error'>
          You do not have the necessary permissions to view or manage CI keys for this workspace.
          Reach out to your workspace admin to review permissions for your account.
        </Alert>
      </CustomerLayout>
    );
  }

  return (
    <CustomerLayout {...PAGE_PROPS} >
      {(isApiKeysLoading || !subscriptionCiKeys) ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" className={classes.skeleton} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" className={classes.skeleton} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="rectangular" className={classes.skeleton} />
          </Grid>
        </Grid>
      ) : (
        <>
          {subscriptionCiKeys.length === 0 && (
            <Alert severity='info'>
              <AlertTitle>No Subscription in Workspace</AlertTitle>
              There is currently no subscription in this workspace. To add CI keys first subscribe to a plan
              on the <NavLink to={AppRoute.WORKSPACE_SUBSCRIPTIONS}>Subscriptions</NavLink> page.
            </Alert>
          )}

          {((subscriptionCiKeys.length === 1) && subscriptionCiKeys[0]) && (
            <SubscriptionCiKeys
              subscriptionId={subscriptionCiKeys[0].subscription_id}
              quota={subscriptionCiKeys[0].quota}
              cardVariant='elevation'
              orgKeys={subscriptionCiKeys[0].keys}
              isApiKeysLoading={isApiKeysLoading}
              deletedKeysUsage={subscriptionCiKeys[0].deleted_keys_usage}
            />
          )}

          {subscriptionCiKeys.length > 1 && (
            <Grid container spacing={2}>
              {subscriptionCiKeys.map((subKeys) => (
                <Grid item xs={12} key={subKeys.subscription_id}>
                  <Card>
                    <CardHeader title={subKeys.subscription_display_name} />
                    <CardContent>
                      <SubscriptionCiKeys
                        subscriptionId={subKeys.subscription_id}
                        orgKeys={subKeys.keys}
                        quota={subKeys.quota}
                        deletedKeysUsage={subKeys.deleted_keys_usage}
                        isApiKeysLoading={isApiKeysLoading}
                        cardVariant='outlined'
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </>
      )}
    </CustomerLayout>
  );
};
