import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { DMSRoutes } from '../types';

type EndpointProps = {
  routes: DMSRoutes;
  endpointId: string;
}

export const EndpointTabs = ({ routes, endpointId }: EndpointProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_DMS_ENDPOINT, { id: endpointId })} end>
      Details
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_DMS_ENDPOINT_CONNECTIONS, { id: endpointId })} end>
      Connections
    </NavLink>
  </>
);
