import { ReactElement } from 'react';
import { LambdaFunctionConfig, LambdaFunctionCodeLocation } from '@localstack/types';

import { MagicDetails } from '../../../magic/MagicDetails';

export type LambdaCodeDetailsProps = {
  lambdaFunction: Optional<LambdaFunctionConfig>;
  lambdaFunctionCodeLocation: Optional<LambdaFunctionCodeLocation>;
};

const SCHEMA = {
  shapes: {
    LambdaFunctionRefactored: {
      type: 'structure',
      members: {
        Location: {
          type: 'string',
        },
        Runtime: {
          type: 'string',
        },
        Handler: {
          type: 'string',
        },
        CodeSize: {
          type: 'integer',
        },
        CodeSha256: {
          type: 'string',
        },
      },
    },
  },
};

export const LambdaCodeDetails = ({
  lambdaFunction,
  lambdaFunctionCodeLocation,
}: LambdaCodeDetailsProps): ReactElement => (
  <MagicDetails
    data={{ ...lambdaFunction, ...lambdaFunctionCodeLocation }}
    schema={SCHEMA}
    entry="LambdaFunctionRefactored"
    title="Details for Lambda"
  />
);
