import { ReactElement } from 'react';
import { AwsClientOverrides, buildRoute, getSchema } from '@localstack/services';

import {
  AppSyncResolver,
  CreateAppSyncResolverRequest,
  UpdateAppSyncResolverRequest,
} from '@localstack/types';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor, RelatedResourcePicker } from '../../../../form';

export interface AppSyncResolverFormProps {
  apiId: string;
  clientOverrides?: AwsClientOverrides;
  typeName: string;
  resolver?: Optional<Partial<AppSyncResolver>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAppSyncResolverRequest) => unknown;
  onUpdate: (data: UpdateAppSyncResolverRequest) => unknown;
}

export const AppSyncResolverForm = ({
  apiId,
  clientOverrides,
  typeName,
  resolver,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AppSyncResolverFormProps): ReactElement => (
  <MagicForm
    data={resolver}
    schema={getSchema('AppSync')}
    loading={loading}
    entry={resolver ? 'UpdateResolverRequest' : 'CreateResolverRequest'}
    formId={formId}
    fieldConditions={{
      '^(apiId|typeName)$': false,
      '^fieldName$': !resolver,
      '^syncConfig\\.lambdaConflictHandlerConfig': ['$syncConfig.conflictHandler', '===', 'LAMBDA'],
      '^pipelineConfig': ['$kind', '===', 'PIPELINE'],
    }}
    externalFields={{
      '^requestMappingTemplate$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          // eslint-disable-next-line
          // @ts-ignore
          language="velocity"
        />
      ),
      '^responseMappingTemplate$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="json"
        />
      ),
      '^dataSourceName$': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName} entityName='Data Source'
          client='AppSync'
          method='listDataSources'
          arrayKeyName='dataSources'
          property='name'
          args={[{ apiId }]}
          clientOverrides={clientOverrides}
          creationRoute={buildRoute(DEFAULT_APPSYNC_ROUTES.RESOURCES_APPSYNC_API_DATA_SOURCE_CREATE, { apiId })}
        />
      ),
    }}
    onSubmit={(data: CreateAppSyncResolverRequest | UpdateAppSyncResolverRequest) => {
      if (!resolver) return onCreate({ ...data, apiId, typeName } as CreateAppSyncResolverRequest);
      return onUpdate({ ...data, apiId, typeName, fieldName: resolver?.fieldName as string });
    }}
  />
);
