import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAthenaDataCatalogRequest, UpdateAthenaDataCatalogRequest } from '@localstack/types';
import { ProgressButton, AthenaDataCatalogForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_ATHENA_ROUTES } from '@localstack/constants';

import { AthenaProps } from './types';

export const AthenaCatalogUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ATHENA_ROUTES,
}: AthenaProps): ReactElement => {
  const { goto } = useRoutes();
  const { catalogName } = useParams<'catalogName'>();

  const { createDataCatalog, updateDataCatalog, isLoading } = useAwsEffect(
    'Athena',
    ['createDataCatalog', 'updateDataCatalog'],
    { revalidate: ['listDataCatalogs'], clientOverrides },
  );

  const { data: catalog } = useAwsGetter('Athena', 'getDataCatalog', [{ Name: catalogName }],{ clientOverrides });

  const handleCreate = async (data: CreateAthenaDataCatalogRequest) => {
    await createDataCatalog(data);
    goto(routes.RESOURCES_ATHENA_CATALOGS);
  };

  const handleUpdate = async (data: UpdateAthenaDataCatalogRequest) => {
    await updateDataCatalog(data);
    goto(routes.RESOURCES_ATHENA_CATALOGS);
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Catalog Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Athena', () => goto(routes.RESOURCES_ATHENA_DATABASES)],
              ['Catalogs', () => goto(routes.RESOURCES_ATHENA_CATALOGS)],
              [catalogName ? 'Update' : 'Create Catalog', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AthenaDataCatalogForm
            catalog={catalog?.DataCatalog}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
