import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const CODECOMMIT_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.CODECOMMIT}`;

export const DEFAULT_CODECOMMIT_ROUTES: Record<string, string> = {
  RESOURCES_CODECOMMIT_REPOSITORIES: `${CODECOMMIT_BASE_ROUTE}/repositories`,
  RESOURCES_CODECOMMIT_REPOSITORY: `${CODECOMMIT_BASE_ROUTE}/repository/:id`,
  RESOURCES_CODECOMMIT_REPOSITORY_CREATE: `${CODECOMMIT_BASE_ROUTE}/repository/new`,
  RESOURCES_CODECOMMIT_REPOSITORY_UPDATE: `${CODECOMMIT_BASE_ROUTE}/repository/:id/update`,
};
