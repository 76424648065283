import { ReactElement } from 'react';
import { AgwApiModel } from '@localstack/types'; 
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwApiModelsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  models: AgwApiModel[];
  onViewModel?: (model: AgwApiModel) => unknown;
  onSelect?: (ids: string[]) => void;
};

export const AgwApiModelsTable = ({
  models,
  selectable = true,
  loading,
  onViewModel,
  onSelect,
}: AgwApiModelsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ApiGatewayV2')}
    entry="Model"
    idAttribute="ModelId"
    rows={models}
    selectable={selectable}
    onSelect={onSelect}
    order={['ModelId']}
    loading={loading}
    externalFields={{
      ModelId: (row) => (
        <Link onClick={() => onViewModel && onViewModel(row)} underline="hover">
          {row.ModelId}
        </Link>
      ),
    }}
  />
);
