import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';
import { Breadcrumbs, DMSReplicationTaskDetail } from '@localstack/ui';

import { DMSProps } from './types';
import { ReplicationTaskTabs } from './components/ReplicationTaskTabs';

export const DMSReplicationTask = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DMS_ROUTES,
}: DMSProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: replicationTask } = useAwsGetter(
    'DMS',
    'describeReplicationTasks',
    [{ Filters: [{ Name: 'replication-task-arn', Values: [id || ''] }] }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      tabs={<ReplicationTaskTabs routes={routes} replicationTaskId={id || ''} />}
      title={
        <Box>
          <Typography variant="h4">DMS Replication Task</Typography>
          <Breadcrumbs
            mappings={[
              ['DMS', () => goto(routes.RESOURCES_DMS_REPLICATION_TASKS)],
              [id, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <DMSReplicationTaskDetail replicationTask={replicationTask?.ReplicationTasks?.[0]} />
      </Card>
    </Layout>
  );
};
