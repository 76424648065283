import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { ProgressButton, IAMRolePolicyUpsertForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants/src';
import { PutIAMRolePolicyRequest } from '@localstack/types';

import { RoleTabs } from './components';
import { IAMProps } from './types';

export const IAMRolePolicyUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_IAM_ROUTES,
}: IAMProps): ReactElement => {
  const { goto } = useRoutes();
  const { roleName, policyName } = useParams<'roleName' | 'policyName'>();

  const { data: rolePolicy } = useAwsGetter('IAM', 'getRolePolicy', [{
    RoleName: roleName,
    PolicyName: policyName,
  }], { clientOverrides });

  const { putRolePolicy, isLoading } = useAwsEffect(
    'IAM',
    ['putRolePolicy'],
    { revalidate: ['getRolePolicy', 'listRolePolicies'], clientOverrides },
  );

  const handleUpsert = async (data: PutIAMRolePolicyRequest) => {
    await putRolePolicy(data);
    goto(routes.RESOURCES_IAM_ROLE_POLICIES, { roleName });
  };

  return (
    <Layout
      documentTitle={`IAM: ${policyName ? 'Update' : 'Create'} Role Policy`}
      title={
        <Box>
          <Typography variant="h4">{policyName ? 'Update' : 'Create'} Role Policy</Typography>
          <Breadcrumbs
            mappings={[
              ['IAM', () => goto(routes.RESOURCES_IAM)],
              [roleName, () => goto(routes.RESOURCES_IAM_ROLE, { roleName })],
              ['Policies', () => goto(routes.RESOURCES_IAM_ROLE_POLICIES, { roleName })],
              [policyName, () => goto(routes.RESOURCES_IAM_ROLE_POLICIES, { roleName })],
              [`${policyName ? 'Update' : 'Create'} Role Policy`, () => null],
            ]}
          />
        </Box>
      }
      tabs={<RoleTabs routes={routes} roleName={roleName} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <IAMRolePolicyUpsertForm
            roleName={roleName}
            rolePolicy={rolePolicy ?? undefined}
            onUpsert={handleUpsert}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
