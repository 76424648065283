import { getSchema } from '@localstack/services/src';
import { 
  DescribeCostCategoryDefinitionResponse, 
  CreateCostCategoryDefinitionRequest,
  UpdateCostCategoryDefinitionRequest, 
} from '@localstack/types';
import { DEFAULT_CE_VALUES } from '@localstack/constants';
import { ReactElement } from 'react';

import { ControlledTextField } from '../../../form';
import { MagicForm } from '../../magic/MagicForm';

export type CECostCategoryDefinitionFormProps = {
  costCategoryDefinition?: Optional<DescribeCostCategoryDefinitionResponse>;
  formId: string;
  onSubmit: (data: CreateCostCategoryDefinitionRequest | UpdateCostCategoryDefinitionRequest) => void;
  loading?: boolean;
};

export const CECostCategoryDefinitionForm = ({
  costCategoryDefinition,
  formId,
  onSubmit,
  loading,
}: CECostCategoryDefinitionFormProps): ReactElement => (
  <MagicForm
    entry={costCategoryDefinition ? 'UpdateCostCategoryDefinitionRequest' : 'CreateCostCategoryDefinitionRequest'}
    schema={getSchema('CostExplorer')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={costCategoryDefinition?.CostCategory}
    defaultValues={DEFAULT_CE_VALUES?.CreateCostCategoryDefinitionRequest?.input}
    externalFields={{
      'EffectiveStart$': (control) => (
        <ControlledTextField
          fullWidth
          required
          control={control}
          name="EffectiveStart"
          variant="outlined"
          label="EffectiveStart"
          type='date'
          InputLabelProps={{ shrink: true }}
        />
      ),
    }}
  />
);
