import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const ECR_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ECR}`;

export const DEFAULT_ECR_ROUTES: Record<string,string> = {
  RESOURCES_ECR_REPOSITORIES: `${ECR_BASE_ROUTE}/repositories`,
  RESOURCES_ECR_REPOSITORY_NEW: `${ECR_BASE_ROUTE}/repository/new`,
  RESOURCES_ECR_IMAGES: `${ECR_BASE_ROUTE}/repository/:name/images`,
  RESOURCES_ECR_IMAGE_NEW: `${ECR_BASE_ROUTE}/image/new`,
};
