export enum ThemeType {
  DARK = 'dark',
  LIGHT = 'light',
  SYSTEM = 'system'
}

export enum LocalStackEventType {
  THEME_UPDATE = 'theme_update',
  THEME_INJECT = 'theme_inject',
}

export interface EventDataMap {
  [LocalStackEventType.THEME_UPDATE]: { theme: ThemeType };
  [LocalStackEventType.THEME_INJECT]: { theme: string };
}

export type LocalStackWebEvent = {
  [K in keyof EventDataMap]: {
    eventType: K;
    data: EventDataMap[K];
  };
}[keyof EventDataMap];

export type LocalStackEventData = EventDataMap[keyof EventDataMap];
