import { IconButton, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Close } from '@mui/icons-material';
import classNames from 'classnames';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      gap: theme.spacing(2),
      padding: theme.spacing(1),
      display: 'flex',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      background: `linear-gradient(45deg,
        #4D29B4 0%,
        #6B7CF5 50%,
        #70adfe 100%);`,
    },
    variantRainbow: {
      background: `radial-gradient(circle, rgba(77,41,180,1) 0%,
        rgba(116,255,230,1) 100%)`,
    },
    closeButton: {
      color: 'white',
    },
  }),
);


type BannerProps = {
  title: string | ReactElement;
  actions?: ReactElement;
  onClose: () => void;
  variant?: 'rainbow' | 'blue';
}

export const Banner = ({ title, actions, onClose, variant = 'blue' }: BannerProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.root, { [classes.variantRainbow]: (variant === 'rainbow') })}>
      {title}
      {actions}
      <IconButton
        size="medium"
        className={classes.closeButton}
        onClick={onClose}
      >
        <Close />
      </IconButton>
    </div>
  );
};
