import { useState, useCallback, useEffect, useMemo, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Button, Box, Typography, Link } from '@mui/material';
import { ScanDynamoDBRequest, QueryDynamoDBRequest } from '@localstack/types';
import { DEFAULT_DYNAMODB_ROUTES } from '@localstack/constants';

import {
  useRoutes,
  useAwsEffect,
  useAwsGetter,
  useAwsKeysMemo,
  usePrevious,
  buildDynamoDBPrimaryKey,
} from '@localstack/services';

import {
  Dropdown,
  Breadcrumbs,
  ConfirmableButton,
  DynamoDBExpressionBuilder,
  DynamoDBItemsTable,
  DYNAMODB_ITEMS_TABLE_RANGE_SEPARATOR,
} from '@localstack/ui';

import { DynamoDBProps } from './types';

const PAGE_SIZE = 50;

export const DynamoDBTableItems = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DYNAMODB_ROUTES,
}: DynamoDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { tableName } = useParams<'tableName'>();

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [mode, setMode] = useState<'scan' | 'query'>('scan');
  const [page, setPage] = useState(0);

  const DEFAULT_SCAN_DATA = { 'TableName': tableName || '' };
  const [query, setQuery] = useState<Optional<QueryDynamoDBRequest>>(null);
  const [scan, setScan] = useState<Optional<QueryDynamoDBRequest>>(DEFAULT_SCAN_DATA);

  const prevQuery = usePrevious(query);
  const prevScan = usePrevious(scan);

  const [paginationKey, setPaginationKey] = useState<any>(null); // eslint-disable-line

  const { data: table, isLoading: isTableLoading } =
    useAwsGetter('DynamoDB', 'describeTable', [{ TableName: tableName }], { clientOverrides });

  const scanArgs = useMemo(
    () => ({ ...scan, ExclusiveStartKey: paginationKey, Limit: PAGE_SIZE }),
    [scan, paginationKey],
  );
  const queryArgs = useMemo(
    () => ({ ...query, ExclusiveStartKey: paginationKey, Limit: PAGE_SIZE }),
    [query, paginationKey],
  );

  const { data: scanResult, isLoading: isScanning, mutate: mutateScan } = useAwsGetter(
    'DynamoDB', 'scan', [scanArgs],
    { defaultValue: { Items: [], LastEvaluatedKey: undefined }, clientOverrides },
  );

  const { data: queryResult, isLoading: isQuerying, mutate: mutateQuery } = useAwsGetter(
    'DynamoDB', 'query', [queryArgs],
    { defaultValue: { Items: [], LastEvaluatedKey: undefined }, clientOverrides },
  );

  // A hacky way to re-load the data even when arguments have not changed
  useEffect(() => {
    const [newScanDump, oldScanDump] = [JSON.stringify(scan), JSON.stringify(prevScan)];
    if (scan && prevScan && scan !== prevScan && newScanDump === oldScanDump) mutateScan();
  }, [scan, prevScan]);
  useEffect(() => {
    const [newQueryDump, oldQueryDump] = [JSON.stringify(query), JSON.stringify(prevQuery)];
    if (query && prevQuery && query !== prevQuery && newQueryDump === oldQueryDump) mutateQuery();
  }, [query, prevQuery]);

  const scanKeys = useAwsKeysMemo(page, scanResult?.LastEvaluatedKey);
  const queryKeys = useAwsKeysMemo(page, queryResult?.LastEvaluatedKey);

  const result = mode === 'scan' ? scanResult : queryResult;
  const isLoading = isTableLoading || isScanning || isQuerying;

  const { delete: deleteItem } = useAwsEffect(
    'DynamoDBDocument',
    ['delete'],
    { revalidate: ['scan', 'query'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((hashAndRangeKey) => {
      const [hashKeyVal, rangeKeyVal] = hashAndRangeKey.split(DYNAMODB_ITEMS_TABLE_RANGE_SEPARATOR);
      const Key = buildDynamoDBPrimaryKey(table?.Table, hashKeyVal, rangeKeyVal);
      return deleteItem({ TableName: tableName as string, Key });
    });

    await Promise.all(promises);
  }, [selectedIds]);

  useEffect(() => {
    setPaginationKey(null);
    if (mode === 'scan') setQuery(null);
    if (mode === 'query') setScan(DEFAULT_SCAN_DATA);
  }, [mode]);

  useEffect(() => {
    const lastEvalKeys = mode === 'scan' ? scanKeys : queryKeys;
    setPaginationKey(lastEvalKeys[page]);
  }, [page]);

  return (
    <Layout
      documentTitle="Table Items"
      tabs={<>
        <Link onClick={() => setMode('scan')} underline={mode === 'scan' ? 'always' : undefined}>
          Scan
        </Link>
        <Link onClick={() => setMode('query')} underline={mode === 'query' ? 'always' : undefined}>
          Query
        </Link>
      </>}
      title={
        <Box>
          <Typography variant="h4">Table Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DynamoDB', () => goto(routes.RESOURCES_DYNAMODB)],
              [table?.Table?.TableName, () => goto(routes.RESOURCES_DYNAMODB_TABLE, { tableName })],
              ['Items', null],
            ]}
          />
        </Box>
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_DYNAMODB_TABLE_ITEM_CREATE, { tableName })}>
            Create Item
          </Button>

          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Item(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Items will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card variant="outlined">
        <CardContent>
          <DynamoDBExpressionBuilder
            table={table?.Table}
            formId="expression"
            mode={mode}
            onSubmit={(data: ScanDynamoDBRequest | QueryDynamoDBRequest) => {
              setPage(0);
              if (mode === 'scan') setScan(data);
              if (mode === 'query') setQuery(data);
            }}
          />
        </CardContent>
        <CardActions>
          <Button
            type="submit"
            form="expression"
            color="primary"
            variant="outlined"
          >
            Submit
          </Button>
        </CardActions>
      </Card>
      <Box mt={3}>
        <Card>
          <DynamoDBItemsTable
            selectable
            page={page}
            pageSize={PAGE_SIZE}
            table={table?.Table}
            rows={result?.Items ?? []}
            loading={isLoading}
            hasMore={!!result?.LastEvaluatedKey}
            onPageChange={setPage}
            onSelect={setSelectedIds}
            onViewRow={(_, hashKey, rangeKey) => goto(
              routes.RESOURCES_DYNAMODB_TABLE_ITEM_RANGE_UPDATE,
              { tableName, hashKey, rangeKey },
            )}
          />
        </Card>
      </Box>
    </Layout>
  );
};
