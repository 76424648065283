import { getSchema } from '@localstack/services';
import { GetAppResult, CreateAppRequest, UpdateAppRequest } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type AmplifyAppFormProps = {
  app?: Optional<Partial<GetAppResult>>;
  formId: string;
  onSubmit: (data: CreateAppRequest | UpdateAppRequest) => void;
  loading?: boolean;
};

export const AmplifyAppForm = ({
  app,
  formId,
  onSubmit,
  loading,
}: AmplifyAppFormProps): ReactElement => (
  <MagicForm
    entry={app ? 'UpdateAppRequest' : 'CreateAppRequest'}
    schema={getSchema('Amplify')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={app?.app}
    fieldConditions={{ appId: false }}
  />
);
