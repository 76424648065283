import { ReactElement } from 'react';
import { RegisterScalableTargetsRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';
import { MagicForm } from '../../magic/MagicForm';

export interface ScalableTargetFormProps {
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: RegisterScalableTargetsRequest) => unknown;
}

export const ScalableTargetForm = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: ScalableTargetFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('ApplicationAutoScaling')}
    loading={loading}
    entry="ScalableTarget"
    formId={formId}
    onSubmit={(data: RegisterScalableTargetsRequest) => onSubmit(data)}
    externalFields={{
      '^RoleARN$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='IAM' method='listRoles' arrayKeyName='Roles' property='Arn'
          fieldName={fieldName} entityName='IAM Role'
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
  />
);
