import { ReactElement } from 'react';
import { GlueSchema } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueSchemasTableProps = {
  schemas: GlueSchema[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewSchema?: (schemaName: string, registryName: string) => void;
};

export const GlueSchemasTable = ({
  schemas,
  selectable = true,
  loading,
  onSelect,
  onViewSchema,
}: GlueSchemasTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="SchemaListItem"
    idAttribute="SchemaName"
    rows={schemas}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    filterColumns={['SchemaName', 'RegistryName', 'Description', 'CreatedTime', 'UpdatedTime']}
    order={['SchemaName', 'RegistryName', 'Description', 'CreatedTime', 'UpdatedTime']}
    externalFields={{
      SchemaName: (row) => (
        <Link
          onClick={() => onViewSchema && onViewSchema(row.SchemaName as string, row.RegistryName as string)}
          underline="hover"
        >
          {row.SchemaName}
        </Link>
      ),
    }}
  />
);
