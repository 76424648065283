import { useState, useCallback, ReactElement, ChangeEvent } from 'react';
import { Autocomplete ,
  Box,
  TextField,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { MembershipRole } from '@localstack/types';
import { ProgressButton } from '@localstack/ui';



type Props = {
  className?: string;
  loading: boolean,
  searching: boolean;
  searchedOrganizations: [string, string][];
  onSearchOrganization: (query: string) => unknown;
  onCreateMember: (orgId: string, roles: MembershipRole[]) => unknown;
}

export const MemberForm = ({
  className,
  loading,
  searching,
  searchedOrganizations,
  onSearchOrganization,
  onCreateMember,
}: Props): ReactElement => {
  const [org, setOrg] = useState<[Optional<string>, Optional<string>]>([null, null]);
  const [roles, setRoles] = useState<MembershipRole[]>([]);

  const handleOnCreateMember = useCallback(() => {
    if (!org[0] || roles.length === 0) return;
    onCreateMember(org[0], roles);
  }, [org, roles, onCreateMember]);

  return (
    <Card className={className} variant="outlined">
      <CardHeader title="Add new Membership" />
      <CardContent>
        <Box>
          <Autocomplete
            options={searchedOrganizations}
            getOptionLabel={(option: [string, string]) => option[1]}
            onChange={(_, value) => setOrg(value as [string, string])}
            value={org}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Organization"
                variant="outlined"
                onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchOrganization(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searching ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box mt={3}>
          <FormControl variant="outlined" fullWidth required>
            <InputLabel>Member Roles</InputLabel>
            <Select
              variant="standard"
              multiple
              value={roles}
              label="Member Roles"
              onChange={({ target }) => setRoles(target.value as MembershipRole[])}
            >
              <MenuItem value={MembershipRole.ADMIN}>Admin</MenuItem>,
              <MenuItem value={MembershipRole.MEMBER}>Member</MenuItem>,
            </Select>
          </FormControl>
        </Box>
      </CardContent>
      <CardActions>
        <ProgressButton
          color="primary"
          variant="contained"
          size="small"
          loading={loading}
          disabled={!org || roles.length === 0 || searching}
          onClick={handleOnCreateMember}
        >
          Add
        </ProgressButton>
      </CardActions>
    </Card>
  );
};
