import { DEFAULT_SQS_ROUTES } from '@localstack/constants';
import { useRoutes, useAwsGetter, refineSqsUrl, useEndpoint } from '@localstack/services';
import { SQSQueueAttributes } from '@localstack/types';
import { LoadingFragment, SQSQueueDetails, Breadcrumbs } from '@localstack/ui';
import { Card, Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';


import { QueueNavTabs } from './components';
import { SQSProps } from './types';

export const SQSQueue = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SQS_ROUTES,
}: SQSProps): ReactElement => {
  const { goto } = useRoutes();
  const { name } = useParams<'name'>();

  const { endpoint } = useEndpoint('SQS');

  const { data: url } = useAwsGetter('SQS', 'getQueueUrl', [{ QueueName: name }], { clientOverrides });

  const { data: attributes, isLoading } = useAwsGetter('SQS', 'getQueueAttributes',
    [{ QueueUrl: refineSqsUrl(url?.QueueUrl, clientOverrides?.endpoint ?? endpoint), AttributeNames: ['All'] }],
    { defaultValue: { Attributes: {} as SQSQueueAttributes }, clientOverrides });

  const queue = {
    QueueUrl: refineSqsUrl(url?.QueueUrl, clientOverrides?.endpoint ?? endpoint),
    Name: name,
    attributes: attributes?.Attributes,
  };

  return (
    <>
      <Layout
        title={
          <Box>
            <Typography variant="h4">SQS Queue</Typography>
            <Breadcrumbs
              mappings={[
                ['SQS', () => goto(routes.RESOURCES_SQS_QUEUES)],
                [name, null],
              ]}
            />
          </Box>
        }
        tabs={<QueueNavTabs name={name as string} routes={routes} />}
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <SQSQueueDetails attributes={queue.attributes} />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
