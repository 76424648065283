import { ThemeType } from '@localstack/constants';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { ReactElement } from 'react';

import LogoRocketSvg from './localstack-rocket.svg';

export interface LogoRocketProps {
  variant?: 'contrast' | 'default';
  size?: 'small' | 'medium' | 'large';
  display?: 'color' | 'grayscale';
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  small: {
    height: theme.spacing(2.5),
    width: 'auto',
  },
  medium: {
    height: theme.spacing(4),
    width: 'auto',
  },
  dark: {
    '& .rocket': {
      fill: theme.palette.grey[400],
    },
  },
  light: {
    '& .rocket': {
      fill: theme.palette.text.primary,
    },
  },
  grayscale: {
    filter: 'grayscale(100%)', 
  },
  root: {
    flexShrink: 0,
  },
}));

export const LogoRocket = ({
  variant = 'default',
  size = 'large',
  display = 'color',
}: LogoRocketProps): ReactElement => {
  const classes = useStyles();
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === ThemeType.DARK;

  return (
    <LogoRocketSvg className={classnames({
      [classes.dark]: variant === 'contrast' && !isDarkTheme,
      [classes.light]: variant === 'contrast' && isDarkTheme,
      [classes.medium]: size === 'medium',
      [classes.small]: size === 'small',
      [classes.grayscale]: display === 'grayscale',
      [classes.root]: true,
    })}
    />
  );
};
