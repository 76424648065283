import { ReactElement } from 'react';
import { Card } from '@mui/material';
import { Theme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    borderColor: theme.palette.error.main,
  },
}));

type Props = {
  children: JSX.Element;
};

export const DangerZone = ({ children }: Props): ReactElement => {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.root}>
      {children}
    </Card>
  );
};

export default DangerZone;
