import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const ATHENA_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ATHENA}`;

export const DEFAULT_ATHENA_ROUTES: Record<string,string> = {
  RESOURCES_ATHENA_CATALOGS: `${ATHENA_BASE_ROUTE}/catalogs`,
  RESOURCES_ATHENA_CATALOG_CREATE: `${ATHENA_BASE_ROUTE}/catalogs/new`,
  RESOURCES_ATHENA_CATALOG_UPDATE: `${ATHENA_BASE_ROUTE}/cattalogs/:catalogName/update`,
  RESOURCES_ATHENA_DATABASES: `${ATHENA_BASE_ROUTE}/databases`,
  RESOURCES_ATHENA_DATABASE: `${ATHENA_BASE_ROUTE}/databases/:databaseName`,
  RESOURCES_ATHENA_SQL: `${ATHENA_BASE_ROUTE}/sql`,
};
