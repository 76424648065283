import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueCrawlerDetails as Details } from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueCrawlerDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { crawler } = useParams<'crawler'>();

  const { data: CrawlerData } = useAwsGetter(
    'Glue', 'getCrawler',
    [{ Name: crawler }], { clientOverrides });

  return (
    <Layout
      documentTitle="Glue: Crawler Details"
      tabs={<MainNavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Crawler Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Crawlers', () => goto(routes.RESOURCES_GLUE_CRAWLERS)],
              [crawler, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_GLUE_CRAWLER_UPDATE, { crawler })}
        >
          Edit Crawler
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details crawler={CrawlerData?.Crawler} />
        </CardContent>
      </Card>
    </Layout>
  );
};
