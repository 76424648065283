/* eslint-disable max-len */

import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const DYNAMODB_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.DYNAMODB}`;

export const DEFAULT_DYNAMODB_ROUTES: Record<string,string> = {
  RESOURCES_DYNAMODB: `${DYNAMODB_BASE_ROUTE}`,
  RESOURCES_DYNAMODB_TABLE: `${DYNAMODB_BASE_ROUTE}/tables/:tableName`,
  RESOURCES_DYNAMODB_TABLE_CREATE: `${DYNAMODB_BASE_ROUTE}/tables/new`,
  RESOURCES_DYNAMODB_TABLE_UPDATE: `${DYNAMODB_BASE_ROUTE}/tables/:tableName/update`,
  RESOURCES_DYNAMODB_TABLE_ITEMS: `${DYNAMODB_BASE_ROUTE}/tables/:tableName/items`,
  RESOURCES_DYNAMODB_TABLE_ITEM_CREATE: `${DYNAMODB_BASE_ROUTE}/tables/:tableName/items/new`,
  RESOURCES_DYNAMODB_TABLE_ITEM_UPDATE: `${DYNAMODB_BASE_ROUTE}/tables/:tableName/items/:hashKey`,
  RESOURCES_DYNAMODB_TABLE_ITEM_RANGE_UPDATE: `${DYNAMODB_BASE_ROUTE}/tables/:tableName/items/:hashKey/:rangeKey`,
  RESOURCES_DYNAMODB_TABLE_PARTIQL: `${DYNAMODB_BASE_ROUTE}/tables/:tableName/partiql`,
};
