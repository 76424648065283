import { ReactElement, useEffect } from 'react';
import { Grid, TextField } from '@mui/material';
import { ControlledAutocomplete } from '@localstack/ui';
import { useForm } from 'react-hook-form';
import { VALIDATION_RULES } from '@localstack/services';
import { SERVICE_NAME } from '@localstack/types';
import { SERVICE_CODES } from '@localstack/constants';

import { ExperimentFormValues, ExperimentProps } from '../../types';
import { ExperimentCard } from '../ExperimentCard';
import { DEFAULT_REGIONS, DEFAULT_SERVICES } from '../../constants';
import { UpdateExperimentButton } from '../UpdateExperimentButton';
import { getServiceUnavailableError } from '../../ExperimentTemplates';
import { RegionInput } from '../RegionInput';

const getSortedServiceOptions = (list: string[]) =>
  list.map(key => ({
    key,
    value: SERVICE_CODES[key as keyof typeof SERVICE_CODES] || key,
  })).sort((a, b) => a.value.localeCompare(b.value));

const SERVICES = getSortedServiceOptions(Object.values(SERVICE_NAME));

export const ServiceUnavailableCard = (props: ExperimentProps): ReactElement => {
  const { experiment: experimentState, alert, onUpsertExperiment } = props;
  const experiment = Array.isArray(experimentState) ? experimentState : undefined;

  const { control, handleSubmit, formState, watch, reset } = useForm<ExperimentFormValues>({
    mode: 'all',
    defaultValues: {
      services: getSortedServiceOptions(DEFAULT_SERVICES),
      region: DEFAULT_REGIONS[0],
    },
  });
  useEffect(() => {
    if (!experiment?.length) return;
    reset({
      services: getSortedServiceOptions(experiment.map(exp => exp.service || '')),
      region: experiment?.[0]?.region,
    });
  }, [experiment?.length]);
  const formValues = watch();
  const template = getServiceUnavailableError(formValues);
  return (
    <ExperimentCard
      experimentCard={{
        title: 'Service Unavailable',
        description: `This experiment causes all calls to certain specified services to
        return a 503 'service unavailable' response.`,
        template,
        options: (
          <form onSubmit={handleSubmit(_ => onUpsertExperiment(template, experimentState))} >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledAutocomplete
                  control={control}
                  fullWidth
                  name="services"
                  options={SERVICES}
                  rules={VALIDATION_RULES.required}
                  multiple
                  getValueOption={item => item}
                  getOptionLabel={item => item.value}
                  getOptionValue={item => item}
                  isOptionEqualToValue={(item, value) => item.key === value.key}
                  renderInput={
                    (params) => <TextField variant="standard" {...params} label="Services" />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <RegionInput control={control} />
              </Grid>
              <Grid item xs={12}>
                <UpdateExperimentButton formState={formState} experimentState={experimentState} alert={alert} />
              </Grid>
            </Grid>
          </form>
        ),
      }}
      formState={formState}
      {...props}
    />
  );
};
