import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { DEFAULT_ACCOUNT_ROUTES } from '@localstack/constants';
import { PutContactInformationRequest } from '@localstack/types';

import {
  Card,
  CardContent,
  CardActions,
} from '@mui/material';

import {
  ContactInformationForm,
  PageTitle,
  ProgressButton,
} from '@localstack/ui';

import { AccountProps } from './types';
import { NavTabs } from './components/NavTabs';

export const ContactInformationUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ACCOUNT_ROUTES,
}: AccountProps): ReactElement => {
  const { goto } = useRoutes();

  const { data: info } = useAwsGetter(
    'Account',
    'getContactInformation',
    [{ }],
    { clientOverrides },
  );

  const { putContactInformation, isLoading } = useAwsEffect(
    'Account',
    ['putContactInformation'],
    {
      clientOverrides,
      revalidate: ['getContactInformation', 'putContactInformation'],
    },
  );

  const handleUpdate = async (data: PutContactInformationRequest) => {
    if (await putContactInformation(data)) {
      goto(routes.RESOURCES_ACCOUNT_CONTACTINFO);
    }
  };

  return (
    <Layout
      documentTitle="Account: Contact Information"
      tabs={<NavTabs routes={routes} />}
      title={<PageTitle title="Account" />}
    >
      <Card>
        <CardContent>
          <ContactInformationForm
            formId="ContactInformationForm"
            onSubmit={handleUpdate}
            loading={isLoading}
            info={info}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="contained"
            type="submit"
            form="ContactInformationForm"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
