import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { useParams } from 'react-router-dom';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  AgwRestApiAuthorizersTable,
} from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiAuthorizers = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { restApiId } = useParams<'restApiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: authorizers, isLoading, mutate } = useAwsGetter(
    'APIGateway',
    'getAuthorizers',
    [{ restApiId }],
    { clientOverrides },
  );

  const { deleteAuthorizer } = useAwsEffect(
    'APIGateway',
    ['deleteAuthorizer'],
    {
      revalidate: ['getAuthorizers', 'getAuthorizer'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map(
      (authorizerId) => deleteAuthorizer({ restApiId: restApiId as string, authorizerId }),
    );
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Authorizers"
      title={
        <PageTitle
          title="API Authorizers"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
            [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
            ['Authorizers', null],
          ]}
        />
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_AUTHORIZER_CREATE, { restApiId })}>
            Create Authorizer
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Authorizer(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Authorizers will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwRestApiAuthorizersTable
          authorizers={authorizers?.items || []}
          onSelect={setSelectedIds}
          onViewAuthorizer={
            ({ id }) => goto(
              routes.RESOURCES_AGW1_API_AUTHORIZER,
              { restApiId, authorizerId: id },
            )
          }
        />
      </Card>
    </Layout>
  );
};
