import { useApiEffect, CiService, useRoutes } from '@localstack/services';
import { CIProject } from '@localstack/types';
import { ProgressButton } from '@localstack/ui';
import { 
  Button, Card, CardActions, CardContent, CardHeader,
} from '@mui/material';
import { ReactElement } from 'react';

import { AppRoute } from '~/config';

import { PROJECT_FORM_ID, ProjectForm } from '../ProjectForm'; 

export const CIProjectCard = (): ReactElement => {
  const { createCiProject, isLoading: isMutatingCiProject } = useApiEffect(
    CiService, ['createCiProject'], { revalidate: ['listCiProjects'] },
  );
    
  const { goto } = useRoutes();

  const onSubmit = async (data: CIProject) => {
    const res = await createCiProject(data);
    if (!res) return;
    goto(AppRoute.CI_PROJECT, { project: data.project_name });
  };


  return (
    <Card>
      <CardHeader title="Create a new CI Project"/>
      <CardContent>
        <ProjectForm onSubmit={onSubmit} />
      </CardContent>
      <CardActions>
        <Button onClick={() => goto(AppRoute.CI_PROJECTS)}>
          Cancel
        </Button>
        <ProgressButton 
          form={PROJECT_FORM_ID} 
          loading={isMutatingCiProject}
          type="submit" 
          variant="contained" 
          color="primary"
        >
          Create
        </ProgressButton>
      </CardActions>
    </Card>
  );
};
