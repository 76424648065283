import { ReactElement } from 'react';
import { AgwRestApiResource } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiResourceDetailsProps {
  resource?: Optional<Partial<AgwRestApiResource>>;
}

export const AgwRestApiResourceDetails = ({ resource }: AgwRestApiResourceDetailsProps): ReactElement => (
  <MagicDetails
    data={resource}
    schema={getSchema('APIGateway')}
    entry="Resource"
    title="Resource Details"
  />
);
