/* eslint-disable max-len */

import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';


const ROUTE53_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ROUTE53}`;
const ROUTE53RESOLVER_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ROUTE53RESOLVER}`;

export const DEFAULT_ROUTE53_ROUTES: Record<string,string> = {
  RESOURCES_ROUTE53: `${ROUTE53_BASE_ROUTE}`,
  RESOURCES_ROUTE53_HOSTED_ZONES: `${ROUTE53_BASE_ROUTE}/hosted-zones`,
  RESOURCES_ROUTE53_HOSTED_ZONE_CREATE: `${ROUTE53_BASE_ROUTE}/hosted-zones/new`,
  RESOURCES_ROUTE53_HOSTED_ZONE: `${ROUTE53_BASE_ROUTE}/hosted-zones/:hostedZone`,
  RESOURCES_ROUTE53_HOSTED_ZONE_RECORDS: `${ROUTE53_BASE_ROUTE}/hosted-zones/:hostedZone/records`,
  RESOURCES_ROUTE53_HOSTED_ZONE_RECORD: `${ROUTE53_BASE_ROUTE}/hosted-zones/:hostedZone/records/:record`,
  RESOURCES_ROUTE53_HOSTED_ZONE_RECORD_CREATE: `${ROUTE53_BASE_ROUTE}/hosted-zones/:hostedZone/records/new`,
  RESOURCES_ROUTE53_HOSTED_ZONE_RECORD_UPDATE: `${ROUTE53_BASE_ROUTE}/hosted-zones/:hostedZone/records/:record/update`,
 
  // Route53Resolver
  RESOURCES_ROUTE53RESOLVER: `${ROUTE53RESOLVER_BASE_ROUTE}`,
  RESOURCES_ROUTE53RESOLVER_ENDPOINTS: `${ROUTE53RESOLVER_BASE_ROUTE}/endpoints`,
  RESOURCES_ROUTE53RESOLVER_ENDPOINT_CREATE: `${ROUTE53RESOLVER_BASE_ROUTE}/endpoint/new`,
  RESOURCES_ROUTE53RESOLVER_ENDPOINT_UPDATE: `${ROUTE53RESOLVER_BASE_ROUTE}/endpoints/:id/update`,
  RESOURCES_ROUTE53RESOLVER_ENDPOINT: `${ROUTE53RESOLVER_BASE_ROUTE}/endpoints/:id`,
};
