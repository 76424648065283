import { DEFAULT_COGNITO_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateIdentityPoolInput, IdentityPool } from '@localstack/types';
import { Breadcrumbs, CognitoIdentityPoolForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { CognitoProps } from './types';

export const CognitoIdentityPoolUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_COGNITO_ROUTES,
}: CognitoProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: identityPool } = useAwsGetter(
    'CognitoIdentity',
    'describeIdentityPool',
    [{ IdentityPoolId: id }],
    { clientOverrides },
  );
  const { createIdentityPool, updateIdentityPool, isLoading } = useAwsEffect(
    'CognitoIdentity',
    ['createIdentityPool', 'updateIdentityPool'],
    {
      clientOverrides,
      revalidate: ['listIdentityPools'],
    },
  );
  const handleCreate = async (data: CreateIdentityPoolInput) => {
    const newIdentityPool = await createIdentityPool({
      ...data,
      AllowUnauthenticatedIdentities: data.AllowUnauthenticatedIdentities || false,
    });
    if (newIdentityPool) {
      goto(routes.RESOURCES_COGNITO_IDENTITY_POOLS);
    }
  };
  const handleUpdate = async (data: IdentityPool) => {
    const newIdentityPool = await updateIdentityPool({
      ...data,
      AllowUnauthenticatedIdentities: data.AllowUnauthenticatedIdentities || false,
    });
    if (newIdentityPool) {
      goto(routes.RESOURCES_COGNITO_IDENTITY_POOL, { id });
    }
  };
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Cognito Identity Pool</Typography>
          <Breadcrumbs
            mappings={[
              ['Cognito Identity', () => goto(routes.RESOURCES_COGNITO_IDENTITY_POOLS)],
              [id, () => goto(routes.RESOURCES_COGNITO_IDENTITY_POOL, { id })],
              [identityPool ? 'Update Identity Pool' : 'Create Identity Pool', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="CognitoIdentityPoolUpsert"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CognitoIdentityPoolForm
            formId="CognitoIdentityPoolUpsert"
            onSubmit={identityPool ? handleUpdate : handleCreate}
            identityPool={identityPool}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="CognitoIdentityPoolUpsert"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
