import { ReactElement } from 'react';
import { GlueJob } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface GlueJobDetailsProps {
  job?: Optional<Partial<GlueJob>>;
}

export const GlueJobDetails = ({ job }: GlueJobDetailsProps): ReactElement => (
  <MagicDetails
    data={job}
    schema={getSchema('Glue')}
    entry="Job"
    title="Job Run Details"
  />
);
