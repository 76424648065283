import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiIntegrationRequest, UpdateAgwRestApiIntegrationRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwRestApiIntegrationForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiMethodNavTabs } from './components';

export const AgwRestApiIntegrationUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, resourceId, httpMethod } = useParams<'restApiId' | 'resourceId' | 'httpMethod'>();

  const { putIntegration, updateIntegration, isLoading } = useAwsEffect(
    'APIGateway',
    ['putIntegration', 'updateIntegration'],
    {
      revalidate: ['getMethod', 'getIntegration'],
      clientOverrides,
    },
  );

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: resource } = useAwsGetter(
    'APIGateway',
    'getResource',
    [{ restApiId, resourceId }],
    { clientOverrides },
  );

  const { data: method } = useAwsGetter(
    'APIGateway',
    'getMethod',
    [{ restApiId, resourceId, httpMethod }],
    { clientOverrides },
  );

  const { data: integration } = useAwsGetter(
    'APIGateway',
    'getIntegration',
    [{ restApiId, resourceId, httpMethod }],
    { clientOverrides },
  );

  const handleCreate = async (data: CreateAgwRestApiIntegrationRequest) => {
    await putIntegration(data);
    goto(routes.RESOURCES_AGW1_API_INTEGRATION, { restApiId, resourceId, httpMethod });
  };

  const handleUpdate = async (data: UpdateAgwRestApiIntegrationRequest) => {
    await updateIntegration(data);
    goto(routes.RESOURCES_AGW1_API_INTEGRATION, { restApiId, resourceId, httpMethod });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Integration Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [resource?.path, () => goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })],
              [
                method?.httpMethod,
                () => goto(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod }),
              ],
              [httpMethod ? 'Update Integration' : 'Create Integration', null],
            ]}
          />
        </Box>
      }
      tabs={
        <RestApiMethodNavTabs
          restApiId={restApiId as string}
          resourceId={resourceId as string}
          httpMethod={httpMethod as string}
          routes={routes}
        />
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiIntegrationForm
            restApiId={restApiId as string}
            resourceId={resourceId as string}
            httpMethod={httpMethod as string}
            integration={integration}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
