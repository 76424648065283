import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@mui/material';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { StateExecutionDetails as Details, PageTitle } from '@localstack/ui';

import { DEFAULT_STEPFUNCTIONS_ROUTES } from '@localstack/constants';

import { StepFunctionsProps } from './types';
import { ExecutionTabs } from './components';

export const StateExecutionDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_STEPFUNCTIONS_ROUTES,
}: StepFunctionsProps): ReactElement => {
  const { goto } = useRoutes();
  const { stateMachineArn, executionArn } = useParams<'stateMachineArn' | 'executionArn'>();
  const stateMachineName = stateMachineArn?.split(':').pop();
  const executionID = executionArn?.split(':').pop();
  const POLLING_INTERVAL = 5000;

  const { data: execution } = useAwsGetter(
    'StepFunctions',
    'describeExecution',
    [{ executionArn }],
    { swrOverrides: { refreshInterval: POLLING_INTERVAL }, clientOverrides },
  );

  return (
    <Layout
      documentTitle="Step Functions: State Machine Execution Details"
      tabs={<ExecutionTabs stateMachineArn={stateMachineArn} executionArn={executionArn} routes={routes} />}
      title={
        <PageTitle
          title="State Machine Execution Details"
          breadcrumbs={[
            ['Step Functions', () => goto(routes.RESOURCES_STEPFUNCTIONS)],
            ['State Machines', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES)],
            [
              stateMachineName,
              () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS, { stateMachineArn }),
            ],
            [
              'Executions', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS, { stateMachineArn }),
            ],
            [executionID, null],
          ]}
        />
      }
    >
      <Card>
        <Details execution={execution} />
      </Card>
    </Layout>
  );
};
