import { NeptuneCluster, QUERY_TYPES } from '@localstack/types';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, TextField, Typography } from '@mui/material';
import { ReactElement, useState } from 'react';

import { AwsClientOverrides, buildRoute, useGremlinServer, useGremlinServerReturn } from '@localstack/services';

import { RelatedResourcePicker } from '@localstack/ui/src';

import { useForm } from 'react-hook-form';

import { NeptuneRoutes } from '../../types';


export type useNeptuneDataSourceAreaProps = {
  clientOverrides?: AwsClientOverrides,
  routes: NeptuneRoutes,
}

export type useNeptuneDataSourceAreaReturn = Omit<useGremlinServerReturn, 'deleteAll'> & {
  NeptuneDataSourceArea: ReactElement
};

type DataSourceForm = {
  searchQuery: string,
  cluster: NeptuneCluster,
}

export const useNeptuneDataSourceArea = ({
  clientOverrides,
  routes,
}: useNeptuneDataSourceAreaProps): useNeptuneDataSourceAreaReturn => {

  const [searchQuery, setQuery] = useState<string>('');
  const { control, watch } = useForm<DataSourceForm>({ mode: 'all' });

  const port = watch('cluster')?.Port;

  const {
    returnData,
    error,
    sendToServer,
    deleteNode,
    clearData,
  } = useGremlinServer(port?.toString() || '4510');

  const returnElement = (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <Card>
          <CardHeader title='Graph Query' />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} xl={6}>
                    <Typography>
                      The Neptune Cluster to quey
                    </Typography>
                  </Grid>
                  <Grid item xs={6} xl={6}>
                    <RelatedResourcePicker
                      control={control}
                      fieldName='cluster'
                      entityName='Cluster'
                      creationRoute={buildRoute(routes.RESOURCES_NEPTUNE_CLUSTER_CREATE)}
                      client='Neptune'
                      method='describeDBClusters'
                      arrayKeyName='DBClusters'
                      property='DBClusterIdentifier'
                      args={[{ Filters: [{ Name: 'engine', Values: ['neptune'] }] }]}
                      clientOverrides={clientOverrides}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6} xl={6}>
                    <Typography>
                      The query used to retrieve data
                    </Typography>
                  </Grid>
                  <Grid item xs={6} xl={6}>
                    <TextField
                      fullWidth
                      value={searchQuery}
                      placeholder='g.V()'
                      onChange={(e) => setQuery(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => sendToServer(searchQuery, QUERY_TYPES.Search)}
            >
              Submit
            </Button>
            <Button
              color="primary"
              variant="outlined"
              onClick={clearData}
            >
              Clear
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardHeader title="Dataset" />
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Typography>
                  Nodes: {returnData.nodes.length}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  Edges: {returnData.edges.length}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
  );

  return {
    returnData,
    error,
    sendToServer,
    deleteNode,
    clearData,
    NeptuneDataSourceArea: returnElement,
  };
};

