import { ReactElement } from 'react';
import { EventSourceMapping } from '@localstack/types';
import { formatServiceName } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export type LambdaEventSourceMappingsProps = {
  mappings: EventSourceMapping[];
};

const SCHEMA = {
  shapes: {
    EventSourceMappingConfigurationRefactored: {
      type: 'structure',
      members: {
        UUID: {
          type: 'string',
        },
        EventSourceArn: {
          type: 'string',
        },
        State: {
          type: 'string',
        },
        BatchSize: {
          type: 'integer',
        },
        LastModified: {
          type: 'timestamp',
        },
        LastProcessingResult: {
          type: 'string',
        },
      },
    },
  },
};

export const LambdaEventSourceMappings = ({ mappings }: LambdaEventSourceMappingsProps): ReactElement => (
  <>
    {
      mappings.map((mapping: EventSourceMapping) => (
        <MagicDetails
          data={mapping}
          schema={SCHEMA}
          entry="EventSourceMappingConfigurationRefactored"
          title={formatServiceName(mapping.FunctionArn as string)}
        />
      ))
    }
  </>
);
