import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwRestApiIntegrationResponseDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiMethodNavTabs } from './components';

export const AgwRestApiIntegrationResponseDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();

  const { restApiId, resourceId, httpMethod, statusCode } =
    useParams<'restApiId' | 'resourceId' | 'httpMethod' | 'statusCode'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: resource } = useAwsGetter(
    'APIGateway',
    'getResource',
    [{ restApiId, resourceId }],
    { clientOverrides },
  );

  const { data: method } = useAwsGetter(
    'APIGateway',
    'getMethod',
    [{ restApiId, resourceId, httpMethod }],
    { clientOverrides },
  );

  const { data: response } = useAwsGetter(
    'APIGateway',
    'getIntegrationResponse',
    [{ restApiId, resourceId, httpMethod, statusCode }],
  );

  return (
    <Layout
      documentTitle="Integration Response Details"
      tabs={
        <RestApiMethodNavTabs
          restApiId={restApiId as string}
          resourceId={resourceId as string}
          httpMethod={httpMethod as string}
          routes={routes}
        />
      }
      title={
        <Box>
          <Typography variant="h4">Integration Response Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [resource?.path, () => goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })],
              [
                method?.httpMethod,
                () => goto(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod }),
              ],
              [response?.statusCode, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(
            routes.RESOURCES_AGW1_API_INTEGRATION_RESPONSE_UPDATE,
            { restApiId, resourceId, httpMethod, statusCode },
          )}
        >
          Edit Response
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details response={response} />
        </CardContent>
      </Card>
    </Layout>
  );
};
