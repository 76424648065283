import {
  DEFAULT_API_GATEWAY_ROUTES,
  DEFAULT_KMS_ROUTES,
  DEFAULT_SNS_ROUTES,
  DEFAULT_APPSYNC_ROUTES,
  DEFAULT_BACKUP_ROUTES,
  DEFAULT_ATHENA_ROUTES,
  DEFAULT_SQS_ROUTES,
  DEFAULT_S3_ROUTES,
  DEFAULT_CLOUDFORMATION_ROUTES,
  DEFAULT_RDS_ROUTES,
  DEFAULT_LAMBDA_ROUTES,
  DEFAULT_CLOUDWATCH_ROUTES,
  DEFAULT_EC2_ROUTES,
  DEFAULT_ECS_ROUTES,
  DEFAULT_COGNITO_ROUTES,
  DEFAULT_STEPFUNCTIONS_ROUTES,
  DEFAULT_SECRETSMANAGER_ROUTES,
  DEFAULT_SES_ROUTES,
  DEFAULT_DYNAMODB_ROUTES,
  DEFAULT_CLOUDTRAIL_ROUTES,
  DEFAULT_ELASTICACHE_ROUTES,
  DEFAULT_IAM_ROUTES,
  DEFAULT_KAFKA_ROUTES,
  DEFAULT_KINESIS_ROUTES,
  DEFAULT_EVENTBRIDGE_ROUTES,
  DEFAULT_METRICS_ROUTES,
  DEFAULT_MQ_ROUTES,
  DEFAULT_SYSTEMPARAMETERS_ROUTES,
  DEFAULT_TIMESTREAMDB_ROUTES,
  DEFAULT_GLUE_ROUTES,
  DEFAULT_SGM_ROUTES,
  DEFAULT_APPLICATION_AUTO_SCALING_ROUTES,
  DEFAULT_ROUTE53_ROUTES,
  DEFAULT_CLOUDFRONT_ROUTES,
  DEFAULT_ECR_ROUTES,
  DEFAULT_NEPTUNE_ROUTES,
  DEFAULT_OPENSEARCH_ROUTES,
  DEFAULT_QLDB_ROUTES,
  DEFAULT_DOCDB_ROUTES,
  DEFAULT_APPCONFIG_ROUTES,
  DEFAULT_ACM_ROUTES,
  DEFAULT_MWAA_ROUTES,
  DEFAULT_AMPLIFY_ROUTES,
  DEFAULT_EKS_ROUTES,
  DEFAULT_TRANSCRIBE_ROUTES,
  DEFAULT_CODECOMMIT_ROUTES,
  DEFAULT_CE_ROUTES,
  DEFAULT_ACCOUNT_ROUTES,
  DEFAULT_DMS_ROUTES,
} from './routes/index';

export const GLOBAL_ROUTES = {
  ...DEFAULT_S3_ROUTES,
  ...DEFAULT_SECRETSMANAGER_ROUTES,
  ...DEFAULT_SES_ROUTES,
  ...DEFAULT_API_GATEWAY_ROUTES,
  ...DEFAULT_KMS_ROUTES,
  ...DEFAULT_SNS_ROUTES,
  ...DEFAULT_APPSYNC_ROUTES,
  ...DEFAULT_BACKUP_ROUTES,
  ...DEFAULT_ATHENA_ROUTES,
  ...DEFAULT_SQS_ROUTES,
  ...DEFAULT_CLOUDFORMATION_ROUTES,
  ...DEFAULT_RDS_ROUTES,
  ...DEFAULT_LAMBDA_ROUTES,
  ...DEFAULT_CLOUDWATCH_ROUTES,
  ...DEFAULT_EC2_ROUTES,
  ...DEFAULT_ECS_ROUTES,
  ...DEFAULT_ECR_ROUTES,
  ...DEFAULT_COGNITO_ROUTES,
  ...DEFAULT_STEPFUNCTIONS_ROUTES,
  ...DEFAULT_DYNAMODB_ROUTES,
  ...DEFAULT_CLOUDTRAIL_ROUTES,
  ...DEFAULT_ELASTICACHE_ROUTES,
  ...DEFAULT_IAM_ROUTES,
  ...DEFAULT_KINESIS_ROUTES,
  ...DEFAULT_KAFKA_ROUTES,
  ...DEFAULT_EVENTBRIDGE_ROUTES,
  ...DEFAULT_METRICS_ROUTES,
  ...DEFAULT_MQ_ROUTES,
  ...DEFAULT_SYSTEMPARAMETERS_ROUTES,
  ...DEFAULT_TIMESTREAMDB_ROUTES,
  ...DEFAULT_GLUE_ROUTES,
  ...DEFAULT_SGM_ROUTES,
  ...DEFAULT_APPLICATION_AUTO_SCALING_ROUTES,
  ...DEFAULT_CLOUDFRONT_ROUTES,
  ...DEFAULT_ROUTE53_ROUTES,
  ...DEFAULT_NEPTUNE_ROUTES,
  ...DEFAULT_OPENSEARCH_ROUTES,
  ...DEFAULT_QLDB_ROUTES,
  ...DEFAULT_DOCDB_ROUTES,
  ...DEFAULT_APPCONFIG_ROUTES,
  ...DEFAULT_ACM_ROUTES,
  ...DEFAULT_MWAA_ROUTES,
  ...DEFAULT_AMPLIFY_ROUTES,
  ...DEFAULT_EKS_ROUTES,
  ...DEFAULT_TRANSCRIBE_ROUTES,
  ...DEFAULT_CODECOMMIT_ROUTES,
  ...DEFAULT_CE_ROUTES,
  ...DEFAULT_ACCOUNT_ROUTES,
  ...DEFAULT_DMS_ROUTES,
};
