import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { CreateAgwApiDeploymentRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwApiDeploymentFormProps {
  apiId: string;
  formId?: string;
  loading?: boolean;
  onCreate: (data: CreateAgwApiDeploymentRequest) => unknown;
}

export const AgwApiDeploymentForm = ({
  apiId,
  loading,
  formId,
  onCreate,
}: AgwApiDeploymentFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('ApiGatewayV2')}
    loading={loading}
    entry="CreateDeploymentRequest"
    formId={formId}
    externalFields={{
      '^ApiId$': () => (
        <TextField fullWidth disabled value={apiId} variant="outlined" />
      ),
    }}
    onSubmit={(data: CreateAgwApiDeploymentRequest) => onCreate({ ...data, ApiId: apiId })}
  />
);
