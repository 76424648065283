import { ReactElement } from 'react';
import { CreateS3BucketRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';


export interface S3BucketCreateFormProps {
  region: string;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateS3BucketRequest) => void;
}

export const S3BucketCreateForm = ({
  region,
  loading,
  formId,
  onCreate,
}: S3BucketCreateFormProps): ReactElement => (
  <MagicForm
    // if region is us-east-1, no need to set CreateBucketConfiguration
    data={region !== 'us-east-1' ? {
      CreateBucketConfiguration: {
        LocationConstraint: region,
      },
    } : undefined}
    schema={getSchema('S3')}
    loading={loading}
    entry="CreateBucketRequest"
    formId={formId}
    onSubmit={(data: CreateS3BucketRequest) => onCreate(data)}
  />
);
