import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes, useSnackbar } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, ContentModal, DiffViewer, GlueSchemaVersionForm, ProgressButton } from '@localstack/ui';
import { GlueSchemaDefinitionItem, RegisterGlueSchemaVersionRequest } from '@localstack/types';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { SchemaTabs } from './components';


export const GlueSchemaVersionCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const [diffVersions, setDiffVersions] = useState<GlueSchemaDefinitionItem[]>([]);
  const { schema, registry, latestVersion } = useParams<'schema' | 'registry' | 'latestVersion'>();
  const { showSnackbar } = useSnackbar();
  const { registerSchemaVersion, checkSchemaVersionValidity, isLoading } = useAwsEffect(
    'Glue',
    ['registerSchemaVersion', 'checkSchemaVersionValidity'],
    { revalidate: ['listSchemaVersions'], clientOverrides },
  );

  const { data: schemaLatestVersion } = useAwsGetter(
    'Glue', 'getSchemaVersion',
    [{
      SchemaId: { SchemaName: schema, RegistryName: registry },
      SchemaVersionNumber: { VersionNumber: Number(latestVersion ?? 0) },
    }], { clientOverrides });

  const handleCreate = async (data: RegisterGlueSchemaVersionRequest) => {
    const SchemaValidityResponse = await checkSchemaVersionValidity({
      DataFormat: schemaLatestVersion?.DataFormat ?? '',
      SchemaDefinition: data.SchemaDefinition ?? '',
    });
    if (!SchemaValidityResponse.Valid) {
      return showSnackbar({
        message: SchemaValidityResponse.Error ?? '',
        severity: 'error',
      });
    }

    await registerSchemaVersion({
      ...data,
      SchemaDefinition: JSON.stringify(JSON.parse(data.SchemaDefinition), null, 2),
    });
    goto(routes.RESOURCES_GLUE_SCHEMA_VERSIONS, { schema, registry });
  };


  return (
    <Layout
      documentTitle='Glue: Register Schema Version'
      title={
        <Box>
          <Typography variant="h4">Register Schema Version</Typography>
          <Breadcrumbs
            mappings={[
              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Schemas', () => goto(routes.RESOURCES_GLUE_SCHEMAS)],
              [schema, () => goto(routes.RESOURCES_GLUE_SCHEMA, { schema, registry })],
              ['Versions', () => goto(routes.RESOURCES_GLUE_SCHEMA_VERSIONS, { schema, registry })],
              ['Register Schema Version', null],
            ]}
          />
        </Box>
      }
      tabs={<SchemaTabs routes={routes} schema={schema ?? ''} registry={registry ?? ''} />}
      actions={
        <ProgressButton
          type="submit"
          form="RegisterSchemaVersion"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueSchemaVersionForm
            schema={schemaLatestVersion}
            loading={isLoading}
            formId="RegisterSchemaVersion"
            onCreate={handleCreate}
            setDiffVersions={setDiffVersions}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="RegisterSchemaVersion"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
      {!!diffVersions.length && (
        <ContentModal
          title='Schema Version Comparison'
          fullWidth maxWidth="md" open={!!diffVersions.length} onClose={() => setDiffVersions([])}
        >
          <DiffViewer
            previousObj={diffVersions[0]?.SchemaDefinition ?? ''} latestObj={diffVersions[1]?.SchemaDefinition ?? ''}
            leftTitle={`Version ${(diffVersions[0]?.VersionNumber || '').toString()}`}
            rightTitle={`Version ${(diffVersions[1]?.VersionNumber || '').toString()}`}
          />
        </ContentModal>
      )}
    </Layout>
  );
};
