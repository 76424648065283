import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { IAMRolePolicy } from '@localstack/types';

import { MagicTable } from '../../../magic/MagicTable';

export interface IAMRolePoliciesTableProps {
  policies: IAMRolePolicy[];
  loading?: boolean;
  selectable?: boolean;
  onViewPolicy?: (policy: IAMRolePolicy) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

const SCHEMA = {
  shapes: {
    policyNameType: {
      type: 'structure',
      members: {
        PolicyName: {
          type: 'string',
        },
        PolicyType: {
          type: 'string',
        },
      },
    },
  },
};

export const IAMRolePoliciesTable = ({
  policies,
  loading,
  selectable,
  onViewPolicy,
  onSelect,
}: IAMRolePoliciesTableProps): ReactElement => (
  <MagicTable
    schema={SCHEMA}
    entry="policyNameType"
    idAttribute="PolicyName"
    rows={policies}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      PolicyName: (row) => (
        <Link onClick={() => onViewPolicy?.(row)} underline="hover">
          {row.PolicyName}
        </Link>
      ),
    }}
  />
);
