import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { IAMGroup } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export interface IAMGroupsTableProps {
  groups: IAMGroup[];
  loading?: boolean;
  selectable?: boolean;
  onViewGroup?: (group: IAMGroup) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const IAMGroupsTable = ({
  groups,
  loading,
  selectable,
  onViewGroup,
  onSelect,
}: IAMGroupsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('IAM')}
    entry="Group"
    idAttribute="GroupName"
    rows={groups}
    selectable={selectable}
    onSelect={onSelect}
    order={['GroupId', 'GroupName']}
    loading={loading}
    externalFields={{
      GroupId: (row) => (
        <Link onClick={() => onViewGroup && onViewGroup(row)} underline="hover">
          {row.GroupId}
        </Link>
      ),
    }}
  />
);
