import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { buildAgwPatches, getSchema } from '@localstack/services';

import {
  AgwRestApiRequestValidator,
  CreateAgwRestApiRequestValidatorRequest,
  UpdateAgwRestApiRequestValidatorRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwRestApiValidatorFormProps {
  restApiId: string;
  validator?: Optional<Partial<AgwRestApiRequestValidator>>;
  formId?: string;
  loading?: boolean;
  onCreate: (data: CreateAgwRestApiRequestValidatorRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiRequestValidatorRequest) => unknown;
}

export const AgwRestApiValidatorForm = ({
  restApiId,
  validator,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AgwRestApiValidatorFormProps): ReactElement => (
  <MagicForm
    data={validator}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateRequestValidatorRequest"
    formId={formId}
    externalFields={{
      '^restApiId$': () => <TextField fullWidth disabled value={restApiId} variant="outlined" />,
    }}
    onSubmit={(data: CreateAgwRestApiRequestValidatorRequest) => {
      if (!validator) return onCreate({ ...data, restApiId });
      const patchOperations = buildAgwPatches(validator, data);
      return onUpdate({ restApiId, requestValidatorId: validator.id as string, patchOperations });
    }}
  />
);
