import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { buildAgwPatches, getSchema } from '@localstack/services';

import {
  AgwRestApiMethod,
  CreateAgwRestApiMethodRequest,
  UpdateAgwRestApiMethodRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwRestApiMethodFormProps {
  restApiId: string;
  resourceId: string;
  method?: Optional<Partial<AgwRestApiMethod>>;
  formId?: string;
  loading?: boolean;
  onCreate: (data: CreateAgwRestApiMethodRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiMethodRequest) => unknown;
}

export const AgwRestApiMethodForm = ({
  restApiId,
  resourceId,
  method,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AgwRestApiMethodFormProps): ReactElement => (
  <MagicForm
    data={method}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="PutMethodRequest"
    formId={formId}
    fieldConditions={{
      '^authorizerId$': ['$authorizationType', 'in', ['COGNITO_USER_POOLS', 'CUSTOM']],
      '^authorizationScopes': ['$authorizationType', '===', 'COGNITO_USER_POOLS'],
    }}
    externalFields={{
      '^restApiId$': () => <TextField fullWidth disabled value={restApiId} variant="outlined" />,
      '^resourceId$': () => <TextField fullWidth disabled value={resourceId} variant="outlined" />,
    }}
    onSubmit={(data: CreateAgwRestApiMethodRequest) => {
      if (!method) return onCreate({ ...data, restApiId, resourceId });
      const patchOperations = buildAgwPatches(method, data);
      return onUpdate({ restApiId, resourceId, httpMethod: method.httpMethod as string, patchOperations });
    }}
  />
);
