import { ReactElement, useState } from 'react';

import {
  FileCopyOutlined as CopyIcon,
} from '@mui/icons-material';
import { ExternalLink } from '@localstack/constants';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import CopyToClipboard from 'react-copy-to-clipboard';

const BASE_LAUNCHPAD_LINK = `${document.URL}?url=`;

export const LinkGenerator = (): ReactElement => {
  const [url, setUrl] = useState('');
  const [link, setLink] = useState('');

  const generateLink = () => setLink(`${BASE_LAUNCHPAD_LINK}${url}`);

  return <>
    <Typography variant="h5" color='textSecondary'>
      The LocalStack Cloud Pods Launchpad allows you to easily create sharable links to cloud pods
      that are hosted anywhere on the web. You can also {' '}
      {/* eslint-disable-next-line */}
      <Link
        href={ExternalLink.DOCS_ADD_BADGE_TO_REPO}
        target="_blank"
        underline="hover"
      >
        add a cloud pod badge to your repo
      </Link> and let anyone quickly inject a cloud pod into their running LocalStack instance.
    </Typography>

    <Card>
      <CardHeader title='Generate your link' titleTypographyProps={{ variant: 'h4' }} />
      <CardContent>
        <TextField fullWidth
          variant='outlined'
          label='Public URL to your pod'
          onChange={(event) => setUrl(event.target.value)}
        />
      </CardContent>
      <CardActions style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <Button variant='contained' color='primary' onClick={generateLink}>
          Generate Link
        </Button>
        {link &&
          <List style={{ width: '100%', marginTop: '1rem' }}>
            <ListItem disableGutters>
              <Grid container>
                <Grid item xs={9}>
                  <Link variant='body1' href={link} target='_blank' underline="hover">{link}</Link>
                </Grid>
                <Grid item xs={3}>
                  <CopyToClipboard text={link}>
                    <Tooltip title="Copy Link">
                      <Button startIcon={<CopyIcon />} color="primary" size="small">
                        Copy Link
                      </Button>
                    </Tooltip>
                  </CopyToClipboard>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <img src={ExternalLink.BADGE_LAUNCH_POD} alt="" />
                }
              />
              <ListItemSecondaryAction>
                <CopyToClipboard
                  text={`[![Launch LocalStack Cloudpod](${ExternalLink.BADGE_LAUNCH_POD})](${link})`}
                >
                  <Tooltip title="Copy Code">
                    <Button startIcon={<CopyIcon />} color="primary" size="small">
                      Copy Markdown
                    </Button>
                  </Tooltip>
                </CopyToClipboard>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        }
      </CardActions>
    </Card>
  </>;
};
