import { ReactElement } from 'react';
import { Tooltip } from '@mui/material';

type WrapInToolTipProps = {
  title: Optional<string>,
  children: ReactElement<unknown, string>,
}

export const WrapInToolTip = ({ title, children }: WrapInToolTipProps): ReactElement =>
  title ? <Tooltip title={title}>{children}</Tooltip> : children;
