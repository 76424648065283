import { ReactElement, useState } from 'react';
import { Box, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { LoadingFragment, SubscriptionsList } from '@localstack/ui';
import { AdminService, useApiGetter, useRoutes } from '@localstack/services';
import { SUB_ACTIVE_STATUSES } from '@localstack/constants';

import { AppRoute } from '~/config';

export interface SubscriptionsProps {
  orgId: string;
}

export const Subscriptions = ({ orgId }: SubscriptionsProps): ReactElement => {
  const { goto } = useRoutes();

  const [showCancelledSubs, setShowCancelledSubs] = useState(false);

  const { data: organization } = useApiGetter(AdminService, 'getOrganization', [orgId]);

  const { data: subscriptions, isLoading: isSubsLoading } =
    useApiGetter(AdminService, 'listOrganizationSubscriptions', [orgId]);

  const { data: licenseAssignments, isLoading: isLicensesLoading } = useApiGetter(
    AdminService,
    'listOrganizationLicenseAssignments',
    [organization?.id || ''],
    { enable: !!organization },
  );

  const { data: keys } = useApiGetter(AdminService, 'listOrganizationKeys', [orgId]);

  const activeSubscriptions = (subscriptions ?? []).filter(({ status }) => SUB_ACTIVE_STATUSES.includes(status));
  const activeKeys = (keys ?? []).filter(({ deleted }) => !deleted);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="caption" align="right" component="div">Subscriptions</Typography>
        <Box display="flex" alignItems="center">
          <IconButton color="primary" onClick={() => goto(AppRoute.ADMIN_ORGANIZATION_SUBSCRIBE, { orgId })}>
            <AddIcon />
          </IconButton>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={showCancelledSubs}
                onChange={(_, checked: boolean) => setShowCancelledSubs(checked)}
              />
            }
            label="Show Cancelled"
          />
        </Box>
      </Box>
      <LoadingFragment
        loading={isSubsLoading || isLicensesLoading}
        arrayData={subscriptions ?? []}
        height={40}
        size={5}
      >
        <SubscriptionsList
          showCancelled
          isAdminPanel
          keys={{ shared: [], org: activeKeys }}
          licenseAssignments={licenseAssignments ?? []}
          members={organization?.members ?? []}
          subscriptions={showCancelledSubs ? (subscriptions ?? []) : activeSubscriptions}
          onViewSubscription={
            (subscription) => goto(
              AppRoute.ADMIN_ORGANIZATION_SUBSCRIPTION,
              { orgId, subscriptionId: subscription.id },
            )
          }
        />
      </LoadingFragment>
    </>
  );
};
