import { getSchema, AwsClientOverrides } from '@localstack/services';
import { GetRepositoryOutput, CreateRepositoryInput } from '@localstack/types';
import { ReactElement } from 'react';
import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';
import { MagicForm } from '../../magic/MagicForm';

export type CodeCommitRepositoryFormProps = {
  repository?: Optional<GetRepositoryOutput>;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateRepositoryInput) => void;
  loading?: boolean;
};

export const CodeCommitRepositoryForm = ({
  repository,
  formId,
  clientOverrides,
  onSubmit,
  loading,
}: CodeCommitRepositoryFormProps): ReactElement => (
  <MagicForm
    entry='CreateRepositoryInput'
    schema={getSchema('CodeCommit')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    externalFields={{
      '^kmsKeyId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='KMS' method='listKeys' arrayKeyName='Keys' property='KeyArn'
          fieldName={fieldName} entityName='KMS Key'
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    data={repository?.repositoryMetadata}
  />
);
