import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { BackupPlan } from '@localstack/types';
import { formatDate } from '@localstack/services';

import { DataGrid, GridColDef, GridRenderCellParams } from '../../../../display';

export type BackupPlansTableProps = {
  selectable?: boolean;
  plans: BackupPlan[];
  onViewBackupPlan?: (plan: BackupPlan) => unknown;
  onSelect?: (ids: string[]) => void;
};

const buildColumns = ({
  onViewBackupPlan,
}: Pick<BackupPlansTableProps, 'onViewBackupPlan'>): GridColDef[] => [
  {
    field: 'BackupPlanId',
    headerName: 'ID',
    width: 250,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        onClick={() => onViewBackupPlan && onViewBackupPlan(params.row as BackupPlan)}
        underline="hover"
      >
        {params.value}
      </Link>
    ),
  },
  {
    field: 'BackupPlan.BackupPlanName',
    headerName: 'Name',
    width: 250,
    valueGetter: (params) => (params.row as BackupPlan).BackupPlan?.BackupPlanName,
  },
  {
    field: 'CreationDate',
    headerName: 'Creation Date',
    width: 250,
    renderCell: (params) => formatDate((params.row as BackupPlan).CreationDate as Date),
  },
];

export const BackupPlansTable = ({
  plans,
  selectable = true,
  onViewBackupPlan,
  onSelect,
}: BackupPlansTableProps): ReactElement => (
  <DataGrid
    autoHeight
    getRowId={(row: BackupPlan) => row.BackupPlanId as string}
    rows={plans}
    columns={buildColumns({ onViewBackupPlan })}
    checkboxSelection={selectable}
    onSelectionModelChange={(names) => onSelect && onSelect(names as string[])}
  />
);
