import { useCallback, useEffect, useState, ReactElement } from 'react';
import { Alert, Box, TextField } from '@mui/material';
import moment from 'moment';
import { AuditTrailTable, LoadingFragment, ProgressButton } from '@localstack/ui';
import { AuditTrailQueryResult, AuditTrailQueryStatus } from '@localstack/types';
import { AdminService } from '@localstack/services';

import {
  COMPLETED_AUDIT_TRAIL_STATES,
  FAILED_AUDIT_TRAIL_STATES,
  LOADING_AUDIT_TRAIL_STATES,
} from '@localstack/constants';

const AUDIT_TRAIL_DEFAULT_START_OFFSET = 14 * 24 * 60 * 60; // 2 weeks (sec)

export interface AuditTrailProps {
  orgId: string;
}

export const AuditTrail = ({ orgId }: AuditTrailProps): ReactElement => {
  const [startTimestamp, setStartTimestamp] = useState(
    Math.floor(new Date().getTime() / 1000) - AUDIT_TRAIL_DEFAULT_START_OFFSET,
  );
  const [endTimestamp, setEndTimestamp] = useState(Math.floor(new Date().getTime() / 1000));
  const [auditTrailResult, setAuditTrailResult] = useState<AuditTrailQueryResult>(
    { events: [], status: AuditTrailQueryStatus.COMPLETE },
  );

  const loadAuditTrail = useCallback(async () => {
    let result: AuditTrailQueryResult = {
      events: [],
      status: AuditTrailQueryStatus.SCHEDULED,
    };

    setAuditTrailResult(result);

    while (!COMPLETED_AUDIT_TRAIL_STATES.includes(result.status)) {
      try {
        // eslint-disable-next-line
        result = await AdminService.getOrgAuditTrail(
          orgId,
          startTimestamp,
          result.query_id,
          endTimestamp,
        );
      } catch (e) {
        result = {
          status: AuditTrailQueryStatus.FAILED,
          events: [],
        };
      }

      setAuditTrailResult(result);

      // eslint-disable-next-line
      await new Promise(t => setTimeout(t, 3000));
    }
  }, [orgId, startTimestamp, endTimestamp]);

  useEffect(() => {
    loadAuditTrail();
  }, [orgId]);

  return (
    <>
      <Box display="flex" mb={2}>
        <Box mr={1} flexGrow={1}>
          <TextField
            required
            variant="outlined"
            label="Start Date"
            size="small"
            type="date"
            fullWidth
            disabled={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
            onChange={
              ({ target }) => setStartTimestamp(
                target.value ? moment(target.value).unix() : Date.now(),
              )
            }
            value={
              startTimestamp ? moment.unix(startTimestamp).format(moment.HTML5_FMT.DATE) : ''
            }
          />
        </Box>
        <Box mr={1} flexGrow={1}>
          <TextField
            required
            variant="outlined"
            label="End Date"
            size="small"
            type="date"
            fullWidth
            disabled={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
            onChange={
              ({ target }) => setEndTimestamp(
                target.value ? moment(target.value).unix() : Date.now(),
              )
            }
            value={
              endTimestamp ? moment.unix(endTimestamp).format(moment.HTML5_FMT.DATE) : ''
            }
          />
        </Box>
        <ProgressButton
          variant="contained"
          color="primary"
          onClick={loadAuditTrail}
          disabled={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
          loading={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
        >
          Load Audit Trail
        </ProgressButton>
      </Box>
      {FAILED_AUDIT_TRAIL_STATES.includes(auditTrailResult.status) && (
        <Box mb={2}>
          <Alert severity="error">
            There was an error querying audit trail
          </Alert>
        </Box>
      )}
      <LoadingFragment
        loading={LOADING_AUDIT_TRAIL_STATES.includes(auditTrailResult.status)}
        arrayData={auditTrailResult.events ?? []}
        size={10}
        height={40}
      >
        <AuditTrailTable events={auditTrailResult.events ?? []} revealLocalStackStaff />
      </LoadingFragment>
    </>
  );
};
