import { ReactElement } from 'react';
import { CognitoUser } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface CognitoUsersTableProps {
  selectable?: boolean;
  loading?: boolean;
  users: CognitoUser[];
  onViewUser?: (user: CognitoUser) => unknown;
  onSelect?: (usernames: string[]) => void;
}

export const CognitoUsersTable = ({
  users,
  selectable = true,
  loading,
  onViewUser,
  onSelect,
}: CognitoUsersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('CognitoIdentityServiceProvider')}
    entry="UserType"
    idAttribute="Username"
    rows={users}
    selectable={selectable}
    onSelect={onSelect}
    order={['Username']}
    loading={loading}
    externalFields={{
      Username: (row) => (
        <Link onClick={() => onViewUser && onViewUser(row)} underline="hover">
          {row.Username}
        </Link>
      ),
    }}
  />
);
