import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { ListRepositoriesOutput } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type CodeCommitRepositoriesTableProps = {
  repositories: ListRepositoriesOutput;
  onViewRepository: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const CodeCommitRepositoriesTable = ({
  repositories,
  onViewRepository,
  onSelect,
  loading,
}: CodeCommitRepositoriesTableProps): ReactElement => (
  <MagicTable
    entry="RepositoryNameIdPair"
    idAttribute="repositoryName"
    rows={repositories?.repositories || []}
    schema={getSchema('CodeCommit')}
    externalFields={{
      repositoryName: ({ repositoryName }) => (
        <Link onClick={() => onViewRepository(repositoryName || '')} underline="hover">
          {repositoryName}
        </Link>
      ),
    }}
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
