import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const ELASTICACHE_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ELASTICACHE}`;

export const DEFAULT_ELASTICACHE_ROUTES: Record<string,string> = {
  RESOURCES_ELASTICACHE: `${ELASTICACHE_BASE_ROUTE}`,
  RESOURCES_ELASTICACHE_CLUSTERS: `${ELASTICACHE_BASE_ROUTE}/clusters`,
  RESOURCES_ELASTICACHE_CLUSTER: `${ELASTICACHE_BASE_ROUTE}/clusters/:clusterId`,
  RESOURCES_ELASTICACHE_CLUSTER_CREATE: `${ELASTICACHE_BASE_ROUTE}/clusters/new`,
  RESOURCES_ELASTICACHE_CLUSTER_UPDATE: `${ELASTICACHE_BASE_ROUTE}/clusters/:clusterId/update`,
};
