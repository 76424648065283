import { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { CIProject, PlanFamily } from '@localstack/types';
import { CiService, formatDateTime, useApiGetter, useRoutes } from '@localstack/services';
import { LoadingFragment, DataGrid, GridColDef } from '@localstack/ui';
import { Button, Card, CardContent, CardHeader, Grid, Link } from '@mui/material';
import { CreateNewFolder } from '@mui/icons-material';

import { AppRoute } from '~/config';
import { CustomerLayout } from '~/layouts';

export const CIProjects = (): ReactElement => {
  const { data: projects, isLoading } = useApiGetter(CiService, 'listCiProjects', [], { defaultValue: [] });
  const { goto } = useRoutes();

  const { project: projectName } = useParams<'project'>();
  const [selectedProject, setSelectedProject] = useState<Optional<CIProject>>(null);

  // update path whenever selected project changes
  useEffect(() => {
    if (!selectedProject) return;
    goto(AppRoute.CI_PROJECT, { project: selectedProject.project_name });
  }, [selectedProject]);

  // pre-select project from path if present
  useEffect(() => {
    if (!projectName || !projects?.length) return;
    setSelectedProject(projects?.find((p) => p.project_name === projectName));
  }, [projectName, projects]);

  const buildColumns = (): GridColDef[] => [
    {
      field: 'project_name',
      headerName: 'Project Name',
      flex: 0.2,
      renderCell: (props) => (
        <Link
          onClick={() => setSelectedProject(props.row as CIProject)}
          underline="hover"
        >
          {props.row.project_name}
        </Link>
      ),
    },
    {
      field: 'runs',
      headerName: 'Recorded CI Runs',
      flex: 0.15,
      renderCell: (props) => props.row.runs_count || (props.row.runs ?? []).length,
    },
    {
      field: 'last_updated',
      headerName: 'Last updated',
      flex: 0.2,
      renderCell: (props) => props.row.st && formatDateTime(props.row.st) || 'n/a',
    },
  ];

  return (
    <CustomerLayout
      title="CI Projects"
      planFamily={PlanFamily.ENTERPRISE_PLANS}
    >
      <Card>
        <CardHeader title="CI Projects" action={
          <Button
            variant='contained'
            color='primary'
            onClick={() => goto(AppRoute.CI_PROJECT_CREATE)}
            startIcon={<CreateNewFolder />}
          >
            Create new project
          </Button>
        }
        />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <LoadingFragment loading={isLoading} variant="card" height={300}>
                <DataGrid
                  autoHeight
                  rows={projects || []}
                  columns={buildColumns()}
                  getRowId={(row: CIProject) => row.project_name as string}
                />
              </LoadingFragment>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </CustomerLayout>
  );
};
