import { ReactElement, useCallback, useState } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import { ConfirmableButton, Dropdown, LoadingFragment, PageTitle, SGMEndpointsTable } from '@localstack/ui';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';

import { SageMakerProps } from './types';
import { NavTabs } from './components/NavTabs';

export const SGMEndpoints = ({
  Layout,
  clientOverrides,
  routes,
}: SageMakerProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const { data: endpoints, isLoading: isEndpointsLoading, mutate } = useAwsGetter(
    'SGM',
    'listEndpoints',
    [],
    { clientOverrides },
  );
  const { deleteEndpoint } = useAwsEffect(
    'SGM',
    ['deleteEndpoint'],
    { revalidate: ['listEndpoints'], clientOverrides },
  );

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((EndpointName) => deleteEndpoint({ EndpointName }))),
    [selected],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="SageMaker Endpoints"
            onMutate={mutate}
          />
        }
        tabs={<NavTabs routes={routes} />}
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_SGM_ENDPOINT_NEW)}>
              Create
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selected.length === 0 || isEndpointsLoading}
                title={`Remove ${selected.length} Endpoint(s)?`}
                onClick={handleDeleteObjects}
                text="Selected endpoints will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <CardContent>
            <LoadingFragment
              loading={isEndpointsLoading}
              arrayData={endpoints?.Endpoints ?? []}
              variant="list"
              height={50}
              size={10}
            >
              <SGMEndpointsTable
                loading={isEndpointsLoading}
                selectable
                onSelect={setSelected}
                endpoints={endpoints?.Endpoints ?? []}
              />
            </LoadingFragment>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
};
