import { DEFAULT_CODECOMMIT_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';
import { Breadcrumbs, CodeCommitRepositoryDetail } from '@localstack/ui';

import { CodeCommitProps } from './types';

export const CodeCommitRepository = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CODECOMMIT_ROUTES,
}: CodeCommitProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: repository } = useAwsGetter(
    'CodeCommit',
    'getRepository',
    [{ repositoryName: id }],
    {
      clientOverrides,
    },
  );
  return (
    <Layout
      documentTitle="CodeCommit: Repository Details"
      title={
        <Box>
          <Typography variant="h4">Repository Details</Typography>
          <Breadcrumbs
            mappings={[
              ['CodeCommit', () => goto(routes.RESOURCES_CODECOMMIT_REPOSITORIES)],
              [id, null],
            ]}
          />
        </Box>
      }
      // actions={
      //   <Button
      //     onClick={() => goto(routes.RESOURCES_CODECOMMIT_REPOSITORY_UPDATE, { id })}
      //   >
      //     Edit Repository
      //   </Button>
      // }
    >
      <Card>
        <CodeCommitRepositoryDetail repository={repository} />
      </Card>
    </Layout>
  );
};
