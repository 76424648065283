import { ReactElement } from 'react';
import { LoadBalancer } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type ELoadBalancersV1TableProps = {
  selectable?: boolean;
  loading?: boolean;
  loadBalancers: LoadBalancer[];
  onViewLoadBalancer?: (loadBalancers: LoadBalancer) => unknown;
  onSelect?: (names: string[]) => void;
};

export const ELoadBalancersV1Table = ({
  loadBalancers,
  selectable = true,
  loading,
  onSelect,
}: ELoadBalancersV1TableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ELB')}
    entry="LoadBalancerDescriptions"
    idAttribute="LoadBalancerName"
    filterColumns={['LoadBalancerName','DNSName', 'CanonicalHostedZoneName', 'Scheme', 'CanonicalHostedZoneNameID']}
    rows={loadBalancers}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
  />
);
