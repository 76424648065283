import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const ACM_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.ACM}`;

export const DEFAULT_ACM_ROUTES: Record<string, string> = {
  RESOURCES_ACM_CERTIFICATES: `${ACM_BASE_ROUTE}/certificates`,
  RESOURCES_ACM_CERTIFICATE: `${ACM_BASE_ROUTE}/certificate/:id`,
  RESOURCES_ACM_CERTIFICATE_CREATE: `${ACM_BASE_ROUTE}/certificate/new`,
  RESOURCES_ACM_CERTIFICATE_UPDATE: `${ACM_BASE_ROUTE}/certificate/:id/update`,
};
