import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateAgwRestApiMethodRequest, UpdateAgwRestApiMethodRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, AgwRestApiMethodForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiResourceNavTabs } from './components';

export const AgwRestApiMethodUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, resourceId, httpMethod } = useParams<'restApiId' | 'resourceId' | 'httpMethod'>();

  const { putMethod, updateMethod, isLoading } = useAwsEffect(
    'APIGateway',
    ['putMethod', 'updateMethod'],
    {
      revalidate: ['getMethod', 'getResource', 'getResources'] ,
      clientOverrides,
    },
  );

  const { data: resource } = useAwsGetter(
    'APIGateway',
    'getResource',
    [{ restApiId, resourceId }],
    { clientOverrides },
  );

  const { data: method } = useAwsGetter(
    'APIGateway',
    'getMethod',
    [{ restApiId, resourceId, httpMethod }],
    { clientOverrides },
  );

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const handleCreate = async (data: CreateAgwRestApiMethodRequest) => {
    await putMethod(data);
    goto(routes.RESOURCES_AGW1_API_METHODS, { restApiId, resourceId });
  };

  const handleUpdate = async (data: UpdateAgwRestApiMethodRequest) => {
    await updateMethod(data);
    goto(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Method Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [resource?.path, () => goto(routes.RESOURCES_AGW1_API_RESOURCE, { restApiId, resourceId })],
              [
                method?.httpMethod,
                () => goto(routes.RESOURCES_AGW1_API_METHOD, { restApiId, resourceId, httpMethod }),
              ],
              [httpMethod ? 'Update' : 'Create Method', null],
            ]}
          />
        </Box>
      }
      tabs={
        <RestApiResourceNavTabs
          restApiId={restApiId as string}
          resourceId={resourceId as string}
          routes={routes}
        />
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <AgwRestApiMethodForm
            restApiId={restApiId as string}
            resourceId={resourceId as string}
            method={method}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
