import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginBottom: theme.spacing(1),
      width: theme.breakpoints.values.sm,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    cardWithMt: {
      marginTop: theme.spacing(3),
    },
    idpItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      textTransform: 'uppercase',
    },
    progressItem: {
      height: '3px',
      backgroundColor: theme.palette.grey[300],
    },
    progressItemPassedStep: {
      backgroundColor: theme.palette.primary.main,
    },
    primaryItemBox: {
      border: '1px solid',
      borderColor: theme.palette.grey[300],
      borderRadius: '5px',
      padding: theme.spacing(1),
      margin: 0,
      transition: 'all .2s ease-in-out',
    },
    primaryItemBoxActive: {
      borderColor: theme.palette.primary.main,
    },
    hobbyBox: {
      paddingLeft: theme.spacing(2),
      borderLeft: '5px solid',
      borderColor: theme.palette.grey[300],
      marginLeft: theme.spacing(1.5),
    },
  }),
);
