import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { CloudFormationStack } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type CloudFormationStackGridProps = {
  loading?: boolean;
  selectable?: boolean;
  stacks: CloudFormationStack[];
  onViewStack?: (name: CloudFormationStack) => void;
  onSelect?: (names: string[]) => void;
};

export const CloudFormationStackGrid = ({
  loading,
  stacks,
  selectable = true,
  onViewStack,
  onSelect,
}: CloudFormationStackGridProps): ReactElement => (
  <MagicTable
    schema={getSchema('CloudFormation')}
    entry="StackSummary"
    idAttribute="StackName"
    rows={stacks}
    selectable={selectable}
    onSelect={onSelect}
    order={['StackName', 'StackStatus', 'CreationTime']}
    loading={loading}
    externalFields={{
      StackName: (row) => (
        <Link onClick={() => onViewStack && onViewStack(row)} underline="hover">
          {row.StackName}
        </Link>
      ),
    }}
  />
);
