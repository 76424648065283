import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useErrorSnackbarEffect } from '@localstack/services';
import {
  Card,
  CardContent,
  Box,
  Typography,
  Button,
  CardHeader,
  FormControlLabel,
  Switch,
  Grid,
  useTheme,
} from '@mui/material';
import {
  DEFAULT_NEPTUNE_ROUTES,
  ThemeType,
} from '@localstack/constants';

import Graph, { Network } from 'react-graph-vis';

import { IdType } from 'vis-network';

import {
  GraphEvent,
  GremlinEdge,
  GremlinGraphDataMap,
  GremlinNode,
  SERVICE_NAME,
} from '@localstack/types/src';

import { ICONS_MAP } from '@localstack/ui/src/display/AwsServiceIcon/icons';

import ReactDOMServer from 'react-dom/server';

import { NeptuneProps } from './types';

import { GraphNodeInfo, useNeptuneDataSourceArea } from './components';
import { NeptuneRequirementsAlert } from './NeptuneRequirementsAlert';
import { NavTabs } from './components/NavTabs';

const cache = new Map();

const computeImageUrl = (label: string): string | undefined => {
  const sections = label.split(':');
  if (label.startsWith('aws') && sections.length === 3) {
    const Icon = ICONS_MAP[sections.at(1) as SERVICE_NAME] || ICONS_MAP.infrastructure;
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(ReactDOMServer.renderToString(<Icon />))}`;
  }
  return undefined;
};

const getImageUrl = (label: string): string | undefined => {
  if (!cache.has(label)) {
    cache.set(label, computeImageUrl(label));
  }
  return cache.get(label);

};

export const NeptuneDBGraphBrowser = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_NEPTUNE_ROUTES,
}: NeptuneProps): ReactElement => {
  const [selectedNode, setSelectedNode] = useState<Optional<GremlinNode>>();
  const [networkPhysics, setNetworkPhysics] = useState<boolean>(true);
  const [network, setNetwork] = useState<Network | null>(null);
  const theme = useTheme();

  const getEdgeFontColor = () => theme.palette.mode === ThemeType.LIGHT ?
    theme.palette.grey[900] : theme.palette.common.white;

  const {
    returnData: data,
    error,
    deleteNode,
    NeptuneDataSourceArea,
  } = useNeptuneDataSourceArea({ clientOverrides, routes });

  const FIXED_GRAPH_OPTIONS = {
    layout: {
      hierarchical: false,
    },
    edges: {
      arrows: 'to',
      color: {
        color: theme.palette.common.black,
        opacity: 1,
        inherit: true,
        highlight: theme.palette.common.black,
      },
      font: {
        color: getEdgeFontColor(),
        strokeWidth: 0,
      },
      smooth: {
        enabled: true,
        type: 'dynamic',
        roundness: 0.5,
      },
    },
    nodes: {
      font: {
        color: getEdgeFontColor(),
        strokeWidth: 0,
      },
    },
    height: '800px',
    clickToUse: true,
  };

  const OPTIONS = {
    physics: {
      enabled: networkPhysics,
      forceAtlas2Based: {
        gravitationalConstant: -26,
        centralGravity: 0.005,
        springLength: 230,
        springConstant: 0.18,
      },
      maxVelocity: 146,
      solver: 'forceAtlas2Based',
      timestep: 0.35,
      stabilization: { iterations: 150 },
    },
    ...FIXED_GRAPH_OPTIONS,
  };

  useEffect(() => {
    if (network) {
      network.setData(data);
    }
  }, [data]);

  const dataMap = useMemo(() => ({
    nodes: data.nodes.reduce((map: Map<IdType, GremlinNode>, node: GremlinNode) => {
      const svg = getImageUrl(node.label ?? '');
      node.shape = svg ? 'image' : 'dot';
      node.image = svg;
      map.set(node.id as IdType, node);
      return map;
    }, new Map<IdType, GremlinNode>()),
    edges: data.edges.reduce((map: Map<IdType, GremlinEdge>, edge: GremlinEdge) => {
      map.set(edge.id as IdType, edge);
      return map;
    }, new Map<IdType, GremlinEdge>()),
  }), [data]) as GremlinGraphDataMap;

  const events = {
    selectNode: (event: GraphEvent) => {
      setSelectedNode(dataMap.nodes.get(event.nodes.at(0) as string));
    },
    dragStart: (event: GraphEvent) => {
      if (event.nodes.length === 1) {
        setSelectedNode(dataMap.nodes.get(event.nodes.at(0) as string));
      }
    },
  };

  useErrorSnackbarEffect(error);

  const isolate = () => {
    if (!selectedNode) {
      return;
    }

    const edges = network?.getConnectedEdges(selectedNode.id as IdType) ?? [];
    const nodes = edges.reduce((prev, curr) => {
      const [sourceId, destinationId] = network?.getConnectedNodes(curr) as [IdType, IdType];
      return sourceId === selectedNode.id ? [...prev, destinationId] : [...prev, sourceId];
    }, [selectedNode.id]);

    data.nodes.forEach((node) => {
      if (!nodes.includes(node.id as IdType)) {
        node.opacity = 0.3;
      } else {
        node.opacity = 1;
      }
    });

    type EdgeColor = Extract<GremlinEdge['color'], object>;
    type EdgeFont = Extract<GremlinEdge['font'], object>;

    data.edges.forEach((edge) => {
      if (!edges.includes(edge.id as IdType)) {
        edge.color = {
          ...edge.color as EdgeColor,
          opacity: 0.3,
        };
        edge.font = {
          ...edge.font as EdgeFont,
          color: 'rgba(0,0,0,0.3)',
          strokeWidth: 0,
        };
      } else {
        edge.color = FIXED_GRAPH_OPTIONS.edges.color;
        edge.font = FIXED_GRAPH_OPTIONS.edges.font;
      }
    });

    network?.setData(data);
  };
  
  return (
    <Layout
      documentTitle="Cluster Details"
      tabs={<NavTabs routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Graph Browser</Typography>
        </Box>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NeptuneRequirementsAlert />
        </Grid>
        <Grid item xs={12}>
          {NeptuneDataSourceArea}
        </Grid>
        <Grid item xs={12}>
          <GraphNodeInfo
            node={selectedNode}
            graphDataMap={dataMap}
            network={network}
            onNavigateCallback={(nodeId) => setSelectedNode(dataMap.nodes.get(nodeId))}
            onDelete={deleteNode}
          />
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader action={
              <>
                <FormControlLabel
                  control={
                    <Switch
                      checked={networkPhysics}
                      onChange={(_, checked) => setNetworkPhysics(checked)}
                      color="primary"
                    />
                  }
                  label="Physics"
                />
                <Button onClick={isolate}>
                  Isolate
                </Button>
                <Button onClick={() =>
                  selectedNode && network?.focus(selectedNode?.id as IdType, { scale: 1, animation: true })
                }
                >
                  Focus
                </Button>
              </>
            }
            />
            <CardContent>
              <Graph
                graph={data}
                options={OPTIONS}
                events={events}
                getNetwork={setNetwork}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout >
  );
};
