import { DEFAULT_DMS_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { CreateReplicationTaskMessage } from '@localstack/types';
import { Breadcrumbs, DMSReplicationTaskForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { DMSProps } from './types';

export const DMSReplicationTaskCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DMS_ROUTES,
}: DMSProps): ReactElement => {
  const { goto } = useRoutes();

  const { createReplicationTask, isLoading } = useAwsEffect(
    'DMS',
    ['createReplicationTask'],
    {
      clientOverrides,
      revalidate: ['describeReplicationTasks'],
    },
  );

  const handleCreate = async (data: CreateReplicationTaskMessage) => {
    if (await createReplicationTask(data)) {
      goto(routes.RESOURCES_DMS_REPLICATION_TASKS);
    }
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Replication Task Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DMS', () => goto(routes.RESOURCES_DMS_REPLICATION_TASKS)],
              ['Replication Tasks', () => goto(routes.RESOURCES_DMS_REPLICATION_TASKS)],
              ['Create', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="DMSReplicationTaskCreate"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <DMSReplicationTaskForm
            formId="DMSReplicationTaskCreate"
            onSubmit={handleCreate}
            loading={isLoading}
            clientOverrides={clientOverrides}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="outlined"
            type="submit"
            form="DMSReplicationTaskCreate"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
