import { useState, ReactElement } from 'react';
import { Toolbar, TextField, Button, IconButton } from '@mui/material';
import { PauseCircleFilled, PlayCircleFilled } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    background: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  textField: {
    flexGrow: 1,
    marginRight: theme.spacing(1),
  },
  liveUpdateOn: {
    color: theme.palette.success.main,
  },
  liveUpdateOff: {
    color: theme.palette.info.main,
  },
}));

export interface LogsTableToolbarProps {
  className?: any, // eslint-disable-line
  liveUpdate: boolean,
  onToggleLiveMode: () => unknown,
  onApply: (query?: string) => unknown,
}

export const LogsTableToolbar = ({
  className,
  liveUpdate,
  onToggleLiveMode,
  onApply,
}: LogsTableToolbarProps): ReactElement => {
  const classes = useStyles();
  const [query, setQuery] = useState<string | undefined>(undefined);

  return (
    <Toolbar className={classnames(classes.root, className)}>
      <TextField
        className={classes.textField}
        variant="outlined"
        size="small"
        label="Filter Pattern"
        value={query || ''}
        onChange={({ target }) => setQuery(target.value)}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => onApply(query)}
      >
        Apply
      </Button>
      <IconButton
        onClick={onToggleLiveMode}
        className={classnames({
          [classes.liveUpdateOn]: liveUpdate,
          [classes.liveUpdateOff]: !liveUpdate,
        })}
        size="large"
      >
        {liveUpdate && <PlayCircleFilled />}
        {!liveUpdate && <PauseCircleFilled />}
      </IconButton>
    </Toolbar>
  );
};
