import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, StateMachineUpsertForm, ProgressButton } from '@localstack/ui';
import { CreateStateMachineRequest, UpdateStateMachineRequest } from '@localstack/types';
import { DEFAULT_STEPFUNCTIONS_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { StepFunctionsProps } from './types';

export const StateMachineUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_STEPFUNCTIONS_ROUTES,
}: StepFunctionsProps): ReactElement => {
  const { goto } = useRoutes();
  const { stateMachineArn } = useParams<'stateMachineArn'>();

  const { data: stateMachineData } = useAwsGetter(
    'StepFunctions', 'describeStateMachine',
    [{ stateMachineArn }], { clientOverrides });

  const { createStateMachine, updateStateMachine, isLoading } = useAwsEffect(
    'StepFunctions',
    ['createStateMachine', 'updateStateMachine'],
    { revalidate: ['listStateMachines', 'describeStateMachine'], clientOverrides },
  );

  const handleCreate = async (data: CreateStateMachineRequest) => {
    const res = await createStateMachine(data);
    if (res) {
      goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES);
    }
  };

  const handleUpdate = async (data: UpdateStateMachineRequest) => {
    const res = await updateStateMachine(data);
    if (res) {
      goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES);
    }
  };


  return (
    <Layout
      documentTitle="Step Functions: Create State Machine"
      title={
        <Box>
          <Typography variant="h4">Create State Machine</Typography>
          <Breadcrumbs
            mappings={[
              ['Step Functions', () => goto(routes.RESOURCES_STEPFUNCTIONS)],
              ['State Machines', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES)],
              [stateMachineArn?.split(':').pop(), () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE)],
              [`${stateMachineArn ? 'Update' : 'Create'}`, null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateDB"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <StateMachineUpsertForm
            stateMachine={stateMachineData}
            loading={isLoading}
            formId="CreateDB"
            onCreate={handleCreate}
            onUpdate={handleUpdate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateDB"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
