import { getSchema } from '@localstack/services';
import { AppConfigApplication } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type AppConfigApplicationDetailProps = {
  application?: Optional<Partial<AppConfigApplication>>;
};

export const AppConfigApplicationDetail = ({
  application,
}: AppConfigApplicationDetailProps): ReactElement => (
  <MagicDetails
    data={application}
    schema={getSchema('AppConfig')}
    entry="Application"
    title="Application Details"
  />
);
