import { useState, useCallback, ReactElement, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, TextField, Box, Typography } from '@mui/material';
import { useRoutes, AuthService, useApiEffect, useSnackbar, useKeyPress } from '@localstack/services';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks';

import { BaseActivation } from './components/BaseActivation';

const DEFAULT_MESSAGE = 'Confirm authentication by clicking the button below';

export const AuthRequestActivation = (): ReactElement => {
  const { goto } = useRoutes();
  const { userInfo } = useAuthProvider();

  const [code, setCode] = useState<string>('');
  const [message, setMessage] = useState<JSX.Element | string>(DEFAULT_MESSAGE);

  const { requestId } = useParams<'requestId'>();
  const { showSnackbar } = useSnackbar();

  const { confirmUserAuthRequest } = useApiEffect(AuthService, ['confirmUserAuthRequest']);

  useEffect(() => {
    if (!userInfo) goto(AppRoute.SIGN_IN);
  }, [userInfo]);

  const confirm = useCallback(async () => {
    if (!requestId) return;

    setMessage(<CircularProgress />);

    if (await confirmUserAuthRequest(requestId, { code })) {
      goto(AppRoute.DASHBOARD);
      showSnackbar({ severity: 'success', message: 'Authenticated successfully' });
    }

    setMessage(DEFAULT_MESSAGE);
  }, [requestId, code]);

  useKeyPress(confirm, 'Enter');

  return (
    <BaseActivation
      message={
        <>
          <Box mb={3}>
            <TextField
              type="code"
              variant="outlined"
              label="Code"
              value={code || ''}
              onChange={(event) => setCode(event.target.value?.toUpperCase())}
              fullWidth
            />
            <Typography variant="caption">
              Type the code displayed in the application to authenticate.
            </Typography>
          </Box>
          {message}
        </>
      }
      activationDisabled={code?.length !== 4}
      activationText="Confirm"
      onActivation={confirm}
    />
  );
};
