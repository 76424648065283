import { ReactElement, useContext } from 'react';
import { Chip, Link, MenuItem, Typography, Box } from '@mui/material';
import { NavLink, useMatch, useParams } from 'react-router-dom';
import { Theme, useTheme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  OpenInNew as OpenInNewIcon,
  AccountCircle as AccountIcon,
  Apps as ResourcesIcon,
  BookOutlined as DocumentationIcon,
  Cloud as CloudIcon,
  DashboardOutlined as DashboardIcon,
  FlightTakeoff as FlightTakeoffIcon,
  Policy as PolicyIcon,
  Extension as ExtensionIcon,
  Computer as ComputerIcon,
  Lock as AdminIcon,
  Layers as LayersIcon,
  Loop as LoopIcon,
  Work as WorkspaceIcon,
  Report as ChaosEngineeringIcon,
} from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';

import {
  EphemeralInstanceStatus,
  LocalStackInstance,
  LocalStackInstanceType,
  MembershipRole,
  Permission,
  ProductFeature,
} from '@localstack/types';

import classNames from 'classnames';

import { buildRoute, GlobalStateContext, useLocalstackStatus } from '@localstack/services';


import { ExternalLink } from '@localstack/constants';

import { AppRoute } from '~/config';
import { useAuthProvider } from '~/hooks';
import { useFeatureFlags } from '~/util/FeatureFlags';
import { WorkspaceSelector } from '~/components/WorkspaceSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '310px',
      padding: '1rem',

      '.collapsed &': {
        width: 'unset',
        padding: '0.5em',
        paddingTop: '1em',
      },
    },

    hideOnSmallScreens: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },

    adminCallout: {
      color: 'white',
      fontWeight: 900,
      borderRadius: '8px',
      backgroundColor: '#ec4646',
      padding: '1em',
      textAlign: 'center',
    },

    menuItem: {
      border: '1px solid transparent',
      '&:hover, &.active': {
        color: theme.palette.primary.main,
        '& svg': {
          color: theme.palette.primary.main,
        },
      },
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
      },
      '&.active': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      borderRadius: 8,
    },
    icon: {
      color: theme.palette.text.secondary,
    },
    label: {
      marginLeft: '0.5em',

      '.collapsed &': {
        display: 'none',
      },
    },

    subMenu: {
      paddingLeft: '3rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.2rem',
      overflow: 'hidden',

      '.collapsed &': {
        display: 'none',
      },
    },

    sidebarSubMenuEntry: {
      textDecoration: 'none',
      marginRight: theme.spacing(2),
      fontSize: '14px',
      color: theme.palette.text.primary,
      padding: '0.5em',
      borderRadius: '0.5em',
      display: 'flex',
      flex: 4,
      overflow: 'hidden',

      '&.active': {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
      },

      '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.hover,
      },
    },

    instanceEntry: {
      width: '100%',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '0.5em',
      padding: '0.5em',
      marginLeft: '-0.5em',

      '&.active': {
        borderColor: theme.palette.primary.main,
      },

      '& hr': {
        marginLeft: '-0.5em',
        marginRight: '-0.5em',
        marginTop: '0.5em',
        marginBottom: '0.5em',
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },

    instanceSubMenu: {
      display: 'flex',
      flexDirection: 'column',
    },

    logo: {
      display: 'flex',
      alignItems: 'center',

      '& svg': {
        height: '1em',
        width: '1em',
      },
    },
    onlineIndicatorOffline: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      flex: 1,
      display: 'flex',
    },
    onlineIndicatorOnline: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    onlineIndicatorCreating: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
  }),
);

type InstanceSubMenuProps = {
  instance: LocalStackInstance
  activeInstanceId: string
  expanded: boolean
}
const InstanceSubMenu = ({ activeInstanceId, expanded, instance }: InstanceSubMenuProps): ReactElement => {
  const classes = useStyles();
  const { running } = useLocalstackStatus({ endpoint: instance.endpoint });
  
  const localstackStatus = running ? 'running' : 'offline';
  const ephemeralInstanceStatus = instance.ephemeralInstance?.status;
  const statusText = ephemeralInstanceStatus ?? localstackStatus;
  const creating = statusText === EphemeralInstanceStatus.CREATING;

  return (
    <Box className={classNames({ [classes.instanceEntry]: true, 'active': activeInstanceId === instance.id })}>
      <Box display="flex" overflow="hidden" alignItems="center" justifyContent="space-between">

        <NavLink
          to={buildRoute(AppRoute.RESOURCES_OVERVIEW, { iid: instance.id })}
          key={instance.id}
          end
          className={classNames(classes.sidebarSubMenuEntry, { 'active': activeInstanceId === instance.id })}
        >
          <Box alignItems="center" mr={1} component="span">
            {instance.instanceType === LocalStackInstanceType.EPHEMERAL_INSTANCE && (
              <><Chip size="small" color="primary" label={
                instance.name?.startsWith('pod-') ? 'Pod' : 'Eph'
              } />&nbsp;</>
            )}
            {instance.name}
            {!!instance.podVersion && `:${instance.podVersion}`}
          </Box>
        </NavLink>
        <Chip
          className={classNames(classes.onlineIndicatorOffline, { 
            [classes.onlineIndicatorOnline]: running,
            [classes.onlineIndicatorCreating]: creating,
          })}
          label={statusText}
          size='small'
        />
      </Box>
      {expanded && (
        <>
          <hr />
          <Box className={classes.instanceSubMenu}>
            <NavLink
              to={buildRoute(AppRoute.STATUS, { iid: instance?.id })}
              className={classes.sidebarSubMenuEntry}
            >
              Status
            </NavLink>
            <NavLink
              to={buildRoute(AppRoute.RESOURCES_OVERVIEW, { iid: instance?.id })}
              className={classes.sidebarSubMenuEntry}
            >
              Resource Browser
            </NavLink>
            <NavLink
              to={buildRoute(AppRoute.INSTANCE_STATE, { iid: instance?.id })}
              className={classes.sidebarSubMenuEntry}
            >
              Export&thinsp;/&thinsp;Import State
            </NavLink>
            <NavLink
              to={buildRoute(AppRoute.EXTENSIONS_MANAGE, { iid: instance?.id })}
              className={classes.sidebarSubMenuEntry}
            >
              Extensions
            </NavLink>
          </Box>
        </>
      )}
    </Box>
  );
};

export const Navbar = (): ReactElement => {
  const { instances } = useContext(GlobalStateContext);
  const { iid } = useParams<'iid'>() as { iid: string };
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { can, hasFeature } = useAuthProvider();

  const isResourceBrowser = !!useMatch(AppRoute.RESOURCES_COMMON);
  const isSystemStatus = !!useMatch(AppRoute.STATUS);
  const isExtensionsPage = !!useMatch(AppRoute.EXTENSIONS_MANAGE);
  const isStateManage = !!useMatch(AppRoute.INSTANCE_STATE);
  const isPerInstancePage = isResourceBrowser || isSystemStatus || isExtensionsPage || isStateManage;
  const isCloudPodsPage = !!useMatch(AppRoute.PODS) || !!useMatch(AppRoute.POD);

  const flags = useFeatureFlags();

  // show billing if user is permitted for any of those actions
  const billingPermissions = [
    Permission.READ_CREDIT_CARDS,
    Permission.READ_INVOICES,
    Permission.UPDATE_ORGANIZATION,
  ];

  return (
    <div data-name="navbar" className={classes.root}>
      <WorkspaceSelector menuItemClassName={classes.menuItem} labelClassName={classes.label}/>

      {can(MembershipRole.PLATFORM_ADMIN) && (
        <MenuItem className={classes.menuItem} component={NavLink} to={AppRoute.ADMIN_HOME}>
          <AdminIcon fontSize="small" className={classes.icon} />
          <Typography variant="inherit" className={classes.label}>Admin Panel</Typography>
        </MenuItem>
      )}

      {/* DASHBOARD */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.DASHBOARD}
      >
        <DashboardIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Dashboard</Typography>
      </MenuItem>

      {/* GETTING STARTED */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.GETTING_STARTED}
      >
        <FlightTakeoffIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Getting Started</Typography>
      </MenuItem>

      {/* LS INSTANCES */}
      <MenuItem className={classNames(classes.menuItem, { 'active': isPerInstancePage })}
        component={NavLink}
        to={buildRoute(AppRoute.INSTANCES)}
        end
      >
        <ResourcesIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>LocalStack Instances</Typography>
      </MenuItem>

      <div className={classes.subMenu}
        data-name="instances sub navigation"
        style={{ paddingTop: '0.5em', paddingBottom: '0.5em' }}
      >
        <NavLink
          to={buildRoute(AppRoute.INSTANCES_EPHEMERAL)}
          className={classes.sidebarSubMenuEntry}
          style={{ marginBottom: '0.5em' }}
          end
        >
          Ephemeral Instances
        </NavLink>

        {instances.map((instance, index) => (
          <InstanceSubMenu
            instance={instance}
            activeInstanceId={iid}
            expanded={(instance.id === iid) || (iid === undefined && index === 0)}
            key={instance.id}
          />

        ))}
      </div>

      {/* IAM POLICY STREAM */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.IAM_POLICY_STREAM}
      >
        <PolicyIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>IAM Policy Stream</Typography>
      </MenuItem>

      {/* CHAOS ENGINEERING */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.CHAOS_ENGINEERING}
      >
        <ChaosEngineeringIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Chaos Engineering</Typography>
      </MenuItem>

      {/* CLOUD PODS */}
      <MenuItem className={classNames(classes.menuItem, { 'active': isCloudPodsPage })}
        component={NavLink}
        to={AppRoute.PODS}
      >
        <CloudIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Cloud Pods</Typography>
      </MenuItem>

      {/* CI PROJECTS */}
      {hasFeature(ProductFeature.FEATURE_CI_PROJECTS) && flags.showCIProjects && (
        <MenuItem className={classes.menuItem}
          component={NavLink}
          to={AppRoute.CI_PROJECTS}
        >
          <LoopIcon fontSize="small" className={classes.icon} />
          <Typography variant="inherit" className={classes.label}>CI Projects</Typography>
        </MenuItem>
      )}

      {/* STACK INSIGHTS */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.STACKS_OVERVIEW}
      >
        <LayersIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Stack Insights</Typography>
      </MenuItem>
      
      {/* EXTENSIONS */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.EXTENSIONS_LIBRARY}
      >
        <ExtensionIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Extensions</Typography>
      </MenuItem>

      {/* WORKSPACE */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.WORKSPACE as string}
      >
        <WorkspaceIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Workspace</Typography>
      </MenuItem>

      <div className={classes.subMenu} data-name="workspace sub navigation">
        {can(Permission.UPDATE_ORGANIZATION) && (
          <NavLink to={buildRoute(AppRoute.WORKSPACE)} end className={classes.sidebarSubMenuEntry}>
            Workspace Settings
          </NavLink>
        )}

        <NavLink to={buildRoute(AppRoute.WORKSPACE_AUTH_TOKEN)} end className={classes.sidebarSubMenuEntry}>
          Auth Token
        </NavLink>

        <NavLink to={buildRoute(AppRoute.WORKSPACE_MY_LICENSE)} end className={classes.sidebarSubMenuEntry}>
          My License
        </NavLink>

        {can(Permission.READ_MEMBERS) && can(Permission.READ_SUBSCRIPTIONS) && (
          <NavLink to={buildRoute(AppRoute.WORKSPACE_MEMBERS)} className={classes.sidebarSubMenuEntry}>
            Users & Licenses
          </NavLink>
        )}

        {can(Permission.READ_CI_KEYS) && (
          <NavLink to={buildRoute(AppRoute.WORKSPACE_CI_KEYS)} className={classes.sidebarSubMenuEntry}>
            CI Keys
          </NavLink>
        )}

        <NavLink to={buildRoute(AppRoute.WORKSPACE_KEYS)} className={classes.sidebarSubMenuEntry}>
          Legacy API Keys
        </NavLink>

        {can(Permission.READ_SUBSCRIPTIONS) && (
          <NavLink to={buildRoute(AppRoute.WORKSPACE_SUBSCRIPTIONS)} end className={classes.sidebarSubMenuEntry}>
            Subscriptions
          </NavLink>
        )}

        {billingPermissions.some(can) && (
          <NavLink to={buildRoute(AppRoute.WORKSPACE_BILLING)} className={classes.sidebarSubMenuEntry}>
            Billing
          </NavLink>
        )}

        {(hasFeature(ProductFeature.FEATURE_CI_PROJECTS) && can(Permission.UPDATE_ORGANIZATION)) && (
          <NavLink
            to={buildRoute(AppRoute.WORKSPACE_SSO_SETTINGS)}
            className={classes.sidebarSubMenuEntry}
          >
            Single Sign-on
          </NavLink>
        )}
      </div>

      {/* ACCOUNT */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.ACCOUNT}
      >
        <AccountIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Account</Typography>
      </MenuItem>

      <div className={classes.subMenu} data-name="account sub navigation">
        <NavLink to={buildRoute(AppRoute.ACCOUNT)} end className={classes.sidebarSubMenuEntry}>
          Account Info
        </NavLink>

        <NavLink to={buildRoute(AppRoute.ACCOUNT_AUTHENTICATION)} className={classes.sidebarSubMenuEntry}>
          Password and Authentication
        </NavLink>
      </div>

      {/* LocalStack Desktop */}
      <MenuItem className={classes.menuItem}
        component={NavLink}
        to={AppRoute.DESKTOP_DOWNLOAD}
      >
        <ComputerIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>LocalStack Desktop</Typography>
      </MenuItem>

      {/* DOCS */}
      <MenuItem
        component={Link}
        href={ExternalLink.DOCS_BASE}
        target="__blank"
        className={classes.menuItem}
      >
        <DocumentationIcon fontSize="small" className={classes.icon} />
        <Typography variant="inherit" className={classes.label}>Documentation</Typography>
        {!isSmallScreen && (
          <OpenInNewIcon fontSize="small" className={classes.hideOnSmallScreens} />
        )}
      </MenuItem>
    </div>
  );
};
