import { useState, useMemo, ReactElement, ChangeEvent, useEffect } from 'react';
import { TextField, TextFieldProps, InputAdornment, CircularProgress } from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { usePrevious } from '@localstack/services';
import { debounce } from 'lodash';

const DEFAULT_DEBOUBCE_RATE = 1000;
const DEFAULT_CLEAR_TIMEOUT = 2000;

export type AutoSaveTextFieldProps = TextFieldProps & {
  onSave: (value: string) => unknown;
}

export const AutoSaveTextField = ({ onSave, value, defaultValue, ...rest }: AutoSaveTextFieldProps): ReactElement => {
  const [val, setVal] = useState<string>((defaultValue as string) || (value as string) || '');
  const previousVal = usePrevious(val);

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVal(event.target.value || '');
  };

  const handleSave = useMemo(
    () => debounce(async (text: string) => {
      setSaving(true);
      await onSave(text);
      setSaving(false);
      setSaved(true);
    }, DEFAULT_DEBOUBCE_RATE),
    [onSave],
  );

  useEffect(() => {
    // skip initial render
    if (!previousVal && val || previousVal === val) return;
    handleSave(val);
  }, [val]);

  useEffect(() => {
    if (!saved) return;
    const timeout = setTimeout(() => setSaved(false), DEFAULT_CLEAR_TIMEOUT);
    return () => clearTimeout(timeout);
  }, [saved]);

  return (
    <TextField
      variant="standard"
      onChange={handleChange}
      value={val}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            {saving && <CircularProgress size={20} />}
            {saved && <CheckIcon />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};
