import { ReactElement } from 'react';
import { AgwRestApiUsagePlan } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiUsagePlanDetailsProps {
  usagePlan?: Optional<Partial<AgwRestApiUsagePlan>>;
}

export const AgwRestApiUsagePlanDetails = ({ usagePlan }: AgwRestApiUsagePlanDetailsProps): ReactElement => (
  <MagicDetails
    data={usagePlan}
    schema={getSchema('APIGateway')}
    entry="UsagePlan"
    title="Usage Plan Details"
  />
);
