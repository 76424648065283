import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { AppRoute } from '~/config';

type Props = {
  /** pod name */
  name: string;
};

export const DetailsNavTabs = ({ name }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(AppRoute.POD, { name })}>
      Browser
    </NavLink>
    {false &&
      // TODO: re-enable diff view with new metamodel
      <NavLink to={buildRoute(AppRoute.POD_DIFF, { name })} end>
        Diff View
      </NavLink>
    }
  </>
);
