import { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';
import { v4 as uuid } from 'uuid';
import { Skeleton } from '@mui/material';

type StyleProps = {
  versionsPerRow: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: (props: StyleProps) => `repeat(${props.versionsPerRow}, 1fr)`,
      gridTemplateRows: (props: StyleProps) => `repeat(${props.versionsPerRow}, 1fr)`,
      gridGap: '1rem',
      padding: theme.spacing(2),
      '&::before': {
        content: '""',
        width: 0,
        paddingBottom: '100%',
        gridRow: '1 / 1',
        gridColumn: '1 / 1',
      },
    },
    item: {
      position: 'relative',
      borderRadius: '50%',
      '&:first-child': {
        gridRow: '1 / 1',
        gridColumn: '1 / 1',
      },
    },
    version: {
      ...theme.typography.body1,
      color: theme.palette.primary.contrastText,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: `linear-gradient(45deg, ${theme.palette.secondary.light} 0%, ${theme.palette.secondary.light} 100%)`,
      transition: 'box-shadow 0.1s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        background: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
        boxShadow: theme.shadows[5],
      },
    },
    filler: {
      background: theme.palette.action.disabledBackground,
    },
    skeleton: {
      height: '100%',
    },
    active: {
      background: `linear-gradient(45deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    },
  }),
);

export type VersionSelectorProps = {
  /** show loading state */
  loading?: boolean;
  /** selected version */
  selected: Optional<number>;
  /** list of available versions to choose from */
  versions: number[];
  /** callback fired when new version is selected */
  onSelectVersion: (version: number) => void;
  /** size of the selector */
  size?: 'small' | 'medium';
};

export const VersionSelector = ({
  loading,
  selected,
  versions,
  onSelectVersion,
  size = 'medium',
}: VersionSelectorProps): ReactElement => {
  const versionsPerRow = size === 'small' ? 10 : 24;
  const classes = useStyles({ versionsPerRow });

  return (
    <div
      className={classes.root}
      style={{
        gridTemplateRows: `repeat(${Math.max(1, Math.ceil(versions.length / versionsPerRow))}, 1fr)`,
      }}
    >
      {loading && new Array(versionsPerRow).fill(0).map(() => (
        <Skeleton variant="rectangular" className={classnames(classes.item, classes.skeleton)} key={uuid()} />
      ))}
      {!loading && versions.map((version) => (
        <div
          key={version}
          role="button"
          tabIndex={0}
          onClick={() => onSelectVersion(version)}
          className={classnames(classes.item, classes.version, {
            [classes.active]: version === selected,
          })}
        >
          V{version}
        </div>
      ))}
      {!loading && new Array(versionsPerRow - (versions.length % versionsPerRow)).fill(0).map(() => (
        <div className={classnames(classes.item, classes.filler)} key={uuid()} />
      ))}
    </div>
  );
};
