import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AppSyncFunctionDetails } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiFunction = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const { goto } = useRoutes();
  const { apiId, functionId } = useParams<'apiId' | 'functionId'>();

  const { data: func } = useAwsGetter('AppSync', 'getFunction', [{ apiId, functionId }], { clientOverrides });

  return (
    <Layout
      documentTitle="Function Details"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Function Details</Typography>
          <Breadcrumbs
            mappings={[
              ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
              [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
              [func?.functionConfiguration?.name, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(
            routes.RESOURCES_APPSYNC_API_FUNCTION_UPDATE,
            { apiId, functionId },
          )}
        >
          Edit Function
        </Button>
      }
    >
      <Card>
        <CardContent>
          <AppSyncFunctionDetails func={func?.functionConfiguration} />
        </CardContent>
      </Card>
    </Layout>
  );
};
