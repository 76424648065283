import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, RDSInstanceDetails } from '@localstack/ui';

import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { InstanceNavTabs } from './components';
import { RDSProps } from './types';

export const RDSDBInstance = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_RDS_ROUTES,
}: RDSProps): ReactElement => {
  const { goto } = useRoutes();
  const { instanceId } = useParams<'instanceId'>();

  const { data: instances } = useAwsGetter(
    'RDS',
    'describeDBInstances',
    [{ DBInstanceIdentifier: instanceId }],
    { clientOverrides },
  );
  const instance = instances?.DBInstances?.find((inst) => inst.DBInstanceIdentifier === instanceId);

  return (
    <Layout
      documentTitle="RDS: Instance Details"
      tabs={<InstanceNavTabs instanceId={instanceId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Instance Details</Typography>
          <Breadcrumbs
            mappings={[
              ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
              ['Databases', () => goto(routes.RESOURCES_RDS_DATABASES)],
              [`${instanceId} (${instance?.DBName})`, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_RDS_INSTANCE_UPDATE, { instanceId })}
        >
          Edit Instance
        </Button>
      }
    >
      <Card>
        <CardContent>
          <RDSInstanceDetails instance={instance} />
        </CardContent>
      </Card>
    </Layout>
  );
};
