import { ReactElement } from 'react';
import { EventBridgeBus } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface EventBridgeBusesTableProps {
  selectable?: boolean;
  loading?: boolean;
  buses: EventBridgeBus[];
  onViewBus?: (model: EventBridgeBus) => unknown;
  onSelect?: (ids: string[]) => void;
}

export const EventBridgeBusesTable = ({
  buses,
  selectable = true,
  loading,
  onViewBus,
  onSelect,
}: EventBridgeBusesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('EventBridge')}
    entry="EventBus"
    idAttribute="Name"
    rows={buses}
    selectable={selectable}
    onSelect={onSelect}
    order={['Name']}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link onClick={() => onViewBus && onViewBus(row)} underline="hover">
          {row.Name}
        </Link>
      ),
    }}
  />
);
