import { ReactElement } from 'react';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { PutCloudWatchMetricRequest } from '@localstack/types';
import { ProgressButton, MonitoringMetricForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_METRICS_ROUTES } from '@localstack/constants/src';

import { MetricsProps } from './types';

export const MetricUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_METRICS_ROUTES,
}: MetricsProps): ReactElement => {
  const { goto } = useRoutes();

  const { putMetricData, isLoading } = useAwsEffect(
    'CloudWatch',
    ['putMetricData'],
    { revalidate: ['listMetrics'],clientOverrides },
  );

  const handleCreate = async (data: PutCloudWatchMetricRequest) => {
    await putMetricData(data);
    goto(routes.RESOURCES_CLOUDWATCH_MONITORING_METRICS);
  };

  return (
    <Layout
      documentTitle="Metric Details"
      title={
        <Box>
          <Typography variant="h4">Metric Details</Typography>
          <Breadcrumbs
            mappings={[
              ['CloudWatch Metrics', () => goto(routes.RESOURCES_CLOUDWATCH_MONITORING)],
              ['Metrics', () => goto(routes.RESOURCES_CLOUDWATCH_MONITORING_METRICS)],
              ['Put Metric', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <MonitoringMetricForm
            onCreate={handleCreate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
