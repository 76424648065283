import { ReactElement } from 'react';
import { AgwRestApiMethod } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiMethodsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  methods: AgwRestApiMethod[];
  onViewMethod?: (method: AgwRestApiMethod) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AgwRestApiMethodsTable = ({
  methods,
  selectable = true,
  loading,
  onViewMethod,
  onSelect,
}: AgwRestApiMethodsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="Method"
    idAttribute="httpMethod"
    rows={methods}
    selectable={selectable}
    onSelect={onSelect}
    order={['httpMethod']}
    loading={loading}
    externalFields={{
      httpMethod: (row) => (
        <Link onClick={() => onViewMethod && onViewMethod(row)} underline="hover">
          {row.httpMethod}
        </Link>
      ),
    }}
  />
);
