import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, AgwRestApiAuthorizerDetails as Details } from '@localstack/ui';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiAuthorizerDetails = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { goto } = useRoutes();
  const { restApiId, authorizerId } = useParams<'restApiId' | 'authorizerId'>();

  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });

  const { data: authorizer } = useAwsGetter(
    'APIGateway',
    'getAuthorizer',
    [{ restApiId, authorizerId }],
    { clientOverrides },
  );

  return (
    <Layout
      documentTitle="Authorizer Details"
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Authorizer Details</Typography>
          <Breadcrumbs
            mappings={[
              ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
              [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
              [authorizer?.name, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(
            routes.RESOURCES_AGW1_API_AUTHORIZER_UPDATE,
            { restApiId, authorizerId },
          )}
        >
          Edit Authorizer
        </Button>
      }
    >
      <Card>
        <CardContent>
          <Details authorizer={authorizer} />
        </CardContent>
      </Card>
    </Layout>
  );
};
