import { ReactElement } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';

import { ThemeType } from '@localstack/constants';

import LogoSvg from './logos/localstack.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@keyframes text-animation': {
      '0%': {
        transform: 'translate(120px, -120px)',
        visibility: 'hidden',
        opacity: 0,
      },
      '100%': {
        transform: 'translate(0)',
        visibility: 'visible',
        opacity: 1,
      },
    },
    '@keyframes rocket-animation': {
      '0%': {
        transform: 'translate(-120px, 120px)',
        visibility: 'hidden',
        opacity: 0,
      },
      '100%': {
        transform: 'translate(0,0)',
        visibility: 'visible',
        opacity: 1,
      },
    },
    // Note svg classes a prefixed in storybook
    root: {
      '& .text': {
        fill: theme.palette.mode === ThemeType.DARK ? '#E5E5E5' : undefined,
      },
      marginBottom: theme.spacing(8),
      maxWidth: '100%',
    },
    animation: {
      '& .text': {
        animation: '$text-animation 1.5s ease-in-out',
      },
      '& .rocket': {
        animation: '$rocket-animation 1.5s ease-in-out',
      },
    },
    medium: {
      height: theme.spacing(15),
      width: 'auto',
    },
    small: {
      height: theme.spacing(10),
      width: 'auto',
    },
  }),
);

export type AnimatedLogoProps = {
  size?: 'medium' | 'small';
  animation?: boolean;
};

export const AnimatedLogo = ({ size = 'medium', animation = true }: AnimatedLogoProps): ReactElement => {
  const classes = useStyles();

  return (
    <LogoSvg
      className={classnames(classes.root, {
        [classes.medium]: size === 'medium',
        [classes.small]: size === 'small',
        [classes.animation]: animation,
      })}
    />
  );
};
