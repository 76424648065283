import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  AgwRestApisTable,
} from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { IndexNavTabs } from './components';

export const AgwV1Index = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: apis, isLoading, mutate } = useAwsGetter('APIGateway', 'getRestApis', [], { clientOverrides });

  const { deleteRestApi } = useAwsEffect(
    'APIGateway',
    ['deleteRestApi'],
    {
      revalidate: ['getRestApi', 'getRestApis'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((restApiId) => deleteRestApi({ restApiId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={
        <PageTitle
          title="API Gateway"
          onMutate={mutate}
        />
      }
      tabs={<IndexNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_CREATE)}>
            Create API
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} API(s)?`}
              onClick={handleDeleteSelected}
              text="Selected APIs will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwRestApisTable
          apis={apis?.items || []}
          onSelect={setSelectedIds}
          onViewApi={({ id }) => goto(routes.RESOURCES_AGW1_API, { restApiId: id })}
        />
      </Card>
    </Layout>
  );
};
