import { ReactElement } from 'react';
import { Grid, Card, CardHeader, CardContent, TextField, CardActions, Button , Alert } from '@mui/material';
import { Organization } from '@localstack/types';
import { useRoutes } from '@localstack/services';


import { AppRoute } from '~/config';

export interface SmallOrgDetailsProps {
  organization?: Organization;
  alertMessage?: string;
}


export const SmallOrgDetails = ({
  organization,
  alertMessage,
}: SmallOrgDetailsProps): ReactElement => {
  const { goto } = useRoutes();

  const handleMoreButton = () => {
    goto(AppRoute.ADMIN_ORGANIZATION, { id: organization?.id });
  };

  return (
    <Card>
      <CardHeader title='Organization Info' />
      <CardContent>
        {organization ?
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField
                label="Name"
                fullWidth
                variant="outlined"
                value={organization?.name}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                fullWidth
                variant="outlined"
                value={organization?.email}
                disabled
              />
            </Grid>
          </Grid>
          :
          <Alert severity="error">{alertMessage}</Alert>
        }
      </CardContent>
      <CardActions>
        {
          organization &&
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={handleMoreButton}
          >
            More Info
          </Button>
        }
      </CardActions>
    </Card>
  );
};
