import { useEffect, useState, useMemo } from 'react';

import { HealthServices, LocalStackEvent, RESOURCES_SERVICES_TYPE, SERVICE_NAME } from '@localstack/types';

import { AwsClientOverrides, useAwsGetter } from '../aws';
/**
 * A little hook to automatically keep track of localstack health
 */
type LocalStackStatus = {
  running: boolean;
  version?: string;
  services?: HealthServices;
  isPro?: boolean;
}
export const useLocalstackStatus = (clientOverrides?: AwsClientOverrides, enable?: boolean): LocalStackStatus => {
  const { data: health, hasError } =
    useAwsGetter(
      'LocalStack',
      'getHealth',
      [],
      {
        silentErrors: true,
        swrOverrides: {
          refreshInterval: 1000,
          errorRetryInterval: 1000,
          shouldRetryOnError: true,
          onErrorRetry: (_error, _key, _config, revalidate) => {
            setTimeout(() => revalidate(), 1000);
          },
        },
        clientOverrides,
        enable,
        defaultValue: undefined,
      },
    );

  const [running, setRunning] = useState<boolean>(!!health); // init value is whether we got data
  const [version, setVersion] = useState<string | undefined>();
  const [services, setServices] = useState<HealthServices>();
  const [isPro, setIsPro] = useState<boolean>(false);

  useEffect(() => {
    const run = !!health && !hasError;
    setRunning(run); // if there's an error we're not running
    setVersion(run ? health?.version : undefined);
    setServices(run ? health?.services : undefined);
  }, [health, hasError]);

  useEffect(() => {
    const servicesList = Object.keys(health?.services ?? {});
    setIsPro(servicesList.some(element =>
      RESOURCES_SERVICES_TYPE.pro.includes(element as SERVICE_NAME),
    ));
  }, [health]);

  return {
    running,
    version,
    services,
    isPro,
  };
};


type LocalStackEventsHandler = {
  setLogs: (logs: string) => void;
  logs?: LocalStackEvent[];
}

export const useLocalStackEvents = (): LocalStackEventsHandler => {
  const [events, setEvents] = useState<string>('');

  const parsedEvents: LocalStackEvent[] = useMemo(() => {
    const splittedEvents = events.split('\n');
    const nonEmptyEvents = splittedEvents.filter((line) => line !== '');
    return nonEmptyEvents.map(event => JSON.parse(event));
  }, [events]);

  return {
    setLogs: setEvents,
    logs: parsedEvents,
  };

};
