/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StackAwsApiCall } from '@localstack/types';
import type { StackInfo } from '@localstack/types';
import type { StackServiceUsage } from '@localstack/types';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StacksService {

    /**
     * @param since Only return stacks that were started after the given timestamp.
     * Adding this is good, it limits the search space.
     *
     * @param until Only return stacks that were started before the given timestamp.
     * Adding this is good, it limits the search space.
     *
     * @param limit
     * @param offset
     * @returns StackInfo
     * @throws ApiError
     */
    public static listStacks(
        since?: string,
        until?: string,
        limit?: number,
        offset?: number,
    ): CancelablePromise<Array<StackInfo>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/stacks',
            query: {
                'since': since,
                'until': until,
                'limit': limit,
                'offset': offset,
            },
        });
    }

    /**
     * @param stackId The ID of the stack (i.e., the LocalStack session ID)
     * @param service Only return API calls belonging to this AWS service.
     * @param operation Only return API calls of this AWS operation type.
     * @param region Only return API calls that were made in this AWS region.
     * @param account Only return API calls that were made for this AWS account ID.
     * @param isInternal Include or exclude AWS API calls that were made by the LocalStack system.
     * @param statusCode Only return API calls that resulted in the given HTTP status code.
     * @param since Only return events that were started after the given timestamp.
     * Adding this is good, it limits the search space.
     *
     * @param until Only return events that were started before the given timestamp.
     * Adding this is good, it limits the search space.
     *
     * @returns StackAwsApiCall
     * @throws ApiError
     */
    public static listStackEvents(
        stackId: string,
        service?: string,
        operation?: string,
        region?: string,
        account?: string,
        isInternal?: boolean,
        statusCode?: number,
        since?: string,
        until?: string,
    ): CancelablePromise<Array<StackAwsApiCall>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/stacks/{stack_id}/events',
            path: {
                'stack_id': stackId,
            },
            query: {
                'service': service,
                'operation': operation,
                'region': region,
                'account': account,
                'is_internal': isInternal,
                'status_code': statusCode,
                'since': since,
                'until': until,
            },
        });
    }

    /**
     * @param stackId The ID of the stack (i.e., the LocalStack session ID)
     * @returns StackServiceUsage
     * @throws ApiError
     */
    public static getStackStatsServiceUsage(
        stackId: string,
    ): CancelablePromise<Array<StackServiceUsage>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/stacks/{stack_id}/stats/services',
            path: {
                'stack_id': stackId,
            },
        });
    }

}
