import { ReactElement } from 'react';
import { ResolverEndpoint } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface Route53ResolverEndpointDetailProps {
  endpoint?: Optional<ResolverEndpoint>;
}

export const Route53ResolverEndpointDetails = ({ endpoint }: Route53ResolverEndpointDetailProps): ReactElement => (
  <MagicDetails
    data={endpoint}
    schema={getSchema('Route53Resolver')}
    entry="ResolverEndpoint"
    title="Endpoint Details"
  />
);
