import { useState, useEffect, ReactNode, ReactElement } from 'react';
import { Tabs, Tab, TabsProps } from '@mui/material';

export const TabPanel: React.FC<{ index: number | string; tab: number | string }> = ({
  children,
  index,
  tab,
}) => (
  /**
   * Instead of using "hidden" attribute for hidden tab panels,
   * hiding tab panels with CSS visibility: hidden
   * so that repeating SVGs linearGradient IDs don't point out to hidden elements
   * Fixes: https://github.com/localstack/localstack-web/issues/541
   */
  <div role="tabpanel" style={tab !== index ? {
    visibility: 'hidden',
    position: 'absolute',
    width: 0,
    height: 0,
    overflow: 'hidden',
  } : {}}
  >
    {children}
  </div>
);

export interface ControlledTabPanelsProps {
  selected?: number;
  onTabChange?: (label: string) => void;
  options: { label: string; panel: ReactNode }[];
  renderTab?: (label: string, index: number) => ReactElement;
}

/**
 * Material UI Tab switching with automatic state handling.
 */
export const ControlledTabPanels: React.FC<ControlledTabPanelsProps & TabsProps> = ({
  selected,
  onTabChange,
  options,
  renderTab,
  ...rest
}) => {
  const [tab, setTab] = useState(selected || 0);

  useEffect(() => {
    if (selected !== undefined) setTab(selected);
  }, [selected]);

  useEffect(() => {
    if (tab !== selected) onTabChange?.(options.find((_v, idx) => idx === tab)?.label as string);
    if (tab >= options.length) setTab(0);
  }, [tab]);

  useEffect(() => {
    if (tab >= options.length) setTab(0);
  }, [tab, options]);

  return (
    <>
      <Tabs
        {...rest}
        value={tab}
        onChange={(_event, index) => setTab(index)}
      >
        {/* eslint-disable-next-line */}
        {options.map(({ label }, index) => renderTab ? renderTab(label, index) : <Tab key={index} label={label} />)}
      </Tabs>
      {options.map(({ label, panel }, index) => (
        <TabPanel key={label} tab={tab} index={index}>
          {panel}
        </TabPanel>
      ))}
    </>
  );
};
