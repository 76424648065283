import { ReactElement } from 'react';
import { AgwRestApiModel } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface AgwRestApiModelDetailsProps {
  model?: Optional<Partial<AgwRestApiModel>>;
}

export const AgwRestApiModelDetails = ({ model }: AgwRestApiModelDetailsProps): ReactElement => (
  <MagicDetails
    data={model}
    schema={getSchema('APIGateway')}
    entry="Model"
    title="Model Details"
  />
);
