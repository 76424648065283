import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AgwApiIntegration } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwApiIntegrationsTableProps = {
  selectable?: boolean;
  loading?: boolean;
  integrations: AgwApiIntegration[];
  onViewIntegration?: (integration: AgwApiIntegration) => unknown;
  onSelect?: (ids: string[]) => void;
};

export const AgwApiIntegrationsTable = ({
  integrations,
  selectable = true,
  loading,
  onViewIntegration,
  onSelect,
}: AgwApiIntegrationsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ApiGatewayV2')}
    entry="Integration"
    idAttribute="IntegrationId"
    rows={integrations}
    selectable={selectable}
    onSelect={onSelect}
    order={['IntegrationId', 'IntegrationUri']}
    loading={loading}
    externalFields={{
      IntegrationId: (row) => (
        <Link
          onClick={() => onViewIntegration && onViewIntegration(row)}
          underline="hover"
        >
          {row.IntegrationId}
        </Link>
      ),
      IntegrationUri: (row) => (
        <Link
          onClick={() => onViewIntegration && onViewIntegration(row)}
          underline="hover"
        >
          {row.IntegrationUri?.split(':')[6]}
        </Link>
      ),
    }}
  />
);
