import { ReactElement } from 'react';
import { Address } from '@localstack/types';
import { Box, Typography } from '@mui/material';
import { COUNTRY_MAP } from '@localstack/constants';

export interface AddressPreviewProps {
  showName?: boolean;
  address: Address;
}

export const AddressPreview = ({ showName, address }: AddressPreviewProps): ReactElement => (
  <Box display="flex" alignItems="center" flexWrap="wrap" flexDirection="row">
    {showName && (
      <Box mr={2}>
        <Typography variant="caption">Company Name</Typography>
        <Typography>{address?.name}</Typography>
      </Box>
    )}
    <Box mr={2}>
      <Typography variant="caption">Phone</Typography>
      <Typography>{address?.phone}</Typography>
    </Box>
    <Box mr={2}>
      <Typography variant="caption">Address</Typography>
      <Typography>
        {address?.address},{' '}
        {address?.zip}{' '}
        {address?.city},{' '}
        {COUNTRY_MAP[address?.country as keyof typeof COUNTRY_MAP]}
      </Typography>
    </Box>
  </Box>
);
