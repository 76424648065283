import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { buildAgwPatches, getSchema } from '@localstack/services';

import {
  AgwRestApiAuthorizer,
  CreateAgwRestApiAuthorizerRequest,
  UpdateAgwRestApiAuthorizerRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';

export interface AgwRestApiAuthorizerFormProps {
  restApiId: string;
  authorizer?: Optional<Partial<AgwRestApiAuthorizer>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAgwRestApiAuthorizerRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiAuthorizerRequest) => unknown;
}

export const AgwRestApiAuthorizerForm = ({
  restApiId,
  authorizer,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AgwRestApiAuthorizerFormProps): ReactElement => (
  <MagicForm
    data={authorizer}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateAuthorizerRequest"
    formId={formId}
    fieldConditions={{
      '^providerARNs': ['$type', '===', 'COGNITO_USER_POOLS'],
      '^(authorizerUri|authorizerCredentials|identitySource)': ['$type', 'in', ['TOKEN', 'REQUEST']],
      '^identityValidationExpression': ['$type', '===', 'TOKEN'],
    }}
    externalFields={{
      '^restApiId$': () => <TextField fullWidth disabled value={restApiId} variant="outlined" />,
    }}
    onSubmit={(data: CreateAgwRestApiAuthorizerRequest) => {
      if (!authorizer) return onCreate({ ...data, restApiId });

      const patchOperations = buildAgwPatches(authorizer, data);
      return onUpdate({ restApiId, authorizerId: authorizer.id as string, patchOperations });
    }}
  />
);
