/* eslint-disable @typescript-eslint/no-explicit-any */

import { ReactElement } from 'react';
import { Grid, IconButton, MenuItem, Tooltip } from '@mui/material';
import {
  AWS_CLIENTS,
  AwsClientOverrides,
  buildRoute,
  getSelectedInstance,
  transformMagicFieldName,
  useAwsGetter,
  VALIDATION_RULES,
} from '@localstack/services';
import { Control } from 'react-hook-form';
import { AddOutlined, RefreshOutlined } from '@mui/icons-material';
import {
  ArrayElement,
  ExtractMethodsRecord,
  ExtractOverloadedParams,
  ExtractResponse,
  FilterUnknowns,
  TupleArrayUnion ,
} from '@localstack/types';

import ControlledSelect from '../ControlledSelect';
import { NewTabLink } from '../../navigation';

export const RelatedResourcePicker = <
  // Client name is a key of AWS_CLIENTS
  Client extends keyof typeof AWS_CLIENTS,
  // Extract all methods from the matched AWS Client Instance
  Methods extends ExtractMethodsRecord<InstanceType<(typeof AWS_CLIENTS)[Client]>>,
  // Selected method name should be part of our matched AWS Client Instance
  MethodName extends keyof Methods,
  // Extract matching method from AWS Client Instance
  Method extends (Methods[MethodName] extends (...a: any[]) => any ? Methods[MethodName] : never),
  // Extract return type from our AWS SDK METHOD
  MethodArgs extends TupleArrayUnion<FilterUnknowns<ExtractOverloadedParams<Method>>>,
  // Here we are mapping through arguments and making them optional
  MethodArgsTuple extends {
    [K in keyof MethodArgs]: MethodArgs[K] extends Record<any, any>
    ? Partial<MethodArgs[K]>
    : Optional<MethodArgs[K]>
  },
  // TS argues "MethodArgsTuple" is not an array, thus here we are making sure the final args list is an array
  MethodArgsArray extends (MethodArgsTuple extends any[] ? MethodArgsTuple : never),

  ResultsKey extends keyof ExtractResponse<ReturnType<Method>>,
  ResultsItemProperty extends keyof ArrayElement<ExtractResponse<ReturnType<Method>>[ResultsKey]>,
>({
    client,
    method,
    args,
    arrayKeyName,
    control,
    property,
    fieldName,
    entityName,
    clientOverrides,
    creationRoute,
    required,
  }: {
  client: Client,
  method: MethodName,
  args?: MethodArgsArray,
  clientOverrides?: AwsClientOverrides,
  arrayKeyName: ResultsKey,
  property: ResultsItemProperty,
  control: Control<any>;
  fieldName: string;
  entityName: string;
  creationRoute?: string;
  required?: Optional<boolean>
}): ReactElement => {
  // TODO: clean up by passing down settings everywhere this Picker is used
  const selectedInstance = getSelectedInstance();
  const { data, mutate } = useAwsGetter(
    client,
    method as unknown as keyof ExtractMethodsRecord<InstanceType<(typeof AWS_CLIENTS)[Client]>>,
    // @ts-expect-error FIXME: cast to proper type
    args, { clientOverrides },
  );

  let options: ReactElement[] = [<MenuItem key="" value="">None</MenuItem>];
  if (data) {
    const dataArray = data[arrayKeyName as keyof typeof data];
    if (Array.isArray(dataArray) && dataArray.length) {
      options = [
        ...options,
        ...dataArray.map(
          (item: ReactElement) =>
            <MenuItem
              key={item[property as keyof typeof item]}
              value={item[property as keyof typeof item]}
            >
              {item[property as keyof typeof item]}
            </MenuItem>,
        ),
      ];
    }
  }

  const adjustedRoute = creationRoute ? buildRoute(creationRoute, { iid: selectedInstance?.id }) : undefined;

  return (
    <Grid container spacing={3} alignItems='center' style={{ flexWrap: 'nowrap' }}>
      <Grid item style={{ flexGrow: 1, minWidth: 0 }}>
        <ControlledSelect
          control={control}
          required={!!required}
          fullWidth
          label={transformMagicFieldName(fieldName.split('.').pop())}
          name={fieldName}
          variant="outlined"
          options={options}
          rules={required ? VALIDATION_RULES.required : undefined}
        />
      </Grid>
      <Grid item>
        <Grid container spacing={2} alignItems='center' style={{ flexWrap: 'nowrap' }}>
          <Grid item xs>
            <Tooltip title='Refresh'>
              <IconButton edge="start" onClick={() => mutate()} size="large"><RefreshOutlined /></IconButton>
            </Tooltip>
          </Grid>
          {adjustedRoute && (
            <Grid item xs>
              <NewTabLink type='NavLink' to={adjustedRoute}>
                <Tooltip title={`Create ${entityName} (opens a new tab)`}>
                  <IconButton edge="start" size="large"><AddOutlined /></IconButton>
                </Tooltip>
              </NewTabLink>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid >
  );
};
