import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { StateMachineExecutionsTable, PageTitle, ConfirmableButton, Dropdown } from '@localstack/ui';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { ExecutionItem } from '@localstack/types';
import { DEFAULT_STEPFUNCTIONS_ROUTES } from '@localstack/constants';

import { StepFunctionsProps } from './types';

import { MachineNavTabs } from './components';

const MAX_RESULTS = 200;

export const StateExecutions = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_STEPFUNCTIONS_ROUTES,
}: StepFunctionsProps): ReactElement => {
  const { stateMachineArn } = useParams<'stateMachineArn'>();
  const stateMachineName = stateMachineArn?.split(':').pop();
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<ExecutionItem[]>([]);

  const { data: executions, isLoading, mutate } = useAwsGetter(
    'StepFunctions',
    'listExecutions',
    [{ stateMachineArn, maxResults: MAX_RESULTS }],
    { clientOverrides },
  );

  const { stopExecution } = useAwsEffect(
    'StepFunctions',
    ['stopExecution'],
    { revalidate: ['listExecutions'], clientOverrides },
  );

  useEffect(() => {
    setSelectedRows(executions?.executions?.filter(execution => selected.includes(execution.executionArn)) || []);
  }, [selected]);

  const handleStopExecution = useCallback(async () =>
    Promise.all(selected.map((executionArn) => stopExecution({ executionArn }))), [selected],
  );


  return (
    <Layout
      documentTitle="Step Functions: State Machine Executions"
      tabs={<MachineNavTabs stateMachineArn={stateMachineArn as string} routes={routes} />}
      title={
        <PageTitle
          title="State Machine Executions"
          onMutate={mutate}
          breadcrumbs={[
            ['Step Functions', () => goto(routes.RESOURCES_STEPFUNCTIONS)],
            ['State Machines', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES)],
            [stateMachineName, () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE, { stateMachineArn })],
            ['Executions', () => null],
          ]}
        />
      }
      actions={
        <>
          <Button
            onClick={() => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION_START, { stateMachineArn })}
          >
            Start Execution
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading || !selectedRows.every(item => item.status === 'RUNNING')}
              title={`Stop ${selected.length} Execution(s)?`}
              onClick={handleStopExecution}
              text="This will abort the selected execution run"
            >
              Stop Execution
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <StateMachineExecutionsTable
          executions={executions?.executions ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewExecution={
            (execution) => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION, {
              stateMachineArn,
              executionArn: execution.executionArn,
            })
          }
        />
      </Card>
    </Layout>
  );
};
