import { ReactElement } from 'react';
import { LambdaFunctionConfig } from '@localstack/types';

import { MagicDetails } from '../../../magic/MagicDetails';

export type LambdaDetailsProps = {
  lambdaFunction: Optional<LambdaFunctionConfig>;
};

const SCHEMA = {
  shapes: {
    LambdaFunctionRefactored: {
      type: 'structure',
      members: {
        FunctionName: {
          type: 'string',
        },
        FunctionArn: {
          type: 'string',
        },
        Role: {
          type: 'string',
        },
        Timeout: {
          type: 'integer',
        },
        LastModified: {
          type: 'timestamp',
        },
        Version: {
          type: 'string',
        },
        RevisionId: {
          type: 'string',
        },
        State: {
          type: 'string',
        },
        LastUpdateStatus: {
          type: 'string',
        },
        PackageType: {
          type: 'string',
        },
        Architectures: {
          type: 'string',
        },
      },
    },
  },
};

export const LambdaDetails = ({ lambdaFunction }: LambdaDetailsProps): ReactElement => (
  <MagicDetails
    data={lambdaFunction}
    schema={SCHEMA}
    entry="LambdaFunctionRefactored"
    title="Details for Lambda"
  />
);
