import { ReactElement } from 'react';
import { Route53HostedZone } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type Route53HostedZonesTableProps = {
  hostedzones: Route53HostedZone[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewHostedZone?: (name: string) => void;
};

export const Route53HostedZonesTable = ({
  hostedzones,
  selectable = true,
  loading,
  onSelect,
  onViewHostedZone,
}: Route53HostedZonesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Route53')}
    entry="HostedZone"
    idAttribute="Name"
    order={['Name']}
    filterColumns={['Id', 'Name', 'ResourceRecordSetCount']}
    rows={hostedzones}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      Name: (row) => (
        <Link
          onClick={() => onViewHostedZone && onViewHostedZone(row.Id as string)}
          underline="hover"
        >
          {row.Name}
        </Link>
      ),
    }}
  />
);
