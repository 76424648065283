import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, ECSServiceDetails } from '@localstack/ui';
import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

import { ClusterNavTabs } from './components';

export const ECSClusterService = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterName, arn } = useParams<'clusterName' | 'arn'>();

  const { data: services } = useAwsGetter(
    'ECS',
    'describeServices',
    [{ cluster: clusterName as string, services: [arn as string] }],
    { clientOverrides },
  );

  const service = services?.services?.[0];

  return (
    <Layout
      documentTitle="Service Details"
      tabs={<ClusterNavTabs clusterName={clusterName as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Service Details</Typography>
          <Breadcrumbs
            mappings={[
              ['ECS', () => goto(routes.RESOURCES_ECS)],
              ['Clusters', () => goto(routes.RESOURCES_ECS_CLUSTERS)],
              [clusterName, () => goto(routes.RESOURCES_ECS_CLUSTER, { clusterName })],
              ['Services', () => goto(routes.RESOURCES_ECS_CLUSTER_SERVICES, { clusterName })],
              [arn, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_ECS_CLUSTER_SERVICE_UPDATE, { clusterName, arn })}>
          Edit Service
        </Button>
      }
    >
      <Card>
        <CardContent>
          <ECSServiceDetails service={service} />
        </CardContent>
      </Card>
    </Layout>
  );
};
