import { ReactElement } from 'react';
import { KMSKeyMetadata } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface KMSKeyDetailsProps {
  metadata?: Optional<Partial<KMSKeyMetadata>>;
}

export const KMSKeyDetails = ({ metadata }: KMSKeyDetailsProps): ReactElement => (
  <MagicDetails
    data={metadata}
    schema={getSchema('KMS')}
    entry="KeyMetadata"
    title="KMS Key Details"
  />
);
