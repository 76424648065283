import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { CreateBackupVaultOutput, BackupVault } from '@localstack/types';
import { formatDate, getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type BackupVaultsTableProps = {
  selectable?: boolean;
  vaults: CreateBackupVaultOutput[];
  onViewVault?: (vault: BackupVault) => void;
  onSelect?: (ids: string[]) => void;
};

export const BackupVaultsTable = ({
  vaults,
  selectable = true,
  onViewVault,
  onSelect,
}: BackupVaultsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Backup')}
    entry="CreateBackupVaultOutput"
    idAttribute="BackupVaultName"
    rows={vaults}
    selectable={selectable}
    onSelect={onSelect}
    externalFields={{
      BackupVaultName: (row) => (
        <Link onClick={() => onViewVault && onViewVault(row)} underline="hover">
          {row.BackupVaultName}
        </Link>
      ),
      CreationDate: (params) => <>{formatDate(params.CreationDate as Date)}</>,
    }}
  />
);
