import { ReactElement } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import { LoadingFragment, LambdaLayersTable, PageTitle } from '@localstack/ui';
import { useRoutes, useAwsGetter } from '@localstack/services';

import { LambdaProps } from './types';
import { NavTabs } from './components/NavTabs';

export const LambdaLayers = ({
  Layout,
  clientOverrides,
  routes,
}: LambdaProps): ReactElement => {
  const { goto } = useRoutes();

  const { data: layers, isLoading: isLayersLoading, mutate } = useAwsGetter(
    'Lambda',
    'listLayers',
    [],
    { clientOverrides },
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="Lambda Layers"
            onMutate={mutate}
          />
        }
        tabs={<NavTabs routes={routes} />}
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_LAMBDA_LAYER_NEW)}>
              Create
            </Button>
          </>
        }
      >
        <Card>
          <CardContent>
            <LoadingFragment
              loading={isLayersLoading}
              arrayData={layers?.Layers ?? []}
              variant="list"
              height={50}
              size={10}
            >
              <LambdaLayersTable
                loading={isLayersLoading}
                layers={layers?.Layers ?? []}
                onViewLayer={(layer) => goto(routes.RESOURCES_LAMBDA_LAYER_VERSIONS, { layer })}
              />
            </LoadingFragment>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
};
