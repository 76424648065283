import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { StartTranscriptionJobRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, TranscriptionJobForm } from '@localstack/ui';

import { TranscribeProps } from './types';

export const TranscriptionJobCreate = ({
  Layout,
  clientOverrides,
  routes,
}: TranscribeProps): ReactElement => {
  const { goto } = useRoutes();

  const { startTranscriptionJob, isLoading } = useAwsEffect(
    'TranscribeService',
    ['startTranscriptionJob'],
    { revalidate: ['listTranscriptionJobs'], clientOverrides },
  );

  const handleCreate = async (data: StartTranscriptionJobRequest) => {
    if (await startTranscriptionJob(data)) {
      goto(routes.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOBS);
    }
  };

  return (
    <Layout
      documentTitle='Transcribe: Create Transcription Job'
      title={
        <Box>
          <Typography variant="h4">Create Transcription Job</Typography>
          <Breadcrumbs
            mappings={[
              ['Transcibe', () => goto(routes.RESOURCES_TRANSCRIBE_TRANSCRIPTION_JOBS)],
              ['Transcription Job', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateTranscriptionJob"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <TranscriptionJobForm
            onSubmit={handleCreate}
            clientOverrides={clientOverrides}
            formId="CreateTranscriptionJob"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateTranscriptionJob"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
