import { useState, useCallback, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import {
  Dropdown,
  ConfirmableButton,
  EventBridgeTargetsTable,
  PageTitle,
} from '@localstack/ui';

import { EventBridgeProps } from './types';

export const EventBridgeTargets = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { busName, ruleName } = useParams<'busName' | 'ruleName'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: targets, isLoading, mutate } =
    useAwsGetter('EventBridge', 'listTargetsByRule', [{ Rule: ruleName, EventBusName: busName }], { clientOverrides });

  const { removeTargets } = useAwsEffect(
    'EventBridge',
    ['removeTargets'],
    { revalidate: ['listTargetsByRule'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((id) => removeTargets({ Ids: [id], Rule: ruleName as string }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="EventBridge: Targets"
      title={
        <PageTitle
          title="EventBridge Targets"
          onMutate={mutate}
          breadcrumbs={[
            ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
            ['Buses', () => goto(routes.RESOURCES_EVENT_BRIDGE_BUSES)],
            [busName, () => goto(routes.RESOURCES_EVENT_BRIDGE_BUS, { busName })],
            ['Rules', () => goto(routes.RESOURCES_EVENT_BRIDGE_RULES, { busName })],
            [ruleName, () => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGETS, { ruleName, busName })],
            ['Targets', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGET_CREATE, { ruleName, busName })}>
            Create Target
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Target(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Targets will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <EventBridgeTargetsTable
          targets={targets?.Targets ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewTarget={
            ({ Id }) => goto(routes.RESOURCES_EVENT_BRIDGE_RULE_TARGET, { ruleName, targetId: Id, busName })
          }
        />
      </Card>
    </Layout>
  );
};
