import { isUndefined } from 'lodash';

export const refineSqsUrl = (url: Optional<string>, baseUrl: string): string => {
  // regex which finds the text till third slash(/)
  if (isUndefined(url)) {
    return url as unknown as string;
  }
  const regex = new RegExp(/(?:[^/]*\/){3}/g);
  const results = regex.exec(url as string) || [];
  const domain = results[0]?.slice(0, -1);

  // replaces the found text with user endpoint
  return (url ?? '').replace(domain || '', baseUrl);
};
