import { useState, useCallback, ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, Button } from '@mui/material';
import { Dropdown, ConfirmableButton, AppSyncApiKeysTable,PageTitle } from '@localstack/ui';

import { DEFAULT_APPSYNC_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AppSyncProps } from './types';

export const AppSyncApiKeys = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPSYNC_ROUTES,
}: AppSyncProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { apiId } = useParams<'apiId'>();

  const { data: apiKeys, isLoading, mutate } = useAwsGetter('AppSync', 'listApiKeys', [{ apiId }],{ clientOverrides });

  const { deleteApiKey } = useAwsEffect(
    'AppSync',
    ['deleteApiKey'],
    { revalidate: ['listApiKeys'],clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((id) => deleteApiKey({ apiId: apiId as string, id }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="API Keys"
      tabs={<NavTabs apiId={apiId as string} routes={routes} />}
      title={
        <PageTitle
          title="API Keys"
          onMutate={mutate}
          breadcrumbs={[
            ['AppSync', () => goto(routes.RESOURCES_APPSYNC)],
            [apiId, () => goto(routes.RESOURCES_APPSYNC_API, { apiId })],
            ['API Keys', null],
          ]}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_APPSYNC_API_KEY_CREATE, { apiId })}>
            Create API Key
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} API Key(s)?`}
              onClick={handleDeleteSelected}
              text="Selected API Keys will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AppSyncApiKeysTable
          selectable
          loading={isLoading}
          apiKeys={apiKeys?.apiKeys ?? []}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
