import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { Card, Button } from '@mui/material';

import {
  Dropdown,
  ConfirmableButton,
  PageTitle,
  QLDBLedgersTable,
} from '@localstack/ui';

import { DEFAULT_QLDB_ROUTES } from '@localstack/constants';

import { QLDBProps } from './types';

export const QLDBLedgers = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_QLDB_ROUTES,
}: QLDBProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { goto } = useRoutes();
  const { data: ledgers, isLoading, mutate } = useAwsGetter(
    'QLDB',
    'listLedgers',
    [],
    { clientOverrides },
  );

  const { deleteLedger } = useAwsEffect(
    'QLDB',
    ['deleteLedger'],
    { revalidate: ['listLedgers'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((Name) => deleteLedger({ Name }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="QLDB: Ledgers"
      title={
        <PageTitle
          title="QLDB Ledgers"
          onMutate={mutate}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_QLDB_LEDGER_CREATE)}>
            Create Ledger
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Ledger(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Ledgers will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <QLDBLedgersTable
          ledgers={ledgers?.Ledgers ?? []}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewLedger={({ Name }) => goto(routes.RESOURCES_QLDB_LEDGER, { name: Name })}
        />
      </Card>
    </Layout>
  );
};
