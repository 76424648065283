import { ReactElement } from 'react';
import { CreateResolverEndpointRequest, UpdateResolverEndpointRequest } from '@localstack/types';
import { Breadcrumbs, Route53ResolverEndpointForm, ProgressButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';

import { useParams } from 'react-router-dom';

import { Route53Props } from './types';

export const Route53ResolverEndpointUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const title = `${id ? 'Update' : 'Create'} Endpoint`;

  const { data: endpoint } = useAwsGetter(
    'Route53Resolver',
    'getResolverEndpoint',
    [{ ResolverEndpointId: id }],
    { clientOverrides },
  );
  const { createResolverEndpoint, updateResolverEndpoint, isLoading } = useAwsEffect(
    'Route53Resolver',
    ['createResolverEndpoint', 'updateResolverEndpoint'],
    {
      clientOverrides,
      revalidate: ['listResolverEndpoints', 'getResolverEndpoint'],
    },
  );

  const handleCreate = async (data: CreateResolverEndpointRequest) => {
    if (await createResolverEndpoint(data)) {
      goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINTS);
    }
  };

  const handleUpdate = async (data: UpdateResolverEndpointRequest) => {
    if (await updateResolverEndpoint(data)) {
      goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINT, { id });
    }
  };

  return (
    <Layout
      documentTitle={`Route53Resolver: ${title}`}
      title={
        <Box>
          <Typography variant="h4">Create Endpoint</Typography>
          <Breadcrumbs
            mappings={[
              ['Route53', () => goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINTS)],
              [id, () => goto(routes.RESOURCES_ROUTE53RESOLVER_ENDPOINT, { id })],
              [id ? 'Update Endpoint' : 'Create Endpoint', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          color="primary"
          variant="outlined"
          type="submit"
          form="Route53ResolverEndpointUpsert"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <Route53ResolverEndpointForm
            formId="Route53ResolverEndpointUpsert"
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            endpoint={endpoint?.ResolverEndpoint}
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            color="primary"
            variant="contained"
            type="submit"
            form="Route53ResolverEndpointUpsert"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
