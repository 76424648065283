import { ReactElement } from 'react';

import { CustomerLayout } from '~/layouts';

import { Billing as AccountBilling } from './components';

export const Billing = (): ReactElement => (
  <CustomerLayout
    title="Billing"
  >
    <AccountBilling />
  </CustomerLayout>
);
