import { ReactElement } from 'react';
import { AgwRestApiIntegrationResponse } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';


export interface AgwRestApiIntegrationResponseDetailsProps {
  response?: Optional<Partial<AgwRestApiIntegrationResponse>>;
}

export const AgwRestApiIntegrationResponseDetails = ({ 
  response,
}: AgwRestApiIntegrationResponseDetailsProps): ReactElement => (
  <MagicDetails
    data={response}
    schema={getSchema('APIGateway')}
    entry="IntegrationResponse"
    title="Response Details"
  />
);
