import { ReactElement } from 'react';
import { TextField } from '@mui/material';
import { buildAgwPatches, getSchema } from '@localstack/services';

import { 
  AgwRestApiIntegrationResponse, 
  CreateAgwRestApiIntegrationResponseRequest,
  UpdateAgwRestApiIntegrationResponseRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledTextarea } from '../../../../form';

export interface AgwRestApiIntegrationResponseFormProps {
  restApiId: string;
  resourceId: string;
  httpMethod: string;
  response?: Optional<Partial<AgwRestApiIntegrationResponse>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateAgwRestApiIntegrationResponseRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiIntegrationResponseRequest) => unknown;
}

export const AgwRestApiIntegrationResponseForm = ({
  restApiId,
  resourceId,
  httpMethod,
  response,
  loading,
  formId,
  onCreate,
  onUpdate,
}: AgwRestApiIntegrationResponseFormProps): ReactElement => (
  <MagicForm
    data={response}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="PutIntegrationResponseRequest"
    formId={formId}
    externalFields={{
      '^restApiId$': () => <TextField value={restApiId} disabled fullWidth variant="outlined" />,
      '^resourceId$': () => <TextField value={resourceId} disabled fullWidth variant="outlined" />,
      '^httpMethod$': () => <TextField value={httpMethod} disabled fullWidth variant="outlined" />,
      '^responseTemplates\\..+': (control, name) => (
        <ControlledTextarea
          control={control}
          name={name}
          placeholder="Template"
          minRows={10}
          fullWidth
        />
      ),
    }}
    onSubmit={(data: CreateAgwRestApiIntegrationResponseRequest) => {
      if (!response) return onCreate({ ...data, restApiId, resourceId, httpMethod });

      const patchOperations = buildAgwPatches(response, data);

      return onUpdate({ 
        restApiId, 
        resourceId, 
        httpMethod, 
        statusCode: response.statusCode as string, 
        patchOperations,
      });
    }}
  />
);
