import { ReactElement, useContext, useState } from 'react';
import { Box, Card, Button, CardContent, CardHeader, Grid, Tooltip, Typography, Alert } from '@mui/material';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import {
  AwsClientOverrides, GlobalStateContext, LeadsService, getSelectedInstance, satisfiesVersionConstraint,
  useApiEffect, useAwsEffect, useAwsGetter, useLocalstackStatus, useSnackbar,
} from '@localstack/services';
import {
  FeatureMaturityLevel, LocalStackChaosFaults,
  LocalStackChaosNetworkEffect, LocalStackInstance,
} from '@localstack/types';
import { InstanceSelector } from '@localstack/ui';

import { CustomerLayout } from '~/layouts';

import {
  InternalServerErrorCard, LatencyCard, RegionUnavailableCard,
  ServiceUnavailableCard, DynamodbErrorCard, KinesisErrorCard,
} from './components/experiments';
import { EXPERIMENT_NAMES } from './constants';

const MIN_REQUIRED_VERSION = '3.6.0';

export const FISExperiments = (): ReactElement => {
  const { instances } = useContext(GlobalStateContext);
  const { showSnackbar } = useSnackbar();

  const defaultInstance = getSelectedInstance();
  const [clientOverrides, setClientOverrides] = useState<AwsClientOverrides>(
    defaultInstance ? { endpoint: defaultInstance.endpoint } : {},
  );

  const { running: isLocalStackRunning, version } = useLocalstackStatus(clientOverrides);

  const { data: chaosFaults } = useAwsGetter(
    'LocalStack',
    'getChaosFaults',
    undefined,
    { clientOverrides },
  );

  const { data: chaosNetworkEffects, hasError } = useAwsGetter(
    'LocalStack',
    'getChaosNetworkEffects',
    undefined,
    { silentErrors: true, swrOverrides: { shouldRetryOnError: false }, clientOverrides },
    // when chaos-basic is loaded, getChaosNetworkEffects returns 404 as its not available
    // swrOverrides are set to prevent retries
  );
  const isNotEnterpriseSub = hasError;

  const { createChaosEngineeringLead } = useApiEffect(
    LeadsService,
    ['createChaosEngineeringLead'],
    { suppressErrors: true },
  );

  const { addChaosNetworkEffect } = useAwsEffect(
    'LocalStack',
    ['addChaosNetworkEffect'],
    { revalidate: ['getChaosNetworkEffects'], clientOverrides },
  );

  const { addChaosFaults, deleteChaosFaults } = useAwsEffect(
    'LocalStack',
    ['addChaosFaults', 'deleteChaosFaults'],
    { revalidate: ['getChaosFaults'], clientOverrides },
  );

  const createLead = async (faultName: string) => {
    const res = await createChaosEngineeringLead({ chaos_fault_name: faultName });
    if (res) {
      showSnackbar({
        message: 'We\'ll reach out to you soon',
        severity: 'success',
      });
    } else {
      showSnackbar({
        message: 'Something went wrong. Please try again later',
        severity: 'error',
      });
    }
  };

  const onUpsertFault = async (template: LocalStackChaosFaults, existingExperiment: LocalStackChaosFaults) => {
    await deleteChaosFaults(existingExperiment);
    await addChaosFaults(template);
  };

  const onUpsertNetworkEffect = async (template: LocalStackChaosNetworkEffect) => {
    await addChaosNetworkEffect(template);
  };

  const onStopFault = async (existingExperiment: LocalStackChaosFaults) => {
    await deleteChaosFaults(existingExperiment);
  };

  const onStopNetworkEffect = async (existingExperiment: LocalStackChaosNetworkEffect) => {
    // for now, there's only latency which has to be set to 0 for the experiment to be stopped
    await addChaosNetworkEffect({ ...existingExperiment, latency: 0 });
  };

  const handleInstanceChange = (instance: LocalStackInstance) => {
    setClientOverrides({ endpoint: instance.endpoint });
  };

  const LocalStackNotRunningAlert = !isLocalStackRunning ?
    'LocalStack is not running. Start LocalStack to use this feature.' : undefined;
  const hasVersionMismatch = !satisfiesVersionConstraint(version || '', MIN_REQUIRED_VERSION);
  const VersionMismatchAlert = hasVersionMismatch ?
    `This feature requires version ${MIN_REQUIRED_VERSION} or higher.` : undefined;
  const alertMessage = LocalStackNotRunningAlert || VersionMismatchAlert;

  const experiments = [
    {
      id: EXPERIMENT_NAMES.dynamodbError,
      content: (
        <DynamodbErrorCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosFaults?.filter(fault => fault.description === EXPERIMENT_NAMES.dynamodbError) || []}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.kinesisError,
      content: (
        <KinesisErrorCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosFaults?.filter(fault => fault.description === EXPERIMENT_NAMES.kinesisError) || []}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.internalServerError,
      content: (
        <InternalServerErrorCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosFaults?.filter(fault => fault.description === EXPERIMENT_NAMES.internalServerError) || []}
          disableAndShowCta={isNotEnterpriseSub}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.serviceUnavailableError,
      content: (
        <ServiceUnavailableCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosFaults?.filter(
            fault => fault.description === EXPERIMENT_NAMES.serviceUnavailableError,
          ) || []}
          disableAndShowCta={isNotEnterpriseSub}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.regionUnavailableError,
      content: (
        <RegionUnavailableCard
          onUpsertExperiment={onUpsertFault}
          onStopExperiment={onStopFault}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosFaults?.filter(
            fault => fault.description === EXPERIMENT_NAMES.regionUnavailableError,
          ) || []}
          disableAndShowCta={isNotEnterpriseSub}
        />
      ),
    },
    {
      id: EXPERIMENT_NAMES.latencyError,
      content: (
        <LatencyCard
          onUpsertExperiment={onUpsertNetworkEffect}
          onStopExperiment={onStopNetworkEffect}
          alert={alertMessage}
          createLead={createLead}
          experiment={chaosNetworkEffects || {}}
          disableAndShowCta={isNotEnterpriseSub}
        />
      ),
    },
  ];

  return (
    <CustomerLayout title="Chaos Engineering" planFamily={FeatureMaturityLevel.PREVIEW}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={
                <Typography variant='inherit'>
                  Chaos Engineering
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    Testing your system under turbulent conditions
                    helps ensure its resilience before going into production.<br />
                    Chaos Engineering utilize the Chaos API to simulate faults and network effects.<br />
                    The templates created for these faults and network effects provide a foundation for further
                    exploration of Chaos Engineering within LocalStack.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Alert
                    severity='warning'
                    style={{ width: '100%' }}
                  >
                    Chaos Engineering is currently in preview phase.
                    We are working on finalizing the Chaos API and also on bringing chaos functionality to more users.
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {instances.length > 1 && (
                      <Grid item xs={12} md={4}>
                        <Tooltip
                          title='Select the LocalStack instance you want to target with the experiments' placement="top"
                        >
                          <Box><InstanceSelector callback={handleInstanceChange} /></Box>
                        </Tooltip>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        size='medium'
                        color='primary'
                        variant='contained'
                        startIcon={<OpenInNewIcon />}
                        onClick={() => window.open(
                          'https://docs.localstack.cloud/user-guide/chaos-engineering/',
                          '_blank',
                        )}
                      >
                        Documentation
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                {alertMessage && (
                  <Grid item xs={12}>
                    <Box>
                      <Alert
                        severity='error'
                        style={{ width: '100%' }}
                      >
                        {alertMessage}
                      </Alert>
                    </Box>
                  </Grid>
                )}
              </Grid>
              {experiments.map(experiment => (
                <Box mt={3} key={experiment.id}>
                  {experiment.content}
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CustomerLayout>
  );
};
