import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { KafkaCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type KafkaClustersTableProps = {
selectable?: boolean;
  loading?: boolean;
  clusters: KafkaCluster[];
  onViewCluster?: (stream: KafkaCluster) => unknown;
  onSelect?: (names: string[]) => void;
};

export const KafkaClustersTable = ({
  clusters,
  selectable = true,
  loading,
  onViewCluster,
  onSelect,
}: KafkaClustersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Kafka')}
    entry="ClusterInfo"
    idAttribute="ClusterArn"
    rows={clusters}
    selectable={selectable}
    onSelect={onSelect}
    order={['ClusterArn']}
    loading={loading}
    externalFields={{
      ClusterArn: (row) => (
        <Link onClick={() => onViewCluster && onViewCluster(row)} underline="hover">
          {row.ClusterArn}
        </Link>
      ),
    }}
  />
);
