import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const APPLICATION_AUTOSCALING_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.APPLICATIONAUTOSCALING}`;

export const DEFAULT_APPLICATION_AUTO_SCALING_ROUTES: Record<string,string> = {
  RESOURCES_APPLICATION_AUTO_SCALING: `${APPLICATION_AUTOSCALING_BASE_ROUTE}`,
  RESOURCES_APPLICATION_AUTO_SCALING_NEW: `${APPLICATION_AUTOSCALING_BASE_ROUTE}/new`,
};
