import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { IAMRoutes } from '../../types';

interface IAMNavTabsProps {
  routes: IAMRoutes;
}

export const NavTabs = ({ routes }: IAMNavTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_IAM)} end>
      Users
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_IAM_GROUPS)}>
      Groups
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_IAM_ROLES)}>
      Roles
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_IAM_POLICIES)}>
      Policies
    </NavLink>
  </>
);
