import { ReactElement } from 'react';
import { AgwRestApi, CreateAgwRestApiRequest, UpdateAgwRestApiRequest } from '@localstack/types';
import { AwsClientOverrides, buildAgwPatches, getSchema } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form';

export interface AgwRestApiFormProps {
  api?: Optional<Partial<AgwRestApi>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateAgwRestApiRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiRequest) => unknown;
}

export const AgwRestApiForm = ({
  api,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: AgwRestApiFormProps): ReactElement => (
  <MagicForm
    data={api}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateRestApiRequest"
    formId={formId}
    fieldConditions={{
      '^cloneFrom': !api,
    }}
    externalFields={{
      '^cloneFrom': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName} entityName='API'
          client='APIGateway'
          method='getRestApis'
          arrayKeyName='items'
          creationRoute={DEFAULT_API_GATEWAY_ROUTES.RESOURCES_AGW1_API_CREATE}
          property='name'
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateAgwRestApiRequest) => {
      if (!api) return onCreate(data);

      const patchOperations = buildAgwPatches(api, data);
      return onUpdate({ restApiId: api?.id as string, patchOperations });
    }}
  />
);
