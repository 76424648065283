import { ReactElement } from 'react';
import { CreateECRRepositoryRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../../form/RelatedResourcePicker';

import { MagicForm } from '../../../magic/MagicForm';

export type CreateECRRepositoryFormProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateECRRepositoryRequest) => unknown;
}

export const CreateECRRepositoryForm = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: CreateECRRepositoryFormProps): ReactElement => {

  // eslint-disable-next-line arrow-body-style
  const submitHandler = async (data: CreateECRRepositoryRequest) => {
    return onSubmit(data);
  };

  return (
    <>
      <MagicForm
        schema={getSchema('ECR')}
        formId={formId}
        entry="CreateRepositoryRequest"
        loading={loading}
        onSubmit={(data: CreateECRRepositoryRequest) => submitHandler(data)}
        fieldConditions={{
          '^encryptionConfiguration.kmsKey$': ['$encryptionConfiguration.encryptionType', '===', 'KMS'],
        }}
        externalFields={{
          '^encryptionConfiguration.kmsKey$': (control, fieldName, required) => (
            <RelatedResourcePicker
              control={control}
              client='KMS' method='listKeys' arrayKeyName='Keys' property='KeyArn'
              fieldName={fieldName} entityName='KMS Key'
              creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
        }}
      />
    </>
  );
};
