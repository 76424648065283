import { ReactElement } from 'react';
import { OpenSearchDomainConfig } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface OpenSearchDomainDetailProps {
  domain?: Optional<Partial<OpenSearchDomainConfig>>;
}

export const OpenSearchDomainDetails = ({ domain }: OpenSearchDomainDetailProps): ReactElement => (
  <MagicDetails
    data={domain}
    schema={getSchema('OpenSearch')}
    entry="DomainConfig"
    title="Domain Details"
  />
);
