import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateSGMModelRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, SGMModelCreate } from '@localstack/ui';

import { SageMakerProps } from './types';

export const SageMakerModelCreate = ({
  Layout,
  clientOverrides,
  routes,
}: SageMakerProps): ReactElement => {
  const { goto } = useRoutes();

  const { createModel, isLoading } = useAwsEffect(
    'SGM',
    ['createModel'],
    { revalidate: ['listModels'], clientOverrides },
  );

  const handleCreate = async (data: CreateSGMModelRequest) => {
    await createModel(data);
    goto(routes.RESOURCES_SGM_MODELS, { name: data.ModelName });
  };

  return (
    <Layout
      documentTitle="Model Details"
      title={
        <Box>
          <Typography variant="h4">Model Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SGM', () => goto(routes.RESOURCES_SGM_MODELS)],
              ['Create Model', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateSageMakerModel"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SGMModelCreate
            onSubmit={handleCreate}
            clientOverrides={clientOverrides}
            formId="CreateSageMakerModel"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateSageMakerModel"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
