import { ReactElement } from 'react';
import { IAMPolicyVersion as Version } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { ListItem } from '@mui/material';

import { MagicDetails } from '../../../magic/MagicDetails';
import { CodeSnippetViewer } from '../../../../display/CodeSnippetViewer';

export interface IAMPolicyVersionProps {
  policyVersion?: Optional<Partial<Version>>;
}

export const IAMPolicyVersion = ({ policyVersion }: IAMPolicyVersionProps): ReactElement => (
  <MagicDetails
    data={policyVersion}
    schema={getSchema('IAM')}
    entry="PolicyVersion"
    title="Policy Version Details"
    externalFields={{
      '^Document$': (data: string, fieldName: string) => (
        <ListItem key={fieldName}>
          <CodeSnippetViewer data={data} fieldName={fieldName} />
        </ListItem>
      ),
    }}
  />
);
