import { ReactElement } from 'react';
import { GlueMetadataInfoMap } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueSchemaVersionMetadataTableProps = {
  schemaVersionMetadata: GlueMetadataInfoMap;
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewMetadata?: (key: string, value: string) => void;
};

export const GlueSchemaVersionMetadataTable = ({
  schemaVersionMetadata,
  selectable = true,
  loading,
  onSelect,
  onViewMetadata,
}: GlueSchemaVersionMetadataTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="MetadataInfo"
    idAttribute="MetadataKey"
    rows={Object.entries(schemaVersionMetadata).map(([MetadataKey, value]) => ({
      MetadataKey,
      ...value,
    }))}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    order={['MetadataKey', 'MetadataValue', 'CreatedTime']}
    externalFields={{
      MetadataKey: (row) => (
        <Link
          onClick={() => onViewMetadata && onViewMetadata(row.MetadataKey as string, row.MetadataValue as string)}
          underline="hover"
        >
          {row.MetadataKey}
        </Link>
      ),
    }}
  />
);
