import { ReactElement } from 'react';
import { EventBridgeTarget } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface EventBridgeTargetsTableProps {
  selectable?: boolean;
  loading?: boolean;
  targets: EventBridgeTarget[];
  onViewTarget?: (rule: EventBridgeTarget) => unknown;
  onSelect?: (ids: string[]) => void;
}

export const EventBridgeTargetsTable = ({
  targets,
  selectable = true,
  loading,
  onViewTarget,
  onSelect,
}: EventBridgeTargetsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('EventBridge')}
    entry="Target"
    idAttribute="Id"
    rows={targets}
    selectable={selectable}
    onSelect={onSelect}
    order={['Id']}
    loading={loading}
    externalFields={{
      Id: (row) => (
        <Link onClick={() => onViewTarget && onViewTarget(row)} underline="hover">
          {row.Id}
        </Link>
      ),
    }}
  />
);
