import { ReactElement } from 'react';
import { CreateGlueConnectionRequest, GlueConnection, UpdateGlueConnectionRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';


export type GlueConnectionFormProps = {
  connection?: Optional<Partial<GlueConnection>>;
  loading?: boolean;
  formId: string;
  onCreate: (data: CreateGlueConnectionRequest) => unknown;
  onUpdate: (data: UpdateGlueConnectionRequest) => unknown;
}

export const GlueConnectionForm = ({
  connection,
  loading,
  formId,
  onCreate,
  onUpdate,
}: GlueConnectionFormProps): ReactElement => (
  <MagicForm
    data={{ ConnectionInput: connection }}
    schema={getSchema('Glue')}
    formId={formId}
    entry={connection ? 'UpdateConnectionRequest' : 'CreateConnectionRequest'}
    loading={loading}
    fieldConditions={{
      'Name': !connection,
    }}
    onSubmit={(data: CreateGlueConnectionRequest) => {
      if (!connection) return onCreate(data);
      return onUpdate({ ...data, Name: connection.Name as string });
    }}
  />
);
