import { useEffect, ReactElement } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link } from '@mui/material';

import { BaseLayout } from '~/layouts';
import { AppRoute } from '~/config';

export const SessionExpired = (): ReactElement => {
  useEffect(() => {
    document.title = 'Session expired - LocalStack';
  }, []);

  return (
    <BaseLayout hideNavigation>
      <Box flexGrow={1}>
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Typography variant="h3">
            Your session has expired
          </Typography>

          <Box mt={2}>
            <Typography variant="h2" component="span">
              <Link to={AppRoute.SIGN_IN} component={RouterLink} underline="hover">
                Sign in again
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </BaseLayout>
  );
};
