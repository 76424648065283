import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { NeptuneCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface NeptuneClustersTableProps {
  clusters: NeptuneCluster[];
  loading?: boolean;
  selectable?: boolean;
  onViewCluster?: (cluster: NeptuneCluster) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const NeptuneClustersTable = ({
  clusters,
  loading,
  selectable,
  onViewCluster,
  onSelect,
}: NeptuneClustersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Neptune')}
    entry="DBCluster"
    idAttribute="DBClusterIdentifier"
    rows={clusters}
    selectable={selectable}
    onSelect={onSelect}
    order={['DBClusterIdentifier']}
    loading={loading}
    externalFields={{
      DBClusterIdentifier: (row) => (
        <Link onClick={() => onViewCluster && onViewCluster(row)}>
          {row.DBClusterIdentifier}
        </Link>
      ),
    }}
  />
);
