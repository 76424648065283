import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { BackendEnvironment } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type AmplifyBEEnvironmentsTableProps = {
  environments: BackendEnvironment[];
  onViewEnvironment: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const AmplifyBEEnvironmentsTable = ({
  environments,
  onViewEnvironment,
  onSelect,
  loading,
}: AmplifyBEEnvironmentsTableProps): ReactElement => (
  <MagicTable
    entry="BackendEnvironment"
    idAttribute="environmentName"
    rows={environments}
    schema={getSchema('Amplify')}
    externalFields={{
      environmentName: ({ environmentName }) => (
        <Link
          onClick={() => onViewEnvironment(environmentName || '')}
          underline="hover"
        >
          {environmentName}
        </Link>
      ),
      
    }}
    loading={loading}
    order={['environmentName']}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
