import { useEffect, ReactElement } from 'react';

import { Link as RouterLink } from 'react-router-dom';
import { Box, Grid, Typography, Link } from '@mui/material';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { MARKER_IDS, TestMarker } from '@localstack/services';

import { BaseLayout } from '~/layouts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(4),
    },
    content: {
      marginTop: '150px',
      textAlign: 'center',
    },
    subtitle: {
      marginTop: theme.spacing(2),
    },
  }),
);

export const NotFound = (): ReactElement => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Page not found - LocalStack';
  }, []);

  return (
    <BaseLayout hideNavigation>
      <Box flexGrow={1}>
        <TestMarker name={MARKER_IDS.PAGE_NOT_FOUND} />

        <Grid container justifyContent="center" spacing={4}>
          <Grid item lg={6} xs={12}>
            <div className={classes.content}>
              <Typography variant="h1">404: Page not found</Typography>
              <Typography className={classes.subtitle} variant="subtitle2">
                The page you were looking for could not be found.
              </Typography>

              <Box component="p" mt={4}>
                <Link to="/" component={RouterLink} underline="hover">
                  &larr; Return to LocalStack
                </Link>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </BaseLayout>
  );
};
