import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { DEFAULT_ACCOUNT_ROUTES } from '@localstack/constants';

import {
  Button,
  Card,
} from '@mui/material';

import {
  ContactInformationTable,
  PageTitle,
} from '@localstack/ui';

import { AccountProps } from './types';
import { NavTabs } from './components/NavTabs';

export const ContactInformation = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ACCOUNT_ROUTES,
}: AccountProps): ReactElement => {
  const { goto } = useRoutes();

  const { data: info, isLoading, mutate } = useAwsGetter(
    'Account',
    'getContactInformation',
    [{}],
    { clientOverrides },
  );

  const contactInformation = info?.ContactInformation;
  const isEmpty = !contactInformation || Object.keys(contactInformation).length === 0;

  const onEditContactInformation = () => {
    goto(routes.RESOURCES_ACCOUNT_CONTACTINFO_FORM);
  };

  return (
    <Layout
      documentTitle="Account: Contact Information"
      tabs={<NavTabs routes={routes} />}
      title={<PageTitle title="Account" onMutate={mutate} />}
      actions={
        <Button onClick={onEditContactInformation} disabled={!isLoading && !isEmpty}>
          Create
        </Button>
      }
    >
      <Card>
        <ContactInformationTable
          onEditContactInformation={onEditContactInformation}
          loading={isLoading}
          item={isEmpty ? undefined : contactInformation}
        />
      </Card>
    </Layout>
  );
};
