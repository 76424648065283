import axios, { ResponseType } from 'axios';
import pako from 'pako';
import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Card, CardContent, Grid, Link, Paper, Tab, Tabs } from '@mui/material';
import { CiService, formatDateTime, useApiGetter, useRoutes } from '@localstack/services';
import { PlanFamily } from '@localstack/types';
import { LoadingFragment, LogOutput, PageTitle } from '@localstack/ui';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config/routes';

import { RunTraces } from './RunTraces';

const TabPanel = (
  { children, selected, value }: {children: ReactNode, selected: string, value: string},
) => <>{selected === value ? children : ''}</>;


export const CIProjectRunDetails = (): ReactElement => {
  const { goto } = useRoutes();

  const { project: projectName, run: runName } = useParams<'project' | 'run'>();

  const [currentTab, setCurrentTab] = useState('logs');

  const [runLogs, setRunLogs] = useState('');
  const [isLogsLoading, setLogsLoading] = useState(true);

  const { data: project, isLoading: isRunDetailsLoading } = useApiGetter(
    CiService, 'getCiProject', [projectName]);
  const runDetails = project?.runs?.find(run => run.run_name === runName);

  const { data: runLogUrl, isLoading: isRunLogsLoading } = useApiGetter(
    CiService, 'getCiRunLogs', [projectName, runName, undefined]);

  useEffect(() => {
    if (!runLogUrl || !runLogUrl.url) return;

    const fetchLogs = async () => {
      const uninterceptedAxiosClient = axios.create();
      const axiosOptions = {
        responseType: 'arraybuffer' as ResponseType,
      };
      const logs = await uninterceptedAxiosClient.get(runLogUrl.url ?? '', axiosOptions);
      const logsUncompressed = pako.inflate(logs.data, { to: 'string' });
      setRunLogs(logsUncompressed);
      setLogsLoading(false);
    };
    fetchLogs();
  }, [runLogUrl]);

  const allLines = runLogs.split('\n');
  const logLines = allLines.filter(line => !line.match(/^ci:/)).slice(0, 1000);
  const logEvents = logLines.map(line => ({ event: 'log', message: line }));
  const podParts = runDetails?.pod?.split(':');
  const ciRunPod = podParts ? podParts[0] : null;
  const ciRunPodVersion = podParts && podParts.length > 1 ? podParts.slice(-1) : '';

  const traceLines = allLines.filter(line => line.match(/^ci:/));

  const isLoading = isRunLogsLoading || isLogsLoading;

  return (
    <CustomerLayout
      title={
        <PageTitle
          title="CI Run Details"
          breadcrumbs={[
            ['CI Projects', () => goto(AppRoute.CI_PROJECTS)],
            [projectName, () => goto(AppRoute.CI_PROJECT, { project: projectName })],
            ['Runs', () => goto(AppRoute.CI_PROJECT, { project: projectName })],
            [runName, () => null],
          ]}
          planFamily={PlanFamily.ENTERPRISE_PLANS}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <LoadingFragment loading={isRunDetailsLoading} variant="card" height={100}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Time started:
                  </Grid>
                  <Grid item xs={7}>
                    {runDetails?.start_time ? formatDateTime(runDetails.start_time) : 'n/a'}
                  </Grid>
                  <Grid item xs={5}>
                    Time finished:
                  </Grid>
                  <Grid item xs={7}>
                    {runDetails?.end_time ? formatDateTime(runDetails.end_time) : 'n/a'}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </LoadingFragment>
        </Grid>
        <Grid item md={6} xs={12}>
          <LoadingFragment loading={isRunDetailsLoading} variant="card" height={100}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    Cloud Pod:
                  </Grid>
                  <Grid item xs={7}>
                    <Link
                      onClick={() => goto(AppRoute.POD, { name: ciRunPod }, `version=${ciRunPodVersion}`)}
                      underline="hover"
                    >
                      {runDetails?.pod}
                    </Link>
                  </Grid>
                  <Grid item xs={5}>
                    Stack Insights Session:
                  </Grid>
                  <Grid item xs={7}>
                    <Link
                      onClick={() => goto(AppRoute.STACKS_STACK, { stackId: runDetails?.stack_id })}
                      underline="hover"
                    >
                      {runDetails?.stack_id}
                    </Link>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </LoadingFragment>
        </Grid>
        <Grid item xs={12}>
          <Paper variant='outlined'>
            <Tabs
              value={currentTab}
              indicatorColor="primary"
              textColor="primary"
              style={{ marginBottom: 10 }}
              onChange={(_: unknown, newValue: string) => {
                setCurrentTab(newValue);
              }}
            >
              <Tab label="Log Output" value="logs" />
              <Tab label="Request/Response Traces" value="traces" />
            </Tabs>

            <TabPanel selected={currentTab} value='logs'>
              <LoadingFragment loading={isLoading} variant="card" height={300}>
                <LogOutput logEvents={logEvents} hideMoreButton />
              </LoadingFragment>
            </TabPanel>
            <TabPanel selected={currentTab} value='traces'>
              <LoadingFragment loading={isLoading} variant="card" height={300}>
                <RunTraces traceLogs={traceLines}/>
              </LoadingFragment>
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </CustomerLayout>
  );
};
