import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Grid,
  Card,
  CardActions,
  FormHelperText,
  Button,
  CardHeader,
  CardContent,
  Skeleton,
} from '@mui/material';

import {
  MARKER_IDS,
  OrganizationsService,
  SubscriptionService,
  TestMarkerSpan,
  UserService,
  formatErrorCode,
  pushGTMEvent,
  useApiEffect,
  useRoutes,
} from '@localstack/services';

import {
  NavLink,
  AnimatedLogo,
  ProgressButton,
  SSOIcon,
} from '@localstack/ui';
import { PlanFamily, PlannedUsageType, TosAcceptanceContext, User } from '@localstack/types';
import { GTMSignupTriggers, PUBLIC_IDENTITY_PROVIDERS } from '@localstack/constants';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import { BaseLayout } from '~/layouts';
import { AppRoute } from '~/config';
import { useRecaptchaV3 } from '~/hooks/useRecaptchaV3';
import { useAuthProvider } from '~/hooks';

import { LoginDetails } from './LoginDetails';
import { LSUsageDetails } from './LSUsageDetails';
import { PersonalDetails } from './PersonalDetails';
import { useStyles } from './styles';
import { SIGNUP_FLOW_TYPE } from './common-types';

const providers = Array.from(
  new Set([
    ...PUBLIC_IDENTITY_PROVIDERS.map((p) => `public/${p}`),
  ]),
);

interface FormFields {
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  company: string;
  job_title: string;
  github_username: string;
  tos: boolean;
  newsletter: boolean;
}

interface FormFlowControlData {
  createSub: boolean;
  plannedUsageType: PlannedUsageType;
  hobbyTermsAgree: boolean;
}

type FormData = FormFields & FormFlowControlData;

enum STEPS_ENUM {
  LOADING = 0,
  LOGIN_DETAILS_STEP = 1,
  LS_USAGE_DETAILS_STEP = 2,
  PERSONAL_DETAILS_STEP = 3,
}

const ERROR_STEPS_MAP: Record<string, STEPS_ENUM> = {
  'auth.create_identity': STEPS_ENUM.PERSONAL_DETAILS_STEP,
  'auth.identity_exists': STEPS_ENUM.LOGIN_DETAILS_STEP,
};

const TRIGGER_MAP: Record<STEPS_ENUM, (keyof FormData)[]> = {
  [STEPS_ENUM.LOADING]: [],
  [STEPS_ENUM.LOGIN_DETAILS_STEP]: ['email', 'password'],
  [STEPS_ENUM.LS_USAGE_DETAILS_STEP]: ['plannedUsageType'],
  [STEPS_ENUM.PERSONAL_DETAILS_STEP]: ['firstname', 'lastname', 'tos', 'createSub'],
};

const GA4_STEPS_EVENTS: Record<STEPS_ENUM, GTMSignupTriggers | undefined> = {
  [STEPS_ENUM.LOADING]: undefined,
  [STEPS_ENUM.LOGIN_DETAILS_STEP]: GTMSignupTriggers.SIGNUP_STEP1_VIEWED,
  [STEPS_ENUM.LS_USAGE_DETAILS_STEP]: GTMSignupTriggers.SIGNUP_STEP2_VIEWED,
  [STEPS_ENUM.PERSONAL_DETAILS_STEP]: GTMSignupTriggers.SIGNUP_STEP3_VIEWED,
};

const initialStepsMap: Record<SIGNUP_FLOW_TYPE, STEPS_ENUM> = {
  [SIGNUP_FLOW_TYPE.EMAIL]: STEPS_ENUM.LOGIN_DETAILS_STEP,
  [SIGNUP_FLOW_TYPE.PUBLIC_SSO]: STEPS_ENUM.LS_USAGE_DETAILS_STEP,
  [SIGNUP_FLOW_TYPE.ENTERPRISE_SSO]: STEPS_ENUM.PERSONAL_DETAILS_STEP,
};

export const SignUp = (): ReactElement => {
  const { userInfo } = useAuthProvider();
  const isEnterpriseSSOSignup = !!Object.keys(userInfo?.org.settings?.sso_settings || {}).length;
  // if sso_settings is falsy (null or undefined or {}), we know its not a enterprise signup
  const { search } = useLocation();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const isFinishAccountCreation = queryParams.get('isFinishAccountCreation');
  const formRef = useRef<HTMLFormElement | null>(null);

  const ssoFlow = isEnterpriseSSOSignup ? SIGNUP_FLOW_TYPE.ENTERPRISE_SSO : SIGNUP_FLOW_TYPE.PUBLIC_SSO;
  const flowType = isFinishAccountCreation ? ssoFlow : SIGNUP_FLOW_TYPE.EMAIL;
  const isSSOFlow = [SIGNUP_FLOW_TYPE.PUBLIC_SSO, SIGNUP_FLOW_TYPE.ENTERPRISE_SSO].includes(flowType);

  const classes = useStyles();
  const { goto } = useRoutes();
  const { initToken } = useRecaptchaV3();

  const partialUser: Partial<FormData> = useMemo(() => ({
    firstname: userInfo?.user.firstname,
    lastname: userInfo?.user.lastname,
    email: userInfo?.user.email,
    company: userInfo?.org.name,
  }), [userInfo]);

  const methods = useForm<FormData>({
    mode: 'all',
    defaultValues: {
      hobbyTermsAgree: false,
      tos: false,
      newsletter: false,
      plannedUsageType: flowType === SIGNUP_FLOW_TYPE.ENTERPRISE_SSO ? PlannedUsageType.PROFESSIONAL : undefined,
    },
  });

  const {
    handleSubmit,
    formState,
    setValue,
    trigger,
    watch,
  } = methods;
  const emailFormVal = watch('email');
  const { signupUser, error, isLoading: isSigningUpWithEmail } = useApiEffect(
    UserService,
    ['signupUser'],
  );

  const { checkEmailDomain, isLoading: isCheckingEmailDomain } = useApiEffect(
    UserService,
    ['checkEmailDomain'],
  );

  const { updateAccount, acceptTermsOfService, isLoading: isSigningUpWithSSO } = useApiEffect(
    UserService,
    ['updateAccount', 'acceptTermsOfService'],
    { revalidate: ['getUser'] },
  );
  const { createSubscription, isLoading: isCreatingSubscription } = useApiEffect(
    SubscriptionService,
    ['createSubscription'],
    {
      revalidate: ['listSubscriptions', 'getSubscription', 'listKeys', 'listInvoices', 'listPlans'],
      revalidateOtherClient: {
        client: OrganizationsService, methods: ['listLicenseAssignments', 'getUserLicenseAssignment'],
      },
    },
  );

  const [step, setStep] = useState(STEPS_ENUM.LOADING);
  const [isPublicDomainEmail, setIsPublicDomainEmail] = useState(true);

  useEffect(() => {
    if (!isSSOFlow) return;

    const isUserLoaded = !!partialUser?.email;
    if (isUserLoaded) {
      methods.reset(partialUser);
    }
  }, [partialUser]);

  useEffect(() => {
    // Set initial step
    // For SSO flows, where email will be set by default, set initial step to an actual step instead of "Loading"
    // For email flow, directly set the login details step

    (async () => {
      if (step !== STEPS_ENUM.LOADING) return; // Initial step already set, no need to proceed
      if (isSSOFlow && !emailFormVal) return; //  Prevent setting initial step for sso signups until auth user's email is available

      if (isSSOFlow) {
        const isPublicDomain = await checkIfPublicDomain(emailFormVal);
        setIsPublicDomainEmail(isPublicDomain);
        if (!isPublicDomain) {
          pushGTMEvent(GTMSignupTriggers.SIGNUP_STEP2_SKIPPED);
          initialStepsMap[SIGNUP_FLOW_TYPE.PUBLIC_SSO] = STEPS_ENUM.PERSONAL_DETAILS_STEP;
          setValue('plannedUsageType', PlannedUsageType.PROFESSIONAL);
        }
      }
      setStep(initialStepsMap[flowType]);
    })();
  }, [emailFormVal]);


  useEffect(() => {
    const fieldsToTrigger = TRIGGER_MAP[step];
    if (fieldsToTrigger) {
      trigger(fieldsToTrigger);
    }
  }, [step]);

  useEffect(() => {
    const event = GA4_STEPS_EVENTS[step];
    if (!event) return; // loading step
    pushGTMEvent(event);
  }, [step]);

  const checkIfPublicDomain = useCallback(
    async (email: string) => {
      const emailDomainInfo = await checkEmailDomain({ email });
      return !!emailDomainInfo.is_public;
    },
    [],
  );

  const handleNext = async () => {
    if (step === STEPS_ENUM.LOGIN_DETAILS_STEP) {
      const isPublicDomain = await checkIfPublicDomain(emailFormVal);
      setIsPublicDomainEmail(isPublicDomain);
      if (!isPublicDomain) {
        pushGTMEvent(GTMSignupTriggers.SIGNUP_STEP2_SKIPPED);
        setValue('plannedUsageType', PlannedUsageType.PROFESSIONAL);
        return setStep(STEPS_ENUM.PERSONAL_DETAILS_STEP);
      }
    }
    setStep(step + 1);
  };
  const handlePrev = () => {
    if (step === STEPS_ENUM.PERSONAL_DETAILS_STEP) {
      setValue('createSub', false);
      setValue('hobbyTermsAgree', false);
      if (!isPublicDomainEmail) {
        return setStep(STEPS_ENUM.LOGIN_DETAILS_STEP);
      }
    }
    setStep(step - 1);
  };

  const onSSOUserSubmit = async (data: FormData) => {
    const signupSurveyData = {
      create_sub: data.createSub,
      planned_usage_type: data.plannedUsageType,
      require_finish_account_creation: false,
    };
    const { firstname, lastname, company, job_title, github_username, newsletter } = data;
    const userData: Partial<User> = {
      firstname,
      lastname,
      company,
      job_title,
      github_username,
      newsletter,
      signup_survey: signupSurveyData,
    };
    await updateAccount(userData as User);
    await acceptTermsOfService({ acceptance_context: TosAcceptanceContext.SIGN_UP });
    if (signupSurveyData.create_sub) {
      await createSubscription({
        'plan': `plan.${signupSurveyData.planned_usage_type === PlannedUsageType.HOBBYIST
          ? PlanFamily.HOBBY_PLANS : PlanFamily.TRIAL_PLANS}`,
      });
    }
    goto(AppRoute.POST_SIGN_UP, undefined, 'source=sso');
  };

  const onEmailUserSubmit = async (data: FormData) => {
    const recaptchaToken = await initToken();
    const { createSub, plannedUsageType } = data;
    const signupSurveyData = {
      create_sub: createSub,
      planned_usage_type: plannedUsageType,
    };
    const { firstname, lastname, email, password, company, job_title, github_username, newsletter } = data;
    const user = await signupUser({
      firstname,
      lastname,
      email,
      password,
      company,
      job_title,
      github_username,
      newsletter,
      recaptcha_token: recaptchaToken,
      tos_acceptance_context: TosAcceptanceContext.SIGN_UP,
      signup_survey: signupSurveyData,
    });
    if (user) {
      goto(AppRoute.POST_SIGN_UP);
    }
  };

  const errorCode = error && formatErrorCode(error);

  useEffect(() => {
    if (!error) return;
    const redirectToStep = ERROR_STEPS_MAP[errorCode || ''];
    if (redirectToStep) setStep(redirectToStep);
  }, [error]);

  const isErrorForCurrentStep = step === ERROR_STEPS_MAP[errorCode || ''];

  const finalStepCtaText = isFinishAccountCreation ? 'Finish Sign up' : 'Sign up';
  const isSigningUp = isFinishAccountCreation ? isSigningUpWithSSO || isCreatingSubscription : isSigningUpWithEmail;

  return (
    <BaseLayout documentTitle="Sign Up" hideNavigation>
      <Box flexGrow={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center" mt={3} px={2}>
        <Box textAlign="center">
          <AnimatedLogo animation={false} size='small' />
        </Box>
        <Card className={classes.card}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(isFinishAccountCreation ? onSSOUserSubmit : onEmailUserSubmit)}
              ref={formRef} id="signup"
            >
              {step === STEPS_ENUM.LOADING && <>
                <Skeleton variant="rectangular" height={500} width="100%" />
              </>}
              {step === STEPS_ENUM.LOGIN_DETAILS_STEP &&
                <LoginDetails error={isErrorForCurrentStep ? error : undefined} />}
              {step === STEPS_ENUM.LS_USAGE_DETAILS_STEP && <LSUsageDetails />}
              {step === STEPS_ENUM.PERSONAL_DETAILS_STEP &&
                <PersonalDetails error={isErrorForCurrentStep ? error : undefined} flowType={flowType} />}
              {step !== STEPS_ENUM.LOADING &&
                <CardActions>
                  {step !== initialStepsMap[flowType]
                    &&
                    <Button
                      color="primary"
                      type="button"
                      size="large"
                      variant="outlined"
                      form="signup"
                      onClick={handlePrev}
                    >
                      Previous
                    </Button>}
                  <TestMarkerSpan name={MARKER_IDS.SIGNUP_NEXT}>
                    <ProgressButton
                      disabled={!formState.isValid || isCheckingEmailDomain}
                      color="primary"
                      type='button'
                      size="large"
                      variant="contained"
                      form="signup"
                      onClick={step !== STEPS_ENUM.PERSONAL_DETAILS_STEP ?
                        handleNext : () => formRef.current?.requestSubmit()}
                      loading={isSigningUp || isCheckingEmailDomain}
                    >
                      {step === STEPS_ENUM.PERSONAL_DETAILS_STEP ? finalStepCtaText : 'Next'}
                    </ProgressButton>
                  </TestMarkerSpan>
                </CardActions>
              }
            </form>
          </FormProvider>
        </Card>
        {step === STEPS_ENUM.LOGIN_DETAILS_STEP &&
          <Card className={classNames(classes.card, classes.cardWithMt)}>
            <CardHeader title="Sign up with SSO" />
            <CardContent>
              <Grid container columnSpacing={1}>
                {providers.map((orgIdWithProviderName) => (
                  <Grid key={orgIdWithProviderName} item>
                    <Button
                      size="small"
                      variant="outlined"
                      data-sso-button={orgIdWithProviderName}
                      startIcon={<SSOIcon size="small" provider={orgIdWithProviderName.split('/')[1] as string} />}
                      onClick={
                        () => goto(
                          AppRoute.SSO_START,
                          {
                            orgId: orgIdWithProviderName.split('/')[0],
                            idpName: orgIdWithProviderName.split('/')[1],
                          },
                        )
                      }
                    >
                      {orgIdWithProviderName.split('/')[1]}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        }
        {!isFinishAccountCreation && <Box mb={2}>
          <FormHelperText>
            Already have an account? Sign In{' '}
            <NavLink to={AppRoute.SIGN_IN}>here</NavLink> instead
          </FormHelperText>
        </Box>}
      </Box>
    </BaseLayout >
  );
};
