import { ReactElement } from 'react';
import { Card, Button, Box , Alert } from '@mui/material';
import { AthenaDatabasesTable } from '@localstack/ui';
import { useRoutes } from '@localstack/services';

import { DEFAULT_ATHENA_ROUTES } from '@localstack/constants';

import { NavTabs } from './components';
import { AthenaProps } from './types';
import { AthenaDatabasesLayoutLess } from './components/Databases';

export const AthenaDatabases = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ATHENA_ROUTES,
}: AthenaProps): ReactElement => {
  const { goto } = useRoutes();

  const { executionState, databases, isLoading, reloadDatabases } = AthenaDatabasesLayoutLess({
    clientOverrides,
    onViewDatabase:
      ({ Name: databaseName }) => goto(routes.RESOURCES_ATHENA_DATABASE, { databaseName }),
  });

  return (
    <>
      <Layout
        title="Athena Databases"
        tabs={<NavTabs routes={routes} />}
      >
        <>
          {executionState === 'FAILED' && (
            <Box mb={2}>
              <Alert
                severity="error"
                action={
                  <Button onClick={reloadDatabases}>
                    Retry
                  </Button>
                }
              >
                Failed to load databases
              </Alert>
            </Box>
          )}
          <Card>
            <AthenaDatabasesTable
              databases={databases ?? []}
              loading={isLoading}
              onViewDatabase={({ Name: databaseName }) => goto(routes.RESOURCES_ATHENA_DATABASE, { databaseName })}
            />
          </Card>
        </>
      </Layout>
    </>
  );
};
