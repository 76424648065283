import { ReactElement, useState } from 'react';

import { UserService, useApiEffect } from '@localstack/services';
import { Notification } from '@localstack/types';

import { NotificationModal } from '~/views/customer/Notifications/NotificationModal';

type Props = {
  announcements: Notification[],
  revalidate: () => void,
}

export const AnnouncementModal = ({ announcements, revalidate }: Props): ReactElement => {

  const [seenAnnouncements, setSeenAnnouncements] = useState<string[]>([]);
  const { updateNotification } = useApiEffect(UserService, ['updateNotification']);

  const currentAnnouncement = announcements.filter(ann => !seenAnnouncements.includes(ann.id))
    .sort((a, b) => (b.created ?? 0) - (a.created ?? 0)).at(0); // oldest first

  const handleClose = async () => {
    const wasLastNotification = announcements.length - seenAnnouncements.length <= 1;
    setSeenAnnouncements(current => [...current, currentAnnouncement?.id as string]);
    await updateNotification(currentAnnouncement?.id, { ...currentAnnouncement, read: true } as Notification);
    if (wasLastNotification) {
      revalidate();
    }
  };

  return (
    <NotificationModal
      notification={currentAnnouncement}
      onClose={handleClose}
    />
  );
};

