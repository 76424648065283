import { ReactElement } from 'react';
import { noop } from 'lodash';
import { Typography, IconButton, Box } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { MARKER_IDS, TestMarkerSpan } from '@localstack/services';
import { Variant } from '@mui/material/styles/createTypography';

import { Breadcrumbs } from '../../navigation/Breadcrumbs';

import { ActionTitle } from '../ActionTitle';
import { AllowedPlanChipPlans } from '../../feedback/PlanChip';

type RefreshingTitleProps = {
  title: string;
  breadcrumbs?: [Optional<string>, Optional<() => unknown>][];
  onMutate?: () => unknown;
  planFamily?: AllowedPlanChipPlans;
  typographyVariant?: Variant; 
}

export const PageTitle = ({
  title,
  breadcrumbs,
  onMutate,
  planFamily,
  typographyVariant = 'h4',
}: RefreshingTitleProps): ReactElement => {
  const mutateCallback = onMutate ?? noop;

  // TODO: add favourites icon

  return (
    <Box>
      <ActionTitle
        variant="dense"
        title={
          <Typography variant={typographyVariant}>
            {title}
          </Typography>
        }
        actions={onMutate && (
          <TestMarkerSpan name={MARKER_IDS.MUTATE_BUTTON}>
            <IconButton onClick={() => mutateCallback()} size="large"><RefreshIcon /></IconButton>
          </TestMarkerSpan>)}
        planFamily={planFamily}
      />
      {breadcrumbs && breadcrumbs.length > 0 && (
        <Breadcrumbs mappings={breadcrumbs} />
      )}
    </Box>
  );
};
