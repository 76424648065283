import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes } from '@localstack/services';
import { CreateSGMEndpointConfigRequest } from '@localstack/types';
import { Breadcrumbs, ProgressButton, SGMEndpointConfigCreate } from '@localstack/ui';

import { SageMakerProps } from './types';

export const SageMakerEndpointConfigCreate = ({
  Layout,
  clientOverrides,
  routes,
}: SageMakerProps): ReactElement => {
  const { goto } = useRoutes();

  const { createEndpointConfig, isLoading } = useAwsEffect(
    'SGM',
    ['createEndpointConfig'],
    { revalidate: ['listEndpointConfigs'], clientOverrides },
  );

  const handleCreate = async (data: CreateSGMEndpointConfigRequest) => {
    await createEndpointConfig(data);
    goto(routes.RESOURCES_SGM_ENDPOINT_CONFIGS, { name: data.EndpointConfigName });
  };

  return (
    <Layout
      documentTitle="Endpoint Config Details"
      title={
        <Box>
          <Typography variant="h4">Endpoint Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SGM', () => goto(routes.RESOURCES_SGM_ENDPOINT_CONFIGS)],
              ['Create Endpoint Config', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateSageMakerEndpointConfig"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <SGMEndpointConfigCreate
            onSubmit={handleCreate}
            clientOverrides={clientOverrides}
            formId="CreateSageMakerEndpointConfig"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateSageMakerEndpointConfig"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
