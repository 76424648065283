import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const DMS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.DMS}`;

export const DEFAULT_DMS_ROUTES: Record<string, string> = {
  RESOURCES_DMS_REPLICATION_INSTANCES: `${DMS_BASE_ROUTE}/replication-instances`,
  RESOURCES_DMS_REPLICATION_INSTANCE: `${DMS_BASE_ROUTE}/replication-instance/:id`,
  RESOURCES_DMS_REPLICATION_INSTANCE_CREATE: `${DMS_BASE_ROUTE}/replication-instance/new`,
  RESOURCES_DMS_ENDPOINTS: `${DMS_BASE_ROUTE}/endpoints`,
  RESOURCES_DMS_ENDPOINT: `${DMS_BASE_ROUTE}/endpoint/:id`,
  RESOURCES_DMS_ENDPOINT_CREATE: `${DMS_BASE_ROUTE}/endpoint/new`,
  RESOURCES_DMS_ENDPOINT_CONNECTIONS: `${DMS_BASE_ROUTE}/endpoint/:id/connections`,
  RESOURCES_DMS_ENDPOINT_CONNECTIONS_TEST: `${DMS_BASE_ROUTE}/endpoint/:id/connections/test`,
  RESOURCES_DMS_REPLICATION_TASKS: `${DMS_BASE_ROUTE}/replication-tasks`,
  RESOURCES_DMS_REPLICATION_TASK: `${DMS_BASE_ROUTE}/replication-task/:id`,
  RESOURCES_DMS_REPLICATION_TASK_CREATE: `${DMS_BASE_ROUTE}/replication-task/new`,
  RESOURCES_DMS_REPLICATION_TASK_TABLE_STATS: `${DMS_BASE_ROUTE}/replication-task/:id/table-stats`,
};
