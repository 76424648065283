export const PLACEHOLDER_DATA = {
  'AWSTemplateFormatVersion': '2010-09-09',
  'Description': 'This example creates a bucket as a website. The AccessControl property is set to the canned ACL PublicRead (public read permissions are required for buckets set up for website hosting). Because this bucket resource has a DeletionPolicy attribute set to Retain, AWS CloudFormation will not delete this bucket when it deletes the stack. The Output section uses Fn::GetAtt to retrieve the WebsiteURL attribute and DomainName attribute of the S3Bucket resource.',//eslint-disable-line
  'Resources': {
    'S3Bucket': {
      'Type': 'AWS::S3::Bucket',
      'Properties': {
        'AccessControl': 'PublicRead',
        'WebsiteConfiguration': {
          'IndexDocument': 'index.html',
          'ErrorDocument': 'error.html',
        },
      },
      'DeletionPolicy': 'Retain',
    },
    'BucketPolicy': {
      'Type': 'AWS::S3::BucketPolicy',
      'Properties': {
        'PolicyDocument': {
          'Id': 'MyPolicy',
          'Version': '2012-10-17',
          'Statement': [
            {
              'Sid': 'PublicReadForGetBucketObjects',
              'Effect': 'Allow',
              'Principal': '*',
              'Action': 's3:GetObject',
              'Resource': {
                'Fn::Join': [
                  '',
                  [
                    'arn:aws:s3:::',
                    {
                      'Ref': 'S3Bucket',
                    },
                    '/*',
                  ],
                ],
              },
            },
          ],
        },
        'Bucket': {
          'Ref': 'S3Bucket',
        },
      },
    },
  },
  'Outputs': {
    'WebsiteURL': {
      'Value': {
        'Fn::GetAtt': [
          'S3Bucket',
          'WebsiteURL',
        ],
      },
      'Description': 'URL for website hosted on S3',
    },
    'S3BucketSecureURL': {
      'Value': {
        'Fn::Join': [
          '',
          [
            'https://',
            {
              'Fn::GetAtt': [
                'S3Bucket',
                'DomainName',
              ],
            },
          ],
        ],
      },
      'Description': 'Name of S3 bucket to hold website content',
    },
  },
};
