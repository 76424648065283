import { DEFAULT_MWAA_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, MWAAEnvironmentDetail } from '@localstack/ui';

import { MWAAProps } from './types';

export const MWAAEnvironment = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_MWAA_ROUTES,
}: MWAAProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: environment } = useAwsGetter(
    'MWAA',
    'getEnvironment',
    [{ Name: id }],
    {
      clientOverrides,
      swrOverrides: { revalidateOnMount: true },
    },
  );
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Environment Details</Typography>
          <Breadcrumbs
            mappings={[
              ['MWAA', () => goto(routes.RESOURCES_MWAA_ENVIRONMENTS)],
              [id, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_MWAA_ENVIRONMENT_UPDATE, { id })}
        >
          Edit Environment
        </Button>
      }
    >
      <Card>
        <MWAAEnvironmentDetail environment={environment} />
      </Card>
    </Layout>
  );
};
