import { ReactElement, useEffect, useState } from 'react';
import { Box, Grid , ToggleButton, ToggleButtonGroup } from '@mui/material';


import { Bookmark as BookmarkIcon, ViewInAr as EphemeralInstanceIcon } from '@mui/icons-material';

import { useLocation } from 'react-router-dom';

import { useRoutes } from '@localstack/services';

import { CustomerLayout } from '~/layouts';

import { AppRoute } from '~/config';

import { InstanceBookmarks } from './components/InstanceBookmarks';
import { InstanceEphemeral } from './components/InstanceEphemeral';


enum ToggleState {
  BOOKMARK = 'bookmark',
  EPHEMERAL = 'ephemeral',
}

export const Instances = (): ReactElement => {
  const location = useLocation();
  const { goto } = useRoutes();
  const defaultToggle = 
  location.pathname === AppRoute.INSTANCES_EPHEMERAL ? ToggleState.EPHEMERAL : ToggleState.BOOKMARK;

  const [toggleState, setToggleState] = useState<ToggleState>(defaultToggle);

  useEffect(() => {
    goto(
      toggleState === ToggleState.BOOKMARK ? AppRoute.INSTANCES : AppRoute.INSTANCES_EPHEMERAL,
    );
  }, [toggleState]);

  useEffect(() => {
    setToggleState(
      location.pathname === AppRoute.INSTANCES_EPHEMERAL ? ToggleState.EPHEMERAL : ToggleState.BOOKMARK,
    );
  }, [location]);
  
  

  return (
    <CustomerLayout
      title="LocalStack Instance Management"
    >
      <Grid container spacing={2}>
        <Grid item xs={12} 
          style={{ 
            marginBottom: '1rem', 
            display: 'flex', 
            alignItems: 'center',
          }}
        >
          <ToggleButtonGroup
            exclusive
            onChange={(_, value) => setToggleState(value || toggleState)}
            value={toggleState}
          >
            <ToggleButton
              value={ToggleState.BOOKMARK}
              color="primary"
            >
              <Box display="flex" alignItems="center">
                <BookmarkIcon />
                <Box mr={1} />
                <span>Bookmarks</span>
              </Box>
            </ToggleButton>
            <ToggleButton
              value={ToggleState.EPHEMERAL}
              color="primary"
            >
              <Box display="flex" alignItems="center" style={{ gap: '0.75rem' }}>
                <EphemeralInstanceIcon />
                <span>
                  Ephemeral
                </span>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        { toggleState === ToggleState.BOOKMARK ?
          <InstanceBookmarks/> : <InstanceEphemeral/>
        }
      </Grid>
    </CustomerLayout>
  );
};
