import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter, useAwsBatchIterator } from '@localstack/services';
import { Card, Button } from '@mui/material';
import { ECSTaskDefinition } from '@localstack/types';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import {
  Dropdown,
  ConfirmableButton,
  ECSTaskDefinitionsTable,
  PageTitle,
} from '@localstack/ui';

import { ECSProps } from './types';

import { MainNavTabs } from './components';

export const ECSTaskDefinitions = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: definitionArns, isLoading, mutate } = useAwsGetter(
    'ECS',
    'listTaskDefinitions',
    [],
    { clientOverrides },
  );
  const { describeTaskDefinition } = useAwsEffect(
    'ECS',
    ['describeTaskDefinition'],
    { clientOverrides },
  );

  const taskDefinitions = useAwsBatchIterator(
    definitionArns?.taskDefinitionArns,
    async (arn) => (await describeTaskDefinition({ taskDefinition: arn })).taskDefinition as ECSTaskDefinition,
  );

  const { deregisterTaskDefinition } = useAwsEffect(
    'ECS',
    ['deregisterTaskDefinition'],
    { revalidate: ['listClusters', 'describeClusters'], clientOverrides },
  );

  const handleDeregisterSelected = useCallback(async () => {
    const promises = selectedIds.map((taskDefinition) => deregisterTaskDefinition({ taskDefinition }));
    await Promise.all(promises);
  }, [selectedIds]);

  // TODO: extend isLoading in the useAwsBatchIterator so we can avoid this magic
  const loading = isLoading || definitionArns?.taskDefinitionArns?.length !== taskDefinitions.length;

  return (
    <Layout
      title={
        <PageTitle
          title="ECS Task Definitions"
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ECS_TASK_DEFINITION_CREATE)}>
            Register Task Definition
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Deregister ${selectedIds.length} Task Definition(s)?`}
              onClick={handleDeregisterSelected}
              text="Selected Task Definitions will be permanently deregistered"
            >
              Deregister Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <ECSTaskDefinitionsTable
          definitions={taskDefinitions}
          loading={loading}
          onSelect={setSelectedIds}
          onViewDefinition={
            ({ family, revision }) => goto(
              routes.RESOURCES_ECS_TASK_DEFINITION,
              { family: revision ? `${family}:${revision}` : family },
            )
          }
        />
      </Card>
    </Layout>
  );
};
