import { Palette } from '@mui/material';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiInputAdornment'] => ({
  defaultProps: {
    sx: {
      color: palette.secondary.main,
    },
  },
});
