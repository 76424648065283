import { getSchema } from '@localstack/services';
import { GetBackendEnvironmentResult } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type AmplifyBEEnvironmentDetailProps = {
  environment?: Optional<Partial<GetBackendEnvironmentResult>>;
};

export const AmplifyBEEnvironmentDetail = ({
  environment,
}: AmplifyBEEnvironmentDetailProps): ReactElement => (
  <MagicDetails
    data={environment?.backendEnvironment}
    schema={getSchema('Amplify')}
    entry="BackendEnvironment"
    title="Amplify Backend Environment Details"
  />
);
