import { ReactElement } from 'react';
import { ResourceBrowserProps } from '@localstack/ui';

import {
  DEFAULT_API_GATEWAY_ROUTES,
  DEFAULT_KMS_ROUTES,
  DEFAULT_SNS_ROUTES,
  DEFAULT_APPSYNC_ROUTES,
  DEFAULT_BACKUP_ROUTES,
  DEFAULT_ATHENA_ROUTES,
  DEFAULT_SQS_ROUTES,
  DEFAULT_S3_ROUTES,
  DEFAULT_CLOUDFORMATION_ROUTES,
  DEFAULT_RDS_ROUTES,
  DEFAULT_LAMBDA_ROUTES,
  DEFAULT_CLOUDWATCH_ROUTES,
  DEFAULT_EC2_ROUTES,
  DEFAULT_ECS_ROUTES,
  DEFAULT_ECR_ROUTES,
  DEFAULT_COGNITO_ROUTES,
  DEFAULT_STEPFUNCTIONS_ROUTES,
  DEFAULT_SECRETSMANAGER_ROUTES,
  DEFAULT_SES_ROUTES,
  DEFAULT_DYNAMODB_ROUTES,
  DEFAULT_CLOUDTRAIL_ROUTES,
  DEFAULT_ELASTICACHE_ROUTES,
  DEFAULT_IAM_ROUTES,
  DEFAULT_KINESIS_ROUTES,
  DEFAULT_KAFKA_ROUTES,
  DEFAULT_EVENTBRIDGE_ROUTES,
  DEFAULT_METRICS_ROUTES,
  DEFAULT_MQ_ROUTES,
  DEFAULT_SYSTEMPARAMETERS_ROUTES,
  DEFAULT_TIMESTREAMDB_ROUTES,
  DEFAULT_GLUE_ROUTES,
  DEFAULT_NEPTUNE_ROUTES,
  DEFAULT_SGM_ROUTES,
  DEFAULT_APPLICATION_AUTO_SCALING_ROUTES,
  DEFAULT_CLOUDFRONT_ROUTES,
  DEFAULT_ROUTE53_ROUTES,
  DEFAULT_OPENSEARCH_ROUTES,
  DEFAULT_QLDB_ROUTES,
  DEFAULT_DOCDB_ROUTES,
  DEFAULT_APPCONFIG_ROUTES,
  DEFAULT_ACM_ROUTES,
  DEFAULT_MWAA_ROUTES,
  DEFAULT_AMPLIFY_ROUTES,
  DEFAULT_EKS_ROUTES,
  DEFAULT_TRANSCRIBE_ROUTES,
  DEFAULT_CODECOMMIT_ROUTES,
  DEFAULT_CE_ROUTES,
  DEFAULT_ACCOUNT_ROUTES,
  DEFAULT_DMS_ROUTES,
} from '@localstack/constants';

import {
  APIGatewayViewMappings,
  KMSViewMappings,
  AppSyncViewMappings,
  BackupViewMappings,
  SNSViewMappings,
  AthenaViewMappings,
  SQSViewMappings,
  S3ViewMappings,
  CloudFormationViewMappings,
  RDSViewMappings,
  LambdaViewMappings,
  CloudWatchViewMappings,
  EC2ViewMappings,
  ECSViewMappings,
  ECRViewMappings,
  CognitoViewMappings,
  StepFunctionsViewMappings,
  SecretsManagerViewMappings,
  SESViewMappings,
  DynamoDBViewMappings,
  CloudTrailViewMappings,
  ElastiCacheViewMappings,
  IAMViewMappings,
  KinesisViewMappings,
  KafkaViewMappings,
  EventBridgeViewMappings,
  MetricsViewMappings,
  MQViewMappings,
  SystemParametersViewMappings,
  TimestreamDBViewMappings,
  GlueMappings,
  NeptuneViewMappings,
  SageMakerViewMappings,
  ApplicationAutoscalingViewMappings,
  CloudFrontViewMappings,
  Route53ViewMappings,
  OpenSearchViewMappings,
  QLDBViewMappings,
  DocDBViewMappings,
  AppConfigViewMappings,
  ACMViewMappings,
  MWAAViewMappings,
  AmplifyViewMappings,
  EKSViewMappings,
  TranscribeViewMappings,
  CodeCommitViewMappings,
  CEViewMappings,
  AccountViewMappings,
  DMSViewMappings,
} from './mappings';

export * from './views';
export * from './mappings';
export * from './constants';

type RouteKey = keyof typeof DEFAULT_API_GATEWAY_ROUTES
  | keyof typeof DEFAULT_KMS_ROUTES
  | keyof typeof DEFAULT_SNS_ROUTES
  | keyof typeof DEFAULT_APPSYNC_ROUTES
  | keyof typeof DEFAULT_ATHENA_ROUTES
  | keyof typeof DEFAULT_SQS_ROUTES
  | keyof typeof DEFAULT_S3_ROUTES
  | keyof typeof DEFAULT_RDS_ROUTES
  | keyof typeof DEFAULT_LAMBDA_ROUTES
  | keyof typeof DEFAULT_CLOUDWATCH_ROUTES
  | keyof typeof DEFAULT_EC2_ROUTES
  | keyof typeof DEFAULT_ECS_ROUTES
  | keyof typeof DEFAULT_ECR_ROUTES
  | keyof typeof DEFAULT_COGNITO_ROUTES
  | keyof typeof DEFAULT_STEPFUNCTIONS_ROUTES
  | keyof typeof DEFAULT_SECRETSMANAGER_ROUTES
  | keyof typeof DEFAULT_SES_ROUTES
  | keyof typeof DEFAULT_CLOUDFORMATION_ROUTES
  | keyof typeof DEFAULT_DYNAMODB_ROUTES
  | keyof typeof DEFAULT_BACKUP_ROUTES
  | keyof typeof DEFAULT_ELASTICACHE_ROUTES
  | keyof typeof DEFAULT_IAM_ROUTES
  | keyof typeof DEFAULT_KINESIS_ROUTES
  | keyof typeof DEFAULT_KAFKA_ROUTES
  | keyof typeof DEFAULT_EVENTBRIDGE_ROUTES
  | keyof typeof DEFAULT_METRICS_ROUTES
  | keyof typeof DEFAULT_MQ_ROUTES
  | keyof typeof DEFAULT_SYSTEMPARAMETERS_ROUTES
  | keyof typeof DEFAULT_TIMESTREAMDB_ROUTES
  | keyof typeof DEFAULT_CLOUDTRAIL_ROUTES
  | keyof typeof DEFAULT_GLUE_ROUTES
  | keyof typeof DEFAULT_NEPTUNE_ROUTES
  | keyof typeof DEFAULT_SGM_ROUTES
  | keyof typeof DEFAULT_APPLICATION_AUTO_SCALING_ROUTES
  | keyof typeof DEFAULT_CLOUDFRONT_ROUTES
  | keyof typeof DEFAULT_ROUTE53_ROUTES
  | keyof typeof DEFAULT_OPENSEARCH_ROUTES
  | keyof typeof DEFAULT_DOCDB_ROUTES
  | keyof typeof DEFAULT_APPCONFIG_ROUTES
  | keyof typeof DEFAULT_ACM_ROUTES
  | keyof typeof DEFAULT_MWAA_ROUTES
  | keyof typeof DEFAULT_AMPLIFY_ROUTES
  | keyof typeof DEFAULT_EKS_ROUTES
  | keyof typeof DEFAULT_CODECOMMIT_ROUTES
  | keyof typeof DEFAULT_CE_ROUTES
  | keyof typeof DEFAULT_OPENSEARCH_ROUTES
  | keyof typeof DEFAULT_ACCOUNT_ROUTES
  | keyof typeof DEFAULT_QLDB_ROUTES
  | keyof typeof DEFAULT_DMS_ROUTES
  | keyof typeof DEFAULT_TRANSCRIBE_ROUTES;

type RouteEntry = [RouteKey, (props: ResourceBrowserProps<any>) => ReactElement] // eslint-disable-line
type RawRouteEntry = [RouteKey, { [componentName: string]: RouteEntry[1] }]

export const ROUTE_VIEW_MAPPING_RAW = new Map<RouteKey, RawRouteEntry[1]>(
  [
    ...(Array.from(APIGatewayViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(AppSyncViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(S3ViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(KMSViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(SNSViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(BackupViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(AthenaViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(SQSViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(CloudFormationViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(RDSViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(LambdaViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(CloudWatchViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(EC2ViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(ECSViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(ECRViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(StepFunctionsViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(CognitoViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(SecretsManagerViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(SESViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(DynamoDBViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(CloudTrailViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(ElastiCacheViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(IAMViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(KinesisViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(KafkaViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(EventBridgeViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(MetricsViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(MQViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(SystemParametersViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(TimestreamDBViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(GlueMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(SageMakerViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(ApplicationAutoscalingViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(CloudFrontViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(Route53ViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(OpenSearchViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(QLDBViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(DocDBViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(AppConfigViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(ACMViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(MWAAViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(AmplifyViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(EKSViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(TranscribeViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(CodeCommitViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(CEViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(AccountViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(NeptuneViewMappings.entries()) as unknown as RawRouteEntry[]),
    ...(Array.from(DMSViewMappings.entries()) as unknown as RawRouteEntry[]),
  ],
);

export const ROUTE_VIEW_MAPPING = new Map<RouteEntry[0], RouteEntry[1]>(
  Array.from(ROUTE_VIEW_MAPPING_RAW.entries()).map(([route, obj]) =>
    [route, Object.values(obj).at(0) as unknown as RouteEntry[1]]),
);
