import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, Breadcrumbs, BackupVaultCreateForm } from '@localstack/ui';
import { CreateBackupVaultRequest } from '@localstack/types';

import { DEFAULT_BACKUP_ROUTES } from '@localstack/constants/src';

import { BackupProps } from './types';

export const BackupVaultCreate = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_BACKUP_ROUTES,
}: BackupProps): ReactElement => {
  const { goto } = useRoutes();

  const {
    createBackupVault,
    isLoading: isVaultsMutating,
  } = useAwsEffect(
    'Backup',
    ['createBackupVault'],
    { revalidate: ['listBackupVaults'], clientOverrides },
  );

  const handleCreateBackupVault = async (data: CreateBackupVaultRequest) => {
    await createBackupVault(data);
    goto(routes.RESOURCES_BACKUP_VAULTS);
  };
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Create Backup Vault</Typography>
          <Breadcrumbs
            mappings={[
              ['Vaults', () => goto(routes.RESOURCES_BACKUP_VAULTS)],
              ['Create Vault', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateBackupVault"
          variant="outlined"
          color="primary"
          loading={isVaultsMutating}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <BackupVaultCreateForm
            onCreate={handleCreateBackupVault}
            formId="CreateBackupVault"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateBackupVault"
            variant="contained"
            color="primary"
            loading={isVaultsMutating}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
