import React, { ReactElement } from 'react';
import { StateMachineCustomExecutionEvent, StateMachineExecutionEventDetails } from '@localstack/types';
import { Link } from '@mui/material';

import { MagicTable } from '../../magic/MagicTable';

const getSchema = (isMinimal: boolean) => ({
  shapes: {
    HistoryEvents: {
      type: 'structure',
      members: {
        Id: {
          type: 'string',
        },
        Type: {
          type: 'string',
        },
        ...(!isMinimal ? {
          Step: {
            type: 'string',
          },
          Resource: {
            type: 'string',
          },
          StartedAfter: {
            type: 'string',
          },
        } : {}),
        DateAndTime: {
          type: 'timestamp',
        },
      },
    },
  },
});

export interface StateMachineExecutionEventsTableProps {
  events: StateMachineCustomExecutionEvent[];
  loading?: boolean;
  minimalTable?: boolean;
  setSelectedExecutionEvent: (object: StateMachineExecutionEventDetails | null) => void;
}

export const StateMachineExecutionEventsTable = React.memo(({
  events,
  loading,
  minimalTable = false,
  setSelectedExecutionEvent,
}: StateMachineExecutionEventsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema(minimalTable)}
    entry="HistoryEvents"
    idAttribute="Id"
    rows={events}
    loading={loading}
    externalFields={{
      'DateAndTime': (row) => {
        const date = row.DateAndTime as Date;
        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        const day = date.getDate();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const milliseconds = date.getMilliseconds();
        const formattedDate = `${month} ${day}, ${year}, ${hours}:${minutes}:${seconds}.${milliseconds}`;
        return <>{formattedDate}</>;
      },
      'Type': (row) => {
        if (!row.EventDetails) return <>{row.Type}</>;
        return (
          <Link
            onClick={() => setSelectedExecutionEvent(row.EventDetails)}
            underline="hover"
          >{row.Type}</Link>
        );
      },
    }}
  />
));
