
import { ReactElement } from 'react';
import { CreateLoadBalancer } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export type ELoadBalancerV1Props = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateLoadBalancer) => unknown;
}

export const ELoadBalancerV1Form = ({
  loading,
  formId,
  onSubmit,
}: ELoadBalancerV1Props): ReactElement => {

  // eslint-disable-next-line arrow-body-style
  const submitHandler = async (data: CreateLoadBalancer) => {
    return onSubmit(data);
  };

  return (
    <>
      <MagicForm
        schema={getSchema('ELB')}
        formId={formId}
        entry="CreateLoadBalancerListenerInput"
        loading={loading}
        onSubmit={(data: CreateLoadBalancer) => submitHandler(data)}
      />
    </>
  );
};
