import { Readable } from 'stream';

import axios from 'axios';

const inferMimeType = (fileName: string): string => {
  if (fileName.endsWith('.pdf')) {
    return 'application/pdf';
  }
  if (fileName.endsWith('.json')) {
    return 'application/json';
  }
  if (fileName.endsWith('.html')) {
    return 'text/html';
  }
  // TODO add more MIME types, as needed. Ideally we should
  // store the mime type alongside the uploaded document.
  return 'text/plain';
};

export const downloadFileContent = (
  content: Buffer|Uint8Array|Blob|string|Readable,
  fileName: string,
  contentType: string,
): void => {
  const cType = contentType || inferMimeType(fileName);
  const blob = new Blob([content as Blob|string], {
    type: `${cType};charset=utf8;`,
  });
  // create and open hidden link
  const element = document.createElement('a');
  document.body.appendChild(element);
  element.setAttribute('href', window.URL.createObjectURL(blob));
  element.setAttribute('download', fileName);
  element.style.display = '';
  element.click();
  document.body.removeChild(element);
};

export const retrieveFileContent = (file: File): Promise<ArrayBuffer> => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsArrayBuffer(file);
    reader.onload = (event) => resolve(event.target?.result as ArrayBuffer);
    reader.onerror = (event) => reject(event);
  });
};

export const retrieveFileContentAsBlob = async (file: File): Promise<Blob> => {
  const arrayBuffer = await retrieveFileContent(file);
  return new Blob([arrayBuffer]);
};

export const downloadUrlContent = async (url: string): Promise<Blob> => {
  const client = axios.create();
  const res = await client.get(url, { responseType: 'blob' });
  return res.data;
};
