import { useAwsGetter, AwsClientOverrides } from '@localstack/services';
import { AthenaDatabase } from '@localstack/types';


export type AthenaDatabasesLayoutLessProps = {
  clientOverrides?: AwsClientOverrides;
  onViewDatabase?: (name: AthenaDatabase) => void;
}

export type AthenaDatabasesLayoutLessReturns = {
  executionState?: string;
  databases?: AthenaDatabase[];
  isLoading: boolean,
  reloadDatabases: () => void;
}

export const AthenaDatabasesLayoutLess = ({
  clientOverrides,
}: AthenaDatabasesLayoutLessProps): AthenaDatabasesLayoutLessReturns => {

  const { data: showDbsQuery, mutate: reloadDatabases } = useAwsGetter(
    'Athena',
    'startQueryExecution',
    [{ QueryString: 'SHOW DATABASES' }],
    { clientOverrides },
  );

  const { data: showDbsQueryStatus } = useAwsGetter(
    'Athena',
    'getQueryExecution',
    [{ QueryExecutionId: showDbsQuery?.QueryExecutionId }],
    { swrOverrides: { refreshInterval: 1000 }, clientOverrides },
  );

  const executionState = showDbsQueryStatus?.QueryExecution?.Status?.State;

  const queryResultId = executionState === 'SUCCEEDED'
    ? showDbsQuery?.QueryExecutionId
    : undefined;

  const { data: showDbsQueryResult } = useAwsGetter(
    'Athena',
    'getQueryResults',
    [{ QueryExecutionId: queryResultId }],
    { swrOverrides: { refreshInterval: 1000 }, clientOverrides },
  );

  const transformedDatabases: AthenaDatabase[] = (showDbsQueryResult?.ResultSet?.Rows ?? []).slice(1).map(
    (r) => ({ Name: r.Data?.[0]?.VarCharValue as string }),
  );

  const isStillLoading = executionState === 'RUNNING';
  
  return {
    executionState,
    databases: transformedDatabases,
    isLoading: isStillLoading,
    reloadDatabases,
  };
};
