// Material helpers
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import {
  createGenerateClassName,
  StylesProvider,
} from '@mui/styles';

import { ReactElement, useState } from 'react';

import { createTheme, CssBaseline } from '@mui/material';

import { EventDataMap, LocalStackEventType, STORAGE_KEY_THEME, ThemeType } from '../constants';

import { useObservableEvent, CommunicationProvider } from '../communication';

import { BaseExtensionLayout } from './BaseExtensionLayout';

import { getTheme } from './theming';

// since mui creates a global instance as soon as imported, which is a problem when you
// have a subpackage which also needs mui, but doesn't really need it's own instance
// https://github.com/mui/material-ui/issues/11843
const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
  disableGlobal: true,
});

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
}

const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps): ReactElement =>
  condition ? wrapper(children) : <>{children}</>;

interface Props {
  children: ReactElement;
  useExtensionLayout?: boolean;
  themeType?: ThemeType;
  disableCommunicationProvider?: boolean;
}


export const LocalStackThemeProvider = ({
  children,
  useExtensionLayout,
  themeType,
  disableCommunicationProvider,
}: Props): ReactElement => {

  const [currentTheme, setCurrentTheme] = useState(getTheme(themeType));

  const handleThemeUpdate = (data: EventDataMap[LocalStackEventType.THEME_UPDATE]) => {
    localStorage.setItem(STORAGE_KEY_THEME, data.theme);
    setCurrentTheme(getTheme(data.theme));
  };

  const handleThemeInject = (data: EventDataMap[LocalStackEventType.THEME_INJECT]) =>
    setCurrentTheme(createTheme(JSON.parse(data.theme)));

  useObservableEvent({
    subscribedEvents: {
      [LocalStackEventType.THEME_UPDATE]: handleThemeUpdate,
      [LocalStackEventType.THEME_INJECT]: handleThemeInject,
    },
  });

  return (
    <StylesProvider generateClassName={generateClassName}>
      <StyledEngineProvider injectFirst>
        <ConditionalWrapper
          condition={!disableCommunicationProvider}
          wrapper={content => <CommunicationProvider>{content}</CommunicationProvider>}
        >
          <ThemeProvider theme={currentTheme}>
            <CssBaseline>
              <ConditionalWrapper
                condition={!!useExtensionLayout}
                wrapper={content => <BaseExtensionLayout>{content}</BaseExtensionLayout>}
              >
                {children}
              </ConditionalWrapper>
            </CssBaseline>
          </ThemeProvider>
        </ConditionalWrapper>
      </StyledEngineProvider>
    </StylesProvider>
  );
};

