import { buildRoute, getSchema, VALIDATION_RULES, AwsClientOverrides } from '@localstack/services';
import { CreateReplicationTaskMessage } from '@localstack/types';
import { ReactElement } from 'react';
import { DEFAULT_DMS_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledCodeEditor, RelatedResourcePicker } from '../../../form';


export type DMSReplicationTaskFormProps = {
  formId: string;
  onSubmit: (data: CreateReplicationTaskMessage) => void;
  loading?: boolean;
  clientOverrides?: AwsClientOverrides;
};

export const DMSReplicationTaskForm = ({
  formId,
  onSubmit,
  loading,
  clientOverrides,
}: DMSReplicationTaskFormProps): ReactElement => (
  <MagicForm
    entry='CreateReplicationTaskMessage'
    schema={getSchema('DMS')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    externalFields={{
      '^TableMappings$': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="json"
          rules={{ ...VALIDATION_RULES.required, ...VALIDATION_RULES.validJson }}
          defaultValue='{}'
        />
      ),
      '^SourceEndpointArn$': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name} entityName='ReplicationInstance'
          client='DMS'
          method='describeEndpoints'
          arrayKeyName='Endpoints'
          property='EndpointArn'
          clientOverrides={clientOverrides}
          creationRoute={buildRoute(DEFAULT_DMS_ROUTES.RESOURCES_DMS_REPLICATION_INSTANCE_CREATE)}
        />
      ),
      '^TargetEndpointArn$': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name} entityName='ReplicationInstance'
          client='DMS'
          method='describeEndpoints'
          arrayKeyName='Endpoints'
          property='EndpointArn'
          clientOverrides={clientOverrides}
          creationRoute={buildRoute(DEFAULT_DMS_ROUTES.RESOURCES_DMS_REPLICATION_INSTANCE_CREATE)}
        />
      ),
      '^ReplicationInstanceArn$': (control, name, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={name} entityName='ReplicationInstance'
          client='DMS'
          method='describeReplicationInstances'
          arrayKeyName='ReplicationInstances'
          property='ReplicationInstanceArn'
          clientOverrides={clientOverrides}
          creationRoute={buildRoute(DEFAULT_DMS_ROUTES.RESOURCES_DMS_REPLICATION_INSTANCE_CREATE)}
        />
      ),
    }}
  />
);
