import { ReactElement } from 'react';
import { IconButton, Tooltip, IconButtonProps, SvgIconOwnProps } from '@mui/material';
import { FileCopyOutlined as CopyIcon } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSnackbar } from '@localstack/services';


export type CopyIconButtonProps = IconButtonProps & {
  textToCopy: string;
  tooltipTitle?: string;
  button?: ReactElement;
  iconFontSize?: SvgIconOwnProps['fontSize'];
}


export const CopyIconButton = ({ 
  textToCopy, 
  tooltipTitle, 
  button, 
  iconFontSize = 'medium', 
  ...rest 
}: CopyIconButtonProps): ReactElement => {
  const { showSnackbar } = useSnackbar();

  return (
    <CopyToClipboard
      text={textToCopy}
      onCopy={() => showSnackbar({
        message: 'Copied to clipboard',
        severity: 'info',
      })}
    >
      <Tooltip title={tooltipTitle}>
        {button ||
          <IconButton color="primary" size="large" {...rest}>
            <CopyIcon fontSize={iconFontSize}/>
          </IconButton>
        }
      </Tooltip>
    </CopyToClipboard>
  );
};
