import { ReactElement } from 'react';
import { ButtonGroup, Button, Theme, alpha } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export interface IncrementButtonProps {
  value: number | string;
  onIncrement?: () => unknown;
  onDecrement?: () => unknown;
  onSetValue?: (value: number) => unknown;
  disabled?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      border: `1px solid ${alpha(theme.palette.primary.main, 0.4)}`, // closest approximation I could find...
      width: '3em',
      textAlign: 'center',
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
    },
  }),
);

export const IncrementButton = (
  {
    value,
    onIncrement,
    onDecrement,
    onSetValue,
    disabled,
  }: IncrementButtonProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <ButtonGroup>
        <Button onClick={onDecrement} disabled={disabled}>-</Button>
        <input
          className={classes.input}
          value={value}
          disabled={disabled}
          onChange={(event) => {
            if (event.target.value === '') {
              onSetValue?.(1);
            }
            const numValue = parseInt(event.target.value, 10);
            if (numValue) {
              onSetValue?.(numValue);
            }
          }}
        />
        <Button onClick={onIncrement} disabled={disabled}>+</Button>
      </ButtonGroup>

    </>
  );
};
