import { ReactElement } from 'react';
import { AgwRestApiAuthorizer } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type AgwRestApiAuthorizersTableProps = {
  selectable?: boolean;
  loading?: boolean;
  authorizers: AgwRestApiAuthorizer[];
  onViewAuthorizer?: (authorizer: AgwRestApiAuthorizer) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AgwRestApiAuthorizersTable = ({
  authorizers,
  selectable = true,
  loading,
  onViewAuthorizer,
  onSelect,
}: AgwRestApiAuthorizersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('APIGateway')}
    entry="Authorizer"
    idAttribute="id"
    rows={authorizers}
    selectable={selectable}
    onSelect={onSelect}
    order={['id', 'name']}
    loading={loading}
    externalFields={{
      id: (row) => (
        <Link
          onClick={() => onViewAuthorizer && onViewAuthorizer(row)}
          underline="hover"
        >
          {row.id}
        </Link>
      ),
    }}
  />
);
