import { useState, useCallback, ReactElement } from 'react';
import { Button, Card } from '@mui/material';
import { Dropdown, ConfirmableButton, S3BucketsTable, PageTitle } from '@localstack/ui';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { DEFAULT_S3_ROUTES } from '@localstack/constants';

import { S3Props } from './types';

export const S3Buckets = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_S3_ROUTES,
}: S3Props): ReactElement => {
  const { goto } = useRoutes();

  const [selectedBuckets, setSelectedBuckets] = useState<string[]>([]);

  const { data: buckets, isLoading, mutate } = useAwsGetter(
    'S3',
    'listBuckets', [],
    { clientOverrides: { s3ForcePathStyle: true , ...clientOverrides } },
  );

  const { deleteBucket } = useAwsEffect(
    'S3',
    ['deleteBucket'],
    {
      revalidate: ['listBuckets'],
      clientOverrides: { s3ForcePathStyle: true , ...clientOverrides },
    },
  );

  const handleDeleteBuckets = useCallback(
    async () => Promise.all(selectedBuckets.map((Bucket) => deleteBucket({ Bucket }))),
    [selectedBuckets],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="Buckets"
            onMutate={mutate}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_S3_BUCKET_CREATE)}>Create</Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedBuckets.length === 0 || isLoading}
                title={`Remove ${selectedBuckets.length} bucket(s)?`}
                onClick={handleDeleteBuckets}
                text="Selected Buckets will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <S3BucketsTable
            selectable
            buckets={buckets?.Buckets ?? []}
            onViewBucket={(bucket) => goto(routes.RESOURCES_S3_BUCKET, { bucket })}
            onSelect={setSelectedBuckets}
          />
        </Card>
      </Layout>
    </>
  );
};
