import { ReactElement } from 'react';
import { CreateSNSSubscriptionRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../../form';

const DEFAULT_DELIVERY_POLICY = {
  healthyRetryPolicy: {
    numRetries: 3,
    numNoDelayRetries: 0,
    minDelayTarget: 20,
    maxDelayTarget: 20,
    numMinDelayRetries: 0,
    numMaxDelayRetries: 0,
    backoffFunction: 'linear',
  },
};

export type SNSSubscribeFormProps = {
  loading?: boolean;
  arn: string;
  formId: string;
  onSubmit: (data: CreateSNSSubscriptionRequest) => unknown;
}

export const SNSSubscribeForm = ({
  loading,
  arn,
  formId,
  onSubmit,
}: SNSSubscribeFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('SNS')}
    formId={formId}
    entry="SubscribeInput"
    loading={loading}
    defaultValues={{
      'Attributes': {
        DeliveryPolicy: JSON.stringify(DEFAULT_DELIVERY_POLICY, null, 2),
      },
    }}
    onSubmit={(data: CreateSNSSubscriptionRequest) => onSubmit({ ...data, TopicArn: arn })}
    fieldConditions={{
      '^TopicArn': false,
    }}
    externalFields={{
      '^Attributes\\.(DeliveryPolicy|FilterPolicy|RedrivePolicy)$': (control, name) => (
        <ControlledCodeEditor
          language="json"
          control={control}
          name={name}
        />
      ),
    }}
  />
);
