import { AxiosError } from 'axios';
import { useContext, useRef, useState, useEffect, useCallback, FocusEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DEFAULT_AWS_ACCOUNT_ID, INSTANCE_DEFAULT_ID, STORAGE_KEY_FAVORITES } from '@localstack/constants';
import useSWR, { useSWRConfig, mutate as mutateGlobal } from 'swr';

import { ApiError } from '../apis';
import { buildRoute } from '../util/navigation';
import { formatError, formatErrorStatus } from '../util/format';
import { SnackbarMessage, GlobalStateContext } from '../context/global';
import { AWS_SERVICE_PORTS } from '../config';

/**
 * Delay state propagation.
 *
 * Can be useful, for example, to fix x-data-grid row updates
 * (when we need to add a little delay to let the table to cleanup before updating rows).
 */

export const useDelayedData = <T, D>(data: T, fallback: D, delay = 100): T | D => {
  const [state, setState] = useState<T>();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setState(data);
    }, delay);
    return () => clearTimeout(timeout);
  }, [data]);

  return state || fallback;
};

type UseGlobalSwr = {
  mutateRelated: (key: unknown, value?: any[]) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  clearAll: () => void;
};

export const clearSwrCache = (): Promise<undefined[]> =>
  mutateGlobal(() => true, undefined, { revalidate: false });

export const useGlobalSwr = (): UseGlobalSwr => {
  const { cache, mutate } = useSWRConfig();
  /**
   * In some situations you may want to mutate (reload) some data w/o
   * knowing the full cache key. This function will revalidate (reload)
   * any data that includes the specified cache key or its part.
   *
   * For example:
   * // somewhere: useSwr(JSON.stringify([SwrCacheKey.SOME_DATA, someId]))
   * mutateRelated(SwrCacheKey.SOME_DATA)
   * IMPORTANT: Include the region in your cache key unless you want to mutate the cache for all regions!
   */
  const mutateRelated = useCallback(async (key: unknown, value?: any[]) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    const cacheKey = typeof key === 'string' ? key : JSON.stringify(key);
    const notInternalCacheKey = (k: string): boolean => !k.startsWith('$req$') && !k.startsWith('$err$');
    Array.from((cache as Map<string, unknown>).keys()).forEach((k) => {
      if (k.includes(`"${cacheKey}"`)) {
        cache.delete(k);
        if (value && notInternalCacheKey(k)) {
          mutate(k, value);
        } else if (notInternalCacheKey(k)) {
          mutate(k);
        }
      }
    });
  }, [cache, mutate]);

  return { mutateRelated, clearAll: clearSwrCache };
};

type UseRoutes = {
  goto: (route?: string, params?: Record<string, Optional<string | number | boolean>>, query?: string) => void;
};

export const useRoutes = (): UseRoutes => {
  const navigate = useNavigate();
  const { iid = INSTANCE_DEFAULT_ID } = useParams<'iid'>() as { iid: string };
  const goto = useCallback(
    (route?: string, params?: Record<string, Optional<string | number | boolean>>, query?: string) => {
      const newParams = { ...params };
      if (iid && !newParams.iid) newParams.iid = iid;

      const builtRoute = buildRoute(route, newParams);
      const queryStringValue = builtRoute.includes('?') ? `&${query}` : `?${query}`;
      const url = `${builtRoute}${query ? queryStringValue : ''}`;
      navigate(url);
      if (newParams.refresh) navigate(0);
    },
    [navigate],
  );

  return { goto };
};

interface UseSnackbar {
  showSnackbar: (message: SnackbarMessage) => void;
}

/**
 * Hook that simplifies connection to the Snackbar's Provider Context
 * @returns {UseSnackbar}
 */
export const useSnackbar = (): UseSnackbar => {
  const { showSnackbar } = useContext(GlobalStateContext);
  return { showSnackbar };
};

interface UseRegion {
  region: string;
  setRegion: (region: string) => void;
}

export const useRegion = (): UseRegion => {
  const { region, setRegion } = useContext(GlobalStateContext);
  return { region, setRegion };
};

interface UseAwsAccountId {
  awsAccountId: string;
  setAwsAccountId: (region: string) => void;
  handleAwsAccountIdChange: (e: FocusEvent<HTMLInputElement>) => void;
}

export const useAwsAccountId = (): UseAwsAccountId => {
  const { awsAccountId, setAwsAccountId } = useContext(GlobalStateContext);
  const { showSnackbar } = useSnackbar();

  const handleAwsAccountIdChange = (e: FocusEvent<HTMLInputElement>) => {
    const isValid = /^\d+$/.test(e.target.value) && e.target.value.length === 12;
    if (!isValid) {
      e.target.value = DEFAULT_AWS_ACCOUNT_ID;
      setAwsAccountId(DEFAULT_AWS_ACCOUNT_ID);
      return showSnackbar({
        message: `Account ID is invalid. Please use 12-digit number.
        Falling back to default account ID: ${DEFAULT_AWS_ACCOUNT_ID}`,
        severity: 'error',
      });
    }
    setAwsAccountId(e.target.value);
  };

  return { awsAccountId, setAwsAccountId, handleAwsAccountIdChange };
};

interface UseEndpoint {
  endpoint: string;
  setEndpoint: (endpoint: string) => void;
}

export const useEndpoint = (service?: keyof typeof AWS_SERVICE_PORTS): UseEndpoint => {
  const { endpoint: endpointUrl, setEndpoint } = useContext(GlobalStateContext);

  const endpoint = (endpointUrl.includes(':') || !service)
    ? endpointUrl :
    `${endpointUrl}:${AWS_SERVICE_PORTS[service]}`;

  return { endpoint, setEndpoint };
};

/**
 * Track previous state of the specified value
 * @param value - Value to track to
 * @returns Previous value state
 */
export const usePrevious = <T>(value: T): Optional<T> => {
  const valueRef = useRef<T>();

  useEffect(() => {
    valueRef.current = value;
  }, [value]);

  return valueRef.current;
};

/**
 * A little hook to automatically keep track of
 * the error state and display the snackbar if it occurs.
 * @param error - Regular Error or AxiosError
 */
export const useErrorSnackbarEffect = (error: Optional<AxiosError | ApiError | Error>): void => {
  const { showSnackbar } = useSnackbar();

  const ignoredErrors = [403];

  useEffect(() => {
    if (error) {
      const status = formatErrorStatus(error);

      if (!ignoredErrors.includes(status)) {
        console.error(error);
        showSnackbar({ severity: 'error', message: formatError(error) });
      }
    }
  }, [error]);
};

interface UseFavoritesReturn {
  favorites: string[];
  isLoading: boolean;
  setFavorites: (data: string[]) => unknown;
}

// change in case you want to reset them for some reason
const FAVORITES_VERSION = '2023-09-04';

export const useFavorites = (): UseFavoritesReturn => {
  const cacheKey = STORAGE_KEY_FAVORITES;
  const { mutateRelated } = useGlobalSwr();

  const { data, isValidating } = useSWR(
    cacheKey,
    () => JSON.parse(localStorage.getItem(STORAGE_KEY_FAVORITES) as string),
  );

  const mutateFav = (newData: string[]) => {
    localStorage.setItem(cacheKey, JSON.stringify({ version: FAVORITES_VERSION, favorites: newData }));
    mutateRelated(cacheKey);
  };

  /* Reset favorites once we make sure that we have the
     data fetched (isValidating = false) and the version doesn't match
  */
  if (!isValidating && (!data?.version || data?.version !== FAVORITES_VERSION)) {
    mutateFav([]);
  }

  return {
    favorites: data?.favorites || [],
    isLoading: isValidating,
    setFavorites: mutateFav,
  };
};

type ComboKeys = 'ctrlKey' | 'shiftKey'; // these are the ones common among all os

/**
 * A little hook to add event listener on key press(es)
 * @param callback - The function to be executed on key press(es)
 * @param keyCode - The main key that will trigger the callback
 * @param comboKeys - The additional keys combination that will trigger the callback
 */
export const useKeyPress = (
  callback: (event: Optional<KeyboardEvent>) => void,
  keyCode: string,
  comboKeys?: [ComboKeys],
): void => {
  const handler = (event: KeyboardEvent) => {
    if (keyCode === event.code && (comboKeys?.every(key => event[key]) ?? true)) {
      
      callback(event);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handler, false);
    return () => {
      window.removeEventListener('keydown', handler, false);
    };
  }, [callback]);
};
