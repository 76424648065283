import { ReactElement } from 'react';

import { CustomerLayout } from '~/layouts';

import { Profile } from './components';

export const Account = (): ReactElement => (
  <CustomerLayout
    title="Account Information"
  >
    <Profile />
  </CustomerLayout>
);
