import { DEFAULT_APPCONFIG_ROUTES } from '@localstack/constants';
import { ReactElement } from 'react';
import { useAwsGetter, useRoutes } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Typography } from '@mui/material';
import { Breadcrumbs, AppConfigApplicationDetail } from '@localstack/ui';

import { AppConfigProps } from './types';

export const AppConfigApplication = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_APPCONFIG_ROUTES,
}: AppConfigProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();
  const { data: application } = useAwsGetter(
    'AppConfig',
    'getApplication',
    [{ ApplicationId: id }],
    {
      clientOverrides,
      swrOverrides: { revalidateOnMount: true },
    },
  );
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">AppConfig Application</Typography>
          <Breadcrumbs
            mappings={[
              ['AppConfig', () => goto(routes.RESOURCES_APPCONFIG_APPLICATIONS)],
              [id, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button
          onClick={() => goto(routes.RESOURCES_APPCONFIG_APPLICATION_UPDATE, { id })}
        >
          Edit Application
        </Button>
      }
    >
      <Card>
        <AppConfigApplicationDetail application={application} />
      </Card>
    </Layout>
  );
};
