import { ReactElement } from 'react';
import { PutCloudWatchMetricAlarmRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface MonitoringAlarmFormProps {
  loading?: boolean;
  formId?: string;
  onCreate: (data: PutCloudWatchMetricAlarmRequest) => unknown;
}

export const MonitoringAlarmForm = ({
  loading,
  formId,
  onCreate,
}: MonitoringAlarmFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('CloudWatch')}
    loading={loading}
    entry="PutMetricAlarmInput"
    formId={formId}
    onSubmit={(data: PutCloudWatchMetricAlarmRequest) => onCreate(data)}
  />
);
