import { getSchema } from '@localstack/services';
import { CreateBackendEnvironmentRequest } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicForm } from '../../magic/MagicForm';

export type AmplifyBEEnvironmentFormProps = {
  formId: string;
  appId: string;
  onSubmit: (data: CreateBackendEnvironmentRequest) => void;
  loading?: boolean;
};

export const AmplifyBEEnvironmentForm = ({
  formId,
  appId,
  onSubmit,
  loading,
}: AmplifyBEEnvironmentFormProps): ReactElement => (
  <MagicForm
    entry="CreateBackendEnvironmentRequest"
    schema={getSchema('Amplify')}
    loading={loading}
    onSubmit={onSubmit}
    formId={formId}
    data={{ appId }}
  />
);
