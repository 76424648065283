import { ExternalLink } from '@localstack/constants';
import { MARKER_IDS, TestMarker, createProperDate } from '@localstack/services';
import { PlanFamily, Subscription } from '@localstack/types';
import { Alert, Box, BoxProps } from '@mui/material';
import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      color: 'unset',
      textDecoration: 'underline',
    },
  }),
);

type ProStarterRenameInfoPros = {
  activeSubscriptions: Array<Subscription>
} & BoxProps

const RENAME_DATE = createProperDate(2024, 3, 22);

export const ProStarterRenameInfo = ({ activeSubscriptions, ...boxProps }: ProStarterRenameInfoPros): ReactElement => {

  const classes = useStyles();
  const proSubscriptions = activeSubscriptions.filter(sub => sub.plan.family === PlanFamily.PRO_PLANS);
  const containsOldProSubs = proSubscriptions.some((sub) => sub.created < (RENAME_DATE.getTime() / 1000));

  if (containsOldProSubs) {
    return (
      <Box {...boxProps}>
        <TestMarker name={MARKER_IDS.PRO_STARTER_RENAME_INFO} />
        <Alert severity='info' variant='outlined'>
          We recently renamed the <b>Pro</b> plan to <b>Starter</b>.
          This is only a name change and all features and functionality of the plan
          remain unchanged.
          {' '}
          <a
            className={classes.link}
            href={ExternalLink.BLOG_2024_03_RENAME_PRO_STARTER}
            target='_blank' rel="noreferrer"
          >
            Learn more
          </a>
        </Alert>
      </Box>
    );
  }

  return <></>;
};
