import { ReactElement } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ResourceBrowserProps } from '@localstack/ui';

import { ROUTE_VIEW_MAPPING } from '@localstack/resources';

import { buildRoute, getSelectedInstance, useEndpoint } from '@localstack/services';

import { PATH_PREFIX_RESOURCES } from '@localstack/constants';

import { CustomerLayout } from '~/layouts';
import { AppRoute } from '~/config';

interface ResourceAdapterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: (props: ResourceBrowserProps<any>) => ReactElement;
  routes: Record<string, string>;
  endpoint?: string;
}

const DefaultResourceAdapter = ({ Component, routes, endpoint }: ResourceAdapterProps): ReactElement =>
  <Component Layout={CustomerLayout} routes={routes} clientOverrides={{ endpoint }} />;

export const DefaultResourceBrowserRoutes = (): ReactElement => {
  const selectedInstance = getSelectedInstance();
  const { endpoint: globalEndpoint } = useEndpoint();
  const instanceEndpoint = selectedInstance?.endpoint || globalEndpoint;
  
  const routePrefix = `${PATH_PREFIX_RESOURCES}/`;

  const filteredRoutes = Object.fromEntries(
    Object.entries(AppRoute as Record<string, string>)
      .filter((v) => v[1].startsWith(routePrefix)),
  );

  // building routes for the resource browser based on instance id
  const adjustedRoutes = Object.fromEntries(
    Object.entries(filteredRoutes)
      .map((v) => [v[0], buildRoute(v[1], { iid: selectedInstance?.id })]),
  );

  return (
    <Routes>
      {Array.from(ROUTE_VIEW_MAPPING.keys()).map((key) => (
        <Route
          key={key}
          // since our customer routes specifies a general route for all resources
          // we need to strip away the prefix, since it's already included in the general route
          path={key.replace(routePrefix, '')}
          element={
            <DefaultResourceAdapter
              Component={ROUTE_VIEW_MAPPING.get(key) as ResourceAdapterProps['Component']}
              routes={adjustedRoutes}
              endpoint={instanceEndpoint}
            />
          }
        />
      ))}
    </Routes>
  );
};
