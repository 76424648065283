import { MuiThemeComponentsType } from '../types';

export default (): MuiThemeComponentsType['MuiCard'] => ({
  styleOverrides: {
    root: {
      borderRadius: 10,
      padding: 10,
    },
  },
});
