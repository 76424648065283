import { ReactElement } from 'react';
import { GlueSchemaItem } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface GlueSchemaDetailsProps {
  schema?: Optional<Partial<GlueSchemaItem>>;
}

export const GlueSchemaDetails = ({ schema }: GlueSchemaDetailsProps): ReactElement => (
  <MagicDetails
    data={schema}
    schema={getSchema('Glue')}
    entry="GetSchemaResponse"
    title="Schema Details"
  />
);
