import { Link } from '@mui/material';

import { ReactElement } from 'react';
import { AlternateContact } from '@localstack/types';
import { getSchema } from '@localstack/services/src';

import { MagicTable } from '../../magic/MagicTable';

export type AlternateContactTableProps = {
  item?: AlternateContact;
  onEditAlternateContact: () => void;
  loading?: boolean;
};

export const AlternateContactTable = ({
  item,
  onEditAlternateContact,
  loading,
}: AlternateContactTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Account')}
    entry="AlternateContact"
    idAttribute="EmailAddress"
    rows={item ? [item] : []}
    externalFields={{
      EmailAddress: ({ EmailAddress }) => (
        <Link onClick={() => onEditAlternateContact()} underline="hover">
          {EmailAddress}
        </Link>
      ),
    }}
    filterColumns={[
      'EmailAddress',
      'Name',
      'PhoneNumber',
      'Title',
    ]}
    loading={loading}
    disableSelectionOnClick
  />
);
