import { ReactElement } from 'react';
import { CognitoPool } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface CognitoPoolDetailsProps {
  pool?: Optional<Partial<CognitoPool>>;
}

export const CognitoPoolDetails = ({ pool }: CognitoPoolDetailsProps): ReactElement => (
  <MagicDetails
    data={pool}
    schema={getSchema('CognitoIdentityServiceProvider')}
    entry="UserPoolType"
    title="Cognito Pool Details"
  />
);
