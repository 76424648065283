import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Button, Box, Typography } from '@mui/material';
import { Breadcrumbs, DynamoDBTableDetails } from '@localstack/ui';
import { DEFAULT_DYNAMODB_ROUTES } from '@localstack/constants';

import { TableNavTabs } from './components';
import { DynamoDBProps } from './types';

export const DynamoDBTable = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DYNAMODB_ROUTES,
}: DynamoDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { tableName } = useParams<'tableName'>();

  const { data: table } = useAwsGetter('DynamoDB', 'describeTable', [{ TableName: tableName }], { clientOverrides });

  return (
    <Layout
      documentTitle="Table Details"
      tabs={<TableNavTabs tableName={tableName as string} routes={routes} />}
      title={
        <Box>
          <Typography variant="h4">Table Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DynamoDB', () => goto(routes.RESOURCES_DYNAMODB)],
              [table?.Table?.TableName, null],
            ]}
          />
        </Box>
      }
      actions={
        <Button onClick={() => goto(routes.RESOURCES_DYNAMODB_TABLE_UPDATE, { tableName })}>
          Edit Table
        </Button>
      }
    >
      <Card>
        <CardContent>
          <DynamoDBTableDetails table={table?.Table} />
        </CardContent>
      </Card>
    </Layout>
  );
};
