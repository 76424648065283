import { ReactElement, useState } from 'react';
import { ButtonProps, Button, LinearProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'inline-block',
    position: 'relative',
  },
  fullWidth: {
    width: '100%',
  },
  progress: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
}));

export type ProgressButtonProps = ButtonProps & {
  loading?: boolean;
}

export const ProgressButton = (
  { loading, children, disabled, onClick, ...rest }: ProgressButtonProps,
): ReactElement => {
  const classes = useStyles();

  const [isPromisePending, setIsPromisePeding] = useState<boolean | undefined>(false);
  const isLoading = (loading !== undefined) ? loading : isPromisePending;

  return (
    <div className={classnames(classes.root, { [classes.fullWidth]: rest.fullWidth })}>
      <Button
        disabled={isPromisePending || disabled}
        onClick={async (event) => {
          if (loading === undefined) {
            setIsPromisePeding(true);
            await onClick?.(event);
            setIsPromisePeding(false);
          } else {
            onClick?.(event);
          }
        }}
        {...rest}
      >
        {children}
      </Button>
      {isLoading && <LinearProgress className={classes.progress} />}
    </div>
  );
};

export default ProgressButton;
