import { getSchema } from '@localstack/services';
import { DMSReplicationInstance } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicDetails } from '../../magic/MagicDetails';

export type DMSReplicationInstanceDetailProps = {
  replicationInstance?: Optional<Partial<DMSReplicationInstance>>;
};

export const DMSReplicationInstanceDetail = ({
  replicationInstance,
}: DMSReplicationInstanceDetailProps): ReactElement => (
  <MagicDetails
    data={replicationInstance}
    schema={getSchema('DMS')}
    entry="ReplicationInstance"
    title="DMS Replication Instance Details"
  />
);
