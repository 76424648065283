import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';
import { ListCostCategoryDefinitionsResponse } from '@localstack/types';
import { ReactElement } from 'react';

import { MagicTable } from '../../magic/MagicTable';

export type CECostCategoryDefinitionsTableProps = {
  costCategoryDefinitions: ListCostCategoryDefinitionsResponse;
  onViewCostCategoryDefinition: (id: string) => void;
  onSelect: (ids: string[]) => void;
  loading?: boolean;
};

export const CECostCategoryDefinitionsTable = ({
  costCategoryDefinitions,
  onViewCostCategoryDefinition,
  onSelect,
  loading,
}: CECostCategoryDefinitionsTableProps): ReactElement => (
  <MagicTable
    entry="CostCategoryReference"
    idAttribute="CostCategoryArn"
    rows={costCategoryDefinitions?.CostCategoryReferences || []}
    schema={getSchema('CostExplorer')}
    externalFields={{
      CostCategoryArn: ({ CostCategoryArn }) => (
        <Link
          onClick={() => onViewCostCategoryDefinition(CostCategoryArn || '')}
          underline="hover"
        >
          {CostCategoryArn}
        </Link>
      ),
    }}
    filterColumns={
      ['CostCategoryArn', 'Name', 'DefaultValue']
    }
    loading={loading}
    onSelect={onSelect}
    selectable
    disableSelectionOnClick
  />
);
