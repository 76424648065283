import { ReactElement } from 'react';
import { SGMModels } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface SGMModelsTableProps {
  models: SGMModels[];
  loading?: boolean;
  selectable?: boolean;
  onSelect?: (ids: string[]) => unknown;
}

export const SGMModelsTable = ({
  models,
  loading,
  selectable,
  onSelect,
}: SGMModelsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('SGM')}
    entry="ModelSummary"
    idAttribute="ModelName"
    rows={models}
    selectable={selectable}
    onSelect={onSelect}
    order={['ModelName']}
    loading={loading}
  />
);
