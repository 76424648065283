import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { GlueRoutes } from '../../types';

type SchemaVersionTabsProps = {
  routes: GlueRoutes;
  schema: string;
  version: string;
  registry: string;
}

export const SchemaVersionTabs = ({ routes, schema, version, registry }: SchemaVersionTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_GLUE_SCHEMA_VERSION_METADATA, { schema, version, registry })}>
      Metadata
    </NavLink>
  </>
);
