import { useState, useCallback, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';

import {
  Dropdown,
  ConfirmableButton,
  TimestreamTablesGrid,
  LoadingFragment,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_TIMESTREAMDB_ROUTES } from '@localstack/constants/src';

import { TimestreamDBProps } from './types';

/**
 * A list view of a single Timestream database's tables.
 */
export const TimestreamTables = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_TIMESTREAMDB_ROUTES,
}: TimestreamDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { database } = useParams<'database'>();
  const [selected, setSelected] = useState<string[]>([]);
  const {
    data: tables,
    isLoading,
    mutate,
  } = useAwsGetter('TimestreamWrite', 'listTables', [{ DatabaseName: database }], { clientOverrides });
  const { deleteTable } = useAwsEffect(
    'TimestreamWrite',
    ['deleteTable'],
    { revalidate: ['listTables'], clientOverrides });

  const handleDeleteObjects = useCallback(
    async () => Promise.all(selected.map((TableName) => deleteTable({ DatabaseName: database as string, TableName }))),
    [selected],
  );

  return (
    <>
      <Layout
        documentTitle={`Timestream: ${database}`}
        title={
          <PageTitle
            title="Timestream"
            onMutate={mutate}
            breadcrumbs={[
              ['Timestream', () => goto(routes.RESOURCES_TIMESTREAM)],
              [database, null],
            ]}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_TIMESTREAM_TABLE_CREATE, { database })}>
              Create Table
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selected.length === 0 || isLoading}
                title={`Remove ${selected.length} database(s)?`}
                onClick={handleDeleteObjects}
                text="Selected Table(s) will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <LoadingFragment loading={isLoading} variant="card" height={300}>
            <TimestreamTablesGrid
              selectable
              entries={tables?.Tables || []}
              onViewTable={(entry) =>
                goto(routes.RESOURCES_TIMESTREAM_TABLE, {
                  database: database || '',
                  table: entry.TableName,
                })
              }
              onSelect={setSelected}
            />
          </LoadingFragment>
        </Card>
      </Layout>
    </>
  );
};
