import Editor from 'react-simple-code-editor';
import Highlight, { defaultProps, Language, PrismTheme } from 'prism-react-renderer';
import duotoneLight from 'prism-react-renderer/themes/duotoneLight';
import duotoneDark from 'prism-react-renderer/themes/duotoneDark';
import { Theme, useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { CSSProperties } from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    minHeight: theme.spacing(20),
  },
}));

export interface CodeEditorProps {
  style?: CSSProperties;
  placeholder?: string;
  value: string;
  setValue: (value: string) => void;
  language: Language;
  theme?: PrismTheme;
}

/**
 * A Code Editor with syntax highlighting for multiple languages.
 */
export const CodeEditor: React.FC<CodeEditorProps> = ({
  value,
  setValue,
  language,
  theme = duotoneLight,
  style = {},
  ...rest
}) => {
  const classes = useStyles();
  const defaultStyle = { boxSizing: 'border-box' };
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const highlight = (code: string) => (
    <Highlight {...defaultProps} theme={isDarkTheme ? duotoneDark : theme} code={code} language={language}>
      {({ tokens, getLineProps, getTokenProps }) => (
        <>
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </>
      )}
    </Highlight>
  );

  return (
    <Editor
      {...rest}
      className={classes.root}
      padding={10}
      value={value}
      onValueChange={setValue}
      highlight={highlight}
      style={{ ...defaultStyle, ...style } as CSSProperties}
    />
  );
};
