import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateEventBridgeEventBusRequest } from '@localstack/types';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, EventBridgeBusForm, Breadcrumbs } from '@localstack/ui';

import { DEFAULT_EVENTBRIDGE_ROUTES } from '@localstack/constants/src';

import { EventBridgeProps } from './types';

export const EventBridgeBusUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_EVENTBRIDGE_ROUTES,
}: EventBridgeProps): ReactElement => {
  const { goto } = useRoutes();

  const { createEventBus, isLoading } = useAwsEffect(
    'EventBridge',
    ['createEventBus'],
    { revalidate: ['listEventBuses', 'describeEventBus'], clientOverrides },
  );

  const handleCreate = async (data: CreateEventBridgeEventBusRequest) => {
    if (await createEventBus(data)) {
      goto(routes.RESOURCES_EVENT_BRIDGE_BUSES);
    }
  };

  return (
    <Layout
      documentTitle="EventBridge: Create Bus"
      title={
        <Box>
          <Typography variant="h4">Create Bus</Typography>
          <Breadcrumbs
            mappings={[
              ['EventBridge', () => goto(routes.RESOURCES_EVENT_BRIDGE)],
              ['Buses', () => goto(routes.RESOURCES_EVENT_BRIDGE_BUSES)],
              ['Create Event Bus', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <EventBridgeBusForm
            onCreate={handleCreate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
