import { ReactElement, useCallback, useState } from 'react';
import { Button, Card } from '@mui/material';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { StateMachinesTable, PageTitle, Dropdown, ConfirmableButton } from '@localstack/ui';

import { DEFAULT_STEPFUNCTIONS_ROUTES } from '@localstack/constants';

import { StepFunctionsProps } from './types';

export const StateMachines = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_STEPFUNCTIONS_ROUTES,
}: StepFunctionsProps): ReactElement => {
  const { goto } = useRoutes();
  const [selected, setSelected] = useState<string[]>([]);

  const { data: stateMachines, isLoading, mutate } = useAwsGetter(
    'StepFunctions',
    'listStateMachines',
    [],
    { clientOverrides },
  );

  const { deleteStateMachine } = useAwsEffect(
    'StepFunctions',
    ['deleteStateMachine'],
    { revalidate: ['listStateMachines'], clientOverrides });

  const handleDeleteSelected = useCallback(async () =>
    Promise.all(selected.map((sm) => deleteStateMachine({ stateMachineArn: sm }))),
  [selected],
  );

  return (
    <Layout
      documentTitle="Step Functions: State Machines"
      title={
        <PageTitle
          title="Step Functions State Machines"
          breadcrumbs={[
            ['Step Functions', () => goto(routes.RESOURCES_STEPFUNCTIONS)],
            ['State Machines', () => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINES)],
          ]}
          onMutate={mutate}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_CREATE)}>
            Create State Machine
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} State Machine(s)?`}
              onClick={handleDeleteSelected}
              text="Selected State Machine(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <StateMachinesTable
          stateMachines={stateMachines?.stateMachines ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewStateMachine={
            (sm) => goto(routes.RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS, {
              stateMachineArn: sm.stateMachineArn,
            })
          }
        />
      </Card>
    </Layout>
  );
};
