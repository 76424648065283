import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { LambdaRoutes } from '../../types';

interface Props {
  routes: LambdaRoutes;
}

export const NavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_LAMBDA_FUNCTIONS)} end>
      Functions
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_LAMBDA_LAYERS)} pro>
      Layers
    </NavLink>
  </>
);
