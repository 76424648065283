import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateDynamoDBTableRequest, UpdateDynamoDBTableRequest } from '@localstack/types';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { ProgressButton, DynamoDBTableForm, Breadcrumbs } from '@localstack/ui';
import { DEFAULT_DYNAMODB_ROUTES } from '@localstack/constants';

import { TableNavTabs } from './components';
import { DynamoDBProps } from './types';

export const DynamoDBTableUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_DYNAMODB_ROUTES,
}: DynamoDBProps): ReactElement => {
  const { goto } = useRoutes();
  const { tableName } = useParams<'tableName'>();

  const { createTable, updateTable, isLoading } = useAwsEffect(
    'DynamoDB',
    ['createTable', 'updateTable'],
    { revalidate: ['listTables', 'describeTable'], clientOverrides },
  );

  const { data: table } = useAwsGetter('DynamoDB', 'describeTable', [{ TableName: tableName }], { clientOverrides });

  const handleCreate = async (data: CreateDynamoDBTableRequest) => {
    if (await createTable(data)) {
      goto(routes.RESOURCES_DYNAMODB);
    }
  };

  const handleUpdate = async (data: UpdateDynamoDBTableRequest) => {
    await updateTable(data);
    goto(routes.RESOURCES_DYNAMODB_TABLE, { tableName });
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Table Details</Typography>
          <Breadcrumbs
            mappings={[
              ['DynamoDB', () => goto(routes.RESOURCES_DYNAMODB)],
              [tableName, () => goto(routes.RESOURCES_DYNAMODB_TABLE, { tableName })],
              [tableName ? 'Update' : 'Create Table', null],
            ]}
          />
        </Box>
      }
      tabs={tableName && <TableNavTabs tableName={tableName} routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <DynamoDBTableForm
            table={table?.Table}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
