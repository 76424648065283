import { ReactElement } from 'react';
import { CreateCloudWatchLogStreamRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicForm } from '../../magic/MagicForm';

export interface CloudWatchLogStreamFormProps {
  logGroupName: string;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateCloudWatchLogStreamRequest) => unknown;
}

export const CloudWatchLogStreamForm = ({
  logGroupName,
  loading,
  formId,
  onCreate,
}: CloudWatchLogStreamFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('CloudWatchLogs')}
    loading={loading}
    entry="CreateLogStreamRequest"
    formId={formId}
    fieldConditions={{
      '^logGroupName$': false,
    }}
    onSubmit={(data: CreateCloudWatchLogStreamRequest) => onCreate({ ...data, logGroupName })}
  />
);
