import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const KMS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.KMS}`;

export const DEFAULT_KMS_ROUTES: Record<string,string> = {
  RESOURCES_KMS: `${KMS_BASE_ROUTE}`,
  RESOURCES_KMS_KEYS: `${KMS_BASE_ROUTE}/keys`,
  RESOURCES_KMS_KEY: `${KMS_BASE_ROUTE}/keys/:keyId`,
  RESOURCES_KMS_KEY_CREATE: `${KMS_BASE_ROUTE}/keys/new`,
  RESOURCES_KMS_KEY_UPDATE: `${KMS_BASE_ROUTE}/keys/:keyId/update`,
};
