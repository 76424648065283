import { ReactElement, forwardRef } from 'react';
import { NavLink as RouterNavLink, NavLinkProps as RouterNavLinkProps } from 'react-router-dom';
import { Link, LinkProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';

import { PlanFamily } from '@localstack/types';

import { PlanChip } from '../../feedback/PlanChip';

const useStyles = makeStyles((theme: Theme) => createStyles({
  active: {
    textDecoration: 'underline',
  },
  planChip: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
}));

export type NavLinkProps = RouterNavLinkProps & LinkProps & {
  pro?: boolean
}

/**
 * Material Link connected to React Router
 */
const WrappedNavLink = (props: NavLinkProps) => {
  const classes = useStyles();
  const { pro } = props;
  return (
    <>
      <RouterNavLink
        {...props}
        style={pro ? { marginRight: 0 } : {}}
        className={({ isActive }) => classnames(
          props.className,
          { [classes.active]: isActive },
        )}
      />
      {
        pro &&
        <PlanChip planFamily={PlanFamily.PRO_PLANS} className={classes.planChip} />
      }
    </>
  );
};

export const NavLink = ({ ...rest }: NavLinkProps): ReactElement => (
  // eslint-disable-next-line
  <Link
    {...rest}
    component={forwardRef((props: NavLinkProps, _ref) => <WrappedNavLink {...props} />)}
    underline="hover"
  />
);
