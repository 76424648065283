import { ReactElement, MouseEvent } from 'react';
import { noop } from 'lodash';
import { Link, Typography, Breadcrumbs as MuiBreadcrumbs, LinkProps } from '@mui/material';

export interface BreadcrumbsProps {
  /** breadcrumbs path mappings (key = path name, value = navigation function) */
  mappings: [Optional<string>, Optional<() => unknown>][];
}

export const WrappedLink = (props: LinkProps): ReactElement => {
  // eslint-disable-next-line
  const onClick = (e: MouseEvent<any, any>) => {
    e.preventDefault();
    if (props.onClick) props.onClick(e);
  };

  return <Link href="#" {...props} onClick={onClick} underline="hover" />;
};

export const Breadcrumbs = ({ mappings }: BreadcrumbsProps): ReactElement => {
  const filteredParts = mappings.filter(([name]) => Boolean(name));

  const lastPart = filteredParts.slice(-1)[0];
  const rootPart = filteredParts[0];

  // remove head and tail elements
  const remainingParts = filteredParts.slice(1).slice(0, -1);

  return (
    <MuiBreadcrumbs sx={{
      '& li.MuiBreadcrumbs-li, & li.MuiBreadcrumbs-li > *': {
        maxWidth: 250,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    }}
    >
      {rootPart && <WrappedLink onClick={rootPart[1] || noop}>{rootPart[0]}</WrappedLink>}
      {remainingParts.map(([name, handler]) => <WrappedLink key={name} onClick={handler || noop}>{name}</WrappedLink>)}
      {lastPart && lastPart !== rootPart && <Typography color="textPrimary">{lastPart?.[0]}</Typography>}
    </MuiBreadcrumbs>
  );
};
