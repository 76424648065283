import { ReactElement } from 'react';
import { IAMGroup } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../../magic/MagicDetails';

export interface IAMGroupDetailsProps {
  group?: Optional<Partial<IAMGroup>>;
}

export const IAMGroupDetails = ({ group }: IAMGroupDetailsProps): ReactElement => (
  <MagicDetails
    data={group}
    schema={getSchema('IAM')}
    entry="Group"
    title="Group Details"
  />
);
