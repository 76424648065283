import { ReactElement } from 'react';
import { getSchema } from '@localstack/services';

import { CreateNDBCluster, NeptuneCluster, UpdateNDBCluster } from '@localstack/types';

import { MagicForm } from '../../magic/MagicForm';

export interface NeptuneClusterFormProps {
  cluster?: Optional<Partial<NeptuneCluster>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateNDBCluster) => unknown;
  onUpdate: (data: UpdateNDBCluster) => unknown;
}

export const NeptuneClusterForm = ({
  cluster,
  loading,
  formId,
  onCreate,
  onUpdate,
}: NeptuneClusterFormProps): ReactElement => (
  <MagicForm
    data={cluster ?? { Engine: 'neptune', EngineVersion: '1.0.2.1' }}
    schema={getSchema('Neptune')}
    loading={loading}
    entry="CreateDBClusterMessage"
    formId={formId}
    onSubmit={(data: CreateNDBCluster) => {
      if (!cluster) return onCreate(data);
      return onUpdate({ ...data, DBClusterIdentifier: cluster.DBClusterIdentifier as string });
    }}
  />
);
