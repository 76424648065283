import { ReactElement } from 'react';
import { SQSQueueAttributes } from '@localstack/types';

import { MagicDetails } from '../../../magic/MagicDetails';

export type SQSQueueDetailsProps = {
  attributes: Optional<SQSQueueAttributes>;
};

const SCHEMA = {
  shapes: {
    QueueAttributesMap: {
      type: 'structure',
      members: {
        ApproximateNumberOfMessages: { type: 'integer' },
        ApproximateNumberOfMessagesDelayed: { type: 'integer' },
        ApproximateNumberOfMessagesNotVisible: { type: 'integer' },
        CreatedTimestamp: { type: 'string' },
        DelaySeconds: { type: 'integer' },
        LastModifiedTimestamp: { type: 'string' },
        MaximumMessageSize: { type: 'integer' },
        MessageRetentionPeriod: { type: 'integer' },
        QueueArn: { type: 'string' },
        ReceiveMessageWaitTimeSeconds: { type: 'integer' },
        VisibilityTimeout: { type: 'integer' },
      },
    },
  },
};

export const SQSQueueDetails = ({ attributes }: SQSQueueDetailsProps): ReactElement => (
  <MagicDetails
    data={attributes}
    schema={SCHEMA}
    entry="QueueAttributesMap"
    title="Queue Attributes"
  />
);
