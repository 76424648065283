import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useRoutes, useAwsEffect } from '@localstack/services';
import { ProgressButton, Breadcrumbs, CloudTrailCreateForm } from '@localstack/ui';
import { CreateTrailRequest } from '@localstack/types';

import { TrailsNavTabs } from './components/TrailsNavTabs';
import { CloudTrailProps } from './types';

export const CloudTrailCreate = ({
  Layout,
  clientOverrides,
  routes,
}: CloudTrailProps): ReactElement => {
  const { goto } = useRoutes();

  const { createTrail } = useAwsEffect('CloudTrail', ['createTrail'], { revalidate: ['listTrails'], clientOverrides });

  const handleCreateCloudTrail = async (data: CreateTrailRequest) => {
    await createTrail(data as CreateTrailRequest);
    goto(routes.RESOURCES_CLOUDTRAIL_TRAILS);
  };

  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">CloudTrail Trail</Typography>
          <Breadcrumbs
            mappings={[
              ['Trails', () => goto(routes.RESOURCES_CLOUDTRAIL_TRAILS)],
              ['Create Trail', null],
            ]}
          />
        </Box>
      }
      tabs={<TrailsNavTabs routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="CloudTrailCreate"
          variant="outlined"
          color="primary"
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CloudTrailCreateForm
            clientOverrides={clientOverrides}
            onCreate={handleCreateCloudTrail}
            formId="CloudTrailCreate"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CloudTrailCreate"
            variant="contained"
            color="primary"
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
