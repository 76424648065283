import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Fingerprint as CustomIdentityIcon } from '@mui/icons-material';

import GitHubIcon from './icons/github.svg';
import GoogleIcon from './icons/google.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    fill: theme.palette.text.primary,
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

const ICONS_MAP = {
  'github': GitHubIcon,
  'google': GoogleIcon,
  'public-github': GitHubIcon,
  fallback: CustomIdentityIcon,
};

type Props = {
  provider: string;
  size?: 'medium' | 'small';
};

export const SSOIcon = ({ provider, size = 'medium' }: Props): ReactElement => {
  const classes = useStyles();
  const Icon = ICONS_MAP[provider.toString().toLowerCase() as keyof typeof ICONS_MAP] ?? ICONS_MAP.fallback;

  return (
    <Icon
      className={classnames(classes.icon, {
        [classes.small]: size === 'small',
      })}
    />
  );
};
