import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { AccountRoutes } from '../../types';

interface Props {
  routes: AccountRoutes;
}

export const NavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_ACCOUNT_CONTACTINFO)}>
      Contact Information
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_ALTERNATE_CONTACT)}>
      Alternate Contact
    </NavLink>
  </>
);
