import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { DEFAULT_API_GATEWAY_ROUTES } from '@localstack/constants';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

import {
  PageTitle,
  Dropdown,
  ConfirmableButton,
  AgwRestApiUsagePlansTable,
} from '@localstack/ui';

import { ApiGatewayProps } from './types';
import { RestApiNavTabs } from './components';

export const AgwRestApiUsagePlans = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_API_GATEWAY_ROUTES,
}: ApiGatewayProps): ReactElement => {
  const { restApiId } = useParams<'restApiId'>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();
  const { data: api } = useAwsGetter('APIGateway', 'getRestApi', [{ restApiId }], { clientOverrides });
  const { data: plans, isLoading, mutate } = useAwsGetter('APIGateway', 'getUsagePlans', [], { clientOverrides });

  const { deleteUsagePlan } = useAwsEffect(
    'APIGateway',
    ['deleteUsagePlan'],
    {
      revalidate: ['getUsagePlans', 'getUsagePlan'],
      clientOverrides,
    },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((usagePlanId) => deleteUsagePlan({ usagePlanId }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      documentTitle="Usage Plans"
      title={
        <PageTitle
          title="Usage Plans"
          onMutate={mutate}
          breadcrumbs={[
            ['API Gateway', () => goto(routes.RESOURCES_AGW1)],
            [api?.name, () => goto(routes.RESOURCES_AGW1_API, { restApiId })],
            ['Usage Plans', null],
          ]}
        />
      }
      tabs={<RestApiNavTabs restApiId={restApiId as string} routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_AGW1_API_USAGE_PLAN_CREATE, { restApiId })}>
            Create Plan
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Plan(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Plans will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <AgwRestApiUsagePlansTable
          plans={plans?.items || []}
          onSelect={setSelectedIds}
          onViewUsagePlan={
            ({ id }) => goto(routes.RESOURCES_AGW1_API_USAGE_PLAN, { restApiId, usagePlanId: id })
          }
        />
      </Card>
    </Layout>
  );
};
