import { ReactElement } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes, useSnackbar } from '@localstack/services';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { Breadcrumbs, GlueSchemaForm, ProgressButton } from '@localstack/ui';
import { CreateGlueSchemaRequest, UpdateGlueSchemaRequest } from '@localstack/types';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants/src';
import { useParams } from 'react-router-dom';

import { GlueProps } from './types';
import { MainNavTabs } from './components';


export const GlueSchemaUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const { goto } = useRoutes();
  const { schema, registry } = useParams<'schema' | 'registry'>();
  const { showSnackbar } = useSnackbar();

  const { createSchema, updateSchema, checkSchemaVersionValidity, isLoading } = useAwsEffect(
    'Glue',
    ['createSchema', 'updateSchema', 'checkSchemaVersionValidity'],
    { revalidate: ['listSchemas', 'getSchema'], clientOverrides },
  );

  const { data: SchemaData } = useAwsGetter(
    'Glue', 'getSchema',
    [schema ? { SchemaId: { SchemaName: schema, RegistryName: registry } } : {}], { clientOverrides });

  const handleCreate = async (data: CreateGlueSchemaRequest) => {
    const SchemaValidityResponse = await checkSchemaVersionValidity({
      DataFormat: data.DataFormat,
      SchemaDefinition: data.SchemaDefinition ?? '',
    });

    // might return undefined if something not implemented in LS    
    if(!SchemaValidityResponse){
      return;
    }

    if (!SchemaValidityResponse.Valid) {
      return showSnackbar({
        message: SchemaValidityResponse.Error ?? '',
        severity: 'error',
      });
    }
    await createSchema({
      ...data,
      SchemaDefinition: JSON.stringify(JSON.parse(data.SchemaDefinition ?? '{}'), null, 2),
    });
    goto(routes.RESOURCES_GLUE_SCHEMAS);
  };

  const handleUpdate = async (data: UpdateGlueSchemaRequest) => {
    await updateSchema(data);
    goto(routes.RESOURCES_GLUE_SCHEMAS);
  };

  return (
    <Layout
      documentTitle={`Glue: ${schema ? 'Update' : 'Create'} Schema`}
      title={
        <Box>
          <Typography variant="h4">{schema ? 'Update' : 'Create'} Schema</Typography>
          <Breadcrumbs
            mappings={[

              ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
              ['Schemas', () => goto(routes.RESOURCES_GLUE_SCHEMAS)],
              [`${schema ? 'Update' : 'Create'} Schema`, null],
            ]}
          />
        </Box>
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <ProgressButton
          type="submit"
          form="UpsertSchema"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <GlueSchemaForm
            schema={SchemaData}
            loading={isLoading}
            formId="UpsertSchema"
            clientOverrides={clientOverrides}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="UpsertSchema"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
