import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { EC2Routes } from '../../types';

interface Props {
  routes: EC2Routes;
}

export const LoadBalancerNavTabs = ({ routes }: Props): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_EC2_LBV1)} >
      Load Balancers V1
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_EC2_LBV2)} >
      Load Balancers V2
    </NavLink>
  </>
);
