import { ReactElement, FunctionComponent } from 'react';
import { Alert } from '@mui/material';

import { LambdaPreview } from './LambdaPreview';
import { S3Preview } from './S3Preview';

const SERVICE_PREVIEW_MAP = {
  lambda: LambdaPreview,
  s3: S3Preview,
};

export type ResourcePreviewProps = {
  /** selected service type */
  service: string;
  /** snaphost of the service */
  delta: Optional<unknown>;
  /** state snaphost of the entire region */
  regionalDelta: Optional<unknown>;
};

type PreviewComponent = FunctionComponent<{
  delta: ResourcePreviewProps['delta'],
  regionalDelta: ResourcePreviewProps['regionalDelta']
}>;

export const ResourcePreview = ({ service, delta, regionalDelta }: ResourcePreviewProps): ReactElement => {
  const Component: PreviewComponent = SERVICE_PREVIEW_MAP[service as keyof typeof SERVICE_PREVIEW_MAP];

  if (!delta) {
    return (
      <Alert severity="info" variant="outlined">
        There are no changes to visualize
      </Alert>
    );
  }

  if (!Component) {
    return (
      <Alert severity="info" variant="outlined">
        Historical preview of <strong>{service}</strong> is not yet supported
      </Alert>
    );
  }

  return (
    <>
      <Component delta={delta} regionalDelta={regionalDelta} />
    </>
  );
};
