import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const EKS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.EKS}`;

export const DEFAULT_EKS_ROUTES: Record<string, string> = {
  RESOURCES_EKS_CLUSTERS: `${EKS_BASE_ROUTE}/clusters`,
  RESOURCES_EKS_CLUSTER: `${EKS_BASE_ROUTE}/cluster/:id`,
  RESOURCES_EKS_CLUSTER_CREATE: `${EKS_BASE_ROUTE}/cluster/new`,
  RESOURCES_EKS_CLUSTER_UPDATE: `${EKS_BASE_ROUTE}/cluster/:id/update`,
};
