import { ReactElement } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { CreateRDBParams, UpdateRDBParams } from '@localstack/types';
import { ProgressButton, Breadcrumbs, NeptuneInstanceForm } from '@localstack/ui';

import { DEFAULT_NEPTUNE_ROUTES } from '@localstack/constants';

import { NeptuneProps } from './types';

export const NeptuneDBInstanceUpsert = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_NEPTUNE_ROUTES,
}: NeptuneProps): ReactElement => {
  const { goto } = useRoutes();
  const { instanceId } = useParams<'instanceId'>();

  const { createDBInstance, modifyDBInstance, isLoading } = useAwsEffect(
    'Neptune',
    ['createDBInstance', 'modifyDBInstance'],
    { revalidate: ['describeDBInstances'], clientOverrides },
  );

  const { data: instances } = useAwsGetter(
    'Neptune',
    'describeDBInstances',
    [{ DBInstanceIdentifier: instanceId }],
    { clientOverrides },
  );
  const instance = instances?.DBInstances?.find((inst) => inst.DBInstanceIdentifier === instanceId);

  const handleCreate = async (data: CreateRDBParams) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore 
    if (await createDBInstance(data)) {
      goto(routes.RESOURCES_NEPTUNE_INSTANCES);
    }
  };

  const handleUpdate = async (data: UpdateRDBParams) => {
    if (await modifyDBInstance(data)) {
      goto(routes.RESOURCES_NEPTUNE_INSTANCE, { instanceId });
    }
  };

  return (
    <Layout
      documentTitle="instance Details"
      title={
        <Box>
          <Typography variant="h4">Instance Details</Typography>
          <Breadcrumbs
            mappings={[
              ['Neptune', () => goto(routes.RESOURCES_NEPTUNE_INSTANCES)],
              [
                instanceId ? `${instanceId} (${instance?.DBName})` : null,
                () => goto(routes.RESOURCES_NEPTUNE_INSTANCE, { instanceId }),
              ],
              [instanceId ? 'Update' : 'Create Instance', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="update"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <NeptuneInstanceForm
            instance={instance}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            loading={isLoading}
            formId="update"
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="update"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
