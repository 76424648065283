import {
  UserService,
  useApiGetter,
} from '@localstack/services';
import {
  Container,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactElement, useMemo } from 'react';

import { AnnouncementModal } from '~/components';

import { BaseLayout, BaseLayoutProps } from '../Base';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabs: {
      borderBottom: '1px solid',
      color: theme.palette.divider,
      borderColor: theme.palette.divider,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      '& a': {
        marginRight: theme.spacing(3),
      },
    },
    iconsContainer: {
      display: 'flex',
      gap: '1rem',
      alignItems: 'center',
      '.collapsed &': {
        flexDirection: 'column-reverse',
      },
    },

    contentContainer: {
      flex: 1,
      display: 'flex',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    content: {
      flex: 1,
      maxWidth: '100%',
    },
  }),
);

export type AuthenticatedLayoutProps = BaseLayoutProps & {
  tabs?: React.ReactNode;
  sidebar?: React.ReactNode;
};

export const AuthenticatedLayout = ({
  children,
  sidebar,
  tabs,
  maxWidth = 'lg',
  fullScreen,
  ...rest
}: AuthenticatedLayoutProps): ReactElement => {
  const classes = useStyles();
  const { data: notifications, mutate } = useApiGetter(UserService, 'listNotifications', []);

  const unreadNotifications = useMemo(() => notifications?.filter(n => !n.read), [notifications]);

  const announcements = useMemo(() =>
    unreadNotifications?.filter(item => item.reference_id) ?? [], [unreadNotifications]);

  const containerMaxWidth = fullScreen ? false : maxWidth;

  return (
    <BaseLayout
      {...rest}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
    >
      {tabs && (
        <div id="tabs" className={classes.tabs}>
          <Container maxWidth={containerMaxWidth}>{tabs}</Container>
        </div>
      )}

      <Container maxWidth={containerMaxWidth} className={classes.contentContainer}>
        {sidebar && <div>{sidebar}</div>}
        <AnnouncementModal
          announcements={announcements}
          revalidate={mutate}
        />
        <main className={classes.content}>
          {children}
        </main>
      </Container>
    </BaseLayout>
  );
};
