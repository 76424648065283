import { ReactElement } from 'react';

import { MermaidRenderer } from '../../../display/MermaidRenderer';

interface StateMachineDefinition {
  Type: string;
  States: Record<string, StateMachineDefinition>;
  Branches: StateMachineDefinition[];
  Choices: StateMachineDefinition[];
  Iterator: StateMachineDefinition;
  Next?: string;
  End?: boolean;
  StartAt?: string;
}

const removeSpecialCharacters = (id: string | undefined) => (id || '').replace(/[^a-zA-Z_$0-9]/g, '');

const buildMermaidNode = (state: StateMachineDefinition): string =>
  Object.entries(state.States ?? {}).reduce((memo, [id, def]) => {
    if (def.Type === 'Parallel') {
      return `${memo}
        subgraph ${id}
          ${def.Branches.map(buildMermaidNode).join('\n')}
        end
      `;
    }

    if (def.Type === 'Map') {
      return `${memo}
        subgraph ${id}
          ${buildMermaidNode(def.Iterator)}
        end
      `;
    }

    if (def.Type === 'Choice') {
      return `${memo}
        ${removeSpecialCharacters(id)}{"${id}"}
        ${def.Choices.map((choice) => `${removeSpecialCharacters(id)} ` +
        `--> ${removeSpecialCharacters(choice.Next)}`).join('\n')}
        ${def.Choices.map(buildMermaidNode).join('\n')}
      `;
    }

    if (def.Next) {
      return `${memo}
        ${removeSpecialCharacters(id)}(["${id}"]) --> ${removeSpecialCharacters(def.Next)}(["${def.Next}"])
      `;
    }

    return `${memo}
      ${removeSpecialCharacters(id)}(["${id}"])
    `;
  }, '');

const buildMermaid = (state: StateMachineDefinition) => {
  const finalState = Object.entries(state.States ?? {}).find(([, def]) => def.End);
  return `
    flowchart TB
      Start((Start)) --> ${removeSpecialCharacters(state.StartAt)}(["${state.StartAt}"])
      ${buildMermaidNode(state)}
      ${removeSpecialCharacters(finalState?.[0])} --> End((End))
  `;
};

export interface StateMachineFlowchartProps {
  definition: any; // eslint-disable-line
}

export const StateMachineFlowchart = ({ definition }: StateMachineFlowchartProps): ReactElement =>
  <MermaidRenderer code={buildMermaid(definition)} />;
