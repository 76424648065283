import { ReactElement } from 'react';
import { CloudWatchMetric } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';

export interface MonitoringMetricDetailsProps {
  metric?: Optional<Partial<CloudWatchMetric>>;
}

export const MonitoringMetricDetails = ({ metric }: MonitoringMetricDetailsProps): ReactElement => (
  <MagicDetails
    data={metric}
    schema={getSchema('CloudWatch')}
    entry="Metric"
    title="Metric Details"
  />
);
