import { SUB_ACTIVE_STATUSES } from '@localstack/constants';
import { LicenseAssignment, OrganizationMember, Subscription } from '@localstack/types';
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import {
  DeleteForever as DeleteIcon,
  ErrorRounded as SubscriptionWarningIcon,
} from '@mui/icons-material';

import { ReactElement, useState } from 'react';

type LicenseAssignmentProps = {
  licenseAssignments: Array<LicenseAssignment>,
  members: Array<OrganizationMember>,
  subscriptions: Array<Subscription>,
  assignLicense: (userId: string, subscriptionId: string) => unknown,
  removeLicenseAssignment: (assignmentId: string) => unknown,
}

const useStyles = makeStyles(() => createStyles({
  table: {
    '& > *': {
      textWrap: 'nowrap',
    },
  },
}));

export const LicenseAssignments = ({
  licenseAssignments,
  members,
  subscriptions,
  assignLicense,
  removeLicenseAssignment,
}: LicenseAssignmentProps): ReactElement => {

  const idToUser = (userId: string | undefined) => members.find(member => member.id === userId);

  const membersWithoutLicenseAssigned = members.filter(member =>
    !licenseAssignments.find(assignment => assignment.user_id === member.id),
  );

  const [assignee, setAssignee] = useState<string>('');
  const [targetSub, setTargetSub] = useState<string>('');

  const classes = useStyles();

  const activeSubscriptions = subscriptions.filter((sub) => SUB_ACTIVE_STATUSES.includes(sub.status));

  return (
    <Card>
      <CardHeader title="License Assignments" />
      <CardContent>
        <Box style={{ overflowX: 'auto' }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>User ID</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell>Subscription ID</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(licenseAssignments && licenseAssignments.length === 0 && (
                <TableRow>
                  <TableCell>No licenses assigned yet</TableCell>
                </TableRow>
              ))}

              {licenseAssignments.map((assignment) => {
                const subscription = subscriptions.find(sub => sub.id === assignment.subscription_ids?.[0]);
                return (
                  <TableRow key={assignment.id}>
                    <TableCell>
                      <b>{idToUser(assignment.user_id)?.name}</b><br />
                      {idToUser(assignment.user_id)?.email}
                    </TableCell>
                    <TableCell>
                      <code>{assignment.user_id}</code>
                    </TableCell>
                    <TableCell>
                      <Box style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                        {!subscription?.status || !SUB_ACTIVE_STATUSES.includes(subscription?.status) && (
                          <Tooltip
                            title={`Subscription not active: status: '${subscription?.status}'`}
                          >
                            <SubscriptionWarningIcon color='error' />
                          </Tooltip>
                        )}
                        {subscription?.name ?? ''}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <code>{assignment.subscription_ids?.[0] ?? 'unknown'}</code>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color='primary'
                        onClick={() => removeLicenseAssignment(assignment.id ?? '')}
                        size="large"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>

        <Box mt={6} style={{ display: 'flex', width: '100%', gap: '1em' }}>
          <Box style={{ width: '40%' }}>
            <InputLabel id='license-assignment-user'>User</InputLabel>
            <Select
              variant="standard"
              labelId='license-assignment-user'
              onChange={(event) => setAssignee(event.target.value as string)}
              value={assignee}
              fullWidth
            >
              {membersWithoutLicenseAssigned.map((member) => (
                <MenuItem value={member.id} key={member.id}>
                  {member.name} ({member.email})
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box style={{ width: '40%' }}>
            <InputLabel>Subscription</InputLabel>
            <Select
              variant="standard"
              onChange={(event) => setTargetSub(event.target.value as string)}
              value={targetSub}
              fullWidth
            >
              {activeSubscriptions.map(sub => (
                <MenuItem value={sub.id} key={sub.id}>{sub.name} ({sub.id})</MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            variant='outlined'
            onClick={() => {
              if (assignee && targetSub) {
                assignLicense(assignee, targetSub);
                setAssignee('');
                setTargetSub('');
              }
            }}
            disabled={!(assignee && targetSub)}
            size='small'
          >Assign</Button>
        </Box>
      </CardContent>
    </Card>
  );
};
