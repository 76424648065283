import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { BackupSelection } from '@localstack/types';
import { formatDate, getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type BackupSelectionsTableProps = {
  selections: BackupSelection[];
  loading?: boolean;
  onViewSelection?: (selection: BackupSelection) => void;
};

export const BackupSelectionsTable = ({
  selections,
  loading,
  onViewSelection,
}: BackupSelectionsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Backup')}
    entry="GetBackupSelectionOutput"
    idAttribute="SelectionId"
    rows={selections}
    loading={loading}
    filterColumns={['BackupSelection', 'SelectionId', 'CreationDate']}
    externalFields={{
      BackupSelection: (row: BackupSelection) => (
        <Link onClick={() => onViewSelection && onViewSelection(row)} underline="hover">
          {row.BackupSelection?.SelectionName}
        </Link>
      ),
      CreationDate: (params) => <>{formatDate(params.CreationDate as Date)}</>,
    }}
  />
);
