import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, RDSClusterDetails } from '@localstack/ui';

import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { RDSProps } from './types';

export const RDSDBCluster = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_RDS_ROUTES,
}: RDSProps): ReactElement => {
  const { goto } = useRoutes();
  const { clusterId } = useParams<'clusterId'>();

  const { data: clusters } = useAwsGetter(
    'RDS',
    'describeDBClusters',
    [{ DBClusterIdentifier: clusterId }],
    { clientOverrides },
  );
  const cluster = clusters?.DBClusters?.find((inst) => inst.DBClusterIdentifier === clusterId);

  return (
    <Layout
      documentTitle="RDS: Cluster Details"
      title={
        <Box>
          <Typography variant="h4">Cluster Details</Typography>
          <Breadcrumbs
            mappings={[
              ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
              ['Databases', () => goto(routes.RESOURCES_RDS_DATABASES)],
              [`${clusterId} (${cluster?.DBClusterIdentifier})`, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <RDSClusterDetails cluster={cluster} />
        </CardContent>
      </Card>
    </Layout>
  );
};
