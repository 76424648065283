import { ReactElement } from 'react';
import { Controller, Control, ControllerProps } from 'react-hook-form';
import MuiPhoneNumber, { MuiPhoneNumberProps } from 'material-ui-phone-number';

const PHONE_REGEX = /^\+[()0-9+ -]+$/;

const fixPhoneValue = (value: Optional<string>) => {
  if (!PHONE_REGEX.test(value || '')) return null; // null is important!
  return value;
};

type MuiPhoneNumberPropsWithoutOnChange = Omit<MuiPhoneNumberProps, 'onChange'>;

type Props = MuiPhoneNumberPropsWithoutOnChange & Omit<ControllerProps, 'control' | 'render'> & { 
    control: Control<any> // eslint-disable-line
}

export const ControlledPhoneField = ({ control, ...rest }: Props): ReactElement => (
  <Controller
    {...rest}
    control={control}
    render={({ field, fieldState }) => (
      <MuiPhoneNumber
        {...rest}
        {...field}
        value={fixPhoneValue(field.value)}
        defaultCountry='us'
        defaultValue={null}
        error={fieldState.isTouched && Boolean(fieldState.error)}
        helperText={fieldState.isTouched && fieldState.error?.message}
      />
    )}
  />
);

export default ControlledPhoneField;
