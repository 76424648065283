import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { ProductType } from '@localstack/types';

import CiIcon from './icons/ci.svg';
import ProIcon from './icons/pro.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  icon: {
    fill: theme.palette.text.primary,
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  primary: {
    fill: theme.palette.primary.main,
  },
  contrastText: {
    fill: theme.palette.primary.contrastText,
  },
}));

const ICONS_MAP = {
  'seats': ProIcon,
  'ci_usage': CiIcon,
  'pod_usage': CiIcon,
};

type Props = {
  className?: string;
  productType: ProductType;
  color?: 'default' | 'primary' | 'contrastText';
  size?: 'medium' | 'large';
};

export const ProductIcon = ({ className, productType, color = 'default', size = 'medium' }: Props): ReactElement => {
  const classes = useStyles();
  const Icon = ICONS_MAP[productType.toString().toLowerCase() as keyof typeof ICONS_MAP] || ProIcon;

  return (
    <Icon
      className={classnames(className, classes.icon, {
        [classes.large]: size === 'large',
        [classes.primary]: color === 'primary',
        [classes.contrastText]: color === 'contrastText',
      })}
    />
  );
};

export default ProductIcon;
