import { ReactElement } from 'react';
import { useRoutes, useAwsGetter } from '@localstack/services';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, Typography } from '@mui/material';
import { Breadcrumbs, SystemParameterDetails } from '@localstack/ui';

import { DEFAULT_SYSTEMPARAMETERS_ROUTES } from '@localstack/constants/src';

import { SystemParametersProps } from './types';

export const SystemParameter = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_SYSTEMPARAMETERS_ROUTES,
}: SystemParametersProps): ReactElement => {
  const { goto } = useRoutes();

  const { name: rawName } = useParams<'name'>();

  const name = decodeURIComponent(rawName as string);
  const { data: parameter } = useAwsGetter(
    'SSM',
    'getParameter',
    [{ Name: name, WithDecryption: true }],
    { clientOverrides });

  return (
    <Layout
      documentTitle="Parameter Details"
      title={
        <Box>
          <Typography variant="h4">Parameter Details</Typography>
          <Breadcrumbs
            mappings={[
              ['SSM', () => goto(routes.RESOURCES_SYSTEM_PARAMETERS)],
              [name, null],
            ]}
          />
        </Box>
      }
    >
      <Card>
        <CardContent>
          <SystemParameterDetails parameter={parameter?.Parameter} />
        </CardContent>
      </Card>
    </Layout>
  );
};
