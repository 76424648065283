import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { ECSCluster } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export type ECSClustersTableProps = {
  selectable?: boolean;
  loading?: boolean;
  clusters: ECSCluster[];
  onViewCluster?: (cluster: ECSCluster) => unknown;
  onSelect?: (names: string[]) => void;
};

export const ECSClustersTable = ({
  clusters,
  selectable = true,
  loading,
  onViewCluster,
  onSelect,
}: ECSClustersTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('ECS')}
    entry="Cluster"
    idAttribute="clusterName"
    rows={clusters}
    selectable={selectable}
    onSelect={onSelect}
    order={['clusterName', 'clusterArn']}
    loading={loading}
    externalFields={{
      clusterName: (row) => (
        <Link onClick={() => onViewCluster && onViewCluster(row)} underline="hover">
          {row.clusterName}
        </Link>
      ),
    }}
  />
);
