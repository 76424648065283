import { ReactElement } from 'react';
import { ProgressButton } from '@localstack/ui';
import { FormState } from 'react-hook-form';
import { LocalStackChaosExperiment } from '@localstack/types';

import { isExperimentRunning } from '../utils';

import { WrapInToolTip } from './WrapInTooltip';


type UpdateExperimentButtonProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formState: FormState<any>
  alert: Optional<string>;
  experimentState: LocalStackChaosExperiment;
}
export const UpdateExperimentButton = ({ formState, alert, experimentState }
  : UpdateExperimentButtonProps): ReactElement => {
  const { isSubmitting, isDirty } = formState;
  return (
    <>
      {isDirty && isExperimentRunning(experimentState) &&
        <WrapInToolTip title={alert}>
          <span>
            <ProgressButton
              color="primary"
              variant="outlined"
              loading={isSubmitting}
              type="submit"
              disabled={!!alert}
            >
              Update Experiment
            </ProgressButton>
          </span>
        </WrapInToolTip>}
    </>
  );
};
