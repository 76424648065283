import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useAwsEffect, useRoutes, useAwsGetter } from '@localstack/services';
import { CreateCloudFrontDistributionRequest, UpdateDistributionParams } from '@localstack/types';
import { Breadcrumbs, CloudFrontDistributionForm, ProgressButton } from '@localstack/ui';

import { CloudFrontProps } from './types';

export const CloudFrontDistributionCreate = ({
  Layout,
  clientOverrides,
  routes,
}: CloudFrontProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();

  const { data: distributions } = useAwsGetter(
    'CloudFront',
    'getDistribution',
    [{ Id: id }],
    { clientOverrides },
  );

  const { createDistribution, updateDistribution, isLoading } = useAwsEffect(
    'CloudFront',
    ['createDistribution', 'updateDistribution'],
    { revalidate: ['listDistributions', 'getDistribution'], clientOverrides },
  );

  const handleCreate = async (data: CreateCloudFrontDistributionRequest) => {
    if (await createDistribution(data)) {
      goto(routes.RESOURCES_CLOUDFRONT_DISTRIBUTIONS);
    }
  };

  const handleUpdate = async (data: UpdateDistributionParams) => {
    if (await updateDistribution(data)) {
      goto(routes.RESOURCES_CLOUDFRONT_DISTRIBUTION, { id });
    }
  };

  return (
    <Layout
      documentTitle={`${id ? 'Update' : 'Create'} Distribution`}
      title={
        <Box>
          <Typography variant="h4">{id ? 'Update' : 'Create'} Distribution</Typography>
          <Breadcrumbs
            mappings={[
              ['Distributions', () => goto(routes.RESOURCES_CLOUDFRONT_DISTRIBUTIONS)],
              [id, () => goto(routes.RESOURCES_CLOUDFRONT_DISTRIBUTION, { id })],
              [id ? 'Update' : 'Create Distribution', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateCloudFrontDistribution"
          variant="outlined"
          color="primary"
          loading={isLoading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <CloudFrontDistributionForm
            distribution={distributions?.Distribution}
            onCreate={handleCreate}
            onUpdate={handleUpdate}
            clientOverrides={clientOverrides}
            formId="CreateCloudFrontDistribution"
            loading={isLoading}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateCloudFrontDistribution"
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
