import { useState, useCallback, ReactElement, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Box, CardActions, Typography } from '@mui/material';
import { RDSResultsTable, CodeEditor, ProgressButton, Breadcrumbs } from '@localstack/ui';
import { useAwsGetter, useRoutes } from '@localstack/services';

import { DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { InstanceNavTabs } from './components';

import { RDSProps } from './types';

const PAGE_SIZE = 50;

export const RDSDBInstanceQuery = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_RDS_ROUTES,
}: RDSProps): ReactElement => {
  const { goto } = useRoutes();
  const { instanceId } = useParams<'instanceId'>();

  const [statement, setStatement] = useState('SHOW TABLES');
  const [statementToExecute, setStatementToExecute] = useState<string | undefined>(undefined);

  const { data: createdSecret } = useAwsGetter(
    'SecretsManager',
    'createSecret',
    [{ Name: 'RDSTestingSecretValue' }],
    { silentErrors: true, clientOverrides },
  );

  const { data: existingSecret } = useAwsGetter(
    'SecretsManager',
    'describeSecret',
    [{ SecretId: 'RDSTestingSecretValue' }],
    { silentErrors: true, clientOverrides },
  );

  const rdsSecret = createdSecret ?? existingSecret;

  const { data: instances } = useAwsGetter(
    'RDS',
    'describeDBInstances',
    [{ DBInstanceIdentifier: instanceId }],
    { clientOverrides },
  );

  const instance = instances?.DBInstances?.find((inst) => inst.DBInstanceIdentifier === instanceId);

  const { data: result, isLoading } = useAwsGetter(
    'RDSDataService',
    'executeStatement',
    [{
      resourceArn: instance?.DBInstanceArn,
      secretArn: rdsSecret?.ARN,
      sql: statementToExecute,
      database: instance?.DBName,
    }],
    { defaultValue: {}, clientOverrides },
  );

  const handleExecuteStatement = useCallback((e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // we are adding spaces here to re-trigger query execution even if the sql statement hasn't changed
    setStatementToExecute(statement + ' '.repeat(Math.random() * 100));
  }, [statement]);

  return (
    <>
      <Layout
        documentTitle="Instance Query"
        tabs={<InstanceNavTabs instanceId={instanceId as string} routes={routes} />}
        title={
          <Box>
            <Typography variant="h4">Instance Query</Typography>
            <Breadcrumbs
              mappings={[
                ['RDS', () => goto(routes.RESOURCES_RDS_DATABASES)],
                ['Databases', () => goto(routes.RESOURCES_RDS_DATABASES)],
                [`${instanceId} (${instance?.DBName})`, () => goto(routes.RESOURCES_RDS_INSTANCE, { instanceId })],
                ['Query', null],
              ]}
            />
          </Box>
        }
      >
        <>
          <Card variant="outlined">
            <CardContent>
              <form id="statement" onSubmit={handleExecuteStatement}>
                <CodeEditor
                  language="sql"
                  setValue={setStatement}
                  value={statement ?? ''}
                />
              </form>
            </CardContent>
            <CardActions>
              <ProgressButton
                type="submit"
                form="statement"
                color="primary"
                variant="outlined"
                loading={isLoading}
              >
                Execute
              </ProgressButton>
            </CardActions>
          </Card>
          <Box mt={3}>
            <Card>
              <RDSResultsTable
                pageSize={PAGE_SIZE}
                results={result}
                loading={isLoading}
              />
            </Card>
          </Box>
        </>
      </Layout>
    </>
  );
};
