import { ReactElement } from 'react';

type WrapInComponentProps = {
	wrapperFn: (children: ReactElement) => ReactElement,
	children: ReactElement<unknown, string>,
	wrap?: boolean,
  }
export const WrapInComponent: React.FC<WrapInComponentProps> = ({ wrapperFn, children, wrap }) =>
  wrap ? wrapperFn(children): children;

