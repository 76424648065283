import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { CloudWatchMetricAlarm } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface MonitoringAlarmsTableProps {
  alarms: CloudWatchMetricAlarm[];
  loading?: boolean;
  selectable?: boolean;
  onViewAlarm?: (metric: CloudWatchMetricAlarm) => unknown;
  onSelect?: (ids: string[]) => unknown;
}

export const MonitoringAlarmsTable = ({
  alarms,
  loading,
  selectable,
  onViewAlarm,
  onSelect,
}: MonitoringAlarmsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('CloudWatch')}
    entry="MetricAlarm"
    idAttribute="AlarmName"
    rows={alarms}
    selectable={selectable}
    onSelect={onSelect}
    order={['AlarmName', 'StateValue', 'StateReason']}
    loading={loading}
    externalFields={{
      AlarmName: (row) => (
        <Link onClick={() => onViewAlarm && onViewAlarm(row)} underline="hover">
          {row.AlarmName}
        </Link>
      ),
    }}
  />
);
