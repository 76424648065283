import { Palette, alpha } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';


export default (palette: Palette): MuiThemeComponentsType['MuiButton'] => ({
  styleOverrides: {
    root: {
      borderRadius: 8,
    },
  },
  variants: [
    {
      props: { color: 'inherit', variant: 'contained' },
      style: {
        backgroundColor: palette.grey[100],
        '&:hover': {
          backgroundColor: palette.grey[400],
        },
        color: 'rgba(0, 0, 0, 0.87)',
      },
    },
    {
      props: { color: 'inherit', variant: 'outlined' },
      style: {
        color: palette.text.primary,
        borderColor:
          palette.mode === 'light'
            ? 'rgba(0, 0, 0, 0.23)'
            : 'rgba(255, 255, 255, 0.23)',
        '&.Mui-disabled': {
          border: `1px solid ${palette.action.disabledBackground}`,
        },
        '&:hover': {
          borderColor:
            palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.23)'
              : 'rgba(255, 255, 255, 0.23)',
          backgroundColor: alpha(
            palette.text.primary,
            palette.action.hoverOpacity,
          ),
        },
      },
    },
  ],
  defaultProps: {
    color: 'inherit',
  },
});
