import { DEFAULT_MWAA_ROUTES } from '@localstack/constants';
import { ReactElement, useEffect, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { MWAAEnvironmentsTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';
import { GetEnvironmentOutput } from '@localstack/types';

import { MWAAProps } from './types';

export const MWAAEnvironments = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_MWAA_ROUTES,
}: MWAAProps): ReactElement => {
  const { goto } = useRoutes();
  const [envs, setEnvs] = useState<GetEnvironmentOutput[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: environments, isLoading, mutate } = useAwsGetter(
    'MWAA',
    'listEnvironments',
    [],
    { clientOverrides },
  );
  const { getEnvironment } = useAwsEffect('MWAA', ['getEnvironment'], { clientOverrides });
  useEffect(() => {
    setLoading(true);
    (async () => {
      setEnvs(await Promise.all(environments?.Environments?.map(
        async envName => getEnvironment({ Name: envName })) || []));
    })();
    setLoading(false);
  }, [environments]);
  const { deleteEnvironment } = useAwsEffect('MWAA', ['deleteEnvironment'], {
    revalidate: ['listEnvironments'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteEnvironment({ Name: id }),
      ),
    );
  };
  return (
    <Layout
      title={<PageTitle title="MWAA Environments" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_MWAA_ENVIRONMENT_CREATE)}>
            Create Environment
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Environment(s)?`}
              onClick={handleRemove}
              text="Selected Environments will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <MWAAEnvironmentsTable
          environments={envs?.map(env => env?.Environment || {})}
          onViewEnvironment={(id: string) =>
            goto(routes.RESOURCES_MWAA_ENVIRONMENT, { id })
          }
          loading={isLoading || loading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
