import { MuiThemeComponentsType } from '../types';

export default (): MuiThemeComponentsType['MuiCardActions'] => ({
  styleOverrides: {
    root: {
      justifyContent: 'flex-end',
      padding: '8px 16px',
    },
  },
});
