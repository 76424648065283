/* eslint-disable max-len */

import { SERVICE_NAME } from '@localstack/types';

import { PATH_PREFIX_RESOURCES } from './base';

const STEPFUNCTIONS_BASE_ROUTE = `${PATH_PREFIX_RESOURCES}/${SERVICE_NAME.STEPFUNCTIONS}`;

export const DEFAULT_STEPFUNCTIONS_ROUTES: Record<string,string> = {
  RESOURCES_STEPFUNCTIONS: `${STEPFUNCTIONS_BASE_ROUTE}`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINES: `${PATH_PREFIX_RESOURCES}/stepfunctions/state-machines`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_CREATE: `${PATH_PREFIX_RESOURCES}/stepfunctions/state-machine/create`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_UPDATE: `${PATH_PREFIX_RESOURCES}/stepfunctions/state-machine/:stateMachineArn/update`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE: `${PATH_PREFIX_RESOURCES}/stepfunctions/state-machine/:stateMachineArn`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTIONS: `${STEPFUNCTIONS_BASE_ROUTE}/state-machine/:stateMachineArn/executions`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION_START: `${STEPFUNCTIONS_BASE_ROUTE}/state-machine/:stateMachineArn/executions/start`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION: `${STEPFUNCTIONS_BASE_ROUTE}/state-machine/:stateMachineArn/executions/:executionArn`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_EXECUTION_EVENTS: `${STEPFUNCTIONS_BASE_ROUTE}/state-machine/:stateMachineArn/executions/:executionArn/events`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION: `${STEPFUNCTIONS_BASE_ROUTE}/state-machine/:stateMachineArn/definition`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION_SCHEMA: `${STEPFUNCTIONS_BASE_ROUTE}/state-machine/:stateMachineArn/definition/schema`,
  RESOURCES_STEPFUNCTIONS_STATE_MACHINE_DEFINITION_FLOWCHART: `${STEPFUNCTIONS_BASE_ROUTE}/state-machine/:stateMachineArn/definition/flowchart`,
};
