import { ReactElement } from 'react';
import { CreateSGMEndpointConfigRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';

import { S3PathPicker } from '../../../form/S3PathPicker';
import { RelatedResourcePicker } from '../../../form';

import { MagicForm } from '../../magic/MagicForm';

export type CreateSGMEndpointConfigProps = {
  loading?: boolean;
  formId: string;
  clientOverrides?: AwsClientOverrides;
  onSubmit: (data: CreateSGMEndpointConfigRequest) => unknown;
}

export const SGMEndpointConfigCreate = ({
  loading,
  formId,
  clientOverrides,
  onSubmit,
}: CreateSGMEndpointConfigProps): ReactElement => {

  // eslint-disable-next-line arrow-body-style
  const submitHandler = async (data: CreateSGMEndpointConfigRequest) => {
    return onSubmit(data);
  };

  return (
    <>
      <MagicForm
        schema={getSchema('SGM')}
        formId={formId}
        entry="CreateEndpointConfigInput"
        loading={loading}
        onSubmit={(data: CreateSGMEndpointConfigRequest) => submitHandler(data)}
        externalFields={{
          '^DataCaptureConfig.DestinationS3Uri$': (control, fieldName, required) => (
            <S3PathPicker
              control={control}
              fieldName={fieldName}
              required={required}
              clientOverrides={clientOverrides}
            />
          ),
          '^ProductionVariants\\.\\d+\\.ModelName$': (control, fieldName, required) => (
            <RelatedResourcePicker
              required={required}
              control={control}
              fieldName={fieldName} entityName='ModelName'
              client='SGM'
              method='listModels'
              arrayKeyName='Models'
              property='ModelName'
              clientOverrides={clientOverrides}
            />
          ),
        }}
      />
    </>
  );
};
