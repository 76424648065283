import { ReactElement } from 'react';
import { StateMachineExecution } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicDetails } from '../../magic/MagicDetails';
import { CodeSnippetViewer } from '../../../display/CodeSnippetViewer';

export interface StateExecutionDetailsProps {
  execution?: Optional<Partial<StateMachineExecution>>;
}

export const StateExecutionDetails = ({ execution }: StateExecutionDetailsProps): ReactElement => (
  <>
    <MagicDetails
      data={execution}
      schema={getSchema('StepFunctions')}
      entry="DescribeExecutionOutput"
      title="Execution Details"
      externalFields={{
        '^input$': (data: string, fieldName: string) =>
          execution?.inputDetails?.included ? <CodeSnippetViewer data={data} fieldName={fieldName} /> : <></>,
        '^output$': (data: string, fieldName: string) =>
          execution?.outputDetails?.included ? <CodeSnippetViewer data={data} fieldName={fieldName} /> : <></>,
        '^inputDetails$': () => <></>,
        '^outputDetails$': () => <></>,
      }}
    />
  </>
);
