import { ReactElement } from 'react';
import { PutEventBridgeRuleRequest } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_IAM_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface EventBridgeRuleFormProps {
  rule?: Optional<Partial<PutEventBridgeRuleRequest>>;
  bus: string;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: PutEventBridgeRuleRequest) => unknown;
}

export const EventBridgeRuleForm = ({
  rule,
  loading,
  formId,
  bus,
  clientOverrides,
  onCreate,
}: EventBridgeRuleFormProps): ReactElement => (
  <MagicForm
    data={rule || {
      'EventBusName': bus,
    }}
    schema={getSchema('EventBridge')}
    loading={loading}
    entry="PutRuleRequest"
    formId={formId}
    onSubmit={(data: PutEventBridgeRuleRequest) => onCreate(data)}
    fieldConditions={{
      '^EventBusName$': false,
      '^Name': !rule,
    }}
    externalFields={{
      '^RoleArn$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='IAM' method='listRoles' arrayKeyName='Roles' property='Arn'
          fieldName={fieldName} entityName='IAM Role'
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
  />
);
