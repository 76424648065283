import { ReactElement } from 'react';
import classnames from 'classnames';
import { Theme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const ZIGZAG_SIZE = 16; // should be dividable by 4

const useStyles = makeStyles((theme: Theme) => createStyles({
  divider: {
    width: '100%',
  },
  zigzag: {
    height: `${ZIGZAG_SIZE}px`,
    '& > div:nth-child(1)': {
      height: `${ZIGZAG_SIZE / 2}px`,
      backgroundSize: `${ZIGZAG_SIZE / 2}px ${ZIGZAG_SIZE / 2}px`,
      background: `
        linear-gradient(45deg, ${theme.palette.divider} 25%, transparent 25%) 0 0,
        linear-gradient(-45deg, ${theme.palette.divider} 25%, transparent 25%) ${ZIGZAG_SIZE}px 0
      `,
    },
    '& > div:nth-child(2)': {
      height: `${ZIGZAG_SIZE / 2}px`,
      backgroundSize: `${ZIGZAG_SIZE / 2}px ${ZIGZAG_SIZE / 2}px`,
      background: `
        linear-gradient(135deg, ${theme.palette.divider} 25%, transparent 25%) -${ZIGZAG_SIZE / 4}px 0,
        linear-gradient(-135deg, ${theme.palette.divider} 25%, transparent 25%) -${ZIGZAG_SIZE / 4}px 0
      `,
    },
  },
}));

export interface DecorativeDividerProps {
  pattern?: 'zigzag';
}

export const DecorativeDivider = ({ pattern = 'zigzag' }: DecorativeDividerProps): ReactElement => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.divider, { [classes.zigzag]: pattern === 'zigzag' })}>
      <div />
      <div />
    </div>
  );
};
