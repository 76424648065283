import { ReactElement, useState, useCallback } from 'react';
import { useRoutes, useAwsEffect, useAwsGetter, useAwsBatchIterator } from '@localstack/services';
import { Card, Button } from '@mui/material';

import {
  Dropdown,
  ConfirmableButton,
  ECSClustersTable,
  PageTitle,
} from '@localstack/ui';

import { DEFAULT_ECS_ROUTES } from '@localstack/constants';

import { ECSProps } from './types';

import { MainNavTabs } from './components';

export const ECSClusters = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ECS_ROUTES,
}: ECSProps): ReactElement => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: clusterArns, isLoading, mutate } = useAwsGetter(
    'ECS',
    'listClusters',
    [],
    { clientOverrides },
  );
  const { describeClusters } = useAwsEffect(
    'ECS',
    ['describeClusters'],
    { clientOverrides },
  );

  const clusters = useAwsBatchIterator(
    clusterArns?.clusterArns,
    async (arn) => (await describeClusters({ clusters: [arn] })).clusters ?? [],
  );

  const { deleteCluster } = useAwsEffect(
    'ECS',
    ['deleteCluster'],
    { revalidate: ['listClusters', 'describeClusters'], clientOverrides },
  );

  const handleDeleteSelected = useCallback(async () => {
    const promises = selectedIds.map((cluster) => deleteCluster({ cluster }));
    await Promise.all(promises);
  }, [selectedIds]);

  return (
    <Layout
      title={
        <PageTitle
          title="ECS Clusters"
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ECS_CLUSTER_CREATE)}>
            Create Cluster
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Cluster(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Clusters will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <ECSClustersTable
          clusters={clusters}
          loading={isLoading}
          onSelect={setSelectedIds}
          onViewCluster={({ clusterName }) => goto(routes.RESOURCES_ECS_CLUSTER, { clusterName })}
        />
      </Card>
    </Layout>
  );
};
