import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';

import { CloudPod } from '@localstack/types';

import { retrieveAuthToken } from '@localstack/web/src/util/storage';

import { useAwsEffect } from '../aws';

interface InjectPodState {
  inject: (pod: CloudPod, callback?: () => void) => void,
  injected: boolean;
  isLoading: boolean;
  isError: boolean;
  error?: Error | AxiosError;
}

export const useInjectPodState = (): InjectPodState => {
  const { loadPod, isLoading: isPodInjecting, hasError: hasPodInjectError, error: podInjectError } = useAwsEffect(
    'LocalStack',
    ['loadPod'],
    { silentErrors: true },
  );

  const [injected, setInjected] = useState(false);

  const inject = useCallback(
    async (pod: CloudPod, callback?: () => void) => {
      const authToken = retrieveAuthToken();
      await loadPod(pod.pod_name, null, authToken?.token);
      setInjected(true);
      if (callback) callback();
    },
    [],
  );

  return {
    inject,
    injected,
    isLoading: isPodInjecting,
    isError: hasPodInjectError,
    error: podInjectError || undefined,
  };
};

