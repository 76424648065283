import { ReactElement } from 'react';
import { CloudWatchLogGroup } from '@localstack/types';
import { Link } from '@mui/material';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../magic/MagicTable';

export interface CloudWatchLogGroupsTableProps {
  selectable?: boolean;
  loading?: boolean;
  logGroups: CloudWatchLogGroup[];
  onViewLogGroup?: (user: CloudWatchLogGroup) => unknown;
  onSelect?: (names: string[]) => void;
}

export const CloudWatchLogGroupsTable = ({
  logGroups,
  selectable = true,
  loading,
  onViewLogGroup,
  onSelect,
}: CloudWatchLogGroupsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('CloudWatchLogs')}
    entry="LogGroup"
    idAttribute="logGroupName"
    rows={logGroups}
    selectable={selectable}
    onSelect={onSelect}
    order={['logGroupName']}
    loading={loading}
    externalFields={{
      logGroupName: (row) => (
        <Link onClick={() => onViewLogGroup && onViewLogGroup(row)} underline="hover">
          {row.logGroupName}
        </Link>
      ),
    }}
  />
);
