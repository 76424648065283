import { DEFAULT_CODECOMMIT_ROUTES } from '@localstack/constants';
import { ReactElement, useState } from 'react';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import { CodeCommitRepositoriesTable, ConfirmableButton, PageTitle, Dropdown } from '@localstack/ui';

import { CodeCommitProps } from './types';

export const CodeCommitRepositories = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_CODECOMMIT_ROUTES,
}: CodeCommitProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { data: repositories, isLoading, mutate } = useAwsGetter(
    'CodeCommit',
    'listRepositories',
    [],
    { clientOverrides },
  );
  const { deleteRepository } = useAwsEffect('CodeCommit', ['deleteRepository'], {
    revalidate: ['listRepositories'],
    clientOverrides,
  });
  const handleRemove = async () => {
    await Promise.all(
      selectedIds.map(async (id) =>
        deleteRepository({ repositoryName: id }),
      ),
    );
  };
  return (
    <Layout
      documentTitle="CodeCommit: Repositories"
      title={<PageTitle title="CodeCommit Repositories" onMutate={mutate} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_CODECOMMIT_REPOSITORY_CREATE)}>
            Create Repository
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selectedIds.length === 0 || isLoading}
              title={`Delete ${selectedIds.length} Repository(s)?`}
              onClick={handleRemove}
              text="Selected Repositories will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <CodeCommitRepositoriesTable
          repositories={repositories || {}}
          onViewRepository={(id: string) =>
            goto(routes.RESOURCES_CODECOMMIT_REPOSITORY, { id })
          }
          loading={isLoading}
          onSelect={setSelectedIds}
        />
      </Card>
    </Layout>
  );
};
