import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { AppSyncType } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type AppSyncTypesTableProps = {
  selectable?: boolean;
  loading?: boolean;
  types: AppSyncType[];
  onViewType?: (type: AppSyncType) => unknown;
  onSelect?: (names: string[]) => void;
};

export const AppSyncTypesTable = ({
  types,
  selectable = true,
  loading,
  onViewType,
  onSelect,
}: AppSyncTypesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('AppSync')}
    entry="Type"
    idAttribute="name"
    rows={types}
    selectable={selectable}
    onSelect={onSelect}
    order={['name', 'arn']}
    loading={loading}
    filterColumns={['name', 'arn', 'description']}
    externalFields={{
      name: (row) => (
        <Link onClick={() => onViewType && onViewType(row)} underline="hover">
          {row.name}
        </Link>
      ),
    }}
  />
);
