import { ReactElement } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { LocalStackEvent } from '@localstack/types';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Cancel, Check } from '@mui/icons-material';

const useStyles = makeStyles(() => createStyles({
  statusIndicator: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
  },
}));

type Props = {
  logEvents?: LocalStackEvent[];
  isProgressing?: boolean;
  hasError?: boolean;
}

export const StatusIndicator = ({ logEvents, isProgressing, hasError }: Props): ReactElement => {
  const classes = useStyles();
  const filteredEvents = logEvents?.filter((log) => log.event === 'status');
  const errorEvents = logEvents?.filter((log) => log.event === 'error' || log.event === 'exception');
  const hasLogError = !!errorEvents?.length || hasError;

  const lastErrorMessage = errorEvents?.[errorEvents.length - 1]?.message ?? 'An Error occured';
  const lastStatusMessage = filteredEvents?.[filteredEvents.length - 1]?.message ?? 'Success';
  
  return (
    <Box className={classes.statusIndicator}>
      { isProgressing && !hasLogError && <CircularProgress size={30} /> }
      { hasLogError && <Cancel color='primary'/> }
      { !hasLogError && !isProgressing && <Check color='primary'/>}
      <Typography className={classes.status} variant='body2'>
        { hasLogError ? lastErrorMessage : lastStatusMessage }
      </Typography>
    </Box>
  );
};

