import { useCallback, ReactElement, useState } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { LoadingFragment, LambdaLayerVersionsTable, PageTitle, Dropdown, ConfirmableButton } from '@localstack/ui';
import { useAwsEffect, useAwsGetter, useRoutes } from '@localstack/services';

import { LambdaProps } from './types';
import { NavTabs } from './components/NavTabs';

export const LambdaLayerVersions = ({
  Layout,
  clientOverrides,
  routes,
}: LambdaProps): ReactElement => {
  const { goto } = useRoutes();
  const { layer: layerName } = useParams<'layer'>();
  const [selectedVersions, setSelectedVersions] = useState<string[]>([]);
  const { data: versions, isLoading: isLayerVersionsLoading, mutate } = useAwsGetter(
    'Lambda',
    'listLayerVersions',
    [{ LayerName: layerName }],
    { clientOverrides },
  );

  const { deleteLayerVersion, isLoading: isLayerVersionMutating } = useAwsEffect(
    'Lambda',
    ['deleteLayerVersion'],
    { revalidate: ['listLayerVersions'], clientOverrides },
  );

  const handleDeleteLayerVersions = useCallback(async () => {
    const promises = selectedVersions.map((VersionNumber: string) =>
      deleteLayerVersion({ LayerName: layerName ?? '', VersionNumber: parseInt(VersionNumber, 10) }));
    await Promise.all(promises);
  }, [selectedVersions]);

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="Lambda Layer Versions"
            onMutate={mutate}
          />
        }
        tabs={<NavTabs routes={routes} />}
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_LAMBDA_LAYER_NEW)}>
              Create
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedVersions.length === 0 || isLayerVersionMutating}
                title={`Remove ${selectedVersions.length} version(s)?`}
                onClick={handleDeleteLayerVersions}
                text="Selected Versions will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <CardContent>
            <LoadingFragment
              loading={isLayerVersionsLoading}
              arrayData={versions?.LayerVersions ?? []}
              variant="list"
              height={50}
              size={10}
            >
              <LambdaLayerVersionsTable
                loading={isLayerVersionsLoading}
                onSelectLayerVersion={setSelectedVersions}
                versions={versions?.LayerVersions ?? []}
              />
            </LoadingFragment>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
};
