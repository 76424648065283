import { ReactElement } from 'react';
import { RunEC2InstancesRequest } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { DEFAULT_EC2_VALUES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';

export interface EC2InstanceFormProps {
  loading?: boolean;
  formId?: string;
  onSubmit: (data: RunEC2InstancesRequest) => unknown;
}

export const EC2InstanceForm = ({
  loading,
  formId,
  onSubmit,
}: EC2InstanceFormProps): ReactElement => (
  <MagicForm
    schema={getSchema('EC2')}
    loading={loading}
    entry="RunInstancesRequest"
    formId={formId}
    defaultValues={DEFAULT_EC2_VALUES?.RunInstancesRequest?.input}
    onSubmit={(data: RunEC2InstancesRequest) => onSubmit(data)}
  />
);
