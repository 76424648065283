import { ReactElement } from 'react';
import { Link } from '@mui/material';
import { TimestreamTable } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type TimestreamTablesGridProps = {
  loading?: boolean;
  selectable?: boolean;
  entries: TimestreamTable[];
  onViewTable?: (table: TimestreamTable) => void;
  onSelect?: (names: string[]) => void;
};

export const TimestreamTablesGrid = ({
  entries,
  selectable = true,
  loading,
  onSelect,
  onViewTable,
}: TimestreamTablesGridProps): ReactElement => (
  <MagicTable
    schema={getSchema('TimestreamWrite')}
    entry="TableList"
    idAttribute="TableName"
    rows={entries}
    selectable={selectable}
    order={['TableName', 'CreationTime', 'LastUpdatedTime', 'TableStatus', 'Arn']}
    filterColumns={['TableName', 'CreationTime', 'LastUpdatedTime', 'TableStatus', 'Arn']}
    onSelect={onSelect}
    loading={loading}
    externalFields={{
      TableName: (row) => (
        <Link onClick={() => onViewTable && onViewTable(row)} underline="hover">
          {row.TableName}
        </Link>
      ),
    }}
  />
);
