import React, { ReactElement } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Fade,
  Grid,
  ListItem,
  Typography,
} from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import {
  SwapVert as RequestIcon,
  ExpandMore as ExpandMoreIcon,
  InfoOutlined as DeniedIcon,
} from '@mui/icons-material';

import { AwsServiceIcon, CodeSnippetViewer } from '@localstack/ui';

import { AggregatedPolices, IAMPolicyStreamConfig } from '@localstack/types';

import { SERVICE_CODES } from '@localstack/constants';

import { PolicyCard } from '../PolicyCard';

interface PolicyListItemProps {
  aggregatedPoliciesRequest: AggregatedPolices,
  updateExpandStatus: (isExpanded: boolean, requestId?: string) => void;
  policyStatusConfig: IAMPolicyStreamConfig,
}


const useStyles = makeStyles(() => createStyles({
  accordion: {
    border: 'none',
    boxShadow: 'none',
    padding: 0,
    width: '100%',
    '&::before': {
      display: 'none',
    },
  },
  accordionSummary: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconStyle: {
    display: 'block',
    borderRadius: 6,
    width: '32px',
    height: '32px',
  },
  dropdownCell: {
    width: '2em',
  },
  deniedChip: {
    marginLeft: '1em',
  },
}));

const PolicyListItem = ({
  aggregatedPoliciesRequest,
  updateExpandStatus,
  policyStatusConfig,
}: PolicyListItemProps): ReactElement => {
  const classes = useStyles();
  const { service, operation, parameters, id, allowed } = aggregatedPoliciesRequest.request;
  const expanded = aggregatedPoliciesRequest.isExpanded;
  const denied = !(allowed ?? true);
  const enforcesIam = (policyStatusConfig.enforce_iam && !policyStatusConfig.iam_soft_mode);
  const deniedText = enforcesIam ? 'IAM violation, call rejected' : 'IAM violation';
  return (
    <Fade key={aggregatedPoliciesRequest.request.id} in timeout={500}>
      <ListItem
        divider
      >
        <Accordion
          expanded={expanded}
          onChange={(_event, state) => updateExpandStatus(state, id)}
          className={classes.accordion}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            <Box alignItems='center' display='flex' width='100%'>
              <AwsServiceIcon code={service} size='large' />
              <Typography style={{ whiteSpace: 'normal' }} color={denied ? 'error' : undefined} variant='h6'>
                {SERVICE_CODES[service as keyof typeof SERVICE_CODES] || service}.{operation}
              </Typography>
              {denied && (
                <Chip
                  icon={<DeniedIcon />}
                  className={classes.deniedChip}
                  size='small'
                  variant='outlined'
                  label={deniedText}
                />
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item sm={12} md={12} lg={6}>
                <Box alignItems='center' display='flex' width='100%'>
                  <RequestIcon
                    className={classes.iconStyle}
                  />
                  <Typography variant='subtitle1' style={{ lineHeight: '16px' }}>
                    Request Params
                  </Typography>
                </Box>
                <CodeSnippetViewer data={JSON.stringify(parameters)} />
              </Grid>
              <Grid item sm={12} md={12} lg={6}>
                <Grid container spacing={2} direction='column'>
                  {aggregatedPoliciesRequest.policies.map(policy => (
                    <Grid item>
                      <PolicyCard policy={policy} />
                    </Grid>
                  ),
                  )}
                </Grid>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </ListItem>
    </Fade>
  );
};

export const MemoizedPolicyListItem = React.memo(PolicyListItem);
