import { ReactElement } from 'react';
import { RDSCluster, CreateClusterParams, UpdateClusterParams } from '@localstack/types';
import { AwsClientOverrides, getSchema } from '@localstack/services';
import { DEFAULT_IAM_ROUTES, DEFAULT_KMS_ROUTES, DEFAULT_RDS_ROUTES } from '@localstack/constants';

import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';
import { MagicForm } from '../../magic/MagicForm';

export interface RDSClusterFormProps {
  cluster?: Optional<Partial<RDSCluster>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateClusterParams) => unknown;
  onUpdate: (data: UpdateClusterParams) => unknown;
}

export const RDSClusterForm = ({
  cluster,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: RDSClusterFormProps): ReactElement => (
  <MagicForm
    data={cluster}
    schema={getSchema('RDS')}
    loading={loading}
    entry={cluster ? 'ModifyDBClusterMessage' : 'CreateDBClusterMessage'}
    formId={formId}
    fieldConditions={{
      '^DBClusterIdentifier': !cluster,
      '^MasterUserPassword': !cluster, // not supported in editing mode by LS atm
    }}
    externalFields={{
      '^(MonitoringRoleArn|DomainIAMRoleName)$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='IAM' method='listRoles' arrayKeyName='Roles' property='Arn'
          fieldName={fieldName} entityName='IAM Role'
          creationRoute={DEFAULT_IAM_ROUTES.RESOURCES_IAM_ROLE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^(KmsKeyId|PerformanceInsightsKMSKeyId)$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='KMS' method='listKeys' arrayKeyName='Keys' property='KeyArn'
          fieldName={fieldName} entityName='KMS Key'
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
      '^GlobalClusterIdentifier$': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='RDS' method='describeGlobalClusters' arrayKeyName='GlobalClusters' property='GlobalClusterIdentifier'
          fieldName={fieldName} entityName='Global Database'
          creationRoute={DEFAULT_RDS_ROUTES.RESOURCES_RDS_DATABASE_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={(data: CreateClusterParams) => {
      if (!cluster) return onCreate(data);
      return onUpdate({ ...data });
    }}
  />
);
