import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect, processIdAttributes } from '@localstack/services';
import { Button, Card } from '@mui/material';
import {
  ConfirmableButton,
  Dropdown,
  PageTitle,
  Route53HostedZonesRecordsTable,
} from '@localstack/ui';
import { Route53ResourceRecordSet } from '@localstack/types';
import { DEFAULT_ROUTE53_ROUTES } from '@localstack/constants';
import { useParams } from 'react-router-dom';

import { Route53Props } from './types';
import { processHostedZoneRecord } from './utils';

export const Route53HostedZoneRecords = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_ROUTE53_ROUTES,
}: Route53Props): ReactElement => {
  const { hostedZone } = useParams<'hostedZone'>();
  const [selected, setSelected] = useState<(string)[]>([]);
  const [deleteDisabled, setDeleteDisabled] = useState(false);

  const { goto } = useRoutes();

  const { data: records, isLoading, mutate } = useAwsGetter(
    'Route53',
    'listResourceRecordSets',
    [{ HostedZoneId: hostedZone }],
    { clientOverrides },
  );

  const { data: hostedZoneData } = useAwsGetter(
    'Route53', 'getHostedZone',
    [{ Id: hostedZone }], { clientOverrides });

  const { changeResourceRecordSets } = useAwsEffect(
    'Route53',
    ['changeResourceRecordSets'],
    { revalidate: ['listResourceRecordSets'], clientOverrides },
  );


  const handleDeleteObjects = useCallback(
    async () => {
      if (!records) return;
      const changesList = selected.map(r => {
        const [recordName, recordType] = processIdAttributes(r);
        const record = records.ResourceRecordSets.find(
          item => item.Name === `${recordName}.` && item.Type === recordType,
        );
        if (!record?.ResourceRecords?.length) delete record?.ResourceRecords;
        return ({
          Action: 'DELETE',
          ResourceRecordSet: record as Route53ResourceRecordSet,
        });
      });
      await changeResourceRecordSets({
        HostedZoneId: hostedZone || '',
        ChangeBatch: {
          Changes: changesList,
        },
      });
    },
    [selected],
  );

  useEffect(() => {
    setDeleteDisabled(false);
    selected.forEach(record => {
      const [recordName] = processIdAttributes(record);
      if (hostedZoneData?.HostedZone.Name === `${recordName}.`) setDeleteDisabled(true);
    });
  }, [selected]);


  return (
    <Layout
      documentTitle="Route53: Records"
      title={
        <PageTitle
          title="Route53 Records"
          breadcrumbs={[
            ['Route53', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
            ['Hosted Zones', () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONES)],
            [hostedZone, () => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE, { hostedZone })],
            ['Records', () => null],
          ]}
          onMutate={mutate}
        />
      }
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORD_CREATE, { hostedZone })}>
            Create Record
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading || deleteDisabled}
              title={`Remove ${selected.length} table(s)?`}
              onClick={handleDeleteObjects}
              text="Selected Table(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <Route53HostedZonesRecordsTable
          records={records?.ResourceRecordSets ? records.ResourceRecordSets.map(
            r => processHostedZoneRecord(r),
          ) : []}
          loading={isLoading}
          onViewRecord={({ Name, Type }) => goto(routes.RESOURCES_ROUTE53_HOSTED_ZONE_RECORD, {
            hostedZone,
            record: processIdAttributes([Name, Type]),
          })}
          onSelect={setSelected}
        />
      </Card>
    </Layout>
  );
};
