import { ReactElement } from 'react';
import { LamdaLayerVersionsList } from '@localstack/types';
import { getSchema } from '@localstack/services';

import { MagicTable } from '../../../magic/MagicTable';

export type LambdaLayerVersionsTableProps = {
  loading?: boolean;
  selectable?: boolean;
  versions: LamdaLayerVersionsList;
  onSelectLayerVersion?: (versions: string[]) => void;
};

export const LambdaLayerVersionsTable = ({
  loading,
  selectable = true,
  versions,
  onSelectLayerVersion,
}: LambdaLayerVersionsTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Lambda')}
    entry="LayerVersionsList"
    idAttribute="Version"
    order={['Version']}
    filterColumns={['LayerVersionArn','Version','Description','CreatedDate','CompatibleRuntimes',
      'LicenseInfo', 'CompatibleArchitectures']}
    rows={versions}
    selectable={selectable}
    onSelect={onSelectLayerVersion}
    loading={loading}
  />
);
