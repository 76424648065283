import { ReactElement } from 'react';
import { useDelayedData } from '@localstack/services';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import classnames from 'classnames';

import {
  DataGrid as MuiDataGrid,
  GridColDef,
  DataGridProps,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';

export type { GridColDef, DataGridProps, GridRenderCellParams, GridValueFormatterParams };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      border: 'none',
      borderColor: theme.palette.divider,
      '& .MuiDataGrid-iconSeparator': {
        color: theme.palette.divider,
      },
      '& .MuiDataGrid-overlay': {
        zIndex: '3',
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.common.white,
      },
    },
    tableDisableSorting: {
      '& .MuiDataGrid-columnHeader--sortable': {
        pointerEvents: 'none',
      },
      '& .MuiDataGrid-iconButtonContainer': {
        display: 'none',
      },
    },
  }),
);

export interface DelayedDataGridProps extends DataGridProps {
  /**
   * Whether or not to use useDelayedData to get around a bug in MUI
   * with rows not rendering due to a race condition.
   * Defaults to true.
   */
  useDelayedDataHook?: boolean;
}

export const DataGrid = ({
  useDelayedDataHook = true,
  className,
  rows,
  disableColumnMenu,
  ...rest
}: DelayedDataGridProps): ReactElement => {
  const classes = useStyles();
  const delayedRows = useDelayedDataHook ? useDelayedData(rows, []) : rows;
  return (
    <MuiDataGrid
      className={classnames(classes.table, { [classes.tableDisableSorting]: disableColumnMenu }, className)}
      rows={delayedRows}
      disableColumnMenu={disableColumnMenu}
      {...rest}
    />
  );
};
