import { ReactElement } from 'react';
import { NavLink } from '@localstack/ui';
import { buildRoute } from '@localstack/services';

import { Route53Routes } from '../../types';

type MainNavTabsProps = {
  routes: Route53Routes;
}

export const MainNavTabs = ({ routes }: MainNavTabsProps): ReactElement => (
  <>
    <NavLink to={buildRoute(routes.RESOURCES_ROUTE53)}>
      Hosted Zones
    </NavLink>
    <NavLink to={buildRoute(routes.RESOURCES_ROUTE53RESOLVER)}>
      Resolver Endpoints
    </NavLink>
  </>
);

