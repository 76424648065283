import { ReactElement } from 'react';
import { GlueDatabase } from '@localstack/types';
import { getSchema } from '@localstack/services';
import { Link } from '@mui/material';

import { MagicTable } from '../../../magic/MagicTable';

export type GlueDatabasesTableProps = {
  databases: GlueDatabase[];
  selectable?: boolean;
  loading?: boolean;
  onSelect?: (names: string[]) => void;
  onViewDatabase?: (name: string) => void;
};

export const GlueDatabasesTable = ({
  databases,
  selectable = true,
  loading,
  onSelect,
  onViewDatabase,
}: GlueDatabasesTableProps): ReactElement => (
  <MagicTable
    schema={getSchema('Glue')}
    entry="Database"
    idAttribute="Name"
    rows={databases}
    selectable={selectable}
    onSelect={onSelect}
    loading={loading}
    order={['Name', 'Description', 'CatalogId', 'LocationUri', 'CreateTime']}
    externalFields={{
      Name: (row) => (
        <Link
          onClick={() => onViewDatabase && onViewDatabase(row.Name as string)}
          underline="hover"
        >
          {row.Name}
        </Link>
      ),
    }}
  />
);
