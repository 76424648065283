import { ReactElement } from 'react';
import { AwsClientOverrides, buildAgwPatches, getSchema } from '@localstack/services';
import { TextField } from '@mui/material';

import {
  AgwRestApiStage,
  CreateAgwRestApiStageRequest,
  UpdateAgwRestApiStageRequest,
} from '@localstack/types';

import { MagicForm } from '../../../magic/MagicForm';
import { RelatedResourcePicker } from '../../../../form';

export interface AgwRestApiStageFormProps {
  restApiId: string;
  stage?: Optional<Partial<AgwRestApiStage>>;
  formId?: string;
  loading?: boolean;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: CreateAgwRestApiStageRequest) => unknown;
  onUpdate: (data: UpdateAgwRestApiStageRequest) => unknown;
}

export const AgwRestApiStageForm = ({
  restApiId,
  stage,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: AgwRestApiStageFormProps): ReactElement => (
  <MagicForm
    data={stage}
    schema={getSchema('APIGateway')}
    loading={loading}
    entry="CreateStageRequest"
    formId={formId}
    externalFields={{
      '^restApiId$': () => <TextField fullWidth disabled value={restApiId} variant="outlined" />,
      '^deploymentId$': (control, fieldName, required) => (
        <RelatedResourcePicker
          required={required}
          control={control}
          fieldName={fieldName} entityName='Deployment'
          client='APIGateway'
          method='getDeployments'
          arrayKeyName='items'
          property='id'
          clientOverrides={clientOverrides}
          args={[{ restApiId }]}
        />
      ),
    }}
    onSubmit={(data: CreateAgwRestApiStageRequest) => {
      if (!stage) return onCreate({ ...data, restApiId });
      const patchOperations = buildAgwPatches(stage, data);
      return onUpdate({ ...data, restApiId, stageName: stage.stageName as string, patchOperations });
    }}
  />
);
