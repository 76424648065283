import { Palette } from '@mui/material/styles';

import { MuiThemeComponentsType } from '../types';

export default (palette: Palette): MuiThemeComponentsType['MuiTableRow'] => ({
  styleOverrides: {
    root: {
      height: '40px',
      '&$selected': {
        backgroundColor: palette.background.default,
      },
      '&:last-child td': {
        borderBottom: 'none',
      },
      '& td + td, & th + th': {
        paddingLeft: '1em',
      },
      '&$hover': {
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      },
    },
  },
});
