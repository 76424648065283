import { ReactElement, useCallback, useState } from 'react';
import { useRoutes, useAwsGetter, useAwsEffect } from '@localstack/services';
import { Card, Button } from '@mui/material';
import {
  Dropdown,
  ConfirmableButton,
  PageTitle,
  GlueJobsTable,
} from '@localstack/ui';
import { DEFAULT_GLUE_ROUTES } from '@localstack/constants';

import { GlueProps } from './types';
import { MainNavTabs } from './components';

export const GlueJobs = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_GLUE_ROUTES,
}: GlueProps): ReactElement => {
  const [selected, setSelected] = useState<string[]>([]);

  const { goto } = useRoutes();

  const { data: jobs, isLoading, mutate } = useAwsGetter(
    'Glue',
    'getJobs',
    [],
    { clientOverrides },
  );

  const { deleteJob, startJobRun } = useAwsEffect(
    'Glue',
    ['deleteJob', 'startJobRun'],
    { revalidate: ['getJobs'], clientOverrides });

  const handleDeleteSelected = useCallback(async () =>
    Promise.all(selected.map((job) => deleteJob({ JobName: job }))),
  [selected],
  );

  const handleStartSelected = useCallback(async () =>
    Promise.all(selected.map((job) => startJobRun({ JobName: job }))),
  [selected],
  );

  return (
    <Layout
      documentTitle="Glue: Jobs"
      title={
        <PageTitle
          title="Glue Jobs"
          breadcrumbs={[
            ['Glue', () => goto(routes.RESOURCES_GLUE_DATABASES)],
            ['Jobs', () => goto(routes.RESOURCES_GLUE_JOBS)],
          ]}
          onMutate={mutate}
        />
      }
      tabs={<MainNavTabs routes={routes} />}
      actions={
        <>
          <Button onClick={() => goto(routes.RESOURCES_GLUE_JOB_CREATE)}>
            Create Job
          </Button>
          <Dropdown label="Actions">
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || selected.length > 1 || isLoading}
              title='Start Selected Job?'
              onClick={handleStartSelected}
              text="Selected Job will started"
            >
              Run Job
            </ConfirmableButton>
            <ConfirmableButton
              componentType="MenuItem"
              disabled={selected.length === 0 || isLoading}
              title={`Delete ${selected.length} Job(s)?`}
              onClick={handleDeleteSelected}
              text="Selected Job(s) will be permanently deleted"
            >
              Remove Selected
            </ConfirmableButton>
          </Dropdown>
        </>
      }
    >
      <Card>
        <GlueJobsTable
          jobs={jobs?.Jobs ?? []}
          loading={isLoading}
          onSelect={setSelected}
          onViewJob={(job) => goto(routes.RESOURCES_GLUE_JOB, { job })}
        />
      </Card>
    </Layout>
  );
};
