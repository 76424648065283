import { ReactElement } from 'react';
import { IAMPolicy, CreateIAMPolicyRequest } from '@localstack/types';
import { VALIDATION_RULES, getSchema } from '@localstack/services';

import { MagicForm } from '../../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../../form';

export interface IAMPolicyFormProps {
  policy?: Optional<Partial<IAMPolicy>>;
  loading?: boolean;
  formId?: string;
  onCreate: (data: CreateIAMPolicyRequest) => unknown;
}

export const IAMPolicyForm = ({ policy, loading, formId, onCreate }: IAMPolicyFormProps): ReactElement => (
  <MagicForm
    data={policy}
    schema={getSchema('IAM')}
    loading={loading}
    entry="CreatePolicyRequest"
    formId={formId}
    externalFields={{
      '^PolicyDocument$': (control, name) => (
        <ControlledCodeEditor
          name={name}
          control={control}
          required
          rules={{ ...VALIDATION_RULES.validJson, ...VALIDATION_RULES.required }}
          language="json"
        />
      ),
    }}
    onSubmit={(data: CreateIAMPolicyRequest) => onCreate(data)}
  />
);
