import { useState, useCallback, ReactElement } from 'react';
import { Button, Card, CardContent } from '@mui/material';
import { Dropdown, ConfirmableButton, LoadingFragment, ECRRepositoriesTable, PageTitle } from '@localstack/ui';
import { useRoutes, useAwsEffect, useAwsGetter } from '@localstack/services';

import { ECRProps } from './types';

export const ECRRepositories = ({
  Layout,
  clientOverrides,
  routes,
}: ECRProps): ReactElement => {
  const { goto } = useRoutes();
  const [selectedRepositories, setSelectedRepositories] = useState<string[]>([]);
  const { data: repositories, isLoading: isECRRepositoriesLoading, mutate } = useAwsGetter(
    'ECR',
    'describeRepositories',
    [],
    { clientOverrides },
  );
  const { deleteRepository, isLoading: isRepositoryMutating } = useAwsEffect(
    'ECR',
    ['deleteRepository'],
    { revalidate: ['describeRepositories'], clientOverrides },
  );

  const handleDeleteRepositories = useCallback(
    async () => Promise.all(selectedRepositories.map((repositoryName: string) => deleteRepository({ repositoryName }))),
    [selectedRepositories],
  );

  return (
    <>
      <Layout
        title={
          <PageTitle
            title="ECR Repositories"
            onMutate={mutate}
          />
        }
        actions={
          <>
            <Button onClick={() => goto(routes.RESOURCES_ECR_REPOSITORY_NEW)}>
              Create
            </Button>
            <Dropdown label="Actions">
              <ConfirmableButton
                componentType="MenuItem"
                disabled={selectedRepositories.length === 0 || isRepositoryMutating}
                title={`Remove ${selectedRepositories.length} repository(s)?`}
                onClick={handleDeleteRepositories}
                text="Selected Repositories will be permanently deleted"
              >
                Remove Selected
              </ConfirmableButton>
            </Dropdown>
          </>
        }
      >
        <Card>
          <CardContent>
            <LoadingFragment
              loading={isECRRepositoriesLoading}
              arrayData={repositories?.repositories ?? []}
              variant="list"
              height={50}
              size={10}
            >
              <ECRRepositoriesTable
                loading={isECRRepositoriesLoading}
                repositories={repositories?.repositories ?? []}
                onSelectRepositories={setSelectedRepositories}
                onViewRepository={(name: string) => goto(routes.RESOURCES_ECR_IMAGES, { name })}
              />
            </LoadingFragment>
          </CardContent>
        </Card>
      </Layout>
    </>
  );
};
