import { ReactElement } from 'react';
import { SMSecret, SMSecretCreateParams, SMSecretUpdateParams } from '@localstack/types';
import { retrieveFileContent, getSchema, AwsClientOverrides } from '@localstack/services';
import { DEFAULT_KMS_ROUTES } from '@localstack/constants';

import { MagicForm } from '../../magic/MagicForm';
import { ControlledCodeEditor } from '../../../form';
import { RelatedResourcePicker } from '../../../form/RelatedResourcePicker';

export interface SecretsManagerSecretFormProps {
  secret?: Optional<Partial<SMSecret>>;
  loading?: boolean;
  formId?: string;
  clientOverrides?: AwsClientOverrides;
  onCreate: (data: SMSecretCreateParams) => unknown;
  onUpdate: (data: SMSecretUpdateParams) => unknown;
}

export const SecretsManagerSecretForm = ({
  secret,
  loading,
  formId,
  clientOverrides,
  onCreate,
  onUpdate,
}: SecretsManagerSecretFormProps): ReactElement => (
  <MagicForm
    data={secret}
    schema={getSchema('SecretsManager')}
    loading={loading}
    entry={secret ? 'UpdateSecretRequest' : 'CreateSecretRequest'}
    formId={formId}
    fieldConditions={{
      '^SecretId': !secret,
    }}
    externalFields={{
      '^SecretString': (control, name) => (
        <ControlledCodeEditor
          control={control}
          name={name}
          language="json"
        />
      ),
      'KmsKeyId': (control, fieldName, required) => (
        <RelatedResourcePicker
          control={control}
          client='KMS' method='listKeys' arrayKeyName='Keys' property='KeyArn'
          fieldName={fieldName} entityName='KMS Key'
          creationRoute={DEFAULT_KMS_ROUTES.RESOURCES_KMS_KEY_CREATE}
          required={required}
          clientOverrides={clientOverrides}
        />
      ),
    }}
    onSubmit={async (data: SMSecretCreateParams | SMSecretUpdateParams) => {
      // convert File into ArrayBuffer by reading its content
      if (data.SecretBinary) {
        data.SecretBinary = await retrieveFileContent(data.SecretBinary as File);
      }

      if (!secret) return onCreate(data as SMSecretCreateParams);
      return onUpdate({ ...data, SecretId: secret.ARN } as SMSecretUpdateParams);
    }}
  />
);
