import { ReactElement } from 'react';
import { Card, CardContent, CardActions, Box, Typography } from '@mui/material';
import { useRoutes, useAwsEffect, useAwsBatchIterator, useAwsGetter } from '@localstack/services';
import { ProgressButton, Breadcrumbs, BackupSelectionForm } from '@localstack/ui';
import { useParams } from 'react-router-dom';
import { CreateBackupSelectionRequest } from '@localstack/types';

import { DEFAULT_BACKUP_ROUTES } from '@localstack/constants';

import { BackupProps } from './types';

const DEFAULT_IAM_ROLE_ARN = 'arn:aws:iam::000000000000:role/test';

export const BackupPlanAssignment = ({
  Layout,
  clientOverrides,
  routes = DEFAULT_BACKUP_ROUTES,
}: BackupProps): ReactElement => {
  const { goto } = useRoutes();
  const { id } = useParams<'id'>();

  const { data: dynamodbTableNames, isLoading: isDynamoLoading } =
    useAwsGetter('DynamoDB', 'listTables', [], { clientOverrides });
  const { data: rdsInstances, isLoading: isRDSLoading } =
    useAwsGetter('RDS', 'describeDBInstances', [], { clientOverrides });

  const { describeTable } = useAwsEffect('DynamoDB', ['describeTable'], { clientOverrides });

  const { createBackupSelection } = useAwsEffect(
    'Backup',
    ['createBackupSelection'],
    { revalidate: ['listBackupSelections', 'getBackupSelection'], clientOverrides },
  );

  const loading = isDynamoLoading || isRDSLoading;
  const dynamoDbTables = useAwsBatchIterator(
    dynamodbTableNames?.TableNames,
    (TableName) => describeTable({ TableName }),
  );

  const arns = [
    DEFAULT_IAM_ROLE_ARN,
    ...dynamoDbTables.map((ddb) => ddb.Table?.TableArn as string),
    ...(rdsInstances?.DBInstances?.map((rds) => rds.DBInstanceArn as string) ?? []),
  ];

  const handleAssignResources = async (data: CreateBackupSelectionRequest) => {
    await createBackupSelection(data);
    goto(routes.RESOURCES_BACKUP_PLAN, { id });
  };
  return (
    <Layout
      title={
        <Box>
          <Typography variant="h4">Backup Plan</Typography>
          <Breadcrumbs
            mappings={[
              ['Plans', () => goto(routes.RESOURCES_BACKUP_PLANS)],
              [id, () => goto(routes.RESOURCES_BACKUP_PLAN, { id })],
              ['Assign Resources', null],
            ]}
          />
        </Box>
      }
      actions={
        <ProgressButton
          type="submit"
          form="CreateSelection"
          variant="outlined"
          color="primary"
          loading={loading}
        >
          Submit
        </ProgressButton>
      }
    >
      <Card>
        <CardContent>
          <BackupSelectionForm
            onCreate={handleAssignResources}
            formId="CreateSelection"
            planId={id as string}
            arns={arns}
          />
        </CardContent>
        <CardActions>
          <ProgressButton
            type="submit"
            form="CreateSelection"
            variant="contained"
            color="primary"
            loading={loading}
          >
            Submit
          </ProgressButton>
        </CardActions>
      </Card>
    </Layout>
  );
};
